import { clientActions } from '../../store/clientSlice';
import { snackActions } from '../../store/snackSlice';
import { useAppDispatch } from './reduxTypedHooks';

function useHandleTokenExpired() {

  const dispatch = useAppDispatch()

  const handleTokenExpired = () => {
    dispatch(clientActions.disconnect());
    dispatch(snackActions.set({ type: 'warning', content: 'Your access token has expired, please login' }));
}

  return handleTokenExpired;
}

export default useHandleTokenExpired