import { useState } from "react"
import Dialog from "../../../components/dialog/Dialog"
import { Offer } from "../../../utils/models/offer.model"
import { createPortal } from "react-dom"
import { useAppSelector } from "../../../utils/hooks/reduxTypedHooks"

const OfferItemRow = ({ offer, ongoing }: { offer: Offer, ongoing?: boolean }) => {

    const [formVisible, setFormVisible] = useState(false)

    const rootModalDiv = document.getElementById('modal-root')!
 
    const promotors = useAppSelector(state => state.promotors)
    const promotor = promotors.find(promotor => promotor.id === offer.promotorId)

    const openForm = () => setFormVisible(true)
    const closeForm = () => setFormVisible(false)

    const handleSeeOffer = () => {
        
    }

    const handleAcceptOffer = () => {
        
    }
    const handleDeclineOffer = () => {
        
    }


    return (
        <>
            {formVisible
                    && createPortal(
                    <Dialog
                        visible
                        onClose={closeForm}
                        backdropClose={false}
                        dialogCustomStyle={{overflowY: 'scroll', height: '90vh', top: '5vh', width: '80%', left: '10%'}}
                        notAllSpace={true}
                    >
                        {/* {see offer} */}
                    </Dialog>, rootModalDiv)}
            <tr>
                <td>eg 10/12/22</td>
                <td>eg 15/12/22</td>
                <td>{promotor ? promotor.name : 'Promotor not found'}</td>
                <td>eg 3</td>
                <td>eg SG - KR</td>
                <td>eg 10/12/22 - 12/12/22</td>
                <td>eg $ 1,000,000</td>
                {!ongoing && <td>{offer.status}</td>}
                <td>
                    <button className="button button--primary button--small offer__action-btn" onClick={handleSeeOffer}>See Offer</button>
                    {ongoing &&
                        <> 
                            <button className="button button--primary button--small offer__action-btn" onClick={handleAcceptOffer}>Accept Offer</button>
                            <button className="button button--primary button--small offer__action-btn" onClick={handleDeclineOffer}>Decline Offer</button>
                        </>}
                </td>
            </tr>
        </>
    )
}

export default OfferItemRow