import { useState } from "react"
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai"
import FormSection from "../../../components/form/form-components/FormSection"
import LabelInput from "../../../components/form/mui/label-input/LabelInput"
import { hash } from "../../../crypto"
import useFetchApiAuth from "../../../utils/hooks/useFetchApiAuth"
import useSetSnack from "../../../utils/hooks/useSetSnack"
import { Client } from "../../../utils/models/client.model"

const UserProfileForm = ({ client }: { client: Client }) => {

    const [formInputs, setFormInputs] = useState({
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
    })
    const [isPosting, setIsPosting] = useState(false)
    const [showPasswords, setShowPasswords] = useState({
        currentPassword: false,
        newPassword: false,
        confirmNewPassword: false,
    })

    const fetchApiAuth = useFetchApiAuth()
    const setSnack = useSetSnack()

    const updateInput = (value: any, inputName: keyof typeof formInputs) => {
        setFormInputs(prevInputs => ({...prevInputs, [inputName]: value}))
    }

    const toggleShowPasswords = (password: keyof typeof showPasswords) => {
        setShowPasswords(prevInputs => ({...prevInputs, [password]: !prevInputs[password]}))
    }

    const validateForm = () => {
        if(formInputs.newPassword !== formInputs.confirmNewPassword) {
            setSnack({type: 'warning', content: 'New password confirmation not matching.'})
            return false
        }

        return true
    }

    const handleSubmit = async () => {
        setIsPosting(true)

        const isFormValid = validateForm()

        if(!isFormValid) {
            setIsPosting(false)
            return
        }

        const id = client.id
        const email = client.email
        const { newPassword } = formInputs
        const passwordHash = await hash(formInputs.currentPassword, '')

        const body = {
            id,
            email,
            hash: passwordHash,
            newPassword,
        }

        const { error, response } = await fetchApiAuth({
            method: 'POST',
            route: 'user-update-password',
            body
        });

        // snack
        setSnack(
            error
            ? {
                type: 'error', 
                content: error.toString()
            }
            : {
                type: 'success',
                content: 'Your password has been updated successfully.'
            }
        )

        setIsPosting(false)
    }

    return (
        <div className="allwidth app-card">
            <p className="card-title leftalign margedBot10px">User Credentials</p>

            <div className="margedBot50px">
                <FormSection>
                        <LabelInput
                            disabled
                            title="Email"
                            value={client.email}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {}}
                        />

                        <div className="password-container">
                            <LabelInput
                                title="Current Password"
                                type={showPasswords.currentPassword ? "text" : "password"}
                                value={formInputs.currentPassword}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateInput(e.target.value, 'currentPassword')}
                            />
                            {
                                showPasswords.currentPassword
                                ? <AiFillEyeInvisible onClick={() => toggleShowPasswords('currentPassword')} size={20} className="show-password-icon" />
                                : <AiFillEye onClick={() => toggleShowPasswords('currentPassword')} size={20} className="show-password-icon" />
                            }
                        </div>

                        <div className="password-container">
                            <LabelInput
                                title="New Password"
                                type={showPasswords.newPassword ? "text" : "password"}
                                value={formInputs.newPassword}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateInput(e.target.value, 'newPassword')}
                            />
                            {
                                showPasswords.newPassword
                                ? <AiFillEyeInvisible onClick={() => toggleShowPasswords('newPassword')} size={20} className="show-password-icon" />
                                : <AiFillEye onClick={() => toggleShowPasswords('newPassword')} size={20} className="show-password-icon" />
                            }
                        </div>

                        <div className="password-container">
                            <LabelInput
                                title="Confirm New Password"
                                type={showPasswords.confirmNewPassword ? "text" : "password"}
                                value={formInputs.confirmNewPassword}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateInput(e.target.value, 'confirmNewPassword')}
                            />
                            {
                                showPasswords.confirmNewPassword
                                ? <AiFillEyeInvisible onClick={() => toggleShowPasswords('confirmNewPassword')} size={20} className="show-password-icon" />
                                : <AiFillEye onClick={() => toggleShowPasswords('confirmNewPassword')} size={20} className="show-password-icon" />
                            }
                        </div>
                        
                    </FormSection>
            </div>

            <button className="button button--primary button--uppercase" disabled={isPosting} onClick={handleSubmit}>{isPosting ? 'Changing...' : 'Change Password'}</button>

        </div>
    )
}

export default UserProfileForm