import { Component } from "react";
import { Flex, Row } from "../../../../components/Containers";
import LabelInput from "../../../../components/form/mui/label-input/LabelInput";
import { AiOutlineCloseCircle, AiOutlineUser } from 'react-icons/ai';
import { FaRegFilePdf } from 'react-icons/fa'
import { BiDuplicate, BiError } from 'react-icons/bi'
import { MdCancel, MdOutlinePlaylistAdd } from 'react-icons/md'
import { BsShieldCheck } from 'react-icons/bs';
import SelectInput from "../../../../components/form/form-components/SelectInput";

import ReactFlagsSelect from "react-flags-select";
import CheckboxSpruha from "../../../../components/form/spruha/CheckboxSpruha";

import { fetchApi, mapAndReduceAsync, plural, promisify } from "../../../../utils/utils";
import moment from 'moment';

const rightBorder = {borderRight: '1px solid silver'};
const leftBorder = {borderLeft: '1px solid silver'};



/**
 * @dev PRIMUSE Live Performance Contract Form / Generator
 * @output docData = {
        date,               //  timestamp
        purchaser,          //  { name, cieName, fullAddress, cieId, password, perDiem }
        venue,              //  { city, name, address, taxPercent, flag, totalCapacity }
        artist,             //  { name, code, fee, useNetFee, useGrossFee, password, totalPartyPeople }
        shows,              //  [{ tickets, date, loadin, soundCheck, doorsOpen, onStage, time, minAge, curfew, merchandising, artistSell, buildSell }]
        payments,           //  [{ date, amount }]
        hotel,              //  { name, address, phone, website }
        transportation,     //  { international, domestic }

    }
 */
class LivePerformanceContractForm extends Component {
    state = {
        docData: {
            payments: [
                {},
            ],
            shows: [
                {
                    tickets: [{}],
                    artistFee: {useNetFee: true}    // use net fee by default
                },
            ],
            
        },

        /* TEST DATA */
        // docData: {
  
        //     // "payments": [
        //     //     { date: '2022-10-01', amount: '300000' },
        //     //     { amount: '550000', date: '2022-11-01' }
        //     // ],
        //     "artist": {
        //         "name": "ColdPlay",
        //         "code": "CDPLY",
        //         "password": "MLSFK45$",
        //         "totalPartyPeople": 2,
                
        //     },
        
        //     "shows": [
        //         {
        //             artistFee: {
        //                 "amount": "850000",
        //                 "useNetFee": true
        //             },
        //             "tickets": [
        //                 {
        //                     "name": 'Q1',
        //                     "quantity": "5000",
        //                     "grossPrice": "75"
        //                 },
        //                 {
        //                     "name": 'Q2',
        //                     "quantity": "7500",
        //                     "grossPrice": "90",
        //                 }
        //             ],
        //             "date": "2022-10-15",
        //             "loadin": "15:49",
        //             "soundCheck": "16:50",
        //             "doorsOpen": "17:50",
        //             "onStage": "18:50",
        //             "time": "19:50",
        //             "minAge": "21",
        //             "curfew": "00:00",
        //             "merchandising": "15",
        //             "artistSell": "50",
        //             "buildSell": "35",
        //             "venue": {
        //                 city: 'New York',
        //                 taxPercent: 12,
        //                 "name": "Central Stadium",
        //                 "address": "948 central avenue, NY, USA",
        //                 flag: 'US',
        //                 totalCapacity: 45000,
        //                 "phone": '+15467834590',
        //                 website: 'https://nycentralstadium.com'
        //             },
        //             "hotel": {
        //                 "name": "Hilton",
        //                 "address": "Hilton Hotel, 465 Grand Street, New York, NY, USA",
        //                 "phone": "+01234567890",
        //                 "website": "https://hiltn.com"
        //             },
        //         },
        //         {
        //             artistFee: {
        //                 "amount": "735000",
        //                 "useNetFee": true
        //             },
        //             tickets: [
        //                 {
        //                     "name": 'Q1',
        //                     "quantity": "5000",
        //                     "grossPrice": "80"
        //                 },
        //                 {
        //                     "name": 'Q2',
        //                     "quantity": "8500",
        //                     "grossPrice": "95",
        //                 }
        //             ],
        //             date: '2022-10-14',
        //             merchandising: '15',
        //             artistSell: '50',
        //             buildSell: '35',
        //             minAge: '21',
        //             notes: 'no curfew',
        //             loadin: '19:14',
        //             soundCheck: '20:14',
        //             doorsOpen: '21:14',
        //             onStage: '22:14',
        //             time: '22:30',
        //             "venue": {
        //                 city: 'Las Vegas',
        //                 taxPercent: 9,
        //                 "name": "Air Continent",
        //                 "address": "12th Street #8476, AZ, USA",
        //                 flag: 'US',
        //                 //totalCapacity: 45000,
        //                 "phone": '+17689236743',
        //                 website: 'https://air-continent-vegas.com'
        //             },
        //             "hotel": {
        //                 "name": "Grand Plaza",
        //                 "address": "14th Street #23, AZ, USA",
        //                 "phone": "+16567998771",
        //                 "website": "https://grandplazahotel.com"
        //             },
        //         }
        //     ],
        //     "purchaser": {
        //         "name": "John Doe",
        //         "email": "johndoe@doecie.com",
        //         "cieName": "DoeCie",
        //         "fullAddress": "34 central street, NY, USA",
        //         "cieId": "VDFLSK45",
        //         'password': 'J1234D',
        //         "perDiem": 500,

        //     },
            
        //     "transportation": {
        //         type: 'PLUS FLIGHT',
        //         // international: 8532, // (shutted for now)
        //         // domestic: 345        // (idem)
        //     }
    
        // }
    }

    componentDidMount() {
        if (this.props.useDocData) {
            this.setState({docData: {...this.state.docData, ...this.props.useDocData}})
        }
    }

    getFees = () => {
        const { shows, payments } = this.state.docData;

        const ttlArtistFees = (
            shows?.length
            ? (
            shows
                .map(
                    show => parseInt(show.artistFee?.amount||0)
                )
                .reduce((a, b) => (a + b))
            )
            : 0
        );

        const ttlPayments = (
            payments?.length
            ? (
                payments
                    .map(
                        p => parseInt(p.amount||0),
                    )
                    .reduce((a, b) => (a + b))
            )
            : 0
        );

        return { ttlArtistFees, ttlPayments, paymentRemaining: ttlArtistFees - ttlPayments }
    }

   
    generatePdfContract = async() => {
        const { docData } = this.state;

        // Check for payments mismatchs
        const ttlArtistFees = await mapAndReduceAsync(    
            docData.shows,
            async show => parseFloat(show.artistFee.amount),
            (a, b) => (a + b)
        );

        const ttlPayments = await mapAndReduceAsync(
            docData.payments,
            async p => parseInt(p.amount),
            (a, b) => ( a + b )
        );

        const eachPaymentHasDate = await mapAndReduceAsync(
            docData.payments,
            async p => p.date?.length > 0,
            (a, b) => ( a===true ? b===true : false)
        );

        const eachVenueHasKnownTax = await mapAndReduceAsync(    
            docData.shows,
            async show => show?.venue?.taxPercent > 0,
            (a, b) => ( a===true ? b===true : false)
        );

        (
            (parseInt(ttlPayments) !== parseInt(ttlArtistFees))
            ? this.props.setSnack({
                type: 'error', 
                content: 'There is a mismatchs between total payments and artist fee, please change'
            })
            : (
                (!eachPaymentHasDate)
                ? this.props.setSnack({
                    type: 'error', 
                    content: 'At least one payment is missing a date'
                })
                : (
                    eachVenueHasKnownTax
                    ? this.props.storeComputedContract(docData)
                    : this.props.setSnack({
                        type: 'error', 
                        content: 'At least one venue is missing a tax percentage'
                    })
                )
            )
        );
            
        

    }

    setDocData = (props, _additionalState) => this.setState({
        docData: {
            ...(this.state.docData || {}),
            ...props,
        },
        ...(_additionalState||{}) 

    });

    setDocDataKey = (key, props) => this.setDocData({
        [key]: {
            ...this.state.docData[key] || {},
            ...props
        }
    })

    //                ex. "shows", showIndex, "venue", props
    setDocDataNestedKey = (key, keyIndex, prop, props) => (
        this.setDocArrayData(key, keyIndex, { [prop]: {...(this.state.docData[key][keyIndex][prop]||{}), ...props} })
    )
    

    addKeyItem = (key, _props, _additionalState) => this.setDocData(
        {
            [key]: [
                ...(this.state.docData[key]||[]),
                {...(_props||{})}
            ]
        },
        _additionalState
    )

    setDocArrayData = (
        key, index, props
    ) => {
        
        const upKey = {
            [key]: this.state.docData[key].map((it, iti) => (
                iti === index
                ? { ...it, ...props }
                : it
            ))
        }

        this.setDocData(upKey);
    }

    removeDocArrayData = (
        key, index
    ) => {
        const upKey = this.state.docData[key].filter((it, iti) => iti !== index);
        this.setDocData({[key]: upKey});
    }

    addShowTickets = (showIndex) => {
        const { tickets } = this.state.docData.shows[showIndex];
        const upTickets = [...tickets, {}]; 
        this.setDocArrayData('shows', showIndex, { tickets: upTickets })
    }

    removeShowTickets = (showIndex, ticketsIndex) => {
        let { tickets } = this.state.docData.shows[showIndex];
        const upTickets = tickets.filter((t, ti) => ti !== ticketsIndex) 
        this.setDocArrayData('shows', showIndex, { tickets: upTickets })
    }

    setShowTickets = (showIndex, ticketsIndex, props) => {
        let { tickets } = this.state.docData.shows[showIndex];
        const upTickets = tickets.map((t, ti) => (
            (ti === ticketsIndex)
            ? {...t, ...props}
            : t
        ));
        this.setDocArrayData('shows', showIndex, { tickets: upTickets })

    }

    setShowDuplicate = (key, value) => {
        const conflict = value > this.state.docData?.shows?.length;

        conflict
        ? (
            this.props.setSnack({type: 'warning', content: `Bad Duplicate Reference: show n°${value} does not exist`})
        )
        : this.setState({
            showDuplicate: {
                ...(this.state?.showDuplicate||{}),
                [key]: value
            }
        })
    }

    addShow = () => {
        // handle duplicate data requests
        const { showDuplicate, docData } = this.state;
        const sProps = (
            showDuplicate 
            ? (
                Object.keys(showDuplicate)  // TODO HERE : key `specs` is not a valid show prop (docData.shows[0].specs == undefined)
                    .map(dk => ({
                        [dk]: docData.shows[showDuplicate[dk]-1][dk]
                    }))
                    .reduce((a, b) => ({...a, ...b}))
            ) 
            : {}
        )
        this.addKeyItem('shows', {tickets: [{}], artistFee: {useNetFee: true}, ...sProps}, {showDuplicate: undefined});
    }

    render() {
        const {
            artists,
            promotors,

        } = this.props;

        const {
            showDuplicate,
            docData,
        } = this.state;

        const {
            //date,
            purchaser,  // { name, cieName, cieId, fullAddress, password }
            artist,     // { fee, name }
            payments,   // [ { amount, date }, ... ]
            shows,      // [ {date, time, venue({ name, address, phone, website }), hotel({ name, address, phone, website })}, ... ]
            hotel,      // { name, address, phone, website }
            transportation,


        } = docData || {};

        console.log('CONTRACT FORM ARTIST ?', artist)

        const promotorsOptions = (
            promotors?.length
            ? promotors
                .map(p => {
                    const pImg = p.logo; // || defaultPromotorImage (todo)

                    return ({ 
                        value: p,   // todo?: sanitize `p` to only backend `docDef` necessary props? 
                        label: <div className="flex row justifystart clickable">
                            {
                                pImg
                                ? <img alt={`primuse artist ${p.name}`} src={pImg} className="small thumbnail-img" />
                                : <AiOutlineUser size={20} />
                            }
                            &nbsp;
                            <b className="larger">{p.name||'Unnamed*'}</b> 
                            &nbsp; 
                            Id #{p.id}
                            &nbsp; 
                            Company: ({p.cieName})
                        </div>,
                        searchData: JSON.stringify(p)
                    })
                })
            : []
        )
        
        const artistsOptions = (
            artists?.length
            ? artists
                .map((a) => {
                    const name = a.displayableName || a.name;
                    const aImg = a.image || a.logo || a.banner;
                    const {id: aId, code: aCode, totalPartyPeople} = a;
                    return ({ 
                        value: {id: aId, code: aCode, name, totalPartyPeople},  // contract required artist props
                        label: <div className="flex row justifystart clickable">
                            {
                                aImg
                                ? <img alt={`primuse artist ${name}`} src={aImg} className="thumbnail-img" />
                                : <div className="thumbnail-img"><AiOutlineUser size={50} color="grey" /></div>
                            }
                            &nbsp;
                            <Flex className="alignstart">
                                <b className="larger">{name||'Unnamed*'}</b> 
                                <div>Id #{aId}</div>
                                <div>Country: ({a.country})</div>
                            </Flex>
                        </div>,
                        searchData: JSON.stringify(a)
                    })
                })
            : []
        )
        const { ttlArtistFees, paymentRemaining } = this.getFees();


        console.log('docData.shows[0].venue', docData.shows?.length && docData.shows[0].venue)

        return (
            <div className="flex allheight width90 justifystart alignstart smaller" >
                <div className="title selfcentered xxlarge spaced-text">LIVE PERFORMANCE CONTRACT {/* <div className="theme1 small">by Primuse</div> */}</div>
                
                {/* PURCHASER */}
                <label className="bold xlarge">PURCHASER :</label>
                <div className="flex margedLeft allwidth">
                    {/* <Row>
                        <LabelInput
                            title='Purchaser Name'
                            value={purchaser?.name}
                            onChange={e => this.setDocDataKey('purchaser', { name: e.target.value })}
                        />

                        <LabelInput 
                            title='Purchaser Email'
                            value={purchaser?.email}
                            onChange={e => this.setDocDataKey('purchaser', { email: e.target.value })}
                        />

                        <LabelInput 
                            title='Company Name'
                            value={purchaser?.cieName}
                            onChange={e => this.setDocDataKey('purchaser', { cieName: e.target.value })}
                        />
                    </Row>
                    <Row>
                        <LabelInput 
                            title='Company Full Address'
                            value={purchaser?.fullAddress}
                            onChange={e => this.setDocDataKey('purchaser', { fullAddress: e.target.value })}
                        />

                        <LabelInput 
                            title='Company Legal Identifier'
                            value={purchaser?.cieId}
                            onChange={e => this.setDocDataKey('purchaser', { cieId: e.target.value })}
                        />

                        <LabelInput 
                            title='Purchaser Password'
                            value={purchaser?.password}  
                            onChange={e => this.setDocDataKey('purchaser', { password: e.target.value })}
                        />
                    </Row> */}

                    <div className="selfstart width90">
                        <SelectInput
                            searchable
                            filterOption={(_promotor, input) => (
                                !input 
                                || _promotor.data.searchData.toLowerCase().includes(input.toLowerCase()) 
                            )}
                            title={`Select one of ${promotors?.length||0} ${plural('promotor', promotors?.length)}...`}
                            noTitle
                            // className="width90 selfstart"
                            options={promotorsOptions}
                            onChange={e => this.setDocDataKey('purchaser', e.value)} 
                            value={promotorsOptions.find((o) => o.value.id === purchaser?.id)}
                            showRawValue
                        />
                    </div>
                </div>

                
                {/* ARTIST */}
                <label className="bold xlarge margedTop">ARTIST :</label>
                <div className="flex margedLeft allwidth">
                    {/* <Row>
                        <LabelInput 
                            title='Artist Name'
                            value={artist?.name}
                            onChange={e => this.setDocDataKey('artist', { name: e.target.value })}
                        />

                        <LabelInput 
                            title='Artist Code'
                            value={artist?.code}
                            onChange={e => this.setDocDataKey('artist', { code: e.target.value })}
                        /> 
                    </Row> */}


                    {/* 
                        {
                            options: users.filter((u:any) => u.id !== client.id).map((user:any) => ({ value: user.id, label: <div className="flex row justifystart"><AiOutlineUser size={20} />&nbsp;<b>{user.name||'Unnamed*'}</b> &nbsp; ({user.email})</div> })),
                            title: 'Link to Existing User',
                            value: promotor.clientId,
                            onChange: (e:any) => setPromotor({clientId: e?.value||''})
                        },
                    */}
                    <div className="width90 selfstart">
                        <SelectInput
                            searchable
                            filterOption={(_artist, input) => (
                                !input 
                                || _artist.data.searchData.toLowerCase().includes(input.toLowerCase()) 
                            )}
                            title={`Select one of ${artists?.length||0} ${plural('artist', artists?.length)}...`}
                            noTitle
                            options={artistsOptions}
                            onChange={e => this.setDocDataKey('artist', e.value)} 
                            value={artistsOptions.find((o) => o.value.id === artist?.id)}
                            showRawValue
                        />
                    </div>
                </div>


                {/* SHOWS n°1 (after changes) */}
                <label className="bold xlarge margedTop">SHOWS :</label>
                <div className="flex margedLeft allwidth">
                    
                  
                    <table className="form-table selfstart">
                        <tr>
                            <th style={{width: '4%'}}>N°</th>
                            <th style={{width: '3%', minWidth: '4%'}}>Cat.</th>
                            <th style={{width: '88%'}}>Datas</th>
                            <th style={{width: '5%'}}>Remove</th>
                        </tr>
                        {
                            shows.map((show, si) => <>
                      
                                {/* VENUE row */}
                                <tr key={`show_${si}_venue`} className={si ? 'row-separator' : ''}>
                                    {/* <td rowSpan={5} className="bold xlarge theme1bg" style={rightBorder}>{si + 1}</td> */}
                                    <td rowSpan={6} className="bold xlarge theme1bg vertical-text spaced-text-large" style={rightBorder}>SHOW <span style={{letterSpacing: 'normal', writingMode: 'horizontal-tb'}}>#{si + 1}</span></td>

                                    <td className="vertical-text theme2bg" style={rightBorder}>VENUE</td>
                                    <td>
                                        <Row>
                                            <Flex row style={{flex: .66}}>
                                            <LabelInput 
                                                title='Venue Name'
                                                value={show?.venue?.name}
                                                onChange={e => this.setDocDataNestedKey('shows', si, 'venue', { name: e.target.value })}

                                            />
                                            <LabelInput 
                                                title='City'
                                                value={show.venue?.city}
                                                onChange={e => this.setDocDataNestedKey('shows', si, 'venue', { city: e.target.value })}
                                            />
                                            </Flex>
                                            <Flex row style={{flex: .33}}>
                                                <Flex flex={5} className="">
                                                    <label className="selfstart">Country</label>
                                                    <ReactFlagsSelect
                                                        searchable
                                                        selected={show.venue?.flag}
                                                        onSelect={e => this.setDocDataNestedKey('shows', si, 'venue', { flag: e })}                                                    
                                                        className="width95 selfstart"
                                                        selectButtonClassName="flags-selection-button"

                                                    />
                                                </Flex>

                                                <Flex flex={5}>
                                                    <LabelInput 
                                                        title='Tax %'
                                                        className='width95 selfstart'
                                                        value={show.venue?.taxPercent}
                                                        onChange={e => this.setDocDataNestedKey('shows', si, 'venue', { taxPercent: e.target.value })}
                                                        type='number'
                                                        min={0}
                                                        max={100}
                                                    />
                                                </Flex>
                                            </Flex>
                                            
                                            
                                        </Row>
                                        <Row>
                                            <LabelInput 
                                                title='Full Address'
                                                value={show.venue?.address}
                                                onChange={e => this.setDocDataNestedKey('shows', si, 'venue', { address: e.target.value })}
                                            />
                                                
                                            <LabelInput 
                                                title='Phone'
                                                value={show.venue?.phone}
                                                onChange={e => this.setDocDataNestedKey('shows', si, 'venue', { phone: e.target.value })}
                                            />

                                            <LabelInput 
                                                title='Website'
                                                value={show.venue?.website}
                                                onChange={e => this.setDocDataNestedKey('shows', si, 'venue', { website: e.target.value })}
                                            />
                                        </Row>
                                       
                                    </td>
                                    <td rowSpan={6} style={leftBorder}>
                                        <div className="allwidth flex">
                                            <button className='xsmall button button--round button--danger flex' onClick={() => this.removeDocArrayData('shows', si)}>
                                                <div className="larger">X</div>
                                            </button>
                                        </div>
                                    </td>
                                 
                                </tr>


                                <tr key={`show_${si}_artistfee`}>
                                    <td className="vertical-text theme2bg" style={rightBorder}>FEE</td>
                                    <td>
                                        <Row>
                                            <LabelInput 
                                                title='Artist Fee'
                                                value={show.artistFee?.amount}  
                                                onChange={e => this.setDocDataNestedKey('shows', si, 'artistFee', { amount: e.target.value })}

                                                type="number"
                                            />
                                            <Row>
                                                {/* <LabelInput 
                                                    title='Fee expressed in Net'
                                                    value={show.artistFee?.useNetFee}  
                                                    onChange={e => this.setDocDataNestedKey('shows', si, 'artistFee', { useNetFee: e.target.checked, useGrossFee: !e.target.checked })}
                                                    type="checkbox"
                                                /> */}
                                                <CheckboxSpruha 
                                                    title='Fee expressed in Net'
                                                    checked={show.artistFee?.useNetFee}
                                                    onChange={e => this.setDocDataNestedKey('shows', si, 'artistFee', { useNetFee: e.target.checked, useGrossFee: !e.target.checked })}
                                                />
                                                {/* <LabelInput 
                                                    title='Fee expressed in Gross'
                                                    value={show.artistFee?.useGrossFee}  
                                                    onChange={e => this.setDocDataNestedKey('shows', si, 'artistFee', { useGrossFee: e.target.checked, useNetFee: !e.target.checked })}
                                                    type="checkbox"
                                                /> */}
                                                <CheckboxSpruha 
                                                    title='Fee expressed in Gross'
                                                    checked={show.artistFee?.useGrossFee}
                                                    onChange={e => this.setDocDataNestedKey('shows', si, 'artistFee', { useGrossFee: e.target.checked, useNetFee: !e.target.checked })}
                                                />
                                            </Row>
                                        </Row>
                                    </td>
                                   
                                </tr>

                                
                                <tr key={`show_${si}_schedules`}>
                                    <td className="vertical-text theme2bg" style={rightBorder}>TIME</td>
                                    <td style={{verticalAlign: 'top'}}>
                                        <Row>

                                            <LabelInput 
                                                title='Show Date'
                                                value={show.date}
                                                onChange={e => this.setDocArrayData('shows', si, { date: e.target.value })}
                                                type="date"
                                            /> 
                                            <LabelInput 
                                                title='Doors Open'
                                                value={show.doorsOpen}
                                                onChange={e => this.setDocArrayData('shows', si, { doorsOpen: e.target.value })}
                                                type="time"
                                            />
                                            <LabelInput 
                                                title='Show Time'
                                                value={show?.time}  // TODO : index in array
                                                onChange={e => this.setDocArrayData('shows', si, { time: e.target.value })}
                                                type="time"
                                            />

                                        </Row>

                                    </td>
                                    
                                </tr>

                                <tr key={`show_${si}_tickets`}>
                                    <td className="vertical-text theme2bg" style={rightBorder}>TICKETS</td>
                                    <td>
                                        <table className="xsmall allwidth">
                                            <tr>
                                                <th>Name</th>
                                                <th>Quantity</th>
                                                <th>Gross Price</th>
                                                <th>Remove</th>
                                            </tr>

                                            {/* Show tickets */}
                                            {
                                                show.tickets?.length
                                                ? show.tickets.map((ticket, sti) => <tr key={`show${si}tkts${sti}`}>
                                                    <td>
                                                        <input 
                                                            onChange={(e) => {
                                                                const name = e.target.value;
                                                                const sameName = show.tickets.find(_t=>_t.name===name);
                                                                if (sameName)
                                                                    this.props.setSnack({type: 'error', content: <span>A ticket type named <b>{name}</b> already exists in show n°{si+1}</span>})    
                                                                else 
                                                                    this.setShowTickets(si, sti, { name });
                                                            }}
                                                            value={ticket.name}
                                                            className='width90'
                                                        />
                                                    </td>
                                                    <td>
                                                        <input 
                                                            onChange={(e) => this.setShowTickets(si, sti, { quantity: e.target.value })}
                                                            value={ticket.quantity}
                                                            type='number'
                                                            className='width90'

                                                        />
                                                    </td>
                                                    <td>
                                                        <input 
                                                            onChange={(e) => this.setShowTickets(si, sti, { grossPrice: e.target.value })}
                                                            value={ticket.grossPrice}
                                                            type='number'
                                                            className='width90'
                                                        />
                                                    </td>
                                                    <td>
                                                        <AiOutlineCloseCircle 
                                                            size={25}
                                                            onClick={() => this.removeShowTickets(si, sti)}
                                                            fill={'tomato'}
                                                            className='clickable'
                                                        />
                                                    </td>
                                                </tr>)
                                                : null
                                            }
                                         
                                            <tr>
                                                <td colSpan={4}>
                                                    <Flex>
                                                        <button className='xsmall button button--primary button--round flex' onClick={() => this.addShowTickets(si)}><Row>
                                                            <span className="xlarge" style={{lineHeight: '20px'}}>+</span> &nbsp; ADD TICKETS</Row>
                                                        </button>
                                                    </Flex>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>

                                <tr key={`show_${si}_specs`}>
                                    <td className="vertical-text theme2bg" style={rightBorder}>SPECIFICITIES</td>
                                    <td style={{verticalAlign: 'top'}}>
                                        <Row>
                                            <Flex flex={5}>
                                            <LabelInput 
                                                title='Merchandising %'
                                                value={show.merchandising}
                                                onChange={e => this.setDocArrayData('shows', si, { merchandising: e.target.value > 100 ? 100 : e.target.value })}
                                                type="number"
                                                min={'0'}
                                                max={'100'}
                                            />
                                            </Flex>

                                            <Flex flex={5}>
                                            <Row className='width95 selfstart'>
                                            <LabelInput 
                                                title='Artist sell %'
                                                value={show.artistSell}
                                                onChange={e => this.setDocArrayData('shows', si, { artistSell: e.target.value > 100 ? 100 : e.target.value })}
                                                type="number"
                                                min={'0'}
                                                max={'100'}
                                            />

                                            <LabelInput 
                                                title='Build sell %'
                                                value={show.buildSell}
                                                onChange={e => this.setDocArrayData('shows', si, { buildSell: e.target.value > 100 ? 100 : e.target.value })}
                                                type="number"
                                                min={'0'}
                                                max={'100'}
                                            />
                                            </Row>
                                            </Flex>
                                            
                                        </Row>
                                        <Row>
                                            <LabelInput 
                                                title='Min age'
                                                value={show.minAge}
                                                onChange={e => this.setDocArrayData('shows', si, { minAge: e.target.value })}
                                                type="number"
                                            />

                                            <LabelInput 
                                                title='Curfew'
                                                value={show.curfew}
                                                onChange={e => this.setDocArrayData('shows', si, { curfew: e.target.value })}
                                                type="time"
                                            />

                                            
                                        </Row>
                                        <LabelInput 
                                            inputClassName='app-input width95 show-input selfstart height40px'
                                            title='Notes'
                                            value={show.notes}
                                            onChange={e => this.setDocArrayData('shows', si, { notes: e.target.value })}
                                        />
                                    </td>
                                </tr>

                                <tr key={`show_${si}_hotel`}>
                                    <td className="vertical-text theme2bg" style={rightBorder}>HOTEL</td>
                                    <td style={{minWidth: '10vw'}}>
                                        <Row>
                                            <LabelInput 
                                                title='Hotel Name'
                                                value={show.hotel?.name}
                                                onChange={e => this.setDocDataNestedKey('shows', si, 'hotel', { name: e.target.value })}

                                            />

                                            <LabelInput 
                                                title='Hotel Address'
                                                value={show.hotel?.address}
                                                onChange={e => this.setDocDataNestedKey('shows', si, 'hotel', { address: e.target.value })}
                                            />
                                        </Row>
                                        <Row>
                                            <LabelInput 
                                                title='Hotel Phone'
                                                value={show.hotel?.phone}
                                                onChange={e => this.setDocDataNestedKey('shows', si, 'hotel', { phone: e.target.value })}
                                            />

                                            <LabelInput 
                                                title='Hotel Website'
                                                value={show.hotel?.website}
                                                onChange={e => this.setDocDataNestedKey('shows', si, 'hotel', { website: e.target.value })}
                                            />
                                        </Row>
                                    </td>
                                </tr>
                            </>)
                        }

                        {/* DUPLICATE */}
                        <tr>
                            <td className="noBorder theme1bg " colSpan={6}>
                                {
                                    shows?.length
                                    ? <>
                                        <Flex row className='justifystart'>
                                            <BiDuplicate size={15} />
                                            &nbsp;
                                            <i className="flex alignstart bold"> DUPLICATE FROM PAST SHOWS:</i>
                                        </Flex>
                                        <Row className='flex justifystart margedTopHalf spaceevenly width60 wrap'>
                                            <Flex>
                                                <label><b>Artist Fee</b><br/>from</label>
                                                
                                                <input
                                                    className="app-input"
                                                    placeholder="show n°"
                                                    style={{width: 100}}
                                                    type='number'
                                                    min={1}
                                                    onChange={e => this.setShowDuplicate('artistFee', e.target.value)}
                                                    value={showDuplicate?.artistFee||' '}
                                                />
                                            </Flex>

                                            <Flex>
                                                <label><b>Venue</b><br/>from</label>
                                                
                                                <input
                                                    className="app-input"
                                                    placeholder="show n°"
                                                    style={{width: 100}}
                                                    type='number'
                                                    min={1}
                                                    onChange={e => this.setShowDuplicate('venue', e.target.value)}
                                                    value={showDuplicate?.venue||' '}
                                                />
                                            </Flex>

                                            <Flex>
                                                <label><b>Schedules</b><br/>from</label>
                                                
                                                <input
                                                    className="app-input"
                                                    placeholder="show n°"
                                                    style={{width: 100}}
                                                    onChange={e => this.setShowDuplicate('schedules', e.target.value)}
                                                    value={showDuplicate?.schedules||''}
                                                    type='number'
                                                    min={1}
                                                />
                                            </Flex>

                                            {/* <Flex>  // TODO !
                                                <label><b>Specs</b><br/>from</label>
                                                
                                                <input
                                                    className="app-input"
                                                    placeholder="show n°"
                                                    style={{width: 100}}
                                                    onChange={e => this.setShowDuplicate('specs', e.target.value)}
                                                    value={showDuplicate?.specs||''}
                                                    type='number'
                                                    min={1}
                                                />
                                            </Flex> */}

                                            <Flex>
                                                <label><b>Hotel</b><br/>from</label>
                                                
                                                <input
                                                    className="app-input"
                                                    placeholder="show n°"
                                                    style={{width: 100}}
                                                    onChange={e => this.setShowDuplicate('hotel', e.target.value)}
                                                    value={showDuplicate?.hotel||''}
                                                    type='number'
                                                    min={1}
                                                />
                                            </Flex>

                                            <Flex>
                                                <label><b>Tickets</b><br/> from</label>
                                                
                                                <input
                                                    className="app-input"
                                                    placeholder="show n°"
                                                    style={{width: 100}}
                                                    onChange={e => this.setShowDuplicate('tickets', e.target.value)}
                                                    value={showDuplicate?.tickets||''}
                                                    type='number'
                                                    min={1}
                                                />
                                            </Flex>

                                        </Row>
                                    </>
                                    : null
                                }

                                <Flex className="alignstart">
                                    <Flex row className='justifystart margedTop'>
                                        <MdOutlinePlaylistAdd size={20} />
                                        &nbsp;
                                        <i className="flex alignstart bold"> ADD SHOW N°{shows.length+1} :</i>
                                    </Flex> 
                                    <button className=' button button--primary button--round flex margedTopHalf' style={{marginLeft: 30}} onClick={this.addShow}>
                                        <Row>
                                            <span className="xlarge" style={{lineHeight: '20px'}}>+</span> 
                                            &nbsp; 
                                            ADD SHOW
                                        </Row>
                                    </button>
                                </Flex>
                            </td>
                        </tr>
                    </table>
                </div>

                {/* PAYMENTS */}
                <label className="bold xlarge margedTop">PAYMENTS :</label>
                <div className="flex margedLeft allwidth">
                    <table className="form-table selfstart">
                        <thead>
                            <th style={{width: '10%'}}>N°</th>
                            <th>US$ Amount</th>
                            <th>Date</th>
                            <th style={{width: '5%'}}>Remove</th>
                        </thead>
                        <tbody>
                            {
                                (payments||[]).map((p, pi) => (
                                    <tr key={`payment_n_${pi}`}>
                                        <td>{pi + 1}</td>
                                        <td>
                                            <Flex row>
                                                <LabelInput 
                                                    noLabel
                                                    title='Payment Amount'
                                                    value={p?.amount}
                                                    onChange={e => this.setDocArrayData('payments', pi, { amount: e.target.value })}
                                                    type="number"
                                                    inputClassName="app-input allwidth selfstart"
                                                /> 
                                                &nbsp;
                                                {
                                                    paymentRemaining
                                                    ? <button className="app-button xsmall inverted-button" onClick={e => this.setDocArrayData('payments', pi, { amount: paymentRemaining+Number(p?.amount||0) })}>max</button>
                                                    : null
                                                }
                                            </Flex>
                                            
                                        </td>
                                        <td>
                                            <LabelInput 
                                                noLabel
                                                title='Payment Date'
                                                value={payments?.date}  // TODO : index in array
                                                onChange={e => this.setDocArrayData('payments', pi, { date: e.target.value })}
                                                type="date"
                                            />
                                        </td>
                                        <td>
                                            <div className="allwidth flex">
                                                <button className='xsmall button button--round button--danger flex' onClick={() => this.removeDocArrayData('payments', pi)}>
                                                    <div className="larger">X</div>
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            }
                            
                            <tr className="theme1bg">
                                <td className="noBorder" colSpan={4}>
                                    <Flex row className="justifystart allwidth bold">
                                        
                                        {
                                            paymentRemaining<0
                                            ? <>
                                                <BiError fill='red' size={20} />
                                                &nbsp;
                                                <i className="error">Over Max: {-paymentRemaining} US$ </i>
                                            </>
                                            : <>
                                                {paymentRemaining===0 ? <BsShieldCheck fill='teal' size={20} /> : null}
                                                &nbsp;
                                                <i>Remaining: {paymentRemaining}/{ttlArtistFees} US$</i>
                                            </>
                                        }
                                    </Flex>
                                    &nbsp;
                                    <button className='button button--primary button--round flex' onClick={() => this.addKeyItem('payments')}>
                                        <Row><span className="xlarge" style={{lineHeight: '20px'}}>+</span> &nbsp; ADD PAYMENT</Row>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>


                {/* TRANSPORTATION */}
                <label className="bold xlarge margedTop">TRANSPORTATION & DUTIES :</label>
                <div className="flex margedLeft allwidth">
                    <Row className='allwidth justifystart selfstart'>
                        <Flex row flex={5}>
                            {/* <LabelInput 
                                title='LANDED'
                                value={transportation?.type === 'LANDED'}
                                onChange={e => {
                                    this.setDocDataKey('transportation', { type: e.target.checked ? 'LANDED' : '' })
                                }}
                                type="checkbox"
                            /> */}
                            <CheckboxSpruha 
                                title='LANDED'
                                checked={transportation?.type === 'LANDED'}
                                onChange={e => {
                                    this.setDocDataKey('transportation', { type: e.target.checked ? 'LANDED' : '' })
                                }}
                            />
                            {/* <LabelInput 
                                title='PLUS FLIGHT'
                                value={transportation?.type === 'PLUS FLIGHT'}
                                onChange={e => this.setDocDataKey('transportation', { type: e.target.checked ? 'PLUS FLIGHT' : '' })}
                                type="checkbox"
                            /> */}
                            <CheckboxSpruha 
                                title='PLUS FLIGHT'
                                checked={transportation?.type === 'PLUS FLIGHT'}
                                onChange={e => this.setDocDataKey('transportation', { type: e.target.checked ? 'PLUS FLIGHT' : '' })}
                            />
                        </Flex>
                        <Flex flex={5}>

                            <LabelInput 
                                title='OTHER Transportation'
                                value={((transportation?.type !== 'PLUS FLIGHT') && (transportation?.type !== 'LANDED')) ? transportation?.type : ''}
                                onChange={e => this.setDocDataKey('transportation', { type: e.target.value })}
                                type="text"
                            />
                        </Flex>
                           
                    </Row>
                    
                    <Row>
                        <LabelInput 
                            title='Total Party People'
                            value={artist?.totalPartyPeople}  
                            onChange={e => this.setDocDataKey('artist', { totalPartyPeople: e.target.value })}
                            type="number"
                        />

                        <LabelInput 
                            title='Per Diem'
                            value={purchaser?.perDiem}
                            onChange={e => this.setDocDataKey('purchaser', { perDiem: e.target.value })}
                            type='number'
                        />
                    </Row>
                </div>
                
                <Row>

                    <Flex className='allwidth v-marged5'>
                        <button className="button button--primary flex row xlarge" onClick={this.props.onCancel}>
                            CANCEL
                            &nbsp;
                            <MdCancel size={20} />
                        </button>
                    </Flex>
                    <Flex className='allwidth v-marged5'>
                        <button className="button button--primary flex row xlarge" onClick={this.generatePdfContract}>
                            GENERATE CONTRACT
                            &nbsp;
                            <FaRegFilePdf size={20} />
                        </button>
                    </Flex>    
                </Row>
                
             
            </div>
        );
    }


}


// /**
//  * @dev LabelInput
//  *  TODO : move to /components ?
//  */
// const LabelInput = ({
//     title, value, onChange, className, inputClassName, type, noLabel, min, max, cell, inputStyle, placeholder
// }) => <Flex 
//     className={`${className || 'allwidth'}`} 
// >
//     {noLabel ? null : <label className="selfstart">{ title }</label>}
//     <input 
//         type={type || 'text'}
//         className={inputClassName || 'app-input width90 selfstart'}
//         placeholder={placeholder || title}
//         onChange={onChange}
//         value={value}
//         style={inputStyle||{}}
//         {...(min||max ? {min, max} : {})}
//         {...(type==='checkbox' ? {checked: value} : {})}
//     />
// </Flex>



export default LivePerformanceContractForm;