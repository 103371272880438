import styles from './mu-input.module.css'

 const LabelInput = (
    {
        title, 
        value, 
        onChange, 
        type, 
        min, 
        max, 
        disabled,
        textarea,
        rows,
        outlined,
        noLabel,
    } : {
        title?: string
        value: any
        onChange?: any
        type?: string
        min?: number|string
        max?: number|string
        disabled?: boolean
        textarea?: boolean
        rows?: number,
        outlined?: boolean,
        noLabel?: boolean,
    }
) => {
    const randomNumber = Math.random()
    const id = `${title}_${randomNumber}`

    return (
        <div className="allwidth">
            <div className={noLabel ? "" : styles.formGroup}>
            {textarea
            ?
                <textarea
                    id={id} 
                    className={outlined ? styles.outlinedFormField : styles.formField} 
                    placeholder={title}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    rows={rows || 6}
                />
            :   <input 
                    type={type || 'text'}
                    id={id} 
                    className={outlined ? styles.outlinedFormField : styles.formField} 
                    placeholder={title}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    {...(min||max ? {min, max} : {})}
                />}
                {!noLabel && <label htmlFor={id} className={outlined ? styles.outlinedFormLabel : styles.formLabel}>{title}</label>}
            </div>
        </div>
    )
}

export default LabelInput;