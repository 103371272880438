import { FaUserEdit } from "react-icons/fa";
import { MdOutlineRemoveModerator, MdPendingActions, MdTransferWithinAStation } from "react-icons/md";
import { HiOutlineShieldExclamation } from 'react-icons/hi'
import { BsTrophy } from "react-icons/bs";
import { GiBurningEmbers } from "react-icons/gi";
import { errorToSuccessTheme3, theme } from '../../../constants'
/* 
    Pending = "PDG",                // Pending
    RequestEdition = "RPE",         // Waiting Promotor Edition
    Withdrawn = "WTN",            // Withdrawn
    PromotorTrusteeCheck = "PTC",   // Waiting Promotor trustee check
    WaitingArtist = "WAT",          // Waiting Artist
    Accepted = "ACC",               // Accepted
    Deprecated = "DEP",             // Deprecated
*/
const offerStatus = {
    'PDG': {
        label: 'Pending',
        details: 'The offer has been stored and is waiting for a confirmation of Primuse team',
        icon: <MdPendingActions size={30} color={theme[0]} />
    },
    'ADD': {
        label: 'Denied By Admin',
        details: "The offer has been denied by the admin.",
        icon: <MdOutlineRemoveModerator size={30} color={theme[2]} />
    },
    'PER': {
        label: 'Waiting Promotor Edition',
        details: "Some parameters of the offer need to be modified (cf. reason below)",
        icon: <FaUserEdit size={30} color={theme[6]} />
    },
    'ADA': {
        label: 'Admin Super Accept',
        details: "The offer has been super accepted by the Admin",
        icon: <BsTrophy size={30} color={errorToSuccessTheme3[2]} />
    },
    'WTN': {
        label: 'Withdrawn', // DENIED
        details: 'The offer has been denied and withdrawn from the current offers list',
        icon: <MdOutlineRemoveModerator size={30} color={theme[2]} />
    },
    'PTC': {
        label: 'Waiting Promotor Trustee Check',
        details: 'The offer is waiting for trustee check confirmation',
        icon: <HiOutlineShieldExclamation size={30} color={theme[6]} />
    },
    'WAT': {
        label: 'Waiting Artist',
        details: "The offer has been transmitted to the artist and is now waiting for his response",
        icon: <MdTransferWithinAStation size={30} color={theme[3]} />
    },
    'ARA': {
        label: 'Accepted',
        details: 'The offer has been accepted by the artist',
        icon: <BsTrophy size={30} color={errorToSuccessTheme3[2]} />
    },
    'ARD': {
        label: 'Denied By Artist',
        details: 'The offer has been denied by the artist',
        icon: <MdOutlineRemoveModerator size={30} color={theme[2]} />
    },
    'DEP': {
        label: 'Deprecated',
        details: 'This offer is deprecated',
        icon: <GiBurningEmbers size={30} color={errorToSuccessTheme3[0]} />
    }
}
export default offerStatus;