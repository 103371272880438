import { ArtistWithTours } from "../../../utils/models/artists.model"
import CardItem from "./CardItem"

import styles from "./touring.module.css"

const CardScreen = ({touringArtists}: {touringArtists: ArtistWithTours[]}) => {

    const touringArtistsDisplay = touringArtists.map((artist, index) => <CardItem key={index} touringArtist={artist} />)
    return (
        <>
            {touringArtistsDisplay.length ?
                <div className={styles.cardsContainer}>
                {touringArtistsDisplay}
                </div>
            :   <p className="paddedTop50px">No artists are matching your selection.</p>}
        </>
    )
}

export default CardScreen