import { useSelector } from "react-redux";
import { fetchApiAuthentified } from "../utils";
import useHandleTokenExpired from "./useHandleTokenExpired";

const useFetchApiAuth = () => {
    const client = useSelector((state: any) => state.client);
    const handleTokenExpired = useHandleTokenExpired()

    const fetchApiAuth = fetchApiAuthentified(
        client.token, 
        handleTokenExpired
    );
    
    return fetchApiAuth
}

export default useFetchApiAuth