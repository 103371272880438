import { useRef } from "react"
// @ts-ignore
import { useScreenshot, createFileName } from 'use-react-screenshot'
import { AmountType, OfferShow, OfferShowFormInputs } from "../../../../utils/models/offer.model"
import { ls } from "../../../../utils/utils"
import TicketsTable from "../../../promotor/offers/form/pnl/tables/TicketsTable"
import computePnlData from "../../../../utils/hooks/offer/computePnlDatas"
import ViewRevenuesTable from "./tables/ViewRevenuesTable"
import ViewVariableCostsTable from "./tables/ViewVariableCostsTable"
import ArtistCostsTable from "../../../promotor/offers/form/pnl/tables/ArtistCostsTable"
import ViewFixedCostsTable from "./tables/ViewFixedCostsTable"
import BreakEvenPointBox from "../../../promotor/offers/form/pnl/other/BreakEvenPointBox"
import ProfitAndLossTable from "../../../promotor/offers/form/pnl/tables/ProfitAndLossTable"
import FixedIncomePieChart from "../../../promotor/offers/form/pnl/charts/FixedIncomePieChart"
import FixedCostPieChart from "../../../promotor/offers/form/pnl/charts/FixedCostPieChart"
import CostIncomePieChart from "../../../promotor/offers/form/pnl/charts/CostIncomePieChart"
import BreakEvenPointLinesChart from "../../../promotor/offers/form/pnl/charts/BreakEvenPointLinesChart"
import ProfitAndLossBarChart from "../../../promotor/offers/form/pnl/charts/ProfitAndLossBarChart"
import Tabs from "../../../../components/tabs-menu/Tabs"
import moment from "moment"
import { countries } from "../../../../utils/models/touring.model"

const ViewPNL = ({ show, rate, currencySymbol, artistName}: { 
    show: OfferShowFormInputs|OfferShow,
    rate: null|number,
    currencySymbol: null|string,
    artistName: string,
 }) => {

    const refPage1 = useRef(null)
    const refPage2 = useRef(null)
    const refPage3 = useRef(null)
    const refPage4 = useRef(null)
    
    const [image, takeScreenshot] = useScreenshot({
        type: "image/jpeg",
        quality: 1.0
    })

    const { tickets, ticketsVariables, localCurrency: currency, pnlInputs, dealInputs, hasOtherIncomes, otherIncomes, sponsored, sponsors, amountType } = show

    const { compsTickets, taxes } = ticketsVariables
    const { corporateTicketPurchase, variableCosts, fixedCosts } = pnlInputs
    const { witholdingTax: artistWitholdingTax, promotorDistributionPercent } = dealInputs
    
    const { vatPercent, ticketFeePercent, creditCardFeePercent, otherTaxesPercent } = taxes

    const isAmountDeal = amountType === AmountType.Deal

    const { 
        ticketPricesAfterVATArray, ticketNetPricesArray, compTicketsNumbersArray, ticketsForSaleArray, salesArray, totalTickets, pourcentagesArray ,
        ticketsSoldArray, ticketCollectionsArray, totalRevenueArray, sponsorAmount, otherIncomesAmount, corporateTicketAmount, totalVariableCostArray, guaranteedFee, 
        artistWitholdingTaxAmount, airFare, totalArtistCost, totalFixedCosts, totalExpenditureArray, profitsArray, artistProfitsArray, promotorProfitsArray, artistDistrubutionPercent
    } = computePnlData({show})




    const downloadScreenshot = async (ref: React.MutableRefObject<null>, pageName: string) => {
        const _image = await takeScreenshot(ref.current)
        const name = `PNL_show_${artistName}_${moment(show.date).format("D/M/yy")}_${pageName}`
        const extension = "jpg"
        const a = document.createElement("a");
        a.href = _image;
        a.download = createFileName(extension, name);
        a.click();

        // console.log(image)
        // const doc = new jsPDF();

        // const width = doc.internal.pageSize.getWidth()
        // const height = doc.internal.pageSize.getHeight()

        // doc.addImage(image, 'JPEG', 0, 0, width , height)
        // // doc.text("Hello world!", 10, 10);
        // doc.save("PNL.pdf");
    }


    

    return (
        <Tabs activeTab='Summary'>

            {/* Summary Page */}
            <div option-data='Summary'>
                <div className="width95 marged0auto paddedTop20px paddedBot20px" ref={refPage1} style={{backgroundColor: 'var(--app-background)'}}>

                    <div className="textAlignEnd margedBot10px">
                        <button className="button button--primary" onClick={() => downloadScreenshot(refPage1, "summary")}>Save Screenshot</button>
                    </div>

                    <div className="flex-align-center row space-between margedBot10px">
                        <div style={{width: "33%"}}></div>
                        <p style={{width: "33%"}}>Show with {artistName}</p>
                        <div style={{width: "33%"}}></div>
                    </div>

                    <div className="flex-align-center row space-between margedBot10px">
                        <div style={{width: "33%"}} className="leftalign">{show.venue.name} ({countries[show.country]})</div>
                        <div style={{width: "33%"}}>{moment(show.date).format('LL')}</div>
                        <div style={{width: "33%"}} className="textAlignEnd">{ls(totalTickets.quantity)} Tickets</div>
                    </div>

                    <p className="leftalign margedBot20px">Rate : US$1 = {currencySymbol ?? currency} {ls(rate)}</p>

                    <div className="flex-only row margedBot20px">
                        <div className="width50 margedRight20px">
                            <BreakEvenPointLinesChart
                                currency={currency}
                                currencySymbol={currencySymbol}
                                pourcentagesArray={pourcentagesArray}
                                totalRevenueArray={totalRevenueArray}
                                totalExpenditureArray={totalExpenditureArray}
                            />
                        </div>

                        <div className="width50">
                            <BreakEvenPointBox
                                currency={currency}
                                currencySymbol={currencySymbol}
                                show={show}
                            />
                        </div>


                    </div>

                    <div className="margedBot20px">
                        <ProfitAndLossTable
                            currency={currency}
                            currencySymbol={currencySymbol}
                            rate={rate}
                            pourcentagesArray={pourcentagesArray}
                            totalExpenditureArray={totalExpenditureArray}
                            profitsArray={profitsArray}
                            ticketsSoldArray={ticketsSoldArray}
                            artistProfitsArray={artistProfitsArray}
                            promotorProfitsArray={promotorProfitsArray}
                            artistDistrubutionPercent={artistDistrubutionPercent}
                            promotorDistributionPercent={promotorDistributionPercent}
                            isAmountDeal={isAmountDeal}
                        />
                    </div>

                    <div className="flex-only row margedBot20px">
                        <div className="margedRight20px">
                            <FixedIncomePieChart
                                currency={currency}
                                currencySymbol={currencySymbol}
                                hasOtherIncomes={hasOtherIncomes}
                                otherIncomes={otherIncomes}
                                sponsored={sponsored}
                                sponsors={sponsors}
                                corporateTicketPurchase={corporateTicketPurchase}
                            />
                        </div>

                        <div className="margedRight20px">
                            <FixedCostPieChart
                                currency={currency}
                                currencySymbol={currencySymbol}
                                totalArtistCost={totalArtistCost}
                                fixedCosts={fixedCosts}
                            />
                        </div>

                        <div className="margedRight20px">
                            <CostIncomePieChart
                                currency={currency}
                                currencySymbol={currencySymbol}
                                totalArtistCost={totalArtistCost}
                                totalFixedCosts={totalFixedCosts}
                                sponsorAmount={sponsorAmount}
                                otherIncomesAmount={otherIncomesAmount}
                                corporateTicketAmount={corporateTicketAmount}
                            />
                        </div>
                    </div>
                        
                    <div className="width50">
                        <ProfitAndLossBarChart
                            currency={currency}
                            currencySymbol={currencySymbol}
                            pourcentagesArray={pourcentagesArray}
                            totalRevenueArray={totalRevenueArray}
                            totalExpenditureArray={totalExpenditureArray}
                            profitsArray={profitsArray}
                        />
                    </div>
                </div>
            </div>

            {/* Costs Page */}
            <div option-data='Costs'>
                <div className="width95 marged0auto paddedTop20px paddedBot20px" ref={refPage2} style={{backgroundColor: 'var(--app-background)'}}>

                    <div className="textAlignEnd margedBot20px">
                        <button className="leftalign button button--primary" onClick={() => downloadScreenshot(refPage2, "costs")}>Save Screenshot</button>
                    </div>

                    {/* Costs */}
                    <h3 className="margedTop20px margedBot20px">Costs</h3>

                    <div className="margedBot20px">
                        <ViewVariableCostsTable
                            currency={currency}
                            currencySymbol={currencySymbol}
                            rate={rate}
                            pourcentagesArray={pourcentagesArray}
                            variableCosts={variableCosts}
                            totalVariableCostArray={totalVariableCostArray}
                            totalRevenueArray={totalRevenueArray}
                        />
                    </div>

                    <div className="width50 margedBot20px">
                        <ArtistCostsTable
                            currency={currency}
                            currencySymbol={currencySymbol}
                            rate={rate}
                            guaranteedFee={guaranteedFee}
                            artistWitholdingTax={artistWitholdingTax}
                            artistWitholdingTaxAmount={artistWitholdingTaxAmount}
                            airFare={airFare}
                            totalArtistCost={totalArtistCost}
                        />
                    </div>

                    <div className="width50 margedBot20px">
                        <ViewFixedCostsTable
                            currency={currency}
                            currencySymbol={currencySymbol}
                            rate={rate}
                            fixedCosts={fixedCosts}
                            totalFixedCosts={totalFixedCosts}
                        />
                    </div>
                </div>
            </div>

            {/* Incomes Page */}
            <div option-data='Incomes'>

                <div className="width95 marged0auto paddedTop20px paddedBot20px" ref={refPage3} style={{backgroundColor: 'var(--app-background)'}}>

                    <div className="textAlignEnd margedBot20px">
                        <button className="leftalign button button--primary" onClick={() => downloadScreenshot(refPage3, "incomes")}>Save Screenshot</button>
                    </div>

                    {/* TICKETING */}
                    <h3 className="margedBot20px">Ticketing and other incomes</h3>

                    <div className="margedBot20px">
                        <TicketsTable 
                            tickets={tickets}
                            currency={currency}
                            currencySymbol={currencySymbol}
                            rate={rate}
                            VAT={vatPercent}
                            ticketFee={ticketFeePercent}
                            creditCardTax={creditCardFeePercent}
                            otherTaxes={otherTaxesPercent}
                            compTickets={compsTickets}
                            ticketPricesAfterVATArray={ticketPricesAfterVATArray}
                            ticketNetPricesArray={ticketNetPricesArray}
                            compTicketsNumbersArray={compTicketsNumbersArray}
                            ticketsForSaleArray={ticketsForSaleArray}
                            salesArray={salesArray}
                            totalTickets={totalTickets}
                        />
                    </div>


                    {/* Revenues */}
                    <h3 className="margedTop20px margedBot20px">Revenues</h3>

                    <div className="margedBot20px">
                        <ViewRevenuesTable 
                            currency={currency}
                            currencySymbol={currencySymbol}
                            rate={rate}
                            pourcentagesArray={pourcentagesArray}
                            ticketsSoldArray={ticketsSoldArray}
                            ticketCollectionsArray={ticketCollectionsArray}
                            totalRevenueArray={totalRevenueArray}
                            sponsorAmount={sponsorAmount}
                            otherIncomesAmount={otherIncomesAmount}
                            corporateTicketAmount={corporateTicketAmount}
                            corporateTicketPurchase={corporateTicketPurchase}
                        />
                    </div>
                </div>
            </div>

            <div option-data='Global'>
                <div className="width95 marged0auto paddedTop20px paddedBot20px" ref={refPage4} style={{backgroundColor: 'var(--app-background)'}}>

                    <div className="textAlignEnd margedBot20px">
                        <button className="leftalign button button--primary" onClick={() => downloadScreenshot(refPage4, "global")}>Save Screenshot</button>
                    </div>

                    <div className="flex-align-center row space-between margedBot10px">
                        <div style={{width: "33%"}}></div>
                        <p style={{width: "33%"}}>Show with {artistName}</p>
                        <div style={{width: "33%"}}></div>
                    </div>

                    <div className="flex-align-center row space-between margedBot10px">
                        <div style={{width: "33%"}} className="leftalign">{show.venue.name} ({countries[show.country]})</div>
                        <div style={{width: "33%"}}>{moment(show.date).format('LL')}</div>
                        <div style={{width: "33%"}} className="textAlignEnd">{ls(totalTickets.quantity)} Tickets</div>
                    </div>

                    <p className="leftalign margedBot20px">Rate : US$1 = {currencySymbol ?? currency} {ls(rate)}</p>

                    {/* TICKETING */}
                    <h3 className="margedBot20px">Ticketing and other incomes</h3>

                    <div className="margedBot20px">
                        <TicketsTable 
                            tickets={tickets}
                            currency={currency}
                            currencySymbol={currencySymbol}
                            rate={rate}
                            VAT={vatPercent}
                            ticketFee={ticketFeePercent}
                            creditCardTax={creditCardFeePercent}
                            otherTaxes={otherTaxesPercent}
                            compTickets={compsTickets}
                            ticketPricesAfterVATArray={ticketPricesAfterVATArray}
                            ticketNetPricesArray={ticketNetPricesArray}
                            compTicketsNumbersArray={compTicketsNumbersArray}
                            ticketsForSaleArray={ticketsForSaleArray}
                            salesArray={salesArray}
                            totalTickets={totalTickets}
                        />
                    </div>


                    {/* Revenues */}
                    <h3 className="margedTop20px margedBot20px">Pofit and Loss</h3>

                    <div className="margedBot20px">
                        <ViewRevenuesTable 
                            currency={currency}
                            currencySymbol={currencySymbol}
                            rate={rate}
                            pourcentagesArray={pourcentagesArray}
                            ticketsSoldArray={ticketsSoldArray}
                            ticketCollectionsArray={ticketCollectionsArray}
                            totalRevenueArray={totalRevenueArray}
                            sponsorAmount={sponsorAmount}
                            otherIncomesAmount={otherIncomesAmount}
                            corporateTicketAmount={corporateTicketAmount}
                            corporateTicketPurchase={corporateTicketPurchase}
                        />
                    </div>

                    <div className="margedBot20px">
                        <ViewVariableCostsTable
                            currency={currency}
                            currencySymbol={currencySymbol}
                            rate={rate}
                            pourcentagesArray={pourcentagesArray}
                            variableCosts={variableCosts}
                            totalVariableCostArray={totalVariableCostArray}
                            totalRevenueArray={totalRevenueArray}
                        />
                    </div>

                    {/* Costs */}
                    <div className="width50 margedBot20px">
                        <ArtistCostsTable
                            currency={currency}
                            currencySymbol={currencySymbol}
                            rate={rate}
                            guaranteedFee={guaranteedFee}
                            artistWitholdingTax={artistWitholdingTax}
                            artistWitholdingTaxAmount={artistWitholdingTaxAmount}
                            airFare={airFare}
                            totalArtistCost={totalArtistCost}
                        />
                    </div>

                    <div className="width50 margedBot20px">
                        <ViewFixedCostsTable
                            currency={currency}
                            currencySymbol={currencySymbol}
                            rate={rate}
                            fixedCosts={fixedCosts}
                            totalFixedCosts={totalFixedCosts}
                        />
                    </div>

                    {/* Charts */}
                    <div className="flex-only margedBot20px">
                        <BreakEvenPointBox
                            currency={currency}
                            currencySymbol={currencySymbol}
                            show={show}
                        />
                    </div>

                    <div className="margedBot20px">
                        <ProfitAndLossTable
                            currency={currency}
                            currencySymbol={currencySymbol}
                            rate={rate}
                            pourcentagesArray={pourcentagesArray}
                            totalExpenditureArray={totalExpenditureArray}
                            profitsArray={profitsArray}
                            ticketsSoldArray={ticketsSoldArray}
                            artistProfitsArray={artistProfitsArray}
                            promotorProfitsArray={promotorProfitsArray}
                            artistDistrubutionPercent={artistDistrubutionPercent}
                            promotorDistributionPercent={promotorDistributionPercent}
                            isAmountDeal={isAmountDeal}
                        />
                    </div>

                    <div className="flex-only row margedBot20px">
                        <div className="margedRight20px">
                            <FixedIncomePieChart
                                currency={currency}
                                currencySymbol={currencySymbol}
                                hasOtherIncomes={hasOtherIncomes}
                                otherIncomes={otherIncomes}
                                sponsored={sponsored}
                                sponsors={sponsors}
                                corporateTicketPurchase={corporateTicketPurchase}
                            />
                        </div>

                        <div className="margedRight20px">
                            <FixedCostPieChart
                                currency={currency}
                                currencySymbol={currencySymbol}
                                totalArtistCost={totalArtistCost}
                                fixedCosts={fixedCosts}
                            />
                        </div>

                        <div className="margedRight20px">
                            <CostIncomePieChart
                                currency={currency}
                                currencySymbol={currencySymbol}
                                totalArtistCost={totalArtistCost}
                                totalFixedCosts={totalFixedCosts}
                                sponsorAmount={sponsorAmount}
                                otherIncomesAmount={otherIncomesAmount}
                                corporateTicketAmount={corporateTicketAmount}
                            />
                        </div>
                    </div>
                    
                    <div className="flex-only row">
                        <div className="width50 margedRight20px">
                                <BreakEvenPointLinesChart
                                    currency={currency}
                                    currencySymbol={currencySymbol}
                                    pourcentagesArray={pourcentagesArray}
                                    totalRevenueArray={totalRevenueArray}
                                    totalExpenditureArray={totalExpenditureArray}
                                />
                        </div>

                        <div className="width50">
                            <ProfitAndLossBarChart
                                currency={currency}
                                currencySymbol={currencySymbol}
                                pourcentagesArray={pourcentagesArray}
                                totalRevenueArray={totalRevenueArray}
                                totalExpenditureArray={totalExpenditureArray}
                                profitsArray={profitsArray}
                            />
                        </div>
                    </div>

                    
                </div>
            </div>
            
        </Tabs>
    )
}

export default ViewPNL