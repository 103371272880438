import Tabs from "../../../../components/tabs-menu/Tabs"
import { useAppSelector } from "../../../../utils/hooks/reduxTypedHooks"
import OffersTab from "./OffersTab"

import AdminRequests from "./requests/AdminRequests"
import AdminFeedbacks from "./feedback/AdminFeedbacks"
import { OfferStatus } from "../../../../utils/models/offer.model"
import AdminTourRequests from "./tour-requests/AdminTourRequests"


// const hasBinderToSigned = (binderLetter: any) => (
//     (binderLetter?.data?.signatures?.length===1)
//     && (binderLetter?.data?.signatures[0].licence === Licence.Promotor)   // admin signature requested
// )

const AdminOffers = () => {

    const offers = useAppSelector(state => state.offers);

    const ongoingStatus = [OfferStatus.Pending, OfferStatus.PromotorTrusteeCheck]
    const inProcessStatus = [OfferStatus.WaitingArtist, OfferStatus.RequestEdition]
    const pastStatus = [OfferStatus.AdminDenied, OfferStatus.Deprecated, OfferStatus.Withdrawn, OfferStatus.AdminAccepted, OfferStatus.ArtistAccepted, OfferStatus.ArtistDenied]
    
    const ongoingOffers = offers.filter(offer => ongoingStatus.includes(offer.status))
    const inProcessOffers = offers.filter(offer => inProcessStatus.includes(offer.status))
    const pastOffers = offers.filter(offer => pastStatus.includes(offer.status))

    return(
        <Tabs activeTab='Action needed'>
            <div option-data='Action needed' data-icon='ic:baseline-pending-actions'>
                <OffersTab offers={ongoingOffers} ongoing title="Ongoing" />
            </div>
            <div option-data='In process' data-icon='uim:process'>
                <OffersTab offers={inProcessOffers} title="In Process" />
            </div>
            <div option-data='past' data-icon='wpf:past'>
                <OffersTab offers={pastOffers} title="Past" />
            </div>
            <div option-data='requests' data-icon='humbleicons:user-asking'>
                <AdminRequests />
            </div>
            <div option-data='tour requests' data-icon='humbleicons:user-asking'>
                <AdminTourRequests />
            </div>
            <div option-data='feedbacks' data-icon='gg:notes'>
                <AdminFeedbacks />
            </div>
        </Tabs>
    )
}

export default AdminOffers