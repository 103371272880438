import moment from "moment"
import { GiFireShield } from "react-icons/gi"
import { accessLevels, errorToSuccessTheme3 } from "../../../../../constants"
import { useAppSelector } from "../../../../../utils/hooks/reduxTypedHooks"
import { RequestObject } from "./AdminTourRequests"
import { countries } from "../../../../../utils/models/touring.model"

const TourRequestItemRow = ({ requestObject }: {requestObject: RequestObject}) => {
    
    const { touring, request } = requestObject

    const { datesRequested, showsAmounts, dateCreated, showsCountries, showsVenues  } = request

    const promotors = useAppSelector(state => state.promotors)
    const promotor = promotors.find(promotor => promotor.id === request.promotorId)

    const artists = useAppSelector(state => state.artists)
    const foundArtist = artists.find(artist => artist.id === touring.artistId)

    const dateCreatedDisplay = moment(new Date(dateCreated)).format("MM/D/YYYY h:mm A")
    const artistName = foundArtist?.displayableName

    // const numberOfShows = touring.shows.length
    // const touringFromDate = moment(new Date(touring.shows[0].date)).format("ddd D MMM YYYY")
    // const touringToDate = moment(new Date(touring.shows[numberOfShows - 1].date)).format("ddd D MMM YYYY")

    const datesDisplay = datesRequested.map((dateRequested, index) => `${moment(new Date(dateRequested)).format("ddd D MMM")} for US$ ${showsAmounts[index]} (${countries[showsCountries[index]]} : ${showsVenues[index] || "Unknown"})`)

    return (
        <tr>
            <th className="fw400 leftalign">{dateCreatedDisplay}</th>
            <th className="leftalign">{artistName ?? "Artist not found"}</th>
            {/* <th className="leftalign">US$ {totalAmount}</th> */}
            {/* <th className="fw400 leftalign">{touringFromDate}</th>
            <th className="fw400 leftalign">{touringToDate}</th> */}
            <th className="leftalign">{datesDisplay.join(' // ')}</th>
            {promotor
            ?   <>
                    <th className="leftalign">{promotor.name}</th>
                    <th>{promotor?.accessLevel && 
                            <div className="flex-align-center row bold">
                                <GiFireShield size={25} color={errorToSuccessTheme3[promotor.accessLevel - 1]} /> {accessLevels.promotor[promotor.accessLevel]}
                            </div>}
                    </th>
                    <th className="fw400 leftalign">{promotor.email}</th>
                    <th className="fw400 leftalign">{promotor.phone}</th>
                    <th className="fw400">{promotor.contracts?.length ?? 0}</th>
                </>
            :   <>
                    <th>Not Found</th>
                    <th>Not Found</th>
                    <th>Not Found</th>
                    <th>Not Found</th>
                </>
            }
        </tr>
    )
}

export default TourRequestItemRow