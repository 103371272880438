import { NavLink, useNavigate } from "react-router-dom"
import { ROUTE_HOME } from "../routes/routes.const"

const NotFoundPage = () => {

    const navigate = useNavigate()

    const handleGoBack = () => {
        navigate(-1)
    }

    return(
        <div className="flex allspace">
            <h1 style={{marginBottom:'20px'}}>404</h1>
            <h4 style={{marginBottom:'10px'}}>Page not found.</h4>
            <div>You can either <button className="button" onClick={handleGoBack}>Go back</button> or <NavLink to={ROUTE_HOME}>Go to the home page</NavLink></div>
        </div>
    )
}

export default NotFoundPage