import { Flex, Row } from "../../../../../components/Containers";
import { useAppDispatch, useAppSelector } from "../../../../../utils/hooks/reduxTypedHooks";
import useFetchApiAuth from "../../../../../utils/hooks/useFetchApiAuth";
import { BiLayerPlus } from "react-icons/bi";
import PromotorForm from "../PromotorForm";
import { hostImage } from "../../../../../utils/utils";
import { snackActions } from "../../../../../store/snackSlice";
import { promotorsActions } from "../../../../../store/promotorsSlice";
import { useLocation } from "react-router-dom";
import useSetSnack from "../../../../../utils/hooks/useSetSnack";
import useFilter from "../../../../../utils/hooks/useFilter";
import { Promotor, PromotorCreateObject, PromotorFormInputs } from "../../../../../utils/models/promotors.model";
import { useState } from "react";
import PromotorsTable from "./table/PromotorsTable";
import { Licence } from "../../../../../utils/models/client.model";
import { usersActions } from "../../../../../store/usersSlice";


const ActivePromotorsList = ( { promotors, setOpenedPromotor, setOpenPromotor }: {
    promotors: Promotor[],
    setOpenedPromotor: React.Dispatch<React.SetStateAction<Promotor | null>>,
    setOpenPromotor: React.Dispatch<React.SetStateAction<boolean>>,
}) => {

    const [isPosting, setIsPosting] = useState(false);
    const [usePromotor, setUsePromotor] = useState<Promotor | null>(null);
    const [selectedLogoFile, setSelectedLogoFile] = useState('')
    const [activeTab, setActiveTab] = useState('find');
    
    const location = useLocation();
    const client = useAppSelector(state => state.client);

    const dispatch = useAppDispatch()
    const setSnack = useSetSnack()
    const fetchApiAuth = useFetchApiAuth()

    const {FilterUI, filterInput} = useFilter("Promotor id, name, location...", location?.state?.searchValue||'')
    
    const filteredPromotors = promotors.filter(promotor => {
        return JSON.stringify(promotor).toLowerCase().includes(filterInput?.toLowerCase());
    });

    // const openForm = () => setFormVisible(true);
    const closeForm = () => {
        setUsePromotor(null);
        setActiveTab('find')
    }

    const validateForm = (formInputs: PromotorFormInputs) => {
        if(!formInputs.name) {
            setSnack({type: "error", content: "Please fill the name field"})
            return false
        }
        
        if(!formInputs.accessLevel?.value) {
            setSnack({type: "error", content: "Please put a licence for the promotor"})
            return false
        }

        if(formInputs.createNewAccount) {
            if(!formInputs.newAccountEmail) {
                setSnack({type: "error", content: "If you want to create a new account please fill the account email"})
                return false
            }
        }

        return true
    }

    const storeNewPromotor = async(newPromotor: PromotorFormInputs) => {
        setIsPosting(true)
        // set `b64Logo` as buffer before upload
        // POST /host-image
        const {createNewAccount, logo: b64Logo, ...promotor} = newPromotor;
        console.log('NEW PROMOTOR', newPromotor)

        const isFormValid = validateForm(newPromotor)
        if(!isFormValid) { 
            setIsPosting(false)
            return 
        }

        const newUser = {
            active: true,
            email: promotor.newAccountEmail,
            licence: Licence.Promotor,
            isArchived: false,
        }

        const promotorBody: PromotorCreateObject = {
            ...promotor, 
            accessLevel: promotor.accessLevel.value,
            isArchived: false,
        }

        // POST /create-promotor
        const { error, response, status } = await fetchApiAuth({
            method: 'POST',
            route: 'create-promotor',
            body: { promotor: promotorBody, createNewAccount, newUser }  
        });

        dispatch(
            snackActions.set(
                status===200
                ? {type: 'success', content: 'The new promotor has been created'}
                : {type: 'error', content: `Error while creating a new promotor: ${error}`}
            )
        );

        if (!error) {
            dispatch(promotorsActions.add(response.promotor))
            if(response.user) {
                dispatch(usersActions.add(response.user))
            }
            closeForm()
        }


        // request promotor cration with `createNewAccount` option if set
        
        setIsPosting(false)
        return true;
    }

    const mergePromotor = async(upPromotor:any) => {
        setIsPosting(true)
        const {logo, ...promotor} = upPromotor;
        console.log('UPDATED PROMOTOR', upPromotor)

        const isFormValid = validateForm(upPromotor)
        if(!isFormValid) {
            setIsPosting(false)
            return 
        }

        // set `logo` as buffer before upload
        // POST /host-image
        const hostedLogo = await new Promise(async(_withLogoProm) => {
            console.log("log", logo)
            if (logo && logo?.includes('data:image')) {
                const {response: hLogo}:any = await hostImage(selectedLogoFile, client?.token);  // selectedLogoFile
                _withLogoProm(hLogo);
            }
            else _withLogoProm(logo);
        })

        const promotorBody: Promotor = {...promotor, accessLevel: promotor.accessLevel.value}
        // POST /create-promotor
        const { error, response, status } = await fetchApiAuth({
            method: 'POST',
            route: 'merge-promotor',
            body: {...promotorBody, logo: hostedLogo} 
        });


        dispatch(
            snackActions.set(
                status===200
                ? {type: 'success', content: 'The promotor has been updated'}
                : {type: 'error', content: `Error while updating the promotor: ${error}`}
            )
        );

        if (!error) {
            closeForm();
            dispatch(
                promotorsActions.merge(response)
            )
        }
        // request promotor creation with `createNewAccount` option if set

        setIsPosting(false)
        return true;
    }


    return (
        <Flex flex={10} className='allwidth justifystart'>

            {
                activeTab === 'create'
                ? <Flex className="allspace">
                    <PromotorForm 
                        usePromotor={usePromotor}
                        isPosting={isPosting}
                        mergePromotor={mergePromotor}
                        storeNewPromotor={storeNewPromotor}
                        setSelectedLogoFile={setSelectedLogoFile}
                        closeForm={() => setActiveTab('find')}
                    />
                </Flex>
                : <div className="main-container">
                    <h3 className="page-title margedTop30px">Promotors</h3>
                    <Row className="allwidth margedBot30px">
                        <Flex row flex={8}>
                            <div className="allwidth">{FilterUI}</div>
                        </Flex>
                        <Flex flex={2}>
                            <button 
                                className="flex row button button--primary uppercase"
                                onClick={() => {
                                    setUsePromotor(null)
                                    setActiveTab('create')
                                }}
                            >
                                <BiLayerPlus size={25} />
                                &nbsp;
                                New Promotor
                            </button>
                        </Flex>
                    </Row>
                    <Flex flex={10} className="allspace justifystart">
                        <PromotorsTable 
                            promotors={filteredPromotors} 
                            setOpenedPromotor={setOpenedPromotor}
                            setOpenPromotor={setOpenPromotor}
                            setUsePromotor={setUsePromotor}
                            setActiveTab={setActiveTab}
                        />
                    </Flex>
                </div>

            }
            
        
        </Flex>
    )
}

export default ActivePromotorsList