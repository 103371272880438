import { Artist, ArtistAPIDatas, ArtistMarcoDB, ArtistMinifiedStreamingDatas, BandsintownEvent, NormalizedArtistMarcoDB, SeatgeekEvent, StandardizedEvent } from "./models/artists.model"
import { countries } from "./models/touring.model"

export const filterArtistAllFields = (artistsList: Artist[], filterInput: string) => {
    return artistsList.filter(artist => {
        const lowerCFilter = filterInput.toLowerCase()
        const match = JSON.stringify(artist).toLowerCase().includes(lowerCFilter)
        return match
    })
}

export const formatDisplayableNameToUrl = (displayableName: string) => {
    return displayableName.replace(/\s+/g, '-').toLowerCase()
}
export const formatDisplayableNameIntoSeatGeekSlug = (displayableName: string) => {
    return displayableName.replace(/-/g, '%20')
}
export const formatUrlNameIntoName = (urlName: string) => {
    return urlName.replace(/-/g, ' ')
}
export const formatDisplayableNameIntoNameQuery = (displayableName: string) => {
    return displayableName.replace(/\s+/g, '%20').toLowerCase()
}
export const formatDisplayableNameIntoTiktokName = (displayableName: string) => {
    return displayableName.replace(/\s+/g, '').toLowerCase()
}

export const standardizeBandsITEvent = (bandsintownEvent: BandsintownEvent) => {
    const countryCode = formatCountryToCountryCode(bandsintownEvent.venue.country)
    return {
        ...bandsintownEvent, 
        datetime_local: bandsintownEvent.datetime, 
        stats: undefined,
        venue: {
            ...bandsintownEvent.venue,
            country: countryCode,
        }
    }
}
export const standardizeBandsITEvents = (bandsintownEvents: BandsintownEvent[]) => {
    return bandsintownEvents.map(event => standardizeBandsITEvent(event))
}

export const filterDuplicatesSeatgeekEvents = (seatgeekEvents: SeatgeekEvent[]) => {
    const uniqueEvents: SeatgeekEvent[] = []

    seatgeekEvents.forEach(event => {
        if(uniqueEvents.findIndex(uniqueEvent => uniqueEvent.datetime_local === event.datetime_local) === -1) {
            uniqueEvents.push(event)
        }
    })
    return uniqueEvents
}
// sometime 'country' is not in the countrycode format
export const standardizeSeatgeekEvents = (seatgeekEvents: SeatgeekEvent[]) => {
    const standardizedEvents = seatgeekEvents.map(event => {
        const countryCode = formatCountryToCountryCode(event.venue.country)
        return {
            ...event, 
            venue: {
                ...event.venue,
                country: countryCode,
            }
        }
    })
    return standardizedEvents
}

//! seatgeekEvents first param
export const filterUniqueEventsAndStandardize = (seatgeekEvents: SeatgeekEvent[], bandsintownEvents: BandsintownEvent[]) => {
    //starting with seatgeek events because if we have the event in both we take it from Seatgeek to have the average prices
    const uniqueEvents: (SeatgeekEvent|StandardizedEvent)[] = standardizeSeatgeekEvents(seatgeekEvents)

    bandsintownEvents.forEach(event => {
        if(seatgeekEvents.findIndex(seatgeekEvent => seatgeekEvent.datetime_local.substring(0,10) === event.datetime.substring(0,10)) === -1) {
            const standardizedEvent = standardizeBandsITEvent(event)
            uniqueEvents.push(standardizedEvent)
        }
    })
    const sortedUniqueEvents = uniqueEvents.slice().sort((eventA, eventB) => {
        const timeEventA = new Date(eventA.datetime_local).getTime()
        const timeEventB = new Date(eventB.datetime_local).getTime()
        return timeEventA - timeEventB
    })
    return sortedUniqueEvents
}

export const formatCountryToCountryCode = (country: string) => {
    const countryCode = Object.keys(countries).find(code => countries[code] === country)
    return countryCode ?? country
}

export const computeArtistTotalFollowers = (datas: ArtistAPIDatas) => {
    const { twitter, spotify, deezer, lastfm, bandsintown, genius, youtube, facebook, instagram, tiktok } = datas
    const spotifyFollowers = spotify?.artist?.followers.total
    const totalFollowers = (twitter?.followers ?? 0) + (spotifyFollowers ?? 0) + (deezer?.followers ?? 0) + (lastfm?.listeners ? +lastfm.listeners : 0) + (bandsintown?.followers ?? 0) + (genius?.followers ?? 0) + (facebook?.followers ?? 0) + (instagram?.followers ?? 0) + (youtube?.followers ?? 0) + (tiktok?.followers ?? 0)
    return totalFollowers
}

export const standardizeArtistMarcoDB = (artist: ArtistMarcoDB) => {
    const facebookId = artist.platforms_id?.facebook
    const twitterId = artist.platforms_id?.twitter
    const instagramId = artist.platforms_id?.instagram
    const youtubeId = artist.platforms_id?.youtube
    const spotifyId = artist.platforms_id?.spotify
    const deezerId = artist.platforms_id?.deezer
    const lastId = artist.platforms_id?.lastfm
    const appleId = artist.platforms_id?.apple
    const geniusId = artist.platforms_id?.genius

    const standardizedSocials = {
        facebook: {
            id: facebookId,
            url: facebookId ? `https://www.facebook.com/${facebookId}` : ''
        },
        twitter: {
            id: twitterId,
            url: twitterId ? `https://twitter.com/${twitterId}` : ''
        },
        instagram: {
            id: instagramId,
            url: instagramId ? `https://www.instagram.com/${instagramId}` : ''
        },
        youtube: {
            id: youtubeId,
            url: youtubeId ? `https://www.youtube.com/user/${youtubeId}` : ''
        }
    }

    const standardizedStreamingPlatforms = {
        spotify: {
            id: spotifyId,
            url: spotifyId ? `https://open.spotify.com/artist/${spotifyId}` : ''
        },
        deezer: {
            id: deezerId,
            url: deezerId ? `https://www.deezer.com/artist/${deezerId}` : ''
        },
        last: {
            fm: {
                id: lastId,
                url: lastId ? `https://www.last.fm/music/${lastId}` : ''
            }
        },
        apple: {
            id: appleId ? `id${appleId}` : undefined,
            url: appleId ? `https://itunes.apple.com/us/artist/id${appleId}` : ''
        },
        genius: {
            id: geniusId,
            url: geniusId ? `https://genius.com/artists/${geniusId}` : ''
        },
    }

    const countryCode = formatCountryToCountryCode(artist.area?.area_name)

    const standardized: NormalizedArtistMarcoDB = {
        ...artist, 
        displayableName: artist.name, 
        country: countryCode,
        bio: artist.bio_summary,
        socials: standardizedSocials,
        // @ts-ignore last.fm
        streamingPlatforms: standardizedStreamingPlatforms,
        image: '',
        genres: [],
        website: '',
        banner: '',
        logo: '',
    }
    return standardized
}

export const minifyArtistStreamingDatas = (datas: ArtistAPIDatas) => {
    const { instagram, facebook, twitter, tiktok, deezer, lastfm, bandsintown, spotify, genius, youtube, totalFollowers } = datas

    const minifiedDatas: ArtistMinifiedStreamingDatas = {
        instagram,
        twitter,
        deezer,
        genius,
        youtube,
        facebook,
        tiktok,
        lastfm,
        totalFollowers,
        bandsintown: {
            followers: bandsintown?.followers,
            url: bandsintown?.url
        },
        spotify: {
            artist: {
                external_urls: {
                    spotify: spotify?.artist?.external_urls?.spotify
                },
                followers: {
                    total: spotify?.artist?.followers?.total
                },
                image: { url: spotify?.artist?.images?.[0].url }
            },
            latestAlbum: {
                url: spotify?.albums?.[0].external_urls.spotify,
                image: spotify?.albums?.[0].images?.[0].url,
                name: spotify?.albums?.[0].name,
                total_tracks: spotify?.albums?.[0].total_tracks,
                release_date: spotify?.albums?.[0].release_date,
            },
            topTrackUs: {
                album: {
                    image: { url: spotify?.topTracksUS?.[0].album?.images?.[0].url },
                    release_date: spotify?.topTracksUS?.[0].album?.release_date
                },
                name: spotify?.topTracksUS?.[0].name,
                preview_url: spotify?.topTracksUS?.[0].preview_url
            },
        }
    }
    
    return minifiedDatas
}