import { useEffect, useState } from "react"
import { offerDraftsActions } from "../../../store/offerDraftsSlice"
import { useAppDispatch, useAppSelector } from "../reduxTypedHooks"
import useFetchApiAuth from "../useFetchApiAuth"
import useSetSnack from "../useSetSnack"

const useFetchPromotorDrafts = () => {

    const [isFetchingDrafts, setIsFetching] = useState(false)
    const client = useAppSelector(state => state.client)
    const drafts = useAppSelector(state => state.offerDrafts)
    const dispatch = useAppDispatch()
    const fetchApiAuth = useFetchApiAuth()
    const setSnack = useSetSnack()

    useEffect(() => {
        const fetchDrafts = async () => {
            setIsFetching(true)
            const promotorId = client?.promotor?.id

            const { error, response: draftsList } = await fetchApiAuth({
                method: 'GET',
                route: `get-promotor-offer-drafts?promotorId=${promotorId}`,
            })

            console.log('draft list', draftsList)

            setSnack(
                error
                ? {
                    type: 'error', 
                    content: error.toString()
                }
                : {
                    type: 'success',
                    content: 'Drafts list fetched successfully.'
                }
            )

            if(!error)
                dispatch(offerDraftsActions.set(draftsList))

            setIsFetching(false)
            }
        
        // only fetch drafts once per session
        if(drafts === null) {
            fetchDrafts()
        }
    }, [])

    return { isFetchingDrafts, drafts }
    
}

export default useFetchPromotorDrafts