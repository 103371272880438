import { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import Dialog from "../../../../../components/dialog/Dialog"
import { ROUTE_ARTISTS, ROUTE_CONTRACTS, ROUTE_PROMOTORS, ROUTE_TOURING } from "../../../../../routes/routes.const"
import { Artist } from "../../../../../utils/models/artists.model"
import ArtistForm from "./ArtistForm"
import { createPortal } from "react-dom"
import { BiArchiveIn, BiArchiveOut, BiCalendarPlus, BiEdit } from "react-icons/bi"
import { HiOutlineDocumentPlus } from "react-icons/hi2"
import { Flex, Row } from "../../../../../components/Containers"
import { AiOutlineFolderOpen } from "react-icons/ai"
import { BsThreeDots } from "react-icons/bs"
import ReactCountryFlag from "react-country-flag"
import { countries } from "../../../../../utils/models/touring.model"
import { MdClose, MdEmojiPeople } from "react-icons/md"
import { formatDisplayableNameToUrl } from "../../../../../utils/artists.util"
import useFetchApiAuth from "../../../../../utils/hooks/useFetchApiAuth"
import useSetSnack from "../../../../../utils/hooks/useSetSnack"
import { useAppDispatch } from "../../../../../utils/hooks/reduxTypedHooks"
import { artistsActions } from "../../../../../store/artistsSlice"
import { colors } from "../../../../../constants"
import { archivedArtistsActions } from "../../../../../store/archivedArtistsSlice"

const ArtistItemRow = ({ artist, isArchivedList }: { artist: Artist, isArchivedList?: boolean }) => {

    const [formVisible, setFormVisible] = useState(false)
    const [isFormReadOnly, setIsFormReadOnly] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)

    const navigate = useNavigate()
    const fetchApiAuth = useFetchApiAuth()
    const setSnack = useSetSnack()
    const dispatch = useAppDispatch()

    const rootModalDiv = document.getElementById('modal-root')!

    const artistUrl = `${ROUTE_ARTISTS}/${formatDisplayableNameToUrl(artist.displayableName)}/overview`

    const closeForm = () => setFormVisible(false)
    const openForm = () => setFormVisible(true)

    const openDeleteModal = () => setDeleteModal(true)
    const closeDeleteModal = () => setDeleteModal(false)

    const numberOfContracts = (artist.contracts||[]).length;

    const handleClickEdit = (event: any) => {
        //stop propagation of navigating to the artist page
        event.stopPropagation()
        setIsFormReadOnly(false)
        openForm()
    }

    const handleAddTouring = (event: any) => {
        event.stopPropagation()
        navigate(ROUTE_TOURING, {state: {toAddTouring: true, artist}})
    }

    const handleMakeContract = (event: any) => {
        event.stopPropagation()
        navigate(ROUTE_CONTRACTS, {state: { openForm: { artist } }})
    }

    const goToArtistPage = () => {
        navigate(artistUrl)
    }

    const handleClickDelete = (event: any) => {
        event.stopPropagation()
        openDeleteModal()
    }

    const handleDelete = async () => {
        setIsDeleting(true)

        const { error } = await fetchApiAuth({
            method: 'POST',
            route: 'delete-artist',
            body: { id: artist.id }
        });

        setSnack(
            error
            ? {
                type: 'error', 
                content: error.toString()
            }
            : {
                type: 'success',
                content: `${artist.displayableName} has been successfully deleted`
            }
        )
        
        if(!error){
            dispatch(archivedArtistsActions.delete({ id: artist.id }))
            closeDeleteModal()
        }

        setIsDeleting(false)
    }

    const handleArchive = async (event: any, isArchiving: boolean) => {
        event.stopPropagation()
        const updateBody = { id: artist.id, isArchived: isArchiving} 

        const { error, response } = await fetchApiAuth({
            method: 'POST',
            route: 'merge-artist',
            body: updateBody 
        });


        setSnack(
            error
                ? {
                    type: 'error', 
                    content: error.toString()
                }
                : {
                    type: 'success',
                    content: `Artist ${artist.displayableName} has been successfully ${isArchiving ? "archived" : "unarchived"}`
                }
            )

        if (!error) {
            if(isArchiving) {
                dispatch(archivedArtistsActions.add(response))
                dispatch(artistsActions.delete({id: artist.id}))
            } else {
                dispatch(artistsActions.add(response))
                dispatch(archivedArtistsActions.delete({id: artist.id}))
            }
        }
    }


    return(
        <>

            {createPortal(
                <Dialog
                    visible={formVisible}
                    onClose={closeForm}
                    title={`Edit Primuse Artist ${artist.displayableName}`}
                    backdropClose={false}
                    dialogCustomStyle={{overflowY: 'scroll', height: '90vh', top: '5vh', width: '80%', left: '10%', backgroundColor: 'var(--app-background)'}}
                    notAllSpace={true}
                >
                    <ArtistForm artist={artist} readOnly={isFormReadOnly} closeForm={closeForm}/>
                </Dialog>, rootModalDiv)}
            

            {createPortal(
                <Dialog
                    title={`Delete artist ${artist.displayableName}`}
                    visible={deleteModal}
                    onClose={closeDeleteModal}
                    backdropClose={false}
                    dialogCustomStyle={{height: '250px', top: '23', width: '60%', left: '20%'}}
                    notAllSpace={true}
                >
                    <div>
                        <p className="margedTop20px margedBot30px">Are you sure you want to delete the artist {artist.displayableName} ?</p>
                        <button className="button button--round button--ghost margedRight50px" onClick={closeDeleteModal}>Cancel</button>
                        <button className="button button--round button--danger" onClick={handleDelete}>{isDeleting ? "Deleting..." : "Delete"}</button>
                    </div>
                    
                </Dialog>, rootModalDiv)}
           
            <tr className="clickable hover-medium-scale-shadow" onClick={goToArtistPage}>
                <td><Flex>{artist.image ? <img className="artist__table-image" src={artist.image} alt={`${artist.displayableName} logo`} /> : <Row className="artist__table-image xlarge">{artist.code.toUpperCase()}</Row>}</Flex></td>
                <td className="leftalign">
                    <p className="larger">{artist.displayableName}</p>
                    {/* {
                        artist.aliases?.length
                        ? <Row>{ artist.aliases.filter(alias => (alias.name !== artist.displayableName)).map(a=>a.name).join(', ') }</Row>
                        : null
                    } */}
                    {/* { artist.aliases?.length && <b>{ artist.aliases.map(alias => ((alias.name !== artist.displayableName) ? alias.name : '')).join(', ') }</b> } */}
                    {/* <div className="grey italic">{artist.code}</div> */}
                </td>
                <td>{ artist.type }</td>
                <td>
                    {
                        artist.country
                        ? <Flex>
                            <ReactCountryFlag
                                countryCode={artist.country} 
                                style={{
                                    lineHeight: '25px',
                                    fontSize: '25px',
                                }}
                                title={countries[artist.country]}
                            />
                            {/* { countries[artist.country] }
                            { artist.areaName && (artist.areaName !== countries[artist.country]) && <div className="smaller">{artist.areaName}</div> } */}
                        </Flex>
                        : '-'
                    }
                </td>
                <td>{
                    artist.promotors?.length
                    ? <Flex row 
                        className="clickable hoverscale main-color" 
                        onClick={(event: any) => {
                            event.stopPropagation()
                            navigate(ROUTE_PROMOTORS, { state: { searchValue: artist.id } })
                        }}
                    >  
                        <div className="title"> { artist.promotors?.length } </div> 
                        &nbsp;
                        <MdEmojiPeople 
                            size={25} 
                        />
                    </Flex>
                    : '-'
                }</td>
                <td>
                    {
                        numberOfContracts
                        ? <Flex row 
                            className="clickable hoverscale main-color" 
                            onClick={(event) => {
                                event.stopPropagation()
                                navigate(ROUTE_CONTRACTS, { state: { searchValue: artist.id } })
                            }}
                        >  
                            <div className="title"> {numberOfContracts} </div> 
                            &nbsp;
                            <AiOutlineFolderOpen 
                                size={25} 
                            />
                        </Flex>
                        : '-'
                    }
                </td>
                <td><BiCalendarPlus size={23} className="main-color-fill clickable hoverscale" onClick={handleAddTouring} /></td>
                <td><HiOutlineDocumentPlus size={25} className="main-color clickable hoverscale" onClick={handleMakeContract} /></td>
                <td>
                {isArchivedList
                    ?   <MdClose size={25} color={colors.red} className="clickable hoverscale" onClick={handleClickDelete} />
                    :   <BiEdit size={25} className="main-color-fill clickable hoverscale" onClick={handleClickEdit} />}
                </td>
                <td><Link to={artistUrl}><BsThreeDots size={25} className="main-color-fill clickable hoverscale" /></Link></td>
                <td>
                {isArchivedList
                    ?   <BiArchiveOut size={25} className="main-color-fill clickable hoverscale" onClick={(e) => handleArchive(e, false)} />
                    :   <BiArchiveIn size={25} className="main-color-fill clickable hoverscale" onClick={(e) => handleArchive(e, true)} />}
                </td>
            </tr>
        </>
    )
}

export default ArtistItemRow