import { useRef, useState } from "react"
import ReactFlagsSelect from "react-flags-select";
import { AiFillPlayCircle, AiFillPauseCircle } from "react-icons/ai";
import { fetchSpotifyTopTracks } from "../../../../../api/dirsal/dirsalRequests";
import { Flex } from "../../../../../components/Containers";
import LoadingOval from "../../../../../components/loading/LoadingOval";
import { SpotifyTopTrack } from "../../../../../utils/models/artists.model"
import styles from "./top-tracks.module.css"

const TopTracks = ({ tracks, artistId }: { tracks: SpotifyTopTrack[], artistId: string }) => {

    const [countrySelected, setCountrySelected] = useState("US")
    const [fetchedTracks, setFetchedTracks] = useState(tracks)
    const [isFetching, setIsFetching] = useState(false)

    const handleSelectCountry = async (flag: string) => {
        setIsFetching(true)
        setCountrySelected(flag)

        const newTracksResponse = await fetchSpotifyTopTracks(artistId, flag)
        const newTracks = newTracksResponse?.data?.data
        if(newTracks){
            setFetchedTracks(newTracks)
        }

        setIsFetching(false)
    }

    return (
        <table className={styles.table}>
            <thead>
                <tr>
                    <td className="details-title-border" style={{width: '49%'}}>
                        <h4 className="details-section-title font18">Top hits</h4>
                    </td>
                    <td className="details-title-border bold uppercase letter-spacing-tight text-centered" style={{width: '16%'}}>Audio</td>
                    <td className="details-title-border bold uppercase letter-spacing-tight text-centered" style={{width: '10%'}}>Year</td>
                    <td className="details-title-border" style={{width: '25%'}}>
                        <ReactFlagsSelect
                            searchable
                            selected={countrySelected}
                            onSelect={(flag: any) => {handleSelectCountry(flag)}}                                                    
                            className="allwidth"
                            selectButtonClassName="flags-selection-button"
                            disabled={isFetching}
                        />
                    </td>
                </tr>
            </thead>

            <tbody>
                {isFetching
                ?   <tr>
                        <td><Flex className="margedTop30px margedBot30px align-end"><LoadingOval /></Flex></td>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                :   fetchedTracks.map((track, index) => <TrackItemRow track={track} key={index} />)}
            </tbody>
        </table>
    )
}

const TrackItemRow = ({ track }: {track: SpotifyTopTrack}) => {
    const audioRef = useRef<HTMLAudioElement>(null)
    const [isAudioPaused, setIsAudioPaused] = useState(true)
    const mainArtist = track.artists[0].name
    const isMultipleArists = track.artists.length > 1
    const featuredArtists = track.artists.slice(1).map(artist => artist.name)

    const toggleAudio = () => {
        if(!audioRef.current) { return }

        if(audioRef.current.paused) {
            audioRef.current.play()
            setIsAudioPaused(false)
        } else {
            audioRef.current.pause()
            setIsAudioPaused(true)
        }
    }

    return (
        <tr>
            <td>
                <div className="flex-align-center row">
                    <img className="margedRight10px" style={{width: '80px', height: '80px'}} src={track.album.images[0]?.url} alt="cover" />
                    <div>
                        <a href={track.external_urls?.spotify} target="_blank" rel="noreferrer">
                            <p className="font16 hover-opacity">{track.name}</p>
                        </a>
                        <p className="fw400 font12">{mainArtist} {isMultipleArists && `feat ${featuredArtists.join(', ')}`}</p>
                    </div>
                </div>
            </td>
            <td className="text-centered">
                <div onClick={toggleAudio}>
                    {isAudioPaused 
                    ? <AiFillPlayCircle size={43}/> 
                    : <AiFillPauseCircle size={43}/>}
                </div>
                <audio ref={audioRef} style={{display: 'none'}}>
                    <source src={track.preview_url} />
                </audio> 
            </td>
            <td className="fw400 text-centered">{track.album.release_date.substring(0,4)}</td>
            <td></td>
        </tr>
    )
}

export default TopTracks