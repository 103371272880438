import { Flex, Row } from "../../../../../components/Containers"
import Tabs from "../../../../../components/tabs-menu/Tabs"
import { ls } from "../../../../../utils/utils"
import ShowReview from "./ShowReview"
import { ShowsStepsIndexes } from "../ShowItem"
import { OfferFormInputs } from "../../../../../utils/models/offer.model"

const ReviewStep = ({ offer, rates, currencySymbols, reviewGoToEdit, isEditingOffer, isPosting, totalOfferAmount, closeForm, mergeOffer, storeOffer, setOfferShow, setShowPnlInputs }: 
    { 
        offer: OfferFormInputs,
        rates: (null|number)[],
        currencySymbols: (null|string)[],
        reviewGoToEdit: (showIndex: number, stepIndex: ShowsStepsIndexes) => void,
        isEditingOffer: boolean,
        isPosting: boolean,
        totalOfferAmount: any,
        closeForm: any,
        mergeOffer: () => Promise<void>,
        storeOffer: () => Promise<void>,
        setOfferShow: (showIndex: number, showProps: {}) => void, 
        setShowPnlInputs: (showIndex: number, props: any) => void,
    }) => {

    return (
        <Flex className="allwidth app-card">
            
            <Tabs activeTab='show 1' noContainer>
                    {offer.shows.map((show: any, index: number) => 
                    <div option-data={`show ${index + 1}`} key={index}>
                        <ShowReview
                            offer={offer}
                            show={show}
                            showIndex={index}
                            rates={rates}
                            currencySymbols={currencySymbols}
                            reviewGoToEdit={reviewGoToEdit}
                            setOfferShow={setOfferShow}
                            setShowPnlInputs={setShowPnlInputs}
                        />
                    </div>
                        )}
            </Tabs>

            <Row className="width80 space-between margedTop30px">
                <button className="button button--danger button--round" onClick={closeForm}>Cancel</button>
                <Flex className="alignend">
                    <b>{isNaN(totalOfferAmount) ? '' : `$${ls(totalOfferAmount, true)}`}</b>
                    {isEditingOffer
                        ? <button className="button button--primary button--round" disabled={isPosting} onClick={mergeOffer}>{isPosting ? 'Updating...' : 'Update Offer'}</button>
                        : <button className="button button--primary button--round" disabled={isPosting} onClick={storeOffer}>{isPosting ? 'Validating...' : 'Validate Offer'}</button>}
                </Flex>
            </Row>
        </Flex>
    )
}

export default ReviewStep