import { createRef, useState } from "react";
import { BsInfoCircle } from 'react-icons/bs';
import { FaInfoCircle } from "react-icons/fa";

export default (props) => {
    const [ref, setRef] = useState(createRef());
    const [tooltipVisible, showTooltip] = useState(false);

    const { offsetTop, offsetLeft } = ref?.current || {};
   

    return <div 
        //ref={ref} 
        onMouseEnter={() => showTooltip(true)}
        onMouseLeave={() => showTooltip(false)}    
    >
        {props.refIcon ? props.refIcon(ref) : <BsInfoCircle  ref={ref} size={props.iconSize || 15} className="main-color-fill" />}
        {
            tooltipVisible
            ? <div
                className="ontop flex tooltip-border small leftalign"
                style={{
                    position: 'absolute',
                    top: offsetTop,
                    left: offsetLeft + 40,
                    padding: '1%',
                    marginRight: '1%',
                    background: 'white',
                    color: "black",
                }}
            >
                <div className="flex row selfstart"><FaInfoCircle />&nbsp; <b className="carter">{props.title || ''} Infos</b></div>
                { props.content }
            </div>
            : null
        }
    </div>
}