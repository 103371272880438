import { useState } from "react"
import { createPortal } from "react-dom"
import { AiOutlineFolderOpen } from "react-icons/ai"
import { ThreeDots } from "react-loader-spinner"
import { fetchCountryInfos } from "../../../api/dirsal/dirsalRequests"
import { Flex } from "../../../components/Containers"
import Dialog from "../../../components/dialog/Dialog"
import { colors } from "../../../constants"
import ViewOfferPNLs from "../../../user-panels/common/offers/ViewOfferPNLs"
import { Offer, OfferDraft } from "../../models/offer.model"

const useViewPNLs = ({ offer, textColor }: { offer: Offer|OfferDraft, textColor?: boolean }) => {

    const [viewPNLs, setViewPNLs] = useState(false)
    const numberOfShows = offer.shows.length
    const [rates, setRates] = useState<(null|number)[]>(new Array(numberOfShows).fill(null))
    const [currencySymbols, setCurrencySymbols] = useState<(null|string)[]>(new Array(numberOfShows).fill(null))

    const [isFetchingCountryDatas, setIsFetchingCountryDatas] = useState(false)

    const rootModalDiv = document.getElementById('modal-root')!

    const openViewPNLs = () => setViewPNLs(true)
    const closeViewPNLs = () => setViewPNLs(false)

    const handleViewPNL = async () => {
        setIsFetchingCountryDatas(true)

        // fetching country datas
        await Promise.all(offer.shows.map(async (show, index) => {
            const response = await fetchCountryInfos(show.country)
            console.log(response)
    
            const fetchedRate = response?.data?.currency?.exchange.inverseRate
            if(fetchedRate) {
                setRates(prevRates => {
                    const newRates = [...prevRates]
                    newRates[index] = fetchedRate
                    return newRates
                })
            }
            
            const fetchedCurrencySymbol = response?.data?.currency?.symbol
            if(fetchedCurrencySymbol) {
                setCurrencySymbols(prevSymbols => {
                    const newSymbols = [...prevSymbols]
                    newSymbols[index] = fetchedCurrencySymbol
                    return newSymbols
                })
            }
        }))

        openViewPNLs()

        setIsFetchingCountryDatas(false)
    }

    const pnlsModal = createPortal(
        <Dialog
            visible={viewPNLs}
            onClose={closeViewPNLs}
            dialogCustomStyle={{overflowY: 'scroll', height: '90vh', top: '5vh', width: '80%', left: '10%', backgroundColor: 'var(--app-background)'}}
            notAllSpace={true}
        >
            <ViewOfferPNLs
                offer={offer}
                rates={rates}
                currencySymbols={currencySymbols}
            />
        </Dialog>, rootModalDiv)

    const openPnlsButton = isFetchingCountryDatas
        ?   <Flex><ThreeDots 
                height="25" 
                width="25" 
                radius="9"
                color={textColor ? colors.gray : colors.main} 
            /></Flex>
        :   <AiOutlineFolderOpen className={`${textColor ? "text-color-fill" : "main-color-fill"} clickable hoverscale`} size={25} onClick={handleViewPNL} />


    return { pnlsModal, openPnlsButton, handleViewPNL }
}

export default useViewPNLs