import { Artist, ArtistAPIDatas, ArtistFromDB, NormalizedArtistMarcoDB } from "../../../../../utils/models/artists.model"
import { ARTIST_DETAILS_ALBUMS_ENDPOINT, ARTIST_DETAILS_ASSETS_ENDPOINT, ARTIST_DETAILS_INFOS_ENDPOINT, ARTIST_DETAILS_OVERVIEW_ENDPOINT, ARTIST_DETAILS_TOURS_ENDPOINT } from "../../../../../routes/routes.const"
import { NavLink } from "react-router-dom"
import { countries, TouringDayWithTouringId } from "../../../../../utils/models/touring.model"
import { clipString, numberFormatter } from "../../../../../utils/utils";
import useHasClientAccessToAssets from "../../../../../utils/hooks/useCheckClientAccessToAssets";
import moment from "moment";
import useIsAdmin from "../../../../../utils/hooks/useIsAdmin";
import { ThreeDots } from "react-loader-spinner";
import { colors } from "../../../../../constants";
import { Flex } from "../../../../../components/Containers";
import { useRef, useState } from "react";
import { MdPlayCircle, MdPauseCircle } from "react-icons/md";

const ArtistDetailsHeader = ({ artist, daysOfTour, fetchedDatas }: {artist: Artist|ArtistFromDB|NormalizedArtistMarcoDB, daysOfTour: TouringDayWithTouringId[]|null, fetchedDatas: ArtistAPIDatas}) => {
    
    const [isAudioPaused, setIsAudioPaused] = useState(true)
    const audioRef = useRef<HTMLAudioElement>(null)

    const artistId = artist.id

    const albums = fetchedDatas.spotify?.albums
    const topTracks = fetchedDatas.spotify?.topTracksUS
    const latestRelease = albums ? albums[0] : undefined
    const popularSong = topTracks ? topTracks[0] : undefined

    const spotifyImage = fetchedDatas.spotify?.artist?.images[0]?.url
    const aImg = artist.banner||artist.logo||artist.image||spotifyImage
    const hasAccessToAssets = useHasClientAccessToAssets(artistId)

    const isPrimuseArtist = daysOfTour !== null
    const isAdmin = useIsAdmin()

    const { totalFollowers, pastEvents, events } = fetchedDatas

    const allEvents = (pastEvents !== undefined && events !== undefined) ? [...pastEvents, ...events] : undefined
    let totalToursDays = 0
    let tourDaysAvailable = 0
    let toursDaysBooked = 0
    let nextTour = ""

    if(daysOfTour && daysOfTour.length > 0){
        totalToursDays = daysOfTour.length

        const daysAvailable = daysOfTour.filter(show => !show.offerId)
        tourDaysAvailable = daysAvailable.length

        const daysBooked = daysOfTour.filter(show => show.offerId)
        toursDaysBooked = daysBooked.length

        const nextTourDate = daysOfTour[0].date
        const formated = moment(new Date(nextTourDate)).format("MM/DD/YYYY")
        nextTour = formated
    }

    const toggleAudio = () => {
        if(!audioRef.current) { return }

        if(audioRef.current.paused) {
            audioRef.current.play()
            setIsAudioPaused(false)
        } else {
            audioRef.current.pause()
            setIsAudioPaused(true)
        }
    }

    return (
        <div className="flex-only allwidth">
            <div className="artist-details__banner-wrapper" style={{backgroundImage: `url(${aImg})`}}>
                <div className="artist-details__banner-content">
                    <div className="margedBot50px">
                        <span className="artist-details__banner-title">{artist.displayableName}</span>
                        {artist.country && <span className="font1rem paddedLeft10px opacity80">({countries[artist.country]})</span>}
                    </div>
                    <div className="margedBot50px flex-only">
                        <div className="paddedRight20px">
                            <p className="banner-inner-data-title">Ranking</p>
                            <p className="banner-inner-data-content">#XX</p>
                        </div>
                        <div className="paddedRight20px">
                            <p className="banner-inner-data-title">Total fans</p>
                            {totalFollowers !== undefined
                            ?   <p className="banner-inner-data-content">{numberFormatter(totalFollowers, 1)}</p>
                            :   <Flex><ThreeDots 
                                    height="30" 
                                    width="30" 
                                    radius="9"
                                    color={colors.gray} 
                                /></Flex>}
                        </div>
                        <div>
                            <p className="banner-inner-data-title">Artist rate</p>
                            <p className="banner-inner-data-content">X.XX%</p>
                        </div>
                    </div>
                    {(daysOfTour && daysOfTour.length > 0) 
                    &&  <div className="margedBot50px flex-only text-centered">
                            <div className="paddedRight40px">
                                <p className="banner-inner-data-title">Dates Availables</p>
                                <p className="banner-inner-data-content">{tourDaysAvailable}</p>
                            </div>
                            <div className="paddedRight40px">
                                <p className="banner-inner-data-title">Next Show</p>
                                <p className="banner-inner-data-content">{nextTour}</p>
                            </div>
                            <div className="paddedRight40px">
                                <p className="banner-inner-data-title">Dates Booked</p>
                                <p className="banner-inner-data-content">{toursDaysBooked}</p>
                            </div>
                            <div>
                                <p className="banner-inner-data-title">Total Touring</p>
                                <p className="banner-inner-data-content">{totalToursDays}</p>
                            </div>
                        </div>}
                    <div className="margedBot50px flex-only">
                        <div className="paddedRight30px margedAuto0">
                            <p className="banner-inner-data-title">Possible fee</p>
                            <p className="banner-inner-data-content banner-fee">$X.XM</p>
                            <p className="banner-small-text banner-expenses">Plus Expenses</p>
                        </div>
                        <div className="margedAuto0">
                            <p className="banner-inner-data-title banner-small-text">Total Shows</p>
                            <div className="banner-inner-data-content banner-medium-text margedBot5px" style={{height: "25px"}}>
                                {allEvents !== undefined
                                ?   <>
                                        {allEvents.length}{' '}
                                        <span className="banner-small-text banner-text-show">Shows</span>
                                    </> 
                                :   <ThreeDots 
                                        height="23" 
                                        width="30" 
                                        radius="9"
                                        color={colors.gray} 
                                    />} 
                            </div>
                            <p className="banner-inner-data-title banner-small-text">Past Shows</p>
                            <div className="banner-inner-data-content banner-medium-text margedBot5px" style={{height: "25px"}}>
                                {pastEvents !== undefined
                                ?   <>
                                        {pastEvents.length}{' '}
                                        <span className="banner-small-text banner-text-show">Shows</span>
                                    </>
                                :   <ThreeDots 
                                        height="23" 
                                        width="30" 
                                        radius="9"
                                        color={colors.gray}
                                    />}
                            </div>
                            <p className="banner-inner-data-title banner-small-text">Upcoming Shows</p>
                            <div className="banner-inner-data-content banner-medium-text margedBot5px" style={{height: "25px"}}>
                                {events !== undefined
                                ?   <>
                                        {events.length}{' '}
                                        <span className="banner-small-text banner-text-show">Shows</span>
                                    </> 
                                :   <ThreeDots 
                                        height="23" 
                                        width="30" 
                                        radius="9"
                                        color={colors.gray} 
                                    />}
                            </div>
                            {/* <p className="banner-inner-data-title banner-small-text">Avg. ticket sold</p>
                            <p className="banner-inner-data-content banner-medium-text margedBot5px">
                                XX XXX{' '}
                                <span className="banner-small-text banner-per-show">/Show</span>
                            </p>
                            <p className="banner-inner-data-title banner-small-text">Avg. gross sales</p>
                            <p className="banner-inner-data-content banner-medium-text">
                                $XXX XXX{' '}
                                <span className="banner-small-text banner-per-show">/Show</span>
                            </p> */}
                        </div>
                    </div>
                    <div className="margedBot50px flex-only">
                        {latestRelease && 
                            <div className="flex-only margedRight100px">
                                <img src={latestRelease.images[0]?.url} alt="album cover" className="banner-album-img margedRight5px" />
                                <div className="margedAuto0">
                                    <p className="banner-album-type margedBot5px">Latest release</p>
                                    <a href={latestRelease.external_urls?.spotify} target="_blank" rel="noreferrer">
                                        <p className="banner-album-title margedBot5px hover-opacity">{clipString(latestRelease.name, 17)}</p>
                                    </a>
                                    <p className="banner-album-date">{moment(latestRelease.release_date).format('MMMM Do, YYYY')}</p>
                                </div>
                            </div>}
                        {popularSong &&
                            <div className="flex-only">
                                <div className="relative">
                                    <img src={popularSong.album?.images[0]?.url} alt="album cover" className="banner-album-img margedRight5px"></img>
                                    <div onClick={toggleAudio} style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)', opacity: "0.9"}}>
                                        {isAudioPaused 
                                        ? <MdPlayCircle fill="#1d212f" size={45}/> 
                                        : <MdPauseCircle fill="#1d212f" size={45}/>}
                                    </div>
                                    <audio ref={audioRef} style={{display: 'none'}}>
                                            <source src={popularSong.preview_url} />                                    
                                    </audio> 
                                </div>
                                <div className="margedAuto0">
                                    <p className="banner-album-type margedBot5px">Popular Song</p>
                                    <a href={popularSong.external_urls?.spotify} target="_blank" rel="noreferrer">
                                        <p className="banner-album-title margedBot5px hover-opacity">{clipString(popularSong.name, 17)}</p>
                                    </a>
                                    <p className="banner-album-date">{moment(popularSong.album?.release_date).format('MMMM Do, YYYY')}</p>
                                </div>
                            </div>}
                    </div>

                </div>
                <div className="artist-details--nav">
                    <ul className="flex-only space-around">
                        <li>
                            <NavLink
                                to={ARTIST_DETAILS_OVERVIEW_ENDPOINT}
                                className={({ isActive}) =>
                                isActive
                                ? "banner-nav-item banner-nav-active"
                                : "banner-nav-item"
                            }
                            >
                                Overview
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to={ARTIST_DETAILS_TOURS_ENDPOINT}
                                className={({ isActive}) =>
                                isActive
                                ? "banner-nav-item banner-nav-active"
                                : "banner-nav-item"
                            }
                            >
                                Tours
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to={ARTIST_DETAILS_ALBUMS_ENDPOINT}
                                className={({ isActive}) =>
                                isActive
                                ? "banner-nav-item banner-nav-active"
                                : "banner-nav-item"
                            }
                            >
                                Albums
                            </NavLink>
                        </li>
                        {(isPrimuseArtist && isAdmin) && <li>
                            <NavLink
                                to={ARTIST_DETAILS_INFOS_ENDPOINT}
                                className={({ isActive}) =>
                                isActive
                                ? "banner-nav-item banner-nav-active"
                                : "banner-nav-item"
                            }
                            >
                                Infos
                            </NavLink>
                        </li>}
                        {(isPrimuseArtist && hasAccessToAssets) && <li>
                            <NavLink
                                to={ARTIST_DETAILS_ASSETS_ENDPOINT}
                                className={({ isActive}) =>
                                isActive
                                ? "banner-nav-item banner-nav-active"
                                : "banner-nav-item"
                            }
                            >
                                Assets
                            </NavLink>
                        </li>}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default ArtistDetailsHeader