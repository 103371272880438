import { createRef, useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import SignatureCanvas from 'react-signature-canvas'

import moment from 'moment';
import Axios from 'axios';

import { AiOutlineCalendar, AiOutlineCloseCircle, AiOutlineSearch, AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";
import { BiImage, BiLayerPlus } from "react-icons/bi";
import { BsArrowsFullscreen } from "react-icons/bs";
import { FaFileSignature } from "react-icons/fa";
import { MdEditNote, MdOutlineAccountBox } from "react-icons/md";

import { openedContractActions } from "../../../../store/openedContractSlice";
import { contractsActions } from "../../../../store/contractsSlice";
import { snackActions } from "../../../../store/snackSlice";

import { Flex, Row } from "../../../../components/Containers";
import ToolTip from "../../../../components/ToolTip";
import LivePerformanceContractForm from "./LivePerformanceContractForm";
import { b64toBlob, fetchApiAuthentified, ls, promisify } from "../../../../utils/utils";
import { primuseApi } from "../../../../constants";

import './signature-pad.css';
import DocumentLoading from "../../../../components/document-loading/DocumentLoading";
import useHandleTokenExpired from "../../../../utils/hooks/useHandleTokenExpired";
import { useLocation, useNavigate } from "react-router-dom";


import getContractStatus from './get-contract-status';
import { ROUTE_ARTISTS } from "../../../../routes/routes.const";
import { formatDisplayableNameToUrl } from "../../../../utils/artists.util";
import PromotorFeedback from "../../../promotor/common/feedback/PromotorFeedback";
import LoadingModal from "../../../../components/loading/LoadingModal";
import ContractItemRow from "./ContractItemRow";
import { useAppSelector } from "../../../../utils/hooks/reduxTypedHooks";
import { CONTRACT_STATUS_COMPLETED, CONTRACT_STATUS_IN_PROCESS, CONTRACT_STATUS_NULL_AND_VOID, CONTRACT_STATUS_SIGNED, CONTRACT_STATUS_WAITING_BOTH_SIGNATURES, CONTRACT_STATUS_WAITING_PRIMUSE_SIGNATURE, CONTRACT_STATUS_WAITING_PURCHASER_SIGNATURE } from "../../../../utils/models/contracts.model";


const paymentStatusTips = [
    { status: 'waiting signature', info: 'The contract needs to be signed by at least one of the party' },
    { status: 'signed', info: 'The contract has been signed by both parties' },
    { status: 'nullandvoid', info: 'The contract is Null and Void (considered as never existed)' },
    { status: 'in process', info: "At least one of the contract's related show is currenlty happening or is yet to come" },
    { status: 'completed', info: "The contract has been signed by both parties and last contract's related show is passed" }
]

/**
 * @dev ContractsPanel admin screen
 */
const AdminContracts = () => {
    const [showForm, setShowForm] = useState(false);
    const [signPadVisible, setSignPadVisible] = useState(false);
    const [isSigning, setIsSigning] = useState();
    const [useDocData, setUseDocData] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [openingContract, setOpeningContract] = useState(false);

    
    const openedContract = useSelector(state => state.openedContract);
    const client = useSelector(state => state.client);
    const contracts = useAppSelector(state => state.contracts);
    const artists = useSelector(state => state.artists);
    const promotors = useSelector(state => state.promotors);
    
    const [contractsList, setContractsList] = useState(contracts)
    const [statusFilter, setStatusFilter] = useState([])
    const [isDateSortAscending, setIsDateSortAscending] = useState(true)
    const [isArtistSortAscending, setIsArtistSortAscending] = useState(false)
    const [isPromotorSortAscending, setIsPromotorSortAscending] = useState(false)
    const [isShowsNumberSortAscending, setIsShowsNumberSortAscending] = useState(false)
    const [isAmountSortAscending, setIsAmountSortAscending] = useState(false)
    const [isStatusSortAscending, setIsStatusSortAscending] = useState(false)

    const location = useLocation();
    const unloadedSearchValue = location?.state?.searchValue;
    const openFormRequest = location?.state?.openForm;
    //const openingRequest = location?.state?.openContractId;           // @dev UNUSED
    
    const handleTokenExpired = useHandleTokenExpired()

    const fetchApiAuth = fetchApiAuthentified(
        client.token, 
        handleTokenExpired
    );


    const docviewRef = createRef();

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const setSnack = (newSnack) => dispatch(snackActions.set(newSnack));
    useEffect(() => {
        unloadedSearchValue && setSearchValue(unloadedSearchValue);
        if(openFormRequest) { 
            console.log('OPENFORM REQUEST SPOTTED', openFormRequest)    //
            setUseDocData(openFormRequest)
            showContractForm()       // @TODO add location?.state?.openForm to the form (should be contract form data to be loaded)

        }
        //openingRequest && openContract(openingRequest);

        // close contract when component unmount
        return (() => {
            dispatch(openedContractActions.close())
        })
    },[])

    //status filters
    const isStatusFilterAllStatus = statusFilter.length === 0
    const setStatusFilterAllStatus = () => setStatusFilter([])

    const pastStatus = [CONTRACT_STATUS_COMPLETED, CONTRACT_STATUS_NULL_AND_VOID]
    const ongoingStatus = [CONTRACT_STATUS_IN_PROCESS, CONTRACT_STATUS_SIGNED]
    const toSignStatus = [CONTRACT_STATUS_WAITING_PURCHASER_SIGNATURE, CONTRACT_STATUS_WAITING_PRIMUSE_SIGNATURE, CONTRACT_STATUS_WAITING_BOTH_SIGNATURES]
    
    const filterAllStatusButtonActiveClass = isStatusFilterAllStatus ? 'button--primary' : 'button--ghost'
    const filterIsPastStatusActive = pastStatus.every(status => statusFilter.includes(status))
    const filterIsOngoingStatusActive = ongoingStatus.every(status => statusFilter.includes(status))
    const filterIsToSignStatusActive = toSignStatus.every(status => statusFilter.includes(status))
    
    const toggleStatusFilter = (statusList) => {
        setStatusFilter(prevFilter => {
            const isActive = statusList.every(status => prevFilter.includes(status))
            if(isActive) {
                return prevFilter.filter(status => !statusList.includes(status))
            } else {
                return [...prevFilter, ...statusList]
            }
        })
    }

    // contracts filtering
    useEffect(() => {
        const statusFilteredContracts = contracts.filter(contract => {
            if(isStatusFilterAllStatus) { return true }
            else {
                const status = getContractStatus(contract)
                return statusFilter.includes(status)
            }
        })

        const searchFilteredContracts = statusFilteredContracts.filter(contract => JSON.stringify(contract).toLowerCase().includes(searchValue.toLowerCase()))

        setContractsList(searchFilteredContracts)
    }, [statusFilter, contracts, isStatusFilterAllStatus, searchValue])


    const showContractForm = () => setShowForm(true);
    const hideContractForm = () => {
        setUseDocData(false);
        setShowForm(false);
    }

    // sorting
    const handleSortByDate = () => {
        if(isDateSortAscending) {
            setContractsList(prevContracts => prevContracts.sort((contractA, contractB) => contractB.date - contractA.date))
        } else {
            setContractsList(prevContracts => prevContracts.sort((contractA, contractB) => contractA.date - contractB.date))
        }
        setIsDateSortAscending(prevValue => !prevValue)
    }

    const handleSortByArtist = () => {
        if(isArtistSortAscending) {
            setContractsList(prevContracts => prevContracts.sort((contractA, contractB) => contractB.artist.name.localeCompare(contractA.artist.name)))
        } else {
            setContractsList(prevContracts => prevContracts.sort((contractA, contractB) => contractA.artist.name.localeCompare(contractB.artist.name)))
        }
        setIsArtistSortAscending(prevValue => !prevValue)
    }

    const handleSortByPromotor = () => {
        if(isPromotorSortAscending) {
            setContractsList(prevContracts => prevContracts.sort((contractA, contractB) => contractB.purchaser.name.localeCompare(contractA.purchaser.name)))
        } else {
            setContractsList(prevContracts => prevContracts.sort((contractA, contractB) => contractA.purchaser.name.localeCompare(contractB.purchaser.name)))
        }
        setIsPromotorSortAscending(prevValue => !prevValue)
    }

    const handleSortByShowsNumber = () => {
        if(isShowsNumberSortAscending) {
            setContractsList(prevContracts => prevContracts.sort((contractA, contractB) => contractB.shows.length - contractA.shows.length))
        } else {
            setContractsList(prevContracts => prevContracts.sort((contractA, contractB) => contractA.shows.length - contractB.shows.length))
        }
        setIsShowsNumberSortAscending(prevValue => !prevValue)
    }

    const handleSortByAmount = () => {
        if(isAmountSortAscending) {
            setContractsList(prevContracts => prevContracts.sort((contractA, contractB) => {
                const amountA = contractA.payments.map((p) => parseInt(p.amount)).reduce((a, b) => a+b)
                const amountB = contractB.payments.map((p) => parseInt(p.amount)).reduce((a, b) => a+b)
                return amountB - amountA
            }))
        } else {
            setContractsList(prevContracts => prevContracts.sort((contractA, contractB) => {
                const amountA = contractA.payments.map((p) => parseInt(p.amount)).reduce((a, b) => a+b)
                const amountB = contractB.payments.map((p) => parseInt(p.amount)).reduce((a, b) => a+b)
                return amountA - amountB
            }))
        }
        setIsAmountSortAscending(prevValue => !prevValue)
    }

    const handleSortByStatus = () => {
        if(isStatusSortAscending) {
            setContractsList(prevContracts => prevContracts.sort((contractA, contractB) => {
                const contractAStatus = getContractStatus(contractA)
                const contractBStatus = getContractStatus(contractB)
                return contractBStatus.localeCompare(contractAStatus)
            }))
        } else {
            setContractsList(prevContracts => prevContracts.sort((contractA, contractB) => {
                const contractAStatus = getContractStatus(contractA)
                const contractBStatus = getContractStatus(contractB)
                return contractAStatus.localeCompare(contractBStatus)
            }))
        }
        setIsStatusSortAscending(prevValue => !prevValue)
    }
    
    const openContract = async contractId => {
        setOpeningContract(contractId);
        const {error, response} = await fetchApiAuth(
            {
                method: 'POST',
                route: 'contract',
                body: {
                    contractId
                }
            }
        );

        setOpeningContract(false);

        if (error) 
            setSnack({ type: 'error', content: "This contract can't be opened for now, the service may be down, try again later" })
        else if (response)
            _openContract(response);
        
    }


    const _openContract = _openedContract => {
        hideContractForm();
        signPadVisible && closeSignPad();
        isSigning && setIsSigning(false);
        dispatch(openedContractActions.set(_openedContract));
    }

    const closeContract = () => dispatch(openedContractActions.close())

    const openArtist = () => {
        const { artist } = openedContract;
        const artistUrl = `${ROUTE_ARTISTS}/${formatDisplayableNameToUrl(artist.displayableName)}`
        navigate(artistUrl)
    }

    const openPurchaser = () => {
        const { purchaser } = openedContract;
        // TODO : open promotors view loaded with `purchaser`
        

    }


    const signPadRef = useRef();
    const openSignPad = (contractStatus) => {
        contractStatus === 'nullandvoid' 
        ? setSnack({
            type: 'error',
            content: `Contracts with "null and void" status can not be signed`
        })
        : setSignPadVisible(true)

    }
    const clearSignPad = async() => {
        signPadRef.current.clear();
    }
    const closeSignPad = () => setSignPadVisible(false);

    const confirmSignature = async() => {
        setIsSigning({ text: 'Contract is being signed' });
        try {
            const bS4Signature = signPadRef.current.toDataURL('png');
         
            const blobSignature = await b64toBlob(bS4Signature.split(',')[1], 'image/png');
          
            let fd = new FormData();
            fd.append('image', blobSignature, `${openedContract.id}_${client.licence}_signature.png`);

            // store signature to contract using axios
            Axios.interceptors.request.use((config) => {
                config.headers['X-Auth-Token'] = client.token;
                return config;
            });
            const axiosResponse = await Axios({
                method: "post",
                url: `${primuseApi}/sign-contract/${openedContract.id}`,
                data: fd,   // FormData
                config: { headers: { "Content-Type": "multipart/form-data"/*, 'X-Auth-Token': client.token*/ } }
            });
            
            // update opened contract
            _openContract(axiosResponse.data); 
            console.log('CONTRACT UPDATED AFTER SIGN (axiosResponse.data):', axiosResponse.data);
            // update local contracts
            dispatch(contractsActions.update(axiosResponse.data))

            // skip browser caching by forcing docview src reload  
            //docviewRef.current.setAttribute('src', axiosResponse.data.pdfUrl);// = axiosResponse.data.pdfUrl;
            //ReactDOM.findDOMNode(docviewRef).setAttribute('src', axiosResponse.data.pdfUrl);
            // ReactDOM.findDOMNode(docviewRef).location.href = axiosResponse.data.pdfUrl;
            // docviewRef.current.contentWindow.location.href = axiosResponse.data.pdfUrl;

           
        }catch(e) {
            setIsSigning(false);
            console.log('ERROR WHILE SIGNING CONTRACT :', e);
            setSnack({
                type: 'error',
                content: e.response?.data || e.message | "Error while signing the contract, service may be down"
            })
        }
    }

    const editDocData = () => {
        setUseDocData(true);
        showContractForm();
    }

    const minifiedContractFromId = async id => {

        const spl = {
            2: true,
            4: true
        }
        const keyParts = id.split('-');
        const cd = await promisify(keyParts[2].split('').map((e,i) => ( spl[i]?`-${e}`:e )).join(''));
        const date = moment(cd).format('LL');
        
        const minContract = await promisify({ id, artistCode: keyParts[1], date, codeDate: keyParts[2], index: parseInt(keyParts[3]) });
        return minContract;
    }

    const storeComputedContract = async(_docData) => {
        hideContractForm();
        setIsSigning({title: 'Live Performance Contract', text: 'Creation in progress'});
        openSignPad();
        const { error, response } = await fetchApiAuth({
            method: 'POST',
            route: 'create-contract/live-show',
            body: {
                docData: {
                    ..._docData,
                    date: Date.now(),
                }
            }
        });
       

        if ((!error) && response) {
         
            // locally add contract data to `state.contracts` (minified contracts)
            //const minContract = await minifiedContractFromId(response?.id);

            // push `minContract` in `contracts`
            dispatch(
                contractsActions.set([
                    ...contracts||[],
                    response
                ])
            )
            // close form & open contract with `openedContract`
            _openContract(response);

        }
        else showContractForm();   // todo: locally store if status==511 for after login data retrieval

        closeSignPad();
        setIsSigning(false);
    }


    /// render ///
    // console.log('[AdminContracts] openedContract', openedContract);
    // console.log('[AdminContracts] props.contracts', contracts);

    const contractStatus = openedContract && getContractStatus(openedContract);
    const isAdmin = client.licence === 'admin';

    return <Flex flex={10} className='allwidth'>

        <LoadingModal visible={openingContract} text="Loading contract. Please wait.." />
        
        {
            /** CONTRACT FORM **/ 
            showForm
            ? <LivePerformanceContractForm 
                useDocData={useDocData && (typeof useDocData === 'object' ? useDocData : openedContract)}
                onCancel={hideContractForm}
                storeComputedContract={storeComputedContract}
                client={client}
                setSnack={setSnack}
                artists={artists}
                promotors={promotors}
                //style={signPadVisible && {opacity: 0}}
            />                
            : (
            /** OPENED CONTRACT **/ 
            openedContract
                ? <Flex flex={10} row className='allspace alignstart'>
                    <Flex flex={5} className='justifystart'>
                        <Flex row className='marged-v'>
                            <i className="main-color">Document Reference: </i> 
                            { openedContract.id }
                            &nbsp;
                            <BsArrowsFullscreen 
                                className="main-color-fill clickable" 
                                size={15}
                                onClick={() => window.open(openedContract.pdfUrl, '_blank')}
                            />
                            &nbsp;
                            <AiOutlineCloseCircle 
                                size={25} 
                                className='clickable'
                                onClick={closeContract}    
                            />
                        </Flex>
                        <iframe ref={docviewRef} src={openedContract.pdfUrl} style={{width:'40vw', height:'80vh'}}/>
                    </Flex>

                    <Flex flex={5} className='justifystart margedTop height90 spaceevenly'>
                        <Flex row className="allwidth">
                            <Flex className="allwidth alignstart">
                                <label className="spaced-text title">PURCHASER</label>
                                <Flex row className='clickable' onClick={openPurchaser}>
                                    <MdOutlineAccountBox className="main-color-fill" size={25} />
                                    &nbsp;
                                    {openedContract.purchaser.name}
                                </Flex>
                            </Flex>

                            <Flex className="allwidth alignstart">
                                <label className="spaced-text title">ARTIST</label>
                                <Flex row className='clickable' onClick={openArtist}>
                                    <MdOutlineAccountBox className="main-color-fill" size={25} />
                                    &nbsp;
                                    {openedContract.artist.name}<span className="main-color margedLeft5px">(click to view)</span>
                                </Flex>
                            </Flex>
                        </Flex>

                        <Flex row className="allwidth">
                            <Flex className="allwidth alignstart">
                                <label className="spaced-text title">CREATED ON</label>
                                <Flex row>
                                    <AiOutlineCalendar size={25} />
                                    &nbsp;
                                    {moment(openedContract.date).format('LLL')}
                                </Flex>
                            </Flex>
                            <Flex className="allwidth alignstart">
                                <Flex row >
                                    <label className="spaced-text title">STATUS</label>
                                    &nbsp;
                                    <ToolTip 
                                        title="Contract Status" 
                                        content={
                                            <table className="app-table small">
                                                <thead className="">
                                                    <tr>
                                                        <th>Status</th>
                                                        <th className="last">Info</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    
                                                    { 
                                                        paymentStatusTips.map((tip, tipi) => <tr key={`payment_tip${tipi}`}>
                                                            <td className="bold">{tip.status}</td>
                                                            <td className="leftalign last">{tip.info}</td>
                                                        </tr>)
                                                    }
                                                </tbody>
                                            </table>
                                        } 
                                    />
                                </Flex>
                                <div className="bordered padded">
                                    { contractStatus } 
                                </div>
                            </Flex>
                        </Flex>

                        {/* SHOWS */}
                        <Flex className="allwidth alignstart marged-b">
                            
                            <label className="spaced-text title">SHOWS</label>
                            <div className="width90">
                                <table className="app-table allwidth nomargin">
                                    <thead>
                                        <tr>
                                            <th>Show n°</th>
                                            <th>Date</th>
                                            <th>Venue</th>
                                            <th>Artist Fee</th>
                                            <th>Capacity</th>
                                            <th className="last">Net Total</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            openedContract?.shows?.length
                                            ? openedContract.shows.map((show, showIndex) => (
                                                <tr key={`show_${showIndex}`}>
                                                    <td>{`${showIndex+1}`}</td>
                                                    <td>{moment(show.date).format('LL')}</td>
                                                    <td>
                                                        <div 
                                                            className="hoverscale clickable" 
                                                            onClick={() => ((show.venue?.website?.length) && window.open(show.venue.website, '_blank'))}
                                                        >
                                                            {show.venue?.name}, {show.venue?.city}
                                                        </div>
                                                    </td>
                                                    <td>{ls(show.artistFee?.amount)} <br/><i>US$</i></td>
                                                    <td>{show?.totals.quantity} <br/><i>tickets</i></td>
                                                    <td className="last">{show?.totals.netTotal} <br/><i>US$</i></td>
                                                </tr>
                                            ))
                                            : null
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </Flex>

                        
                        {/* PAYMENTS */}
                        <Flex className="allwidth alignstart marged-b">
                            <Flex row>
                                <label className="spaced-text title">EXPECTED PAYMENTS</label>
                                &nbsp;<ToolTip title="Contract Payments" content="Payment status are connected to Xero" />
                            </Flex>
                            <div className="width90">
                                <table className="app-table allwidth nomargin">
                                    <thead>
                                        <tr>
                                            <th>Reference</th>
                                            <th>Amount</th>
                                            <th>Date</th>
                                            <th className="last">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            openedContract?.payments?.length
                                            ? openedContract.payments.map((payment, paymentIndex) => (
                                                <tr key={`payment_${paymentIndex}`}>
                                                    <td>{`${openedContract.id.replace('CT', 'PA')}-${paymentIndex+1}`}</td>
                                                    <td>{ls(payment.amount)} <br/><i>US$</i></td>

                                                    <td>{moment(payment.date).format('LL')}</td>
                                                    <td className="last">
                                                        <i className="silver">coming soon using Xero</i>
                                                    
                                                    </td>
                                                </tr>
                                            ))
                                            : null
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </Flex>

                        <Flex className="allwidth alignstart marged-b">
                            <label className="selfstart spaced-text title">SIGNATURES</label>
                            {
                                openedContract.signatures?.length
                                ? <div className="width90">
                                    <table className="app-table marged-l allwidth nomargin">
                                        <thead>
                                            <tr>
                                                <th>From</th>
                                                <th>Date</th>
                                                <th className="last">Image</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                openedContract.signatures.map((s, si) => <tr key={`ocsign_${si}`}>
                                                    <td><Flex><b>{s.licence === 'admin' ? 'Primuse' : 'Purchaser'}</b><i className="grey">{s.from}</i></Flex></td>
                                                    <td>{moment(s.date).format('LLL')}</td>
                                                    <td className="last"><BiImage className="clickable main-color-fill" size={25} onClick={() => window.open(s.url, '_blank')}/></td>
                                                </tr>)
                                            }
                                        </tbody>
                                    </table>
                                </div>
                                : <i className="grey">void</i>
                            }
                            
                        </Flex>

                        <Flex className="allwidth justifystart marged-b">
                            <Flex row className="allwidth justifystart marged-b">
                                <label className="spaced-text title">DOCUMENT</label>
                                &nbsp;
                                <ToolTip 
                                    title="Document Options" 
                                    content={<ul>
                                        <li><b>Edit</b> option will open the contract editor using datas from this contract. A new document/reference will be created</li>
                                        <li><b>Sign</b> option will affix your signature on the contract <i className="grey">(signable once by both of each parties)</i></li>
                                        <li><b>Make Null And Void</b> option will change the status of the contract to "nullandvoid"</li>
                                    </ul>} 
                                />
                            </Flex>
                            <Flex row className="allwidth spaceevenly">
                                {
                                    isAdmin
                                    ?   <>
                                            {(contractStatus === "waiting both signatures" || contractStatus === "waiting primuse signature") 
                                            &&  <button className="flex row  button button--primary button--round" onClick={() => openSignPad(contractStatus)}><b>SIGN</b> &nbsp; <FaFileSignature size={20}/> </button>}
                                                    
                                            {(!contractStatus === "completed" && !contractStatus === "nullandvoid" && !contractStatus === "signed" && !contractStatus === "in process") 
                                            &&  <button className="small flex row button button--danger button--round" onClick={()=>false}><b>MAKE NULL AND VOID</b> &nbsp; <FaFileSignature size={20}/> </button>}
                                        </>
                                    :   <>
                                            {(contractStatus === "waiting both signatures" || contractStatus === "waiting purchaser signature") 
                                            &&  <button className="flex row  button button--primary button--round" onClick={() => openSignPad(contractStatus)}><b>SIGN</b> &nbsp; <FaFileSignature size={20}/> </button>}
                                        </>
                                }

                                {(!contractStatus === "completed" && !contractStatus === "nullandvoid" && !contractStatus === "signed" && !contractStatus === "in process") 
                                &&  <button className="flex row button button--primary button--round" onClick={editDocData}><b>EDIT</b> &nbsp; <MdEditNote size={25}/> </button>}

                            </Flex>
                            
                        </Flex>

                       
                    </Flex>
                    
                </Flex>

                /** CONTRACTS LIST **/
                : <div className="main-container margedBot100px">

                    {!isAdmin && 
                        <div className="margedTop20px">
                            <PromotorFeedback page="Contracts" />
                        </div>}
                        
                    <h3 className="page-title">Contracts</h3>
                    <Row className="allwidth margedBot30px">
                        <Flex row flex={isAdmin ? 8 : 10} className="justifystart">
                            <div className="search-container">
                                <input className="input-ts search-input-sp" placeholder="Contract reference, artist, promotor..." value={searchValue} onChange={e => { setSearchValue(e.target.value) }} />
                                <span>
                                    <button disabled={true} className="flex btn button--primary btn-search">
                                        <AiOutlineSearch size={20} color="white" />
                                    </button>
                                </span>
                            </div>
                        </Flex>
                        {
                            isAdmin
                            && <Flex flex={2}>
                                <button 
                                    className="flex row button button--primary uppercase"
                                    onClick={showContractForm}
                                >
                                    <BiLayerPlus size={25} />
                                    &nbsp;
                                    New Contract
                                </button>
                            </Flex>
                        }
                    </Row>

                    {/* Status Filtering */}
                    <div className="allwidth flex-only wrap row-gap10 col-gap20 margedBot20px">
                        <button 
                            className={`button ${filterAllStatusButtonActiveClass}`} 
                            onClick={setStatusFilterAllStatus}
                        >
                                All Contracts
                        </button>

                        <button 
                            className={`button ${filterIsPastStatusActive ? 'button--primary' : 'button--ghost'}`} 
                            onClick={() => toggleStatusFilter(pastStatus)}
                        >
                            Past
                        </button>

                        <button 
                            className={`button ${filterIsOngoingStatusActive ? 'button--primary' : 'button--ghost'}`} 
                            onClick={() => toggleStatusFilter(ongoingStatus)}
                        >
                            Ongoing
                        </button>

                        <button 
                            className={`button ${filterIsToSignStatusActive ? 'button--primary' : 'button--ghost'}`} 
                            onClick={() => toggleStatusFilter(toSignStatus)}
                        >
                            Waiting Signatures
                        </button>
                    </div>

                    <Flex flex={10} className="allwidth justifystart">
                        {
                            contractsList?.length    // client contracts
                            ? <table className="app-table">
                                <thead>
                                    <tr>
                                        <th className="leftalign">
                                            <div className="clickable flex-align-center row" onClick={handleSortByDate}>
                                                <p className="margedRight2px">Date</p>
                                                {isDateSortAscending
                                                ?   <AiOutlineCaretUp size={15}/>
                                                :   <AiOutlineCaretDown size={15}/>}
                                            </div>
                                        </th>
                                        <th className="leftalign">Reference</th>
                                        <th className="leftalign">
                                            <div className="clickable flex-align-center row" onClick={handleSortByArtist}>
                                                <p className="margedRight2px">Artist</p>
                                                {isArtistSortAscending
                                                ?   <AiOutlineCaretUp size={15}/>
                                                :   <AiOutlineCaretDown size={15}/>}
                                            </div>      
                                        </th>
                                        <th className="leftalign">
                                            <div className="clickable flex-align-center row" onClick={handleSortByPromotor}>
                                                <p className="margedRight2px">Promotor</p>
                                                {isPromotorSortAscending
                                                ?   <AiOutlineCaretUp size={15}/>
                                                :   <AiOutlineCaretDown size={15}/>}
                                            </div> 
                                        </th>
                                        <th>Countries</th>
                                        <th>
                                            <div className="clickable flex row" onClick={handleSortByShowsNumber}>
                                                <p className="margedRight2px">Shows</p>
                                                {isShowsNumberSortAscending
                                                ?   <AiOutlineCaretUp size={15}/>
                                                :   <AiOutlineCaretDown size={15}/>}
                                            </div> 
                                        </th>
                                        <th>
                                            <div className="clickable flex row" onClick={handleSortByAmount}>
                                                <p className="margedRight2px">Amount</p>
                                                {isAmountSortAscending
                                                ?   <AiOutlineCaretUp size={15}/>
                                                :   <AiOutlineCaretDown size={15}/>}
                                            </div> 
                                        </th>
                                        <th>
                                            <div className="clickable flex row" onClick={handleSortByStatus}>
                                                <p className="margedRight2px">Status</p>
                                                {isStatusSortAscending
                                                ?   <AiOutlineCaretUp size={15}/>
                                                :   <AiOutlineCaretDown size={15}/>}
                                            </div> 
                                        </th>
                                        <th>Assets</th>
                                        <th className="last">Open</th>
                                    </tr>
                                </thead>
                                <tbody className="fw400">
                                    { 
                                        contractsList
                                            //.sort((a, b) => (a.timestamp - b.timestamp))
                                            .map((contract, index) => 
                                            <ContractItemRow 
                                                key={index} 
                                                c={contract}
                                                cind={index}
                                                openContract={openContract}
                                                openingContract={openingContract}
                                            />) 
                                    }
                                    
                                </tbody>
                        
                            </table>
                            : <p className="grey">You have no contract for now</p>
                        }
                    </Flex>
                </div>
            )
        }

        {
            signPadVisible
            && <Flex style={{position: 'absolute', top: 0, left: 0, width: '100vw', height: '100vh', background: 'rgba(0,0,0,.9'}}>
                <Flex style={{position: 'absolute', top: '20%'}}className="white title">
                    {
                        isSigning?.title
                        || <>Contract {openedContract.id}</>
                    }
                </Flex>
                {
                    isSigning   // storeComputedContract
                    ? <Flex className="m-signature-pad">
                        <DocumentLoading
                            texts={[isSigning.text, 'Please Wait']}
                        />
                    </Flex>
                    : <>
                        <SignatureCanvas
                            canvasProps={{className: 'm-signature-pad'}}
                            ref={signPadRef}
                        />
                        <Flex row className="m-signature-pad--footer">
                            <button className="app-button error-button" onClick={closeSignPad}>CLOSE</button>
                            &nbsp;
                            <button className="app-button" onClick={clearSignPad}>Clear</button>
                            &nbsp;
                            <button className="app-button success" onClick={confirmSignature}>Confirm</button>
                        </Flex>
                    </>
                }
            </Flex>
        }
    </Flex>
    
}

export default AdminContracts;