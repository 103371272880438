
const How = () => {

    return (
        <div className="landing-how" id="how">
            <div className="landing-container flex row">
                <div style={{flexBasis: "50%"}}>
                    <img src="landing_how_it_works.png" alt="How the app works" style={{maxWidth:"100%"}} />
                </div>
                
                <div className="landing-how-right-area">
                    <h2 className="margedBot10px">How the App works</h2>
                    <p>Unlock the power of data with Kisum</p>
                    <p>
                        Kisum is an application that allows to collect data from multiple platforms and to use it to provide analytical results quickly. It can include information about users, trends, performance and other metrics that can be used to improve the performance of a business or product. It uses advanced algorithms to select the most relevant data and present it in a clear and concise manner, allowing for faster decision making.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default How