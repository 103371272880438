import Tabs from "../../../../components/tabs-menu/Tabs"
import { useAppSelector } from "../../../../utils/hooks/reduxTypedHooks"
import PromotorTouring from "../../../promotor/touring/PromotorTouring"
import AdminArtists from "./artists/AdminArtists"
import TouringEditList from "./TouringEditList"


const AdminTouring = ( { manager }: { manager?: boolean }) => {

    const tourings = useAppSelector(state => state.tourings)
    const pastTours = tourings.filter(touring => touring.isPast)
    const activeTours = tourings.filter(touring => !touring.isPast)
        
    return(
        <Tabs activeTab="Current Tourings">
            <div option-data='Current Tourings'>
                <TouringEditList tourings={activeTours} />
            </div>
            <div option-data='Display'>
                <PromotorTouring />
            </div>
            <div option-data='Past Tours'>
                <TouringEditList tourings={pastTours} past />
            </div>
            <div option-data='Artists'>
                <AdminArtists />
            </div>
        </Tabs>
    )
}

export default AdminTouring