export const ROUTE_LANDING = "/"
export const ROUTE_LOGIN = "/login"
export const ROUTE_TERMS_AND_CONDITIONS = "/terms-and-conditions"
export const ROUTE_PRIVACY_POLICY = "/privacy-policy"

export const ROUTE_HOME = "/app"
export const ROUTE_DASHBOARD = `${ROUTE_HOME}/dashboard`
export const ROUTE_ORACLE = `${ROUTE_HOME}/oracle`
export const ROUTE_USERS = `${ROUTE_HOME}/users`
export const ROUTE_OFFERS = `${ROUTE_HOME}/offers`
export const ROUTE_PNLS = `${ROUTE_HOME}/pnls`
export const ROUTE_CONTRACTS = `${ROUTE_HOME}/contracts`
export const ROUTE_TASKS = `${ROUTE_HOME}/tasks`
export const ROUTE_AGENDA = `${ROUTE_HOME}/agenda`
export const ROUTE_PROMOTORS = `${ROUTE_HOME}/promotors`
export const ROUTE_MANAGERS = `${ROUTE_HOME}/managers`

export const ROUTE_ARTISTS = `${ROUTE_HOME}/artists`
export const ROUTE_ARTISTS_FEED = `${ROUTE_ARTISTS}/feed`
export const ROUTE_ARTIST_DETAILS = `${ROUTE_ARTISTS}/:artistName`
export const ARTIST_DETAILS_OVERVIEW_ENDPOINT = 'overview'
export const ROUTE_ARTIST_DETAILS_OVERVIEW = `${ROUTE_ARTIST_DETAILS}/${ARTIST_DETAILS_OVERVIEW_ENDPOINT}`
export const ARTIST_DETAILS_TOURS_ENDPOINT = 'tours'
export const ROUTE_ARTIST_DETAILS_TOURS = `${ROUTE_ARTIST_DETAILS}/${ARTIST_DETAILS_TOURS_ENDPOINT}`
export const ARTIST_DETAILS_ALBUMS_ENDPOINT = 'albums'
export const ROUTE_ARTIST_DETAILS_ALBUMS = `${ROUTE_ARTIST_DETAILS}/${ARTIST_DETAILS_ALBUMS_ENDPOINT}`
export const ARTIST_DETAILS_ASSETS_ENDPOINT = 'assets'
export const ROUTE_ARTIST_DETAILS_ASSETS = `${ROUTE_ARTIST_DETAILS}/${ARTIST_DETAILS_ASSETS_ENDPOINT}`
export const ARTIST_DETAILS_INFOS_ENDPOINT = 'infos'
export const ROUTE_ARTIST_DETAILS_INFOS = `${ROUTE_ARTIST_DETAILS}/${ARTIST_DETAILS_INFOS_ENDPOINT}`

export const ROUTE_TOURING = `${ROUTE_HOME}/tourings`
export const ROUTE_POTENTIAL_TOURINGS = `${ROUTE_HOME}/potential-tourings`
export const ROUTE_VENUES = `${ROUTE_HOME}/venues`
export const ROUTE_TOKEN = `${ROUTE_HOME}/token`
export const ROUTE_STATS = `${ROUTE_HOME}/stats`
export const ROUTE_PROFILE = `${ROUTE_HOME}/profile`

export const ROUTE_APIS = `${ROUTE_HOME}/apis`