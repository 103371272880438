import { useEffect } from "react"
import { inputDay, TouringFormInputs } from "../../user-panels/admin/admin-panel-screens/touring/TouringForm"
import { Touring } from "../models/touring.model"

const useFillTouringDays = ( {formInputs, updateInput, touring} : {
    formInputs: TouringFormInputs,
    updateInput: (value: any, inputName: string) => void,
    touring?: Touring,
}) => {

    const millisecondsInADay = 86400000 // 3600 * 24 * 1000
    const dayDifference = (formInputs.to.getTime() - formInputs.from.getTime() ) / millisecondsInADay
    const numberOfDays = dayDifference + 1

    const fillDays = () => {
        if(touring){return} // not filling days if we are in readOnly or edit mode
        let fillerDaysArray: inputDay[] = []
        if(numberOfDays > 0){
            fillerDaysArray = [...Array(numberOfDays)].map((_, index) => {
                const date = new Date(formInputs.from)
                date.setDate(date.getDate() + index)
                return {
                    date,
                    isDayOff: false,
                    country: formInputs.countries[0],
                    offerId: '',
                    offersIds: [],
                    showDuration: formInputs.showDuration,
                    possibleFee: formInputs.possibleFee,
                    blocked: {value: '', label: <div></div>},
                }
            })
        }
        updateInput(fillerDaysArray, 'shows')
    }

    const fillShowDurations = () => {
        if(touring){return} // not filling days if we are in readOnly or edit mode
        const { shows } = formInputs
        if(shows.length > 0){
            const updatedShows = shows.map(show => ({...show, showDuration: formInputs.showDuration}))
            updateInput(updatedShows, 'shows')
        }
    }

    const fillCountries = () => {
        if(touring){return} // not filling days if we are in readOnly or edit mode
        const { shows } = formInputs
        if(shows.length > 0){
            const updatedShows = shows.map(show => ({...show, country: formInputs.countries[0]}))
            updateInput(updatedShows, 'shows')
        }
    }
    
    const fillPossibleFee = () => {
        if(touring){return} // not filling days if we are in readOnly or edit mode
        const { shows } = formInputs
        if(shows.length > 0){
            const updatedShows = shows.map(show => ({...show, possibleFee: formInputs.possibleFee}))
            updateInput(updatedShows, 'shows')
        }
    }

    // (re)generate all the individual days data when the dates are changed
    useEffect(() => {
        fillDays()
    }, [numberOfDays])


    // (re)filling up each data when the global coresponding data is changed
    useEffect(() => {
        fillShowDurations()
    }, [formInputs.showDuration])
    useEffect(() => {
        fillCountries()
    }, [formInputs.countries[0]])
    useEffect(() => {
        fillPossibleFee()
    }, [formInputs.possibleFee])

}

export default useFillTouringDays