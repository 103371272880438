import { Flex } from "../Containers"
import LoadingOval from "./LoadingOval"

const FullPageLoading = () => {

    return (
        <div className="allwidth" style={{height: '100vh', backgroundColor: 'var(--app-background)', zIndex: "100"}}>
            <Flex className='allwidth margedTop50px' style={{minHeight: '80vh'}}>
                <LoadingOval />
            </Flex>
        </div>
    )
}

export default FullPageLoading