import { Promotor } from "../../../../../../utils/models/promotors.model"
import PromotorItem from "../../PromotorItem"

const PromotorsTable = ( { promotors, setUsePromotor, setOpenedPromotor, setOpenPromotor, setActiveTab, isArchived }: 
    {
        promotors: Promotor[],
        setUsePromotor?: React.Dispatch<React.SetStateAction<Promotor | null>>,
        setOpenedPromotor: React.Dispatch<React.SetStateAction<Promotor | null>>,
        setOpenPromotor: React.Dispatch<React.SetStateAction<boolean>>,
        setActiveTab?: React.Dispatch<React.SetStateAction<string>>,
        isArchived?: boolean,
    }) => {

    const editPromotor = (_index:number) => {
        if(setUsePromotor && setActiveTab) {
            setUsePromotor(promotors[_index])
            setActiveTab('create')
        }
    }

    const openPromotorDetails = (promotor: Promotor) => {
        setOpenedPromotor(promotor);
        setOpenPromotor(true);
    }
    
    return (
        <table className="app-table ">
            <thead>
                <tr>
                    <th className="leftalign" style={{width: '18%'}}>Name</th>
                    <th className="leftalign">Account</th>
                    <th className="leftalign">Level</th>
                    <th className="leftalign">Email</th>
                    <th className="leftalign">Company</th>
                    <th>Total Amount</th>
                    <th>Contracts</th>
                    <th>{isArchived ? "Delete" : "Edit"}</th>
                    <th>{isArchived ? "UnArchive" : "Archive"}</th>
                    <th>Open</th>
                </tr>
            </thead>
            <tbody>
                {
                    promotors.length    
                    ? promotors.map((promotor: Promotor, index: number) => 
                    <PromotorItem 
                        key={index} 
                        promotor={promotor} 
                        index={index} 
                        open={openPromotorDetails} 
                        edit={editPromotor}
                        isArchived={isArchived}
                    />)
                    : <tr><td colSpan={8} className="grey"><i>No promotor found</i></td></tr>
                }
            </tbody>
        </table>
    )
}

export default PromotorsTable