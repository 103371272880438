import { Flex } from "../../../../../components/Containers"
import { OfferFormInputs, OfferShowFormInputs } from "../../../../../utils/models/offer.model"
import ShowReview from "../review/ShowReview"
import { ShowsStepsIndexes } from "../ShowItem"

const ShowReviewStep = ({ offer, os, osi, rates, currencySymbols, goToNextOfferStep, reviewToStep, setOfferShow, setShowPnlInputs }: { 
    offer: OfferFormInputs,
    os: OfferShowFormInputs,
    osi: number,
    rates: (null|number)[],
    currencySymbols: (null|string)[],
    goToNextOfferStep: () => void,
    reviewToStep: (stepIndex: ShowsStepsIndexes) => void,
    setOfferShow: (showIndex: number, showProps: {}) => void, 
    setShowPnlInputs: (showIndex: number, props: any) => void,
}) => {

    const handleNext = () => {
        goToNextOfferStep()
    }

    const numberOfShows = offer.shows.length

    const isLastShow = osi === (numberOfShows - 1)

    return (
        <Flex className="alignstart allwidth">

            <div className="title main-color">Review</div> 

            <div className="margedBot30px">
                <ShowReview
                    offer={offer}
                    show={os}
                    showIndex={osi}
                    rates={rates}
                    currencySymbols={currencySymbols}
                    localReviewGoToEdit={reviewToStep}
                    setOfferShow={setOfferShow}
                    setShowPnlInputs={setShowPnlInputs}
                />
            </div>
            <Flex row className="allwidth">
                <button className="button button--primary button--round" onClick={handleNext}>{isLastShow ? "Review and Confirm" : "Next Show"}</button>
            </Flex>

        </Flex>
    )
}

export default ShowReviewStep