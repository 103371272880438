import DropdownItem from './dropdownItem'

const DropdownMenu = ({ items, currentFilter, handleClickItem, isDurations }: { items: string[] | number[], currentFilter: string[] | number[], handleClickItem: any, isDurations?: boolean }) => {

	const dropdownItems = items.map((item, index) => <DropdownItem key={index} currentFilter={currentFilter} item={item} handleClickItem={handleClickItem} isDurations={isDurations} />)

	//todo make menudiv scrollable if more than x items ?
	return (
		<div className="dropdown__dropdown">
			<div className="dropdown__menu">
				{dropdownItems}
			</div>
		</div>
	)
}

export default DropdownMenu