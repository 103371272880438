import { useEffect, useState } from "react";

import { Flex, Row } from "../../../../components/Containers";
import { FormInput } from "../../../../components/form/FormInput";
import SelectInput from "../../../../components/form/form-components/SelectInput";
import { useDispatch } from "react-redux";
import { snackActions } from "../../../../store/snackSlice";
import { usersActions } from "../../../../store/usersSlice";
import useFetchApiAuth from "../../../../utils/hooks/useFetchApiAuth";
import { licencesList } from "../../../../utils/models/client.model"
import useSetSnack from "../../../../utils/hooks/useSetSnack";

import CheckboxSpruha from "../../../../components/form/spruha/CheckboxSpruha";

import FormSection from "../../../../components/form/form-components/FormSection";
import { promisify } from "../../../../utils/utils";
import { AiOutlineUser } from "react-icons/ai";
import { FaUserCheck } from "react-icons/fa";
import { errorToSuccessTheme3 } from "../../../../constants";
import LabelInput from "../../../../components/form/mui/label-input/LabelInput";
import { User } from "../../../../utils/models/users.model";
import { useAppSelector } from "../../../../utils/hooks/reduxTypedHooks";


/**
 * @dev UserForm admin screen
 */
const UserForm = ( {user, close}: { user?: User, close: () => void} ) => {
    const licenceOptions = licencesList.map(licence => ({ value: licence, label: licence.charAt(0).toUpperCase() + licence.slice(1) }))

    const [newUser, _setNewUser] = useState({
        actorId: user?.actorId || '',
        email: user?.email || '',
        active: user?.active ?? true,
        licence: user?.licence ? licenceOptions.find(lo => user.licence === lo.value) : undefined,
    });

    // const [password, setPassword] = useState('')
    // const [confirmedPassword, setConfirmedPassword] = useState('')
    // const [showPassword, setShowPassword] = useState(false)
    // const [showConfirmPassword, setShowConfirmPassword] = useState(false)

    // const toggleShowPassword = () => setShowPassword(prevValue => !prevValue)
    // const toggleShowConfirmPassword = () => setShowConfirmPassword(prevValue => !prevValue)

    // const isEditing = !(user === undefined)

    const [isPosting, setIsPosting] = useState(false);
    const [actorsOptions, setActorsOptions] = useState([]);

    const dispatch = useDispatch();
    const setSnack = useSetSnack()

    const fetchApiAuth = useFetchApiAuth()
    const actorsByLicence = {
        promotor: useAppSelector(state => state.promotors || []),
        manager: [],
        artist: useAppSelector(state => state.artists || []),

    }

    const validateForm = () => {
        if(!newUser.email){
            setSnack({type: 'warning', content: 'Pleae provide a user email'})
            return false
        }

        if(!newUser.licence){
            setSnack({type: 'warning', content: 'Pleae provide a licence'})
            return false
        }

        // if(!isEditing) {
        //     if(!password) {
        //         setSnack({type: 'warning', content: 'Pleae provide a password'})
        //         return false
        //     }

        //     if(password !== confirmedPassword) {
        //         setSnack({type: 'warning', content: 'Passwords don\'t match'})
        //         return false
        //     }
        // }

        return true
    }


    const createNewUser = async() => {
        setIsPosting(true)
        console.log('CREATING NEW USER', newUser);

        // form validation
        const isFormValid = validateForm()

        if(!isFormValid){
            setIsPosting(false)
            return
        }

        // reset licence to only value
        const { licence, ...nUser } = newUser;

        // const client = {
        //     ...newUser,
        //     licence: newUser.licence.value
        // }

        const {error, response} = await fetchApiAuth(
            {
                method: 'post',
                route: 'create-user',
                body: {...nUser, licence: licence?.value, isArchived: false},
            }
        );

        (error||response) && dispatch(snackActions.set(
            error
            ? {type: 'error', content: `Error while creating a new user: ${error}`}
            : {type: 'success', content: 'The new user has been created'})
            );

        if(!error){
            console.log('NEW USER CREATED, user(api response):', response);
            dispatch(usersActions.add(response))
            close();
        }

        // reset form
        setNewUser({
            email: '',
            active: true,
            licence: '',
        })
        
        setIsPosting(false)
    }

    const updateUser = async () => {
        setIsPosting(true)

        // form validation
        const isFormValid = validateForm()

        if(!isFormValid){
            setIsPosting(false)
            return
        }
        if(!user) {
            setIsPosting(false)
            return
        }

        const { licence, ...nUser } = newUser;

        const {error, response} = await fetchApiAuth(
            {
                method: 'post',
                route: 'merge-user',
                // /!\ reset licence to only value
                body: {user, ...nUser, licence: licence ? licence.value : user.licence},
            },
            {returnServerError: true}
        );

        (error||response) && dispatch(snackActions.set(
            error
            ? {type: 'error', content: `Error while updating the user: ${error}`}
            : {type: 'success', content: 'The user has been successfully updated'})
            );

        if(!error){
            console.log('USER UPDATED, response:', response);
            dispatch(usersActions.merge(response))
            close();
        }
        setIsPosting(false)
    }
    
    const setNewUser = (props: any) => _setNewUser({
       
        ...(newUser||{}),
        ...props
        
    });

    const selectedLicence = newUser?.licence?.value || user?.licence;


    const handleLicenceChange = async (lic: any, _bypass: any) => {
        

        setNewUser({licence: lic});
        // if licence changed and needs an actorId, load select avail actors options
        if (
            _bypass
            || (
                (selectedLicence !== lic.value)
                && ((lic.value !== 'admin') && lic.value !== 'regular')
            )) {
            // define select actors options
            // @ts-ignore
            const actors = actorsByLicence[lic.value]
            const _actorsOptions = (
                actors?.length 
                ? await promisify(actors.filter((a: any) => (!a.clientId || a.clientId === user?.id)).map((actor: any) => ({
                    value: actor.id,
                    label: <Flex row className="allwidth clickable justifystart">
                        <Flex row flex={9} className="justifystart allwidth">
                        {
                            actor.logo
                            ? <img alt={`primuse actor ${actor.name}`} src={actor.logo} className="small thumbnail-img" />
                            : <AiOutlineUser size={20} />
                        }
                        &nbsp;
                        <b className="larger">{actor.name||'Unnamed*'}</b> 
                        &nbsp; 
                        Id #{actor.id}
                        {
                            actor.cieName
                            && <>
                                &nbsp; 
                                Company: ({actor.cieName})
                            </>
                        }
                        </Flex>
                        {
                            user
                            && actor.clientId === user?.id
                            && <Flex flex={1} className="smaller allwidth success"><FaUserCheck size={17} color={errorToSuccessTheme3[2]}/> Current</Flex>
                        }
                    </Flex>,
                    searchData: JSON.stringify(actor)
                }))) 
                : []
            );
            setActorsOptions(_actorsOptions)
        }
    }

    useEffect(() => {
        handleLicenceChange(newUser.licence, true)
    }, [])


    return <Flex flex={10} className='allspace'>
        
        {/* New user form */}
        <Flex flex={9} className=" width95 justifystart">
            
            <FormSection title={user ? 'Edit User Access' : 'Create User Access'}>
                <LabelInput 
                    disabled={!!user} 
                    title='Email' 
                    onChange={(e: any) => setNewUser({email: e.target.value})} 
                    value={newUser?.email||''} 
                />
                
                {
                  user 
                  ? <CheckboxSpruha 
                        title='Active Account'
                        checked={newUser.active}
                        onChange={(e: any) => setNewUser({active: e.target.checked})}
                  />
                  : <></>
                }
            </FormSection>

            <Row className="margedTop">
                <FormInput title='Primuse Licence'>
                    <SelectInput
                        title='Licence'
                        noTitle
                        options={licenceOptions}
                        onChange={handleLicenceChange} 
                        value={newUser?.licence||''}
                    />
                    
                </FormInput>
            </Row>

            {
                ((selectedLicence !== 'admin') && selectedLicence !== 'regular')
                && <Row className="margedTop">
                    <FormInput title='Linked Actor'>
                        <SelectInput
                            searchable
                            filterOption={(_actor: any, input: any) => (
                                !input 
                                || _actor.data.searchData.toLowerCase().includes(input.toLowerCase()) 
                            )}
                            title={`Select an available ${selectedLicence} actor`}
                            noTitle
                            options={actorsOptions}
                            onChange={e => setNewUser({actorId: e?.value ?? ''})} 
                            showRawValue
                            value={actorsOptions.find((ao: any) => ao.value === newUser.actorId) ?? ''}
                        />
                        
                    </FormInput>
                </Row>
            }

            {/* {!isEditing
            &&  <FormSection title="" className="allwidth alignstart margedBot20px margedTop20px">
                    <div className="password-container">
                        <LabelInput 
                                title='Password'
                                type={showPassword ? "text" : "password"}
                                onChange={e => setPassword(e.target.value)} 
                                value={password} 
                        />
                        {
                            showPassword
                            ? <AiFillEyeInvisible onClick={toggleShowPassword} size={20} className="show-password-icon" />
                            : <AiFillEye onClick={toggleShowPassword} size={20} className="show-password-icon" />
                        }
                    </div>

                    <div className="password-container">
                        <LabelInput 
                            title='ConfirmPassword' 
                            type={showPassword ? "text" : "password"}
                            onChange={e => setConfirmedPassword(e.target.value)}  
                            value={confirmedPassword} 
                        />
                        {
                            showConfirmPassword
                            ? <AiFillEyeInvisible onClick={toggleShowConfirmPassword} size={20} className="show-password-icon" />
                            : <AiFillEye onClick={toggleShowConfirmPassword} size={20} className="show-password-icon" />
                        }
                    </div>

                </FormSection>} */}

        </Flex>

        {/* Form validation & cancellation */}
        <Row className="spaceevenly allwidth margedTop50px padded10px0">
            {
                user
                ? <button className="button button--primary button--round" disabled={isPosting} onClick={updateUser}>{isPosting ? 'Updating...' : 'Update User'}</button>
                : <>
                    <button className="button button--danger button--round" onClick={close}>Cancel</button>
                    <button className="button button--primary button--round" disabled={isPosting} onClick={createNewUser}>{isPosting ? 'Creating...' : 'Confirm'}</button>
                </>
            }
        </Row>
    
    </Flex>
    
}

export default UserForm;