import PromotorPnls from "../user-panels/promotor/pnls/PromotorPnls"
import { useAppSelector } from "../utils/hooks/reduxTypedHooks"
import { Licence } from "../utils/models/client.model"
import AccessDeniedPage from "./AccessDeniedPage"

const PnlsPage = () => {
    const licence = useAppSelector(state => state.client?.licence)

    if (licence === Licence.Promotor){
        return (<PromotorPnls />)
    } else {
        return (<AccessDeniedPage />)
    }
}

export default PnlsPage