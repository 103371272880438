import { AmountType, OfferShow, OfferShowFormInputs } from "../../models/offer.model"
import { computeOtherIncomesAmount, computePriceAfterVAT, computeSponsorsAmount } from "../../offers.util"

export type TotalTickets = {
    quantity: number,
    netPrice: number,
    compTicketsNumber: number,
    ticketsForSale: number,
    sales: number,
}

const computePnlData = ({ show }: {
    show: OfferShowFormInputs|OfferShow,
}) => {

    const { tickets, ticketsVariables, pnlInputs, dealInputs, hasAirFare, airFare: grossAirFare, amountType, amount } = show

    const { witholdingTax: artistWitholdingTax, promotorDistributionPercent, hasGuaranteedFee, guaranteedFee: grossGuaranteedFee } = dealInputs

    const { taxes } = ticketsVariables

    const { corporateTicketPurchase, variableCosts, fixedCosts } = pnlInputs

    const { vatPercent, ticketFeePercent, creditCardFeePercent, otherTaxesPercent } = taxes

    const isAmountFixed = amountType === AmountType.Fixed
    
    const remainingTaxes = ticketFeePercent + creditCardFeePercent + otherTaxesPercent

    const sponsorAmount = computeSponsorsAmount(show.sponsors, show.sponsored)
    const otherIncomesAmount = computeOtherIncomesAmount(show.otherIncomes, show.hasOtherIncomes)
    const guaranteedFee = isAmountFixed ? amount : hasGuaranteedFee ? grossGuaranteedFee : 0
    const airFare = hasAirFare ? (grossAirFare ?? 0) : 0

    const totalTickets: TotalTickets = {
        quantity: 0,
        netPrice: 0,
        compTicketsNumber: 0,
        ticketsForSale: 0,
        sales: 0,
    }

    const ticketPricesAfterVATArray: number[] = []
    const ticketNetPricesArray: number[] = []
    const compTicketsNumbersArray: number[] = []
    const ticketsForSaleArray: number[] = []
    const salesArray: number[] = []

    tickets.forEach(ticket => {
        const ticketPriceAfterVAT = computePriceAfterVAT(ticket.grossPrice, vatPercent)
        const ticketNetPrice = ticketPriceAfterVAT * (1 - (remainingTaxes/100))
        const compTicketsNumber = Math.round(ticket.quantity * (ticket.compsTicketsPercent/100))
        const ticketsForSale = ticket.quantity - compTicketsNumber
        const sales = ticketsForSale * ticketNetPrice

        ticketPricesAfterVATArray.push(ticketPriceAfterVAT)
        ticketNetPricesArray.push(ticketNetPrice)
        compTicketsNumbersArray.push(compTicketsNumber)
        ticketsForSaleArray.push(ticketsForSale)
        salesArray.push(sales)

        totalTickets.quantity += ticket.quantity
        totalTickets.netPrice += ticketNetPrice
        totalTickets.compTicketsNumber += compTicketsNumber
        totalTickets.ticketsForSale += ticketsForSale
        totalTickets.sales += sales
    })

    const pourcentagesArray = [...new Array(10)].map((_, index) => (index+1) * 10)
    //revenues
    const ticketsSoldArray = pourcentagesArray.map(pourcentage => Math.round(totalTickets.ticketsForSale * (pourcentage/100)))
    const ticketCollectionsArray = pourcentagesArray.map(pourcentage => totalTickets.sales * (pourcentage/100))
    const corporateTicketAmount = corporateTicketPurchase.has ? corporateTicketPurchase.amount : 0
    const totalRevenueArray = pourcentagesArray.map((_, index) => ticketCollectionsArray[index] + sponsorAmount + otherIncomesAmount + corporateTicketAmount)

    //costs
    const costsArrays = variableCosts.map(
        variableCost => pourcentagesArray.map((_, index) => variableCost.has ? (totalRevenueArray[index] * (variableCost.percentage/100)) : 0)
    )
    const totalVariableCostArray = pourcentagesArray.map((_, index) => costsArrays.reduce((total, costArray) => total + costArray[index], 0))

    const artistWitholdingTaxAmount = guaranteedFee * (artistWitholdingTax/100)
    const totalArtistCost = guaranteedFee + artistWitholdingTaxAmount + airFare


    const totalFixedCosts = fixedCosts.reduce((total, cost) => total += (cost.has ? cost.amount : 0), 0)

    //pnl
    const totalExpenditureArray = pourcentagesArray.map((_, index) => totalVariableCostArray[index] + totalArtistCost + totalFixedCosts)
    const profitsArray = pourcentagesArray.map((_, index) => totalRevenueArray[index] - totalExpenditureArray[index])

    const artistDistrubutionPercent = 100 - promotorDistributionPercent
    const artistProfitsArray = profitsArray.map(profit => (profit > 0) ? profit * (artistDistrubutionPercent/100) : 0)
    const promotorProfitsArray = profitsArray.map(profit => (profit > 0) ? profit * (promotorDistributionPercent/100) : 0)

    return { 
        ticketPricesAfterVATArray, ticketNetPricesArray, compTicketsNumbersArray, ticketsForSaleArray, salesArray, totalTickets, pourcentagesArray, ticketsSoldArray, ticketCollectionsArray,
        totalRevenueArray, sponsorAmount, otherIncomesAmount, corporateTicketAmount, totalVariableCostArray, guaranteedFee, artistWitholdingTaxAmount, airFare, totalArtistCost,
        totalFixedCosts, totalExpenditureArray, profitsArray, artistProfitsArray, promotorProfitsArray, artistDistrubutionPercent
    }
}

export default computePnlData