import { pnlAmountFormatter } from "../../../../../../utils/utils"

const ProfitAndLossTable = ({ currency, currencySymbol, rate, pourcentagesArray, totalExpenditureArray, profitsArray, ticketsSoldArray, 
    artistProfitsArray, promotorProfitsArray, artistDistrubutionPercent, promotorDistributionPercent, isAmountDeal }: {
    currency: string,
    currencySymbol: null|string,
    rate: null|number,
    pourcentagesArray: number[],
    totalExpenditureArray: number[],
    profitsArray: number[],
    ticketsSoldArray: number[],
    artistProfitsArray: number[],
    promotorProfitsArray: number[],
    artistDistrubutionPercent: number,
    promotorDistributionPercent: number,
    isAmountDeal: boolean,
}) => {

    const convertToUSD = (amount: number) => {
        return rate ? amount / rate : 0
    }

    return (
        <table className="app-table">
            <thead>
                <tr>
                    <th className="leftalign uppercase" style={{width: "11%"}}>Profit And Loss</th>
                    <th style={{width: "3%"}}>Remarks</th>
                    {pourcentagesArray.map((pourcentage, index) => 
                        <th key={pourcentage} style={{width: "8%"}} className={`leftalign ${profitsArray[index] > 0 ? "green-color" : "error"}`}>
                            {pourcentage}%
                        </th>)}
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th className="leftalign">Total Expenditure ({currencySymbol ?? currency})</th>
                    <th>{currencySymbol ?? currency}</th>
                    {totalExpenditureArray.map((totalExpenditure, index) => <th key={index} className="leftalign">-{currencySymbol ?? currency} {pnlAmountFormatter(totalExpenditure)}</th>)}
                </tr>
                <tr>
                    <th className="leftalign">Total Expenditure (US$)</th>
                    <th>US$</th>
                    {totalExpenditureArray.map((totalExpenditure, index) => <th key={index} className="leftalign">-${pnlAmountFormatter(convertToUSD(totalExpenditure))}</th>)}
                </tr>
                <tr>
                    <th className="leftalign">Loss / Profit ({currencySymbol ?? currency})</th>
                    <th>{currencySymbol ?? currency}</th>
                    {profitsArray.map((profit, index) => <th key={index} className={`leftalign ${profit > 0 ? "green-color" : "error"}`}>{currencySymbol ?? currency} {pnlAmountFormatter(profit)}</th>)}
                </tr>
                <tr>
                    <th className="leftalign">Loss / Profit (US$)</th>
                    <th>US$</th>
                    {profitsArray.map((profit, index) => <th key={index} className={`leftalign ${profit > 0 ? "green-color" : "error"}`}>${pnlAmountFormatter(convertToUSD(profit))}</th>)}
                </tr>
                <tr>
                    <th className="leftalign">Ticket Sales</th>
                    <th></th>
                    {ticketsSoldArray.map((ticketsSold, index) => <th key={index} className={`leftalign ${profitsArray[index] > 0 ? "green-color" : "error"}`}>{ticketsSold}</th>)}
                </tr>
                {isAmountDeal
                &&  <>
                        <tr>
                            <th className="leftalign">Artist</th>
                            <th>{artistDistrubutionPercent}%</th>
                            {artistProfitsArray.map((profit, index) => <th key={index} className="leftalign">${pnlAmountFormatter(convertToUSD(profit))}</th>)}
                        </tr>
                        <tr>
                            <th className="leftalign">Promotor</th>
                            <th>{promotorDistributionPercent}%</th>
                            {promotorProfitsArray.map((profit, index) => <th key={index} className="leftalign">${pnlAmountFormatter(convertToUSD(profit))}</th>)}
                        </tr>
                    </>}
            </tbody>
        </table>
    )
}

export default ProfitAndLossTable