import Tabs from "../../../components/tabs-menu/Tabs"
import PromotorFeedback from "../common/feedback/PromotorFeedback"
import DraftsPnls from "./DraftsPnls"
import OffersPnls from "./OffersPnls"

const PromotorPnls = () => {

    return (
       <>
            <div className="main-container margedTop20px">
                <PromotorFeedback page="Pnls" />
            </div>
            
            <Tabs activeTab='Offers'>
                <div option-data='Offers' data-icon='tabler:shopping-cart'>
                    <OffersPnls />
                </div>

                <div option-data='Drafts' data-icon='material-symbols:edit-document-outline-rounded'>
                    <DraftsPnls />
                </div>
            </Tabs>
        </>
    )
}

export default PromotorPnls