import { useState } from "react"
import LabelInput from "../../../../components/form/mui/label-input/LabelInput"
import { useAppSelector } from "../../../../utils/hooks/reduxTypedHooks"
import useFetchApiAuth from "../../../../utils/hooks/useFetchApiAuth"
import useSetSnack from "../../../../utils/hooks/useSetSnack"
import { FeedbackCreateObject } from "../../../../utils/models/feedbacks.model"

const FeedbackForm = ({ close }: { close: () => void }) => {
    
    const [text, setText] = useState('')
    const [isPosting, setIsPosting] = useState(false)

    const client = useAppSelector(state => state.client)
    const setSnack = useSetSnack()
    const fetchApiAuth = useFetchApiAuth()

    const handleSubmit = async () => {
        setIsPosting(true)

        if(!text) {
            setSnack({type: 'warning', content: "Please enter a message"})
            setIsPosting(false)
            return
        }

        const actorId = client?.id
        if(!actorId) {
            setSnack({type: 'warning', content: "Account not found."})
            setIsPosting(false)
            return
        }

        const newFeedback: FeedbackCreateObject = {
            text,
            from: actorId,
        }

        // await post
        const { error, response } = await fetchApiAuth({
            method: 'POST',
            route: 'feedback/add',
            body: {feedback: newFeedback}
        })

        setSnack(
            error
            ? {
                type: 'error', 
                content: error.toString()
            }
            : {
                type: 'success',
                content: 'Feedback sent successfully.'
            }
        )

        setIsPosting(false)
        close()
    }

    return (
        <div className="main-container">
            <LabelInput
                textarea
                title="Message"
                value={text}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setText(e.target.value)}
            />

            <button onClick={handleSubmit} disabled={isPosting} className="button button--primary button--round margedTop30px">{isPosting ? "Sending..." : "Send Feedback"}</button>
        </div>
    )
}

export default FeedbackForm