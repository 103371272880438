import moment from "moment"
import Accordion from "../../../../../components/accordion/Accordion"
import { Flex } from "../../../../../components/Containers"
import { Touring } from "../../../../../utils/models/touring.model"

const TourAssets = ({ tour }: { tour: Touring}) => {

    const fromDisplay = moment(new Date(tour.from)).format("MM/DD/YYYY")
    const toDisplay = moment(new Date(tour.to)).format("MM/DD/YYYY")

    return(
        <div className="margedBot10px">
            {/*@ts-ignore*/}
            <Accordion
                title={<Flex className="allwidth alignstart">
                    <h3>{ tour.name || 'Tour without name' }</h3>
                    <p style={{fontWeight: '10px'}}>From {fromDisplay} until {toDisplay}</p>
                </Flex>}
                content={(tour.assets||[]).map((asset:any) => asset.name)}  // @todo: render asset here
            />
        </div>
    )
}

export default TourAssets