import { useRef, useState } from "react"
import Dialog from "../../../../components/dialog/Dialog"
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks/reduxTypedHooks"
import { AmountType, Offer, OfferStatus } from "../../../../utils/models/offer.model"
import { createPortal } from "react-dom"
import offerStatus from "../../../common/offers/offerStatus"
import { Flex, Row } from "../../../../components/Containers"
import moment from "moment"
import { b64toBlob, ls, pnlAmountFormatter } from "../../../../utils/utils"
import { countries } from "../../../../utils/models/touring.model"
import OfferForm from "../form/OfferForm"
import useFetchApiAuth from "../../../../utils/hooks/useFetchApiAuth"
import useSetSnack from "../../../../utils/hooks/useSetSnack"
import { offersActions } from "../../../../store/offersSlice"
import ToolTip from "../../../../components/ToolTip"
import { MdOpenInNew } from "react-icons/md"
import { primuseApi } from "../../../../constants"
import ViewOffer from "../../../admin/admin-panel-screens/offers/ViewOffer"
import { BsArrowsFullscreen } from "react-icons/bs"
// @ts-ignore
import SignatureCanvas from 'react-signature-canvas'
import { FaFileSignature } from "react-icons/fa"
import Axios from 'axios';
import useViewPNLs from "../../../../utils/hooks/offer/useViewPNLs"


const OfferItemRow = ({ offer, past }: { offer: Offer, past?:boolean }) => {

    const [editOffer, setEditOffer] = useState(false)
    const [viewOffer, setViewOffer] = useState(false)
    const [binderVisible, setBinderVisible] = useState(false)
    const [isSigning, setIsSigning] = useState(false)
    const [isPosting, setIsPosting] = useState(false)

    const { pnlsModal, openPnlsButton } = useViewPNLs({offer})

    const rootModalDiv = document.getElementById('modal-root')!
    
    const artists = useAppSelector(state => state.artists)
    const artist = artists.filter(artist => artist.id === offer.artistId)[0]

    const client = useAppSelector(state => state.client)
    const promotor = client?.promotor

    const dispatch = useAppDispatch()
    const fetchApiAuth = useFetchApiAuth()
    const setSnack = useSetSnack()
    const signPadRef = useRef();

    if(!promotor) {
        return (
            <p className="gray margedTop50px">
                Promotor Account not Found.
            </p>
        )
    }
    
    const clearBinder = async() => {
        // @ts-ignore
        signPadRef?.current && signPadRef.current.clear();
    }

    const openBinder = () => setBinderVisible(true)
    const closeBinder = () => setBinderVisible(false)

    const openEditOffer = () => setEditOffer(true)
    const closeEditOffer = () => setEditOffer(false)

    const openViewOffer = () => setViewOffer(true)
    const closeViewOffer = () => setViewOffer(false)

    const showCountries = offer.shows.map(show => countries[show.country])
    const showDates = offer.shows.map(show => moment(new Date(show.date)).format('MM/D/YYYY'))

    const { label, details, icon }:{label: string, details: string, icon: JSX.Element} = offerStatus[offer.status]

    const isTrusteeCheck = offer.status === OfferStatus.PromotorTrusteeCheck;
    const isRequestEdition = offer.status === OfferStatus.RequestEdition;

    const showAmounts = offer.shows.map(show => {
        let amount = `${show.localCurrency} ${pnlAmountFormatter(show.amount)}`
        if(show.amountType === AmountType.Deal) {
            amount = "Deal"
        }
        return amount
    })


    const handleWithdrawOffer = async () => {
        setIsPosting(true)
        const body = {
            offerId: offer.id,
            // comments: ''
        }
        
        const { error, response: updatedOffer } = await fetchApiAuth({
            method: "POST",
            route: "promotor-withdraw-offer",
            body
        })

        // snack
        setSnack(
            error
            ? {
                type: 'error', 
                content: error.toString()
            }
            : {
                type: 'success',
                content: "Your offer has been withdrawn successfully."
            })

        //update Redux state
        if(!error){
            dispatch(offersActions.merge(updatedOffer))
        }
        setIsPosting(false)
    }

    const signBinder = () => {
        openBinder()
    }

    const confirmSignature = async() => {
        setIsSigning(true); // offer is being signed
        try {
            //@ts-ignore
            const bS4Signature = signPadRef.current.toDataURL('png');
         
            const blobSignature = await b64toBlob(bS4Signature.split(',')[1], 'image/png');
          
            let fd = new FormData();

            //@ts-ignore
            fd.append('image', blobSignature, `${offer.id}_${client.licence}_signature.png`);

            // store signature to contract using axios
            Axios.interceptors.request.use((config) => {
                // @ts-ignore
                config.headers['X-Auth-Token'] = client.token;
                return config;
            });
            // @ts-ignore
            const axiosResponse = await Axios({
                method: "post",
                url: `${primuseApi}/sign-binder/${offer.id}`,
                data: fd,   // FormData
                config: { headers: { "Content-Type": "multipart/form-data"/*, 'X-Auth-Token': client.token*/ } }
            });
            
            closeBinder();
            console.log('OFFER UPDATED AFTER SIGN (axiosResponse.data):', axiosResponse.data);
            // update local contracts
            dispatch(offersActions.merge(axiosResponse.data))

            
            setIsSigning(false);

        }catch(e) {
            setIsSigning(false);
            console.log('ERROR WHILE SIGNING CONTRACT :', e);
            setSnack({
                type: 'error',
                // @ts-ignore
                content: e.response?.data || e.message | "Error while signing the contract, service may be down"
            })
        }
    }

    return (
        <>
            {createPortal(
                <Dialog
                    visible={binderVisible}
                    onClose={closeBinder}
                    backdropClose={false}
                    dialogCustomStyle={{overflowY: 'scroll', height: '90vh', top: '5vh', width: '80%', left: '10%'}}
                    notAllSpace={true}
                >   
                    <Flex flex={10} row className='allspace alignstart padded'>
                        <Flex flex={5} className='justifystart'>
                            <Flex row className='marged-v'>
                                <i className="theme1">Document Reference: </i> 
                                &nbsp;
                                { offer.binderLetter?.data.id }
                                &nbsp;&nbsp;
                                <BsArrowsFullscreen
                                    className="theme1fill clickable" 
                                    size={15}
                                    onClick={() => window.open(offer.binderLetter?.url, '_blank')}
                                />
                               
                            </Flex>
                            <iframe /*ref={docviewRef}*/ src={offer.binderLetter?.url} style={{width:'40vw', height:'80vh'}}/>
                        </Flex>

                        <Flex flex={5} className='justifystart margedTop spaceevenly' style={{height: '87vh'}}>
                                <Flex flex={9}>
                                <Flex row className="xxlarge bold margedTop">
                                    <FaFileSignature size={35} className="theme1fill" />
                                    &nbsp;<b>Your signature is required</b>
                                </Flex>
                                <p className="margedTop ">Required Deposit Amount : <b>US$ { offer.binderLetter?.data.deposit.replace(/ /g, ',') }</b></p>
                                    <SignatureCanvas
                                        canvasProps={{
                                            className: 'm-signature-pad nomargin', 
                                            style: { position: 'relative', left: 10, top: 40, width: '30vw', height: '40vh' }
                                        }}
                                        ref={signPadRef}
                                    />
                                </Flex>
                                <Flex flex={10} className="allheight"> <Row>
                                    <button className="app-button error-button" onClick={closeBinder}>CLOSE</button>
                                    &nbsp;
                                    <button className="app-button" onClick={clearBinder}>Clear</button>
                                    &nbsp;
                                    <button className="app-button success" onClick={confirmSignature}>Confirm</button></Row>
                                </Flex>
                            
                        </Flex>
                    </Flex>
                </Dialog>, rootModalDiv)
            }

            {createPortal(
                <Dialog
                    visible={editOffer}
                    onClose={closeEditOffer}
                    backdropClose={false}
                    dialogCustomStyle={{overflowY: 'scroll', height: '90vh', top: '5vh', width: '80%', left: '10%'}}
                    notAllSpace={true}
                >
                    <OfferForm 
                        artist={artist}
                        promotor={promotor}
                        closeForm={closeEditOffer}
                        offerToEdit={offer}
                    />
                </Dialog>, rootModalDiv)}
            
            {createPortal(
            <Dialog
                visible={viewOffer}
                onClose={closeViewOffer}
                dialogCustomStyle={{overflowY: 'scroll', height: '90vh', top: '5vh', width: '80%', left: '10%', backgroundColor: 'var(--app-background)'}}
                notAllSpace={true}
            >
                <ViewOffer 
                    artist={artist}
                    offer={offer}
                    promotor={promotor}
                    close={closeViewOffer}
                />
            </Dialog>, rootModalDiv)}

            {pnlsModal}


            <tr className="fw400">
                <td className="leftalign"><p className="clickable" onClick={openViewOffer}>{moment(new Date(offer.date)).format('MM/D/YYYY h:mm A')}</p></td>
                <td className="leftalign">{moment(new Date(offer.expiryDate)).format('MM/D/YYYY')}</td>
                <td className="leftalign">{artist ? <b>{ artist.displayableName }</b> : "Artist not found"}</td>
                <td><p className="clickable" onClick={openViewOffer}>{offer.shows.length}</p></td>
                <td className="leftalign">
                    <p className="clickable" onClick={openViewOffer}>
                        {showCountries.join(' - ')}
                    </p>
                </td>
                <td className="leftalign">
                    <p className="clickable" onClick={openViewOffer}>
                        {showDates.join(' - ')}
                    </p>
                </td>
                <td className="leftalign">
                    <p className="clickable" onClick={openViewOffer}>
                        <b>{showAmounts.join(' - ')}</b>
                    </p>
                </td>
                <td>
                    <div className="flex-align-center row">
                        <Flex className="margedRight10px">{icon}</Flex>
                        <Flex className="bold margedRight5px">{label}</Flex>
                        <ToolTip 
                            title={label} 
                            content={<Flex>
                                {details}
                                
                                {
                                    (offer.status === 'PER')   // waiting promotor edition
                                    && offer.history?.length
                                    // && <button className="button button--primary xsmall hoverscale margedTop offer__action-btn">See Reasons</button>
                                    && <>
                                        <div className="divider marged-v" />
                                        <p className="bold">{offer.history[offer.history.length-1].comment || <i>No specified reason</i>}</p>
                                    </>
                                }
                            </Flex>} 
                        />
                    </div>
                </td>
                <td>
                    <MdOpenInNew className="main-color-fill clickable hoverscale" size={25} onClick={openViewOffer} />
                </td>
                <td>
                    {openPnlsButton}
                </td>
                {
                    !past
                    &&  <td>
                            <Flex>
                                {
                                    isTrusteeCheck 
                                    && <button className="button button--primary button--small offer__action-btn" onClick={signBinder}>Sign Binder</button>
                                }
                                {
                                    isRequestEdition 
                                    &&  <>
                                            <button className="button button--primary button--small offer__action-btn" onClick={openEditOffer}>Edit</button>
                                            <button className="button button--danger button--small offer__action-btn" onClick={handleWithdrawOffer}>{isPosting ? 'Withdrawing...' : 'Withdraw'}</button>
                                        </>
                                }
                                
                                {(!isTrusteeCheck && !isRequestEdition) && <p>-</p>}
                                
                            </Flex>
                        </td>
                }
            </tr>
        </>
    )
}

export default OfferItemRow