
const Partners = () => {

    return (
        <div className="landing-partners">
            <div className="landing-container">
                <h3 style={{fontSize: "22px"}} className="fw400 margedBot20px">Collecting datas from</h3>
                <img src="/partners_brands.png" alt="partners" />
            </div>
        </div>
    )
}

export default Partners