import { useEffect, useState } from "react"
import { BiLayerPlus } from "react-icons/bi"
import { useLocation } from "react-router-dom"
import { Flex, Row } from "../../../../components/Containers"
import LoadingOval from "../../../../components/loading/LoadingOval"
import { useAppSelector } from "../../../../utils/hooks/reduxTypedHooks"
import useFilter from "../../../../utils/hooks/useFilter"
import { Touring, TouringWithArtist } from "../../../../utils/models/touring.model"
import { getTouringsWithArtistFromTourings } from "../../../../utils/touring.util"
import TouringForm from "./TouringForm"
import TourItemRow from "./TourItemRow"

const TouringEditList = ({ tourings, past }: {tourings: Touring[], past?: boolean}) => {
    const [activeTab, setActiveTab] = useState('list')

    const [computedToursWithArtist, setComputedToursWithArtist] = useState<TouringWithArtist[] | undefined>(undefined)

    const location = useLocation()
    const locationState = location.state
    
    useEffect(() => {
        if(locationState?.toAddTouring){
            setActiveTab('create')
        }
    }, [locationState])

    useEffect(() => {
        const computeToursWithArtist = async () => {
            const tours = await getTouringsWithArtistFromTourings(artists, tourings)
            setComputedToursWithArtist(tours)
        }

        computeToursWithArtist()
    }, [tourings])
    
    const artists = useAppSelector(state => state.artists)
    const {FilterUI, filterInput} = useFilter("Artist name")
    
    if(computedToursWithArtist === undefined){
        return (<Flex>
            <LoadingOval />
        </Flex>)
    }
    
    const handleCloseForm = () => setActiveTab('list')

    const filteredTours = computedToursWithArtist.filter(tour => tour.artist.displayableName.toLowerCase().includes(filterInput.toLowerCase()))
    // sorting chronologically by from date 
    filteredTours.sort((tourA, tourB) => new Date(tourA.from).getTime() - new Date(tourB.from).getTime())

    return (
        <Flex flex={10} className='allwidth'>
            {activeTab === 'create'
            ?   <TouringForm 
                    artist={locationState?.artist} 
                    artistToAdd={locationState?.artistToAdd}
                    streamingDatas={locationState?.streamingDatas}
                    addArtist={locationState?.addArtist} 
                    potentialTouring={locationState?.potentialTouring} 
                    goBack={handleCloseForm}
                />
            :   <div className="main-container margedBot100px">
                    <h3 className="page-title  margedTop30px">{past ? "Past " : ""}Tourings</h3>

                    <Row className="allwidth margedBot30px">
                        <Flex row flex={8}>
                            <div className="allwidth">{FilterUI}</div>
                        </Flex>
                        <Flex flex={2}>
                            <button 
                                className="flex row button button--primary uppercase"
                                onClick={() => setActiveTab('create')}
                            >
                                <BiLayerPlus size={25} />
                                &nbsp;
                                New Touring
                            </button>
                        </Flex>
                    </Row>

                    <Flex row flex={10} className="justifystart wrap spaceevenly allwidth">
                        {filteredTours 
                        ?
                            <table className="app-table">
                                <thead>
                                <tr>
                                    <th className="leftalign" style={{width: "25%"}}>With Artist</th>
                                    <th className="leftalign">From</th>
                                    <th className="leftalign">To</th>
                                    <th className="leftalign">In Countries</th>
                                    <th className="leftalign">Comment</th>
                                    <th>Edit data</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredTours.map((tourWithArtist: TouringWithArtist, index: number) => <TourItemRow key={index} tour={tourWithArtist} />)}
                            </tbody>
                            </table>
                        
                        :   <p className="grey">There is no tours that match.</p>}

                    </Flex>
                </div>}
        </Flex>
    )
}

export default TouringEditList