import { Promotor } from "../../../../../utils/models/promotors.model"
import ProfileForm from "./ProfileForm"

const ProfileDetails = ({ promotor }: {promotor: Promotor}) => {

    return (
        <div className="app-card">
            <p className="card-title margedBot10px">Profile Details</p>
            <ProfileForm promotor={promotor} />
        </div>
)
}

export default ProfileDetails