import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import { TouringDayWithTouringId } from "../../../../../utils/models/touring.model"
import Dialog from '../../../../../components/dialog/Dialog'
import CalendarEventModal from '../../../touring/CalendarEventModal'
import { useState } from 'react'
import { countries } from "../../../../../utils/models/touring.model"
import moment from "moment"
import { Flex } from '../../../../../components/Containers'
import { plural } from '../../../../../utils/utils'
import { Artist, ArtistFromDB, NormalizedArtistMarcoDB } from '../../../../../utils/models/artists.model'
import { useAppSelector } from '../../../../../utils/hooks/reduxTypedHooks'
import { colors } from '../../../../../constants'
import useIsPromotor from '../../../../../utils/hooks/useIsPromotor'
import useSetSnack from '../../../../../utils/hooks/useSetSnack'

const ArtistDaysCalendar = ({ days, artist, openOfferForm }: { days: TouringDayWithTouringId[]|null, artist: Artist|ArtistFromDB|NormalizedArtistMarcoDB, openOfferForm: Function }) => {

    const initialIndexes = days ? days.map(_ => false) : []
    const [selectedIndexes, setSelectedIndexes] = useState(initialIndexes)
    const [showModal, setShowModal] = useState(false)
    const [activeEvent, setActiveEvent] = useState<{touring: TouringDayWithTouringId, index: number} | undefined>(undefined)
    // const [offerFormVisible, setOfferFormVisible] = useState(false);

    const contracts = useAppSelector(state => state.contracts)
    const isPromotor = useIsPromotor()
    const setSnack = useSetSnack()

    const openModal = () => setShowModal(true)
    const closeModal = () => setShowModal(false)

    const selectedDays = days ? days.filter((_, index) => selectedIndexes[index]) : []
    
    // [available, dayBlocked, daySigned, selected, promotorBusy]
    const eventColors = [colors.green, colors.orange, colors.red, colors.lightblue, colors.main, colors.gray]
    const eventBgColors = [colors.bgGreen, colors.bgOrange, colors.bgRed, colors.bgLightblue, colors.bgMain, colors.bgGray]

    const promotorBusyDays: {title: string, start: string, color: string, textColor: string}[] = []
    if(isPromotor){
        contracts.forEach(contract => {
            const color = eventBgColors[4]
            const textColor = eventColors[4]
            const title = `Event booked with ${contract.artist.name}`
    
            contract.shows.forEach(show => {
                const start = show.date
                promotorBusyDays.push({title, color, textColor, start})
            })
        })
    }

    let firstEventTime: null | number = null
    let lastEventTime: null | number = null

    const formatedDays = days 
    ?   days.map((day, index) => {
            const dayTime = new Date(day.date).getTime()
            // find first and last tours dates for navigation limitations
            if(!firstEventTime || dayTime < firstEventTime){ firstEventTime = dayTime }
            if(!lastEventTime || dayTime > lastEventTime){ lastEventTime = dayTime }

            const title = countries[day.country]
            const start = moment(new Date(day.date)).format('YYYYMMDD')
            //todo put the day already signed color when day is signed ?
            const colorIndex = day.isPast ? 5 : selectedIndexes[index] ? 3 : day.blocked.to !== '' ? 1 : 0
            const color = eventBgColors[colorIndex]
            const textColor =eventColors[colorIndex]
            
            const classNames = day.blocked.to !== '' ? ['no-hover-opacity', 'cursor-default'] : []
            const isClickable = (day.blocked.to !== '' || day.isPast) ? false : true

            const extendedProps = {touring: day, index, isClickable}
            return {title, start, color, textColor, extendedProps, classNames}
        })
    :   []

    const handleEventClick = (info:any) => {
        if(!info?.event?.extendedProps?.isClickable){return}
        
        const touring: {touring: TouringDayWithTouringId, index: number }| undefined = info?.event?.extendedProps
        setActiveEvent(touring)
        openModal()
    }

    const handleToggleDay = (dayIndex: number) => {
        setSelectedIndexes(prevTourDays => {
            const newTourDays = [...prevTourDays]
            newTourDays[dayIndex] = !prevTourDays[dayIndex]
            return newTourDays
        })
        closeModal()
    }
    
    const handleRemoveSelectedDay = (dayToRemove: TouringDayWithTouringId) => {
        const dayIndex = days ? days.findIndex(day => day === dayToRemove) : -1
        if(dayIndex === -1){return}
        setSelectedIndexes(prevDays => {
            const newTourDays = [...prevDays]
            newTourDays[dayIndex] = false
            return newTourDays
        })
    }

    const handleRemoveAllDays = () => {
        setSelectedIndexes(selectedIndexes.map(_ => false))
    }

    const handleBookDay = (selectedDay: TouringDayWithTouringId) => {
        if(!isPromotor) {
            setSnack({type: "warning", content: "Only a Kisum Promotor can book a show"})
            return
        }
        openOfferForm([selectedDay])
    }

    const handleBook = () => {
        if(!isPromotor) {
            setSnack({type: "warning", content: "Only a Kisum Promotor can book a show"})
            return
        }
        openOfferForm(selectedDays)
    }

    const firstEventFirstDayOfMonth = firstEventTime ? new Date(firstEventTime) : new Date()
    firstEventFirstDayOfMonth.setDate(1)
    const lastEventLastDayOfMonth = lastEventTime ? new Date(lastEventTime) : new Date()
    lastEventLastDayOfMonth.setDate(31)
    // adding one because end limit is exclusive
    lastEventLastDayOfMonth.setDate(lastEventLastDayOfMonth.getDate() + 1)
    const calendarLimits = {start: moment(firstEventFirstDayOfMonth).format('YYYYMMDD'), end: moment(lastEventLastDayOfMonth).format('YYYYMMDD')}

    return (
        <div>
            <Dialog visible={showModal} onClose={closeModal} dialogCustomStyle={{height: 'auto', top: '30vh'}}>
                <CalendarEventModal 
                    touringDay={activeEvent?.touring}
                    artist={artist}
                    selectedDays={selectedIndexes} 
                    dayIndex={activeEvent?.index} 
                    toggleDay={handleToggleDay} 
                    bookDay={handleBookDay}
                />
            </Dialog>

            <div className='app-card'>
                <div className='margedBot10px'>
                    <Flex row className='margedTop20px'>
                        <p className='arist-calendar__tooltip-item'>
                            <span className='calendar__tooltip-span' style={{backgroundColor: colors.main}}></span> 
                            Day already busy for me
                        </p>
                        <p className='arist-calendar__tooltip-item'>
                            <span className='calendar__tooltip-span' style={{backgroundColor: colors.lightblue}}></span> 
                            Day selected
                        </p>
                        <p className='arist-calendar__tooltip-item'>
                            <span className='calendar__tooltip-span' style={{backgroundColor: colors.green}}></span> 
                            Day available
                        </p>
                        <p className='arist-calendar__tooltip-item'>
                            <span className='calendar__tooltip-span' style={{backgroundColor: colors.orange}}></span> 
                            Day already blocked
                        </p>
                        <p className='arist-calendar__tooltip-item'>
                            <span className='calendar__tooltip-span' style={{backgroundColor: colors.red}}></span> 
                            Day already signed
                        </p>
                    </Flex>
                </div>
                <FullCalendar
                    validRange={calendarLimits}
                    plugins={[ dayGridPlugin ]}
                    initialView="dayGridMonth"
                    events={[...promotorBusyDays, ...formatedDays]}
                    dayMaxEventRows={4}
                    eventClick={handleEventClick}
                    eventClassNames="clickable hover-opacity"
                    aspectRatio={2}
                />
            </div>

            {(days && days.length > 0 )
            &&  <> 
                    <div className='margedTop20px margedBot20px'>
                    <h3>Selected Days :</h3>
                    {selectedDays.length
                    ?   
                        <div className='flex-only row'>
                            {selectedDays.map((day, index) => 
                            <div className='artist__day-selected-div'>
                                <p>{countries[day.country]}</p>
                                <p>{moment(new Date(day.date)).format('MMMM Do YYYY')}</p>
                                <button className='button button--primary button--round button--small' onClick={e => handleRemoveSelectedDay(day)}>Remove day</button>
                            </div>)}
                        </div>
                    : <p className='grey'>You have no selected days.</p>}
                    </div>
                    <button onClick={handleRemoveAllDays} className="margedRight20px button button--ghost button--round">Remove all days</button>
                    <button onClick={handleBook} disabled={!selectedDays.length} className="button button--primary button--round">
                        Book ({selectedDays.length}) {plural('day', selectedDays.length)}
                    </button>
                </>}
        </div>
    )
}

export default ArtistDaysCalendar