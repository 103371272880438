import { Artist, ArtistWithTours } from "./models/artists.model";
import { Country, PotentialTouring, PotentialTouringWithArtist, Touring, TouringDay, TouringDayWithTouringId, TouringWithArtist } from "./models/touring.model";
import { forEachAsync } from "./utils";

export const LOCATION_EVERYWHERE = "Everywhere"
export const SHOW_TYPE_ALL_TYPES = "All types"
export const SHOW_DURATION_ALL_DURATIONS = -1

export const getArtistsWithToursFromTourings = async (artists: Artist[], tourings: Touring[]) => {

    let artistsWithTours: ArtistWithTours[] = []

    forEachAsync(tourings,
        ((touring: Touring) => {
        const artistIndex = artists.findIndex(artist => artist.id === touring.artistId)
        if(artistIndex !== -1){
            // check if we already have our artist in our result array
            const resultIndex = artistsWithTours.findIndex(resultArtist => resultArtist.id === touring.artistId)

            const touringCopy = {...touring}

            // push a new artist to the array if he's not there yet
            if(resultIndex === -1){
                const artistWithTours = {...artists[artistIndex], tours: [touringCopy]}
                artistsWithTours.push(artistWithTours)
            }
            
            // add the new tour to the artist's tours if he's already here
            else {
                artistsWithTours[resultIndex].tours.push(touringCopy)

                // sorting tours by startDate
                artistsWithTours[resultIndex].tours.sort((tourA, tourB) => new Date(tourA.from).getTime() - new Date(tourB.from).getTime())
            }
        }
    }))

    return artistsWithTours
}

export const getArtistWithToursFromTourings = async (artist: Artist, tourings: Touring[]) => {
    const artistWithTours: ArtistWithTours = {...artist, tours: []}

    await forEachAsync(tourings,
        ((touring: Touring) => {
        if(touring.artistId === artist.id){
            artistWithTours.tours.push(touring)
        }
    }))

    // Sorting tours by start date
    artistWithTours.tours.sort((tourA, tourB) => new Date(tourA.from).getTime() - new Date(tourB.from).getTime())

    return artistWithTours
}

export const removeDaysOffFromArtistWithTours = (artist: ArtistWithTours | null) => {
    if(artist === null) { return null }
    
    const newTours = artist.tours.map(tour => {
        const newShows = tour.shows.filter(show => show.isDayOff !== true)
        return {...tour, shows: newShows}
    })
    return {...artist, tours: newTours}
}

export const getTouringsWithArtistFromTourings = async (artists: Artist[], tourings: Touring[]) => {
    const touringsWithArtists: TouringWithArtist[] = []

    forEachAsync(tourings,
        ((touring: Touring) => {
        const artistIndex = artists.findIndex(artist => artist.id === touring.artistId)
        if(artistIndex === -1){return} //if artist not found we skip the touring
        const artist = artists[artistIndex]
        const newTouring = {...touring, artist}
        touringsWithArtists.push(newTouring)
    }))

    return touringsWithArtists
}
export const getPotentialTouringsWithArtistFromTourings = async (artists: Artist[], tourings: PotentialTouring[]) => {
    const touringsWithArtists: PotentialTouringWithArtist[] = []

    forEachAsync(tourings,
        ((touring: PotentialTouring) => {
        const artistIndex = artists.findIndex(artist => artist.id === touring.artistId)
        if(artistIndex === -1){return} //if artist not found we skip the touring
        const artist = artists[artistIndex]
        const newTouring = {...touring, artist}
        touringsWithArtists.push(newTouring)
    }))

    return touringsWithArtists
}

export const getDaysOfTourFromTourings = async (tourings: TouringWithArtist[] | Touring[]) => {
    const daysOfTour: TouringDayWithTouringId[] = []

    await forEachAsync(tourings,
        async (touring: Touring) => {
            await forEachAsync(touring.shows, 
                (day: TouringDay) => {
                    if(day.isDayOff){return}
                    const dayWithId = {...day, touringId: touring.id}
                    daysOfTour.push(dayWithId)
                })
        })

    return daysOfTour
}

export const isTourMatchingDates = (fromDateString:  string, toDateString: string, fromDateFilter: Date, toDateFilter: Date) => {
    const fromDate = new Date(fromDateString).getTime() 
    const toDate = new Date(toDateString).getTime()
    const fromFilter = (toDate >= fromDateFilter.getTime())
    const toFilter = (fromDate <= toDateFilter.getTime())
    return (fromFilter && toFilter)
}

export const getCountriesFromTourings = async (tourings: (Touring|TouringWithArtist | PotentialTouringWithArtist)[]) => {
    let countries: Country[] = []

    await forEachAsync(tourings,
        (async (touring: Touring) => {
        await forEachAsync(touring.countries,
            ((country: Country) => {
            if(!countries.includes(country)){
                countries.push(country)
            }
        }))
    }))
    return countries
}

export const getDurationsFromTourings = async (tourings: (Touring | TouringWithArtist | PotentialTouringWithArtist)[]) => {
    let durations: number[] = []

    await forEachAsync(tourings,
        ((touring: Touring) => {
        if(!durations.includes(touring.showDuration)){
                durations.push(touring.showDuration)
            }
    }))
    return durations
}

export const markIsPastInTourings = (tourings: Touring[]) => {
    const nowTime = new Date().getTime()
    const markedTourings = tourings.map(touring => {

        const markedShows = touring.shows.map(show => {
            const showTime = new Date(show.date).getTime()
            const isPast = showTime < nowTime
            return {...show, isPast}
        })

        const lastShow = touring.shows[touring.shows.length - 1]
        const lastShowTime = new Date(lastShow.date).getTime()
        const isPast = lastShowTime < nowTime

        return {...touring, shows: markedShows, isPast}
    })

    return markedTourings
}