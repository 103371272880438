export const layout = () => {
    const {
        innerWidth: w, 
        innerHeight: h
    } = window;

    const vmin = w > h ? h : w;
    const vmax = w > h ? w : h;

    return {
        vmin,
        vmax,
        isMobile: vmin <= 450,
    }
}

export const colors = {
    main: "rgb(115,103,240)",
    red: "rgb(234, 84, 85)",
    green: "rgb(40, 199, 111)",
    lightblue: "rgb(0, 207, 232)",
    orange: "rgb(255, 159, 67)",
    gray: "rgb(168, 170, 174)",

    //faded backgrounds
    bgMain: '#37395D',
    bgRed: '#46374A',
    bgGreen: '#2E454D',
    bgLightblue: '#29465C',
    bgOrange: '#494048',
    bgGray: '#3E4155',
}

export const theme = [
    colors.main,  // purple
    // '#7f3e9c',  // purple
    '#a33b64',  // pinky
    // '#e71e29',  // red
    colors.red,  // red
    // '#003c8d',  // blue
    colors.lightblue,  // blue
    // '#cd3a92',  // girly pink
    colors.green,    // green
    '#ff4d74',  // coral pinky
    // '#ff7d4c'   // orange
    colors.orange   // orange
];

export const errorToSuccessTheme3 = [
    //'#F8F07A',  // yellow-green 
    // '#cf1e0e',  // dark red
    colors.red,  // dark red
    // '#FA8072',//'#F0B27A',//'#dbba32',  // dark orange
    colors.orange,//'#F0B27A',//'#dbba32',  // dark orange
    // '#2AAA8A',  // green
    colors.green,  // green
]

export const primuseApi = process.env.REACT_APP_PRODUCTION.toString() === 'true' ? 'https://api.primuse.tech/primuse/v1' : 'http://127.0.0.1:5000';   // 'https://primuse.herokuapp.com'


export const accessLevels = {
    promotor: {
        1: 'Junior',
        2: 'Medium',
        3: 'Senior',
    },
    
}