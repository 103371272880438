
const Secured = () => {

    return (
        <div className="landing-secured">
            <div className="landing-container">
                <div className="flex-align-center row">
                    <div className="landing-secured-left-area">
                        <h2 className="margedBot10px">Secured by technology</h2>
                        <p>Kisum uses blockchain technology to securely and transparently record and submit offers. By leveraging the decentralized and immutable nature of blockchain, Kisum ensures that all offers are transparently tracked, recorded and can be audited. This allows for added security and trust in the recording and submission of offers, making the process more efficient and reliable.</p>
                    </div>
                    <div style={{flexBasis: "50%"}}>
                        <div className="landing-laptop-div">
                            <img className="landing-laptop" src="macbook_mockup.png" alt="macbook mockup" />
                            <img className="landing-laptop-image" src="screenshot_contract.png" alt="screenshot of the app" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Secured