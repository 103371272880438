
const Area1 = () => {

    return (
        <div className="area-1" id="app">
            <div className="landing-container">
                <div className="area-1-inner">
                    <div className="area-1-inner-left">
                        <h2 className="landing-title">Reinventing The Technology</h2>
                    </div>

                    <div className="area-1-inner-right">
                        <h4 className="font18 margedBot20px">Join the community</h4>
                        <p>Kisum is a unique application that offers a new experience for promoters by providing them with a new way to make decisions for concerts based on various content sources and artist data from around the world, making their experience more enjoyable and informed.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Area1