import { MdOutlineAddTask } from "react-icons/md";
import { Flex } from "../../Containers";
import { useDispatch } from "react-redux";
import { snackActions } from "../../../store/snackSlice";
import React, { useState } from "react";
import { ImCross } from "react-icons/im"
import LabelInput from "../mui/label-input/LabelInput";
import { colors } from "../../../constants";


const ArrayInput = ({ title, placeholder, value, renderer, onChange}: { title?: string, placeholder?: string, value: any[], renderer: Function, onChange: Function}) => {

    const [input, setInput] = useState('')

    const handleRemoveElement = (indexToRemove: number) => {
        const updatedArray = value.filter((_, index) => index !== indexToRemove)
        onChange(updatedArray)
    }

    const dispatch = useDispatch()
    const setSnack = (snack:any) => dispatch(snackActions.set(snack))

    return (
        <Flex className="allwidth">
            {title && <label className="selfstart bold label-color">{ title }</label>}

            <Flex className="alignstart allwidth">
            {
                value.map((element:any, index:number) => <Flex key={`inpstringArray_${index}`} row>
                    {/* removable row */}
                    { renderer(element) }
                    <ImCross size={10} onClick={(e: React.MouseEvent<SVGElement, MouseEvent>) => handleRemoveElement(index)} color={colors.red} className="array-input__cross-icon clickable"/>
                </Flex>)
            }
            </Flex>
            <Flex row className="selfstart allwidth alignend">           
                <div className="allwidth margedRight20px">
                    <LabelInput
                        onChange={(e:React.ChangeEvent<HTMLInputElement>) => setInput(e.target.value)} 
                        value={input}
                        title={placeholder ?? title}
                    />
                </div>

                <MdOutlineAddTask
                    size={20} 
                    className="clickable main-color" 
                    onClick={() => {
                        if (input) {
                            onChange([...value, input]);
                            setInput('');
                        }
                        else setSnack({ type: 'warning', content: 'Invalid item' })
                    }} 
                />
            </Flex>
            
        </Flex>
    )
}

export default ArrayInput