import { Offer } from "../../../../utils/models/offer.model"
import OfferItemRow from "./OfferItemRow"
import { AiOutlineCaretUp, AiOutlineCaretDown } from "react-icons/ai"
import { useEffect, useState } from "react"
import useFilter from "../../../../utils/hooks/useFilter"

const OffersTable = ({ offers, ongoing }: { offers: Offer[], ongoing?: boolean }) => {

    const [sortedOffers, setSortedOffers] = useState(offers)

    const [isCreationSortAscending, setIsCreationSortAscending] = useState(false)
    const [isLimitSortAscending, setIsLimitSortAscending] = useState(false)
    const [isShowDatesSortAscending, setIsShowDatesSortAscending] = useState(false)
    const [isAmountSortAscending, setIsAmountSortAscending] = useState(false)

    const { filterInput, FilterUI} = useFilter('Search by artist name')

    useEffect(() => {
        const filteredOffers = offers.filter(offer => offer.artistName.toLowerCase().includes(filterInput.toLowerCase()))
        filteredOffers.sort((offerA, offerB) => offerB.date - offerA.date)
        setSortedOffers(filteredOffers)
    }, [offers, filterInput])
    
    const handleSortByCreated = () => {
        if(isCreationSortAscending) {
            const newSortedOffers = sortedOffers.slice()
            newSortedOffers.sort((offerA, offerB) => offerB.date - offerA.date)
            setSortedOffers(newSortedOffers)
        } else {
            const newSortedOffers = sortedOffers.slice()
            newSortedOffers.sort((offerA, offerB) => offerA.date - offerB.date)
            setSortedOffers(newSortedOffers)
        }
        setIsCreationSortAscending(prevValue => !prevValue)
    }

    const handleSortByLimit = () => {
        if(isLimitSortAscending) {
            const newSortedOffers = sortedOffers.slice()
            newSortedOffers.sort((offerA, offerB) => {
                const timeA = new Date(offerA.expiryDate).getTime()
                const timeB = new Date(offerB.expiryDate).getTime()
                return timeB - timeA
            })
            setSortedOffers(newSortedOffers)
        } else {
            const newSortedOffers = sortedOffers.slice()
            newSortedOffers.sort((offerA, offerB) => {
                const timeA = new Date(offerA.expiryDate).getTime()
                const timeB = new Date(offerB.expiryDate).getTime()
                return timeA - timeB
            })
            setSortedOffers(newSortedOffers)
        }
        setIsLimitSortAscending(prevValue => !prevValue)
    }

    const handleSortByShowDates= () => {
        if(isShowDatesSortAscending) {
            const newSortedOffers = sortedOffers.slice()
            newSortedOffers.sort((offerA, offerB) => new Date(offerB.shows[0].date).getTime() - new Date(offerA.shows[0].date).getTime())
            setSortedOffers(newSortedOffers)
        } else {
            const newSortedOffers = sortedOffers.slice()
            newSortedOffers.sort((offerA, offerB) => new Date(offerA.shows[0].date).getTime() - new Date(offerB.shows[0].date).getTime())
            setSortedOffers(newSortedOffers)
        }
        setIsShowDatesSortAscending(prevValue => !prevValue)
    }

    const handleSortByAmount = () => {
        if(isAmountSortAscending) {
            const newSortedOffers = sortedOffers.slice()
            newSortedOffers.sort((offerA, offerB) => offerB.totalAmount - offerA.totalAmount)
            setSortedOffers(newSortedOffers)
        } else {
            const newSortedOffers = sortedOffers.slice()
            newSortedOffers.sort((offerA, offerB) => offerA.totalAmount - offerB.totalAmount)
            setSortedOffers(newSortedOffers)
        }
        setIsAmountSortAscending(prevValue => !prevValue)
    }

    const renderOfferRow = (offer: Offer, index: number) => <OfferItemRow offer={offer} ongoing={ongoing} key={index} />

    return (
        <>
            <div className="margedBot20px">
                {FilterUI}
            </div>
            
            {sortedOffers.length 
            ?
                <table className="app-table ">
                    <thead>
                        <tr>
                            <th className="leftalign">
                                <div className="clickable flex-align-center row" onClick={handleSortByCreated}>
                                    <p className="margedRight2px">Date of Creation</p>
                                    {isCreationSortAscending
                                    ?   <AiOutlineCaretUp size={15}/>
                                    :   <AiOutlineCaretDown size={15}/>}
                                </div>
                            </th>
                            <th className="leftalign">
                                <div className="clickable flex-align-center row" onClick={handleSortByLimit}>
                                    <p className="margedRight2px">Limit date</p>
                                    {isLimitSortAscending
                                    ?   <AiOutlineCaretUp size={15}/>
                                    :   <AiOutlineCaretDown size={15}/>}
                                </div>
                            </th>
                            <th className="leftalign">Promotor</th>
                            <th className="leftalign">Promotor level</th>
                            <th className="leftalign">Artist</th>
                            <th>Nbr shows</th>
                            <th className="leftalign">Countries</th>
                            <th className="leftalign">
                                <div className="clickable flex-align-center row" onClick={handleSortByShowDates}>
                                    <p className="margedRight2px">Shows Dates</p>
                                    {isShowDatesSortAscending
                                    ?   <AiOutlineCaretUp size={15}/>
                                    :   <AiOutlineCaretDown size={15}/>}
                                </div>    
                            </th>
                            <th className="leftalign" style={{width: '10%'}}>
                                <div className="clickable flex-align-center row" onClick={handleSortByAmount}>
                                    <p className="margedRight2px">Offer</p>
                                    {isAmountSortAscending
                                    ?   <AiOutlineCaretUp size={15}/>
                                    :   <AiOutlineCaretDown size={15}/>}
                                </div>
                            </th>
                            <th>Open</th>
                            <th>View PNLs</th>
                            <th style={{width: '15%'}}>
                                {
                                    ongoing ? 'Actions' : 'Current Status'
                                }
                            </th>

                        </tr>
                    </thead>
                    <tbody>
                        {sortedOffers.map(renderOfferRow)} 
                    </tbody>
                </table>

            : <p className="grey">No offers at the moment.</p>}
        </>
    )
}

export default OffersTable