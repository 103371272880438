import { Flex } from "../../Containers";
import CreatableSelect from 'react-select/creatable';
import { MdOutlineAddTask } from "react-icons/md";
import { useDispatch } from "react-redux";
import { snackActions } from "../../../store/snackSlice";
import { useState } from "react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import LabelInput from "../mui/label-input/LabelInput";
import SelectInput from "./SelectInput";
import { colors } from "../../../constants";

const SocialPlatformInput = ({ title, value, options, onChange, renderer }: { title?: string, value: any, options: any[], renderer: Function, onChange: Function}) => {
    const [newObject, _setNewObject] = useState({ key: {label: '', value: ''}, url: '', id: '' });
    const setNewObject = (nsProps:any) => _setNewObject({...newObject, ...nsProps});

    const dispatch = useDispatch();
    const setSnack = (snack:any) => dispatch(snackActions.set(snack));

    const handleRemoveElement = (key: string) => {
        const newValue = {...value}
        delete newValue[key]
        // triggering change to trigger rerender
        onChange(newValue)
    }

    return(
        <Flex className="allwidth">
            {title && <label className="selfstart bold">{ title }</label>}

            <Flex className="alignstart allwidth">
            {
                Object.keys(value).map((sm:any, smi:number) => <Flex key={`inpstack_soi${smi}`} row>
                    {/* removable row */}
                    { renderer(sm) }
                    <AiOutlineCloseCircle size={15} onClick={(e: React.MouseEvent<SVGElement, MouseEvent>) => handleRemoveElement(sm)} color={colors.red} className="array-input__cross-icon clickable"/>
                </Flex>)
            }
            </Flex>

            <Flex row className="selfstart allwidth alignend">

                <Flex row className="allwidth selfstart alignend margedRight20px">
                    <Flex flex={3} className='margedRight20px'>
                        <SelectInput
                            options={options.filter((o:any) => !value[o.value])}
                            title='Select Media'
                            noTitle
                            onChange={(newValue: any) => {
                                setNewObject({key: newValue })
                            }}   

                            value={newObject?.key?.value ? newObject.key : null}
                        />
                    </Flex>
                            
                    <Flex flex={6}  className='margedRight20px'>
                        <Flex row className="selfstart allwidth">
                            <LabelInput
                                value={newObject.url}
                                onChange={(e:React.ChangeEvent<HTMLInputElement>): void => setNewObject({url: e.target.value})} 
                                title='url: eg https://'
                            />
                        </Flex>
                    </Flex>

                    <Flex flex={3}>
                        <Flex row className="selfstart allwidth">
                            <LabelInput
                                value={newObject.id}
                                onChange={(e:React.ChangeEvent<HTMLInputElement>): void => setNewObject({id: e.target.value})} 
                                title='id'
                            />
                        </Flex>
                    </Flex>
                </Flex>

                <MdOutlineAddTask
                    size={20} 
                    className="clickable main-color" 
                    onClick={() => {
                        if (newObject.key.value && newObject.url) {
                            onChange({...value, [newObject.key.value]: {url: newObject.url, id: newObject.id} });
                            setNewObject({ key: {}, url: '', id: '' });
                        }
                        else setSnack({ type: 'warning', content: 'Invalid Social' })
                    }} 
                />
                
            </Flex>
                    
        </Flex>
    )
}

export default SocialPlatformInput