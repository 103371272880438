import { useEffect, useRef } from 'react'
import { FaCaretDown } from 'react-icons/fa'

const DropdownToggler = ({title, isOpen, handleToggleDropdown, handleClose, children}: { title: string, isOpen: boolean, handleToggleDropdown:any, handleClose: any, children?: JSX.Element[] | JSX.Element }) => {
    const ref = useRef<HTMLDivElement>(null)
    
	const togglerText = title

    // close dropdown if click outside
    useEffect(() => {
		const handleClick = (e: any) =>{
			if (ref.current && !ref.current.contains(e.target)) {
				handleClose()
			  }

		}
		document.addEventListener("mousedown", handleClick)

		return(() => {
			document.removeEventListener("mousedown", handleClick)
		})
	}, [ref, handleClose])

	return (
		<div ref={ref} className="dropdown__togglerDiv">
			<button className={`btn dropdown__togglerBtn`} onClick={handleToggleDropdown}>
				{togglerText}
				<FaCaretDown size="11px" />
			</button>
			{isOpen && children}
		</div>
	)
}

export default DropdownToggler