import { Licence } from "../models/client.model"
import { useAppSelector } from "./reduxTypedHooks"

const useIsPromotor = () => {
    const licence = useAppSelector(state => state.client?.licence)
    const isPromotor = licence === Licence.Promotor

    return isPromotor
}

export default useIsPromotor