import { useState } from "react"
import { Artist, ArtistAPIDatas, NormalizedArtistMarcoDB } from "../../models/artists.model"
import { fetchArtistBandsintownPastEvents, fetchArtistDeezerInfos, fetchArtistGeniusInfos, fetchArtistLastfmInfos, fetchArtistTwitterInfos, fetchBandsintownArtistEvents, fetchBandsintownArtistInfos, fetchSpotifyArtistAlbums, fetchSpotifyArtistInfos, fetchSpotifySimilarArtists, fetchSpotifyTopTracks, fetchTiktokInfos } from "../../../api/dirsal/dirsalRequests"
import { computeArtistTotalFollowers, filterDuplicatesSeatgeekEvents, filterUniqueEventsAndStandardize, formatDisplayableNameIntoNameQuery, formatDisplayableNameIntoSeatGeekSlug, formatDisplayableNameIntoTiktokName, standardizeBandsITEvents } from "../../artists.util"
import { fetchFacebookProfileRapidAPI, fetchInstagramProfileRapidAPI, fetchSeatgeekArtistInfos, fetchSeatgeekEventsWithArtist, fetchYoutubeProfileRapidAPI } from "../../../api/other/otherRequests"
import { promisify } from "../../utils"

//APIs datas
export const initialFetchedDatas = {
    spotify: undefined,
    twitter: undefined,
    deezer: undefined,
    lastfm: undefined,
    seatgeek: undefined,
    bandsintown: undefined,
    genius: undefined,
    tiktok: undefined,
    instagram: undefined,
    facebook: undefined,
    youtube: undefined,
    events: undefined,
    pastEvents: undefined,
    totalFollowers: undefined,
}

const useFetchArtistDatas = (primuseArtist?: Artist) => {

    

    const [spotifyFinished, setSpotifyFinished] = useState(false)
    const [fetchedDatas, setFetchedDatas] = useState<ArtistAPIDatas>(initialFetchedDatas)
    
    const updateFetchedData = (dataName: keyof typeof fetchedDatas, value: any) => {
        setFetchedDatas(prevDatas => ({...prevDatas, [dataName]: value}))
    }

    const fetchStreamingDatas = async (mdba?: NormalizedArtistMarcoDB|null) => {
        setFetchedDatas(initialFetchedDatas)
        
        console.time("dataAPIs")
        await Promise.all([getSpotifyData(mdba), fetchEventsData(mdba), getTwitterData(mdba), getDeezerData(mdba), getLastfmData(mdba), getGeniusData(mdba), getTiktokData(mdba), getInstagramData(mdba), getFacebookData(mdba), getYoutubeData(mdba)])
        console.timeEnd("dataAPIs")
        setFetchedDatas(prevData => {
            const totalFollowers = computeArtistTotalFollowers(prevData)
            return {...prevData, totalFollowers }
        })
    }

    const fetchEventsData = async (mdba?: NormalizedArtistMarcoDB|null) => {
        await Promise.all([getSeatgeekData(mdba), getBandsintownData(mdba)])
        setFetchedDatas(prevData => {
            const seatgeekEvents = prevData.seatgeek?.events ? prevData.seatgeek?.events : []
            const uniqueSeatgeekEvents = filterDuplicatesSeatgeekEvents(seatgeekEvents)
            const bandsintownEvents = prevData.bandsintown?.events ? prevData.bandsintown.events : []
            const events = filterUniqueEventsAndStandardize(uniqueSeatgeekEvents, bandsintownEvents)
            const bandsintownpastEvents = prevData.bandsintown?.pastEvents ? prevData.bandsintown.pastEvents : []
            return {...prevData, events, pastEvents: bandsintownpastEvents}
        })
    }

    const getTwitterData = async (mdba?: NormalizedArtistMarcoDB|null) => {
        const id = primuseArtist?.socials?.twitter?.id 
        ?   primuseArtist.socials.twitter.id 
        :   mdba?.socials?.twitter?.id 
            ?   mdba?.socials?.twitter?.id 
            :   undefined
        if(!id){
            updateFetchedData('twitter', undefined)
            return
        }
        console.time('Twitter')
        const response = await fetchArtistTwitterInfos(id)
        console.timeEnd('Twitter')
        const followers = response?.data?.response?.followers_count
        const screenName = response?.data?.response?.screen_name
        updateFetchedData('twitter', {followers, screenName})
    }

    const getSpotifyData = async (mdba?: NormalizedArtistMarcoDB|null) => {
        const id = 
        primuseArtist?.streamingPlatforms?.spotify?.id 
        ?   primuseArtist.streamingPlatforms.spotify.id 
        :   mdba?.streamingPlatforms.spotify.id 
            ?   mdba?.streamingPlatforms.spotify.id 
            :   undefined
        if(!id){
            updateFetchedData('spotify', undefined)
            setSpotifyFinished(true)
            return
        }
        console.time('spotify')
        await Promise.all(
            [   getSpotifyArtistInfos(id), 
                getSpotifyAlbumsInfos(id), 
                getSpotifyTopTracksInfos(id),
                getSpotifySimilarArtistsInfos(id)])
        console.timeEnd('spotify')
        setSpotifyFinished(true)
    }

    const getSpotifyArtistInfos = async (id: string) => {
        const infosResponse = await fetchSpotifyArtistInfos(id)
        const artist = infosResponse?.data?.data
        setFetchedDatas(prevData => ({...prevData, spotify: {...prevData.spotify, artist, id}}))
        setSpotifyFinished(true)
    }
    const getSpotifyAlbumsInfos = async (id: string) => {
        const albumsResponse = await fetchSpotifyArtistAlbums(id)
        const albums = albumsResponse?.data?.data?.items
        setFetchedDatas(prevData => ({...prevData, spotify: {...prevData.spotify, albums, id}}))
    }
    const getSpotifySimilarArtistsInfos = async (id: string) => {
        const similarArtistsResponse = await fetchSpotifySimilarArtists(id)
        const similarArtists = similarArtistsResponse?.data?.data
        setFetchedDatas(prevData => ({...prevData, spotify: {...prevData.spotify, similarArtists, id}}))
    }
    const getSpotifyTopTracksInfos = async (id: string) => {
        const topTracksUSResponse = await fetchSpotifyTopTracks(id, "US")
        const topTracksUS = topTracksUSResponse?.data?.data
        setFetchedDatas(prevData => ({...prevData, spotify: {...prevData.spotify, topTracksUS, id}}))
    }

    const getDeezerData = async (mdba?: NormalizedArtistMarcoDB|null) => {
        const id = primuseArtist?.streamingPlatforms?.deezer?.id 
        ?   primuseArtist.streamingPlatforms.deezer.id 
        :   mdba?.streamingPlatforms?.deezer?.id 
            ?   mdba?.streamingPlatforms?.deezer?.id 
            :   undefined
        if(!id){
            updateFetchedData('deezer', undefined)
            return
        }
        console.time('deezer')
        const response = await fetchArtistDeezerInfos(id)
        console.timeEnd('deezer')
        let followers = undefined
        let url = undefined

        // Prevents a bug in deezer response where respoonse?.date?.response is "Oauth exception"
        const isResponseValid = typeof response?.data?.response === 'object' && response?.data?.response !== null
        if(isResponseValid) {
            followers = response?.data?.response?.nb_fan
            url = response?.data?.response?.link
        }
        
        updateFetchedData('deezer', {followers, url})
    }

    const getLastfmData = async (mdba?: NormalizedArtistMarcoDB|null) => {
        // @ts-ignore // data as last.fm in database
        const id = primuseArtist?.streamingPlatforms?.last?.fm?.id // @ts-ignore
        ?   primuseArtist.streamingPlatforms.last.fm.id  // @ts-ignore
        :   mdba?.streamingPlatforms?.last?.fm?.id  // @ts-ignore
            ?   mdba?.streamingPlatforms?.last?.fm?.id 
            :   undefined
        if(!id){
            updateFetchedData('lastfm', undefined)
            return
        }
        console.time('last')
        const response = await fetchArtistLastfmInfos(id)
        console.timeEnd('last')
        if(!response) {
            updateFetchedData('lastfm', undefined)
        }
        else {
            const stats = response?.data?.response?.stats
            const url = response?.data?.response?.url
            updateFetchedData('lastfm', {listeners: stats?.listeners, playcount: stats?.playcount, url})
        }
    }

    const getSeatgeekData = async (mdba?: NormalizedArtistMarcoDB|null) => {
        const name = primuseArtist ? primuseArtist.displayableName : mdba?.displayableName
        if(!name){
            updateFetchedData('seatgeek', undefined)
            return
        }
        const slug = formatDisplayableNameIntoSeatGeekSlug(name)
        console.time('SeetG')
        const [eventsResponse, artistResponse] = await Promise.all([fetchSeatgeekEventsWithArtist(slug), fetchSeatgeekArtistInfos(slug)])
        console.timeEnd('SeetG')
        const events = eventsResponse?.data?.events
        const markedEvents = await promisify(events?.length ? events.map((event: any) => ({...event, fromSeatgeek: true})) : [])
        const score = artistResponse?.data?.performers[0]?.score
        updateFetchedData('seatgeek', {events: markedEvents, score})
    }

    const getBandsintownData = async (mdba?: NormalizedArtistMarcoDB|null) => {
        const name = primuseArtist ? primuseArtist.displayableName : mdba?.displayableName
        if(!name){
            updateFetchedData('bandsintown', undefined)
            return
        }

        const queryName = formatDisplayableNameIntoNameQuery(name)
        console.time('BandsIT')
        const [infosResponse, eventsResponse, pastEventsResponse] = await Promise.all([fetchBandsintownArtistInfos(queryName), fetchBandsintownArtistEvents(queryName), fetchArtistBandsintownPastEvents(queryName)])
        console.timeEnd('BandsIT')
        const followers = infosResponse?.data?.response?.tracker_count
        const url = infosResponse?.data?.response?.url
        const events = eventsResponse?.data?.response ? eventsResponse?.data?.response : []
        const markedEvents = events.map((event: any) => ({...event, fromBandsintown: true}))
        const pastEvents = pastEventsResponse?.data?.response ? pastEventsResponse?.data?.response : []
        const markedPastEvents = pastEvents.map((event: any) => ({...event, fromBandsintown: true}))
        const standardizedPastEvents = standardizeBandsITEvents(markedPastEvents)
        updateFetchedData('bandsintown', {followers, url, events: markedEvents, pastEvents: standardizedPastEvents})
    }

    const getGeniusData = async (mdba?: NormalizedArtistMarcoDB|null) => {
        const id = primuseArtist?.streamingPlatforms?.genius?.id 
        ?   primuseArtist.streamingPlatforms.genius.id 
        :   mdba?.streamingPlatforms?.genius?.id 
            ?   mdba?.streamingPlatforms?.genius?.id 
            :   undefined
        if(!id){
            updateFetchedData('genius', undefined)
            return
        }
        console.time('Genius')
        const response = await fetchArtistGeniusInfos(id)
        console.timeEnd('Genius')
        const followers = response?.data?.response?.artist?.followers_count
        const url = response?.data?.response?.artist?.url
        updateFetchedData('genius', {followers, url})
    }

    const getTiktokData = async (mdba?: NormalizedArtistMarcoDB|null) => {
        const name = primuseArtist ? primuseArtist.displayableName : mdba?.displayableName
        if(!name){
            updateFetchedData('tiktok', undefined)
            return
        }

        const queryName = formatDisplayableNameIntoTiktokName(name)
        console.time('tiktok')
        const response = await fetchTiktokInfos(queryName)
        console.timeEnd('tiktok')
        const followers = response?.data?.response?.user?.followers
        const urlName = response?.data?.response?.user?.login_name
        updateFetchedData('tiktok', {followers, name: urlName})
    }

    const getInstagramData = async (mdba?: NormalizedArtistMarcoDB|null) => {
        const id = primuseArtist?.socials?.instagram?.id 
        ?   primuseArtist.socials.instagram.id 
        :   mdba?.socials?.instagram?.id 
            ?   mdba?.socials?.instagram?.id 
            :   undefined
        if(!id){
            updateFetchedData('instagram', undefined)
            return
        }
        console.time('Insta')
        const response = await fetchInstagramProfileRapidAPI(id)
        console.timeEnd('Insta')
        const followers = response?.data?.data?.usersCount
        const url = response?.data?.data?.url
        updateFetchedData('instagram', {followers, url})
    }

    const getFacebookData = async (mdba?: NormalizedArtistMarcoDB|null) => {
        const id = primuseArtist?.socials?.facebook?.id 
        ?   primuseArtist.socials.facebook.id 
        :   mdba?.socials?.facebook?.id 
            ?   mdba?.socials?.facebook?.id 
            :   undefined
        if(!id){
            updateFetchedData('facebook', undefined)
            return
        }
        console.time('facebook')
        const response = await fetchFacebookProfileRapidAPI(id)
        console.timeEnd('facebook')
        const followers = response?.data?.data?.usersCount
        const url = response?.data?.data?.url
        updateFetchedData('facebook', {followers, url})
    }

    const getYoutubeData = async (mdba?: NormalizedArtistMarcoDB|null) => {
        const id = primuseArtist?.socials?.youtube?.id 
        ?   primuseArtist.socials.youtube.id 
        :   mdba?.socials?.youtube?.id  
            ?   mdba?.socials?.youtube?.id  
            :   undefined
        if(!id){
            updateFetchedData('youtube', undefined)
            return
        }
        console.time('youtube')
        const response = await fetchYoutubeProfileRapidAPI(id)
        console.timeEnd('youtube')
        const followers = response?.data?.data?.usersCount
        const url = response?.data?.data?.url
        updateFetchedData('youtube', {followers, url})
    }



    return { fetchStreamingDatas, fetchedDatas, spotifyFinished, setSpotifyFinished }

}

export default useFetchArtistDatas