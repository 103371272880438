import { forwardRef, useEffect, useState } from 'react'
import LabelInput from '../../../components/form/mui/label-input/LabelInput'
import DatePicker from "react-datepicker";
import TextField from '@mui/material/TextField'
import SelectInput from '../../../components/form/form-components/SelectInput';
import { PromotorEvent, PromotorEventCreateObject, promotorEventLabelsList, promotorEventsRecurringTitles } from '../../../utils/models/promotors.model';
import useSetSnack from '../../../utils/hooks/useSetSnack';
import CheckboxSpruha from '../../../components/form/spruha/CheckboxSpruha';

const AgendaEventForm = ({ selectedDates, event, handleAdd, handleUpdate, close, resetingValues, setIsResetingValues }: 
    { 
        selectedDates?: {from: Date, to: Date, allDay: boolean, startTime: string, endTime: string}, 
        event?: PromotorEvent, 
        handleAdd: (event: PromotorEventCreateObject) => Promise<void>,
        handleUpdate: (updates: any) => Promise<void>,
        close: () => void,
        resetingValues?: boolean,
        setIsResetingValues?: React.Dispatch<React.SetStateAction<boolean>>
    }) => {

    const labelOptions = promotorEventLabelsList.map(eventLabel => ({value: eventLabel, label: <div>{eventLabel}</div>}))
    
    const filledAllDay = event ? event.allDay : selectedDates ? selectedDates.allDay : null

    const filledFromDate = event?.from ? new Date(event.from) : selectedDates?.from ?? null
    const filledToDate = event?.to ? new Date(event.to) : selectedDates?.to ?? null
    
    const filledStartTime = event?.from ? `${new Date(event?.from).getHours() >= 10 ? new Date(event?.from).getHours() : `0${new Date(event?.from).getHours()}`}:${new Date(event.from).getMinutes() >= 10 ? new Date(event.from).getMinutes() : `0${new Date(event.from).getMinutes()}`}` : selectedDates ? selectedDates.startTime : null
    const filledEndTime = event?.from ? `${new Date(event?.to).getHours() >= 10 ? new Date(event?.to).getHours() : `0${new Date(event?.to).getHours()}`}:${new Date(event.to).getMinutes() >= 10 ? new Date(event.to).getMinutes() : `0${new Date(event.to).getMinutes()}`}` : selectedDates ? selectedDates.endTime : null

    const filledLabel = event ? {value: event.label, label: <div>{event.label}</div>} : null

    const initialValues = {
        title: event ? event.title : '',
        fromDate: filledFromDate ?? null,
        toDate: filledToDate ?? null,
        label: filledLabel ?? labelOptions[0],
        allDay: filledAllDay ?? true,
        startTime: filledStartTime ?? '',
        endTime: filledEndTime ?? '',
    }
    const [formInputs, setFormInputs] = useState({
        ...initialValues
    })
    const [isPosting, setIsPosting] = useState(false)
    
    const setSnack = useSetSnack()

    const resetValues = () => {
        setFormInputs(initialValues)
    }
    
    useEffect(() => {
        if(resetingValues === true && setIsResetingValues !== undefined) {
            resetValues()
            setIsResetingValues(false)
        }
    }, [resetingValues,setIsResetingValues])

    useEffect(() => {
        setFormInputs(initialValues)
    }, [event, selectedDates])

    const updateInput = (value: any, inputName: keyof typeof formInputs) => {
        setFormInputs(prevInputs => ({...prevInputs, [inputName]: value}))
    }

    useEffect(() => {
        if(selectedDates) {
            updateInput(selectedDates.from, "fromDate")
            updateInput(selectedDates.to, "toDate")
        }
    }, [selectedDates])


    const onAdd = async () => {
        setIsPosting(true)
        if(!formInputs.fromDate || !formInputs.toDate) {
            setIsPosting(false)
            return
        }
        const { title, allDay, fromDate, toDate, startTime, endTime } = formInputs
        const _from = new Date(fromDate)
        const _to = new Date(toDate)

        if(!allDay) {
            const startHours = parseInt(startTime.substring(0,2))
            const startMinutes = parseInt(startTime.substring(3,5))
            const endHours = parseInt(endTime.substring(0,2))
            const endMinutes = parseInt(endTime.substring(3,5))
            if(!isNaN(startHours) && !isNaN(startMinutes) && !isNaN(endHours) && !isNaN(endMinutes)) {
                _from.setHours(startHours, startMinutes)
                _to.setHours(endHours, endMinutes)
            } else {
                setSnack({type: 'warning', content: 'Please enter valid start and end times'})
                setIsPosting(false)
                return
            }
        }
        // removing timezone from the date string
        const from = _from.toString().substring(0,25)
        const to = _to.toString().substring(0,25)
        const label = formInputs.label.value
        const newEvent: PromotorEventCreateObject = { title, from, to, label, allDay }
        await handleAdd(newEvent)
        setIsPosting(false)
        close()
    }

    const onSave = async () => {
        setIsPosting(true)
        const id = event?.id
        if(!id) {
            setSnack({type: 'error', content: 'Event not found'})
            setIsPosting(false)
            return
        }
        if(!formInputs.fromDate || !formInputs.toDate) {
            setSnack({type: 'warning', content: 'From/To dates not valid'})
            setIsPosting(false)
            return
        }
        const { title, fromDate, toDate, label, allDay, startTime, endTime } = formInputs
        const _from = new Date(fromDate)
        const _to = new Date(toDate)

        if(!allDay) {
            const startHours = parseInt(startTime.substring(0,2))
            const startMinutes = parseInt(startTime.substring(3,5))
            const endHours = parseInt(endTime.substring(0,2))
            const endMinutes = parseInt(endTime.substring(3,5))
            if(!isNaN(startHours) && !isNaN(startMinutes) && !isNaN(endHours) && !isNaN(endMinutes)) {
                _from.setHours(startHours, startMinutes)
                _to.setHours(endHours, endMinutes)
            } else {
                setSnack({type: 'warning', content: 'Please enter valid start and end times'})
                setIsPosting(false)
                return
            }
        }
        // removing timezone from the date string
        const from = _from.toString().substring(0,25)
        const to = _to.toString().substring(0,25)
        const updates = { id, title, from, to, allDay, label: label.value }
        await handleUpdate(updates)
        setIsPosting(false)
        close()
    }

    const PickersComponent = forwardRef((props:any, ref) => {
        return (
          <TextField
            inputRef={ref}
            disabled
            fullWidth
            {...props}
            label={props.label || ''}
            sx={{ width: '100%'}}
            autoComplete='off'
          />
        )
      })

    return (
        <div className='allwidth margedTop20px'>
            <div className='margedBot20px'>
                <div className='margedBot10px'>
                    <LabelInput 
                        title="Title"
                        value={formInputs.title}
                        onChange={(e: any) => {updateInput(e.target.value, 'title')}}
                    />
                </div>
                <div className='flex-align-center row wrap gap10'>
                   {promotorEventsRecurringTitles.map(eventTitle => 
                    <button className='button button--primary button--round button--small' onClick={() => updateInput(eventTitle, 'title')}>{eventTitle}</button>)}
                </div>
            </div>
            <div className='margedBot20px'>
                <CheckboxSpruha 
                    title="All Day"
                    checked={formInputs.allDay}
                    onChange={(e: any) => {updateInput(e.target.checked, 'allDay')}}
                />
            </div>
            <div className='margedBot20px'>
                <DatePicker
                    selectsEnd
                    id='event-start-date'
                    value={formInputs.fromDate?.toDateString()}
                    customInput={<PickersComponent label='Start Date' registername='endDate' />}
                    onChange={(date: Date) => updateInput(date, 'fromDate')}
                />
            </div>
            {!formInputs.allDay 
            &&  <div className='margedBot20px'>
                    <LabelInput
                        title='Start Time'
                        value={formInputs.startTime}
                        type="time"
                        onChange={(e:any) => updateInput(e.target.value, 'startTime')}
                    />
                </div>}
            <div className='margedBot20px'>
                <DatePicker
                    selectsEnd
                    id='event-end-date'
                    minDate={formInputs.fromDate}
                    value={formInputs.toDate?.toDateString()}
                    customInput={<PickersComponent label='End Date' registername='endDate' />}
                    onChange={(date: Date) => updateInput(date, 'toDate')}
                />
            </div>
            {!formInputs.allDay 
            &&  <div className='margedBot20px'>
                    <LabelInput
                        title='End Time'
                        value={formInputs.endTime}
                        type="time"
                        onChange={(e:any) => updateInput(e.target.value, 'endTime')}
                    />
                </div>}
            <div className='margedBot20px'>
                <SelectInput
                    options={labelOptions}
                    title="Label"
                    value={formInputs.label}
                    onChange={(e:any) => {updateInput(e, 'label')}}
                    showRawValue
                    nonClearable
                />
            </div>
            <div className={`flex-only space-between marged0auto ${event ? "width80" : "width50"}`}>
                <button className='button button--ghost button--round' onClick={close}>Cancel</button>
                {event
                ?   <>
                        <button disabled={isPosting} className='button button--primary button--round' onClick={onSave}>{isPosting ? "Saving..." : "Save"}</button>
                    </>
                :   <button disabled={isPosting} className='button button--primary button--round' onClick={onAdd}>{isPosting ? "Adding..." : "Add"}</button>}
            </div>
        </div>
    )
}

export default AgendaEventForm