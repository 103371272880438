import React, { useEffect, useState } from 'react';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import './accordion.css';

const indexColor = (index, opacity) => {
	
    let r, g, b;
    
    r = parseInt(((43634534 * index) * (index)) % 250);
    g = parseInt(((r * r) * index) % 250);
    b = parseInt(((g / r) * index) % 250);
    
	return `rgba(${r},${g},${b},${opacity || .5})`;
}

const Accordion = ({ className, active:forcedActive, title, date, content, index, lazyContent: lContent, lazyLoader: lLoader }) => {
    const [isActive, setIsActive] = useState(forcedActive || false);
    const [lazyContent, setLazyContent] = useState();

    const setActive = async(active) => {
        setIsActive(active);

        if (active && (typeof lLoader === 'function')) {
            const item = await lLoader();
            if (typeof lContent === 'function') {
                const content = await lContent(item);
                setLazyContent(content);
            }
            else setLazyContent(item);
        }
    }

    return (
        <div 
            key={`accordion_${index}`} 
            className={`accordion-item${isActive ? ' active-accordion-item' : ''} ${className || ' '}`} 
            // style={
            //     isActive 
            //     ? { borderLeft: `3px solid ${indexColor(index + 87, .5)}` } 
            //     : {  }
            // }
        >
            <div className="accordion-title" onClick={(e) => {
                const actionClicked = e.target.getAttribute('class').includes('app-button');
                // clearDay
                !actionClicked && setActive(!isActive)
            }}>
                <div className='flex row allwidth justifystart'>
                    <div className='flex'> {date}</div> 
                    <div className='flex allwidth'>{typeof title === 'function' ? title(isActive) : title}</div>
                </div>
                <div>
                    {
                        isActive 
                        ? <MdKeyboardArrowUp /> 
                        : <MdKeyboardArrowDown />
                    }
                </div>
            </div>
            {
                isActive 
                && <div className="accordion-content">{ lazyContent || content }</div>
            }
        </div>
    );
};

export default Accordion;