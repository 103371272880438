import { Promotor } from "../../../../utils/models/promotors.model";
import { BiArchiveIn, BiArchiveOut, BiEdit } from 'react-icons/bi'; 
import { Flex } from "../../../../components/Containers";

import { AiOutlineFolderOpen } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { ROUTE_CONTRACTS } from "../../../../routes/routes.const";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";
import { ls } from "../../../../utils/utils";
import { GiFireShield } from "react-icons/gi";
import { accessLevels, colors, errorToSuccessTheme3 } from "../../../../constants";
import { BsThreeDots } from "react-icons/bs";
import { MdClose } from "react-icons/md";
import { createPortal } from "react-dom";
import Dialog from "../../../../components/dialog/Dialog";
import { useState } from "react";
import useFetchApiAuth from "../../../../utils/hooks/useFetchApiAuth";
import useSetSnack from "../../../../utils/hooks/useSetSnack";
import { useAppDispatch } from "../../../../utils/hooks/reduxTypedHooks";
import { promotorsActions } from "../../../../store/promotorsSlice";
import { archivedPromotorsActions } from "../../../../store/archivedPromotorsSlice";

const PromotorItem = ({ promotor, index, edit, open, isArchived } : 
    { 
        promotor: Promotor, 
        index: number, 
        edit: (_index: number) => void, 
        open: (promotor: Promotor) => void,
        isArchived?: boolean,
    }) => {
    
    const [deleteModal, setDeleteModal] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)

    const fetchApiAuth = useFetchApiAuth()
    const navigate = useNavigate()
    const setSnack = useSetSnack()
    const dispatch = useAppDispatch()
    
    const rootModalDiv = document.getElementById('modal-root')!

    const openDeleteModal = () => setDeleteModal(true)
    const closeDeleteModal = () => setDeleteModal(false)
    const _openEdit = () => edit(index);
    const _openPromotor = () => open(promotor);
    const contracts = useSelector((state:RootState) => state.contracts);
    const users = useSelector((state: RootState) => state.users);

    
    const pcs = promotor.contracts || [];
    const numberOfContracts = pcs.length

    const totalAmount = (
        numberOfContracts
        && contracts
            .map((c:any) => (
                pcs.includes(c.id)
                ? c.payments
                    .map((p:any) => parseInt(p.amount))
                    .reduce((a:any, b:any) => (a + b))
                : 0
            ))
            .reduce((a:any, b:any) => (a + b), 0)
    );

    const account = promotor.clientId 
    && users.find((u:any) => ( u.id === promotor.clientId ));

    const handleDelete = async () => {
        setIsDeleting(true)

        const { error } = await fetchApiAuth({
            method: 'POST',
            route: 'delete-promotor',
            body: { id: promotor.id }
        });

        // snack
        setSnack(
            error
            ? {
                type: 'error', 
                content: error.toString()
            }
            : {
                type: 'success',
                content: `${promotor.name} has been successfully deleted`
            }
        )
        
        if(!error){
            // update global promotors
            dispatch(archivedPromotorsActions.delete({ id: promotor.id }))
        }

        setIsDeleting(false)
    }

    const handleArchive = async (isArchiving: boolean) => {
        const updateBody = { id: promotor.id, isArchived: isArchiving} 

        // POST /create-promotor
        const { error, response } = await fetchApiAuth({
            method: 'POST',
            route: 'merge-promotor',
            body: updateBody 
        });


        setSnack(
            error
                ? {
                    type: 'error', 
                    content: error.toString()
                }
                : {
                    type: 'success',
                    content: `Promotor ${promotor.name} has been successfully ${isArchiving ? "archived" : "unarchived"}`
                }
            )

        if (!error) {
            if(isArchiving) {
                dispatch(archivedPromotorsActions.add(response))
                dispatch(promotorsActions.delete({id: promotor.id}))
            } else {
                dispatch(promotorsActions.add(response))
                dispatch(archivedPromotorsActions.delete({id: promotor.id}))
            }
        }
    }

    return(
        <>
            {createPortal(
                <Dialog
                    title={`Delete promotor ${promotor.name}`}
                    visible={deleteModal}
                    onClose={closeDeleteModal}
                    backdropClose={false}
                    dialogCustomStyle={{height: '250px', top: '23', width: '60%', left: '20%'}}
                    notAllSpace={true}
                >
                    <div>
                        <p className="margedTop20px margedBot30px">Are you sure you want to delete promotor {promotor.name} ?</p>
                        <button className="button button--round button--ghost margedRight50px" onClick={closeDeleteModal}>Cancel</button>
                        <button className="button button--round button--danger" onClick={handleDelete}>{isDeleting ? "Deleting..." : "Delete"}</button>
                    </div>
                    
                </Dialog>, rootModalDiv)}
                        
            <tr key={`prom_it${index}`} className="boxshadowonhover">
                <td className="leftalign">
                        <p className="bold larger">{promotor.name}</p>
                </td>
                <td className="leftalign">
                    { 
                        account 
                        ?   <p>{account.email}</p>
                        :   '-'
                    }
                </td>
                <td>
                    {promotor.accessLevel && 
                        <div className="flex-align center">
                            <div className="flex row justifystart bold">
                                <GiFireShield size={25} color={errorToSuccessTheme3[promotor.accessLevel - 1]} /> {accessLevels.promotor[promotor.accessLevel]}
                            </div>
                        </div>}
                </td>
                <td className="leftalign">
                        <p className="bold">{promotor.email}</p>
                </td>
        
                <td className="leftalign">
                        <p>{promotor.cieName}</p>
                </td>
                <td>{ls(totalAmount)}</td>
                <td>
                    {
                        numberOfContracts
                        ? <Flex row 
                            className="clickable hoverscale main-color" 
                            onClick={() => {
                                navigate(ROUTE_CONTRACTS, { state: { searchValue: promotor.id } })
                            }}
                        >  
                            <div className="title"> {numberOfContracts} </div> 
                            &nbsp;
                            <AiOutlineFolderOpen 
                                size={25} 
                            />
                        </Flex>
                        : '-'
                    }
                </td>
                <td>
                    {isArchived
                    ?   <MdClose size={25} color={colors.red} className="clickable hoverscale" onClick={openDeleteModal} />
                    :   <BiEdit size={25} className="main-color-fill clickable hoverscale" onClick={_openEdit} />}
                </td>
                <td>
                    {isArchived
                    ?   <BiArchiveOut size={25} className="main-color-fill clickable hoverscale" onClick={() => handleArchive(false)} />
                    :   <BiArchiveIn size={25} className="main-color-fill clickable hoverscale" onClick={() => handleArchive(true)} />}
                </td>
                <td><BsThreeDots size={25} className="main-color-fill clickable hoverscale" onClick={_openPromotor} /></td>
            </tr>
        </>
    )
}

export default PromotorItem;