import { Flex, Row } from "../../../../../components/Containers"
import LabelInput from "../../../../../components/form/mui/label-input/LabelInput"
import CheckboxSpruha from "../../../../../components/form/spruha/CheckboxSpruha"
import useSetSnack from "../../../../../utils/hooks/useSetSnack"
import venuesList from "../../../../../temporary-storage/minified_all_venues_by_countrycode.json"
import Autocomplete from "@mui/material/Autocomplete"
import TextField from "@mui/material/TextField"
import { useState } from "react"
import { OfferShowFormInputs } from "../../../../../utils/models/offer.model"

const VenueStep = ({ os, osi, isReviewing, isLocalReviewing, setOfferShow, goToPreviousStep, goToNextStep, goBackToReview, goBackToLocalReview }:
    { 
        os: OfferShowFormInputs, 
        osi: number, 
        isReviewing: boolean,
        isLocalReviewing: boolean,
        setOfferShow: (showIndex: number, showProps: {}) => void,
        goToPreviousStep: () => void,
        goToNextStep: () => void,
        goBackToReview: () => void,
        goBackToLocalReview: () => void,
    }) => {

    const [searchInput, setSearchInput] = useState("")
    const setSnack = useSetSnack()
    
    //@ts-ignore
    const _venues = venuesList[os.country] ?? []

    const searchOptions = _venues.map((venue: any) => `${venue.name} (${venue.city?.name})`)

    const handleSelectAutoComplete = (event: any, values: any) => {
        const venue = _venues.find((venue: any) => `${venue.name} (${venue.city?.name})` === values)
        if(venue === undefined) { return }
        console.log(venue)

        const city = venue.city?.name
        const { address, name, capacity, phone, website } = venue
        const cityProp = city ? {city} : {city: ''}
        const addressProp = address ? {address} : { address: ''}
        const capacityProp = capacity ? {totalCapacity: capacity} : {totalCapacity: 0}
        const phoneProp = phone ? {phone} : {phone: ''}
        const websiteProp = website ? {website} : {website: ''}
        
        setOfferShow(
            osi, 
            { venue: {...(os.venue||{}), ...cityProp, ...addressProp, ...capacityProp, ...phoneProp, ...websiteProp, name } }
        )
    }

    const handlePrevious = () => {
        goToPreviousStep()
    }

    const checkIfVenueValid = () => {
        if(!os.venue.name) {
            setSnack({type: 'warning', content: 'Please fill the venue name'})
            return false
        }
        if(!os.venue.address) {
            setSnack({type: 'warning', content: 'Please fill the venue address'})
            return false
        }
        if(!os.venue.website) {
            setSnack({type: 'warning', content: 'Please fill the venue website'})
            return false
        }
        if(!os.venue.totalCapacity) {
            setSnack({type: 'warning', content: 'Please fill the venue capacity'})
            return false
        }
        if(!os.doorsOpen) {
            setSnack({type: 'warning', content: 'Please fill the doors opening time'})
            return false
        }
        if(!os.onStage) {
            setSnack({type: 'warning', content: 'Please fill the requested set time'})
            return false
        }
        if(!os.curfew) {
            setSnack({type: 'warning', content: 'Please enter the curfew time'})
            return false
        }

        return true
    }

    const handleNext = () => {
        const isVenueValid = checkIfVenueValid()
        if(!isVenueValid) {
            // return
        }

        goToNextStep()
    }

    const handleGoBackToReview = (isLocalReview: boolean) => {
        const isVenueValid = checkIfVenueValid()
        if(!isVenueValid) {
            return
        }
        if(isLocalReview) {
            goBackToLocalReview()
        } else {
            goBackToReview()
        }
    }

    const handleClear = () => {
        setOfferShow(
            osi, 
            { venue: {...os.venue, city: '', address: '', totalCapacity: 0, name: '', phone: '', website: '' } }
        )
    }

    return (
        <Flex className="alignstart allwidth">
            <h4 className="title main-color margedBot10px">Venue</h4> 
            <div className="allwidth grid-4-col alignend grid-small-col-gap margedBot50px">
                    
                <Autocomplete
                    freeSolo
                    id="artist-search"
                    disableClearable
                    options={searchOptions}
                    onChange={handleSelectAutoComplete}
                    renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Search"
                                value={searchInput}
                                onChange={(e) => setSearchInput(e.target.value)}
                                InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                }}
                            />
                    )}
                />

                <LabelInput
                    title='Name*'
                    value={os.venue.name}
                    onChange={(e:any) => setOfferShow(
                        osi, 
                        { venue: {...os.venue, name: e.target.value } }
                    )}

                />

                <LabelInput
                    title='City'
                    value={os.venue.city}
                    onChange={(e:any) => setOfferShow(
                        osi, 
                        { venue: {...os.venue, city: e.target.value } }
                    )}
                />

                <LabelInput
                    title='Full Address*'
                    value={os.venue.address}
                    onChange={(e:any) => setOfferShow(
                        osi, 
                        { venue: {...os.venue, address: e.target.value } }
                    )}
                />

                <Row>
                    <CheckboxSpruha 
                        title='Indoor'
                        checked={!os.venue.isOutdoor}
                        onChange={(e:any) => setOfferShow(
                            osi, 
                            { venue: {...os.venue, isOutdoor: !e.target.checked } }
                        )}
                    />
                    <CheckboxSpruha 
                        title='Outdoor'
                        checked={os.venue.isOutdoor}
                        onChange={(e:any) => setOfferShow(
                            osi, 
                            { venue: {...os.venue, isOutdoor: e.target.checked } }
                        )}
                    />
                </Row>
                        
                <LabelInput
                    title='Phone'
                    value={os.venue.phone}
                    onChange={(e:any) => setOfferShow(
                        osi, 
                        { venue: {...os.venue, phone: e.target.value } }
                    )}
                />

                <LabelInput
                    title='Website*'
                    value={os.venue.website || ''}
                    onChange={(e:any) => setOfferShow(
                        osi, 
                        { venue: {...os.venue, website: e.target.value } }
                    )}
                />

                <LabelInput
                    title='Past Shows'
                    value={os.venue.pastShows}
                    onChange={(e:any) => setOfferShow(
                        osi, 
                        { venue: {...os.venue, pastShows: e.target.value } }
                    )}
                />

                <LabelInput
                    title='Total Capacity*'
                    value={os.venue.totalCapacity}
                    type="number"
                    onChange={(e:any) => setOfferShow(
                        osi, 
                        { venue: {...os.venue, totalCapacity: +e.target.value } }
                    )}
                />
                        
                <LabelInput
                    title='Doors Open*'
                    value={os.doorsOpen}
                    type="time"
                    onChange={(e:any) => setOfferShow(
                        osi, 
                        { doorsOpen: e.target.value }
                    )}
                />

                <LabelInput
                    title='Requested Set Time*'
                    value={os.onStage}
                    type="time"
                    onChange={(e:any) => setOfferShow(
                        osi, 
                        { onStage: e.target.value }
                    )}
                />

                <LabelInput
                    title='Curfew*'
                    value={os.curfew}
                    onChange={(e:any) => setOfferShow(
                        osi, 
                        { curfew: e.target.value }
                    )}
                    type="time"
                />
            </div>

            <Flex row className="allwidth">
                {isReviewing
                ?   <button className="button button--primary button--round margedRight100px" onClick={() => handleGoBackToReview(false)}>Apply</button>
                :    
                    isLocalReviewing
                    ?   <button className="button button--primary button--round margedRight100px" onClick={() => handleGoBackToReview(true)}>Apply</button>
                    :   <>
                            <button className="button button--primary button--round margedRight100px" onClick={handlePrevious}>Previous</button>
                           <button className="button button--ghost button--round margedRight100px" onClick={handleClear}>Clear</button> 
                            <button className="button button--primary button--round" onClick={handleNext}>Next</button>
                        </>}
            </Flex>
        </Flex>
    )
}

export default VenueStep