import { countries } from "../../../../utils/models/touring.model"

const ButtonItem = ({ item, currentFilter, handleClickItem, translateCountryCode }: { item: string, currentFilter: string[], handleClickItem: any, translateCountryCode?: boolean }) => {
    
	function handleClick() {
        handleClickItem(item)
	}

	const isCurrentActive = currentFilter.includes(item)
	const activeClass = isCurrentActive ? 'touring-ghost__button--active' : ''

	return (
		<div>
			<button className={`button touring__button touring-ghost__button  ${activeClass}`} onClick={handleClick}>
				{translateCountryCode ? countries[item] : item}
			</button>
		</div>
	)
}

export default ButtonItem