// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.panels_appScreen__gXPMC {
    height: calc(100vh - var(--nav-height));
    width: 100vw;
    top: var(--nav-height);
    position: absolute;
    background: var(--app-background);
    overflow-y: scroll;
}
`, "",{"version":3,"sources":["webpack://./src/user-panels/panels.module.css"],"names":[],"mappings":"AAAA;IACI,uCAAuC;IACvC,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;IAClB,iCAAiC;IACjC,kBAAkB;AACtB","sourcesContent":[".appScreen {\r\n    height: calc(100vh - var(--nav-height));\r\n    width: 100vw;\r\n    top: var(--nav-height);\r\n    position: absolute;\r\n    background: var(--app-background);\r\n    overflow-y: scroll;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"appScreen": `panels_appScreen__gXPMC`
};
export default ___CSS_LOADER_EXPORT___;
