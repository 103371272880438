import { Flex } from "../../../../components/Containers";
import Tabs from "../../../../components/tabs-menu/Tabs";
import { useAppSelector } from "../../../../utils/hooks/reduxTypedHooks";
import UserProfileForm from "../../../common/user/UserProfileForm";
import ProfileTab from "./profile-tab/ProfileTab";
import ProfileHeader from "./ProfileHeader";

const PromotorProfile = () => {
    const client = useAppSelector(state => state.client);
    const promotor = client?.promotor

    if(client === null || promotor === undefined) {
        return (<Flex><p className="grey">Profile not found</p></Flex>)
    }

    return(
        <div className="main-container margedTop20px margedBot50px">

            <div className="margedBot20px">
                <ProfileHeader promotor={promotor} client={client} />
            </div>
            
            <Tabs activeTab='profile' noContainer widthAuto>
                <div option-data='profile' data-icon='tabler:user-check'>
                    <ProfileTab promotor={promotor} client={client} />
                </div>

                <div option-data='team' data-icon='tabler:users'>

                </div>

                <div option-data='projects' data-icon='tabler:layout-grid'>

                </div>

                <div option-data='activity' data-icon='tabler:link'>

                </div>

                <div option-data='security' data-icon='mdi:password-outline'>
                    <UserProfileForm client={client} />
                </div>
            </Tabs>
        </div>
    )
}

export default PromotorProfile