import { Country, ShowDuration, ShowType } from "./touring.model";

interface OfferBinderLetter {
    data: {
        deposit: string
        setLength: number
        date: number
        id: string
        signatures?: any[]   // todo
    },
    url: string
}

// 2 collections, archived et ongoings
export interface Offer {
    date: number;                   // offer date
    id: string;                     // offer identifier
    artistId: string;               // artistId
    artistName: string;             // artist name
    promotorId: string;             // promotorId
    totalAmount: number;            // total offer amount
    status: OfferStatus;            // current offer status
    expiryDate: string;             // YYYY-MM-DD
    archived: boolean;              // different collection of archived offers that we move
    shows: OfferShow[];             // offer shows
    history?: OfferEvent[];         // past offer events
    binderLetter?: OfferBinderLetter
}
export interface OfferCreateObject extends Omit<Offer, "date"|"id"|"history"|"binderLetter"> {
    id?: string;
}

export interface OfferFormInputs extends Omit<OfferCreateObject, "shows"> {
    shows: OfferShowFormInputs[]
}

export interface OfferShow {
    date: string; 
    touringId: string; 
    eventName: string;
    showTypes: ShowType[]; 
    country: Country;
    localCurrency: string;
    showDuration: ShowDuration; 
    
    venue: OfferVenue;

    tickets: ShowTicket[];
    ticketsVariables: TicketVariables

    hasAirFare: boolean;
    airFare: number;
    broadcasted: boolean;
    sponsored: boolean;
    sponsors: ShowSponsor[];
    hasOtherIncomes: boolean;
    otherIncomes: ShowOtherIncome[]

    minAge: string; 
    doorsOpen: string;
    onStage: string;
    curfew: string; 
    visaRequired: boolean;
    nearestAirport?: string;    //? ?? transportation: {international, domestic} in contract
    comments?: string;

    isAmountNet: boolean;
    amountType: AmountType;
    amount: number;
    taxPercent: number;
    dealInputs: OfferDealInputs;

    isPnlMandatory: boolean;
    isPnlDone: boolean;
    pnlInputs: OfferPNLInputs;

    hotel?: OfferHotel;
}

export interface OfferShowFormInputs extends Omit<OfferShow, "showTypes"|"showDuration"> {
    showTypes: {value: ShowType, label: JSX.Element}[];
    showDuration: {value: ShowDuration, label: JSX.Element};
}

export interface ShowTicket {
    name: string;
    quantity: number;
    grossPrice: number;
    compsTicketsPercent: number;
}
export interface TicketVariables {
    taxes: {
        vatPercent: number
        ticketFeePercent: number
        creditCardFeePercent: number
        otherTaxesPercent: number
    },
    compsTickets: number
}

export interface OfferDealInputs {
    hasGuaranteedFee: boolean
    guaranteedFee: number
    witholdingTax: number
    // breakEvenPoint: number
    promotorDistributionPercent: number
}

export interface OfferVenue {
    name: string;
    address: string;
    city: string;
    isOutdoor: boolean;
    phone?: string;
    website: string;
    totalCapacity: number;
    pastShows?: string;
}

export interface OfferHotel {
    name: string;
    address: string;
}

export interface OfferEvent {
    date: number;                   // timestamp
    from: string;                   // client.id
    comment: string;                // free comment
    beforeStatus: OfferStatus;      // status before the event action
    afterStatus: OfferStatus;       // status after the event action
    edited?: {};                    // edited props / value pairs containing new values (`status` is excluded)
}

export interface ShowSponsor {
    name: string
    amount: number
}
export interface ShowOtherIncome {
    name: string
    amount: number
}

export enum OfferStatus { 
    Pending = "PDG",                // Pending
    AdminDenied = "ADD",            // Denied by Admin         
    RequestEdition = "PER",         // Waiting for Promotor Edition
    AdminAccepted = "ADA",          // Accepted by Admin *super-accept case*
    Withdrawn = "WTN",              // Withdrawn
    PromotorTrusteeCheck = "PTC",   // Waiting for Promotor trustee check
    WaitingArtist = "WAT",          // Waiting for artist
    ArtistAccepted = 'ARA',         // Accepted by Artist
    ArtistDenied = "ARD",           // Denied by Artist       
    // Accepted = "ACC",               // Accepted
    Deprecated = "DEP",             // Deprecated
}

export enum AmountType {
    Fixed = "FX",
    Deal = "DL",
}

export interface OfferPNLInputs {
    corporateTicketPurchase: {has: boolean, amount: number}
    variableCosts: {name: string, has: boolean, percentage: number}[]
    fixedCosts: {name: string, has: boolean, amount: number}[]
}

export interface OfferDraft extends Omit<OfferCreateObject, "id"> {
    id: string;
    createdOn: number;
    // offerStep: number;
    // showStep: number;
}
export type OfferDraftPostObject = Omit<OfferDraft, "id"|"createdOn">

export const dummyOffer4 = {
    date: Date.now() - (1000 * 60 * 60 * 1),
    id: '444',
    artistId: "3fc14dc2-de4c-4a7f-85ea-df6b72d9b8a7",
    promotorId: 'f1f7e6b0-5bf2-4344-89e9-0fe99a02ecbc', 
    touringId: 'touring-id4',
    shows: [
        
    ],
    status: OfferStatus.PromotorTrusteeCheck,
    archived: false, 
    totalAmount: 21000
}


//export interface OfferData {
    // Terms and Budget
    // artistRequested: string;
    // offerNett: number; //? string ?
    // FlightContribution: number; //? ?
    // isBroadcasting: boolean;
    // eventSponsorship: string; //? boolean ? number ? 

    // // Event informations
    // //! One per date
    // date: number; //? date.getTime() ? need to parse in form
    // type: string; //? make a enum for types ?
    // venueName: string; //? "name of venue / event"
    // indoorOrOutdoor: any; //? put as boolean isIndoor ? make a enum, better enum ?
    // venueStreetAddress: string;
    // venueWebsite: string; //? "venue / event website"
    // ageLimitToEnter: number;
    // capacity: number;
    // requestedArtistSetTime: string; //? number ? parse date ?
    // doorsOpenClose: any; //? "Doors open /close"
    // PastShowsOnVenue: any; //? ?

    // // ticket scale
    // //? ??

    // // additional infos
    // isVisaApplicationRequired: boolean;
    // nearestAirport: string;
    // hotelName: string;
    // hotelAddress: string;
    // additionalComments: string;

    /* 
        idem contracts replacement props :
            hotel (iso hotelName and hotelAddress)
            comments (iso additionalComments)
            (iso doorsOpenClose)
            venue (iso venueName, venueStreetAddress, venueWebsite)
            transportation (iso FlightContribution)
            netUsdAmount (iso offerNett)   // usd only  
            
    */
//}


export const countriesCurrencies: Record<string, string> = {
    AF: "AFN",
    AL: "ALL",
    DZ: "DZD",
    AS: "USD",
    AD: "EUR",
    AO: "AOA",
    AI: "XCD",
    AG: "XCD",
    AR: "ARS",
    AM: "AMD",
    AW: "AWG",
    AU: "AUD",
    AT: "EUD",
    AZ: "AZN",
    BS: "BSD",
    BH: "BHD",
    BD: "BDT",
    BB: "BBD",
    BY: "BYN",
    BE: "EUD",
    BZ: "BZD",
    BJ: "XOF",
    BM: "BMD",
    BT: "INR",
    BO: "BOB",
    BA: "BAM",
    BW: "BWP",
    BR: "BRL",
    IO: "USD",
    BG: "BGN",
    BF: "XOF",
    BI: "BIF",
    KH: "KHR",
    CM: "XAF",
    CA: "CAD",
    CV: "CVE",
    KY: "KYD",
    CF: "XAF",
    TD: "XAF",
    CL: "CLP",
    CN: "CNY",
    CO: "COP",
    KM: "KMF",
    CG: "XAF",
    CD: "CDF",
    CK: "NZD",
    CR: "CRC",
    CI: "XOF",
    HR: "HRK",
    CU: "CUP",
    CW: "ANG",
    CY: "EUR",
    CZ: "CZK",
    DK: "DKK",
    DJ: "DJF",
    DM: "XCD",
    DO: "DOP",
    EC: "USD",
    EG: "EGP",
    SV: "SVC",
    GQ: "XAF",
    ER: "ERN",
    EE: "EUR",
    ET: "ETB",
    FK: "FKP",
    FO: "DKK",
    FJ: "FJD",
    FI: "EUR",
    FR: "EUR",
    PF: "XPF",
    GA: "XAF",
    GM: "GMD",
    GE: "GEL",
    DE: "EUR",
    GH: "GHS",
    GI: "GIP",
    GR: "EUR",
    GL: "DKK",
    GD: "XCD",
    GU: "USD",
    GT: "GTQ",
    GG: "GBP",
    GN: "GNF",
    GW: "XOF",
    HT: "HTG",
    HN: "HNL",
    HK: "HKD",
    HU: "HUF",
    IS: "ISK",
    IN: "INR",
    ID: "IDR",
    IR: "IRR",
    IQ: "IQD",
    IE: "EUR",
    IM: "GBP",
    IL: "ILS",
    IT: "EUR",
    JM: "JMD",
    JP: "JPY",
    JE: "GBP",
    JO: "JOD",
    KZ: "KZT",
    KE: "KES",
    KI: "AUD",
    KP: "KPW",
    KR: "KRW",
    KW: "KWD",
    KG: "KGS",
    LA: "LAK",
    LV: "EUR",
    LB: "LBP",
    LS: "LSL",
    LR: "LRD",
    LY: "LYD",
    LI: "CHF",
    LT: "EUR",
    LU: "EUR",
    MO: "MOP",
    MK: "MKD",
    MG: "MGA",
    MW: "MWK",
    MY: "MYR",
    MV: "MVR",
    ML: "XOF",
    MT: "EUR",
    MH: "USD",
    MQ: "EUR",
    MR: "MRU",
    MU: "MUR",
    MX: "MXN",
    FM: "USD",
    MD: "MDL",
    MC: "EUR",
    MN: "MNT",
    ME: "EUR",
    MS: "XCD",
    MA: "MAD",
    MZ: "MZN",
    MM: "MMK",
    NA: "NAD",
    NR: "AUD",
    NP: "NPR",
    NL: "EUR",
    NZ: "NZD",
    NI: "NIO",
    NE: "XOF",
    NG: "NGN",
    NU: "NZD",
    NF: "AUD",
    MP: "USD",
    NO: "NOK",
    OM: "OMR",
    PK: "PKR",
    PW: "USD",
    PS: "JOD",
    PA: "PAB",
    PG: "PGK",
    PY: "PYG",
    PE: "PEN",
    PH: "PHP",
    PN: "NZD",
    PL: "PLN",
    PT: "EUR",
    PR: "USD",
    QA: "QAR",
    RO: "RON",
    RU: "RUB",
    RW: "RWF",
    KN: "XCD",
    LC: "XCD",
    WS: "WST",
    SM: "EUR",
    ST: "STN",
    SA: "SAR",
    SN: "XOF",
    RS: "RSD",
    SC: "SCR",
    SL: "SLL",
    SG: "SGD",
    SX: "ANG",
    SK: "EUR",
    SI: "EUR",
    SB: "SBD",
    SO: "SOS",
    ZA: "ZAR",
    SS: "SSP",
    ES: "EUR",
    LK: "LKR",
    SD: "SDG",
    SR: "SRD",
    SZ: "SZL",
    SE: "SEK",
    CH: "CHF",
    SY: "SYP",
    TW: "TWD",
    TJ: "TJS",
    TZ: "TZS",
    TH: "THB",
    TG: "XOF",
    TK: "NZD",
    TO: "TOP",
    TT: "TTD",
    TN: "TND",
    TR: "TRY",
    TM: "TMT",
    TC: "USD",
    TV: "AUD",
    UG: "UGX",
    UA: "UAH",
    AE: "AED",
    GB: "GBP",
    US: "USD",
    UY: "UYU",
    UZ: "UZS",
    VU: "VUV",
    VE: "VES",
    VN: "VND",
    VI: "USD",
    YE: "YER",
    ZM: "ZMW",
    ZW: "ZWL",
  }

export const countriesTaxRate: Record<string, number> = {
    AF: 20,
    AL: 15,
    DZ: 26,
    // AS: "American Samoa",
    // AD: "Andorra",
    AO: 25,
    // AI: "Anguilla",
    // AG: "Antigua and Barbuda",
    AR: 25,
    AM: 18,
    AW: 25,
    AU: 30,
    AT: 25,
    AZ: 20,
    BS: 0,
    BH: 0,
    BD: 30,
    BB: 5.5,
    BY: 18,
    BE: 25,
    // BZ: "Belize",
    // BJ: "Benin",
    BM: 0,
    // BT: "Bhutan",
    BO: 25,
    BA: 10,
    BW: 22,
    BR: 34,
    // IO: "British Indian Ocean Territory",
    BG: 10,
    // BF: "Burkina Faso",
    // BI: "Burundi",
    KH: 20,
    CM: 33,
    CA: 26.5,
    // CV: "Cape Verde",
    KY: 0,
    // CF: "Central African Republic",
    TD: 35,
    CL: 27,
    CN: 25,
    CO: 35,
    // KM: "Comoros",
    CG: 30,
    CD: 30,
    // CK: "Cook Islands",
    CR: 35,
    CI: 25,
    HR: 18,
    // CU: "Cuba",
    // CW: "Curaçao",
    CY: 12.5,
    CZ: 19,
    DK: 22,
    // DJ: "Djibouti",
    // DM: "Dominica",
    DO: 27,
    EC: 25,
    EG: 22.5,
    SV: 30,
    GQ: 35,
    // ER: "Eritrea",
    EE: 20,
    ET: 30,
    // FK: "Falkland Islands (Malvinas)",
    // FO: "Faroe Islands",
    FJ: 20,
    FI: 20,
    FR: 26.5,
    // PF: "French Polynesia",
    GA: 30,
    GM: 27,
    GE: 15,
    DE: 30,
    GH: 25,
    // GI: "Gibraltar",
    GR: 22,
    // GL: "Greenland",
    // GD: "Grenada",
    GU: 21,
    GT: 25,
    // GG: "Guernsey",
    GN: 35,
    // GW: "Guinea-Bissau",
    // HT: "Haiti",
    HN: 25,
    HK: 16.5,
    HU: 9,
    IS: 20,
    IN: 34.94,
    ID: 22,
    // IR: "Iran, Islamic Republic of",
    IQ: 15,
    IE: 12.5,
    IM: 0,
    IL: 23,
    IT: 24,
    JM: 25,
    JP: 30.62,
    // JE: "Jersey",
    JO: 20,
    KZ: 20,
    KE: 30,
    // KI: "Kiribati",
    // KP: "North Korea",
    KR: 27.5,
    KW: 15,
    // KG: "Kyrgyzstan",
    LA: 20,
    LV: 20,
    LB: 17,
    LS: 25,
    // LR: "Liberia",
    LY: 20,
    LI: 12.5,
    LT: 15,
    LU: 24.94,
    MO: 12,
    MK: 10,
    MG: 20,
    MW: 30,
    MY: 24,
    MV: 15,
    // ML: "Mali",
    MT: 35,
    // MH: "Marshall Islands",
    // MQ: "Martinique",
    MR: 25,
    MU: 15,
    MX: 30,
    FM: 21,
    MD: 12,
    // MC: "Monaco",
    MN: 25,
    ME: 9,
    // MS: "Montserrat",
    MA: 31,
    MZ: 32,
    MM: 22,
    NA: 32,
    // NR: "Nauru",
    // NP: "Nepal",
    NL: 25.8,
    NZ: 28,
    NI: 30,
    // NE: "Niger",
    NG: 30,
    // NU: "Niue",
    // NF: "Norfolk Island",
    // MP: "Northern Mariana Islands",
    NO: 22,
    OM: 15,
    PK: 29,
    // PW: "Palau",
    // PS: "Palestinian Territory",
    PA: 25,
    PG: 30,
    PY: 10,
    PE: 29.5,
    PH: 25,
    // PN: "Pitcairn",
    PL: 19,
    PT: 21,
    PR: 37.5,
    QA: 10,
    RO: 16,
    RU: 20,
    RW: 30,
    // KN: "Saint Kitts and Nevis",
    // LC: "Saint Lucia",
    WS: 27,
    // SM: "San Marino",
    // ST: "Sao Tome and Principe",
    SA: 20,
    SN: 30,
    RS: 15,
    SC: 33,
    SL: 25,
    SG: 17,
    // SX: "Sint Maarten",
    SK: 21,
    SI: 19,
    SB: 30,
    // SO: "Somalia",
    ZA: 28,
    // SS: "South Sudan",
    ES: 25,
    LK: 24,
    SD: 35,
    SR: 36,
    SZ: 27.5,
    SE: 20.6,
    CH: 14.93,
    SY: 28,
    TW: 20,
    // TJ: "Tajikistan",
    TZ: 30,
    TH: 20,
    // TG: "Togo",
    // TK: "Tokelau",
    TO: 25,
    TT: 30,
    TN: 15,
    TR: 23,
    // TM: "Turkmenistan",
    // TC: "Turks and Caicos Islands",
    // TV: "Tuvalu",
    UG: 30,
    UA: 18,
    AE: 0,
    GB: 19,
    US: 21,
    UY: 25,
    UZ: 15,
    VU: 0,
    VE: 34,
    VN: 20,
    // VI: "Virgin Islands",
    // YE: "Yemen",
    ZM: 30,
    ZW: 24.72,
  }