import { LOCATION_EVERYWHERE } from "../touring.util";
import { Artist } from "./artists.model";

// In backend the tour is automatically moved to archived collection when the touring date is past (add boolean isArchived as well ?)
export interface Touring {
    id: string;
    name: string;
    artistId: string;
    from: string; // Date().toDateString() ie 'Tue Nov 15 2022'
    to: string; 
    shows: TouringDay[]
    showDuration: ShowDuration;
    possibleFee?: number;
    countries: Country[];
    dateCreated: number; // Date().getTime()
    comments?: string;
    isPnlMandatory: boolean;
    isPast: boolean;
    assets?: string[]
    isPotential?: undefined
    requests: TouringRequest[]   // promotor IDs
}

// for create touring
export type TouringWithoutId = Omit<Touring, "id">
// No Ids in client created shows, and isPast made in backend
export interface TouringCreateObject extends Omit<TouringWithoutId, "shows"|"isPast"> {
    shows: TouringDayCreateObject[]
}

// for update touring, all fields optional except id
export type TouringMergePartial = Partial<Touring>
// No Ids in shows passed to update touring
export interface TouringMergeObject extends Omit<TouringMergePartial, "shows"> {
    id: string;
    shows: TouringDayCreateObject[]
}

export interface TouringWithArtist extends Touring {
    artist: Artist
}

export interface TouringDay {
    id: string;
    date: string; // Date().toDateString()
    country: Country;
    showDuration: ShowDuration;
    isDayOff: boolean;
    possibleFee?: number;
    // blocked: {to: promotorId of who it is blocked for, date: timestamp of the date it has been blocked}, if to = '' it means not blocked
    blocked: {to: string, date: number}; 
    //todo signature: string; // '' means not signed
    offerId: string;        // winning tender, if '' means no offer won yet
    offersIds: string[];    // all related offers ids
    isPast: boolean;
    isPnlMandatory: boolean;
}

export type TouringDayWithoutId = Omit<TouringDay, "id">
export type TouringDayCreateObject = Omit<TouringDay, "id"|"isPast">
export interface TouringDayWithTouringId extends TouringDay {
    touringId: string
}

export interface TouringRequest {
    promotorId: string;
    dateCreated: number;
    datesRequested: string[]     // Date().toDateString()
    showsCountries: Country[]
    showsVenues: string[]
    showsAmounts: string[]
}

export interface PotentialTouring {
    id: string;
    dateCreated: number; // Date().getTime()
    isPast: boolean;
    artistId: string;
    from: string; // Date().toDateString() ie 'Tue Nov 15 2022'
    to: string; 
    showDuration: ShowDuration;
    countries: Country[];
    possibleFee?: number;
    comments?: string;
    isPotential: true;
}
export type PotentialTouringCreateObject = Omit<PotentialTouring, 'id'|'isPast'>
export interface PotentialTouringWithArtist extends PotentialTouring {
    artist: Artist
}

export enum ShowDuration {
    thirtyMin = 30,
    fortyFiveMins = 45,
    sixtyMin = 60,
    ninetyMin = 90,
}
// for some reasons object.values would also return the keys strings here when the enum values are of type number, so need to filter
export const TouringShowDurationsList = Object.values(ShowDuration).filter(value => typeof value === 'number')

export enum ShowType {
    dj = "Dj Set",
    live = "Live",
    private = "Private",
    festival = "Festival",
    club = "Club",
    solo = "Solo"
}
export const TouringShowTypesList = Object.values(ShowType)

export enum Country {
    cambodia = "KH",
    india = "IN",
    indonesia = "ID",
    japan = "JP",
    kazakhstan = "KZ",
    malaysia = "MY",
    philippines = "PH",
    singapore = "SG",
    skorea = "KR",
    taiwan = "TW",
    thailand = "TH",
    vietnam = "VN",
}
export const TouringCountriesList = Object.values(Country)


export const dummyPotentialTouring1: PotentialTouring = {
    id: "adadad",
    dateCreated: 11313131311,
    isPast: false,
    artistId: '3fc14dc2-de4c-4a7f-85ea-df6b72d9b8a7',
    from: "Tue March 15 2023",
    to: "Fri March 18 2023", 
    showDuration: 45,
    countries: [Country.singapore],
    possibleFee: 30000,
    comments: "First potential touring test",
    isPotential: true,
}

// export const dummyTouring4: Touring = {
//     id: '11',
//     artistId: '19b3f8ba-17f0-44eb-a6a3-02f9b3bb3164',
//     from: 1668873600000,
//     to: 1669305600000,
//     possibleFee: 0,
//     shows: [{date: 1668873600000, isDayOff: false, possibleFee: 0, showDuration: ShowDuration.ninetyMin, showTypes: [ShowType.club], country: Country.indonesia, blocked: 0, offerId: ''}],
//     showDuration: ShowDuration.ninetyMin,
//     showTypes: [ShowType.club],
//     countries: [Country.indonesia]
// }

// export const dummyTouring3: Touring = {
//     id: '33',
//     artistId: '3fc14dc2-de4c-4a7f-85ea-df6b72d9b8a7',
//     from: 'Tue Nov 24 2022',
//     to: 'Sat Nov 26 2022',
//     possibleFee: 0,
//     shows: [
//         {id: '111', date: 'Thu Nov 24 2022', isDayOff: false, possibleFee: 0, showDuration: ShowDuration.sixtyMin, showTypes: [ShowType.club], country: Country.singapore, blocked: {to: '', date: 0}, offerId: '', offersIds: []},
//         {id: '222',date: 'Fri Nov 25 2022', isDayOff: false, possibleFee: 0, showDuration: ShowDuration.sixtyMin, showTypes: [ShowType.club], country: Country.singapore, blocked: {to: '', date: 0}, offerId: '11131', offersIds: ['11131']},
//         {id: '333',date: 'Sat Nov 26 2022', isDayOff: false, possibleFee: 0, showDuration: ShowDuration.sixtyMin, showTypes: [ShowType.club], country: Country.singapore, blocked: {to: '', date: 0}, offerId: '', offersIds: []},
//     ],
//     showDuration: ShowDuration.sixtyMin,
//     showTypes: [ShowType.club],
//     countries: [Country.singapore],
//     dateCreated: 1668950877327
// }

export const AS = {
    AS:
    [
    "AF",
    "AM",
    "BH",
    "BD",
    "BT",
    "BN",
    "CN",
    "IO",
    "KH",
    "GE",
    "HK",
    "IN",
    "ID",
    "IR",
    "IQ",
    "IL",
    "JP",
    "JO",
    "KZ",
    "KW",
    "KG",
    "LA",
    "LB",
    "MO",
    "MY",
    "MV",
    "MN",
    "MM",
    "NP",
    "KP",
    "OM",
    "PK",
    "QA",
    "PH",
    "SA",
    "SG",
    "KR",
    "LK",
    "SY",
    "TW",
    "TJ",
    "TH",
    "TR",
    "TM",
    "AZ",
    "UZ",
    "VN",
    "YE",
    ]
}
export const AM = {
    AM:
    [
    "AR",
    "AW",
    "BO",
    "BR",
    "AI",
    "AG",
    "BS",
    "BB",
    "BZ",
    "BM",
    "CA",
    "VG",
    "KY",
    "CL",
    "CO",
    "CR",
    "CU",
    "CW",
    "DM",
    "DO",
    "EC",
    "SV",
    "FK",
    "GL",
    "GP",
    "GT",
    "GY",
    "HT",
    "HN",
    "JM",
    "MX",
    "MS",
    "NI",
    "PA",
    "PY",
    "PE",
    "PR",
    "BL",
    "KN",
    "LC",
    "MF",
    "PM",
    "VC",
    "SR",
    "TT",
    "US",
    "UY",
    "VE",
    ]
}
export const EU = {
    EU:
    [
    "AL",
    "AD",
    "AT",
    "BY",
    "BE",
    "BA",
    "BG",
    "HR",
    "CY",
    "CZ",
    "DK",
    "EE",
    "FO",
    "FI",
    "FR",
    "DE",
    "GI",
    "GR",
    "HU",
    "IS",
    "IE",
    "IM",
    "IT",
    "XK",
    "LV",
    "LI",
    "LT",
    "LU",
    "MK",
    "MT",
    "MD",
    "MC",
    "ME",
    "NL",
    "NO",
    "PL",
    "PT",
    "RO",
    "RU",
    "SM",
    "RS",
    "SK",
    "SI",
    "ES",
    "SE",
    "CH",
    "UA",
    "GB",
    "VA",
    ]
}
export const OC = {
    OC: 
    [
    "AS",
    "AU",
    "CK",
    "TL",
    "FJ",
    "PF",
    "GU",
    "KI",
    "MH",
    "FM",
    "NR",
    "NC",
    "NZ",
    "NU",
    "NF",
    "MP",
    "PW",
    "PG",
    "PN",
    "WS",
    "SB",
    "TK",
    "TV",
    "VU",
    ]
}
export const AF = {
    AF: 
    [
    "AF",
    "DZ",
    "AO",
    "BJ",
    "BW",
    "BF",
    "BI",
    "CM",
    "CV",
    "CF",
    "KM",
    "CD",
    "DJ",
    "EG",
    "GQ",
    "ER",
    "ET",
    "GA",
    "GM",
    "GH",
    "GN",
    "GW",
    "CI",
    "KE",
    "LS",
    "LR",
    "LY",
    "MG",
    "MW",
    "ML",
    "MR",
    "MU",
    "MA",
    "MZ",
    "NA",
    "NE",
    "NG",
    "CG",
    "RE",
    "RW",
    "SH",
    "ST",
    "SN",
    "SC",
    "SL",
    "SO",
    "ZA",
    "SS",
    "SD",
    "SZ",
    "TZ",
    "TG",
    "TN",
    "UG",
    "EH",
    "ZM",
    "ZW",
    ]
}

export const continentsList = [AS, AM, EU, OC, AF]
export const continentDisplay: Record<string, string> = {
    AS: "Asia",
    AM: "Americas",
    EU: "Europe",
    OC: "Oceania",
    AF: "Africa"
}

export const countries: Record<string, string> = {
    AF: "Afghanistan",
    AL: "Albania",
    DZ: "Algeria",
    AS: "American Samoa",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AG: "Antigua and Barbuda",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    AZ: "Azerbaijan",
    BS: "Bahamas",
    BH: "Bahrain",
    BD: "Bangladesh",
    BB: "Barbados",
    BY: "Belarus",
    BE: "Belgium",
    BZ: "Belize",
    BJ: "Benin",
    BM: "Bermuda",
    BT: "Bhutan",
    BO: "Bolivia, Plurinational State of",
    BA: "Bosnia and Herzegovina",
    BW: "Botswana",
    BR: "Brazil",
    IO: "British Indian Ocean Territory",
    BG: "Bulgaria",
    BF: "Burkina Faso",
    BI: "Burundi",
    KH: "Cambodia",
    CM: "Cameroon",
    CA: "Canada",
    CV: "Cape Verde",
    KY: "Cayman Islands",
    CF: "Central African Republic",
    TD: "Chad",
    CL: "Chile",
    CN: "China",
    CO: "Colombia",
    KM: "Comoros",
    CG: "Congo",
    CD: "Democratic Republic of the Congo",
    CK: "Cook Islands",
    CR: "Costa Rica",
    CI: "Côte d'Ivoire",
    HR: "Croatia",
    CU: "Cuba",
    CW: "Curaçao",
    CY: "Cyprus",
    CZ: "Czech Republic",
    DK: "Denmark",
    DJ: "Djibouti",
    DM: "Dominica",
    DO: "Dominican Republic",
    EC: "Ecuador",
    EG: "Egypt",
    SV: "El Salvador",
    GQ: "Equatorial Guinea",
    ER: "Eritrea",
    EE: "Estonia",
    ET: "Ethiopia",
    FK: "Falkland Islands (Malvinas)",
    FO: "Faroe Islands",
    FJ: "Fiji",
    FI: "Finland",
    FR: "France",
    PF: "French Polynesia",
    GA: "Gabon",
    GM: "Gambia",
    GE: "Georgia",
    DE: "Germany",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Greece",
    GL: "Greenland",
    GD: "Grenada",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guinea",
    GW: "Guinea-Bissau",
    HT: "Haiti",
    HN: "Honduras",
    HK: "Hong Kong",
    HU: "Hungary",
    IS: "Iceland",
    IN: "India",
    ID: "Indonesia",
    IR: "Iran, Islamic Republic of",
    IQ: "Iraq",
    IE: "Ireland",
    IM: "Isle of Man",
    IL: "Israel",
    IT: "Italy",
    JM: "Jamaica",
    JP: "Japan",
    JE: "Jersey",
    JO: "Jordan",
    KZ: "Kazakhstan",
    KE: "Kenya",
    KI: "Kiribati",
    KP: "North Korea",
    KR: "South Korea",
    KW: "Kuwait",
    KG: "Kyrgyzstan",
    LA: "Lao People's Democratic Republic",
    LV: "Latvia",
    LB: "Lebanon",
    LS: "Lesotho",
    LR: "Liberia",
    LY: "Libya",
    LI: "Liechtenstein",
    LT: "Lithuania",
    LU: "Luxembourg",
    MO: "Macao",
    MK: "Republic of Macedonia",
    MG: "Madagascar",
    MW: "Malawi",
    MY: "Malaysia",
    MV: "Maldives",
    ML: "Mali",
    MT: "Malta",
    MH: "Marshall Islands",
    MQ: "Martinique",
    MR: "Mauritania",
    MU: "Mauritius",
    MX: "Mexico",
    FM: "Micronesia, Federated States of",
    MD: "Republic of Moldova",
    MC: "Monaco",
    MN: "Mongolia",
    ME: "Montenegro",
    MS: "Montserrat",
    MA: "Morocco",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    NL: "Netherlands",
    NZ: "New Zealand",
    NI: "Nicaragua",
    NE: "Niger",
    NG: "Nigeria",
    NU: "Niue",
    NF: "Norfolk Island",
    MP: "Northern Mariana Islands",
    NO: "Norway",
    OM: "Oman",
    PK: "Pakistan",
    PW: "Palau",
    PS: "Palestinian Territory",
    PA: "Panama",
    PG: "Papua New Guinea",
    PY: "Paraguay",
    PE: "Peru",
    PH: "Philippines",
    PN: "Pitcairn",
    PL: "Poland",
    PT: "Portugal",
    PR: "Puerto Rico",
    QA: "Qatar",
    RO: "Romania",
    RU: "Russia",
    RW: "Rwanda",
    KN: "Saint Kitts and Nevis",
    LC: "Saint Lucia",
    WS: "Samoa",
    SM: "San Marino",
    ST: "Sao Tome and Principe",
    SA: "Saudi Arabia",
    SN: "Senegal",
    RS: "Serbia",
    SC: "Seychelles",
    SL: "Sierra Leone",
    SG: "Singapore",
    SX: "Sint Maarten",
    SK: "Slovakia",
    SI: "Slovenia",
    SB: "Solomon Islands",
    SO: "Somalia",
    ZA: "South Africa",
    SS: "South Sudan",
    ES: "Spain",
    LK: "Sri Lanka",
    SD: "Sudan",
    SR: "Suriname",
    SZ: "Swaziland",
    SE: "Sweden",
    CH: "Switzerland",
    SY: "Syria",
    TW: "Taiwan",
    TJ: "Tajikistan",
    TZ: "Tanzania",
    TH: "Thailand",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad and Tobago",
    TN: "Tunisia",
    TR: "Turkey",
    TM: "Turkmenistan",
    TC: "Turks and Caicos Islands",
    TV: "Tuvalu",
    UG: "Uganda",
    UA: "Ukraine",
    AE: "United Arab Emirates",
    GB: "United Kingdom",
    US: "United States",
    UY: "Uruguay",
    UZ: "Uzbekistan",
    VU: "Vanuatu",
    VE: "Venezuela, Bolivarian Republic of",
    VN: "Viet Nam",
    VI: "Virgin Islands",
    YE: "Yemen",
    ZM: "Zambia",
    ZW: "Zimbabwe",
    [LOCATION_EVERYWHERE]: LOCATION_EVERYWHERE
  }