import moment from "moment"
import { useOutletContext } from "react-router-dom"
import { ArtistDetailsContext } from "../ArtistDetails"
import ArtistInfos from "../common/ArtistInfos"

const ArtistDetailsAlbums = () => {

    const { fetchedDatas, artist } = useOutletContext<ArtistDetailsContext>()
    const albums = fetchedDatas.spotify?.albums ? fetchedDatas.spotify.albums : []

    return (
        <div className="main-container leftalign">
            <h2 className="margedBot20px">Discography</h2>
            
            <div className="flex-only row space-between">

                <div className="width70">
                    <div className="app-card">
                        <div className="details-title-border">
                            <h4 className="details-section-title">Albums</h4>
                        </div>
                        <div className='grid-3-col row-gap40 text-centered'>
                            {albums.map((album, index) => 
                                <div key={index}>
                                    <a className="hover-opacity--medium" href={album.external_urls.spotify} target='_blank' rel="noreferrer">
                                        <img style={{width: '150px', height: '150px'}} src={album.images[0].url} alt='album cover' />
                                    </a>
                                    <a className="hover-opacity--medium" href={album.external_urls.spotify} target='_blank' rel="noreferrer">
                                        <p>{album.name}</p>
                                    </a>
                                    <p className="artist-album-release">Relased on {moment(new Date(album.release_date)).format('MMMM Do, YYYY')}</p>
                                </div>)}
                        </div>
                    </div>
                </div>

                <div className="width25">
                    <div className="app-card">
                        <ArtistInfos artist={artist} spotifyData={fetchedDatas.spotify} />
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ArtistDetailsAlbums