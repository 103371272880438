
const LoginIllustration2 = () => {

    return (
        <svg width="181" height="181" viewBox="0 0 181 181" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="1.30469" y="1.44312" width="178" height="178" rx="19" stroke="#7367F0" strokeOpacity="0.16" strokeWidth="2" strokeDasharray="8 8"/>
<rect x="22.8047" y="22.9431" width="135" height="135" rx="10" fill="#7367F0" fillOpacity="0.08"/>
</svg>
    )
}

export default LoginIllustration2