import { ChartData } from "chart.js"
import ChartJsDoughnut from "../../../../../../components/Charts/ChartsJsDoughnut";
import { colors } from "../../../../../../constants";

const FixedIncomePieChart = ({ currency, currencySymbol, hasOtherIncomes, otherIncomes, sponsored, sponsors, corporateTicketPurchase }: {
    currency: string,
    currencySymbol: null|string,
    hasOtherIncomes: boolean,
    otherIncomes: { name: string; amount: number; }[],
    sponsored: boolean,
    sponsors: { name: string; amount: number; }[],
    corporateTicketPurchase: { has: boolean; amount: number }
}) => {

    const hasCorporateTicketPurchase = corporateTicketPurchase.has && corporateTicketPurchase.amount
    const corporateTicketPurchaseLabel = hasCorporateTicketPurchase ? ["Corporate Ticket Purchase"] : []
    const corporateTicketPurchaseAmount = hasCorporateTicketPurchase ? [corporateTicketPurchase.amount] : [] 

    const _otherIncomes = hasOtherIncomes ? otherIncomes : []
    const otherIncomesLabels = _otherIncomes.map(income => income.name)
    const otherIncomesAmounts = _otherIncomes.map(income => income.amount)

    const _sponsors = sponsored ? sponsors : []
    const sponsorsLabels = _sponsors.map(sponsor => `Sponsor: ${sponsor.name}`)
    const sponsorsAmounts = _sponsors.map(sponsor => sponsor.amount)

    const hasFixedIncomes = corporateTicketPurchaseAmount.length || sponsorsAmounts.length || otherIncomesAmounts.length


    const pieData: ChartData<"doughnut"> = {
        labels: [
            ...corporateTicketPurchaseLabel,
            ...otherIncomesLabels,
            ...sponsorsLabels,
        ],
        datasets: [{
            data: [
                ...corporateTicketPurchaseAmount,
                ...otherIncomesAmounts,
                ...sponsorsAmounts
            ],
            backgroundColor: [
            colors.green,
            colors.lightblue,
            colors.orange,
            colors.main,
            colors.red,
            colors.gray,
            ],
        }],
    }

    return (
        <div className="app-card" style={{width: '350px'}}>
            <p className="margedBot10px">Fix Income ({currencySymbol ?? currency})</p>
            <div>
                {hasFixedIncomes
                ?   <ChartJsDoughnut 
                        data={pieData}
                        legend
                        cutout={0}
                    />
                :   <p className="margedTop50px grey">No Fixed income.</p>}
            </div>
        </div>
    )
}

export default FixedIncomePieChart