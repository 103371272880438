import Tabs from "../../../../../components/tabs-menu/Tabs"
import { useAppSelector } from "../../../../../utils/hooks/reduxTypedHooks"
import ArtistsEditList from "./ArtistsEditList"

const AdminArtists = () => {

    const artists = useAppSelector(state => state.artists)
    const archivedArtists = useAppSelector(state => state.archivedArtists)

    return (
        <Tabs activeTab="Active Artists">
            <div option-data='Active Artists'>
                <ArtistsEditList isArchivedList={false} artists={artists} />
            </div>

            <div option-data='Archived Artists'>
                <ArtistsEditList isArchivedList={true} artists={archivedArtists} />
            </div>
        </Tabs>
    )
}

export default AdminArtists