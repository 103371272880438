import { useState } from "react"
import { BiLayerPlus } from "react-icons/bi"
import { Flex, Row } from "../../../../components/Containers"
import useFilter from "../../../../utils/hooks/useFilter"
import { User } from "../../../../utils/models/users.model"
import UserForm from "./UserForm"
import UserItemRow from "./UserItemRow"

const UsersList = ({ users, isArchivedList }: {
    users: User[],
    isArchivedList?: boolean,
}) => {

    const [activeTab, setActiveTab] = useState('list')

    const handleCloseForm = () => setActiveTab('list')

    const {FilterUI, filterInput} = useFilter("User email")

    const filteredUsers = users.filter(user => user.email.toLowerCase().includes(filterInput.toLowerCase()))

    const renderUserRow = (user: User, index: number) => <UserItemRow key={index} user={user} isArchivedList={isArchivedList} />

    return (
        <Flex flex={10} className='allwidth'>

            {activeTab === 'create'
            ?   <UserForm close={handleCloseForm} />
            :   <div className="main-container ">
                    <h3 className="page-title margedTop30px">Users</h3>

                    <Row className="allwidth margedBot30px">
                        <Flex row flex={isArchivedList ? 10 : 8}>
                            {FilterUI}
                        </Flex>
                        {isArchivedList
                        ?  <></> 
                        :   <Flex flex={2}>
                                <button 
                                    className="flex row button button--primary uppercase"
                                    onClick={() => setActiveTab('create')}
                                >
                                    <BiLayerPlus size={25} />
                                    &nbsp;
                                    New User
                                </button>
                            </Flex>}
                    </Row>

                    <Flex row flex={10} className="justifystart wrap spaceevenly ">
                            {filteredUsers 
                            ?
                                <table className="app-table">
                                    <thead>
                                        <tr>
                                            <th className="leftalign">Email</th>
                                            <th>Licence</th>
                                            <th>Actor</th>
                                            <th>Active</th>
                                            <th>{isArchivedList ? "Delete" : "Edit"}</th>
                                            <th>{isArchivedList ? "Unarchive" : "Archive"}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredUsers.map(renderUserRow)}
                                    </tbody>
                                </table>
                            
                            :   <p className="grey">No users.</p>}

                        </Flex>
                    </div>}
        </Flex>
    )
}

export default UsersList