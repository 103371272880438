import axios from "axios";

export const axiosGetRequest = async (url: string, params?: Object, headers?: any) => {
    const options = {
        method: 'GET',
        url: url,
        params: params,
        headers: headers,
    };
      
    try {
        const response = await axios.request(options)
        return response
    } catch (error) {
        console.log(error)
        return null
    }
}

export const rapidAPIInstagramStatisticsAPIRequest = async (url:string) => {
    const options = {
        method: 'GET',
        url: 'https://instagram-statistics-api.p.rapidapi.com/community',
        params: { url },
        headers: {
            'X-RapidAPI-Key': process.env.REACT_APP_RAPIDAPI_KEY,
            'X-RapidAPI-Host': 'instagram-statistics-api.p.rapidapi.com'
        }
    };

    try {
        const response = await axios.request(options)
        return response
    } catch (error) {
        console.log(error)
        return null
    }
}

export const rapidAPIForexAPIRequest = async (from: string, to: string, amount: number) => {
    const options = {
        method: 'GET',
        url: 'https://currency-conversion-and-exchange-rates.p.rapidapi.com/convert',
        params: {from, to, amount},
        headers: {
          'X-RapidAPI-Key': process.env.REACT_APP_RAPIDAPI_KEY,
          'X-RapidAPI-Host': 'currency-conversion-and-exchange-rates.p.rapidapi.com'
        }
      };

    try {
        const response = await axios.request(options)
        return response
    } catch (error) {
        console.log(error)
        return null
    }
}