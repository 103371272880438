import { useState, forwardRef } from "react"
import FormSection from "../../../../components/form/form-components/FormSection"
import SelectInput from "../../../../components/form/form-components/SelectInput"
import LabelInput from "../../../../components/form/mui/label-input/LabelInput"
import { useAppSelector } from "../../../../utils/hooks/reduxTypedHooks"
import { Country, ShowDuration, Touring, TouringCountriesList, TouringShowDurationsList, PotentialTouringWithArtist } from "../../../../utils/models/touring.model"
import DatePicker from "react-datepicker";
import moment from 'moment'
import { Flex } from "../../../../components/Containers"
import { Artist, ArtistMinifiedStreamingDatas, NormalizedArtistMarcoDB } from "../../../../utils/models/artists.model"
import { countries } from "../../../../utils/models/touring.model"
import CheckboxSpruha from "../../../../components/form/spruha/CheckboxSpruha"
import useFillTouringDays from "../../../../utils/hooks/useFillTouringDays"
import { useHandleSubmitTouring, useHandleUpdateTouring, useValidateTouringForm } from "../../../../utils/touringForm.util"

export interface inputDay {
    date: Date
    isDayOff: boolean
    country: {value: Country, label: any}
    showDuration: {value: ShowDuration, label: any}
    possibleFee?: number
    offerId: string
    offersIds: string[]
    blocked: {value: string, label: any}
}

export interface TouringFormInputs {
    artistSelected: {
            value: string;
            label: JSX.Element;
        }
    name: string;
    from: Date;
    to: Date;
    showDuration: {
            value: ShowDuration;
            label: JSX.Element;
        };
    possibleFee?: number;
    countries: {
            value: Country;
            label: JSX.Element;
        }[];
    shows: inputDay[];
    comments: string;
    isPnlMandatory: boolean;
}

const TouringForm = ({ touring, artist, potentialTouring, readOnly, closeForm, goBack, dates, artistToAdd, addArtist, streamingDatas } : 
    { 
        touring?: Touring, 
        artist?: Artist,
        potentialTouring?: PotentialTouringWithArtist,
        readOnly?: boolean, 
        closeForm?: any, 
        goBack?: any,
        dates?: {from: Date, to: Date},
        addArtist?: boolean,
        artistToAdd?: NormalizedArtistMarcoDB,
        streamingDatas?: ArtistMinifiedStreamingDatas
    }) => {

    // Form inputs prefilled values
    const artistInput = artist ? {value: artist.displayableName, label: <div className="flex row justifystart"><b>{artist.displayableName}</b></div> } : null
    const potentialTouringArtist = potentialTouring ? {value: potentialTouring.artist.displayableName, label: <div className="flex row justifystart"><b>{potentialTouring.artist.displayableName}</b></div> } : null
    const artistToAddArtist = artistToAdd ? {value: artistToAdd.displayableName, label: <div className="flex row justifystart"><b>{artistToAdd.displayableName}</b></div> } : null
    const filledArtist = artistInput ?? potentialTouringArtist ?? artistToAddArtist ?? null
    
    const touringFromDate = touring ? new Date(touring.from) : null
    const inputFromDate = dates ? dates.from : null
    const potentialTouringFromDate = potentialTouring ? new Date(potentialTouring.from) : null 
    const filledFromDate = touringFromDate ?? inputFromDate ?? potentialTouringFromDate

    const touringToDate = touring ? new Date(touring.to) : null
    const inputToDate = dates ? dates.to : null
    const potentialTouringToDate = potentialTouring ? new Date(potentialTouring.to) : null 
    const filledToDate = touringToDate ?? inputToDate ?? potentialTouringToDate ?? null

    const promotors = useAppSelector(state => state.promotors)

    const touringShowDuration = touring ? {value: touring.showDuration, label: <div className="flex row justifystart"><b>{touring.showDuration} Minutes</b></div>} : null
    const touringCountries = touring ? touring.countries.map(country => ({value: country, label: <div className="flex row justifystart"><b>{countries[country]}</b></div>})) : null
    const potentialTouringShowDuration = potentialTouring ? {value: potentialTouring.showDuration, label: <div className="flex row justifystart"><b>{potentialTouring.showDuration} Minutes</b></div>} : null
    const potentialTouringCountries = potentialTouring ? potentialTouring.countries.map(country => ({value: country, label: <div className="flex row justifystart"><b>{countries[country]}</b></div>})) : null
    const filledShowDuration = touringShowDuration ?? potentialTouringShowDuration ?? null
    const filledCountries = touringCountries ?? potentialTouringCountries ?? null

    const filledComments = touring?.comments ?? potentialTouring?.comments ?? null
    const filledPossibleFee = touring?.possibleFee ?? potentialTouring?.possibleFee ?? null
    const filledIsPnlMandatory = (touring?.isPnlMandatory !== undefined )? touring.isPnlMandatory : null

    const filledShows = touring 
    ?   touring.shows.map(day => ({
            ...day,
            date: new Date(day.date),
            country: {value: day.country, label: <div className="flex row justifystart"><b>{countries[day.country]}</b></div>},
            showDuration: {value: day.showDuration, label: <div className="flex row justifystart"><b>{day.showDuration} Minutes</b></div>},
            blocked: {
                value: day.blocked.to, 
                label: <div className="flex row justifystart">
                        <b>{day.blocked.to 
                            ?  promotors.find(promotor => promotor.id === day.blocked.to)?.name
                            : ''}</b>
                    </div>
            },
        }))
    :   null

    const today = new Date()
    today.setHours(0,0,0,0)


    // Form Inputs
    const [formInputs, setFormInputs] = useState<TouringFormInputs>({
        artistSelected: filledArtist ?? { value: '', label: <div></div> },
        name: touring?.name ?? '',
        from: filledFromDate ?? today,
        to: filledToDate ?? today,
        showDuration: filledShowDuration ?? {value: ShowDuration.sixtyMin, label: <div className="flex row justifystart"><b>{ShowDuration.sixtyMin}</b></div>},
        possibleFee: filledPossibleFee ?? undefined,
        countries: filledCountries ??  [],
        shows: filledShows ?? [] as inputDay[],
        comments: filledComments ??  '',
        isPnlMandatory: filledIsPnlMandatory ?? false,
    })

    const [isDj, setIsDj] = useState(false)
    
    const [isPosting, setIsPosting] = useState(false)
    
    const updateInput = (value: any, inputName: string) => {
        setFormInputs(prevInputs => ({...prevInputs, [inputName]: value}))
    }


    // Automatically prefilling days data when global data change
    useFillTouringDays({formInputs, updateInput, touring})

    const artists = useAppSelector(state => state.artists)

    const artistsList = artists.map((artist) => ({ value: artist.displayableName, label: <div className="flex row justifystart"><b>{artist.displayableName}</b></div> }))
    const countriesList = TouringCountriesList.map(country => ({value: country, label: <div className="flex row justifystart"><b>{countries[country]}</b></div>}))
    const countriesOptionsList = formInputs.countries

    const showDurationsList = TouringShowDurationsList.map(duration => ({ value: duration, label: <div className="flex row justifystart"><b>{duration} Minutes</b></div> }))
    
    const promotorsList = promotors.map(promotor => ({value: promotor.id, label: <div className="flex row justifystart"><b>{promotor.name}</b></div>}))


    const updateDay = (value: any, inputName: string, dayIndex: number) => {
        setFormInputs(prevInputs => {
            const updatedDay = {...prevInputs.shows[dayIndex], [inputName]: value}
            const updatedDays = [...prevInputs.shows]
            updatedDays[dayIndex] = updatedDay
            
            return {...prevInputs, shows: updatedDays}
        })
    }

    const resetForm = () => {
        setFormInputs({
            artistSelected: {value: '',label: <div></div>},
            name: '',
            from: today,
            to: today,
            showDuration: {value: ShowDuration.sixtyMin, label: <div className="flex row justifystart"><b>{ShowDuration.sixtyMin}</b></div>},
            possibleFee: undefined,
            countries: [],
            shows: [],
            comments: '',
            isPnlMandatory: false,
        })
    }

    const validateForm = useValidateTouringForm({formInputs, touring})

    const handleSubmit = useHandleSubmitTouring({formInputs, setIsPosting, validateForm, resetForm, artistToAdd, addArtist, streamingDatas, isDj})

    const handleUpdate = useHandleUpdateTouring({formInputs, touring, setIsPosting, validateForm, closeForm})


    // custom input for date picker
    // @ts-ignore
    const DatePickerCustomInput = forwardRef(({ value, onClick }, ref) => (
        // @ts-ignore
        <button disabled={touring ? true : false} className="button button--primary" onClick={onClick} ref={ref}>
          {value}
        </button>
      ));


    return(
        <div className={`smaller main-container ${touring ? 'overflow-x-hidden margedBot30px' : 'margedTop50px margedBot100px'}`}>

            <div className="allwidth">
                {!touring && <h2 className="page-title">New Touring</h2>}
                <div className="allwidth app-card">
                    <fieldset disabled={readOnly ? true : false}>
                        <FormSection title="For Artist">
                            <div>
                                <SelectInput 
                                    options={artistsList}
                                    title='Selected Artist'
                                    value={formInputs.artistSelected}
                                    onChange={(e:any) => {updateInput(e, 'artistSelected')}}
                                    showRawValue
                                    disabled={touring ? true : false}
                                    searchable
                                />
                                {addArtist &&
                                    <div>
                                        <p className="main-color leftalign margedTop5px margedBot5px">New Artist will be added</p>
                                        <CheckboxSpruha
                                            title="DJ ?"
                                            checked={isDj}
                                            label
                                            onChange={(e: any) => {setIsDj(e.target.checked)}}
                                        />
                                    </div>}
                            </div>

                            <LabelInput 
                                title="Tour Name"
                                value={formInputs.name}
                                onChange={(e: any) => {updateInput(e.target.value, 'name')}}
                            />
                        </FormSection>

                        <FormSection title="Global Data" fullWidth>
                            <div className="grid-4-col alignend">
                                <SelectInput 
                                    options={countriesList}
                                    title='Countries'
                                    value={formInputs.countries}
                                    onChange={(e:any) => {updateInput(e, 'countries')}}
                                    showRawValue
                                    multi
                                />
                                <SelectInput 
                                    options={showDurationsList}
                                    title='Show Duration'
                                    value={formInputs.showDuration}
                                    onChange={(e:any) => {updateInput(e, 'showDuration')}}
                                    showRawValue
                                />
                                <LabelInput 
                                    title="Possible Fee"
                                    value={formInputs.possibleFee}
                                    type="number"
                                    onChange={(e: any) => {updateInput(+e.target.value, 'possibleFee')}}
                                />
                                <CheckboxSpruha
                                    title="PNL Mandatory"
                                    checked={formInputs.isPnlMandatory}
                                    label
                                    onChange={(e: any) => {updateInput(e.target.checked, 'isPnlMandatory')}}
                                />
                            </div>
                        </FormSection>

                        <FormSection title="From / To" fullWidth>
                            <div className="grid-2-col">
                                <div>
                                    <DatePicker
                                        customInput={<DatePickerCustomInput />} 
                                        selected={formInputs.from} 
                                        onChange={(selectedDate:Date) => {
                                            selectedDate.setHours(0,0,0,0)
                                            updateInput(selectedDate, 'from')
                                        }} 
                                    />
                                </div>
                                <div>
                                    <DatePicker 
                                        customInput={<DatePickerCustomInput />} 
                                        selected={formInputs.to} 
                                        onChange={(selectedDate:Date) => {
                                            selectedDate.setHours(0,0,0,0)
                                            updateInput(selectedDate, 'to')}} 
                                        minDate={formInputs.from} 
                                    />
                                </div>
                            </div>
                        </FormSection>

                        {formInputs.shows.map((_, index) => 
                            <div key={index} className={`${formInputs.shows[index].isDayOff ? 'filter-dark' : ''}`}>
                                <FormSection key={index} title={`Day ${index + 1}`} fullWidth>
                                    <div className="grid-4-col alignend">
                                        <Flex className="allwidth">
                                            <p>{moment(new Date(formInputs.shows[index].date)).format('ddd MMMM Do YYYY')}</p>
                                            {formInputs.shows[index].offerId && <p style={{color: 'red'}}>Already has an offer!!</p>}
                                        </Flex>

                                        <CheckboxSpruha 
                                            title='Day Off ?'
                                            checked={formInputs.shows[index].isDayOff}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {updateDay(e.target.checked, 'isDayOff', index)}}
                                            disabled={formInputs.shows[index].offerId ? true : false}
                                        />
                                        <SelectInput 
                                            options={countriesOptionsList}
                                            title='Country'
                                            value={formInputs.shows[index].country}
                                            onChange={(e:any) => {updateDay(e, 'country', index)}}
                                            disabled={formInputs.shows[index].offerId ? true : false}
                                            showRawValue
                                        />
                                        <LabelInput 
                                            title="Possible Fee"
                                            value={formInputs.shows[index].possibleFee}
                                            type="number"
                                            onChange={(e: any) => {updateDay(+e.target.value, 'possibleFee', index)}}
                                            disabled={formInputs.shows[index].offerId ? true : false}
                                        />
                                        <SelectInput 
                                            options={showDurationsList}
                                            title='Show Duration'
                                            value={formInputs.shows[index].showDuration}
                                            onChange={(e: any) => {updateDay(e, 'showDuration', index)}}
                                            showRawValue
                                            disabled={formInputs.shows[index].offerId ? true : false}
                                        />
                                        <SelectInput 
                                            options={promotorsList}
                                            title='Blocked to ?'
                                            value={formInputs.shows[index].blocked}
                                            onChange={(e: any) => {
                                                //handling if we want to deselect the option
                                                let updated = e
                                                if(e === null){updated = {value: '', label: <div></div>}}
                                                updateDay(updated, 'blocked', index)}
                                            }
                                            showRawValue
                                            disabled={formInputs.shows[index].offerId ? true : false}
                                        />
                                    </div>
                                </FormSection>
                            </div>
                        )}
                        
                        <FormSection title="Comments (optional)">
                            <LabelInput 
                                title="Comments"
                                value={formInputs.comments}
                                onChange={(e: any) => {updateInput(e.target.value, 'comments')}}
                            />
                        </FormSection>
                        
                        <Flex row className="spaceevenly margedTop50px">
                            {!readOnly &&
                                <>{touring 
                                    ?   <>
                                            <button className="button button--primary button--round button--ghost" onClick={closeForm}>Go Back</button>
                                            <button className="button button--primary button--round" disabled={isPosting} onClick={handleUpdate}>{isPosting ? 'Updating...' : 'Edit touring'}</button>
                                        </>
                                    :   <>
                                            <button className="button button--primary button--round button--ghost" onClick={goBack}>Go Back</button>
                                            <button className="button button--primary button--round" disabled={isPosting} onClick={handleSubmit}>{isPosting ? 'Adding...' : 'Add touring'}</button>
                                        </>}
                                </>
                            }
                        </Flex>
                    </fieldset>
                    {readOnly && <button className="button button--primary button--round" onClick={closeForm}>Go back</button>}
                </div>
            </div>
        </div>
    )
}

export default TouringForm