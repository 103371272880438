import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Feedback } from '../utils/models/feedbacks.model';

type FeedbacksState = Feedback[] | null

const initialState = null;

export const feedbacksSlice = createSlice({
  name: 'feedbacks',
  initialState: initialState as FeedbacksState,
  reducers: {
    set: (state, action: PayloadAction<Feedback[]>) => {
      return action.payload
    },
    // push: (state, action: PayloadAction<Feedback>) => {
    //     state.push(action.payload)
    // },
    // merge: (state, action: PayloadAction<Feedback>) => {
    //   const {id, ...mergeProps} = action.payload
    //   const feedbackIndex = state.findIndex(feedback => feedback.id === id)
    //   if(feedbackIndex !== -1) {
    //     state[feedbackIndex] = {...state[feedbackIndex], ...mergeProps}
    //   }
    // },
    delete: (state, action: PayloadAction<{id: string}>) => {
      if(!state) { return }
      const feedbackIndex = state.findIndex(feedback => feedback.id === action.payload.id)
      if(feedbackIndex !== -1) {
        state.splice(feedbackIndex, 1)
      }
    }
  },
});

export const feedbacksActions = feedbacksSlice.actions;

export default feedbacksSlice.reducer;