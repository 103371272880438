import { Offer } from "../../../utils/models/offer.model"
import OfferItemRow from "./OfferItemRow"

const OffersTable = ({ offers, ongoing }: { offers: Offer[], ongoing?: boolean }) => {

    const renderOfferRow = (offer: Offer, index: number) => <OfferItemRow offer={offer} ongoing={ongoing} key={index} />

    return (
        <>
            {offers.length 
            ?
                <table className="app-table ">
                    <thead>
                        <tr>
                            <th>Date of creation</th>
                            <th>Limit date</th>
                            <th>Promotor</th>
                            <th>Nbr shows</th>
                            <th>Countries</th>
                            <th>Shows Dates</th>
                            <th>Offer</th>
                            {!ongoing && <th>Current Status</th>}
                            <th>{ongoing ? 'Actions ': 'See More'}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {offers.map(renderOfferRow)} 
                    </tbody>
                </table>

            : <p className="grey">No offers at the moment.</p>}
        </>
    )
}

export default OffersTable