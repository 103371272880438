import { useEffect, useState } from "react";
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai";
import computeBreakEvenPoint from "../../../utils/hooks/offer/computeBreakEvenPoint";
import computePnlData from "../../../utils/hooks/offer/computePnlDatas";
import useFilter from "../../../utils/hooks/useFilter";
import { Offer, OfferDraft, OfferShow } from "../../../utils/models/offer.model";
import { countries } from "../../../utils/models/touring.model";
import OfferPnlItemRow from "./OfferPnlItemRow";

const PnlTable = ({ offers }: { offers: (Offer|OfferDraft)[]}) => {

    const [_shows, set_Shows] = useState<{show: OfferShow, artistName: string}[]>([])

    const [isArtistSortAscending, setIsArtistSortAscending] = useState(true)
    const [isCountrySortAscending, setIsCountrySortAscending] = useState(true)
    const [isShowDatesSortAscending, setIsShowDatesSortAscending] = useState(true)
    const [isBreakEvenPercentSortAscending, setIsBreakEvenPercentSortAscending] = useState(true)
    const [isBreakEvenTicketsSortAscending, setIsBreakEvenTicketsSortAscending] = useState(true)
    const [isTotalTicketsSortAscending, setIsTotalTicketsSortAscending] = useState(true)

    const { filterInput, FilterUI} = useFilter('Search by artist name')

    
    useEffect(() => {
        set_Shows([])
        
        const filteredOffers = offers.filter(offer => offer.artistName.toLowerCase().includes(filterInput.toLowerCase()))

        filteredOffers.forEach(offer => {
            offer.shows.forEach(show => {
                set_Shows(prevShows => [...prevShows, {show, artistName: offer.artistName}])
            })
        })
    }, [offers, filterInput])


    //sorting
    const handleSortByArtist = () => {
        if(isArtistSortAscending) {
            const newSortedShows = _shows.slice()
            newSortedShows.sort((showA, showB) => showB.artistName.localeCompare(showA.artistName))
            set_Shows(newSortedShows)
        } else {
            const newSortedShows = _shows.slice()
            newSortedShows.sort((showA, showB) => showA.artistName.localeCompare(showB.artistName))
            set_Shows(newSortedShows)
        }
        setIsArtistSortAscending(prevValue => !prevValue)
    }

    const handleSortByCountry = () => {
        if(isCountrySortAscending) {
            const newSortedShows = _shows.slice()
            newSortedShows.sort((showA, showB) => {
                const countryA = countries[showA.show.country]
                const countryB = countries[showB.show.country]
                return countryB.localeCompare(countryA)
            })
            set_Shows(newSortedShows)
        } else {
            const newSortedShows = _shows.slice()
            newSortedShows.sort((showA, showB) => {
                const countryA = countries[showA.show.country]
                const countryB = countries[showB.show.country]
                return countryA.localeCompare(countryB)
            })
            set_Shows(newSortedShows)
        }
        setIsCountrySortAscending(prevValue => !prevValue)
    }

    const handleSortByShowDates = () => {
        if(isShowDatesSortAscending) {
            const newSortedShows = _shows.slice()
            newSortedShows.sort((showA, showB) => new Date(showB.show.date).getTime() - new Date(showA.show.date).getTime())
            set_Shows(newSortedShows)
        } else {
            const newSortedShows = _shows.slice()
            newSortedShows.sort((showA, showB) => new Date(showA.show.date).getTime() - new Date(showB.show.date).getTime())
            set_Shows(newSortedShows)
        }
        setIsShowDatesSortAscending(prevValue => !prevValue)
    }

    const handleSortByBreakEvenPercent = () => {
        if(isBreakEvenPercentSortAscending) {
            const newSortedShows = _shows.slice()
            newSortedShows.sort((showA, showB) => {
                const { breakEvenIndex: breakEvenIndexA } = computeBreakEvenPoint({show: showA.show})
                const { breakEvenIndex: breakEvenIndexB } = computeBreakEvenPoint({show: showB.show})
                return breakEvenIndexB - breakEvenIndexA
            })
            set_Shows(newSortedShows)
        } else {
            const newSortedShows = _shows.slice()
            newSortedShows.sort((showA, showB) => {
                const { breakEvenIndex: breakEvenIndexA } = computeBreakEvenPoint({show: showA.show})
                const { breakEvenIndex: breakEvenIndexB } = computeBreakEvenPoint({show: showB.show})
                return breakEvenIndexA - breakEvenIndexB
            })
            set_Shows(newSortedShows)
        }
        setIsBreakEvenPercentSortAscending(prevValue => !prevValue)
    }

    const handleSortByBreakEvenTickets = () => {
        if(isBreakEvenTicketsSortAscending) {
            const newSortedShows = _shows.slice()
            newSortedShows.sort((showA, showB) => {
                const { breakEvenIndex: breakEvenIndexA, ticketsSoldArray: ticketsSoldArrayA } = computeBreakEvenPoint({show: showA.show})
                const { breakEvenIndex: breakEvenIndexB, ticketsSoldArray: ticketsSoldArrayB } = computeBreakEvenPoint({show: showB.show})
                const ticketsSoldBreakEvenA = breakEvenIndexA >= 0 ? ticketsSoldArrayA[breakEvenIndexA] : -1
                const ticketsSoldBreakEvenB = breakEvenIndexB >= 0 ? ticketsSoldArrayB[breakEvenIndexB] : -1
                return ticketsSoldBreakEvenB - ticketsSoldBreakEvenA
            })
            set_Shows(newSortedShows)
        } else {
            const newSortedShows = _shows.slice()
            newSortedShows.sort((showA, showB) => {
                const { breakEvenIndex: breakEvenIndexA, ticketsSoldArray: ticketsSoldArrayA } = computeBreakEvenPoint({show: showA.show})
                const { breakEvenIndex: breakEvenIndexB, ticketsSoldArray: ticketsSoldArrayB } = computeBreakEvenPoint({show: showB.show})
                const ticketsSoldBreakEvenA = breakEvenIndexA >= 0 ? ticketsSoldArrayA[breakEvenIndexA] : -1
                const ticketsSoldBreakEvenB = breakEvenIndexB >= 0 ? ticketsSoldArrayB[breakEvenIndexB] : -1
                return ticketsSoldBreakEvenA - ticketsSoldBreakEvenB
            })
            set_Shows(newSortedShows)
        }
        setIsBreakEvenTicketsSortAscending(prevValue => !prevValue)
    }

    const handleSortByTotalTickets = () => {
        if(isTotalTicketsSortAscending) {
            const newSortedShows = _shows.slice()
            newSortedShows.sort((showA, showB) => {
                const { totalTickets: totalTicketsA } = computePnlData({show: showA.show})
                const { totalTickets: totalTicketsB } = computePnlData({show: showB.show})
                return totalTicketsB.quantity - totalTicketsA.quantity
            })
            set_Shows(newSortedShows)
        } else {
            const newSortedShows = _shows.slice()
            newSortedShows.sort((showA, showB) => {
                const { totalTickets: totalTicketsA } = computePnlData({show: showA.show})
                const { totalTickets: totalTicketsB } = computePnlData({show: showB.show})
                return totalTicketsA.quantity - totalTicketsB.quantity
            })
            set_Shows(newSortedShows)
        }
        setIsTotalTicketsSortAscending(prevValue => !prevValue)
    }

    
    return (
        <>
            <div className="margedBot20px">
                {FilterUI}
            </div>

            {_shows.length 
            ?
                <table className="app-table ">
                    <thead>
                        <tr>
                            <th className="leftalign">
                                <div className="clickable flex-align-center row" onClick={handleSortByArtist}>
                                    <p className="margedRight2px">Artist</p>
                                    {isArtistSortAscending
                                    ?   <AiOutlineCaretUp size={15}/>
                                    :   <AiOutlineCaretDown size={15}/>}
                                </div> 
                            </th>
                            <th className="leftalign">
                            <div className="clickable flex-align-center row" onClick={handleSortByCountry}>
                                    <p className="margedRight2px">Country</p>
                                    {isCountrySortAscending
                                    ?   <AiOutlineCaretUp size={15}/>
                                    :   <AiOutlineCaretDown size={15}/>}
                                </div> 
                            </th>
                            <th className="leftalign">
                                <div className="clickable flex-align-center row" onClick={handleSortByShowDates}>
                                    <p className="margedRight2px">Show Date</p>
                                    {isShowDatesSortAscending
                                    ?   <AiOutlineCaretUp size={15}/>
                                    :   <AiOutlineCaretDown size={15}/>}
                                </div> 
                            </th>
                            <th className="leftalign">Venue</th>
                            <th className="leftalign">Amount</th>
                            <th className="leftalign">
                                <div className="clickable flex-align-center row" onClick={handleSortByBreakEvenPercent}>
                                    <p className="margedRight2px">Break Even %</p>
                                    {isBreakEvenPercentSortAscending
                                    ?   <AiOutlineCaretUp size={15}/>
                                    :   <AiOutlineCaretDown size={15}/>}
                                </div> 
                            </th>
                            <th className="leftalign">
                                <div className="clickable flex-align-center row" onClick={handleSortByBreakEvenTickets}>
                                    <p className="margedRight2px">Break Even Tickets</p>
                                    {isBreakEvenTicketsSortAscending
                                    ?   <AiOutlineCaretUp size={15}/>
                                    :   <AiOutlineCaretDown size={15}/>}
                                </div> 
                            </th>
                            <th className="leftalign">
                                <div className="clickable flex-align-center row" onClick={handleSortByTotalTickets}>
                                    <p className="margedRight2px">Total Tickets</p>
                                    {isTotalTicketsSortAscending
                                    ?   <AiOutlineCaretUp size={15}/>
                                    :   <AiOutlineCaretDown size={15}/>}
                                </div> 
                            </th>
                            <th className="leftalign">Total Tickets Income</th>
                            <th>Open PNL</th>
                        </tr>
                    </thead>
                    <tbody>
                        {_shows.map((show, index) => <OfferPnlItemRow key={index} show={show.show} artistName={show.artistName} />)} 
                    </tbody>
                </table>

            : <p className="grey">No offers at the moment.</p>}
        </>
    )
}

export default PnlTable