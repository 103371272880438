// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.clipboard-button {
	display: flex;
	flex: .9 1;
	flex-direction: column;
	align-items: flex-end;
	text-align: center;
	/* height: 24px; */
	overflow: hidden;
	/* width: fit-content; */
	margin: auto;
	cursor: pointer;

  }
  .clipboard-button.copied {
	border-bottom: 1px solid;
  }
  .clipboard-button span {
	transition: 0.3s all ease-in-out;
  }
  .clipboard-button.copied span {
	transform: translateY(-100%);

  }
  .clipboard-copied {
	color: lightseagreen;
	text-align: center;
	padding: 5px;
	width: 100%
  }

  .clipboard-icon {
	  color: rgb(188, 188, 188);
	  flex: .1 1;
	  width: 100%;
  }
`, "",{"version":3,"sources":["webpack://./src/components/clipboard/clipboard.css"],"names":[],"mappings":";AACA;CACC,aAAa;CACb,UAAQ;CACR,sBAAsB;CACtB,qBAAqB;CACrB,kBAAkB;CAClB,kBAAkB;CAClB,gBAAgB;CAChB,wBAAwB;CACxB,YAAY;CACZ,eAAe;;EAEd;EACA;CACD,wBAAwB;EACvB;EACA;CACD,gCAAgC;EAC/B;EACA;CACD,4BAA4B;;EAE3B;EACA;CACD,oBAAoB;CACpB,kBAAkB;CAClB,YAAY;CACZ;EACC;;EAEA;GACC,yBAAyB;GACzB,UAAQ;GACR,WAAW;EACZ","sourcesContent":["\r\n.clipboard-button {\r\n\tdisplay: flex;\r\n\tflex: .9;\r\n\tflex-direction: column;\r\n\talign-items: flex-end;\r\n\ttext-align: center;\r\n\t/* height: 24px; */\r\n\toverflow: hidden;\r\n\t/* width: fit-content; */\r\n\tmargin: auto;\r\n\tcursor: pointer;\r\n\r\n  }\r\n  .clipboard-button.copied {\r\n\tborder-bottom: 1px solid;\r\n  }\r\n  .clipboard-button span {\r\n\ttransition: 0.3s all ease-in-out;\r\n  }\r\n  .clipboard-button.copied span {\r\n\ttransform: translateY(-100%);\r\n\r\n  }\r\n  .clipboard-copied {\r\n\tcolor: lightseagreen;\r\n\ttext-align: center;\r\n\tpadding: 5px;\r\n\twidth: 100%\r\n  }\r\n\r\n  .clipboard-icon {\r\n\t  color: rgb(188, 188, 188);\r\n\t  flex: .1;\r\n\t  width: 100%;\r\n  }\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
