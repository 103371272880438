import { ChartData } from "chart.js"
import ChartJsLine from "../../../../../../components/Charts/ChartsJsLine"
import { colors } from "../../../../../../constants"

const BreakEvenPointLinesChart = ( { currency, currencySymbol, pourcentagesArray, totalRevenueArray, totalExpenditureArray }: {
    currency: string,
    currencySymbol: null|string,
    pourcentagesArray: number[],
    totalRevenueArray: number[],
    totalExpenditureArray: number[],
}) => {

    const labels = [...pourcentagesArray.map(pourcentage => `${pourcentage}%`)]

    const chartDataSets = [
        {
            label: "Total Income",
            data: totalRevenueArray,
            backgroundColor: colors.green,
            borderColor: colors.green,
        },
        {
            label: "Total Cost",
            data: totalExpenditureArray,
            backgroundColor: colors.red,
            borderColor: colors.red,
        },
    ]

    const chartData: ChartData<'line'> = {
        labels,
        datasets: chartDataSets
    }

    return (
        <div className="app-card allwidth">
            <ChartJsLine 
                data={chartData} 
                title={`Break Even Point (${currencySymbol ?? currency})`}
                // height={500} 
            />
        </div>
    )
}

export default BreakEvenPointLinesChart