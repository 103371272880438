import { MdOutlineAddTask } from "react-icons/md";
import { Flex } from "../../Containers";
import { useDispatch } from "react-redux";
import { snackActions } from "../../../store/snackSlice";
import { useState } from "react";
import { Member } from "../../../utils/models/artists.model";
import { ImCross } from "react-icons/im"
import LabelInput from "../mui/label-input/LabelInput";
import CheckboxSpruha from "../spruha/CheckboxSpruha";
import { colors } from "../../../constants";

const MembersArrayInput = ({ title, value, renderer, onChange}: { title?: string, value: Member[], renderer: Function, onChange: Function}) => {

    const [memberInput, setMemberInput] = useState<Member>({id: '', gid: '', name: '', attr: '', begin: '', end: '', ended: false})

    const handleRemoveElement = (indexToRemove: number) => {
        const updatedArray = value.filter((_, index) => index !== indexToRemove)
        onChange(updatedArray)
    }

    const dispatch = useDispatch()
    const setSnack = (snack:any) => dispatch(snackActions.set(snack))

    return(
        <Flex className="allwidth">
        {title && <label className="selfstart bold">{ title }</label>}

        <Flex className="alignstart allwidth">
        {
            value.map((member:Member, index:number) => <Flex key={`inpmembersArray_${index}`} row>
                {/* removable row */}
                { renderer(member) }
                <ImCross size={10} onClick={(e: React.MouseEvent<SVGElement, MouseEvent>) => handleRemoveElement(index)} color={colors.red} className="array-input__cross-icon clickable"/>
            </Flex>)
        }
        </Flex>

        <Flex row className="allwidth selfstart alignend">
            <div className="allwidth grid-7-col margedRight20px">
                <LabelInput
                    value={memberInput.name}
                    onChange={(e:React.ChangeEvent<HTMLInputElement>) => setMemberInput(prevInput => ({...prevInput, name: e.target.value}))}
                    title='name'
                />
                <LabelInput
                    value={memberInput.id}
                    onChange={(e:React.ChangeEvent<HTMLInputElement>) => setMemberInput(prevInput => ({...prevInput, id: e.target.value}))}
                    title='id'
                />


                <LabelInput
                    value={memberInput.gid}
                    onChange={(e:React.ChangeEvent<HTMLInputElement>) => setMemberInput(prevInput => ({...prevInput, gid: e.target.value}))}
                    title='gid'
                />
                <LabelInput
                    value={memberInput.attr}
                    onChange={(e:React.ChangeEvent<HTMLInputElement>) => setMemberInput(prevInput => ({...prevInput, attr: e.target.value}))}
                    title='attr'
                />

                <LabelInput
                    value={memberInput.begin}
                    onChange={(e:React.ChangeEvent<HTMLInputElement>) => setMemberInput(prevInput => ({...prevInput, begin: e.target.value}))}
                    title='begin'
                />

                <LabelInput
                    value={memberInput.end}
                    onChange={(e:React.ChangeEvent<HTMLInputElement>) => setMemberInput(prevInput => ({...prevInput, end: e.target.value}))}
                    title='end'
                />
                <Flex row className="alignend">
                    <label className="label-color">Ended: </label>
                    <CheckboxSpruha
                        checked={memberInput.ended}
                        onChange={(e:React.ChangeEvent<HTMLInputElement>) => setMemberInput(prevInput => ({...prevInput, ended: e.target.checked}))}
                        title=""
                        label={false}
                    />
                </Flex>    
            </div>
            <MdOutlineAddTask
                size={20} 
                className="clickable main-color" 
                onClick={() => {
                    if (memberInput.name) {
                        const newMember = {name: memberInput.name, id: memberInput.id, gid: memberInput.gid, attr: memberInput.attr, begin: memberInput.begin, end: memberInput.end, ended: memberInput.ended}
                        onChange([...value, newMember]);
                        setMemberInput({id: '', gid: '', name: '', attr: '', begin: '', end: '', ended: false});
                    }
                    else setSnack({ type: 'warning', content: 'You need to enter the member name' })
                }} 
            />
        </Flex>
        
    </Flex>
    )
}

export default MembersArrayInput