import { useAppSelector } from "../../../utils/hooks/reduxTypedHooks"
import PnlTable from "./PnlTable"

const OffersPnls = () => {

    const offers = useAppSelector(state => state.offers)

    return (
        <div className='main-container margedBot100px paddedTop40px'>

            <h3 className="page-title">Offers's PNLs</h3>

            <PnlTable offers={offers} />
            
        </div>
    )
}

export default OffersPnls