import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../utils/models/users.model';

const initialState: User[] = [];

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<User[]>) => {
      return action.payload
    },
    add: (state, action: PayloadAction<User>) => {
      state.push(action.payload);
    },
    merge: (state, action: PayloadAction<User>) => {
      const { email, ...mergeProps } = action.payload
      const userIndex = state.findIndex(user => user.email === email)
      if(userIndex !== -1){
        state[userIndex] = {...state[userIndex], ...mergeProps}
      }
    },
    delete: (state, action: PayloadAction<{id: string}>) => {
      const searchId = action.payload.id
      const foundIndex = state.findIndex(user => user.id === searchId)
      if(foundIndex !== -1){
        state.splice(foundIndex, 1)
      }
    },
  },
});

export const usersActions = usersSlice.actions;

export default usersSlice.reducer;