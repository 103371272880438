import { Flex } from "../../../../components/Containers";
import { colors } from "../../../../constants";
import { ls, plural, stackData } from "../../../../utils/utils";
import moment from "moment";
import { useAppSelector } from "../../../../utils/hooks/reduxTypedHooks";
import { Link } from "react-router-dom";
import { ROUTE_ARTISTS_FEED, ROUTE_CONTRACTS, ROUTE_PROMOTORS, ROUTE_TOURING } from "../../../../routes/routes.const";
import IconifyIcon from "../../../../components/Icon/IconifyIcon";
import ChartJsLine from "../../../../components/Charts/ChartsJsLine";

const ttlAmountFromPayments = (c:any) => c.payments?.map((p:any) => parseInt(p.amount)).reduce((a:any, b:any) => a+b)
const AdminDashboard = () => {
    
    const artists = useAppSelector(state => state.artists)
    const contracts = useAppSelector(state => state.contracts)
    const promotors = useAppSelector(state => state.promotors)
    const tourings = useAppSelector(state => state.tourings)
    

    const totalAmount = (
        contracts?.length
        ? contracts
            .map((c:any) => (
                c.payments?.length
                ? c.payments
                    .map((p:any) => parseInt(p.amount))
                    .reduce((a:any, b:any) => (a + b))
                : 0
            ))
            .reduce((a:any, b:any) => (a + b))
        : 0
    )
    // @ts-ignore
    const signedContracts = contracts.filter(c=>c.signatures?.length === 2);
    const ttlContractsShows = (
        signedContracts?.length 
        // @ts-ignore
        ? signedContracts.map(c => (c.shows.length)).reduce((a, b) => a+b)
        : 0
    )

    const chartData = contracts?.length && {
        labels: [...contracts.map((c:any) => (`${moment(c.date).format('DD/MM/YYYY')}`)), 'To Date'],
        datasets: [
            // {
            //     id: 1,  // datasetIdKey
            //     label: ': Unsigned Amount',
            //     // @ts-ignore
            //     data: stackData(
            //             [
            //                 ...contracts.map(ttlAmountFromPayments),
            //                 0
            //             ]
            //         ),
                    
            //     /// render colors
            //     backgroundColor: 'rgba(0,0,0,.3)',
            //     borderColor: theme[0],
            //     borderWidth: 1,
            //     fill: 'origin'
            // },
            {
                id: 2,  // datasetIdKey
                label: ': Signed Amount',//': Contract Amounts',
                labels: [...contracts.map((c:any) => (`${moment(c.date).format('DD/MM/YYYY')}, ${c.purchaser.name} for ${c.artist.name}`)), 'To Date'],
                // @ts-ignore
                data: stackData([...contracts.map((c:any) => (
                    c.signatures?.length === 2
                    ? ttlAmountFromPayments(c)
                    : 0
                )), 0]),
                backgroundColor: 'rgb(115,103,240)',
                borderColor: 'rgb(115,103,240)',
                borderWidth: 1,
                fill: {
                    target: 'origin',
                    above: '#37395D',   // Area will be colored above the origin
                }
            },
        ],
    }

    const chartCustomPlugins = {tooltip: {
        callbacks: {
          title: (tooltipItem: any) => {
            return (tooltipItem[0].dataset?.labels?.[tooltipItem[0].dataIndex])
          }
        }
      }}

    return (
        <div className="main-container"> 
            <div className="margedTop50px">

                {/* Cards row */}
                <Flex row className="allwidth spacebetween">
                    
                    <Link to={ROUTE_CONTRACTS}>
                        <Flex row className="app-card dashboard-card clickable">
                            <Flex flex={3} className='dashboard-icon-div' style={{backgroundColor: colors.bgMain}}>
                                <IconifyIcon icon="mdi:contract-outline" color={colors.main} fontSize={30} />
                            </Flex>
                            <Flex flex={6} className="allwidth textAlignEnd alignend">
                                <div className="title-color">Total Contracts</div>
                                <div className="xxlarge">{contracts?.length||0}</div>
                                <i className="smaller text-soft">
                                    {signedContracts.length} 
                                    {/* &nbsp;{plural('document', signedContracts.length)}  */}
                                    &nbsp;signed
                                </i>
                                <i className="smaller text-soft">{ttlContractsShows} {plural('date', ttlContractsShows)} booked</i>
                            </Flex>
                        </Flex>
                    </Link>

                    <Flex row className="app-card dashboard-card">
                        <Flex flex={3} className='dashboard-icon-div' style={{backgroundColor: colors.bgGreen}}>
                            <IconifyIcon icon="ph:currency-dollar-simple-duotone" color={colors.green} fontSize={30} />
                        </Flex>
                        <Flex flex={6} className="allwidth textAlignEnd alignend">
                            <div className="title-color">Total Amount</div>
                            <div className="font18" style={{lineHeight: '48px'}}>${ls(totalAmount||0, true)}</div>
                        </Flex>
                    </Flex>

                    <Link to={ROUTE_ARTISTS_FEED}>
                        <Flex row className="app-card dashboard-card clickable">
                            <Flex flex={3} className='dashboard-icon-div' style={{backgroundColor: colors.bgLightblue}}>
                                <IconifyIcon icon="mdi:artist-outline" color={colors.lightblue} fontSize={30} />
                            </Flex>
                            <Flex flex={6} className="allwidth textAlignEnd alignend">
                                <div className="title-color">Total Artists</div>
                                <div className="xxlarge">{artists.length}</div>
                            </Flex>
                        </Flex>
                    </Link>
                    
                    <Link to={ROUTE_PROMOTORS}>
                        <Flex row className="app-card dashboard-card clickable">
                            <Flex flex={3} className='dashboard-icon-div' style={{backgroundColor: colors.bgRed}}>
                                <IconifyIcon icon="fluent:person-key-20-regular" color={colors.red} fontSize={30} />
                            </Flex>
                            <Flex flex={6} className="allwidth textAlignEnd alignend">
                                <div className="title-color">Total Promotors</div>
                                <div className="xxlarge">{promotors.length}</div>
                                <i className="smaller text-soft">{promotors.map(p => p.offers?.length||0).reduce((a, b) => a+b, 0)} offers made</i>
                            </Flex>
                        </Flex>
                    </Link>

                    <Link to={ROUTE_TOURING}>
                        <Flex row className="app-card dashboard-card clickable">
                            <Flex flex={3} className='dashboard-icon-div' style={{backgroundColor: colors.bgOrange}}>
                                <IconifyIcon icon="ic:outline-travel-explore" color={colors.orange} fontSize={30} />
                            </Flex>
                            <Flex flex={6} className="allwidth alignend">
                                <div className="title-color">Total Tours</div>
                                <div className="xxlarge">{tourings.length}</div>
                                <i className="smaller text-soft">{tourings.map(t => (t.shows.filter(ts=>!ts.isDayOff).length)).reduce((a, b) => a+b, 0)} dates listed</i>
                            </Flex>
                        </Flex>
                    </Link>

                </Flex>


                {/* Graph area */}
                {/* <Flex row className="allwidth margedTop75px margedBot50px app-card">
                    <Chart 
                        line
                        chartId='amounts_line_chart'
                        title="Live Show Performances"
                        data={chartData}
                        width='90vw'
                        height='55vh'
                    />
                </Flex> */}
                {chartData !== 0 && <div className="margedTop75px margedBot50px app-card">
                    <ChartJsLine data={chartData} title="Live Show Performances" height={500} customPlugins={chartCustomPlugins} />
                </div>}

              
            </div>
        </div>
    )
}

export default AdminDashboard;