import axios from "axios"
import { axiosGetRequest, rapidAPIForexAPIRequest, rapidAPIInstagramStatisticsAPIRequest } from "../api.utils";

export const fetchSeatgeekEventsWithArtist = async (seatgeekArtistSlug: string) => {
    const options = {
        method: 'GET',
        url: 'https://api.seatgeek.com/2/events',
        params: { 
            'performers.slug': seatgeekArtistSlug,
            client_id: process.env.REACT_APP_SEATGEEK_CLIENT_ID,
            per_page: 99
        }
    };
    
    try {
        const response = await axios.request(options)
        return response
    } catch (error) {
        console.log(error)
        return null
    }
}


export const fetchSeatgeekArtistInfos = async (seatgeekArtistSlug: string) => {
    const options = {
        method: 'GET',
        url: 'https://api.seatgeek.com/2/performers',
        params: { 
            'slug': seatgeekArtistSlug,
            client_id: process.env.REACT_APP_SEATGEEK_CLIENT_ID,
        }
    };
    
    try {
        const response = await axios.request(options)
        return response
    } catch (error) {
        console.log(error)
        return null
    }
}


export const fetchInstagramProfileRapidAPI = async (instagramHandle: string) => {
    const url = `https://www.instagram.com/${instagramHandle}`
    const response = await rapidAPIInstagramStatisticsAPIRequest(url)
    return response
}
export const fetchFacebookProfileRapidAPI = async (facebookName: string) => {
    const url = `https://www.facebook.com/${facebookName}`
    const response = await rapidAPIInstagramStatisticsAPIRequest(url)
    return response
}
export const fetchYoutubeProfileRapidAPI = async (youtubeName: string) => {
    const url = `https://www.youtube.com/${youtubeName}`
    const response = await rapidAPIInstagramStatisticsAPIRequest(url)
    return response
}

export const fetchConversionRateRapidAPI = async (from: string, to: string, amount: number) => {
    const response = await rapidAPIForexAPIRequest(from, to, amount)
    return response
}