import { Flex, Row } from "../../components/Containers"
import { FaRegUserCircle } from 'react-icons/fa';
import { MdLogout } from 'react-icons/md';
import logo from '../../images/logo.png';
import ProfileNotifications from "../common/notifications/ProfileNotifications";
import { ROUTE_PROFILE } from "../../routes/routes.const"
import { Outlet, NavLink } from "react-router-dom";
import { logout } from "../../utils/logout.util";

import styles from '../panels.module.css';
import IconifyIcon from "../../components/Icon/IconifyIcon";

const HomePanel = ({ screens, noProfile, profilePicture }: { screens: Record<string, {title: string, icon: string, badge?: number}>, noProfile?: boolean, profilePicture?: string }) => {
  
    return <Flex flex={10} key={'admin-panel'}>
        
        <Row className='app-header'>
            <img src={logo} alt="logo" className="app-logo" />
            <Flex flex={8}>
                <Row className="spaceevenly">
                    {
                        Object.keys(screens).map(
                            (opt, opti) => 
                            <NavLink 
                                key={`adminopt_${opti}`} 
                                className={({ isActive, isPending }) => (
                                    (isActive || isPending)
                                    ? "relative nav-item nav-item--selected"
                                    : "relative nav-item"
                                )}
                                to={opt}  
                            >
                                <IconifyIcon className="margedRight10px" icon={screens[opt].icon} />
                                <span>{ screens[opt].title }</span>

                                {(screens[opt].badge||0) > 0 
                                &&  <div className="notifications-circle-div">
                                        <div className="notifications-circle clickable"><span>{screens[opt].badge}</span></div>
                                    </div>}
                            </NavLink>
                        )
                    }
                </Row>
            </Flex>
            <Flex flex={2} row className="space-evenly">
                {!noProfile && <Flex flex={2}>
                    <NavLink 
                        to={ROUTE_PROFILE}
                        className={({ isActive, isPending }) =>
                            isActive
                            ? "height30p white selected-header-color"
                            : isPending
                            ? "height30p white selected-header-color"
                            : "height30p white"
                        }
                    >
                        {profilePicture
                        ?   <img className="profile-picture" alt='profile' src={profilePicture} />
                        :   <FaRegUserCircle size={30} className='clickable hoverscale' />}
                    </NavLink>
                </Flex>}
                <Flex flex={2}>
                    <ProfileNotifications notifications={[]/*notifications*/} />
                </Flex>
                <Flex flex={2}>
                    <MdLogout size={30} className='clickable hoverscale' onClick={logout} />
                </Flex>
            </Flex>
        </Row>
    
        <div className={styles.appScreen}>

            <Outlet />
            
        </div>
    </Flex>
}

export default HomePanel;