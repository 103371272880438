import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useAppSelector } from "../../../../../utils/hooks/reduxTypedHooks";
import { fetchApiAuthentified, hostImage, ls, promisify, sanitizeObject } from "../../../../../utils/utils";
import useHandleTokenExpired from "../../../../../utils/hooks/useHandleTokenExpired";
import { AiOutlineUser } from "react-icons/ai";
import { socialIcons } from "../../promotors/PromotorForm";
import { Alias, ArtistURL, Member, streamingPlatformsList, PlatformInfo, Artist, ArtistCreateObject, ArtistContact, ArtistAPIDatas, ArtistMinifiedStreamingDatas } from "../../../../../utils/models/artists.model";
import FormSection from "../../../../../components/form/form-components/FormSection";
import ArrayInput from "../../../../../components/form/form-components/ArrayInput";
import AliasArrayInput from "../../../../../components/form/form-components/AliasArrayInput";
import UrlsArrayInput from "../../../../../components/form/form-components/UrlsArrayInput";
import MembersArrayInput from "../../../../../components/form/form-components/MembersArrayInput";
import LabelInput from "../../../../../components/form/mui/label-input/LabelInput";
import SelectInput from "../../../../../components/form/form-components/SelectInput";
import SocialPlatformInput from "../../../../../components/form/form-components/SocialPlatformInput";
import { snackActions } from "../../../../../store/snackSlice";
import { Flex, Row } from "../../../../../components/Containers";
import { artistsActions } from "../../../../../store/artistsSlice";
import ReactFlagsSelect from "react-flags-select";
import FileInput from "../../../../../components/form/form-components/FileInput";
import CheckboxSpruha from "../../../../../components/form/spruha/CheckboxSpruha";
import ContactsArrayInput from "../../../../../components/form/form-components/ContactsArrayInput";
import { Licence } from "../../../../../utils/models/client.model";
import Tabs from "../../../../../components/tabs-menu/Tabs";
import { fetchArtistFromMarcoDB } from "../../../../../api/dirsal/dirsalRequests";
import { minifyArtistStreamingDatas, standardizeArtistMarcoDB } from "../../../../../utils/artists.util";
import useFetchArtistDatas, { initialFetchedDatas } from "../../../../../utils/hooks/artists/useFetchArtistDatas";


const ArtistForm = ({ artist, readOnly, closeForm, goBack, searchName, manager }: { artist?: Artist, readOnly?: boolean, closeForm? : any, goBack? : any, searchName?:string, manager?: boolean }) => {
    const users = useAppSelector(state => state.users);
    const artistUser = artist ? users.find(user => user.id === artist.clientId) : null

    const emptyStreamingDatas = {
        spotify:undefined,
        twitter: undefined,
        deezer: undefined,
        lastfm: undefined,
        seatgeek: undefined,
        bandsintown: undefined,
        genius: undefined,
        tiktok: undefined,
        instagram: undefined,
        facebook: undefined,
        youtube: undefined,
        events: undefined,
        pastEvents: undefined,
        totalFollowers: undefined,
    }

    const areStreamingDatasDefined = artist?.streamingDatas && (Object.keys(artist.streamingDatas).length !== 0)

    const [fetchName, setFetchName] = useState(searchName ?? '')
    const [selectedImageFile, setSelectedImageFile] = useState('')
    const [selectedLogoFile, setSelectedLogoFile] = useState('')
    const [selectedBannerFile, setSelectedBannerFile] = useState('')
    const [formInputs, setFormInputs] = useState({
        displayableName: artist?.displayableName || '',
        type: artist?.type || '',
        assets: artist?.assets ?? {technique: [], rider: [], material: [], travelParty: [], other: []},
        areGeneralAssetsVisible: artist?.areGeneralAssetsVisible ?? true,
        preferences: artist?.preferences ?? '',
        comments: artist?.comments ?? '',
        possibleFee: artist?.possibleFee ?? undefined,
        contacts: [],
        active: artist?.active || true,
        activeFrom: artist?.activeFrom || '',
        areaName: artist?.areaName || '',
        country: artist?.country || '',
        aliases: artist?.aliases?.length ? artist.aliases : [] as Alias[],
        genres: artist?.genres?.length ? artist.genres : [] as string[],
        gender: artist?.gender || '',
        urls: artist?.urls?.length ? artist.urls : [] as ArtistURL[],
        socials: artist?.socials || {} as Record<string, PlatformInfo>,
        streamingPlatforms: artist?.streamingPlatforms || {} as Record<string, PlatformInfo>,
        updated: artist?.updated || 0,
        gid: artist?.gid || '',
        ipis: artist?.ipis.length ? artist.ipis : [] as string[],
        isnis: artist?.isnis.length ? artist.isnis : [] as string[],
        members: artist?.members?.length ? artist.members : [] as Member[],
        bio: artist?.bio || '',
        logo: artist?.logo || '',
        image: artist?.image || '',
        banner: artist?.banner || '',
        website: artist?.website || '',
        userSelected: artist?.clientId ? { value: artist.clientId, label: <div className="flex row justifystart"><AiOutlineUser size={20} />&nbsp;<b>{artistUser?.email}</b></div> } : {
            value: '',
            label: ''
        },
        isDj: artist?.isDj || false,
        streamingDatas: areStreamingDatasDefined ? artist.streamingDatas : emptyStreamingDatas
    })

    const [isFetching, setIsFetching] = useState(false)
    const [isFetchingStreamingDatas, setIsFetchingStreamingDatas] = useState(false)
    const [isPosting, setIsPosting] = useState(false)

    const { fetchStreamingDatas, fetchedDatas } = useFetchArtistDatas(artist)

    const dispatch = useDispatch()
    const setSnack = (snack:any) => dispatch(snackActions.set(snack))

    // When adding an artist from a request with searchName prop
    useEffect(() => {
        if(searchName){
            handleGetInfos()
        }
    }, [searchName])

    const updateStreamingData = (newDatas: ArtistMinifiedStreamingDatas) => {
        setFormInputs(prevInputs => ({...prevInputs, streamingDatas: newDatas}))
    }

    useEffect(() => {
        if(fetchedDatas !== initialFetchedDatas) {
            const minifiedDatas = minifyArtistStreamingDatas(fetchedDatas)
            updateStreamingData(minifiedDatas)
        }
    }, [fetchedDatas])

    console.log("form inputs", formInputs)
    console.log("artist", artist)


    const artistUsers = users.filter(user => user.licence === Licence.Artist)
    const usersOptions = artistUsers.map(user => ({ value: user.id, label: <div className="flex row justifystart"><AiOutlineUser size={20} />&nbsp;<b>{user.email}</b></div> }))

    const clearInputs = () => {
        setFormInputs({
            displayableName:  '',
            type: '',
            assets: {technique: [], rider: [], material: [], travelParty: [], other: []},
            areGeneralAssetsVisible: true,
            preferences: '',
            comments: '',
            possibleFee: undefined,
            contacts: [],
            active: true,
            activeFrom: '',
            areaName: '',
            country: '',
            aliases: [],
            genres: [],
            gender: '',
            urls: [],
            socials: {},
            streamingPlatforms: {},
            updated: 0,
            gid: '',
            ipis: [],
            isnis: [],
            members: [] ,
            bio: '',
            logo: '',
            image: '',
            banner: '',
            website: '',
            userSelected: {
                value: '',
                label: ''
                },
            isDj: false,
            streamingDatas: emptyStreamingDatas
            })
    }

    const updateInput = (value: any, inputName: string) => {
        setFormInputs(prevInputs => ({...prevInputs, [inputName]: value}))
    }
    
    const selectUser = (value: any) => {
        setFormInputs(prevInputs => ({...prevInputs, userSelected: value}))
    }
    
    const client = useSelector((state: any) => state.client);
    const handleTokenExpired = useHandleTokenExpired()

    const fetchApiAuth = fetchApiAuthentified(
        client.token, 
        handleTokenExpired
    );

    const handleFetchStreamingDatas = async () => {
        setIsFetchingStreamingDatas(true)
        await fetchStreamingDatas()
        console.log("fetched", fetchedDatas)
        setSnack({type: "success", content: "Streaming Datas fetching completed."})
        setIsFetchingStreamingDatas(false)
    }

    const handleHostImage = async (selectedFile: string, inputName: "image" | "logo" | "banner", setSelectedFile: (value: React.SetStateAction<string>) => void) => {
        return await new Promise(async(_img) => {
            const isLocalImg = await promisify(selectedFile && formInputs[inputName].slice(0, 10).includes('data:image'));
            
            if(isLocalImg) {
                // read to get file
                const host:any = await hostImage(selectedFile, client.token);
                _img(host.response);
                setSelectedFile('')
            }
            
            else _img(formInputs[inputName])
        })
    }

    // Handle post to add route
    const handleSubmit = async() => {
        setIsPosting(true)
        // form validation
        if(!formInputs.displayableName){
            setSnack({type: 'error', content: 'Please fill the "Displayable Name" field'})
            setIsPosting(false)
            return 
        }

        // await host images
        const image = await handleHostImage(selectedImageFile, "image", setSelectedImageFile) as string
        const logo = await handleHostImage(selectedLogoFile, "logo", setSelectedLogoFile) as string
        const banner = await handleHostImage(selectedBannerFile, "banner", setSelectedBannerFile) as string

        const initialData = { promotors: [], contracts: [] }
        const newArtist: ArtistCreateObject = {
            ...formInputs, 
            clientId: formInputs.userSelected.value, 
            ...initialData, 
            image, 
            logo, 
            banner,
            isArchived: false
        }
        
        // await store new artist
        const { error, response: createdArtist } = await fetchApiAuth({
            method: 'POST',
            route: 'create-artist', //todo other route for manager
            body: newArtist
        });

        // snack
        setSnack(
            error
            ? {
                type: 'error', 
                content: error.toString()
            }
            : {
                type: 'success',
                content: `${formInputs.displayableName} has been successfully added as Primuse artists #${createdArtist?.id}`
            }
        )
        
        if(!error){
            // clear form
            clearInputs();
            // update global artists
            dispatch(artistsActions.add(createdArtist))
        }

        setIsPosting(false)
    }

    // Handle post to update route
    const handleUpdate = async () => {
        setIsPosting(true)
        if(!formInputs.displayableName){
            setSnack({type: 'error', content: 'The "Displayable Name" field is required'})
            setIsPosting(false)
            return 
        }
        if(!artist?.id){
            setSnack({type: 'error', content: 'Artist not found'})
            setIsPosting(false)
            return 
        }

        // hosting images
        const image = await handleHostImage(selectedImageFile, "image", setSelectedImageFile)
        const logo = await handleHostImage(selectedLogoFile, "logo", setSelectedLogoFile)
        const banner = await handleHostImage(selectedBannerFile, "banner", setSelectedBannerFile)

        const updateBody = {id: artist.id, ...formInputs, clientId: formInputs.userSelected.value, image, logo, banner}
        // await update new artist
        const { error, response: updatedArtist } = await fetchApiAuth({
            method: 'POST',
            route: 'merge-artist',
            body: updateBody
        });

        // snack
        setSnack(
            error
            ? {
                type: 'error', 
                content: error.toString()
            }
            : {
                type: 'success',
                content: `${formInputs.displayableName} has been successfully updated`
            }
        )

        if(!error){
            // update global artists
            dispatch(artistsActions.merge(updatedArtist))
        }
        
        setIsPosting(false)
        if(!error){
            closeForm()
        }
    }

    const handleGetInfos = async () => {
        setIsFetching(true)
        const result = await fetchArtistFromMarcoDB(fetchName)
        console.log(fetchName, result)
        const foundArtist = result?.data?.response?.[0]

        if(!foundArtist){
            // const notFound = error.toString().includes('NO ARTIST FOUND');
            setSnack({ type: 'error', content:'Artist Not Found' })
            setIsFetching(false)
            return
        } 

        const inputsKeys = [...Object.keys(formInputs), 'sortName']
        // we don't change the displayableName in case we are editing the artist and not adding a new one
        if(artist){
            //@ts-ignore
            delete inputsKeys['displayableName']
        }
        const standardizedArtist = standardizeArtistMarcoDB(foundArtist)
        const sanitizedResponse = sanitizeObject(standardizedArtist, inputsKeys)
        const serializedUpdated = sanitizedResponse?.updated ? new Date(sanitizedResponse.updated).getTime() : 0
        const serializedResponse = {...sanitizedResponse, updated: serializedUpdated}
        
        clearInputs()

        setFormInputs(prevInputs => ({...prevInputs, ...serializedResponse}))
        setSnack({ type: 'success ', content: 'Artist fetched successfully' })
        
        setIsFetching(false)
    }

    return <div className={`smaller main-container ${artist ? "overflow-x-hidden margedBot10px" : "margedTop50px margedBot100px"}`} >
        
        {! artist && <h2 className="page-title">New Primuse Artist</h2>}
        <div className="allwidth app-card">
            <fieldset disabled={readOnly ? true : false}>

                {!readOnly 
                && <FormSection title="Fetching from database" className="allwidth alignstart margedBot50px">
                        <div className="allwidth grid-2-col">
                            <LabelInput 
                                title='Name to fetch (lowercase)'
                                value={fetchName}
                                onChange={(e: any) => {setFetchName(e.target.value)}}
                            />
                            <Flex className="allwidth">
                                <button disabled={isFetching} className="button button--primary button--round" onClick={handleGetInfos}>{isFetching ? 'Fetching...' : 'Get Infos'}</button>
                            </Flex>
                        </div>
                        <Row className="justify-end">
                            {artist 
                                ?   <button className="button button--round button--uppercase button--primary margedRight20px" disabled={isPosting} onClick={handleUpdate}>{isPosting ? 'Updating...' : 'Update Artist'}</button>
                                :   <button className="button button--round button--uppercase button--primary margedRight20px" disabled={isPosting} onClick={handleSubmit}>{isPosting ? 'Adding...' : 'Add new Artist'}</button>}
                            
                            {closeForm
                            ?   <button className="button button--round button--uppercase button--ghost" onClick={closeForm}>Go back</button>
                            :   <button className="button button--primary button--round button--ghost" onClick={goBack}>Go Back</button>}
                        </Row>
                    </FormSection>}


                {/* Primuse Infos Tab */}
                <Tabs activeTab='Primuse infos' noContainer>
                    <div option-data='Primuse infos'>
                        <FormSection title="Internal Infos" fullWidth className="allwidth alignstart margedBot20px margedTop20px">
                            <div className='grid-2-col alignend'>
                                <ContactsArrayInput
                                    title="Contacts"
                                    value={formInputs.contacts}
                                    onChange={readOnly ? () => {} : (ContactsArray: string[]) => updateInput(ContactsArray, 'contacts')}
                                    renderer={(contact: ArtistContact) => {
                                        return <div className="fw400 leftalign"><span className="margedRight30px">agency : <b>{contact.agency}</b></span><span className="margedRight30px">label : <b>{contact.label}</b></span> management : <b>{contact.management}</b></div>
                                    }}
                                />
                                
                                <div className="grid-3-col alignend">
                                    <LabelInput 
                                        title='Preferences'
                                        value={formInputs.preferences}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {updateInput(e.target.value, 'preferences')}}
                                    />
                                    <LabelInput 
                                        title='Comments'
                                        value={formInputs.comments}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {updateInput(e.target.value, 'comments')}}
                                    />
                                    <LabelInput 
                                        title='Possible Fee'
                                        value={formInputs.possibleFee}
                                        type="number"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {updateInput(+e.target.value, 'possibleFee')}}
                                    />
                                </div>

                                <CheckboxSpruha 
                                    title='DJ'
                                    checked={formInputs.isDj}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {updateInput(e.target.checked, 'isDj')}}
                                />
                            </div>
                        </FormSection>
                        
                        <FormSection title="Primuse Access" fullWidth>
                            <SelectInput 
                                options={usersOptions}
                                title='Existing User (with Artist Licence)'
                                value={formInputs.userSelected}
                                onChange={(e:any) => {selectUser(e)}}
                            />
                        </FormSection>

                    </div>

                    {/* IDs / URLs Tab */}
                    <div option-data='IDs / URLs'>

                        <FormSection title="Socials" fullWidth className="allwidth alignstart margedBot20px margedTop20px">
                            <SocialPlatformInput 
                                options={Object.keys(socialIcons).map((sk:any) => ({ value: sk, label: <div className="flex row justifystart">{socialIcons[sk as keyof {}]}&nbsp;<b className="capitalize">{sk}</b></div> }))}
                                value={formInputs.socials}
                                onChange={readOnly ? () => {} : (_socials:any) => updateInput(_socials, 'socials')}
                                renderer={(_sm:any) => {
                                    const sm = _sm as keyof {};
                                    return <div className="fw400 leftalign">{socialIcons[sm]} {_sm} <span style={{margin: '0 30px'}}>url : <b>{ formInputs.socials[sm].url }</b></span> id : <b>{ formInputs.socials[sm].id }</b></div>
                                }}
                            />
                        </FormSection>

                        <FormSection title="Streaming Platforms" fullWidth>
                            <SocialPlatformInput 
                                options={streamingPlatformsList.map((platform) => ({ value: platform, label: <div className="flex row justifystart"><b className="capitalize">{platform}</b></div> }))}
                                value={formInputs.streamingPlatforms}
                                onChange={readOnly ? () => {} : (_streamingPlatform:any) => updateInput(_streamingPlatform, 'streamingPlatforms')}
                                renderer={(_sm:any) => {
                                    const sm = _sm as keyof {};
                                    return <div className="fw400 leftalign">{socialIcons[sm]} <b>{_sm}</b> <span style={{margin: '0 30px'}}>url : <b>{ formInputs.streamingPlatforms[sm].url }</b></span> id : <b>{ formInputs.streamingPlatforms[sm].id }</b></div>
                                }}
                            />
                        </FormSection>

                        <FormSection title="Database Infos" fullWidth>
                            <div className='allwidth grid-4-col alignend'>
                                <ArrayInput 
                                    title="Isnis"
                                    placeholder="Isni"
                                    value={formInputs.isnis}
                                    onChange={readOnly ? () => {} : (isnisArray: string[]) => updateInput(isnisArray, 'isnis')}
                                    renderer={(element: string) => {
                                        return <div className="leftalign">{ element }</div>
                                    }}
                                />
                                <ArrayInput 
                                    title="Ipis"
                                    placeholder="Ipi"
                                    value={formInputs.ipis}
                                    onChange={readOnly ? () => {} : (ipisArray: string[]) => updateInput(ipisArray, 'ipis')}
                                    renderer={(element: string) => {
                                        return <div className="leftalign">{ element }</div>
                                    }}
                                />
                                <LabelInput 
                                    title='Last updated'
                                    value={formInputs.updated}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {updateInput(+e.target.value, 'updated')}}
                                    type='number'
                                />
                                <LabelInput 
                                    title='Gid'
                                    value={formInputs.gid}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {updateInput(e.target.value, 'gid')}}
                                />
                            </div>
                        </FormSection>

                        <FormSection title="Urls / Aliases">
                            <UrlsArrayInput
                                title="Urls"
                                value={formInputs.urls}
                                onChange={readOnly ? () => {} : (aliassesArray: string[]) => updateInput(aliassesArray, 'urls')}
                                renderer={(url: ArtistURL) => {
                                    return <div className="fw400 leftalign"><span className="margedRight30px">type : <b>{url.type}</b></span> href : <b>{url.href}</b></div>
                                }}
                            />
                            <AliasArrayInput
                                title="Aliases"
                                value={formInputs.aliases}
                                onChange={readOnly ? () => {} : (aliassesArray: string[]) => updateInput(aliassesArray, 'aliases')}
                                renderer={(alias: Alias) => {
                                    return <div className="fw400 leftalign"><span className="margedRight30px">name : <b>{alias.name}</b></span> locale : <b>{alias.locale}</b></div>
                                }}
                            />
                        </FormSection>

                    </div>

                    {/* Artist Infos Tab */}
                    <div option-data='Artist Infos'>
                    <FormSection title="Display" fullWidth className="allwidth alignstart margedBot20px margedTop20px">
                            <div className='allwidth grid-4-col alignend'>
                                <LabelInput 
                                    title='Displayable Name (Required)'
                                    disabled={artist ? true : false}
                                    value={formInputs.displayableName}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {updateInput(e.target.value, 'displayableName')}}
                                />
                                <FileInput
                                    title='Image'
                                    value={formInputs.image}
                                    onChange={({acceptedFile, imageDatas}:any) => {
                                        setSelectedImageFile(acceptedFile)
                                        updateInput(imageDatas.source, 'image');
                                    }}
                                />
                                <FileInput
                                    title='Logo'
                                    value={formInputs.logo}
                                    onChange={({acceptedFile, imageDatas}:any) => {
                                        setSelectedLogoFile(acceptedFile)
                                        updateInput(imageDatas.source, 'logo');
                                    }}
                                />
                                <FileInput
                                    title='Banner'
                                    value={formInputs.banner}
                                    onChange={({acceptedFile, imageDatas}:any) => {
                                        setSelectedBannerFile(acceptedFile)
                                        updateInput(imageDatas.source, 'banner');
                                    }}
                                />
                            </div>
                        </FormSection>

                        <FormSection title="Biography" fullWidth>
                            <LabelInput 
                                title='Biography'
                                value={formInputs.bio}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {updateInput(e.target.value, 'bio')}}
                                textarea
                            />
                        </FormSection>

                        <FormSection title="Informations" fullWidth>
                            <div className='allwidth grid-4-col alignend'>
                                <Flex className="allwidth">
                                    <label className="selfstart bold label-color">Country</label>
                                    <ReactFlagsSelect
                                        searchable
                                        selected={formInputs.country}
                                        onSelect={(flag: any) => {updateInput(flag, 'country')}}                                                    
                                        className="allwidth"
                                        selectButtonClassName="flags-selection-button"
                                    />
                                </Flex>
                                <LabelInput 
                                    title='Area name'
                                    value={formInputs.areaName}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {updateInput(e.target.value, 'areaName')}}
                                />
                                <LabelInput 
                                    title='Artist Type'
                                    value={formInputs.type}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {updateInput(e.target.value, 'type')}}
                                />
                                <ArrayInput 
                                    value={formInputs.genres}
                                    title='Genres'
                                    placeholder="Genre"
                                    onChange={readOnly ? () => {} : (genresArray: string[]) => updateInput(genresArray, 'genres')}
                                    renderer={(genre: string) => {
                                        return <div className="leftalign">{ genre }</div>
                                    }}
                                />
                                <CheckboxSpruha 
                                    title='Active'
                                    checked={formInputs.active}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {updateInput(e.target.checked, 'active')}}
                                />
                                <LabelInput 
                                    title='Active from year'
                                    value={formInputs.activeFrom}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {updateInput(e.target.value, 'activeFrom')}}
                                />
                                <LabelInput 
                                    title='Gender'
                                    value={formInputs.gender}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {updateInput(e.target.value, 'gender')}}
                                />
                                <LabelInput 
                                    title='Website'
                                    value={formInputs.website}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {updateInput(e.target.value, 'website')}}
                                />
                            </div>
                        </FormSection>

                        <FormSection title="Members" fullWidth>
                            <MembersArrayInput 
                                value={formInputs.members }
                                onChange={readOnly ? () => {} : (aliassesArray: string[]) => updateInput(aliassesArray, 'members')}
                                renderer={(member: Member) => {
                                    return <div className="fw400 leftalign">
                                                <span style={{marginRight: '20px'}}>name : <b>{member.name}</b></span> 
                                                <span style={{marginRight: '20px'}}>id : <b>{member.id}</b></span> 
                                                <span style={{marginRight: '20px'}}>gid : <b>{member.gid}</b></span> 
                                                <span style={{marginRight: '20px'}}>attr : <b>{member.attr}</b></span> 
                                                <span style={{marginRight: '20px'}}>begin : <b>{member.begin}</b></span> 
                                                <span style={{marginRight: '20px'}}>end : <b>{member.end}</b></span> 
                                                ended : <b>{member.ended === true ? 'Yes' : member.ended === false ? 'No' : member.ended }</b> 
                                            </div>
                                }}
                            />
                        </FormSection>

                    </div>

                    {/* Streaming Datas Tab */}
                    <div option-data='Streaming Datas'>

                        <div className="margedTop30px">
                            <button disabled={isFetchingStreamingDatas} className="button button--primary" onClick={handleFetchStreamingDatas}>
                                {isFetchingStreamingDatas ? "Fetching..." : "Fetch Streaming Datas"}
                            </button>
                        </div>

                        <FormSection title="Instagram" fullWidth>
                            <div className='allwidth grid-2-col alignend'>
                                <LabelInput 
                                    title='Followers'
                                    value={ls(formInputs.streamingDatas.instagram?.followers) ?? ''}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {}}
                                />
                                <LabelInput 
                                    title='Url'
                                    value={formInputs.streamingDatas.instagram?.url ?? ''}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {}}
                                />
                            </div>
                        </FormSection>

                        <FormSection title="Spotify" fullWidth>
                            <div className='allwidth grid-2-col alignend'>
                                <LabelInput 
                                    title='Followers'
                                    value={ls(formInputs.streamingDatas.spotify?.artist?.followers?.total) ?? ''}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {}}
                                />
                                <LabelInput 
                                    title='Url'
                                    value={formInputs.streamingDatas.spotify?.artist?.external_urls?.spotify ?? ''}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {}}
                                />
                            </div>
                        </FormSection>

                        <FormSection title="Bands In Town" fullWidth>
                            <div className='allwidth grid-2-col alignend'>
                                <LabelInput 
                                    title='Followers'
                                    value={ls(formInputs.streamingDatas.bandsintown?.followers) ?? ''}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {}}
                                />
                                <LabelInput 
                                    title='Url'
                                    value={formInputs.streamingDatas.bandsintown?.url ?? ''}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {}}
                                />
                            </div>
                        </FormSection>

                        <FormSection title="Deezer" fullWidth>
                            <div className='allwidth grid-2-col alignend'>
                                <LabelInput 
                                    title='Followers'
                                    value={ls(formInputs.streamingDatas.deezer?.followers) ?? ''}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {}}
                                />
                                <LabelInput 
                                    title='Url'
                                    value={formInputs.streamingDatas.deezer?.url ?? ''}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {}}
                                />
                            </div>
                        </FormSection>

                        <FormSection title="Facebook" fullWidth>
                            <div className='allwidth grid-2-col alignend'>
                                <LabelInput 
                                    title='Followers'
                                    value={ls(formInputs.streamingDatas.facebook?.followers) ?? ''}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {}}
                                />
                                <LabelInput 
                                    title='Url'
                                    value={formInputs.streamingDatas.facebook?.url ?? ''}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {}}
                                />
                            </div>
                        </FormSection>

                        <FormSection title="Genius" fullWidth>
                            <div className='allwidth grid-2-col alignend'>
                                <LabelInput 
                                    title='Followers'
                                    value={ls(formInputs.streamingDatas.genius?.followers) ?? ''}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {}}
                                />
                                <LabelInput 
                                    title='Url'
                                    value={formInputs.streamingDatas.genius?.url ?? ''}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {}}
                                />
                            </div>
                        </FormSection>

                        <FormSection title="Last FM" fullWidth>
                            <div className='allwidth grid-4-col alignend'>
                                <LabelInput 
                                    title='Listeners'
                                    value={ls(formInputs.streamingDatas.lastfm?.listeners) ?? ''}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {}}
                                />
                                <LabelInput 
                                    title='Playcount'
                                    value={ls(formInputs.streamingDatas.lastfm?.playcount) ?? ''}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {}}
                                />
                                <LabelInput 
                                    title='Url'
                                    value={formInputs.streamingDatas.lastfm?.url ?? ''}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {}}
                                />
                            </div>
                        </FormSection>

                        <FormSection title="TikTok" fullWidth>
                            <div className='allwidth grid-2-col alignend'>
                                <LabelInput 
                                    title='Followers'
                                    value={ls(formInputs.streamingDatas.tiktok?.followers) ?? ''}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {}}
                                />
                                <LabelInput 
                                    title='Name'
                                    value={formInputs.streamingDatas.tiktok?.name ?? ''}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {}}
                                />
                            </div>
                        </FormSection>

                        <FormSection title="Total Followers" fullWidth>
                            <div className='allwidth grid-2-col alignend'>
                                <LabelInput 
                                    title=''
                                    value={ls(formInputs.streamingDatas.totalFollowers) ?? ''}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {}}
                                />
                            </div>
                        </FormSection>
                    </div>

                    
                </Tabs>

                <Flex row className="spaceevenly margedTop50px">
                    {
                        !readOnly &&
                        <>
                            <button className="button button--danger button--uppercase button--round" onClick={clearInputs}>Clear</button>
                            {closeForm
                            ?   <button className="button button--round button--uppercase button--ghost" onClick={closeForm}>Go back</button>
                            :   <button className="button button--primary button--round button--ghost" onClick={goBack}>Go Back</button>}
                            {artist 
                                ?   <button className="button button--round button--uppercase button--primary" disabled={isPosting} onClick={handleUpdate}>{isPosting ? 'Updating...' : 'Update Artist'}</button>
                                :   <button className="button button--round button--uppercase button--primary" disabled={isPosting} onClick={handleSubmit}>{isPosting ? 'Adding...' : 'Add new Artist'}</button>}
                        </>
                    }
                </Flex>
            </fieldset>
            {readOnly && <button className="button button--round button--uppercase button--primary" onClick={closeForm}>Go back</button>}
        </div>
    </div>
}

export default ArtistForm;