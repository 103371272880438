// import { useState } from "react"
import { TfiLocationPin } from "react-icons/tfi"
import { AiOutlineCalendar } from "react-icons/ai"
import { FiClock } from "react-icons/fi"
import { AiOutlineFolder } from "react-icons/ai"
import { BiMoney } from "react-icons/bi"
import { BiCommentDetail } from "react-icons/bi"
import { Touring } from "../../../utils/models/touring.model"
import { Link } from "react-router-dom"
import { ROUTE_ARTISTS } from "../../../routes/routes.const"
import moment from 'moment'
import { countries } from "../../../utils/models/touring.model"
import { ls } from "../../../utils/utils"

import styles from "./touring.module.css"
import { useAppSelector } from "../../../utils/hooks/reduxTypedHooks"
import { Licence } from "../../../utils/models/client.model"
import { Artist, ArtistFromDB, NormalizedArtistMarcoDB } from "../../../utils/models/artists.model"
import { formatDisplayableNameToUrl } from "../../../utils/artists.util"

const ListItem = ({touring, artist}: {touring: Touring, artist: Artist|ArtistFromDB|NormalizedArtistMarcoDB}) => {
  
    const artistBookingUrl = `${ROUTE_ARTISTS}/${formatDisplayableNameToUrl(artist.displayableName)}/tours#booking`

    const licence = useAppSelector(state => state.client?.licence)
    const isAdmin = licence === Licence.Admin

    return (
        <Link to={artistBookingUrl}><div className="touring__list-item clickable hover-medium-scale-shadow">
            <div className={styles.listItemGrid}>
                <div className={styles.listImageDiv}>
                    <img src={`${artist.image}`} className={styles.listImage} alt="artist logo" />
                </div>
                <div className="flex-align-center space-between">
                    <div className={styles.itemTextDiv}>
                        <h3 className={styles.itemArtistTitle}>{artist.displayableName} - {touring.name}</h3>
                        <div className={styles.itemInfosContainer}>
                                <div className={styles.itemLeftDiv}>
                                    <div className="touring_item__infos-block">
                                        <TfiLocationPin className="touring__icons" color='var(--touring-main-color)' /> 
                                        <p>{touring.countries.map(country => countries[country]).join(' - ')}</p>
                                    </div>
                                    <div className="touring_item__infos-block">
                                        <FiClock className="touring__icons" color='var(--touring-main-color)' /> 
                                        <p>Set Duration : {touring.showDuration} Minutes</p>
                                    </div>
                                    {(isAdmin && touring.comments) && <div className="touring_item__infos-block">
                                        <BiCommentDetail className="touring__icons" color='var(--touring-main-color)' /> 
                                        <p>Comments : {touring.comments}</p>
                                    </div>}
                                </div>
                                <div className="touring-item__right-div">
                                    <div className="touring_item__infos-block">
                                        <AiOutlineCalendar className="touring__icons" color='var(--touring-main-color)' /> 
                                        <p>From : {moment(new Date(touring.from)).format('MMMM Do YYYY')}</p>
                                    </div>
                                    <div className="touring_item__infos-block">
                                        <AiOutlineCalendar className="touring__icons" color='var(--touring-main-color)' /> 
                                        <p>Until : {moment(new Date(touring.to)).format('MMMM Do YYYY')}</p>
                                    </div>
                                    {isAdmin && <div className="touring_item__infos-block">
                                        <BiMoney className="touring__icons" color='var(--touring-main-color)' /> 
                                        <p>Possible fee : {ls(touring.possibleFee)}</p>
                                    </div>}
                                </div>
                        </div>
                    </div>
                    <div className="touring-item__CTA-div">
                        <button className="button touring__button button--round">Book Artist</button>
                    </div>
                </div>
            </div>
        </div></Link>
    )
}

export default ListItem