import TiktokAPI from "./TiktokAPI"

const AdminAPIs = () => {

    return (
        <div>
            <div className="margedBot20px margedTop20px">
                <TiktokAPI />
            </div>

        </div>
    )
}

export default AdminAPIs