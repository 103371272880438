import { Flex } from "../Containers"
import LoadingOval from "./LoadingOval"

const PageLoading = () => {

    return (
        <Flex className='allwidth margedTop50px' style={{minHeight: '80vh'}}>
            <LoadingOval />
        </Flex>
    )
}

export default PageLoading