import { countries, TouringWithArtist } from "../../../../utils/models/touring.model"
import moment from 'moment'
import { useState } from "react"
import Dialog from "../../../../components/dialog/Dialog"
import TouringForm from "./TouringForm"
import { createPortal } from "react-dom"

import ReactCountryFlag from "react-country-flag"
import { Row } from "../../../../components/Containers"
import useFetchApiAuth from "../../../../utils/hooks/useFetchApiAuth"
import useSetSnack from "../../../../utils/hooks/useSetSnack"
import { useAppDispatch } from "../../../../utils/hooks/reduxTypedHooks"
import { touringsActions } from "../../../../store/touringsSlice"
import { MdClose } from "react-icons/md"

const TourItemRow = ({ tour }: { tour: TouringWithArtist }) => {

    const [formVisible, setFormVisible] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)

    const fetchApiAuth = useFetchApiAuth()
    const setSnack = useSetSnack()
    const dispatch = useAppDispatch()
    
    const openForm = () => setFormVisible(true)
    const closeForm = () => setFormVisible(false)

    const openDeleteModal = () => setDeleteModal(true)
    const closeDeleteModal = () => setDeleteModal(false)

    const rootModalDiv = document.getElementById('modal-root')!
    
    const handleClickEdit = () => {
        openForm()
    }

    const handleDelete = async () => {
        setIsDeleting(true)

        const { error, response } = await fetchApiAuth({
            method: 'POST',
            route: 'delete-touring',
            body: { id: tour.id }
        });

        // snack
        setSnack(
            error
            ? {
                type: 'error', 
                content: error.toString()
            }
            : {
                type: 'success',
                content: `Tour with ${tour.artist.displayableName} from ${moment(new Date(tour.from)).format('LL')} has been successfully deleted`
            }
        )
        
        if(!error){
            // update global promotors
            dispatch(touringsActions.delete({ id: tour.id }))
            closeDeleteModal()
        }

        setIsDeleting(false)
    }

    return (
        <>
            {createPortal(
                <Dialog
                    visible={formVisible}
                    title={`Edit Touring for ${tour.artist.displayableName} starting ${moment(new Date(tour.from)).format('LL')}`}
                    onClose={closeForm}
                    backdropClose={false}
                    dialogCustomStyle={{overflowY: 'scroll', height: '90vh', top: '5vh', width: '80%', left: '10%', backgroundColor: 'var(--app-background)'}}
                    notAllSpace={true}
                >
                    <TouringForm touring={tour} artist={tour.artist} closeForm={closeForm}/>
                </Dialog>, rootModalDiv)}

            {createPortal(
                <Dialog
                    title={`Delete tour with ${tour.artist.displayableName} from ${moment(new Date(tour.from)).format("LL")} `}
                    visible={deleteModal}
                    onClose={closeDeleteModal}
                    backdropClose={false}
                    dialogCustomStyle={{height: '250px', top: '23', width: '60%', left: '20%'}}
                    notAllSpace={true}
                >
                    <div>
                        <p className="margedTop20px margedBot30px">Are you sure you want to delete the tour with {tour.artist.displayableName} starting {moment(new Date(tour.from)).format('LL')} ?</p>
                        <button className="button button--round button--ghost margedRight50px" onClick={closeDeleteModal}>Cancel</button>
                        <button className="button button--round button--danger" onClick={handleDelete}>{isDeleting ? "Deleting..." : "Delete"}</button>
                    </div>
                    
                </Dialog>, rootModalDiv)}

            <tr>
                <td style={{minWidth: '100px', width: '10vw'}}>
                    <div className="flex-align-center row">
                        {
                            tour.artist.image 
                            ? <img className="artist__table-image margedRight10px" src={tour.artist.image} alt={`${tour.artist.displayableName} logo`} /> 
                            : <Row className="artist__table-image margedRight10px xlarge">{tour.artist.code.toUpperCase()}</Row>
                        }
                        <b className="larger">{ tour.artist.displayableName }</b>  
                    </div>
                </td>

                <td  className="leftalign">{moment(new Date(tour.from)).format('MMMM Do YYYY')}</td>
                <td  className="leftalign">{moment(new Date(tour.to)).format('MMMM Do YYYY')}</td>
                <td className="leftalign" style={{maxWidth: '15vw'}}>
                    <div className="flex-align-center row wrap" style={{columnGap: '10px'}}>
                        {
                            tour.countries.map((country, index) => 
                                <ReactCountryFlag
                                    key={index}
                                    countryCode={country} 
                                    style={{
                                        lineHeight: '20px',
                                        fontSize: '20px',
                                    }}
                                    title={countries[country]}
                                />
                                /* {countries[country]} */)
                        }
                    </div>
                </td>
                <td className="leftalign">{tour.comments}</td>
                <td><button onClick={handleClickEdit} className="button button--primary">Edit Data</button></td>
                <td><MdClose size={25} className="main-color-fill clickable hoverscale" onClick={openDeleteModal} /></td>
            </tr>
        </>
    )
}

export default TourItemRow