import { useEffect, useState, forwardRef } from "react";
import styles from "../../../promotor/touring/touring.module.css"
import DatePicker from "react-datepicker";
import { getCountriesFromTourings, getDurationsFromTourings, LOCATION_EVERYWHERE } from "../../../../utils/touring.util";
import { Country, PotentialTouringWithArtist, TouringWithArtist } from "../../../../utils/models/touring.model";
import PageLoading from "../../../../components/loading/PageLoading";
import ButtonsFilter from "../../../promotor/touring/buttons-filter/ButtonsFilter";
import LabelInput from "../../../../components/form/mui/label-input/LabelInput";

const PotentialTouringsFilters = ( { tourings, textFilter, setTextFilter, fromDateFilter, handlePickFromDate, toDateFilter, handlePickToDate, locationsFilter, 
    handleToggleLocationItem, handleReset, amountFromFilter, setAmountFromFilter, amountToFilter, setAmountToFilter }: {
    tourings: (TouringWithArtist | PotentialTouringWithArtist)[],
    textFilter: string,
    setTextFilter: React.Dispatch<React.SetStateAction<string>>,
    fromDateFilter: Date,
    handlePickFromDate: (date: Date) => void,
    toDateFilter: Date,
    handlePickToDate: (date: Date) => void,
    amountFromFilter: number,
    setAmountFromFilter: React.Dispatch<React.SetStateAction<number>>,
    amountToFilter: number,
    setAmountToFilter: React.Dispatch<React.SetStateAction<number>>,
    locationsFilter: { isOpen: boolean; locations: string[]; },
    handleToggleLocationItem: (toggledLocation: string) => void,
    handleReset: () => void,
}) => {

    const [computedAvailableCountries, setComputedAvailableCountries] = useState<Country[] | undefined>(undefined)
    const [computedAvailableDurations, setComputedAvailableDurations] = useState<number[] | undefined>(undefined)

    useEffect(() => {
        const computeAvailableOptions = async () => {
            const countriesPromise = getCountriesFromTourings(tourings)
            const durationsPromise = getDurationsFromTourings(tourings)

            const [countries, durations] = await Promise.all([countriesPromise, durationsPromise])

            setComputedAvailableCountries(countries)
            setComputedAvailableDurations(durations)
        }

        computeAvailableOptions()
    }, [])

    const isLoading = (computedAvailableCountries === undefined || computedAvailableDurations === undefined)
    if(isLoading){
        return (<PageLoading />)
    }

    const availableCountries = [LOCATION_EVERYWHERE, ...computedAvailableCountries]


    

    // custom input for date picker
    // @ts-ignore
    const DatePickerCustomInput = forwardRef(({ value, onClick }, ref) => (
        // @ts-ignore
        <button className={styles.datePickerInputBtn} onClick={onClick} ref={ref}>
          {value}
        </button>
      ))

    return (
        <div className="allwidth">
            <div className="app-card margedTop30px margedBot50px">
                <div className="paddedTop10px">

                        <div className="allwidth">
                            <ul className="flex-align-center space-between margedBot20px">
                                <li className={styles.navItem}>
                                    <input type="text" className={styles.textFilterInput} value={textFilter} placeholder="Artist Name" onChange={(e) => setTextFilter(e.target.value)}></input>
                                </li>
                                <li className={styles.navItem}>
                                    <LabelInput 
                                        title="Minimum Possible Fee"
                                        value={amountFromFilter}
                                        type="number"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAmountFromFilter(+e.target.value)}
                                    />
                                </li>
                                <li className={styles.navItem}>
                                    <LabelInput 
                                        title="Maximum Possible Fee"
                                        value={amountToFilter}
                                        type="number"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAmountToFilter(+e.target.value)}
                                    />
                                </li>
                            </ul>
                            <ul className="flex-align-center space-between">
                                <p className={styles.datePickerLabel}>From</p>
                                <li className={`${styles.navItem} noPaddingLeft`}>
                                    <DatePicker 
                                        customInput={<DatePickerCustomInput />} 
                                        selected={fromDateFilter} 
                                        onChange={(selectedDate:Date) => {
                                            selectedDate.setHours(0,0,0,0)
                                            handlePickFromDate(selectedDate)
                                        }} 
                                    />
                                </li>
                                
                                <p className={styles.datePickerLabel}>To</p>
                                <li className={`${styles.navItem} noPaddingLeft`}>
                                    <DatePicker 
                                        customInput={<DatePickerCustomInput />} 
                                        selected={toDateFilter} 
                                        onChange={(selectedDate:Date) => {
                                            selectedDate.setHours(0,0,0,0)
                                            handlePickToDate(selectedDate)
                                        }} 
                                        minDate={fromDateFilter} 
                                    />
                                </li>
                                <li className={styles.navItem}>
                                    <button className="button touring__button touring__nav-button" onClick={handleReset}>Reset</button>
                                </li>
                            </ul>
                        </div>
                    </div>
            </div>

            <ButtonsFilter items={availableCountries} currentFilter={locationsFilter.locations} handleClickItem={handleToggleLocationItem} translateCountryCode={true}/>
        </div>
    )
}

export default PotentialTouringsFilters