import { ROUTE_CONTRACTS, ROUTE_OFFERS } from "../../routes/routes.const";
import { Artist } from "./artists.model";
import { Manager } from "./manager.model";
import { Promotor } from "./promotors.model";

export interface Client {
    email: string;
    id: string;
    licence: Licence;
    token: string;
    tokenValidity?: number;
    promotor?: Promotor,
    artist?: Artist,
    manager?: Manager
    tasks?: ClientTask[]
    country?: string;
    // manager?: Manager


    //tasks: ClientTask[]
    
    // linkedPromotorsIds: string[]; //? can be multiple promotors ?
    // linkedArtistIds: string[]; //todo add it in backend ?
} 

export enum Licence { 
    Admin = "admin",
    Promotor = "promotor",
    Artist = "artist",
    Manager = "manager",
    Regular = "regular",
}
export const licencesList = Object.values(Licence)

export interface ClientTask {
    //todo add a date atribute ?
    date: number;
    message: string;
    linkPath: string;
    expiry?: number;
    isRead?: boolean;   // default: false
    isDone?: boolean;   // default: false
}

export const dummyTask1: ClientTask = {
    date: Date.now() - (1000 * 150),
    expiry: Date.now() + (1000 * 60 * 24 * 7),
    message: 'You have one offer to review',
    linkPath: ROUTE_OFFERS,
    isRead: false,
    isDone: false,
}

export const dummyTask2: ClientTask = {
    date: Date.now() - (1000 * 300),
    message: 'One contract just got updated',
    linkPath: ROUTE_CONTRACTS,
    isRead: false,
    isDone: false,
}