import Tabs from "../../../../../components/tabs-menu/Tabs"
import { useAppSelector } from "../../../../../utils/hooks/reduxTypedHooks"
import { Promotor } from "../../../../../utils/models/promotors.model"
import ActivePromotorsList from "./ActivePromotorsList"
import ArchivedPromotorsList from "./ArchivedPromotorsList"

const PromotorsList = ( { promotors, setOpenedPromotor, setOpenPromotor }: {
    promotors: Promotor[],
    setOpenedPromotor: React.Dispatch<React.SetStateAction<Promotor | null>>,
    setOpenPromotor: React.Dispatch<React.SetStateAction<boolean>>,
}) => {

    const archivedPromotors = useAppSelector(state => state.archivedPromotors)

    return (
        <Tabs activeTab="Active Promotors">
            <div option-data='Active Promotors'>
                <ActivePromotorsList
                    promotors={promotors}
                    setOpenedPromotor={setOpenedPromotor}
                    setOpenPromotor={setOpenPromotor}
                />
            </div>
            <div option-data='Archived Promotors'>
                <ArchivedPromotorsList 
                    promotors={archivedPromotors}
                    setOpenedPromotor={setOpenedPromotor}
                    setOpenPromotor={setOpenPromotor}
                />
            </div>
        </Tabs>
    )
}

export default PromotorsList