import { Flex } from '../Containers';
import './document-loading.scss';

export default ({texts}) => <>
    <div className="document loading">
        <div className="top"></div>
        <div className="bottom">
        <div className="icon"><span>Ct</span></div>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
        </div>
    </div>        
    <Flex className="allwidth title theme1bg">{texts.map((t, ti) => <Flex key={`docloadtext_${ti}`} className={!ti ? 'title' : 'italic grey'}>{ t }</Flex>)}</Flex>
</>
