// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.venues_thumbnail__K7vH6 {
    background-color: rgba(0,0,0,0.5);
    display: flex;
    aspect-ratio: 952 / 557;
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 5px;
    color: grey
    /* filter: brightness(0.9); */
}

.venues_cardBody__BKV4f {
    padding: var(--card-padding);
    padding-top: 10px;
}

.venues_scrollDiv__NZ3jB {
    height: 50vh;
    overflow-y: scroll;
    /* IE and Edge */
    /* -ms-overflow-style: none;   */
    /* Firefox */
    /* scrollbar-width: none;   */
}
.venues_scrollDiv__NZ3jB::-webkit-scrollbar {
    /* display: none; */
    width: 4px;
  }
.venues_scrollDiv__NZ3jB::-webkit-scrollbar-track {
    background: var(--card-background);
  }
  
  /* Handle */
.venues_scrollDiv__NZ3jB::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
.venues_scrollDiv__NZ3jB::-webkit-scrollbar-thumb:hover {
    background: #555;
  }`, "",{"version":3,"sources":["webpack://./src/user-panels/admin/admin-panel-screens/venues/venues.module.css"],"names":[],"mappings":";AACA;IACI,iCAAiC;IACjC,aAAa;IACb,uBAAuB;IACvB,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,UAAU;IACV,6BAA6B;AACjC;;AAEA;IACI,4BAA4B;IAC5B,iBAAiB;AACrB;;AAEA;IACI,YAAY;IACZ,kBAAkB;IAClB,gBAAgB;IAChB,gCAAgC;IAChC,YAAY;IACZ,6BAA6B;AACjC;AACA;IACI,mBAAmB;IACnB,UAAU;EACZ;AACF;IACI,kCAAkC;EACpC;;EAEA,WAAW;AACb;IACI,gBAAgB;EAClB;;EAEA,oBAAoB;AACtB;IACI,gBAAgB;EAClB","sourcesContent":["\n.thumbnail {\n    background-color: rgba(0,0,0,0.5);\n    display: flex;\n    aspect-ratio: 952 / 557;\n    width: 100%;\n    height: auto;\n    object-fit: cover;\n    border-radius: 5px;\n    color: grey\n    /* filter: brightness(0.9); */\n}\n\n.cardBody {\n    padding: var(--card-padding);\n    padding-top: 10px;\n}\n\n.scrollDiv {\n    height: 50vh;\n    overflow-y: scroll;\n    /* IE and Edge */\n    /* -ms-overflow-style: none;   */\n    /* Firefox */\n    /* scrollbar-width: none;   */\n}\n.scrollDiv::-webkit-scrollbar {\n    /* display: none; */\n    width: 4px;\n  }\n.scrollDiv::-webkit-scrollbar-track {\n    background: var(--card-background);\n  }\n  \n  /* Handle */\n.scrollDiv::-webkit-scrollbar-thumb {\n    background: #888;\n  }\n  \n  /* Handle on hover */\n.scrollDiv::-webkit-scrollbar-thumb:hover {\n    background: #555;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"thumbnail": `venues_thumbnail__K7vH6`,
	"cardBody": `venues_cardBody__BKV4f`,
	"scrollDiv": `venues_scrollDiv__NZ3jB`
};
export default ___CSS_LOADER_EXPORT___;
