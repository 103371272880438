import { Licence } from "../models/client.model"
import { useAppSelector } from "./reduxTypedHooks"

const useHasClientAccessToAssets = (artistId: string | undefined) => {
    const client = useAppSelector(state => state.client)
    
    const checkClientAccessToAssets = () => {
        if(!artistId) { return false }
        if(client?.licence === Licence.Admin) { return true }
        if(client?.licence === Licence.Promotor) {
            if(!client.promotor?.artists ){return false}
            const promotorArtistIds = client.promotor.artists
            return promotorArtistIds.includes(artistId)
        }
        return false
    }

    return checkClientAccessToAssets()
}

export default useHasClientAccessToAssets