import { useState } from "react"
import ReactFlagsSelect from "react-flags-select"
import FileInput from "../../../../../components/form/form-components/FileInput"
import FormSection from "../../../../../components/form/form-components/FormSection"
import LabelInput from "../../../../../components/form/mui/label-input/LabelInput"
import { clientActions } from "../../../../../store/clientSlice"
import { useAppDispatch } from "../../../../../utils/hooks/reduxTypedHooks"
import useFetchApiAuth from "../../../../../utils/hooks/useFetchApiAuth"
import useHandleHostImage from "../../../../../utils/hooks/useHandleHostImage"
import useSetSnack from "../../../../../utils/hooks/useSetSnack"
import { Promotor } from "../../../../../utils/models/promotors.model"

const ProfileForm = ( { promotor, hideUnchangeable }: { promotor: Promotor, hideUnchangeable?: boolean } ) => {
    
    const [selectedLogoFile, setSelectedLogoFile] = useState('')
    const [profileInputs, setProfileInputs] = useState({
        email: promotor.email,
        name: promotor.name,
        fullAddress: promotor.fullAddress,
        phone: promotor.phone,
        country: promotor.country,
        logo: promotor.logo,
        cieId: promotor.cieId,
        cieName: promotor.cieName,
        website: promotor.website,
    })
    const [isPosting, setIsPosting] = useState(false)

    const updateInput = (value: any, inputName: keyof typeof profileInputs) => {
        setProfileInputs(prevInputs => ({...prevInputs, [inputName]: value}))
    }

    const dispatch = useAppDispatch()
    const handleHostImage = useHandleHostImage(profileInputs)
    const fetchApiAuth = useFetchApiAuth()
    const setSnack = useSetSnack()

    const handleSubmit = async () => {
        setIsPosting(true)

        const id = promotor.id

        //hosting image
        const logo = await handleHostImage(selectedLogoFile, "logo", setSelectedLogoFile)

        const updateProps = {
            id,
            ...profileInputs, 
            logo,
        }

        const { error, response: updatedPromotor } = await fetchApiAuth({
            method: 'POST',
            route: 'promotor-profile-update',
            body: updateProps
        });

        // snack
        setSnack(
            error
            ? {
                type: 'error', 
                content: error.toString()
            }
            : {
                type: 'success',
                content: 'Your profile has been updated successfully.'
            }
        )
        
        if(!error){
            // update local client
            dispatch(clientActions.promotorUpdateProfile(updatedPromotor))
        }

        setIsPosting(false)
    }

    return(
        <>
            <div className="margedBot50px allwidth">
                <FormSection>
                    <FileInput
                        title='Logo'
                        value={profileInputs.logo}
                        onChange={({acceptedFile, imageDatas}:any) => {
                            setSelectedLogoFile(acceptedFile)
                            updateInput(imageDatas.source, 'logo');
                        }}
                    />
                </FormSection>
                <FormSection>
                    {!hideUnchangeable 
                    ?   <>
                        <LabelInput
                            title="Name"
                            value={profileInputs.name}
                            disabled 
                        />
                        <LabelInput
                            title="Email"
                            value={profileInputs.email}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateInput(e.target.value, 'email')}
                        />
                    </>
                    :   <></>}
                    <ReactFlagsSelect
                        searchable
                        onSelect={e => updateInput(e, 'country')} 
                        selected={profileInputs.country} 
                        className="allwidth"
                        selectButtonClassName="flags-selection-button"
                    />
                    <LabelInput
                        title="Address"
                        value={profileInputs.fullAddress}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateInput(e.target.value, 'fullAddress')}
                    />
                    <LabelInput
                        title="Phone"
                        value={profileInputs.phone}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateInput(e.target.value, 'phone')}
                    />
                    <LabelInput
                        title="Company Name"
                        value={profileInputs.cieName}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateInput(e.target.value, 'cieName')}
                    />
                    <LabelInput
                        title="VAT Number"
                        value={profileInputs.cieId}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateInput(e.target.value, 'cieId')}
                    />
                    <LabelInput
                        title="Website"
                        value={profileInputs.website}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateInput(e.target.value, 'website')}
                    />
                    
                </FormSection>
            </div>
            
            <button className="button button--primary button--uppercase" disabled={isPosting} onClick={handleSubmit}>{isPosting ? 'Saving...' : 'Save Changes'}</button>
        </>
    )
}

export default ProfileForm