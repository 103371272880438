import { useState } from "react"
import { createPortal } from "react-dom"
import { BiArchiveIn, BiArchiveOut } from "react-icons/bi"
import { FaUserSlash } from "react-icons/fa"
import { MdClose, MdEmojiPeople } from "react-icons/md"
import { useNavigate } from "react-router-dom"
import { Flex, Row } from "../../../../components/Containers"
import Dialog from "../../../../components/dialog/Dialog"
import ToolTip from "../../../../components/ToolTip"
import { colors, errorToSuccessTheme3 } from "../../../../constants"
import { ROUTE_ARTISTS, ROUTE_MANAGERS, ROUTE_PROMOTORS } from "../../../../routes/routes.const"
import { archivedUsersActions } from "../../../../store/archivedUsersSlice"
import { usersActions } from "../../../../store/usersSlice"
import { useAppDispatch } from "../../../../utils/hooks/reduxTypedHooks"
import useFetchApiAuth from "../../../../utils/hooks/useFetchApiAuth"
import useSetSnack from "../../../../utils/hooks/useSetSnack"
import { User } from "../../../../utils/models/users.model"
import UserForm from "./UserForm";

const licenceToRoute = {
    promotor: ROUTE_PROMOTORS,
    manager: ROUTE_MANAGERS,
    artist: ROUTE_ARTISTS
}

const UserItemRow = ({ user, isArchivedList }: {user: User, isArchivedList?: boolean }) => {
    const [formVisible, setFormVisible] = useState(false)
    const [deleteModal, setDeleteModal] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)

    const openForm = () => setFormVisible(true)
    const closeForm = () => setFormVisible(false)

    const openDeleteModal = () => setDeleteModal(true)
    const closeDeleteModal = () => setDeleteModal(false)

    const navigate = useNavigate();
    const fetchApiAuth = useFetchApiAuth()
    const setSnack = useSetSnack()
    const dispatch = useAppDispatch()

    const rootModalDiv = document.getElementById('modal-root')!
    
    const handleClickEdit = () => {
        openForm()
    }

    const handleDelete = async () => {
        setIsDeleting(true)

        const { error } = await fetchApiAuth({
            method: 'POST',
            route: 'delete-user',
            body: { id: user.id }
        });

        setSnack(
            error
            ? {
                type: 'error', 
                content: error.toString()
            }
            : {
                type: 'success',
                content: `User with email ${user.email} has been successfully deleted`
            }
        )
        
        if(!error){
            dispatch(archivedUsersActions.delete({ id: user.id }))
            closeDeleteModal()
        }

        setIsDeleting(false)
    }

    const handleArchive = async (isArchiving: boolean) => {
        const updateBody = { id: user.id, isArchived: isArchiving} 

        const { error, response } = await fetchApiAuth({
            method: 'POST',
            route: 'merge-user',
            body: updateBody 
        });


        setSnack(
            error
                ? {
                    type: 'error', 
                    content: error.toString()
                }
                : {
                    type: 'success',
                    content: `User ${user.email} has been successfully ${isArchiving ? "archived" : "unarchived"}`
                }
            )

        if (!error) {
            if(isArchiving) {
                dispatch(archivedUsersActions.add(response))
                dispatch(usersActions.delete({id: user.id}))
            } else {
                dispatch(usersActions.add(response))
                dispatch(archivedUsersActions.delete({id: user.id}))
            }
        }
    }

    return(
        <>
            {createPortal(
                <Dialog
                    visible={formVisible}
                    onClose={closeForm}
                    backdropClose={false}
                >
                    <UserForm user={user} close={closeForm} />
                </Dialog>, rootModalDiv)}

                {createPortal(
                <Dialog
                    title={`Delete user with email ${user.email}`}
                    visible={deleteModal}
                    onClose={closeDeleteModal}
                    backdropClose={false}
                    dialogCustomStyle={{height: '250px', top: '23', width: '60%', left: '20%'}}
                    notAllSpace={true}
                >
                    <div>
                        <p className="margedTop20px margedBot30px">Are you sure you want to delete the user with email {user.email} ?</p>
                        <button className="button button--round button--ghost margedRight50px" onClick={closeDeleteModal}>Cancel</button>
                        <button className="button button--round button--danger" onClick={handleDelete}>{isDeleting ? "Deleting..." : "Delete"}</button>
                    </div>
                    
                </Dialog>, rootModalDiv)}
            
            <tr>
                <td className="leftalign">{user.email}</td>
                <td>{user.licence}</td>
                <td>{
                    user.actorId
                    // ? <Flex>
                    //     { user.actorId /* display idem admin artist promotor ?*/}
                    // </Flex>   
                    ? <Flex row 
                        className="clickable hoverscale main-color" 
                        onClick={() => {
                            /* @todo: navigate to `Contracts` with `promotor.id` as search value */
                            // @ts-ignore
                            navigate(licenceToRoute[user.licence], { state: { searchValue: user.actorId } })
                        }}
                    >  
                        <MdEmojiPeople size={25} />
                    </Flex>
                    : (
                        user.licence === 'admin' 
                        ? '-' 
                        : <ToolTip
                            refIcon={(iconRef:any) => <FaUserSlash // Tooltip
                                // @ts-ignore
                                ref={iconRef}
                                size={17} 
                                color={errorToSuccessTheme3[0]}
                                
                            />}
                            title="Missing Actor"
                            content={<Flex className="alignstart">
                                <Flex className="selfstart wrap bold">{'This user'} must be linked to a known {user.licence} actor</Flex>
                                <Row className="wrap smaller">Please click the "Edit Data" option and select an available {user.licence} as "Actor"</Row>
                            </Flex>}
                        />
                    )
                }</td>
                <td>{user.active ? "Yes" : "No"}</td>
                <td>
                    {isArchivedList
                    ?   <MdClose size={25} color={colors.red} className="clickable hoverscale" onClick={openDeleteModal} />
                    :   <button onClick={handleClickEdit} className="button button--primary">Edit Data</button>}
                </td>
                <td>
                    {isArchivedList
                    ?   <BiArchiveOut size={25} className="main-color-fill clickable hoverscale" onClick={() => handleArchive(false)} />
                    :   <BiArchiveIn size={25} className="main-color-fill clickable hoverscale" onClick={() => handleArchive(true)} />}
                </td>
            </tr>
        </>
    )
}

export default UserItemRow