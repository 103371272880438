import AdminPanel from "../user-panels/admin/AdminPanel"
import ManagerPanel from "../user-panels/manager/ManagerPanel"
import PromotorPanel from "../user-panels/promotor/PromotorPanel"
import { useAppSelector } from "../utils/hooks/reduxTypedHooks"
import { Licence } from "../utils/models/client.model"
import AccessDeniedPage from "./AccessDeniedPage"

const PanelPage = () => {
    const licence = useAppSelector(state => state.client?.licence)

    if(licence === Licence.Admin){
        return (<AdminPanel />)
    } else if (licence === Licence.Promotor){
        return (<PromotorPanel />)
    } else if (licence === Licence.Manager) {
        return (<ManagerPanel />)
    } else {
        return (<AccessDeniedPage />)
    }
}

export default PanelPage