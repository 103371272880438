import { Licence } from "../models/client.model"
import { useAppSelector } from "./reduxTypedHooks"

const useIsAdmin = () => {
    const licence = useAppSelector(state => state.client?.licence)
    const isAdmin = licence === Licence.Admin

    return isAdmin
}

export default useIsAdmin