import { AiFillCloseCircle } from "react-icons/ai";
import { Flex } from "../../Containers"
import Dropzone from "../../Dropzone";

const FileInput = ({ value, title, onChange }: { value: any, title: string, onChange: Function}) => {

    return(
        <Flex className="allwidth">
                <label className="selfstart bold label-color">{ title }</label>
                {   value 
                    ? <div>
                        <div style={{ position: 'relative', top: '10px', left: '50%' }} className='clickable' onClick={() => onChange({imageDatas: {source: ''}})}><AiFillCloseCircle color='grey' size={20} /></div>
                        <img src={value} style={{ width: '15vw', height: 'auto', maxHeight: '15vh', objectFit: 'contain' }} alt={`${title}_logo`} />
                    </div>
                    : <Dropzone
                        title='Drop an image here or click to select'
                        accept='.jpg,.jpeg,.png'
                        setFile={onChange}
                    />
                }
            </Flex>
    )
}

export default FileInput