import { ChartData } from "chart.js"
import ChartJsBar from "../../../../../../components/Charts/ChartJsBar"
import { colors } from "../../../../../../constants"

const ProfitAndLossBarChart = ({ currency, currencySymbol, pourcentagesArray, totalRevenueArray, totalExpenditureArray, profitsArray }: {
    currency: string,
    currencySymbol: null|string,
    pourcentagesArray: number[],
    totalRevenueArray: number[],
    totalExpenditureArray: number[],
    profitsArray: number[],
}) => {

    const labels = pourcentagesArray.map(pourcentage => `${pourcentage}%`)

    const datasets = [
        {
            label: "Total Income",
            backgroundColor: colors.green,
            data: totalRevenueArray
        },
        {
            label: "Total Cost",
            backgroundColor: colors.red,
            data: totalExpenditureArray
        },
        {
            label: "Profit / Loss",
            backgroundColor: colors.lightblue,
            data: profitsArray
        },
    ]

    const data: ChartData<'bar'> = {
        labels,
        datasets
      }

    return (
        <div className="app-card allwidth">
            <p className="margedBot10px">Profit And Loss ({currencySymbol ?? currency})</p>
            <ChartJsBar 
                data={data}
            />
        </div>
    )
}

export default ProfitAndLossBarChart