import { useState } from "react"
import { Link, useOutletContext } from "react-router-dom"
import { ArtistDetailsContext } from "../ArtistDetails"
import { useAppSelector } from "../../../../../utils/hooks/reduxTypedHooks"
import { Flex, Row } from "../../../../../components/Containers"
import { ARTIST_DETAILS_OVERVIEW_ENDPOINT, ROUTE_ARTISTS } from "../../../../../routes/routes.const"
import ListItem from "../../../touring/ListItem"
import Dialog from "../../../../../components/dialog/Dialog"
import ArtistRequestTouring from "../common/ArtistRequestTouring"
import { formatDisplayableNameToUrl } from "../../../../../utils/artists.util"
import EventsGraph from "./EventsGraph"
import TopTracks from "./TopTracks"
import ArtistInfos from "../common/ArtistInfos"
import StreamingDatas from "./StreamingDatas"
import LoadingOval from "../../../../../components/loading/LoadingOval"
import useIsAdmin from "../../../../../utils/hooks/useIsAdmin"

const ArtistDetailsOverview = () => {
    const [isReadingMore, setIsReadingMore] = useState(false)
    const [requestVisible, setRequestVisible] = useState(false)

    const artists = useAppSelector(state => state.artists)
    
    const { artist, artistWithTours, fetchedDatas } = useOutletContext<ArtistDetailsContext>()

    const isAdmin = useIsAdmin()

    const openRequest = () => setRequestVisible(true)
    const closeRequest = () => setRequestVisible(false)

    const bioMaxCharLenght = 400
    const isReadMoreNeeded = artist.bio ? artist.bio.length > bioMaxCharLenght : false
    const toggleReadMore = () => {
        setIsReadingMore(prevState => !prevState)
    }

    const spotifySimilarArtists = fetchedDatas.spotify?.similarArtists ? fetchedDatas.spotify?.similarArtists.slice(0,5) : undefined

    //later filter through same genres
    const primuseSimilarArtists = artists.filter(_artist => _artist.id !== artist.id).slice(0,5)

    const tourPreviews = artistWithTours ? artistWithTours.tours.filter(tour => !tour.isPast).slice(0,3) : []
    const toursUrl = `${ROUTE_ARTISTS}/${formatDisplayableNameToUrl(artist.displayableName)}/tours#booking`

    const { spotify, events, pastEvents, seatgeek } = fetchedDatas

    
    return (
        <div className="main-container">
            {isAdmin &&
                <div className="textAlignEnd margedTop10px">
                    <button className="button button--primary">Edit Infos</button>
                </div>}
            <div className="leftalign">
                <h2 className="margedBot20px">Overview</h2>
                <div className="flex-only row space-between">

                    {/* Central Area */}
                    <div className="width70">

                        {/* Bio*/}
                        {artist.bio
                        &&  <div className="app-card margedBot50px">
                                <div className="details-title-border">
                                    <h4 className="details-section-title">Bio</h4>
                                </div>
                                {isReadMoreNeeded
                                ?   <>
                                        <p className="artist-details__biography">
                                            {artist.bio.slice(0, bioMaxCharLenght)}
                                            {!isReadingMore && <>... <button className="read-more" onClick={toggleReadMore}>Read more</button></>}
                                            {isReadingMore && <>{artist.bio.slice(bioMaxCharLenght)} <button className="read-more" onClick={toggleReadMore}>Show less</button></>}
                                        </p>
                                        
                                    </>
                                :   <p className="artist-details__biography">{artist.bio}</p>}
                            </div>}

                        {/* Tours */}
                        <div className="app-card margedBot50px">
                            <div className="details-title-border">
                                <h4 className="details-section-title">Available Dates</h4>
                            </div>
                            {tourPreviews.length > 0
                            ?   <>
                                    {tourPreviews.map((tour, index) => <ListItem artist={artist} touring={tour} key={index} />)}
                                    <Flex className="margedTop20px">
                                        <Link to={toursUrl}>
                                            <button className="button button--primary button--round">View All</button>
                                        </Link>
                                    </Flex>
                                </>
                            :   <>
                                    <p className="grey margedBot30px">No tours available for now.</p>
                                    <button className="button button--primary button--round" onClick={openRequest}>Request Shows</button>
                                    <Dialog 
                                        visible={requestVisible} 
                                        onClose={closeRequest}
                                        title="Touring Request"
                                        notAllSpace 
                                        dialogCustomStyle={{overflowY: "scroll", height: '90vh', top: '5vh', width: '80%', left: '10vw', backgroundColor: 'var(--app-background)'}}
                                    >
                                        <ArtistRequestTouring artistName={artist.displayableName} close={closeRequest}/>
                                    </Dialog>
                                </>}
                        </div>

                        {/* Achievements*/}
                        <div className="app-card margedBot50px">
                            <div className="details-title-border">
                                <h4 className="details-section-title">Achivements</h4>
                            </div>
                            <p>Albums : {spotify?.albums ? spotify.albums.length : 'Not found'}</p>
                            {/* <p>Seatgeek score : {seatgeek?.score ? seatgeek.score : 'Not found'}</p> */}
                            {/* //todo events loader (need put p in FLex) if we keep achievements */}
                            <p>Upcoming events : {events ? events.length : '...'}</p>
                        </div>

                        {/* Steaming Datas*/}
                        <div className="app-card margedBot50px">
                            <div className="details-title-border">
                                <h4 className="details-section-title">Streaming Datas</h4>
                            </div>
                            <StreamingDatas fetchedDatas={fetchedDatas} />
                        </div>

                        {/* Events Activity*/}
                        {(pastEvents === undefined || events === undefined)
                        ?   <div className="app-card margedBot50px">
                                <div className="details-title-border">
                                    <h4 className="details-section-title">Events Activity</h4>
                                </div>
                                <Flex><LoadingOval /></Flex>
                            </div>
                        :   (pastEvents.length > 0 || events.length > 0 )
                            &&  <div className="app-card margedBot50px">
                                    <div className="details-title-border">
                                        <h4 className="details-section-title">Events Activity</h4>
                                    </div>
                                    <Row className="allwidth space-between margedBot5px">
                                        <h3 className="fw400">Total shows : <b>{events.length + pastEvents.length}</b></h3>
                                        <h3 className="fw400">Past shows : <b>{pastEvents.length}</b></h3>
                                        <h3 className="fw400">Upcoming shows : <b>{events.length}</b></h3>
                                    </Row>
                                    <EventsGraph pastEvents={pastEvents} events={events} />
                                </div>}
                        {}

                        {/* Simillar Artists */}
                        <div className="app-card margedBot50px">
                            <div className="details-title-border">
                                <h3 className="details-section-title">Similar Artists</h3>
                            </div>
                            <div className="flex-only row space-between">
                                {spotifySimilarArtists 
                                ?   spotifySimilarArtists.map((similarArtist, index) => 
                                    <div className="width95 text-centered" key={index}>
                                        <Link to={`${ROUTE_ARTISTS}/${formatDisplayableNameToUrl(similarArtist.name)}/${ARTIST_DETAILS_OVERVIEW_ENDPOINT}`} className="hover-opacity">
                                            <Flex className="margedBot15px">
                                                {similarArtist.images[0].url
                                                ?   <img className="simillar-artist-image" src={similarArtist.images[0].url} alt={`${similarArtist.name} logo`} /> 
                                                :   <div className="simillar-artist-image xlarge"></div>}
                                                <p className="font13">{similarArtist.name}</p>
                                            </Flex>
                                        </Link>
                                    </div>)
                                :   primuseSimilarArtists.map((similarArtist, index) =>  
                                    <div className="width95 text-centered" key={index}>
                                        <Link to={`${ROUTE_ARTISTS}/${formatDisplayableNameToUrl(similarArtist.displayableName)}/overview`} className="hover-opacity">
                                            <Flex className="margedBot15px">
                                                {similarArtist.image 
                                                ?   <img className="simillar-artist-image" src={similarArtist.image} alt={`${similarArtist.displayableName} logo`} /> 
                                                :   <Flex className="simillar-artist-image xlarge">{similarArtist.code.toUpperCase()}</Flex>}
                                                <p className="font13">{similarArtist.displayableName}</p>
                                            </Flex>
                                        </Link>
                                    </div>)
                                }
                            </div>
                        </div>

                        {/* Top Tracks */}
                        {(spotify?.id && spotify?.topTracksUS) &&
                            <div className="app-card">
                                <TopTracks artistId={spotify.id} tracks={spotify.topTracksUS}/>
                            </div>}


                    </div>
                    
                    {/* Right Card */}
                    <div className="width25">
                        <div className="app-card">
                            <ArtistInfos artist={artist} spotifyData={fetchedDatas.spotify} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ArtistDetailsOverview