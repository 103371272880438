import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ArtistRequest } from '../utils/models/requests.model';

type RequestsState = ArtistRequest[] | null

const initialState = null;

export const requestsSlice = createSlice({
  name: 'requests',
  initialState: initialState as RequestsState,
  reducers: {
    set: (state, action: PayloadAction<ArtistRequest[]>) => {
      return action.payload
    },
    push: (state, action: PayloadAction<ArtistRequest>) => {
        if(!state) { return } 

        state.push(action.payload)
    },
    merge: (state, action: PayloadAction<ArtistRequest>) => {
      if(!state) { return }

      const {id, ...mergeProps} = action.payload
      const requestIndex = state.findIndex(request => request.id === id)
      if(requestIndex !== -1){
        state[requestIndex] = {...state[requestIndex], ...mergeProps}
      }
    }
  },
});

export const requestsActions = requestsSlice.actions;

export default requestsSlice.reducer;