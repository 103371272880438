import PromotorAgenda from "../user-panels/promotor/agenda/PromotorAgenda"
import { useAppSelector } from "../utils/hooks/reduxTypedHooks"
import { Licence } from "../utils/models/client.model"
import NotFoundPage from "./NotFoundPage"

const AgendaPage = () => {
    const licence = useAppSelector(state => state.client?.licence)

    if(licence === Licence.Promotor){
        return (<PromotorAgenda />)
    } else {
        return (<NotFoundPage />)
    }
}

export default AgendaPage