import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Offer } from '../utils/models/offer.model';

const initialState: Offer[] = [];

export const offersSlice = createSlice({
  name: 'offers',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<Offer[]>) => {
      return action.payload
    },
    push: (state, action: PayloadAction<Offer>) => {
        state.push(action.payload)
    },
    merge: (state, action: PayloadAction<Offer>) => {
      const {id, ...mergeProps} = action.payload
      const offerIndex = state.findIndex(offer => offer.id === id)
      if(offerIndex !== -1){
        state[offerIndex] = {...state[offerIndex], ...mergeProps}
      }
    }
  },
});

export const offersActions = offersSlice.actions;

export default offersSlice.reducer;