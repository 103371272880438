import Tabs from "../../../../components/tabs-menu/Tabs"
import ManagerForm from "./ManagerForm"

const AdminManagers = () => {

    return (
        <Tabs activeTab="Touring submissions">
            <div option-data='Touring submissions'>

            </div>
            <div option-data='Artist submissions'>

            </div>
            <div option-data='Create manager'>
                <div className="content-container">
                    <ManagerForm />
                </div>
            </div>
            <div option-data='Managers list'>

            </div>
        </Tabs>
    )
}

export default AdminManagers