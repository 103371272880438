import { SeatgeekEvent, StandardizedEvent } from "../../../../../utils/models/artists.model"
import { AF, AM, AS, EU, OC } from "../../../../../utils/models/touring.model";
import ChartJsLine from "../../../../../components/Charts/ChartsJsLine";
import { colors } from "../../../../../constants";
import { useState } from "react";
import { Flex } from "../../../../../components/Containers";

enum EventsFilter {
    All = 1,
    Past = 2,
    Future = 3,
}

const EventsGraph = ({ pastEvents, events }: { pastEvents: (StandardizedEvent | SeatgeekEvent)[], events: (StandardizedEvent | SeatgeekEvent)[] }) => {

    const [eventFilter, setEventFilter] = useState(EventsFilter.All)

    const btnAllActiveClass = eventFilter === EventsFilter.All ? "touring-ghost__button--active" : ""
    const btnPastActiveClass = eventFilter === EventsFilter.Past ? "touring-ghost__button--active" : ""
    const btnFutureActiveClass = eventFilter === EventsFilter.Future ? "touring-ghost__button--active" : ""

    const eventsHashMap: Record<string, Record<string, number>> = {}

    const allEvents = [...pastEvents, ...events]

    const today = new Date()
    today.setHours(0,0,0,0)

    const filteredEvents = allEvents.filter(event => {
        const eventTime = new Date(event.datetime_local).getTime()
        switch(eventFilter){
            case EventsFilter.Future:
                return (eventTime >= today.getTime())
            case EventsFilter.Past:
                return (eventTime < today.getTime())
            default:
                return true
        }
    })

    filteredEvents.forEach(event => {
        const date = event.datetime_local.substring(0, 7)   // yyyy-mm
        if(!eventsHashMap[date]) { eventsHashMap[date] = {}}

        if (EU.EU.includes(event.venue.country)) {
            if(eventsHashMap[date]?.["EU"] !== undefined) {
                eventsHashMap[date]["EU"] = eventsHashMap[date]["EU"]+1
            } else {
                eventsHashMap[date]["EU"] = 1
            }
        } else if (AS.AS.includes(event.venue.country)) {
            if(eventsHashMap[date]?.["AS"] !== undefined) {
                eventsHashMap[date]["AS"] = eventsHashMap[date]["AS"]+1
            } else {
                eventsHashMap[date]["AS"] = 1
            }
        } else if (AM.AM.includes(event.venue.country)) {
            if(eventsHashMap[date]?.["AM"] !== undefined) {
                eventsHashMap[date]["AM"] = eventsHashMap[date]["AM"]+1
            } else {
                eventsHashMap[date]["AM"] = 1
            }
        } else if (OC.OC.includes(event.venue.country)) {
            if(eventsHashMap[date]?.["OC"] !== undefined) {
                eventsHashMap[date]["OC"] = eventsHashMap[date]["OC"]+1
            } else {
                eventsHashMap[date]["OC"] = 1
            }
        } else if (AF.AF.includes(event.venue.country)) {
            if(eventsHashMap[date]?.["AF"] !== undefined) {
                eventsHashMap[date]["AF"] = eventsHashMap[date]["AF"]+1
            } else {
                eventsHashMap[date]["AF"] = 1
            }
        } 
        // else {
        //     console.log("no continent", event)
        // }
    })

    const eventDates = Object.keys(eventsHashMap)
    const europeShows = eventDates.map(date => eventsHashMap[date]["EU"] ?? 0)
    const totalEuropeShows = europeShows.reduce((acc, value) => acc+value , 0)
    const asiaShows = eventDates.map(date => eventsHashMap[date]["AS"] ?? 0)
    const totalAsiaShows = asiaShows.reduce((acc, value) => acc+value , 0)
    const americaShows = eventDates.map(date => eventsHashMap[date]["AM"] ?? 0)
    const totalAmericaShows = americaShows.reduce((acc, value) => acc+value , 0)
    const oceaniaShows = eventDates.map(date => eventsHashMap[date]["OC"] ?? 0)
    const totalOceaniaShows = oceaniaShows.reduce((acc, value) => acc+value , 0)
    const africaShows = eventDates.map(date => eventsHashMap[date]["AF"] ?? 0)
    const totalAfricaShows = africaShows.reduce((acc, value) => acc+value , 0)

      const chartDataSets = [
        {
            fill: {target: 'origin', above: colors.bgLightblue},
            label: `Europe Shows (${totalEuropeShows})`,
            data: europeShows,
            backgroundColor: colors.lightblue,
            borderColor: colors.lightblue,
        },
        {
            fill: {target: 'origin', above: colors.bgGreen},
            label: `Asia Shows (${totalAsiaShows})`,
            data: asiaShows,
            backgroundColor: colors.green,
            borderColor: colors.green,
        },
        {
            fill: {target: 'origin', above: colors.bgOrange},
            label: `America Shows (${totalAmericaShows})`,
            data: americaShows,
            backgroundColor: colors.orange,
            borderColor: colors.orange,
        },
        {
            fill: {target: 'origin', above: colors.bgRed},
            label: `Oceania Shows (${totalOceaniaShows})`,
            data: oceaniaShows,
            backgroundColor: colors.red,
            borderColor: colors.red,
        },
        {
            fill: {target: 'origin', above: colors.bgGray},
            label: `Africa Shows (${totalAfricaShows})`,
            data: africaShows,
            backgroundColor: colors.gray,
            borderColor: colors.gray,
        },
       
    ]

    const chartData = {
        labels: eventDates,
        datasets: chartDataSets
    }
    
    return (
        <div>
            <Flex row className="space-between margedTop10px">
                <button onClick={() => setEventFilter(EventsFilter.All)} className={`button touring__button touring-ghost__button ${btnAllActiveClass}`}>
                    All Shows
                </button>
                <button onClick={() => setEventFilter(EventsFilter.Past)} className={`button touring__button touring-ghost__button ${btnPastActiveClass}`}>
                    Past Shows
                </button>
                <button onClick={() => setEventFilter(EventsFilter.Future)} className={`button touring__button touring-ghost__button ${btnFutureActiveClass}`}>
                    Upcoming Shows
                </button>
            </Flex>
            <ChartJsLine data={chartData} dotRadius={1.5} />
        </div>
    )
}

export default EventsGraph