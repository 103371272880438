import { useSelector } from "react-redux";
import { hostImage, promisify } from "../utils";


const useHandleHostImage = (formInputs: any) => {
    const client = useSelector((state: any) => state.client);

    const handleHostImage = async (selectedFile: string, inputName: "image" | "logo" | "banner", setSelectedFile: (value: React.SetStateAction<string>) => void) => {
        return await new Promise(async(_img) => {
            const isLocalImg = await promisify(selectedFile && formInputs[inputName].slice(0, 10).includes('data:image'));
            
            if(isLocalImg) {
                // read to get file
                const host:any = await hostImage(selectedFile, client.token);
                _img(host.response);
                setSelectedFile('')
            }
            
            else _img(formInputs[inputName])
        })
    }

    return handleHostImage
}

export default useHandleHostImage