import { ROUTE_ARTISTS_FEED, ROUTE_TOURING } from "../../routes/routes.const"

import HomePanel from "../common/HomePanel";

const ManagerPanel = () => {

    const screens = {
        [ROUTE_ARTISTS_FEED]: {
            title: 'Artists',
            icon: 'tabler:color-swatch'
        },
        [ROUTE_TOURING]: {
            title: 'Touring',
            icon: 'tabler:color-swatch'
        }
    }

        return <HomePanel screens={screens} />
}

export default ManagerPanel;