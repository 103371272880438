import { Component } from "react";
import IconifyIcon from "../Icon/IconifyIcon";
import { Flex } from "../Containers";

class Tab extends Component {

    onTabClicked = () => {
        const { label, onClick } = this.props;
        onClick(label);
    }

    render() {
        const {
            onTabClicked,
            props: {
                activeTab,
                label,
                icon,
                widthAuto
            },
        } = this;

        

        return (
        <li
            className={`tab-list-item${(activeTab === label) ? ' tab-list-active' : ''} ${widthAuto ? "width-auto" : ""}`}
            onClick={onTabClicked}
        >
            <Flex row>
                {icon && <IconifyIcon className="iconify-tab-icon" icon={icon} />}
                {label}
            </Flex>
        </li>
        );
    }
}
  
export default Tab;