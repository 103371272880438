import { ChartData, ChartOptions } from 'chart.js';
import { Line } from 'react-chartjs-2';

// Example data :
// const chartData = {
//   labels: ["10/12/2022", "15/12/2022"],
//   datasets: [{
//     fill: {target: 'origin', above: colors.bgGray},
//     label: `Europe Shows`,
//     data: [3, 12],
//     backgroundColor: colors.gray,
//     borderColor: colors.gray,
// }]
// }
const ChartJsLine = ( { data, title, height, dotRadius, customPlugins }: {
    data: ChartData<'line'>, 
    title?: string, 
    height?: number, 
    dotRadius?: number, 
    customPlugins?: any,
}) => {
  
    const options: ChartOptions<'line'> = {
        // responsive: true,
        maintainAspectRatio: height ? false : true,
        scales: {
          x: {
            ticks: { 
                color: 'rgba(228, 230, 244, 0.38)' 
            },
            grid: {
              color: 'rgba(228, 230, 244, 0.12)'
            }
          },
          y: {
            ticks: {
              color: 'rgba(228, 230, 244, 0.38)'
            },
            grid: {
              color: 'rgba(228, 230, 244, 0.12)'
            }
          }
        },
        plugins: {
          title: {
              display: title !== undefined, //true if title,
              text: title,
              color: 'rgba(228, 230, 244, 0.87)',
          },
          legend: {
            // align: 'end',
            position: 'top',
            labels: {
              padding: 25,
              boxWidth: 10,
              color: 'rgba(228, 230, 244, 0.68)',
              usePointStyle: true
            }
          },
          ...{...(customPlugins ? customPlugins : {})}
        }
      }

      const styledDatasets = data.datasets.map(dataset => ({
        ...dataset, 
        tension: 0.35,
        pointRadius: dotRadius ?? 3, 
        pointHoverRadius: 5, 
        pointHoverBorderWidth: 5,
        hoverOffset: 4,
        borderWidth: 1,
    }))
    const styledData = {...data, datasets: styledDatasets}

    return (
        <Line data={styledData} options={options} height={height} />
    )
}

export default ChartJsLine