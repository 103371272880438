import { Link } from 'react-router-dom';
import logo from '../../images/logo.png';
import { ROUTE_LOGIN } from '../../routes/routes.const';

const Navbar = () => {

    return (
        <div className="landing-nav">
            <div className="landing-container">
                <div className="flex-align-center space-between" style={{padding: "15px 0"}}>
                    <img style={{width: "200px"}} src={logo} alt="logo" />
                    <ul className='flex-align-center'>
                        <li  className='landing-nav-item'>
                            <a href="/#home">
                                <span>Home</span>
                            </a>
                        </li>
                        <li className='landing-nav-item'>
                            <a href="/#app">
                                <span>The App</span>
                            </a>
                        </li>
                        <li className='landing-nav-item'>
                            <a href="#story">
                                <span>Our Story</span>
                            </a>
                        </li>
                        <li className='landing-nav-item'>
                            <a href="/#features">
                                <span>Features</span>
                            </a>
                        </li>
                        <li className='landing-nav-item'>
                            <a href="#how">
                                <span>How it works</span>
                            </a>
                        </li>
                    </ul>
                    <div>
                        <Link to={ROUTE_LOGIN}>
                            <button className='button button--ghost margedRight20px'>Login</button>
                        </Link>
                        <a href="#contact">
                            <button className='button button--primary'>Contact</button>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Navbar