import { Client } from "./models/client.model";
import { OfferCreateObject, OfferFormInputs, OfferPNLInputs, OfferShowFormInputs, ShowOtherIncome, ShowSponsor, ShowTicket, TicketVariables } from "./models/offer.model";

export const validateForm = (offer: OfferFormInputs, client: Client | null, setSnack: (snack: {type: 'success'|'warning'|'error'; content: any;}) => any) => {
    const numberOfShows = offer.shows.length;
    const promotor = client?.promotor

    if(!promotor) {
        setSnack({type: 'warning', content: 'Only a Kisum Promotor can make an offer'})
        return false
    }

    const showsAmounts = offer.shows
        .map((show: OfferShowFormInputs, si:number) => show.amount)
        .filter((amount) => amount)

    const showsNames = offer.shows
    .map(show => show.eventName)
    .filter(eventName => eventName)
    
    const showsVenuesNames = offer.shows
        .map(show => show.venue.name)
        .filter(name => name)

    const showsVenuesAddresses = offer.shows
        .map(show => show.venue.address)
        .filter(address => address)

    const showsVenuesWebsites = offer.shows
        .map(show => show.venue.website)
        .filter(website => website)

    const showsVenuesCapacities = offer.shows
        .map(show => show.venue.totalCapacity)
        .filter(capacity => capacity)

    const showsDoorsOpen = offer.shows
        .map(show => show.doorsOpen)
        .filter(doorsOpen => doorsOpen)

    const showsCurfews = offer.shows
        .map(show => show.curfew)
        .filter(curfew => curfew)

    const showsSetTimes = offer.shows
        .map(show => show.onStage)
        .filter(onStage => onStage)

    const showsMinAges = offer.shows
        .map(show => show.minAge)
        .filter(minAge => minAge)
    
    const areTicketsValid = validateTickets(offer)

    //todo form validation when deal options
    // if(showsAmounts?.length !== numberOfShows) {
    //     setSnack({
    //         type: 'warning',
    //         content: 'At least one offer show has an invalid amount'
    //     });
    //     return false
    // } 
    if (!offer.expiryDate) {
        setSnack({
            type: 'warning',
            content: 'Please enter an expiry date to your offer'
        });
        return false
    } else if (showsNames?.length !== numberOfShows) {
        setSnack({
            type: 'warning',
            content: 'At least one show does not have an event name'
        });
        return false
    }else if (showsVenuesNames?.length !== numberOfShows) {
        setSnack({
            type: 'warning',
            content: 'At least one show does not have a venue name'
        });
        return false
    } else if (showsVenuesAddresses?.length !== numberOfShows) {
        setSnack({
            type: 'warning',
            content: 'At least one show does not have a venue address'
        });
        return false
    } else if (showsVenuesWebsites?.length !== numberOfShows) {
        setSnack({
            type: 'warning',
            content: 'At least one show does not have a venue website'
        });
        return false
    } else if (showsVenuesCapacities?.length !== numberOfShows) {
        setSnack({
            type: 'warning',
            content: 'At least one show does not have a total capacity indicated'
        });
        return false
    } else if (showsDoorsOpen?.length !== numberOfShows) {
        setSnack({
            type: 'warning',
            content: 'At least one show does not have a doors opening time indicated'
        });
        return false
    } else if (showsSetTimes?.length !== numberOfShows) {
        setSnack({
            type: 'warning',
            content: 'At least one show does not have a requested set time indicated'
        });
        return false
    } else if (showsCurfews?.length !== numberOfShows) {
        setSnack({
            type: 'warning',
            content: 'At least one show does not have a curfew time indicated'
        });
        return false
    } else if (showsMinAges?.length !== numberOfShows) {
        setSnack({
            type: 'warning',
            content: 'At least one show does not have a visitor minimum age indicated'
        });
        return false
    } else if (!areTicketsValid) {
        setSnack({
            type: 'warning',
            content: 'At least one show does not have valid ticket(s) with a gross price indicated'
        });
        return false
    } 

    return true
}


const validateTickets = (offer: OfferFormInputs) => {
    const osLen = offer.shows?.length
    
    for(let i = 0 ; i < osLen ; i++){
        const numberOfTickets = offer.shows[i].tickets?.length
        for(let j= 0 ; j < numberOfTickets ; j++){
            if(!offer.shows[i].tickets[j]?.name) {
                return false
            }
        }
    }

    return true
}

export const validatePnlForm = ({ pnlInputs, setSnack }: {pnlInputs: OfferPNLInputs, setSnack: (snack: {type: 'success'|'warning'|'error'; content: any;}) => any}) => {
    const { corporateTicketPurchase, fixedCosts, variableCosts } = pnlInputs
    if(corporateTicketPurchase.has && !corporateTicketPurchase.amount) {
        setSnack({type: 'warning', content: 'Please enter a valid amount for the Corporate Ticket Purchase, or remove it'})
        return false
    }

    let areVariableCostsProperlyFilled = true
    variableCosts.forEach(variableCost => {
        if(variableCost.has && (!variableCost.name || !variableCost.percentage)){
            areVariableCostsProperlyFilled = false
            return
        }
    })

    if(!areVariableCostsProperlyFilled) {
        setSnack({type: 'warning', content: 'Please enter a valid name and percentage for each Variable Cost, or remove missing ones'})
        return false
    }

    let areFixedCostsProperlyFilled = true
    fixedCosts.forEach(fixedCost => {
        if(fixedCost.has && (!fixedCost.name || !fixedCost.amount)){
            areFixedCostsProperlyFilled = false
            return
        }
    })

    if(!areFixedCostsProperlyFilled) {
        setSnack({type: 'warning', content: 'Please enter a valid name and amount for each Fixed Cost, or remove missing ones'})
        return false
    }

    return true
}


export const formatOfferFromInputs = (offer: OfferFormInputs) => {
    const formatedShows = offer.shows.map(show => {
        const formatedShowTypes = show.showTypes.map(showType => showType.value)
        const formatedShowDuration = show.showDuration.value
        return {
            ...show, 
            showTypes: formatedShowTypes,
            showDuration: formatedShowDuration,
        }
    })
    const formatedOffer: OfferCreateObject = {
        ...offer,
        shows: formatedShows,
    }

    return formatedOffer
}

export const computePriceAfterVAT = (price: number, VATpercent: number) => {
    return (price / ((100+VATpercent)/100))
}

export const computeTicketsDatas = (tickets: ShowTicket[], ticketsVariables: TicketVariables) => {

    const totalTicketsQuantity = tickets.map((ticket: any) => ticket.quantity).reduce((acc: number, cur: number) => {
        const _cur = cur ?? 0
        return (acc + _cur)
    }, 0) 

    const ticketsTotalGrossIncome = tickets.reduce((acc: number, ticket: any) => {
        let ticketTotalPrice = 0
        if(ticket.quantity && ticket.grossPrice) {
            ticketTotalPrice = ticket.quantity * ticket.grossPrice
        }
        return (acc + ticketTotalPrice)
    }, 0) 

    const priceAfterCompsTicketDeduced = tickets.reduce((acc: number, ticket: ShowTicket) => {
        let ticketTotalPrice = 0
        const compsTicketsNumber = Math.round(ticket.quantity * (ticket.compsTicketsPercent/100))
        const quantityAfterComps = ticket.quantity - compsTicketsNumber
        if((quantityAfterComps > 0) && ticket.grossPrice) {
            ticketTotalPrice = quantityAfterComps * ticket.grossPrice
        }
        return (acc + ticketTotalPrice)
    }, 0) 

    const compsTicketTotalAmount = ticketsTotalGrossIncome - priceAfterCompsTicketDeduced
    const priceAfterVatDeduced = computePriceAfterVAT(priceAfterCompsTicketDeduced, ticketsVariables.taxes.vatPercent)
    const vatAmount = priceAfterCompsTicketDeduced - priceAfterVatDeduced
    const totalTaxesPercent = ticketsVariables.taxes.ticketFeePercent + ticketsVariables.taxes.creditCardFeePercent + ticketsVariables.taxes.otherTaxesPercent
    const otherTaxesAmount = priceAfterVatDeduced * (totalTaxesPercent/100)
    const ticketsTotalNetIncome = priceAfterVatDeduced - otherTaxesAmount

    return { ticketsTotalGrossIncome, ticketsTotalNetIncome, compsTicketTotalAmount, vatAmount, otherTaxesAmount, totalTicketsQuantity }
}

export const computeSponsorsAmount = (sponsors: ShowSponsor[], isSponsored: boolean) => {
    const sponsorsTotalAmount = sponsors.reduce((total, sponsor ) => total + sponsor.amount, 0)
    const sponsorshipAmount = isSponsored ? sponsorsTotalAmount : 0
    return sponsorshipAmount
}
export const computeOtherIncomesAmount = (otherIncomes: ShowOtherIncome[], hasOtherIncomes: boolean) => {
    const otherIncomesTotalAmount = otherIncomes.reduce((total, otherIncome ) => total + otherIncome.amount, 0)
    const otherIncomesAmount = hasOtherIncomes ? otherIncomesTotalAmount : 0
    return otherIncomesAmount
}