import Tabs from "../../../components/tabs-menu/Tabs"
import { useAppSelector } from "../../../utils/hooks/reduxTypedHooks"
import { OfferStatus } from "../../../utils/models/offer.model"
import OffersTab from "./OffersTab"

//! styles in admin/admin-panel-screens/offers/offers.css

const ArtistOffers = () => {
    const offers = useAppSelector(state => state.offers)

    const ongoingStatus = [OfferStatus.WaitingArtist]
    const inProcessStatus = [OfferStatus.Pending, OfferStatus.RequestEdition, OfferStatus.PromotorTrusteeCheck]
    const pastStatus = [OfferStatus.AdminDenied, OfferStatus.Deprecated, OfferStatus.Withdrawn, OfferStatus.AdminAccepted, OfferStatus.ArtistAccepted, OfferStatus.ArtistDenied]
    const ongoingOffers = offers.filter(offer => ongoingStatus.includes(offer.status))
    const inProcessOffers = offers.filter(offer => inProcessStatus.includes(offer.status))
    const pastOffers = offers.filter(offer => pastStatus.includes(offer.status))

    return(
        <Tabs activeTab='Ongoing'>
            <div option-data='Ongoing'>
                <OffersTab offers={ongoingOffers} ongoing title="Ongoing" />
            </div>
            <div option-data='In process'>
                <OffersTab offers={inProcessOffers} title="In Process" />
            </div>
            <div option-data='past'>
                <OffersTab offers={pastOffers} title="Past" />
            </div>
        </Tabs>
    )
}

export default ArtistOffers