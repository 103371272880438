import { Link } from "react-router-dom"
import { ROUTE_ARTISTS } from "../../../routes/routes.const"
import { ArtistWithTours } from "../../../utils/models/artists.model"
import moment from 'moment'

import styles from './touring.module.css'
import { formatDisplayableNameToUrl } from "../../../utils/artists.util"

const CardItem = ({touringArtist}: {touringArtist: ArtistWithTours}) => {

    const artistBookingUrl = `${ROUTE_ARTISTS}/${formatDisplayableNameToUrl(touringArtist.displayableName)}/tours#booking`

    return (
        <Link to={artistBookingUrl}><div className={`${styles.cardItem} clickable hover-medium-scale-shadow`}>
            <div>
                <div className={styles.cardImageDiv}>
                    <div className='overflow-clip'>
                        <img src={`${touringArtist.image}`} className="image-cover" alt="artist logo" />
                    </div>
                </div>
                <div className={styles.cardTextDiv}>
                    <h3 className="margedBot10px white font1p3rem">{touringArtist.displayableName}</h3>
                    <div className={styles.itemInfosContainer}>
                         
                        <div className={styles.cardDatesDiv}>
                            <p>{moment(new Date(touringArtist.tours[0].from)).format('MMMM Do YYYY')}</p>
                            <p>{moment(new Date(touringArtist.tours[0].to)).format('MMMM Do YYYY')}</p>
                        </div>
                    </div>
                    <div className="flex-only justify-center">
                        <button className="button touring-card__button button--round">Book Artist</button>
                    </div>
                </div>
            </div>
        </div></Link>
    )
}

export default CardItem