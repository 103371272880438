import { NavLink } from "react-router-dom"
import { ClientTask } from "../../../utils/models/client.model"

const NotificationItem = ({ notification }: { notification: ClientTask}) => {

    return(
        <div className="notification__item-div">
            <p>{notification.message}</p>
            <NavLink to={notification.linkPath}>View</NavLink>
        </div>
    )
}

export default NotificationItem