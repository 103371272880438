import { Flex, Row } from "../Containers";

export const FormInput = ({
    title,
    children,
    prefix,
    postfix,
    className,
    type,
    placeholder,
    onChange,
    value,
    bordered,
    disabled,
} : 
{
    title: string,
    children?: React.ReactNode,
    prefix?: any,
    postfix?: any,
    className?: string,
    type?: string,
    placeholder?:string,
    bordered?: boolean,
    onChange?: any,
    value?: any,
    disabled?: boolean,

}) => <div className={`flex allwidth alignstart ${className||''}`}>
    <div className="bold"> { title } </div>
    
    {
        children 
        || <Row className={bordered ? "bordered" : ''}>
            {
                prefix ? <div className="flex" style={{marginBottom: '1.5%'}}>{ prefix }</div> : null
            }
            
            <input placeholder={placeholder} className="app-input allwidth" type={type||'text'} onChange={onChange} value={value} disabled={disabled} {...(type==='checkbox' ? {checked: value} : {})}/>
            {
                postfix ? <><div className="justifystart " >{ postfix }</div>&nbsp;</> : null
            }
        </Row>
    }
</div>