
import { AppColor } from "../../../../utils/models/common.model"
import styles from "./checkbox-mui.module.css"

const CheckboxMUI = ( { label, checked, onChange, disabled, color}: {
    label: string,
    checked: boolean | undefined,
    onChange: any,
    disabled?: boolean,
    color?: AppColor
}) => {

    let customColorClass = ''
    switch(color) {
        case "green":
            customColorClass = styles.green
            break
        case "main":
            customColorClass = styles.main
            break
        case "red":
            customColorClass = styles.red
            break
        case "orange":
            customColorClass = styles.orange
            break
        case "lightblue":
            customColorClass = styles.lightblue
            break
        case "gray":
            customColorClass = styles.gray
            break
        default:
            customColorClass = styles.main
    }

    return (
        <div className="allwidth">
                <label className={styles.container}>
                    <span className={styles.label}>{label}</span>
                    <input 
                        checked={checked}
                        onChange={onChange}
                        className={`mui-checkbox`}
                        disabled={disabled}
                        type={"checkbox"}
                    />
                    <span className={`${styles.checkmark} ${customColorClass}`}></span>
                </label>
        </div>
    )
}

export default CheckboxMUI