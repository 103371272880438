import { useEffect, useState  } from "react"
import { Venue } from "../../../../utils/models/venues.model"
import VenueItem from "./VenueItem"
import styles from "./venues.module.css"

const VenuesList = ( { venues }: { venues: Venue[] }) => {

    const [numberOfVenuesDisplayed, setNumberOfVenuesDisplayed] = useState(20)
  
    useEffect(() => {
        setNumberOfVenuesDisplayed(20)
    }, [venues])
    
    const handleScroll = (event: any) => {
        const { scrollHeight, clientHeight, scrollTop } = event.target

        // add more to display when we reach bottom 10% of div
        if (scrollTop + clientHeight >= ((100 - 10) / 100) * scrollHeight) {
            setNumberOfVenuesDisplayed(prevAmount => prevAmount + 10)
		}
    }

    
    const displayedVenues = venues.slice(0, numberOfVenuesDisplayed)

    return (
        <div className={`grid-4-col ${styles.scrollDiv}`} onScroll={handleScroll}>
            {displayedVenues.map((venue, index) => <VenueItem key={index} venue={venue} />)}
        </div>
    )
}

export default VenuesList