import { Oval } from "react-loader-spinner"

const LoadingOval = ( {height} : {height?: number}) => {

    const _height = height ?? 40

    return (
        <Oval
            height={_height}
            width={_height}
            color="var(--main-color)"
            secondaryColor="var(--main-color)"
        />
    )
}

export default LoadingOval