import { Component } from "react";
// import { connect } from "react-redux";
import Tab from "./Tab";
import './tabs.css';

class Tabs extends Component {
  state = {}

  onClickTabItem = activeTab => (
    this.props.setActive
    ? this.props.setActive(activeTab)
    : this.setState({ activeTab })
  );

  render() {
    const {
      onClickTabItem,
      props: {
        children,
        activeTab: activeTabProp,
        // considered controlled if `setActive` is provided
        setActive,
        widthAuto,
      },
      state: {
        activeTab: activeTabState,
      }
    } = this;

    const activeTab = (
      setActive
      ? activeTabProp
      : activeTabState||activeTabProp
    )

    const isOnlyChild = !Array.isArray(children)
    const _children = isOnlyChild ? [children] : children

    return (
      <div className="tabs">
        <ol className={`tab-list ${this.props.noContainer ? "" : "main-container"}`}>
          {
           _children.map((child) => {
              const { 'option-data': label, 'data-icon': icon } = child.props;

              return (
                <Tab
                  activeTab={activeTab}
                  key={label}
                  label={label}
                  onClick={onClickTabItem}
                  icon={icon}
                  widthAuto={widthAuto}
                />
              );
            })
          }
        </ol>
        <div className="tab-content">
          {_children.map((child) => {
            if (child.props['option-data'] !== activeTab) return undefined;
            return child.props.children;
          })}
        </div>
      </div>
    );
  }
}
    


export default Tabs;