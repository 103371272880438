import InputSpruha from "../../../../../components/form/spruha/InputSpruha"
import ReactFlagsSelect from "react-flags-select";
import { useState, forwardRef } from "react";
import SelectInput from "../../../../../components/form/form-components/SelectInput";
import { TouringShowDurationsList, TouringShowTypesList } from "../../../../../utils/models/touring.model";
import DatePicker from "react-datepicker";
import { HiOutlineTicket } from "react-icons/hi";
import { Flex, Row } from "../../../../../components/Containers";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { ShowTicket } from "../../../../../utils/models/offer.model";
import useSetSnack from "../../../../../utils/hooks/useSetSnack";
import { ArtistRequest, ArtistRequestCreateObject } from "../../../../../utils/models/requests.model";
import { useAppDispatch, useAppSelector } from "../../../../../utils/hooks/reduxTypedHooks";
import useFetchApiAuth from "../../../../../utils/hooks/useFetchApiAuth";
import LabelInput from "../../../../../components/form/mui/label-input/LabelInput";
import { requestsActions } from "../../../../../store/requestsSlice";

const ArtistRequestTouring = ( { artistName, close, request }: { artistName: string, close: any, request?: ArtistRequest }) => {
    const today = new Date()
    today.setHours(0,0,0,0)

    const requestFrom = request ? new Date(request.from) : undefined
    const requestTo = request ? new Date(request.to) : undefined
    const inputableShowDuration = request?.showDuration ? { value: request.showDuration, label: <div className="flex row justifystart"><b>{request.showDuration} Minutes</b></div> } : null
    const inputableShowType = request?.showType ? {value: request.showType, label: <div><b>{request.showType}</b></div>} : null
    
    const [isPosting, setIsPosting] = useState(false)
    const [formInputs, setFormInputs] = useState({
        country: request?.country ?? '',
        city: request?.city ?? '',
        capacity: request?.capacity ?? undefined as undefined | number,
        dateFrom: requestFrom ?? today,
        dateTo: requestTo ?? today,
        showDuration: inputableShowDuration ?? undefined,
        showType: inputableShowType ?? undefined,
        tickets: request?.tickets ?? [{}] as ShowTicket[],
        amount: request?.amount ?? undefined as undefined | number,
        comment: request?.comment ?? ''
    })
    console.log(formInputs)
    const setSnack = useSetSnack()
    const fetchApiAuth = useFetchApiAuth()
    const client = useAppSelector(state => state.client)
    const promotor = client?.promotor
    const dispatch = useAppDispatch()

    const updateInput = (value: any, inputName: keyof typeof formInputs) => {
        setFormInputs(prevInputs => ({...prevInputs, [inputName]: value}))
    }

      // add new ticket row
      const addShowTickets = () => {
        const { tickets } = formInputs
        const upTickets = [...tickets, {}]
        updateInput(upTickets, 'tickets')
    }

    // update ticket row
    const updateTickets = (ticketsIndex:number, _props:any) => {
        let { tickets } = formInputs;
        const upTickets = tickets.map((t:any, ti:number) => (
            (ti === ticketsIndex)
            ? {...t, ..._props}
            : t
        ))
        updateInput(upTickets, 'tickets')
    }

    // remove ticket row
    const removeShowTickets = (ticketsIndex:number) => {
        const { tickets } = formInputs
        const upTickets = tickets.filter((t:any, ti:number) => ti !== ticketsIndex);
        updateInput(upTickets, 'tickets')
    }

    const showDurationsList = TouringShowDurationsList.map(duration => ({ value: duration, label: <div className="flex row justifystart"><b>{duration} Minutes</b></div> }))
    const showTypesList = TouringShowTypesList.map(showtype => ({value: showtype, label: <div><b>{showtype}</b></div>}))

    const handleSubmit = async () => {
        setIsPosting(true)

        // Form Validation
        if(!promotor) {
            setSnack({type: 'warning', content: 'Only a Kisum Promotor can make a request'})
            setIsPosting(false)
            return 
        }

        const { country, city, capacity, amount, dateFrom, dateTo, showDuration, showType, tickets} = formInputs
        if(!country || !city || !capacity || !amount || !dateFrom || !dateTo || !showDuration || !showType ) {
            setSnack({type: 'warning', content: 'Please fill all the fields.'})
            setIsPosting(false)
            return 
        }

        const fromDateString = formInputs.dateFrom.toDateString()
        const toDateString = formInputs.dateTo.toDateString()
        const newRequest: ArtistRequestCreateObject = {
            country,
            city,
            capacity,
            from: fromDateString,
            to: toDateString,
            showDuration: showDuration.value,
            showType: showType.value,
            tickets,
            amount,
            comment: formInputs.comment,
            artistName,
            promotorId: promotor.id
        }

        // await post new request
        const { error, response: createdRequest } = await fetchApiAuth({
            method: "POST",
            route: "add-showrequest",
            body: {showRequest: {...newRequest}}
        })

        console.log('response', createdRequest)

        // snack
        setSnack(
            error
            ? {
                type: 'error', 
                content: error.toString()
            }
            : {
                type: 'success',
                content: "The Request has been successfully made."
            })

        //update Redux state
        if(!error){
            dispatch(requestsActions.push(createdRequest))
            close()
        }

        setIsPosting(false)
    }

    // custom input for date picker
    // @ts-ignore
    const DatePickerCustomInput = forwardRef(({ value, onClick }, ref) => (
        // @ts-ignore
        <button className="button button--primary" onClick={onClick} ref={ref}>
          {value}
        </button>
      ))

    return (
        <div className="margedBot30px margedTop10px main-container">
            <div className="app-card">
                <div className="leftalign allwidth margedBot50px">
                    <h3 className="margedBot30px">For {artistName}</h3>

                    <fieldset disabled={request !== undefined}>
                        <div className="margedBot30px">
                            <label className="label-ts">Requested Country</label>
                            <ReactFlagsSelect
                                searchable
                                onSelect={e => updateInput(e, 'country')} 
                                selected={formInputs.country} 
                                className="allwidth"
                                selectButtonClassName="flags-selection-button"
                            />
                        </div>

                        <div className="tour-request-grid">
                            <div>
                                <label className="label-ts">From Date</label>
                                <DatePicker
                                    customInput={<DatePickerCustomInput />} 
                                    selected={formInputs.dateFrom} 
                                    onChange={(selectedDate:Date) => {
                                        selectedDate.setHours(0,0,0,0)
                                        updateInput(selectedDate, 'dateFrom')
                                    }} 
                                />
                            </div>
                            <div>
                                <label className="label-ts">To Date</label>
                                <DatePicker
                                    customInput={<DatePickerCustomInput />} 
                                    selected={formInputs.dateTo} 
                                    onChange={(selectedDate:Date) => {
                                        selectedDate.setHours(0,0,0,0)
                                        updateInput(selectedDate, 'dateTo')
                                    }}
                                    minDate={formInputs.dateFrom} 
                                />
                            </div>
                            <InputSpruha 
                                title="City"
                                value={formInputs.city}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateInput(e.target.value, 'city')}
                            />
                            <InputSpruha 
                                title="Total Capacity Preview"
                                value={formInputs.capacity}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateInput(+e.target.value, 'capacity')}
                                type="number"
                            />
                            <SelectInput 
                                options={showDurationsList}
                                title='Show Duration'
                                value={formInputs.showDuration}
                                onChange={(e:any) => {updateInput(e, 'showDuration')}}
                                noBold
                                showRawValue
                            />
                            <SelectInput 
                                options={showTypesList}
                                title='Show Type'
                                value={formInputs.showType}
                                onChange={(e:any) => {updateInput(e, 'showType')}}
                                noBold
                                showRawValue
                            />
                        </div>

                        {/* Tickets */}
                        <Row className='margedTop30px margedBot30px'>
                            <HiOutlineTicket size={35} />
                            <Flex className="marged-l alignstart allwidth">
                                <div className="title main-color">Tickets</div> 
                                <Flex className="allwidth">
                                    <table className="xsmall allwidth">
                                        <thead>
                                            <tr>
                                                <th>Name</th>
                                                <th>Quantity</th>
                                                <th>Gross Price</th>
                                                <th>Remove</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            formInputs.tickets?.length
                                            ? formInputs.tickets.map((ticket:any, sti:number) => <tr key={sti}>
                                                <td>
                                                    <div  className="margedRight20px">
                                                        <LabelInput
                                                            title='Name'
                                                            value={ticket.name}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                const name = e.target.value;
                                                                const sameName = formInputs.tickets.find((_t:any)=>_t.name===name);
                                                                if (sameName)
                                                                    setSnack({type: 'error', content: <span>A ticket type named <b>{name}</b></span>})    
                                                                else 
                                                                    updateTickets(sti, { name });
                                                            }}
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div  className="margedRight20px">
                                                        <LabelInput
                                                            title='Quantity'
                                                            value={ticket.quantity}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateTickets(sti, { quantity: +e.target.value })}
                                                            type='number'
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div  className="margedRight20px">
                                                        <LabelInput
                                                            title='Gross price'
                                                            value={ticket.grossPrice}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateTickets(sti, { grossPrice: +e.target.value })}
                                                            type='number'
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <AiOutlineCloseCircle
                                                        size={25}
                                                        onClick={() => removeShowTickets(sti)}
                                                        fill={'tomato'}
                                                        className='clickable'
                                                    />
                                                </td>
                                            </tr>)
                                            : null
                                        }
                                    
                                            <tr>
                                                <td colSpan={4}>
                                                    <Flex>
                                                        <button className='xsmall button button--primary button--round flex' onClick={() => addShowTickets()}><Row>
                                                            <span className="xlarge" style={{lineHeight: '20px'}}>+</span> &nbsp; ADD TICKETS</Row>
                                                        </button>
                                                    </Flex>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Flex>
                            </Flex>
                        </Row>

                        <div className="tour-request-grid">
                            <InputSpruha 
                                title="Offer net amount"
                                value={formInputs.amount}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateInput(+e.target.value, 'amount')}
                                type="number"
                            />
                            <InputSpruha 
                                title="Comment"
                                value={formInputs.comment}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateInput(e.target.value, 'comment')}
                                placeholder="Enter any additional comment or request..."
                            />
                        </div>
                    </fieldset>

                </div>

                <Flex row>
                    <button onClick={close} className="button button--ghost button--round margedRight50px">{request ? "Close" : "Cancel"}</button>
                    {request === undefined && <button onClick={handleSubmit} disabled={isPosting} className="button button--primary button--round">{isPosting ? "Requesting..." : "Request"}</button>}
                </Flex>
            </div>
        </div>
    )
}

export default ArtistRequestTouring