import Drawer from '@mui/material/Drawer'
import IconButton from '@mui/material/IconButton'
import { useState } from 'react'

import IconifyIcon from '../../../components/Icon/IconifyIcon'
import { PromotorEvent, PromotorEventCreateObject } from '../../../utils/models/promotors.model'
import AgendaEventForm from './AgendaEventForm'


const AddEventSidebar = ({ drawerWidth, addEventSidebarOpen, selectedEvent, selectedDates, closeAddEventSidebar, handleAddEvent, handleUpdateEvent, handleDeleteEvent }: {
    drawerWidth: number,
    addEventSidebarOpen: boolean,
    selectedEvent?: PromotorEvent,
    selectedDates?: {from: Date, to: Date, allDay: boolean, startTime: string, endTime: string},
    closeAddEventSidebar: () => void,
    handleAddEvent: (event: PromotorEventCreateObject) => Promise<void>,
    handleUpdateEvent: (updates: any) => Promise<void>, 
    handleDeleteEvent: (event: PromotorEvent) => Promise<void>, 
}) => {

  const [resetingValues, setIsResetingValues] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)

  const handleClose = () => {
      setIsResetingValues(true)
      closeAddEventSidebar()
  }

  const onDeleteEvent = async (event: PromotorEvent) => {
    setIsDeleting(true)
    await handleDeleteEvent(event)
    setIsDeleting(false)
    handleClose()
  }

  const isEditing = selectedEvent !== undefined


  return (
    <Drawer
      anchor='right'
      open={addEventSidebarOpen}
      onClose={handleClose}
      ModalProps={{ keepMounted: true }}
      sx={{ '& .MuiDrawer-paper': { width: ['100%', drawerWidth], borderLeft: 'var(--input-border)' } }}
    >
        <div className="main-container">
            <div className='flex-only space-between' style={{paddingTop: "20px"}}>

                <p className='font20 title-color'>{isEditing ? 'Update Event' : 'Add Event'}</p>

                <div className='flex-align-center'>
                    {selectedEvent !== undefined 
                        &&  <IconButton
                                size='small'
                                disabled={isDeleting}
                                onClick={() => onDeleteEvent(selectedEvent)}
                                sx={{ color: 'var(--text-color)', backgroundColor: "#41465B", mr: 1 }}
                            >
                                <IconifyIcon icon='tabler:trash' fontSize='1.25rem' />
                            </IconButton>}
                    <IconButton size='small' onClick={handleClose} sx={{ color: 'var(--text-color)', backgroundColor: "#41465B" }}>
                        <IconifyIcon icon='tabler:x' fontSize='1.25rem' />
                    </IconButton>
                </div>
            </div>

          <AgendaEventForm 
            handleAdd={handleAddEvent} 
            handleUpdate={handleUpdateEvent}
            resetingValues={resetingValues}
            setIsResetingValues={setIsResetingValues}
            event={selectedEvent}
            selectedDates={selectedDates}
            close={handleClose}
          />

      </div>
    </Drawer>
  )
}

export default AddEventSidebar