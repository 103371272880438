import { useState } from "react"
import { BiLayerPlus } from "react-icons/bi"
import { Flex, Row } from "../../../../../components/Containers"
import { filterArtistAllFields } from "../../../../../utils/artists.util"
import useFilter from "../../../../../utils/hooks/useFilter"
import { Artist } from "../../../../../utils/models/artists.model"
import ArtistForm from "./ArtistForm"
import ArtistItemRow from "./ArtistItemRow"

const ArtistsEditList = ( { manager, artists, isArchivedList }: { manager?: boolean, artists: Artist[], isArchivedList?: boolean }) => {
    const [activeTab, setActiveTab] = useState('list')

    const handleCloseForm = () => setActiveTab('list')

    const { filterInput, FilterUI} = useFilter('Artist name, genre, country, type...')

    const filteredArtists = filterArtistAllFields(artists, filterInput)
    
    const renderArtistRow = (artist: Artist, artistind: number) => <ArtistItemRow key={artistind} artist={artist} isArchivedList={isArchivedList} />

    return (
        <Flex flex={10} className='allwidth'>
            {activeTab === 'create'
            ?  <ArtistForm manager={manager} goBack={handleCloseForm} />
            :   <div className="main-container margedBot100px">
                    <h3 className="page-title  margedTop30px">{isArchivedList ? "Archived" : "Active"} Artists</h3>
                    
                    <Row className="allwidth margedBot30px">
                        <Flex row flex={isArchivedList ? 10 : 8}>
                            {FilterUI}
                        </Flex>
                        {isArchivedList
                        ?   <></>
                        :   <Flex flex={2}>
                                <button 
                                    className="flex row button button--primary uppercase"
                                    onClick={() => setActiveTab('create')}
                                >
                                    <BiLayerPlus size={25} />
                                    &nbsp;
                                    New Artist
                                </button>
                            </Flex>}
                    </Row>

                    <Flex row flex={10} className="justifystart wrap spaceevenly allwidth ">
                        {filteredArtists    // client artists
                        ? <table className="app-table">
                            <thead>
                                <tr>
                                    <th>Image</th>
                                    <th className="leftalign">Name</th>
                                    <th>Type</th>
                                    <th>Country</th>
                                    <th>Promotors</th>
                                    <th>Contracts</th>
                                    <th className="table-option-cell">Add touring</th>
                                    <th className="table-option-cell">Create Contract</th>
                                    <th className="table-option-cell">{isArchivedList ? "Delete" : "Edit"}</th>
                                    <th className="table-option-cell">View</th>
                                    <th className="table-option-cell">{isArchivedList ? "Unarchive" : "Archive"}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredArtists.map(renderArtistRow)}
                            </tbody>

                        </table>
                        : <p className="grey">You have no artists for now.</p>}                              
                    </Flex>
                </div>}
        </Flex>
    )
}

export default ArtistsEditList