import { useEffect, useState } from "react"
import PageLoading from "../../../../../components/loading/PageLoading"
import { feedbacksActions } from "../../../../../store/feedbacksSlice"
import { useAppDispatch, useAppSelector } from "../../../../../utils/hooks/reduxTypedHooks"
import useFetchApiAuth from "../../../../../utils/hooks/useFetchApiAuth"
import useSetSnack from "../../../../../utils/hooks/useSetSnack"
import FeedbackItemRow from "./FeedbackItemRow"

const AdminFeedbacks = () => {

    const [isFetching, setIsFetching] = useState(false)
    const feedbacks = useAppSelector(state => state.feedbacks)
    const dispatch = useAppDispatch()
    const fetchApiAuth = useFetchApiAuth()
    const setSnack = useSetSnack()

    useEffect(() => {
        const fetchFeedbacks = async () => {
            setIsFetching(true)
            const { error, response: feedbacksList } = await fetchApiAuth({
                method: 'POST',
                route: 'feedback/list',
            })

            setSnack(
                error
                ? {
                    type: 'error', 
                    content: error.toString()
                }
                : {
                    type: 'success',
                    content: 'Feedbacks list fetched successfully.'
                }
            )

            if(!error)
                dispatch(feedbacksActions.set(feedbacksList))

            setIsFetching(false)
            }
        
        // only fetch feedbacks once per session
        if(feedbacks === null) {
            fetchFeedbacks()
        }
    }, [])

    if(isFetching) {
        return (<PageLoading />)
    }

    return (
        <div className='main-container margedBot100px paddedTop30px'>
            <h3 className="page-title">Feedbacks</h3>

            {feedbacks?.length 
            ?   <table className="app-table ">
                    <thead>
                        <tr>
                            <th className="leftalign">Date</th>
                            <th className="leftalign">User Email</th>
                            <th className="leftalign">Preview</th>
                            <th>Actions</th>
                        </tr>
                    </thead>

                    <tbody>
                        {feedbacks.map((feedback, index) => <FeedbackItemRow key={index} feedback={feedback} />)}
                    </tbody>
                </table>
            :   <p className="grey">No feedbacks for now.</p>}
        </div>
    )
}

export default AdminFeedbacks