import IconifyIcon from "../../components/Icon/IconifyIcon"

const WhyItem = ({ title, text, iconName }: { title: string, text: string, iconName: string }) => {

    return (
        <div className="landing-why-item">
            <IconifyIcon icon={iconName} color="var(--main-color)" fontSize={50} />
            <p style={{fontSize: "32px", fontWeight: "600"}}>{text}</p>
            <p className="main-color" style={{fontSize: "42px", fontWeight: "700"}}>{title}</p>
        </div>
    )
}

export default WhyItem