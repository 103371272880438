import { DialogProps } from "../../utils/models/dialog-action.model";
import './dialog.css';

const actionsTypeClass = {
    cancel: 'error-button',
    ok: 'success'
}

const Dialog = (dialog:DialogProps) => {

    const { 
        visible,
        title,
        closable,           // default: true
        backdropClose,      // default: true
        actions,
        children,
        content,
        onClose,
        dialogCustomStyle,
        notAllSpace

    } = dialog;//: DialogProps


    const flex = (
        actions?.length
        ? ['flexdot9','flexdot1']
        : ['flexfull','']
        
    );

    const closeDialog = () => closable !== false && onClose();
    const closeDialogBack = () => backdropClose !== false && onClose();

    return (
        visible
        ? <>
            <div className="backdrop" onClick={closeDialogBack}></div> 
            
            <div className="dialog" style={dialogCustomStyle}>
                {
                    closable !== false
                    && <button className="close" onClick={closeDialog}></button>
                }
                { title 
                &&  <div className="modal-header margedBot20px">
                        <div className="main-container">
                            <h3 className="modal-title">{title}</h3>
                        </div>
                    </div>}

                <div className={`flex ${notAllSpace ? '' : 'allspace'}`}>
                    <div className={`flex allwidth ${flex[0]}`}>
                        { children || content }
                    </div>
                    <div className={`flex allwidth ${flex[1]}`}>
                        <div className="flex row width90 spaceevenly">
                        {
                            actions?.length
                            && actions.map(
                                (action, actionIndex) => <button 
                                    key={`dial_act${actionIndex}`} 
                                    className={`app-button ${actionsTypeClass[action.variant as keyof typeof actionsTypeClass]||''}`}
                                    onClick={action.onClick || ((action.variant==='cancel') ? closeDialog : undefined)}
                                >
                                    { action.title }
                                </button>
                            )
                        }
                        </div>
                    </div>
                </div>
                
            </div>
        </>
        : null
    )
}

export default Dialog;