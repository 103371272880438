import moment from "moment"
import computePnlData from "../../../utils/hooks/offer/computePnlDatas"
import useViewShowPNL from "../../../utils/hooks/offer/useViewShowPNL"
import {  AmountType, OfferShow } from "../../../utils/models/offer.model"
import { countries } from "../../../utils/models/touring.model"
import { plural, pnlAmountFormatter } from "../../../utils/utils"
import computeBreakEvenPoint from "../../../utils/hooks/offer/computeBreakEvenPoint"

const OfferPnlItemRow = ({ show, artistName }: { show: OfferShow, artistName: string }) => {

    const { pnlsModal, openPnlsButton, handleViewPNL } = useViewShowPNL({show, artistName})
    const { totalTickets } = computePnlData({show})

    const { hasBreakEvenPoint, breakEvenIndex, ticketsSoldArray } = computeBreakEvenPoint({show})

    const isAmountDeal = show.amountType === AmountType.Deal

    const showDate = moment(new Date(show.date)).format('MM/D/YYYY')
    const showCountry = countries[show.country]
    const showVenue = show.venue?.name
    const showAmount = isAmountDeal ? "Deal" : `${show.localCurrency} ${pnlAmountFormatter(show.amount)}` 

    return (
        <>

            {pnlsModal}

            <tr className="fw400">
                <td className="leftalign">
                    <p className="clickable" onClick={handleViewPNL}>{artistName}</p>
                </td>
                <td className="leftalign">
                    <p className="clickable" onClick={handleViewPNL}>{showCountry}</p>
                </td>
                <td className="leftalign">
                    <p className="clickable" onClick={handleViewPNL}>{showDate}</p>
                </td>
                <td className="leftalign">
                    <p className="clickable" onClick={handleViewPNL}>{showVenue}</p>
                </td>
                <td className="leftalign">
                    <p className="clickable" onClick={handleViewPNL}>
                        <b>{showAmount}</b>
                    </p>
                </td>
                <td className="leftalign">
                    <p className="clickable" onClick={handleViewPNL}>
                        {hasBreakEvenPoint
                        ?   <span><b className="green-color">{breakEvenIndex}%</b></span>
                        :   <span>No Break Even point</span>}
                    </p>
                </td>
                <td className="leftalign">
                    <p className="clickable" onClick={handleViewPNL}>
                        {hasBreakEvenPoint
                        ?   <span><b>{ticketsSoldArray[breakEvenIndex]} {plural('ticket', ticketsSoldArray[breakEvenIndex])}</b></span>
                        :   <span>No Break Even point</span>}
                    </p>
                </td>
                <td className="leftalign">
                    <p className="clickable" onClick={handleViewPNL}>{totalTickets.quantity}</p>
                </td>
                <td className="leftalign">
                    <p className="clickable" onClick={handleViewPNL}>{show.localCurrency} {pnlAmountFormatter(totalTickets.sales)}</p>
                </td>
                <td>
                    {openPnlsButton}
                </td>
            </tr>
        </>
    )
}

export default OfferPnlItemRow