import moment from "moment"
import ReactFlagsSelect from "react-flags-select"
import Accordion from "../../../../components/accordion/Accordion"
import { Flex, Row } from "../../../../components/Containers"
import { OfferShow } from "../../../../utils/models/offer.model"
import { ls } from "../../../../utils/utils"
import styles from "./offers.module.css"
import InputSpruha from "../../../../components/form/spruha/InputSpruha";

const ViewOfferShowItem = ({ show, index }: {show: OfferShow, index: number}) => {

    const {
        country, 
        date,
        eventName,
        showDuration,
        showTypes,
        amount,
        venue,
        doorsOpen,
        onStage,
        curfew,
        minAge,
        airFare,
        comments,
        visaRequired,
        broadcasted,
        sponsored,
        sponsors,
        hotel,
        nearestAirport,
        tickets,
        isAmountNet,
        taxPercent,
    } = show

    const hasOneVenueInfo = ((venue.address !== undefined) || (venue.city !== undefined) || (venue.isOutdoor !== undefined) || (venue.name !== undefined)|| (venue.phone !== undefined) || (venue.totalCapacity !== undefined) || (venue.website !== undefined) )
    
    //todo remove tickets && and other mandatory fields checks when offers in database are cleaned out
    const isThereTickets = tickets && (tickets?.length > 1 || (!(tickets[0]?.name === undefined || tickets[0]?.name === '') || (tickets[0]?.quantity !== undefined) || (tickets[0]?.grossPrice !== undefined)))

    return (
        // @ts-ignore
        <Accordion 
            className='allwidth'
            index={index+1}
            title={(isActive:any) => <Row className="allwidth spacebetween">
                <ReactFlagsSelect
                    searchable={false}
                    disabled
                    optionsSize={1}
                    selected={country}
                    selectedSize={30}
                    selectButtonClassName="flags-selection-button hidden-dropdown bold"
                    onSelect={()=>false}
                />
                <div className="title">
                    { moment(new Date(date)).format('DD MMM YYYY') }
                </div>
                <Flex className="justifyend">
                    <b>${ls(amount)}</b>
                    {isAmountNet 
                    ?   "(Net)"
                    :   `(Gross with ${taxPercent}% Tax)`}
                </Flex>
            </Row>}
            content={
                <div>
                    
                    <div className={`margedBot20px ${styles.viewGrid}`}>
                        <InputSpruha 
                            title="Event Name"
                            value={eventName}
                            disabled
                            onChange={() => {}}
                        />
                        <InputSpruha 
                            title='Show Duration'
                            value={`${showDuration} Minutes`}
                            disabled
                            onChange={() => {}}
                        />
                        <InputSpruha 
                            title='Show Type'
                            value={showTypes.join(' - ')}
                            disabled
                            onChange={() => {}}
                        />
                    </div>

                    {hasOneVenueInfo &&
                    <div className="margedBot30px">
                        <h3 className="bold xlarge capitalize main-color text-shadow margedBot15px">Venue</h3>
                        <div className={styles.viewGrid}>
                            {venue.name && 
                            <InputSpruha 
                                title="Name"
                                value={venue.name}
                                disabled
                                onChange={() => {}}
                            />}
                            {venue.city && 
                            <InputSpruha 
                                title="City"
                                value={venue.city}
                                disabled
                                onChange={() => {}}
                            />}
                            {venue.isOutdoor !== undefined && 
                            <InputSpruha 
                                title="Outdoor / Indoor"
                                value={venue.isOutdoor ? 'Outdoor' : 'Indoor'}
                                disabled
                                onChange={() => {}}
                            />}
                            {venue.address && 
                            <InputSpruha 
                                title="Address"
                                value={venue.address}
                                disabled
                                onChange={() => {}}
                            />}
                            {venue.phone &&
                            <InputSpruha 
                                title="Phone"
                                value={venue.phone}
                                disabled
                                onChange={() => {}}
                            />}
                            {venue.website &&
                            <InputSpruha 
                                title="Website"
                                value={venue.website}
                                disabled
                                onChange={() => {}}
                            />}
                            {venue.totalCapacity &&
                            <InputSpruha 
                                title="Capacity"
                                value={venue.totalCapacity}
                                disabled
                                onChange={() => {}}
                            />}
                            {venue.pastShows &&
                            <InputSpruha 
                                title="Past Shows"
                                value={venue.pastShows}
                                disabled
                                onChange={() => {}}
                            />}
                        </div>
                    </div>}

                    <div className="margedBot30px">
                        <h3 className="bold xlarge capitalize main-color text-shadow margedBot15px">Terms</h3>
                        <div className={styles.viewGrid}>
                            {doorsOpen &&
                            <InputSpruha 
                                title="Doors open"
                                value={doorsOpen}
                                disabled
                                onChange={() => {}}
                            />}
                            {onStage &&
                            <InputSpruha 
                                title="Requested Set Time"
                                value={onStage}
                                disabled
                                onChange={() => {}}
                            />}
                            {curfew &&
                            <InputSpruha 
                                title="Curfew"
                                value={curfew}
                                disabled
                                onChange={() => {}}
                            />}
                            {minAge &&
                            <InputSpruha 
                                title="Visitor Min Age"
                                value={minAge}
                                disabled
                                onChange={() => {}}
                            />}

                            {airFare &&
                            <InputSpruha 
                                title="Flight Contribution"
                                value={airFare}
                                disabled
                                onChange={() => {}}
                            />}
                            {visaRequired !== undefined &&
                            <InputSpruha 
                                title="Visa Application Required ?"
                                value={visaRequired ? 'Yes' : 'No'}
                                disabled
                                onChange={() => {}}
                            />}
                            <InputSpruha 
                                title="Broadcasted ?"
                                value={broadcasted ? 'Yes' : 'No'}
                                disabled
                                onChange={() => {}}
                            />
                            <InputSpruha 
                                title="Sponsored ?"
                                value={sponsored ? 'Yes' : 'No'}
                                disabled
                                onChange={() => {}}
                            />
                            {sponsored &&
                                <>
                                    {sponsors.map((sponsor, index) => <div key={index} className="flex-align-center row margeBot5px">
                                        <InputSpruha 
                                            title="Sponsor Name"
                                            value={sponsor.name}
                                            disabled
                                            onChange={() => {}}
                                        />
                                        <InputSpruha 
                                            title="Sponsor Amount"
                                            value={sponsor.amount}
                                            disabled
                                            onChange={() => {}}
                                        />
                                    </div>)}
                                </>}
                        </div>
                    </div>

                    {(hotel?.name || hotel?.address || nearestAirport) &&
                    <div className="margedBot30px">
                        <h3 className="bold xlarge capitalize main-color text-shadow margedBot15px">Hotel / Transport</h3>
                        <div className={styles.viewGrid}>
                            {hotel?.name &&
                            <InputSpruha 
                                title="Name"
                                value={hotel.name}
                                disabled
                                onChange={() => {}}
                            />}
                            {hotel?.address &&
                            <InputSpruha 
                                title="Address"
                                value={hotel.address}
                                disabled
                                onChange={() => {}}
                            />}
                            {nearestAirport &&
                            <InputSpruha 
                                title="Nearest Airport"
                                value={nearestAirport}
                                disabled
                                onChange={() => {}}
                            />}
                        </div>
                    </div>}

                    {isThereTickets &&
                    <div className="margedBot30px">
                        <h3 className="bold xlarge capitalize main-color text-shadow margedBot15px">Tickets</h3>
                            {tickets.map((ticket, index) => 
                            <div key={index} className={styles.viewGrid}>
                                <InputSpruha 
                                    title="Name"
                                    value={ticket.name}
                                    disabled
                                    onChange={() => {}}
                                />
                                <InputSpruha 
                                    title="Quantity"
                                    value={ticket.quantity}
                                    disabled
                                    onChange={() => {}}
                                />
                                <InputSpruha 
                                    title="Gross Price"
                                    value={ticket.grossPrice}
                                    disabled
                                    onChange={() => {}}
                                />
                            </div>)}
                    </div>}
                    
                    {comments && 
                    <InputSpruha
                        textarea
                        rows={4}
                        title="Comments"
                        value={comments}
                        disabled
                        onChange={() => {}}
                    />}
                </div>
            }
        />
    )
}

export default ViewOfferShowItem