import { useEffect, useState } from "react"
import { Flex, Row } from "../../../../components/Containers"
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks/reduxTypedHooks"
import { PotentialTouringWithArtist, TouringWithArtist } from "../../../../utils/models/touring.model"
import { getPotentialTouringsWithArtistFromTourings, getTouringsWithArtistFromTourings, isTourMatchingDates, LOCATION_EVERYWHERE } from "../../../../utils/touring.util"
import { BiLayerPlus } from "react-icons/bi"
import PotentialTouringForm from "./PotentialTouringForm"
import ItemRow from "./ItemRow"
import { useNavigate } from "react-router-dom"
import { ROUTE_TOURING } from "../../../../routes/routes.const"
import useFetchApiAuth from "../../../../utils/hooks/useFetchApiAuth"
import useSetSnack from "../../../../utils/hooks/useSetSnack"
import { potentialTouringsActions } from "../../../../store/potentialTouringsSlice"
import PageLoading from "../../../../components/loading/PageLoading"
import PotentialTouringsFilters from "./PotentialTouringsFilters"
import { hasOneMatchInTwoArrays } from "../../../../utils/utils"
import { AiOutlineCaretDown, AiOutlineCaretUp } from "react-icons/ai"

const AdminPotentialTourings = () => {
    const today = new Date()
    today.setHours(0,0,0,0)
    const in6months = new Date(today.getTime())
    in6months.setMonth(today.getMonth()+6)

    // Filters Inputs
    const [textFilter, setTextFilter] = useState('')
    const [fromDateFilter, setFromDateFilter] = useState(today)
    const [toDateFilter, setToDateFilter] = useState(in6months) // By default seting the filter as from now until now+6 months
    const [amountFromFilter, setAmountFromFilter] = useState(0)
    const [amountToFilter, setAmountToFilter] = useState(0)
    const [locationsFilter, setLocationsFilter] = useState({isOpen: false, locations: [LOCATION_EVERYWHERE]})

    // Sorting
    const [isDateCreatedSortAscending, setIsDateCreatedSortAscending] = useState(true)
    const [isArtistSortAscending, setIsArtistSortAscending] = useState(false)
    const [isFromDateSortAscending, setIsFromDateSortAscending] = useState(false)
    const [isToDateSortAscending, setIsToDateSortAscending] = useState(false)
    const [isSetLengthSortAscending, setIsSetLengthSortAscending] = useState(false)
    const [isGenreSortAscending, setIsGenreSortAscending] = useState(false)
    const [isPossibleFeeSortAscending, setIsPossibleFeeSortAscending] = useState(false)
    const [isCommentSortAscending, setIsCommentSortAscending] = useState(false)
    const [isIsPotentialSortAscending, setIsIsPotentialSortAscending] = useState(false)

    const [isFetching, setIsFetching] = useState(false)
    const [activeTab, setActiveTab] = useState('list')
    const [computedToursWithArtist, setComputedToursWithArtist] = useState<(TouringWithArtist|PotentialTouringWithArtist)[] | undefined>(undefined)

    const [filteredTourings, setFilteredTourings] = useState<(TouringWithArtist | PotentialTouringWithArtist)[]>([])

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const fetchApiAuth = useFetchApiAuth()
    const setSnack = useSetSnack()
    
    const artists = useAppSelector(state => state.artists)
    const tourings = useAppSelector(state => state.tourings)
    const potentialTourings = useAppSelector(state => state.potentialTourings)

    useEffect(() => {
        const fetchPotentialTourings = async () => {
            setIsFetching(true)
            const { error, response: potentialTouringsList } = await fetchApiAuth({
                method: 'GET',
                route: 'potential-tourings',
            })

            setSnack(
                error
                ? {
                    type: 'error', 
                    content: error.toString()
                }
                : {
                    type: 'success',
                    content: 'Potential tourings list fetched successfully.'
                }
            )

            if(!error)
                dispatch(potentialTouringsActions.set(potentialTouringsList))

            setIsFetching(false)
            }
        
        // only fetch potential tourings once per session
        if(potentialTourings === null) {
            fetchPotentialTourings()
        }
    }, [])

    useEffect(() => {
        const computeToursWithArtist = async () => {
            if(!potentialTourings) { return }
            const [tours, potentialTours] = await Promise.all([getTouringsWithArtistFromTourings(artists, tourings), getPotentialTouringsWithArtistFromTourings(artists, potentialTourings)])
            setComputedToursWithArtist([...tours, ...potentialTours])
        }

        computeToursWithArtist()
    }, [tourings, potentialTourings, artists])

    //Filtering
    const isLocationFilterEverywhere = locationsFilter.locations.includes(LOCATION_EVERYWHERE)

    useEffect(() => {
        const _filteredTourings = computedToursWithArtist ? computedToursWithArtist.filter(touring => {
            const locationFiltering = (isLocationFilterEverywhere || hasOneMatchInTwoArrays(touring.countries, locationsFilter.locations))
            const dateFiltering = isTourMatchingDates(touring.from, touring.to, fromDateFilter, toDateFilter)
            const artistNameFiltering = touring.artist.displayableName.toLowerCase().includes(textFilter.toLowerCase())
    
            const isAmountFilterNull = (amountFromFilter === 0 && amountToFilter === 0)
            const amountFiltering = (isAmountFilterNull || !touring.possibleFee || (touring.possibleFee >= amountFromFilter && touring.possibleFee <= amountToFilter) )
    
            return (locationFiltering  && dateFiltering && artistNameFiltering && amountFiltering)
        }) : []
        
        _filteredTourings.sort((tourA, tourB) => new Date(tourA.from).getTime() - new Date(tourB.from).getTime())

        setFilteredTourings(_filteredTourings)
    }, [computedToursWithArtist, amountFromFilter, amountToFilter, fromDateFilter, toDateFilter, textFilter, locationsFilter.locations, isLocationFilterEverywhere])


    if(isFetching || (computedToursWithArtist === undefined)) {
        return (<PageLoading />)
    }

    const handleCloseForm = () => setActiveTab('list')

    const handleCreatePTouring = () => {
        setActiveTab("create")
    }

    const handleMakeTouringFromPotential = (potentialTouring: PotentialTouringWithArtist) => {
        navigate(ROUTE_TOURING, {state: {toAddTouring: true, potentialTouring}})
    }
    
    
    // Handle manage filters inputs
    //-----------------------------
    const handlePickFromDate = (date: Date) => {
        // puting time at 0:00:00 to have the same day INCLUDED in the fitler
        date.setHours(0, 0, 0, 0)
        setFromDateFilter(date)
    }
    const handlePickToDate = (date: Date) => {
        date.setHours(0, 0, 0, 0)
        setToDateFilter(date)
    }

     //Location filter state management
     const handleToggleLocationItem = (toggledLocation: string) => {

        setLocationsFilter(prevState => {
            const locationIndex = prevState.locations.findIndex(location => location === toggledLocation)

            if(locationIndex === -1){

                const isAddingLocationEverywhere = toggledLocation === LOCATION_EVERYWHERE
                // If we add the 'Everywhere' filter we remove every other filter
                if(isAddingLocationEverywhere){
                    const newLocations = [toggledLocation]
                    return {...prevState, locations: newLocations}
                } else {
                    const newLocations = [...prevState.locations, toggledLocation]
            
                    // If we add a filter we remove 'Everywhere' from the selected filters
                    if(prevState.locations.includes(LOCATION_EVERYWHERE)){
                        const locationEverywhereIndex = prevState.locations.findIndex(location => location === LOCATION_EVERYWHERE)
                        newLocations.splice(locationEverywhereIndex, 1)
                    }
                    return {...prevState, locations: newLocations}
                }
                
            } else {
                const newLocations = prevState.locations.filter((_, index) => index !== locationIndex)
                return {...prevState, locations: newLocations}
            }
        })
        
    }
    
    const handleReset = () => {
        setTextFilter('')
        setFromDateFilter(today)
        setToDateFilter(in6months)
        setAmountFromFilter(0)
        setAmountToFilter(0)
        setLocationsFilter({isOpen: false, locations: [LOCATION_EVERYWHERE]})
    }
    
    
    // Sorting
    const handleSortByDateCreated = () => {
        if(isDateCreatedSortAscending) {
            const newSortedTourings = filteredTourings.slice()
            newSortedTourings.sort((touringA, touringB) => touringB.dateCreated - touringA.dateCreated)
            setFilteredTourings(newSortedTourings)
        } else {
            const newSortedTourings = filteredTourings.slice()
            newSortedTourings.sort((touringA, touringB) => touringA.dateCreated - touringB.dateCreated)
            setFilteredTourings(newSortedTourings)
        }
        setIsDateCreatedSortAscending(prevValue => !prevValue)
    }

    const handleSortByArtist = () => {
        if(isArtistSortAscending) {
            const newSortedTourings = filteredTourings.slice()
            newSortedTourings.sort((touringA, touringB) => touringB.artist.displayableName.localeCompare(touringA.artist.displayableName))
            setFilteredTourings(newSortedTourings)
        } else {
            const newSortedTourings = filteredTourings.slice()
            newSortedTourings.sort((touringA, touringB) => touringA.artist.displayableName.localeCompare(touringB.artist.displayableName))
            setFilteredTourings(newSortedTourings)
        }
        setIsArtistSortAscending(prevValue => !prevValue)
    }

    const handleSortByFromDate = () => {
        if(isFromDateSortAscending) {
            const newSortedTourings = filteredTourings.slice()
            newSortedTourings.sort((touringA, touringB) => new Date(touringB.from).getTime() - new Date(touringA.from).getTime())
            setFilteredTourings(newSortedTourings)
        } else {
            const newSortedTourings = filteredTourings.slice()
            newSortedTourings.sort((touringA, touringB) => new Date(touringA.from).getTime() - new Date(touringB.from).getTime())
            setFilteredTourings(newSortedTourings)
        }
        setIsFromDateSortAscending(prevValue => !prevValue)
    }

    const handleSortByToDate = () => {
        if(isToDateSortAscending) {
            const newSortedTourings = filteredTourings.slice()
            newSortedTourings.sort((touringA, touringB) => new Date(touringB.to).getTime() - new Date(touringA.to).getTime())
            setFilteredTourings(newSortedTourings)
        } else {
            const newSortedTourings = filteredTourings.slice()
            newSortedTourings.sort((touringA, touringB) => new Date(touringA.to).getTime() - new Date(touringB.to).getTime())
            setFilteredTourings(newSortedTourings)
        }
        setIsToDateSortAscending(prevValue => !prevValue)
    }

    const handleSortBySetLength = () => {
        if(isSetLengthSortAscending) {
            const newSortedTourings = filteredTourings.slice()
            newSortedTourings.sort((touringA, touringB) => touringB.showDuration - touringA.showDuration)
            setFilteredTourings(newSortedTourings)
        } else {
            const newSortedTourings = filteredTourings.slice()
            newSortedTourings.sort((touringA, touringB) => touringA.showDuration - touringB.showDuration)
            setFilteredTourings(newSortedTourings)
        }
        setIsSetLengthSortAscending(prevValue => !prevValue)
    }

    const handleSortByGenre = () => {
        if(isGenreSortAscending) {
            const newSortedTourings = filteredTourings.slice()
            newSortedTourings.sort((touringA, touringB) => {
                const touringBGenre = touringB.artist.genres? touringB.artist.genres[0] : ''
                const touringAGenre = touringA.artist.genres? touringA.artist.genres[0] : ''
                return touringBGenre.localeCompare(touringAGenre)
            })
            setFilteredTourings(newSortedTourings)
        } else {
            const newSortedTourings = filteredTourings.slice()
            newSortedTourings.sort((touringA, touringB) => {
                const touringBGenre = touringB.artist.genres? touringB.artist.genres[0] : ''
                const touringAGenre = touringA.artist.genres? touringA.artist.genres[0] : ''
                return touringAGenre.localeCompare(touringBGenre)
            })
            setFilteredTourings(newSortedTourings)
        }
        setIsGenreSortAscending(prevValue => !prevValue)
    }

    const handleSortByPossibleFee = () => {
        if(isPossibleFeeSortAscending) {
            const newSortedTourings = filteredTourings.slice()
            newSortedTourings.sort((touringA, touringB) => (touringB.possibleFee ?? 0) - (touringA.possibleFee ?? 0))
            setFilteredTourings(newSortedTourings)
        } else {
            const newSortedTourings = filteredTourings.slice()
            newSortedTourings.sort((touringA, touringB) => (touringA.possibleFee ?? 0) - (touringB.possibleFee ?? 0))
            setFilteredTourings(newSortedTourings)
        }
        setIsPossibleFeeSortAscending(prevValue => !prevValue)
    }

    const handleSortByComment = () => {
        if(isCommentSortAscending) {
            const newSortedTourings = filteredTourings.slice()
            newSortedTourings.sort((touringA, touringB) => (touringB.comments ?? '').localeCompare(touringA.comments ?? ''))
            setFilteredTourings(newSortedTourings)
        } else {
            const newSortedTourings = filteredTourings.slice()
            newSortedTourings.sort((touringA, touringB) => (touringA.comments ?? '').localeCompare(touringB.comments ?? ''))
            setFilteredTourings(newSortedTourings)
        }
        setIsCommentSortAscending(prevValue => !prevValue)
    }

    const handleSortByIsPotential = () => {
        if(isIsPotentialSortAscending) {
            const newSortedTourings = filteredTourings.slice()
            newSortedTourings.sort((touringA, touringB) => {
                const touringBPotential = touringB.isPotential ? 1 : -1
                const touringAPotential = touringA.isPotential ? 1 : -1
                return touringBPotential - touringAPotential
            })
            setFilteredTourings(newSortedTourings)
        } else {
            const newSortedTourings = filteredTourings.slice()
            newSortedTourings.sort((touringA, touringB) => {
                const touringBPotential = touringB.isPotential ? 1 : -1
                const touringAPotential = touringA.isPotential ? 1 : -1
                return touringAPotential - touringBPotential
            })
            setFilteredTourings(newSortedTourings)
        }
        setIsIsPotentialSortAscending(prevValue => !prevValue)
    }

    return (
        <>
            {activeTab === "create"
            ?   <PotentialTouringForm goBack={handleCloseForm} />
            :   <div className="main-container margedBot100px">
                    <h3 className="page-title  margedTop30px">Potential Tourings</h3>

                    <Row className="allwidth margedBot30px">
                        <Flex row flex={8}>
                            {/* <div className="allwidth">{FilterUI}</div> */}
                            <PotentialTouringsFilters 
                                tourings={computedToursWithArtist} 
                                textFilter={textFilter}
                                setTextFilter={setTextFilter}
                                fromDateFilter={fromDateFilter}
                                handlePickFromDate={handlePickFromDate}
                                toDateFilter={toDateFilter}
                                handlePickToDate={handlePickToDate}
                                amountFromFilter={amountFromFilter}
                                setAmountFromFilter={setAmountFromFilter}
                                amountToFilter={amountToFilter}
                                setAmountToFilter={setAmountToFilter}
                                locationsFilter={locationsFilter}
                                handleToggleLocationItem={handleToggleLocationItem}
                                handleReset={handleReset}
                            />
                        </Flex>
                        <Flex flex={2}>
                            <button 
                                className="flex row button button--primary uppercase"
                                onClick={handleCreatePTouring}
                            >
                                <BiLayerPlus size={25} />
                                &nbsp;
                                New Potential Touring
                            </button>
                        </Flex>
                    </Row>

                    <Flex row flex={10} className="justifystart wrap spaceevenly allwidth">
                        {filteredTourings 
                        ?
                            <table className="app-table">
                                <thead>
                                <tr>
                                    <th className="leftalign">
                                        <div className="clickable flex-align-center row" onClick={handleSortByDateCreated}>
                                            <p className="margedRight2px">Date Created</p>
                                            {isDateCreatedSortAscending
                                            ?   <AiOutlineCaretUp size={15}/>
                                            :   <AiOutlineCaretDown size={15}/>}
                                        </div>
                                    </th>
                                    <th className="leftalign">
                                        <div className="clickable flex-align-center row" onClick={handleSortByArtist}>
                                            <p className="margedRight2px">Artist</p>
                                            {isArtistSortAscending
                                            ?   <AiOutlineCaretUp size={15}/>
                                            :   <AiOutlineCaretDown size={15}/>}
                                        </div>
                                    </th>
                                    <th className="leftalign">
                                        <div className="clickable flex-align-center row" onClick={handleSortByFromDate}>
                                            <p className="margedRight2px">From</p>
                                            {isFromDateSortAscending
                                            ?   <AiOutlineCaretUp size={15}/>
                                            :   <AiOutlineCaretDown size={15}/>}
                                        </div>
                                    </th>
                                    <th className="leftalign">
                                        <div className="clickable flex-align-center row" onClick={handleSortByToDate}>
                                            <p className="margedRight2px">To</p>
                                            {isToDateSortAscending
                                            ?   <AiOutlineCaretUp size={15}/>
                                            :   <AiOutlineCaretDown size={15}/>}
                                        </div>
                                    </th>
                                    <th className="leftalign">Countries</th>
                                    <th className="leftalign">
                                        <div className="clickable flex-align-center row" onClick={handleSortBySetLength}>
                                            <p className="margedRight2px">Set Length</p>
                                            {isSetLengthSortAscending
                                            ?   <AiOutlineCaretUp size={15}/>
                                            :   <AiOutlineCaretDown size={15}/>}
                                        </div>
                                    </th>
                                    <th className="leftalign">
                                        <div className="clickable flex-align-center row" onClick={handleSortByGenre}>
                                            <p className="margedRight2px">Genre</p>
                                            {isGenreSortAscending
                                            ?   <AiOutlineCaretUp size={15}/>
                                            :   <AiOutlineCaretDown size={15}/>}
                                        </div>
                                    </th>
                                    <th className="leftalign">Ranking</th>
                                    <th className="leftalign">
                                        <div className="clickable flex-align-center row" onClick={handleSortByPossibleFee}>
                                            <p className="margedRight2px">Possible Fee</p>
                                            {isPossibleFeeSortAscending
                                            ?   <AiOutlineCaretUp size={15}/>
                                            :   <AiOutlineCaretDown size={15}/>}
                                        </div>
                                    </th>
                                    <th className="leftalign">
                                        <div className="clickable flex-align-center row" onClick={handleSortByComment}>
                                            <p className="margedRight2px">Comment</p>
                                            {isCommentSortAscending
                                            ?   <AiOutlineCaretUp size={15}/>
                                            :   <AiOutlineCaretDown size={15}/>}
                                        </div>
                                    </th>
                                    <th>
                                        <div className="clickable flex-align-center row" onClick={handleSortByIsPotential}>
                                            <p className="margedRight2px">Touring</p>
                                            {isIsPotentialSortAscending
                                            ?   <AiOutlineCaretUp size={15}/>
                                            :   <AiOutlineCaretDown size={15}/>}
                                        </div>
                                    </th>
                                    <th>Action</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredTourings.map((tourWithArtist: TouringWithArtist|PotentialTouringWithArtist, index: number) => 
                                    <ItemRow key={index} tour={tourWithArtist} handleMakeTouringFromPotential={handleMakeTouringFromPotential} />)}
                            </tbody>
                            </table>
                        
                        :   <p className="grey">There is no tours that match.</p>}

                    </Flex>

                </div>}
        </>
    )
}

export default AdminPotentialTourings