import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

import { Doughnut, Pie, Bar, Line } from 'react-chartjs-2';

import 'chartjs-adapter-moment'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// const data = {
//   labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
//   datasets: [
//     {
//       label: '# of Votes',
//       data: [12, 19, 3, 5, 2, 3],
//       backgroundColor: [
//         'rgba(255, 99, 132, 0.2)',
//         'rgba(54, 162, 235, 0.2)',
//         'rgba(255, 206, 86, 0.2)',
//         'rgba(75, 192, 192, 0.2)',
//         'rgba(153, 102, 255, 0.2)',
//         'rgba(255, 159, 64, 0.2)',
//       ],
//       borderColor: [
//         'rgba(255, 99, 132, 1)',
//         'rgba(54, 162, 235, 1)',
//         'rgba(255, 206, 86, 1)',
//         'rgba(75, 192, 192, 1)',
//         'rgba(153, 102, 255, 1)',
//         'rgba(255, 159, 64, 1)',
//       ],
//       borderWidth: 1,
//     },
//   ],
// };




const Chart = (props) => {

  const {
    title,
    //key,
    style,
    dataLabel,
    line,       // Doughnut chart by default
    bar,        // idem
    pie,        // idem
    width,
    height,
    data: _data,
    size,
    renderLabel,
    renderTooltip,  //  (indexData:any, dataIndex:number, datasetIndex:number) => string
    noLegend,
    autoColors,
    time,
    min,
    max

  } = props;  

  const options = {
    responsive: true,
    tension: .3,
    parsing: {
      yAxisKey: 'y',
      xAxisKey: 'x',
    },
    scales: {
      x: (
        time 
        ? {
          // ticks: {
          //   beginAtZero: true,
          // },
          type: 'time',
          // display: true,
          // offset: false,
          // offsetAfterAutoskip: true,
          time,
          min,
          max,
          
        }
        : {
          ticks: {
            beginAtZero: true,
          },
        })  
    },
    plugins: {
      legend: {
        position: 'top',

        ///
        // labels: {
        //   generateLabels: (a, b) => { console.log('generating labels', a, b); return a; }
        // }
        ///

      },
      title: {
        display: title?.length > 0,//true,
        text: title,
      },
      ...(
        (renderLabel||renderTooltip) // overwrite dataset
        ? {
          tooltip: {
            callbacks: {
              ...(
                renderLabel
                ? { label: renderLabel }
                : {}
              ),
              ...(
                renderTooltip
                ? { footer: (e) => {console.log('renderTooltip footer callback', e); return renderTooltip(e[0].raw, e[0].dataIndex, e[0].datasetIndex)} }
                : {}
              )
            }
          }
        }
        : {}
      )
    },
  };

  const useRectSize = {
    width: width||'auto', 
    maxWidth: width||'auto', 
    height: height||'auto', 
    maxHeight: height||'auto'
  }

  const useSquaredSize = {
    maxWidth: size||'auto', maxHeight: size||'auto'
  }
  const chartId = title||Date.now();

  const autoColor = [
    '#FF3333',  // dark red
    '#FF3333',  // dark red
    '#FF6961',  // light red
    '#FF7417',  // dark orange
    '#FFA526',  // light yellow
    '#F8D66D',  // beige
    '#F8F07A',  // yellow-green 
    '#B0BD71',  // kaki
    '#7ABD7E',  // green
    '#8CD47E',  // light green
    '#22D457',  // very green
  ]
  const datasets = (
    autoColors
    ? _data.datasets.map((dset) => {
      const {data} = dset;
      // set colors (used with percentage data)
      const backgroundColor = data.map((dta, dtai) => {
        return (
          ((dtai === data.length-1)) // do not colorize the "100%" ratio data 
          ? 'transparent'
          : autoColor[parseInt(dta / 10%dta)]
        )
      });

      return {...dset, backgroundColor}
    })
    : _data.datasets
  ) 

  const data = {..._data, datasets}

  return (
    <>
      
      {
        pie 
        ? <Pie 
          key={`pie-chart${chartId}`}
          style={{...(style||{}), ...useSquaredSize}}
          data={data} 
          {...(
            data?.labels?.length || dataLabel
            ? {
              options:{
                plugins: {
                    tooltip: {
                      callbacks: ({
                        label: (
                          renderLabel 
                          || ((item) =>
                              `${item.dataset.label||''}: ${item.formattedValue} ${dataLabel || item.label}`
                          )
                        ),
                      }),
                    },
                    legend: (
                      noLegend
                      ? { display: false }
                      : {
                        position: 'top',
                        labels: {
                          usePointStyle: true,
                          pointStyle: 'circle'
                        }
                      }
                    )
                },
              }
            }
            : {}
          )}
        /> 
        : (
          bar 
          ? <Bar 
              key={`bar-chart${chartId}`} 
              data={data} 
              options={options} 
              style={useRectSize} /> 
          : (
            line
            ? <Line 
                key={`line-chart${chartId}`} 
                data={data} 
                options={options}
                style={useRectSize} 
              />
            : <Doughnut 
              key={`doughnut-chart${chartId}`} 
              data={data} 
              options={options}
              style={{...(style||{}), ...useSquaredSize}} 
            /> 
          )
        )
      }
    </>
  );
}

export default Chart;
