import moment from "moment"
import { useRef, useState } from "react"
import { createPortal } from "react-dom"
// @ts-ignore
import SignatureCanvas from 'react-signature-canvas'
import Axios from 'axios';
import { BsArrowsFullscreen } from "react-icons/bs"
import { FaFileSignature } from "react-icons/fa"
import { GiFireShield } from "react-icons/gi"
import { MdOpenInNew } from "react-icons/md"
import { Flex, Row } from "../../../../components/Containers"
import Dialog from '../../../../components/dialog/Dialog'
import { accessLevels, errorToSuccessTheme3, primuseApi } from "../../../../constants"
import { offersActions } from "../../../../store/offersSlice"
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks/reduxTypedHooks"
import useFetchApiAuth from "../../../../utils/hooks/useFetchApiAuth"
import useSetSnack from "../../../../utils/hooks/useSetSnack"
import { AmountType, Offer } from "../../../../utils/models/offer.model"
import { countries } from "../../../../utils/models/touring.model"
import { b64toBlob, ls, pnlAmountFormatter } from "../../../../utils/utils"
import offerStatus from "../../../common/offers/offerStatus"
import ViewOffer from "./ViewOffer"
import DocumentLoading from "../../../../components/document-loading/DocumentLoading";
import OptionsMenu from "../../../../components/option-menu/OptionMenu"
import IconifyIcon from "../../../../components/Icon/IconifyIcon"
import useViewPNLs from "../../../../utils/hooks/offer/useViewPNLs";

const OfferItemRow = ({ offer, ongoing }: { offer: Offer, ongoing?: boolean }) => {

    const [offerVisible, setOfferVisible] = useState(false)
    const [isSuperAccepting, setIsSuperAccepting] = useState(false)
    const [isRequesting, setIsRequesting] = useState(false)
    const [isDenying, setIsDenying] = useState(false)
    const [isAccepting, setIsAccepting] = useState(false)
    const [editionRequestVisible, showEditionRequestModal] = useState(false);
    const [signPadVisible, setSignPadVisible] = useState(false)
    const [isSigning, setIsSigning] = useState(false)

    const [comment, _setComment] = useState('')

    const setComment = (e:any) => _setComment(e.target.value)
    const closeEditionRequest = () => showEditionRequestModal(false);
    const openOffer = () => setOfferVisible(true)
    const closeOffer = () => setOfferVisible(false)
    const openSignPad = () => setSignPadVisible(true)
    const closeSignPad = () => setSignPadVisible(false)
    const signPadRef = useRef();
    const clearSignPad = async() => {
        // @ts-ignore
        signPadRef?.current && signPadRef.current.clear();
    }

    const { pnlsModal, openPnlsButton } = useViewPNLs({offer, textColor: true})

    const rootModalDiv = document.getElementById('modal-root')!
    
    const artists = useAppSelector(state => state.artists)
    const artist = artists.find(artist => artist.id === offer.artistId)

    const promotors = useAppSelector(state => state.promotors)
    const client = useAppSelector(state => state.client)
    const promotor = promotors.find(promotor => promotor.id === offer.promotorId)

    const showCountries = offer.shows.map(show => countries[show.country])
    const showDates = offer.shows.map(show => moment(new Date(show.date)).format('MM/D/YYYY'))
    const showAmounts = offer.shows.map(show => {
        let amount = `${show.localCurrency} ${pnlAmountFormatter(show.amount)}`
        if(show.amountType === AmountType.Deal) {
            amount = "Deal"
        }
        return amount
    })

    const dispatch = useAppDispatch()
    const fetchApiAuth = useFetchApiAuth()
    const setSnack = useSetSnack()

    const confirmBinderSignature = async() => {
        setIsSigning(true); // offer is being signed
        try {
            //@ts-ignore
            const bS4Signature = signPadRef.current.toDataURL('png');
         
            const blobSignature = await b64toBlob(bS4Signature.split(',')[1], 'image/png');
          
            let fd = new FormData();

            //@ts-ignore
            fd.append('image', blobSignature, `${offer.id}_${client.licence}_signature.png`);

            // store signature to contract using axios
            Axios.interceptors.request.use((config) => {
                // @ts-ignore
                config.headers['X-Auth-Token'] = client.token;
                return config;
            });
            // @ts-ignore
            const axiosResponse = await Axios({
                method: "post",
                url: `${primuseApi}/sign-binder/${offer.id}`,
                data: fd,   // FormData
                config: { headers: { "Content-Type": "multipart/form-data"/*, 'X-Auth-Token': client.token*/ } }
            });
            
            closeSignPad();
            console.log('OFFER UPDATED AFTER SIGN (axiosResponse.data):', axiosResponse.data);
            // update local contracts
            dispatch(offersActions.merge(axiosResponse.data))

            
            setIsSigning(false);

        }catch(e) {
            setIsSigning(false);
            console.log('ERROR WHILE SIGNING CONTRACT :', e);
            setSnack({
                type: 'error',
                // @ts-ignore
                content: e.response?.data || e.message | "Error while signing the contract, service may be down"
            })
        }
    }

    const handleSeeOffer = () => {
        openOffer()
    }

    const handleAcceptOffer = async () => {
        setIsAccepting(true)
        const body = {
            offerId: offer.id,
            promotorId: offer.promotorId,
            // comments: ''
        }
        
        const { error, response: updatedOffer } = await fetchApiAuth({
            method: "POST",
            route: "admin-accept-offer",
            body
        })

        // snack
        setSnack(
            error
            ? {
                type: 'error', 
                content: error.toString()
            }
            : {
                type: 'success',
                content: "The offer has been accepted."
            })

        //update Redux state
        if(!error){
            dispatch(offersActions.merge(updatedOffer))
        }
        setIsAccepting(false)
    }

    const handleSuperAcceptOffer = async () => {
        setIsSuperAccepting(true)
        const body = {
            offerId: offer.id,
            promotorId: offer.promotorId,
            // comments: ''
        }
        
        const { error, response: updatedOffer } = await fetchApiAuth({
            method: "POST",
            route: "admin-super-accept-offer",
            body
        })

        // snack
        setSnack(
            error
            ? {
                type: 'error', 
                content: error.toString()
            }
            : {
                type: 'success',
                content: "The offer has been super accepted."
            })

        //update Redux state
        if(!error){
            dispatch(offersActions.merge(updatedOffer))
        }
        setIsSuperAccepting(false)
    }
    const handleRequestOfferEdition = async () => showEditionRequestModal(true)
    const requestOfferEdition = async () => {
        setIsRequesting(true)
        const body = {
            offerId: offer.id,
            comment
            // comments: ''
        }
        
        const { error, response: updatedOffer } = await fetchApiAuth({
            method: "POST",
            route: "admin-request-offer-edition",
            body
        })

        // snack
        setSnack(
            error
            ? {
                type: 'error', 
                content: error.toString()
            }
            : {
                type: 'success',
                content: "The request has been made to the promotor to edit his offer."
            }
        )

        //update Redux state
        if(!error){
            _setComment('');
            showEditionRequestModal(false);
            dispatch(offersActions.merge(updatedOffer))
        }
        setIsRequesting(false)
    }

    const handleDenyOffer = async () => {
        setIsDenying(true)
        const body = {
            offerId: offer.id,
            // comments: ''
        }
        
        const { error, response: updatedOffer } = await fetchApiAuth({
            method: "POST",
            route: "admin-deny-offer",
            body
        })

        // snack
        setSnack(
            error
            ? {
                type: 'error', 
                content: error.toString()
            }
            : {
                type: 'success',
                content: "The offer has been denied."
            })

        //update Redux state
        if(!error){
            dispatch(offersActions.merge(updatedOffer))
        }
        setIsDenying(false)
    }
    
    const { label, details, icon }:{label: string, details: string, icon: JSX.Element} = !ongoing ? offerStatus[offer.status] : {label:'', details:'', icon: <div />};

    // // binder to be signed ?
    // const binderToSign = (
    //     (offer.binderLetter?.data?.signatures?.length===1)
    //     && (offer.binderLetter?.data?.signatures[0].licence === Licence.Promotor)
    // );

    return (
        <>
            {/* Binder Letter Signer (@TODO: create DocumentSigner component) (duplicate promotor/offers/OfferItemRow) */}
            {createPortal(
                <Dialog
                    visible={signPadVisible}
                    onClose={closeSignPad}
                    backdropClose={false}
                    dialogCustomStyle={{overflowY: 'scroll', height: '90vh', top: '5vh', width: '80%', left: '10%'}}
                    notAllSpace={true}
                >   
                    <Flex flex={10} row className='allspace alignstart padded'>
                        <Flex flex={5} className='justifystart'>
                            <Flex row className='marged-v'>
                                <i className="main-color">Document Reference: </i> 
                                &nbsp;
                                { offer.binderLetter?.data.id }
                                &nbsp;&nbsp;
                                <BsArrowsFullscreen
                                    className="main-color-fill clickable" 
                                    size={15}
                                    onClick={() => window.open(offer.binderLetter?.url, '_blank')}
                                />
                               
                            </Flex>
                            <iframe src={offer.binderLetter?.url} style={{width:'40vw', height:'80vh'}}/>
                        </Flex>

                        <Flex flex={5} className='justifystart margedTop spaceevenly' style={{height: '87vh'}}>
                                <Flex flex={9}>
                                <Flex row className="xxlarge bold margedTop">
                                    <FaFileSignature size={35} className="theme1fill" />
                                    &nbsp;<b>Your signature is required</b>
                                </Flex>
                                <p className="margedTop ">Requested Deposit Amount : <b>US$ { offer.binderLetter?.data.deposit.replace(/ /g, ',') }</b></p>
                                    {
                                        isSigning   // storeComputedContract
                                        ? <Flex className="m-signature-pad">
                                            <DocumentLoading
                                                texts={['Your signature is being affixed to the document', 'Please Wait']}
                                            />
                                        </Flex>
                                        : <SignatureCanvas
                                            canvasProps={{
                                                className: 'm-signature-pad nomargin', 
                                                style: { position: 'relative', left: 10, top: 40, width: '30vw', height: '40vh' }
                                            }}
                                            ref={signPadRef}
                                        />
                                    }
                                </Flex>
                                <Flex flex={10} className="allheight"> <Row>
                                    <button className="app-button error-button" onClick={closeSignPad}>CLOSE</button>
                                    &nbsp;
                                    <button className="app-button" onClick={clearSignPad}>Clear</button>
                                    &nbsp;
                                    <button className="app-button success" onClick={confirmBinderSignature}>Confirm</button></Row>
                                </Flex>
                            
                        </Flex>
                    </Flex>
                </Dialog>, rootModalDiv)
            }
            
            
            {/* Opened Offer */}
            {createPortal(
                <Dialog
                    visible={offerVisible}
                    onClose={closeOffer}
                    dialogCustomStyle={{overflowY: 'scroll', height: '90vh', top: '5vh', width: '80%', left: '10%', backgroundColor: 'var(--app-background)'}}
                    notAllSpace={true}
                >
                    {artist 
                    ?   <ViewOffer 
                        artist={artist}
                        offer={offer}
                        promotor={promotor}
                        isAdmin
                        close={closeOffer}
                    />
                    :   <p className="grey">Artist not found</p>}
                </Dialog>, rootModalDiv)}

            {/* View PNLs */}
            {pnlsModal}

            {/* Table */}
            <tr className="fw400">
                <td className="leftalign"><p className="clickable" onClick={handleSeeOffer}>{moment(new Date(offer.date)).format('MM/D/YYYY h:mm A')}</p></td>
                <td className="leftalign"><p className="clickable" onClick={handleSeeOffer}>{moment(new Date(offer.expiryDate)).format('MM/D/YYYY')}</p></td>
                <td className="leftalign">{promotor ? <b>{promotor.name}</b> : "Promotor not found"}</td>
                <td>
                    {promotor?.accessLevel && 
                        
                            <div className="flex-align-center row bold">
                                <GiFireShield size={25} color={errorToSuccessTheme3[promotor.accessLevel - 1]} /> {accessLevels.promotor[promotor.accessLevel]}
                            </div>
                        }
                </td>
                <td className="leftalign">{artist ? artist.displayableName : "Artist not found"}</td>
                <td><p className="clickable" onClick={handleSeeOffer}>{offer.shows.length}</p></td>
                <td className="leftalign">
                    <p className="clickable" onClick={handleSeeOffer}>
                        {showCountries.join(' - ')}
                    </p>
                </td>
                <td className="leftalign">
                    <p className="clickable" onClick={handleSeeOffer}>
                        {showDates.join(' - ')}
                    </p>
                </td>
                <td className="leftalign">
                    <p className="clickable" onClick={handleSeeOffer}>
                        <b>{showAmounts.join(' - ')}</b>
                    </p>
                </td>
                <td>
                    <MdOpenInNew className="text-color-fill clickable hoverscale" size={25} onClick={handleSeeOffer} />
                </td>
                <td>
                    {openPnlsButton}
                </td>
               
                {
                    ongoing
                    ? <td>
                        <Flex className="spaceevenly">
                            
                            {
                                offer?.binderLetter     // already ranked as `ongoing` by `AdminOffers` (`binderInProcess`)
                                ? <button className="button button--primary button--small offer__action-btn" onClick={openSignPad}>Sign Binder</button>
                                : <>
                                     <OptionsMenu
                                        menuProps={{ sx: { '& .MuiMenuItem-root svg': { mr: 2 } } }}
                                        iconButtonProps={{ size: 'small', sx: { color: 'var(--text-color)' }, className: "hoverscale" }}
                                        noClose
                                        options={[
                                        {
                                            text: isDenying? 'Denying...' : 'Deny',
                                            icon: <IconifyIcon icon='radix-icons:cross-1' fontSize={20} />,
                                            onClick: handleDenyOffer,
                                        },
                                        {
                                            text: isRequesting ? 'Requesting...' : 'Request Edition',
                                            icon: <IconifyIcon icon='carbon:request-quote' fontSize={20} />,
                                            onClick: handleRequestOfferEdition,
                                        },
                                        {
                                            text: isAccepting ? 'Accepting...' : 'Accept',
                                            icon: <IconifyIcon icon='ion:checkmark' fontSize={20} />,
                                            onClick: handleAcceptOffer,
                                        },
                                        {
                                            text: isSuperAccepting ? 'Super Accepting...' : 'Super Accept',
                                            icon: <IconifyIcon icon='ion:checkmark-done' fontSize={20} />,
                                            onClick: handleSuperAcceptOffer,
                                        },
                                        ]}
                                    />
                                    {/* <button className="button button--negative button--small offer__action-btn bold" onClick={handleDenyOffer}>{isDenying? 'Denying...' : 'Deny'}</button>
                                    
                                    <button className="button button--negative button--small offer__action-btn bold" onClick={handleRequestOfferEdition}>{isRequesting ? 'Requesting...' : 'Request Edition'}</button>

                                    <button className="button button--primary button--small offer__action-btn" onClick={handleAcceptOffer}>{isAccepting ? 'Accepting...' : 'Accept'}</button>
                                
                                    <button className="button button--small offer__action-btn holographic-button" onClick={handleSuperAcceptOffer}> <div className="button-container">{isSuperAccepting ? 'Super Accepting...' : 'Super Accept'}</div></button> */}
                                
                                </>
                            }
                        </Flex>

                    </td>
                    : <td>
                        <Flex className="justifystart">
                            <Flex flex={2} className="alignstart">{icon}</Flex>
                            <Flex flex={8} className="bold">{label}</Flex>
                        </Flex>
                    </td>
                }
               
            </tr>

            {
                createPortal(
                    <Dialog
                        visible={editionRequestVisible}
                        onClose={closeEditionRequest}
                        dialogCustomStyle={{overflowY: 'scroll', height: '70vh', top: '15vh', width: '70%', left: '15%'}}
                        notAllSpace={true}
                    >
                        <Flex flex={10} className="allspace spaceevenly">
                            <Flex row flex={1} className="width90 button--primary">
                                <p className="title">Offer Edition Request</p>
                            </Flex>
                            <Flex flex={8}>

                                <div className="marged-v larger selfstart">Please, explain the reason why you are requesting an edition of the offer :</div>
                                <textarea 
                                    className="larger padded" 
                                    style={{width: '60vw', height: '50vh'}} 
                                    placeholder="Edition reason..." 
                                    onChange={setComment}   
                                    value={comment} 
                                />

                            </Flex>
                            
                            <Flex row flex={1} className="margedTop">
                                <button className="button button--negative">Cancel</button>
                                &nbsp;
                                <button className="button--primary button" onClick={requestOfferEdition}>Request Edition</button>
                            </Flex>
                        </Flex>
                    </Dialog>, rootModalDiv)
            }
        </>
    )
}

export default OfferItemRow