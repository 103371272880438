// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.top-tracks_table__IDG3L {
    border-collapse: collapse;
    width: 100%;
}
.top-tracks_table__IDG3L td {
    padding: 10px
}
.top-tracks_table__IDG3L thead td {
    padding-top: 0;
    padding-bottom: 0;
}
.top-tracks_table__IDG3L > tbody > tr:first-of-type > td {
    padding-top: 20px;
}

.top-tracks_table__IDG3L tr {
    border: var(--input-border)
}
/* 
.table tbody tr:nth-of-type(odd) {
	background: #f9f9f9;
} */`, "",{"version":3,"sources":["webpack://./src/user-panels/promotor/artists/details/overview/top-tracks.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,WAAW;AACf;AACA;IACI;AACJ;AACA;IACI,cAAc;IACd,iBAAiB;AACrB;AACA;IACI,iBAAiB;AACrB;;AAEA;IACI;AACJ;AACA;;;GAGG","sourcesContent":[".table {\r\n    border-collapse: collapse;\r\n    width: 100%;\r\n}\r\n.table td {\r\n    padding: 10px\r\n}\r\n.table thead td {\r\n    padding-top: 0;\r\n    padding-bottom: 0;\r\n}\r\n.table > tbody > tr:first-of-type > td {\r\n    padding-top: 20px;\r\n}\r\n\r\n.table tr {\r\n    border: var(--input-border)\r\n}\r\n/* \r\n.table tbody tr:nth-of-type(odd) {\r\n\tbackground: #f9f9f9;\r\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `top-tracks_table__IDG3L`
};
export default ___CSS_LOADER_EXPORT___;
