import { useOutletContext } from "react-router-dom"
import { Flex } from "../../../../../components/Containers"
import useIsAdmin from "../../../../../utils/hooks/useIsAdmin"
import { ArtistDetailsContext } from "../ArtistDetails"

const ArtistDetailsInfos = () => {

    const { artistWithTours } = useOutletContext<ArtistDetailsContext>()
    const isAdmin = useIsAdmin()

    if(!isAdmin) {
        return (
            <Flex className="allspace margedTop50px">
                <h3>Acces Denied</h3>
            </Flex>
        )
    
    }
    if(!artistWithTours) {
        return (<Flex className="allspace margedTop50px"><h3>Not a Primuse Artist</h3></Flex>)
    }

    const { contacts, comments, possibleFee, preferences } = artistWithTours
    
    return (
        <div className="main-container leftalign">
            <h2 className="margedBot20px">Informations</h2>

            <div className="flex-only row space-between">

                <div className="width70">
                    <div className="app-card">
                        <div className="details-title-border">
                            <h4 className="details-section-title">Contacts</h4>
                        </div>
                        {contacts?.length
                        ?   <table className="app-table">
                                <thead>
                                    <tr>
                                        <th>Agency</th>
                                        <th>Label</th>
                                        <th>Management</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {contacts.map((contact, index) => 
                                        <tr key={index}>
                                            <th>{contact.agency}</th>
                                            <th>{contact.label}</th>
                                            <th>{contact.management}</th>
                                        </tr>)}
                                </tbody>
                            </table>
                        :   <p className="grey">No contacts</p>}
                    </div>
                </div>

                <div className="width25">
                    <div className="app-card">
                        <div className="details-title-border">
                            <h4 className="details-section-title">Comments</h4>
                        </div>
                        <p className="margedBot20px fw400">
                            {comments ? comments : <span className="grey">No comments</span>}
                        </p>

                        <div className="details-title-border">
                            <h4 className="details-section-title">Possible fee</h4>
                        </div>
                        <p className="margedBot20px fw400">
                            {possibleFee ? possibleFee : <span className="grey">Unknown</span>}
                        </p>

                        <div className="details-title-border">
                            <h4 className="details-section-title">Preferences</h4>
                        </div>
                        <p className="fw400">
                            {preferences ? preferences : <span className="grey">Unknown</span>}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ArtistDetailsInfos