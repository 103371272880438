import { hash } from "../crypto";
import { snackActions } from "../store/snackSlice";
import { fetchApi } from "../utils/utils";
import { Flex } from "../components/Containers";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { Oval } from 'react-loader-spinner';
import useDispatchLoginRes from "../utils/hooks/useDispatchLoginRes";
import { ROUTE_DASHBOARD } from "../routes/routes.const";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai"
import LabelInput from "../components/form/mui/label-input/LabelInput";
import logo from '../images/logo.png';

import LoginIllustration1 from "./LoginIllustration1";
import LoginIllustration2 from "./LoginIllustration2";

const Login = () => {

    const dispatch = useDispatch();
    const dispatchLoginRes = useDispatchLoginRes();
    
    const [isLoginIn, setIsLoginIn] = useState(false);
    const [credentials, setCredentials] = useState({
        password: "",
        email: ""
    });
    const [showPassword, setShowPassword] = useState(false)

    const toggleShowPassword = () => {
        setShowPassword(prevState => !prevState)
    }


    const login = async() => {
        setIsLoginIn(true);
        console.log('login credentials', credentials);
        const pwdHash = await hash(credentials.password, '');
        const res = await fetchApi(
          {
            method: 'POST',
            route: 'login',
            body: { email: credentials.email, hash: pwdHash }
          },
          {}
        );
        const { error, response, status }:{ error?:any, response?:any, status?:any } = res;
    
        if (error || !response) dispatch(snackActions.set({ type: 'error', content: (status===401 ? 'Unauthorized Access' : error).toString() } ));
    
        else {
            console.log(response);
            dispatchLoginRes(response, ROUTE_DASHBOARD);
        }

        setIsLoginIn(false);        
    
    }

    const setEmail = (e:any) => setCredentials((prevInputs:any) => ({...prevInputs, email: e.target.value}) );
    const setPwd = (e:any) => setCredentials((prevInputs:any) => ({...prevInputs, password: e.target.value}));


    return (
        <Flex className='main-container'>

            <div className={`relative allwidth margedTop100px`} style={{maxWidth: '448px'}}>
                <div style={{position: 'absolute', top: '-79px', left: '-46px', zIndex: '0'}}>
                    <LoginIllustration1 />
                </div>
                <div style={{position: 'absolute', bottom: '-54px', right: '-57px', zIndex: '0'}}>
                    <LoginIllustration2 />
                </div>

                <div className="app-card" style={{position: 'relative', zIndex: "5"}}>

                    <Flex className="allwidth margedBot30px">
                        <img src={logo} alt="logo" className="app-logo" />
                    </Flex>

                    <div className="leftalign margedBot20px">
                        <h5 className="font24 margedBot5px title-color">Welcome to Kisum!</h5>
                        <p className="font16">Please sign-in to your account and start the adventure</p>
                    </div>

                    <LabelInput 
                        title='Email'
                        value={credentials.email}
                        onChange={setEmail}
                    />
                    <div className="password-container margedBot20px">
                        <LabelInput 
                            title='Password'
                            type={showPassword ? "text" : "password"}
                            value={credentials.password}
                            onChange={setPwd}
                        />
                        {
                            showPassword
                            ? <AiFillEyeInvisible onClick={toggleShowPassword} size={20} className="show-password-icon" />
                            : <AiFillEye onClick={toggleShowPassword} size={20} className="show-password-icon" />
                        }
                    </div>

                    <button className="button button--primary button--block uppercase" onClick={login}>
                        {isLoginIn 
                        ?   <Flex className="allwidth"><Oval
                                height={20}
                                width={20}
                                color="white"
                                secondaryColor="gray"
                            /></Flex>
                        :   <span className="marged0auto">Login</span>}
                    </button>
                </div>
            </div>
        </Flex>
    )
}

export default Login;


