import AdminTouring from "../user-panels/admin/admin-panel-screens/touring/AdminTouring"
import PromotorTouring from "../user-panels/promotor/touring/PromotorTouring"

import { useAppSelector } from "../utils/hooks/reduxTypedHooks"
import { Licence } from "../utils/models/client.model"
import AccessDeniedPage from "./AccessDeniedPage"

const TouringPage = () => {
    const licence = useAppSelector(state => state.client?.licence)

    if(licence === Licence.Admin){
        return (<AdminTouring />)
    } else if (licence === Licence.Manager){
        return (<AdminTouring manager={true} />)
    }else if (licence === Licence.Promotor){
        return (<PromotorTouring />)
    } else {
        return (<AccessDeniedPage />)
    }
}

export default TouringPage