import { useState } from "react"
import { continentDisplay, continentsList } from "../models/touring.model"

const useFilterByContinent = () => {

    const [filteredContinentsSelected, setFilteredContinentsSelected] = useState<Record<string, string[]>[]>([])

    const filteredCountries = filteredContinentsSelected
        .map(continentRecord => Object.values(continentRecord)[0])  
        .reduce(((accumulator, array) => {
            return [...accumulator, ...array]
        }), [])

    const isEverywhere = filteredContinentsSelected.length === 0

    const setContinentsEverywhere = () => setFilteredContinentsSelected([])

    const filterEverywhereButtonActiveClass = filteredContinentsSelected.length === 0 ? 'touring-ghost__button--active' : ''
   
    const toggleContinentFilterButton = (continent: Record<string, string[]>) => {
        setFilteredContinentsSelected(prevValue => {
            const isSelected = prevValue.includes(continent)
            if(isSelected){
                return prevValue.filter(_continent => _continent !== continent)
            } else {
                return [...prevValue, continent]
            }
        })
    }

    const FilterUI = 
        <div className="allwidth flex-only wrap row-gap10 col-gap20">
            <button 
                className={`button touring__button touring-ghost__button ${filterEverywhereButtonActiveClass}`} 
                onClick={setContinentsEverywhere}
            >
                    Everywhere
            </button>

            {continentsList.map((continent, index) => 
                <button 
                    key={index}
                    className={`button touring__button touring-ghost__button ${filteredContinentsSelected.includes(continent) ? 'touring-ghost__button--active' : ''}`} 
                    onClick={() => toggleContinentFilterButton(continent)}
                >
                    {continentDisplay[Object.keys(continent)[0]]}
                </button>
            )}
        </div>

        return { FilterUI, filteredCountries, isEverywhere}
}

export default useFilterByContinent