import AdminDashboard from "../user-panels/admin/admin-panel-screens/dashboard/AdminDashboard"
import PromotorDashboard from "../user-panels/promotor/promotor-panel-screens/dashboard/Dashboard"
import { useAppSelector } from "../utils/hooks/reduxTypedHooks"
import { Licence } from "../utils/models/client.model"
import AccessDeniedPage from "./AccessDeniedPage"

const DashboardPage = () => {

     const licence = useAppSelector(state => state.client?.licence)

    if(licence === Licence.Admin){
        return (<AdminDashboard />)
    } else if (licence === Licence.Promotor){
        return (<PromotorDashboard />)
    } else {
        return (<AccessDeniedPage />)
    }
}

export default DashboardPage