import IconifyIcon from "../../components/Icon/IconifyIcon"

const FeatureItem = ({ iconName, title, text } : { iconName: string, title: string, text: string }) => {

    return (
        <div className="allwidth leftalign">
            <div className="margedBot10px">
                <IconifyIcon icon={iconName} color="white" fontSize={50} />
            </div>
            <div className="margedBot10px">
                <h3>{title}</h3> 
            </div>
            <p>{text}</p>
        </div>
    )
}

export default FeatureItem