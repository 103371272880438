import { ChartData } from "chart.js"
import { Doughnut } from "react-chartjs-2"

const ChartJsDoughnut = ({ data, legend, cutout }: { 
    data: ChartData<'doughnut'>,
    legend?: boolean,
    cutout?: number,
}) => {

    const options = {
        cutout: (cutout !== undefined) ? `${cutout}%` : "64%",
        plugins: {
            legend:{ display: legend ? true : false }
        }
    }

    const styledDatasets = data.datasets.map(dataset => ({
        ...dataset,
        hoverOffset: 4,
        borderWidth: 0,
    }))

    const styledData = {...data, datasets: styledDatasets}

    return (
        <Doughnut data={styledData} options={options} />
    )
}

export default ChartJsDoughnut