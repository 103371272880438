import { createSlice } from '@reduxjs/toolkit';

// {"id":"CT-TSTART2-10082022-1","artist":{"name":"TestArtist2","code":"TSTART2","password":"MLSFK45$","totalPartyPeople":2},"shows":[{"artistFee":{"amount":"850000","useNetFee":true},"tickets":[{"name":"Q1","quantity":"5000","grossPrice":"75"},{"name":"Q2","quantity":"7500","grossPrice":"90"}],"date":"2022-10-15","loadin":"15:49","soundCheck":"16:50","doorsOpen":"17:50","onStage":"18:50","time":"19:50","minAge":"21","curfew":"00:00","merchandising":"15","artistSell":"50","buildSell":"35","venue":{"city":"New York","taxPercent":12,"name":"Central Stadium","address":"948 central avenue, NY, USA","flag":"US","totalCapacity":45000,"phone":"+15467834590","website":"https://nycentralstadium.com"},"hotel":{"name":"Hilton","address":"Hilton Hotel, 465 Grand Street, New York, NY, USA","phone":"+01234567890","website":"https://hiltn.com"}},{"artistFee":{"amount":"735000","useNetFee":true},"tickets":[{"name":"Q1","quantity":"5000","grossPrice":"80"},{"name":"Q2","quantity":"8500","grossPrice":"95"}],"date":"2022-10-14","merchandising":"15","artistSell":"50","buildSell":"35","minAge":"21","notes":"no curfew","loadin":"19:14","soundCheck":"20:14","doorsOpen":"21:14","onStage":"22:14","time":"22:30","venue":{"city":"Las Vegas","taxPercent":9,"name":"Air Continent","address":"12th Street #8476, AZ, USA","flag":"US","phone":"+17689236743","website":"https://air-continent-vegas.com"},"hotel":{"name":"Grand Plaza","address":"14th Street #23, AZ, USA","phone":"+16567998771","website":"https://grandplazahotel.com"}}],"purchaser":{"name":"John Doe","email":"johndoe@doecie.com","cieName":"DoeCie","fullAddress":"34 central street, NY, USA","cieId":"VDFLSK45","password":"J1234D","perDiem":500},"transportation":{"type":"PLUS FLIGHT"},"payments":[{"amount":"1500000","date":"2022-10-15"},{"amount":"85000","date":"2022-10-22"}],"date":1665244170523,"pdfUrl":"https://primuse-dev.s3.ap-southeast-1.amazonaws.com/DOCUMENTS/CT-TSTART2-10082022-1.pdf"}
const initialState = null;

export const openedContractSlice = createSlice({
  name: 'openedContract',
  initialState,
  reducers: {
    set: (state, action) => action.payload,
    close: () => initialState,
    
  },
});

export const openedContractActions = openedContractSlice.actions;

export default openedContractSlice.reducer; 