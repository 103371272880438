import { AiOutlineCloseCircle } from "react-icons/ai"
import { pnlAmountFormatter } from "../../../../../utils/utils"

const ViewVariableCostsTable = ({ currency, currencySymbol, rate, variableCosts, pourcentagesArray, totalVariableCostArray, totalRevenueArray }: {
    currency: string,
    currencySymbol: null|string,
    rate: null|number,
    pourcentagesArray: number[],
    totalVariableCostArray: number[],
    variableCosts: {name: string, has: boolean, percentage: number}[],
    totalRevenueArray: number[]
}) => {

    const totalCostUSDArray = totalVariableCostArray.map(cost => rate ? cost/rate : 0)

    return (
        <table className="app-table">
            <thead>
                <tr>
                    <th className="leftalign" style={{width: "11%"}}>Variable cost</th>
                    <th className="leftalign" style={{width: "4%"}}>Tax %</th>
                    {pourcentagesArray.map(pourcentage => <th key={pourcentage}  style={{width: "8%"}} className="leftalign">{pourcentage}%</th>)}
                </tr>
            </thead>
            <tbody>
                {variableCosts.map((variableCost, index) => 
                    variableCost.has 
                    ?   <tr key={index}>
                            <th className="leftalign">
                                <p>{variableCost.name}</p>
                            </th>
                            <th>
                                <p>{variableCost.percentage}</p>
                            </th>
                            {pourcentagesArray.map((_, index) => <th key={index} className="leftalign error">{currencySymbol ?? currency} {pnlAmountFormatter(variableCost.has ? (totalRevenueArray[index] * (variableCost.percentage / 100)) : 0)}</th>)}
                        </tr> 
                    :   null)}
                
                <tr>
                    <th className="leftalign">Total Variable Cost ({currencySymbol ?? currency})</th>
                    <th></th>
                    {totalVariableCostArray.map((totalVariableCost, index) => <th key={index} className="leftalign error">{currencySymbol ?? currency} {pnlAmountFormatter(totalVariableCost)}</th>)}
                </tr>
                <tr>
                    <th className="leftalign">Total Variable Cost (USD)</th>
                    <th></th>
                    {totalCostUSDArray.map((totalVariableCost, index) => <th key={index} className="leftalign error">${pnlAmountFormatter(totalVariableCost)}</th>)}
                </tr>
            </tbody>
        </table>
    )
}

export default ViewVariableCostsTable