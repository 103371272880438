import { Flex } from "../../../../../components/Containers"
import { socialIcons } from "../../../../admin/admin-panel-screens/promotors/PromotorForm"
import { numberFormatter, ls } from "../../../../../utils/utils"
import InstagramLogo from "../../../../../images/InstagramLogo"
import { streamingPlatformsIcons } from "../common/ArtistInfos"
import { colors } from "../../../../../constants"
import { ArtistAPIDatas } from "../../../../../utils/models/artists.model";
import LoadingOval from "../../../../../components/loading/LoadingOval";
import { ChartData } from "chart.js";
import ChartJsDoughnut from "../../../../../components/Charts/ChartsJsDoughnut";

const StreamingDatas = ( {fetchedDatas }: { fetchedDatas: ArtistAPIDatas }) => {

    const { twitter, spotify, deezer, lastfm, bandsintown, genius, tiktok, youtube, facebook, instagram, totalFollowers } = fetchedDatas

    if(totalFollowers === undefined) {
        return <Flex>
            <LoadingOval />
        </Flex>
    }

    const spotifyFollowers = spotify?.artist?.followers.total
    const lastfmListeners = lastfm?.listeners ? +lastfm.listeners : 0

    const pieData: ChartData<"doughnut"> = {
        labels: [
            'Twitter followers',
            'Spotify followers',
            'Deezer fans',
            'LastFm listeners',
            'BandsInTown followers',
            'Genius followers',
            'Tiktok followers',
            'Youtube subscribers',
            'Facebook followers',
            'Instagram followers',
        ],
        datasets: [{
            data: [
                twitter?.followers ?? 0, 
                spotifyFollowers ?? 0, 
                deezer?.followers ?? 0,
                lastfmListeners,
                bandsintown?.followers ?? 0,
                genius?.followers ?? 0,
                tiktok?.followers ?? 0,
                youtube?.followers ?? 0,
                facebook?.followers ?? 0,
                instagram?.followers ?? 0,
            ],
            backgroundColor: [
            colors.lightblue,
            colors.green,
            colors.gray,
            '#AE002A',
            '#00B4B3',
            'rgb(255, 255, 100)',
            'black',
            colors.red,
            '#38529A',
            colors.main,
            ],
        }],
    }

    return (
        <Flex row>

            {/* Doughnut Area*/}
            {totalFollowers > 0 && 
                <Flex className="width40 margedRight50px">
                    <div className="relative" style={{maxWidth: "300px"}}>
                        <ChartJsDoughnut 
                            data={pieData}
                        />
                        <div className="doughnut-center-div flex">
                            <p className="total-fans-text title-color">Total Fans</p>
                            <p className="doughnut-total-fans">{numberFormatter(totalFollowers, 1)}</p>
                        </div>
                
                    </div>
                </Flex>}

            {/* Right Area */}
            <div className={`${totalFollowers > 0 ? "width60" : "allwidth"}`}>
                <Flex row className="space-evenly">
                    <div>
                        <div className="flex-only row margedBot20px">
                            <div className="margedRight10px" style={{color: '#38529A', fontSize: '35px'}}>{socialIcons.facebook}</div>
                            <div>
                                <a href={`${facebook?.url ?? ''}`} target="_blank" rel="noreferrer noopener" className="socials-data-title">Facebook followers</a>
                                {facebook?.followers ? <p className="socials-data-text">{ls(facebook.followers, true)}</p> : <p className="error">Not found</p>}
                            </div>
                        </div>
                        <div className="flex-only row margedBot20px">
                            <div className="margedRight10px" style={{color: '#00aced', fontSize: '35px'}}>{socialIcons.twitter}</div>
                            <div>
                                <a href={`https://twitter.com/${twitter?.screenName}`} target="_blank" rel="noreferrer noopener" className="socials-data-title">Twitter followers</a>
                                {twitter?.followers ? <p className="socials-data-text">{ls(twitter.followers, true)}</p> : <p className="error">Not found</p>}
                            </div>
                        </div>
                        <div className="flex-only row margedBot20px">
                            <div className="margedRight10px" style={{color: '#00B4B3', fontSize: '35px'}}>{streamingPlatformsIcons.bandsintown}</div>
                            <div>
                                <a href={bandsintown?.url} target="_blank" rel="noreferrer noopener" className="socials-data-title">Bandsintown followers</a>
                                {bandsintown?.followers ? <p className="socials-data-text">{ls(bandsintown.followers, true)}</p> : <p className="error">Not found</p>}
                            </div>
                        </div>
                        <div className="flex-only row margedBot20px">
                            <div className="margedRight10px" style={{color: 'black', fontSize: '35px'}}>{socialIcons.tikTok}</div>
                            <div>
                                <a href={`https://www.tiktok.com/@${tiktok?.name}`} target="_blank" rel="noreferrer noopener" className="socials-data-title">TikTok followers</a>
                                {tiktok?.followers ? <p className="socials-data-text">{ls(tiktok.followers, true)}</p> : <p className="error">Not found</p>}
                            </div>
                        </div>
                        <div className="flex-only row">
                            <div className="margedRight10px" style={{color: '#1ed760', fontSize: '35px'}}>{streamingPlatformsIcons.spotify}</div>
                            <div>
                                <a href={spotify?.artist?.external_urls.spotify} target="_blank" rel="noreferrer noopener" className="socials-data-title">Spotify followers</a>
                                {spotify?.artist?.followers?.total ? <p className="socials-data-text">{ls(spotify.artist.followers.total, true)}</p> : <p className="error">Not found</p>}
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="flex-only row margedBot20px">
                            <div className="margedRight10px" style={{fontSize: '32px'}}>{<InstagramLogo width={30} />}</div>
                            <div>
                                <a href={`${instagram?.url ?? ''}`} target="_blank" rel="noreferrer noopener" className="socials-data-title">Instagram followers</a>
                                {instagram?.followers ? <p className="socials-data-text">{ls(instagram.followers, true)}</p> : <p className="error">Not found</p>}
                            </div>
                        </div>
                        <div className="flex-only row margedBot20px">
                            <div className="margedRight10px" style={{color: '#FF0000', fontSize: '35px'}}>{streamingPlatformsIcons.youtube}</div>
                            <div>
                                <a href={`${youtube?.url ?? ''}`} target="_blank" rel="noreferrer noopener" className="socials-data-title">Youtube subscribers</a>
                                {youtube?.followers ? <p className="socials-data-text">{ls(youtube.followers, true)}</p> : <p className="error">Not found</p>}
                            </div>
                        </div>
                        <div className="flex-only row margedBot20px">
                            <div className="margedRight10px" style={{color: 'gray',fontSize: '35px'}}>{streamingPlatformsIcons.deezer}</div>
                            <div>
                                <a href={deezer?.url} target="_blank" rel="noreferrer noopener" className="socials-data-title">Deezer fans</a>
                                {deezer?.followers ? <p className="socials-data-text">{ls(deezer.followers, true)}</p> : <p className="error">Not found</p>}
                            </div>
                        </div>
                        <div className="flex-only row margedBot20px">
                            <div className="margedRight10px" style={{color: 'rgb(255, 255, 100)', fontSize: '35px'}}>{streamingPlatformsIcons.genius}</div>
                            <div>
                                <a href={genius?.url} target="_blank" rel="noreferrer noopener" className="socials-data-title">Genius followers</a>
                                {genius?.followers ? <p className="socials-data-text">{ls(genius.followers, true)}</p> : <p className="error">Not found</p>}
                            </div>
                        </div>
                        <div className="flex-only row">
                            <div className="margedRight10px" style={{color: '#AE002A', fontSize: '35px'}}>{streamingPlatformsIcons.last}</div>
                            <div>
                                <a href={lastfm?.url} target="_blank" rel="noreferrer noopener" className="socials-data-title">Lastfm listeners</a>
                                {lastfm?.listeners
                                ?   <>
                                        <p className="socials-data-text">{ls(lastfm.listeners, true)}</p>
                                        {/* <p className="socials-data-subtext">({ls(lastfm.playcount, true)} playcount)</p> */}
                                    </>
                                :   <p className="error">Not found</p>}
                            </div>
                        </div>
                    </div>
                </Flex>
            </div>

        </Flex>
    )
}

export default StreamingDatas