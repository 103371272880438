import { ROUTE_ARTISTS, ROUTE_CONTRACTS, ROUTE_OFFERS, ROUTE_DASHBOARD, ROUTE_TOURING, ROUTE_AGENDA, ROUTE_VENUES, ROUTE_PNLS } from "../../routes/routes.const"
import { useAppSelector } from "../../utils/hooks/reduxTypedHooks";
import { OfferStatus } from "../../utils/models/offer.model";
import getContractStatus from "../admin/admin-panel-screens/contracts/get-contract-status";

import HomePanel from "../common/HomePanel";

const PromotorPanel = () => {

    const offers = useAppSelector(state => state.offers)
    const contracts = useAppSelector(state => state.contracts)
    const client = useAppSelector(state => state.client)
    const promotor = client?.promotor

    const offersBadge = offers?.length && (offers.filter(o => (
        (o?.status === OfferStatus.PromotorTrusteeCheck)
        || (o?.status === OfferStatus.RequestEdition)
    ))).length

    const contractsBadge = contracts?.length && (
        contracts.filter(c => {
            const cStatus = getContractStatus(c);
            
            return (
                (cStatus === 'waiting purchaser signature')
                || (cStatus === 'waiting both signatures')
            )
        })
    ).length;

    const profilePicture = promotor?.logo ? promotor.logo : undefined

    const screens = {
        [ROUTE_DASHBOARD]: {
            title: 'Dashboard',
            icon: 'tabler:smart-home',
        },
        [ROUTE_OFFERS]: {
            title: 'Offers',
            icon: 'tabler:shopping-cart',
            badge: offersBadge
        },
        [ROUTE_PNLS]: {
            title: 'Pnls',
            icon: 'material-symbols:query-stats'
        },
        [ROUTE_CONTRACTS]: {
            title: 'Contracts',
            icon: 'mdi:contract-outline',
            badge: contractsBadge 
        },
        [ROUTE_AGENDA]: {
            title: 'Agenda',
            icon: 'tabler:calendar',
        },
        // [ROUTE_TASKS]: {
        //     title: 'Tasks', 
        // },
        [ROUTE_ARTISTS]: {
            title: 'Artists',
            icon: 'mdi:artist-outline',
        },
        [ROUTE_TOURING]: {
            title: 'Touring',
            icon: 'ic:outline-travel-explore',
        },
        // [ROUTE_VENUES]: {
        //     title: 'Venues', 
        //     icon: 'mdi:place-outline'
        // },
    }

        return <HomePanel screens={screens} profilePicture={profilePicture} />
}

export default PromotorPanel;