import { useRouteError } from "react-router-dom";
import NotFoundPage from "./NotFoundPage"

export default function ErrorPage() {
  const error: any = useRouteError()
  console.error(error)
  if(error.status === 404){
    return <NotFoundPage />
  } else {

      return (
        <div>
          <h1>Oops!</h1>
          <p>Sorry, an unexpected error has occurred.</p>
          <p>
            <i>{error?.statusText || error?.message}</i>
          </p>
        </div>
      )
  }

}