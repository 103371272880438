import axios from "axios";
import { primuseApi } from "../constants";

/**
* Fetch Primuse server /api routes with a specific request route, method and body(post datas)
* @param {object} fetchConfig {body, route, method}
* @param {object} options
*   - returnServerError (bool) : return error from server
*   - contentType (enum) : the content type to fetch (ex. "application/pdf")
*   - acceptHeader : custom accept header
*   - token: client access token (if route required)
* @return {object} {error, response, status}
*/
export const fetchApi = async(fetchConfig, options) => {
    console.log('fetchApi called with', {fetchConfig, options})
    const {body, route, method} = fetchConfig;
    const { returnServerError, contentType, acceptHeader, token, onExpired } = options || {};
    try {

            let url = await promisify(`${primuseApi}/${route}`);
            console.log('FETCHING', url);
            const isPost = await promisify((method && (method.toLowerCase() === 'post')) || !method); 
            let fetchOptions = await promisify(
                {
                    method: method || 'POST',
                    mode: 'cors',
                    headers: {
                        Accept: acceptHeader || 'application/json',
                        'Content-Type': contentType || 'application/json',
                        ...(token ? {'X-Auth-Token': token} : {})
                    },
                    // headers: {
                    //     //'Origin': window.location.href,   
                    //     ...(
                    //         isPost
                    //         ? {
                    //             Accept: acceptHeader || 'application/json',
                    //             'Content-Type': contentType || 'application/json',
                    //             ...(token ? {'X-Auth-Token': token} : {})
                    //         }
                    //         : {}
                    //     )

                    // },
                    
        
                    body: isPost 
                        ? JSON.stringify(body)
                        : undefined
                }
            )
            console.log('fetchOptions', fetchOptions);
            const expectPdf = await promisify(contentType === 'application/pdf')
            let fetching = await fetch(url, fetchOptions);
            let error = await promisify(
                !fetching.ok 
                && (
                    returnServerError
                    ? fetching.json()
                    : "There was an error fetching Primuse API" 
                )
             );
 
            error = error?.error || error;
            let response = !error && await fetching[expectPdf ? 'blob' : 'json']();
 
            const status = fetching.status;

            if ((status === 511) && onExpired) {
                onExpired();
                return { status };  // @note: login should be triggered when using `fetchApiAuthentified` method
            }
 
            return (
                {response, error, status}
               
            );

    }catch(e) {
        const status = e.toString().includes('INVALID TOKEN') ? 511 : 500;
        if ((status === 511) && onExpired) 
            onExpired();
        return(
            {error: e, status}
        )
    }
}


/**
 * @dev fetchApi Authentified (React Scope version handling token expired case)
 * @param {string} token 
 * @param {function} onExpired expired access token handler (fire login)
 * @return {function} fetchApi authentified
 * @notice import as `const fetchApiAuth = fetchApiAuthentified(token, handleAccessTokenExpired)`
 */
export const fetchApiAuthentified = (token, onExpired) => (
    async(config, options) => fetchApi(config, {...(options||{}), token, onExpired})
)

const _mapAsync = async(array, asyncFun) => {
    const arrProms = await promisify(
        // (array||[]).map(async (e, i) => await asyncFun(e, i))
        (array||[]).map(asyncFun)
    )
    const arr = await Promise.all(arrProms);
    return arr;
}
export const mapAsync = _mapAsync;

export const forEachAsync = async(array, asyncFun) => {
    const arrProms = await promisify(
        (array||[]).map(asyncFun)
    )
    await Promise.all(arrProms);
}


export const mapAndReduceAsync = async(array, asyncFun, reducer, _default) => {
    const mapping = await _mapAsync(array, asyncFun);
    const reduced = await promisify(
        mapping?.length
        ? mapping.reduce(reducer)
        : _default
    );
    return reduced;
}

export const mapAndFilterAsync = async(array, asyncFun, filterer) => {
    const mapping = await mapAsync(array, asyncFun);
    const filtered = await Promise.resolve(
        mapping?.length
        ? mapping.filter(filterer)
        : array
    );
    return filtered;
}

export const promisify = async any => await Promise.resolve(any);

export const plural = (str, len) => `${str}${len>1 ? 's' : ''}`;


export const b64toBlob = async(b64Data, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
  
    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
}

//@Params val: number, comma: boolean
export const ls = (val, comma) => {
    if(typeof val === 'undefined') return '';

    const dCut = parseFloat(val).toFixed(2).toString().split('.');

    const spl = dCut[0].toString().split('').reverse();
    
    const wSpace = spl.map((e, i) => (((i && (i%3) === 0)) ? `${e}${comma ? ',' : ' '}` : e));
    const addDec = parseInt(dCut[1]) > 0;
    return `${wSpace.reverse().join('')}${addDec ? `.${dCut[1]}` : ''}`;

    
}

export const lsToFlat = (lsedVal) => {
    const noSpacesString = lsedVal.replace(/\s/g, '')
    return +noSpacesString
}

export const shortenAddress = address => `${address.slice(0,5)}...${address.slice(38,42)}`


export const sanitizeObject = (object, saneKeysList) => {
    const sanitizedArray = Object.keys(object).map((key) => {
        return saneKeysList.indexOf(key) >= 0 ? {[key] : object[key]} : {}
    })
    const sanitizedObject = sanitizedArray.reduce((a, b) => ({...a, ...b}))
    return sanitizedObject
}


/**
 * 
 * @param {imageData} selectedFile 
 * @returns {object} { error, response } any error or response: url of the hosted public image
 */
 export const hostImage = async(selectedFile, token) => {
    try {
        let formData = new FormData();
        formData.append('image', selectedFile);
        const axiosResponse = selectedFile && await axios.post(
            `${primuseApi}/host-image`,
            formData,
            {
                headers: { "Content-Type": "multipart/form-data", 'X-Auth-Token': token } 
            }
        )

        // return the app s3 url
        return { response: axiosResponse?.data };
    }catch(error) {
        return { error }
    }
}

/// UNUSED ///
// export const base64UrlToArrayBuffer = async dataURI => {
//     let base64 = dataURI.split(',')[1];
//     const binary_string = window.atob(base64);
//     const len = binary_string.length;
//     let bytes = new Uint8Array(len);
//     for (let i = 0; i < len; i++) {
//         bytes[i] = binary_string.charCodeAt(i);
//     }
//     return bytes.buffer;
// }


/**
 * @dev Try parseing json string handling non-parseable cases
 * @param {JSONString} str 
 * @returns {object|string} Object if parseable `str`, else defaulting to str;
 */
export const parseJson = (str) => {
    try {
        return JSON.parse(str)
    }catch(e) {
        return str
    }
}

/**
 * @dev Data Accumulation (used to compute timeline charts datas)
 * @param {array} arr base data
 * @returns {array} stacked data
 */
export const stackData = (arr, useProp) => {
    const dta = useProp ? arr.map(e => e[useProp]) : arr
    const accumulation = arr.map((e, i) => (
        i < 1
        ? e
        : (
            useProp
            ? {...e, [useProp]: ( dta[i] + dta.slice(0, i).reduce((a, b) => ( a + b )) )}
            : e + arr.slice(0,i).reduce((a, b) => ( a + b ))
        )
    ))

    return accumulation;
}

// Async Version
// export const stackData = async arr => {
//     const accumulation = await mapAsync(
//         arr,
//         (e, i) => (
//             i < 1
//             ? e
//             : ( e + arr.slice(0, i).reduce((a, b) => ( a + b )) )
//         )
//     )

//     return accumulation;
// }

export const unix = (time) => new Date(time).getTime()


export const hasOneMatchInTwoArrays = (array1, array2) => {
    for(let i = 0 ; i < array1.length ; i++){
        if(array2.includes(array1[i])){
            return true
        }
    }
    return false
}

export const numberFormatter = (num, digits) => {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: " M" },
      { value: 1e9, symbol: " B" },
    ];
    const regex = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function(item) {
      return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(regex, "$1") + item.symbol : "0";
  }

export const clipString = (string, maxLength) => {
    if(string.length <= maxLength){
        return string
    } else {
        return `${string.substring(0,maxLength)}...`
    }
}

export const pnlAmountFormatter = (num) => {
    if(num < 10000000) { return ls(num)}
    else {
        return numberFormatter(num, 2)
    }
}