import { Flex, Row } from "../../../../components/Containers"

import useFilterByContinent from "../../../../utils/hooks/useFilterByContinents"
import VenuesList from "./VenuesList"
import PageLoading from "../../../../components/loading/PageLoading"
import { useEffect, useState } from "react"
import { ls, mapAndReduceAsync, promisify } from "../../../../utils/utils"
import useFilter from "../../../../utils/hooks/useFilter"
import SelectInput from "../../../../components/form/form-components/SelectInput"
import { countries } from "../../../../utils/models/touring.model"

const AdminVenues = () => {

    const [minifiedVenuesByCountryCode, setMinifiedVenuesByCountryCode] = useState({});
    const [minifiedVenues, setMinifiedVenues] = useState([]);
    const [loading, setLoading] = useState(true);
    const [_countriesFilter, setCountriesFilter] = useState([]);
    const [citiesFilter, setCitiesFilter] = useState([]);
    
    useEffect(() => {
        (async() => {
            const _minifiedVenuesByCountryCode = await import('../../../../temporary-storage/minified_all_venues_by_countrycode.json')
            const vcc = await promisify(Object.keys(_minifiedVenuesByCountryCode));
            const allMinVenues = await mapAndReduceAsync(
                vcc.splice(0, vcc.length-2),        // remove last 'default' prop injected by Module import
                // @ts-ignore
                async cc => await promisify(_minifiedVenuesByCountryCode[cc]||[]),
                (a:any, b:any) => [...a, ...(b||[])]
            )
            setMinifiedVenues(allMinVenues)
            setMinifiedVenuesByCountryCode(_minifiedVenuesByCountryCode);
            setLoading(false)
        })()
    }, [])

    const addCountriesFilter = async(_countriesFilter:any) => {
        setCountriesFilter(_countriesFilter.map((cf:any) => cf.value))
    }

    const addCitiesFilter = async(_citiesFilter:any) => {
        setCitiesFilter(_citiesFilter.map((cf:any) => cf.value))
    }
    
    const { FilterUI: continentFilterUI, filteredCountries, isEverywhere } = useFilterByContinent();
    const countriesFilter = _countriesFilter?.length && _countriesFilter.filter(((cc:any) => filteredCountries.includes(cc)))
    /////// @TODO? handle client scroll lazy load to speed-up renderer ///////
    const _filteredVenues = (
        isEverywhere
        ? minifiedVenues   
        : filteredCountries
            .map(cc => {        // @ts-ignore
                const includedCountry = !countriesFilter?.length || countriesFilter.includes(cc);
                
                return(
                    includedCountry
                    ? (
                        citiesFilter?.length    
                        ? ( //@ts-ignore
                            minifiedVenuesByCountryCode[cc]?.length //@ts-ignore
                            ? minifiedVenuesByCountryCode[cc].filter((mv:any) => citiesFilter.includes(mv.city?.name)) 
                            : []
                        )
                        //@ts-ignore
                        : minifiedVenuesByCountryCode[cc]||[]
                    )
                    : []
                )
            })  // @ts-ignore
            .reduce((a:any, b:any) => [...a, ...b])
    )

    const { filterInput, FilterUI:TextInputUI} = useFilter('Venue name, address, capacity...')

    const _citiesSelectOptions = (
        !isEverywhere
        && filteredCountries  // @ts-ignore
            .filter((fc:any) => ((!countriesFilter?.length) || countriesFilter.includes(fc))) 
            .map((cc:any) => {  // @ts-ignore
                const cvs = minifiedVenuesByCountryCode[cc];
                const citiesOptions = (
                    cvs?.length
                    ? (
                        Array.from(new Set(cvs.map((_cv:any) => _cv.city?.name)))       // @ts-ignore
                            .filter(n => n)
                            .map((cityName:any) => ({label: cityName, value: cityName}))
                    )
                    : []
                )
                return citiesOptions;
            })
           
    )
    const citiesSelectOptions = (   // @ts-ignore
        _citiesSelectOptions?.length && _citiesSelectOptions.reduce((a, b) => [...a, ...b])
    )

    const filteredVenues = (
        (_filteredVenues?.length && (filterInput?.length >=3))
        ? _filteredVenues.filter((v:any) => JSON.stringify(v).toLowerCase().includes(filterInput.toLowerCase()))
        : _filteredVenues
    )

    return (
        loading
        ? <PageLoading />
        : <div className="main-container margedBot100px">
            <h3 className="page-title margedTop30px">Venues</h3>

            <Flex className="allwidth app-card margedTop50px margedBot50px">
                <Row className="spaceevenly">
                    <Flex flex={4} className="width70 margedBot20px">
                        { TextInputUI }
                        <p className="margedTop20px">{ls(filteredVenues.length)} Results</p>
                    </Flex>
                    <Flex flex={1}>&nbsp;</Flex>
                    <Flex flex={5}>
                        {continentFilterUI}
                    </Flex>
                </Row>
                <Row className="spaceevenly">
                    <Flex className="width70 margedTop40px">
                        {
                   
                            !isEverywhere
                            && <SelectInput 
                                multi
                                noTitle
                                searchable
                                showRawValue
                                title='Selected Countries'  // @ts-ignore
                                value={countriesFilter?.length && countriesFilter.map((fc:any) => ({
                                    label: countries[fc],
                                    value: fc
                                }))}
                                onChange={addCountriesFilter}
                                options={
                                    filteredCountries.map((fc:any) => ({
                                        label: countries[fc],
                                        value: fc
                                    }))
                                }
                            />
                        }
                    </Flex>
                    &nbsp;
                    <Flex className="width70 margedTop40px">
                        {
                          
                            !isEverywhere
                            && <SelectInput 
                                multi
                                noTitle
                                searchable
                                title='Selected Cities'
                                value={null}
                                onChange={addCitiesFilter}
                                // @ts-ignore
                                options={   
                                    citiesSelectOptions
                                }
                               
                            />
                        }
                    </Flex>
                </Row>
            </Flex> 
            {
                minifiedVenues?.length > 0
                ?   <div>
                        <VenuesList 
                            venues={filteredVenues}
                        />
                    </div>
                : <p className="grey">No venues are matching your selection.</p>
            }
        </div>
    )
}

export default AdminVenues