import { axiosGetRequest } from "../api.utils";

export const fetchArtistAppleInfos = async (appleId: string) => {
    const url = 'https://api.primuse.tech/v1/stm/am/szapi/artists'
    const params = { 
        id: appleId,
        extend: 'artistBio,bornOrFormed,editorialArtwork,origin',
        include: 'albums,genres,music-videos,playlists,station',
        views: 'featured-playlists,appears-on-albums,full-albums,appears-on-albums,featured-albums,singles,compilation-albums,live-albums,latest-release,top-music-videos,similar-artists,top-songs,playlists'
    }
    const response = await axiosGetRequest(url, params)
    return response
}

export const fetchArtistTwitterInfos = async (twitterName: string) => {
    const url = 'https://api.primuse.tech/v1/sm/tw/users/show'
    const params = { name: twitterName }
    const response = await axiosGetRequest(url, params)
    return response
}

export const fetchSpotifyArtistInfos = async (spotifyId: string) => {
    console.time('SpotifyInfos')
    const url = 'https://api.primuse.tech/v1/stm/sf/artists'
    const params = { id: spotifyId }
    const response = await axiosGetRequest(url, params)
    console.timeEnd('SpotifyInfos')
    return response
}
export const fetchSpotifyArtistAlbums = async (spotifyId: string) => {
    console.time('SpotifyAlbums')
    const url = 'https://api.primuse.tech/v1/stm/sf/artist/albums'
    const params = { 
        id: spotifyId,
        album_type: 'album,compilation',
        limit: '20',
        offset: '0',
    }
    const response = await axiosGetRequest(url, params)
    console.timeEnd('SpotifyAlbums')
    return response
}
export const fetchSpotifySimilarArtists = async (spotifyId: string) => {
    console.time('SpotifySimilarArtists')
    const url = 'https://api.primuse.tech/v1/stm/sf/artist/artistRelated'
    const params = { id: spotifyId }
    const response = await axiosGetRequest(url, params)
    console.timeEnd('SpotifySimilarArtists')
    return response
}
export const fetchSpotifyTopTracks = async (spotifyId: string, countryCode: string) => {
    console.time('SpotifyTopTracks')
    const url = 'https://api.primuse.tech/v1/stm/sf/artist/artistTopTracks'
    const params = { 
        id: spotifyId,
        country: countryCode
     }
    const response = await axiosGetRequest(url, params)
    console.timeEnd('SpotifyTopTracks')
    return response
}

export const fetchArtistDeezerInfos = async (deezerId: string) => {
    const url = 'https://api.primuse.tech/v1/stm/dz/artist'
    const params = { id: deezerId }
    const response = await axiosGetRequest(url, params)
    return response
}

export const fetchArtistLastfmInfos = async (lastfmName: string) => {
    const url = 'https://api.primuse.tech/v1/oth/lf/artistInfo?'
    const params = { artist: lastfmName }
    const response = await axiosGetRequest(url, params)
    return response
}

export const fetchBandsintownArtistInfos = async (artistName: string) => {
    const url = `https://api.primuse.tech/v1/oth/bt/artist/${artistName}`
    const response = await axiosGetRequest(url)
    return response
}
export const fetchBandsintownArtistEvents = async (artistName: string) => {
    const url = `https://api.primuse.tech/v1/oth/bt/artist/${artistName}/events`
    const response = await axiosGetRequest(url)
    return response
}
export const fetchArtistBandsintownPastEvents = async (artistName: string) => {
    const url = `https://api.primuse.tech/v1/oth/bt/artist/${artistName}/events`
    const params = {date: 'past'}
    const response = await axiosGetRequest(url, params)
    return response
}

export const fetchArtistGeniusInfos = async (geniusId: string) => {
    const url = "https://api.primuse.tech/v1/oth/gn/artists"
    const params = {id: geniusId}
    const response = await axiosGetRequest(url, params)
    return response
}

export const fetchTiktokInfos = async (name: string) => {
    const url = `https://api.primuse.tech/v1/sm/tt/user/${name}`
    const response = await axiosGetRequest(url)
    return response
}


export const fetchArtistFromMarcoDB = async (queryName: string) => {
    const url = `https://api.primuse.tech/v1/data/artists/${queryName}?limit=500`
    const response = await axiosGetRequest(url)
    return response
}


export const fetchCountryInfos = async (countryCode: string) => {
    const url = `https://api.primuse.tech/v1/data/countries/${countryCode}`
    const response = await axiosGetRequest(url)
    return response
}