import { pnlAmountFormatter } from "../../../../../utils/utils"

const ViewRevenuesTable = ({ currency, currencySymbol, rate, pourcentagesArray, ticketsSoldArray, ticketCollectionsArray, totalRevenueArray, sponsorAmount, otherIncomesAmount, corporateTicketAmount, corporateTicketPurchase }: {
    currency: string,
    currencySymbol: null|string,
    rate: null|number,
    pourcentagesArray: number[],
    ticketsSoldArray: number[],
    ticketCollectionsArray: number[],
    totalRevenueArray: number[],
    sponsorAmount: number,
    otherIncomesAmount: number,
    corporateTicketAmount: number,
    corporateTicketPurchase: {has: boolean, amount: number},
}) => {

    const totalRevenueUSDArray = totalRevenueArray.map(revenue => rate ? revenue/rate : 0)

    return (
        <table className="app-table">
            <thead>
                <tr>
                    <th style={{width: "15%"}} className="leftalign">Revenue</th>
                    {pourcentagesArray.map(pourcentage => <th key={pourcentage}  style={{width: "8%"}} className="leftalign">{pourcentage}%</th>)}
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th className="leftalign">Number of tickets sold</th>
                    {ticketsSoldArray.map((ticketsSold, index) => <th key={index} className="leftalign green-color">{ticketsSold}</th>)}
                </tr>
                <tr>
                    <th className="leftalign">Ticket collection ({currencySymbol ?? currency})</th>
                    {ticketCollectionsArray.map((ticketCollection, index) => <th key={index} className="leftalign green-color">({currencySymbol ?? currency}) {pnlAmountFormatter(ticketCollection)}</th>)}
                </tr>
                <tr>
                    <th className="leftalign">Sponsorship</th>
                    {pourcentagesArray.map(pourcentage => <th key={pourcentage} className="leftalign green-color">({currencySymbol ?? currency}) {pnlAmountFormatter(sponsorAmount)}</th>)}
                </tr>
                <tr>
                    <th className="leftalign">Other Incomes</th>
                    {pourcentagesArray.map(pourcentage => <th key={pourcentage} className="leftalign green-color">({currencySymbol ?? currency}) {pnlAmountFormatter(otherIncomesAmount)}</th>)}
                </tr>
                <tr>
                    <th className="leftalign">
                        <div className="flex-align-center row">
                            <div className="width70">
                                <p>Corporate Ticket Purchase</p>
                            </div>
                            <div className="width30">
                                <p>{corporateTicketPurchase.has ? "(Yes)" :"(No)"}</p>
                            </div>

                        </div>
                    </th>
                    {pourcentagesArray.map(pourcentage => <th key={pourcentage} className="leftalign green-color">({currencySymbol ?? currency}) {pnlAmountFormatter(corporateTicketAmount)}</th>)}
                </tr>
                <tr>
                    <th className="leftalign">Total Revenue ({currencySymbol ?? currency})</th>
                    {totalRevenueArray.map((totalRevenue, index) => <th key={index} className="leftalign green-color">{currencySymbol ?? currency} {pnlAmountFormatter(totalRevenue)}</th>)}
                </tr>
                <tr>
                    <th className="leftalign">Total Revenue (USD)</th>
                    {totalRevenueUSDArray.map((totalRevenue, index) => <th key={index} className="leftalign green-color">${pnlAmountFormatter(totalRevenue)}</th>)}
                </tr>
            </tbody>
        </table>
    )
}

export default ViewRevenuesTable