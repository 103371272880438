import { Flex } from "../../../../../components/Containers"
import { Feedback } from "../../../../../utils/models/feedbacks.model"

const FeedbackView = ({ feedback, close, handleDelete }: { feedback: Feedback, close: () => void, handleDelete: (event: any) => void }) => {

    return (
        <div className="main-container">

            <p className="leftalign margedBot50px">
                {feedback.text}
            </p>

            <Flex row className="allwidth">
                <button onClick={close} className="button button--round button--primary margedRight50px">Close</button>
                <button onClick={handleDelete} className="button button--round button--danger">Delete Feedback</button>
            </Flex>
        </div>
    )
}

export default FeedbackView