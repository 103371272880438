import { ThreeDots } from "react-loader-spinner"
import { Flex } from "../../../../../components/Containers"
import SelectInput from "../../../../../components/form/form-components/SelectInput"
import LabelInput from "../../../../../components/form/mui/label-input/LabelInput"
import { colors } from "../../../../../constants"
import useSetSnack from "../../../../../utils/hooks/useSetSnack"
import { OfferShowFormInputs } from "../../../../../utils/models/offer.model"
import { TouringShowDurationsList, TouringShowTypesList } from "../../../../../utils/models/touring.model"
import { ls } from "../../../../../utils/utils"

const ShowInfoStep = ({ os, osi, rate, currencySymbol, isReviewing, isLocalReviewing, setOfferShow, goToNextStep, goBackToReview, goBackToLocalReview }:
    { 
        os: OfferShowFormInputs, 
        osi: number,
        rate: null|number,
        currencySymbol: null|string,
        isReviewing: boolean,
        isLocalReviewing: boolean,
        setOfferShow: (showIndex: number, showProps: {}) => void,
        goToNextStep: () => void,
        goBackToReview: () => void,
        goBackToLocalReview: () => void,
    }) => {

    const setSnack = useSetSnack()

    const showTypesList = TouringShowTypesList.map(showtype => ({value: showtype, label: <div><b>{showtype}</b></div>}))
    const showDurationsList = TouringShowDurationsList.map(duration => ({ value: duration, label: <div><b>{duration} Minutes</b></div> }))

    const checkIfInfosValid = () => {
        if(!os.eventName) {
            setSnack({type: 'warning', content: 'Please enter a name for the event'})
            return false
        }
        if(!os.minAge) {
            setSnack({type: 'warning', content: 'Please fill the visitors minimum age field'})
            return false
        }
        if(!os.showDuration) {
            setSnack({type: 'warning', content: 'Please enter a Show Duration'})
            return false
        }
        if(!os.showTypes.length) {
            setSnack({type: 'warning', content: 'Please enter at least 1 Show Type'})
            return false
        }

        return true
    }
    const handleNext = () => {
        const areTermsValid = checkIfInfosValid()
        if(!areTermsValid) {
            return
        }

        goToNextStep()
    }

    const handleGoBackToReview = (isLocalReview: boolean) => {
        const areTermsValid = checkIfInfosValid()
        if(!areTermsValid) {
            return
        }

        if(isLocalReview) {
            goBackToLocalReview()
        } else {
            goBackToReview()
        }
    }

    return (
        <Flex className="alignstart allwidth">

            <div className="title main-color margedBot5px">Terms</div> 

            <div className="allwidth grid-2-col alignend grid-small-col-gap margedBot50px">
                <LabelInput
                    title='Event Name*'
                    value={os.eventName}
                    onChange={(e:any) => setOfferShow(
                        osi, 
                        { eventName: e.target.value }
                    )}
                />

                <LabelInput
                    title='Visitor Min Age*'
                    value={os.minAge}
                    onChange={(e:any) => setOfferShow(
                        osi, 
                        { minAge: e.target.value }
                        )}
                />

                <SelectInput 
                    options={showDurationsList}
                    title='Show Duration'
                    value={os.showDuration}
                    onChange={(e:any) => setOfferShow(
                        osi, 
                        { showDuration: e }
                        )}
                    showRawValue
                />
                
                <SelectInput 
                    options={showTypesList}
                    title='Show Types'
                    value={os.showTypes}
                    onChange={(e:any) => setOfferShow(
                        osi, 
                        { showTypes: e }
                        )}
                    showRawValue
                    multi
                />

                <LabelInput
                    title='Local Currency'
                    value={`${os.localCurrency} ${currencySymbol ? `(${currencySymbol})` : ""}`}
                    onChange={() => {}}
                    disabled
                />
                <div className="flex-align-center row">
                    <p className="margedRight5px">Rate : </p>
                    {rate
                    ?   <p>US$1 = {currencySymbol ?? os.localCurrency}{ls(rate)}</p>
                    :   <Flex><ThreeDots 
                            height="20" 
                            width="30" 
                            radius="9"
                            color={colors.gray} 
                        /></Flex>}
                </div>
                
            </div>

            <Flex row className="allwidth">
                {isReviewing
                ?   <button className="button button--primary button--round margedRight100px" onClick={() => handleGoBackToReview(false)}>Apply</button>
                :   isLocalReviewing
                    ?   <button className="button button--primary button--round margedRight100px" onClick={() => handleGoBackToReview(true)}>Apply</button>
                    :   <button className="button button--primary button--round" onClick={handleNext}>Next</button>}
            </Flex>
        </Flex>
    )
}

export default ShowInfoStep