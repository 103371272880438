import { NavLink, useNavigate } from "react-router-dom"
import { ROUTE_HOME } from "../routes/routes.const"

const AccessDeniedPage = () => {

    const navigate = useNavigate()

    const handleGoBack = () => {
        navigate(-1)
    }

    return(
        <div className="flex allspace">
            <h1 style={{marginBottom: '50px'}}>Access Denied</h1>
            <p style={{marginBottom: '20px'}}>You do not have access to see this page.</p>
            <div>You can either <button className="button" onClick={handleGoBack}>Go back</button> or <NavLink to={ROUTE_HOME}>Go to the home page</NavLink></div>
        </div>
    )
}

export default AccessDeniedPage