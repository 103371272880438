import { MdOutlineAddTask } from "react-icons/md";
import { Flex } from "../../Containers";
import { useDispatch } from "react-redux";
import { snackActions } from "../../../store/snackSlice";
import { useState } from "react";
import { ArtistContact } from "../../../utils/models/artists.model";
import { ImCross } from "react-icons/im"
import LabelInput from "../mui/label-input/LabelInput";
import { colors } from "../../../constants";

const ContactsArrayInput = ({ title, value, renderer, onChange}: { title?: string, value: any[], renderer: Function, onChange: Function}) => {

    const [contactInput, setContactInput] = useState({agency: '', label: '', management: ''})

    const handleRemoveElement = (indexToRemove: number) => {
        const updatedArray = value.filter((_, index) => index !== indexToRemove)
        onChange(updatedArray)
    }

    const dispatch = useDispatch()
    const setSnack = (snack:any) => dispatch(snackActions.set(snack))

    return (
        <Flex className="allwidth">
            {title && <label className="selfstart bold label-color">{ title }</label>}

            <Flex className="alignstart allwidth">
            {
                value.map((element:ArtistContact, index:number) => <Flex key={`inpaliasesArray_i${index}`} row>
                    {/* removable row */}
                    { renderer(element) }
                    <ImCross size={10} onClick={(e: React.MouseEvent<SVGElement, MouseEvent>) => handleRemoveElement(index)} color={colors.red} className="array-input__cross-icon clickable"/>
                </Flex>)
            }
            </Flex>
            <Flex row className="selfstart allwidth alignend"> 
                <div className="allwidth grid-3-col grid-small-col-gap margedRight20px">
                    <LabelInput
                        value={contactInput.agency}
                        onChange={(e:React.ChangeEvent<HTMLInputElement>) => setContactInput(prevInput => ({...prevInput, agency: e.target.value}))} 
                        title='agency'
                    />
                    <LabelInput
                        value={contactInput.label}
                        onChange={(e:React.ChangeEvent<HTMLInputElement>) => setContactInput(prevInput => ({...prevInput, label: e.target.value}))} 
                        title='label'
                    />
                    <LabelInput
                        value={contactInput.management}
                        onChange={(e:React.ChangeEvent<HTMLInputElement>) => setContactInput(prevInput => ({...prevInput, management: e.target.value}))} 
                        title='management'
                    />
                </div>
                <MdOutlineAddTask
                    size={20} 
                    className="clickable main-color" 
                    onClick={() => {
                        if (contactInput.agency || contactInput.label || contactInput.management) {
                            const newContact = {agency: contactInput.agency, label: contactInput.label, management: contactInput.management}
                            onChange([...value, newContact]);
                            setContactInput({agency: '', label: '', management: ''});
                        }
                        else setSnack({ type: 'warning', content: 'You need to fill at least one field' })
                    }} 
                />
            </Flex>
            
        </Flex>
    )
}

export default ContactsArrayInput