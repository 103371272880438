import { configureStore } from '@reduxjs/toolkit';
import clientReducer from './clientSlice';
import artistsReducer from './artistsSlice';
import archivedArtistsReducer from './archivedArtistsSlice';
import contractsReducer from './contractsSlice';
import snackReducer from './snackSlice';
import openedContractReducer from './openedContractSlice';
import promotorsReducer from './promotorsSlice';
import archivedPromotorsReducer from './archivedPromotorsSlice';
import touringsReducer from './touringsSlice';
import potentialTouringsReducer from './potentialTouringsSlice';
import touringLocationDropdownReducer from './touringLocationDropdownSlice';
import touringShowDurationDropdownReducer from './touringShowDurationDropdownSlice';
import usersReducer from './usersSlice';
import archivedUsersReducer from './archivedUsersSlice';
import offersReducer from './offersSlice';
import requestsReducer from './requestsSlice'
import venuesReducer from './venuesSlice'
import feedbacksReducer from './feedbacksSlice'
import offerDraftsReducer from './offerDraftsSlice';

const store = configureStore({
  reducer: {
    users: usersReducer,
    archivedUsers: archivedUsersReducer,
    client: clientReducer,
    artists: artistsReducer,
    archivedArtists: archivedArtistsReducer,
    contracts: contractsReducer,
    promotors: promotorsReducer,
    archivedPromotors: archivedPromotorsReducer,
    snack: snackReducer,
    openedContract: openedContractReducer,
    tourings: touringsReducer,
    potentialTourings: potentialTouringsReducer,
    touringLocationDropdown: touringLocationDropdownReducer,
    touringShowDurationDropdown: touringShowDurationDropdownReducer,
    offers: offersReducer,
    offerDrafts: offerDraftsReducer,
    requests: requestsReducer,
    venues: venuesReducer,
    feedbacks: feedbacksReducer,
  }
});

export default store;

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch