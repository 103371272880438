import { Flex } from "../../../../components/Containers"
import { useAppSelector } from "../../../../utils/hooks/reduxTypedHooks"
import { Artist } from "../../../../utils/models/artists.model"
import ArtistItem from "./ArtistItem"
import PromotorFeedback from "../../common/feedback/PromotorFeedback"
import useArtistSearchInput from "../../../../utils/hooks/artists/useArtistsSearchInput"

//! styles in user-panels/admin/admin-panels-screens/artists/artists.css

const ArtistsList = () => {

    const artistsList = useAppSelector(state => state.artists)

    const { searchInput, artistSearchUI } = useArtistSearchInput()

    const filteredArtists = artistsList.filter(artist => {
        const lowerCFilter = searchInput.toLowerCase()
        return artist.name.includes(lowerCFilter)
    })
    
    const renderArtist = (artist: Artist, artistind: number) => <ArtistItem key={artistind} artist={artist} />

    return (
        <Flex flex={10} className='allwidth'>
                <div className="main-container margedBot100px">
                    <div className="margedTop20px">
                        <PromotorFeedback page="Artists" />
                    </div>
                    
                    <h3 className="page-title">Search For Artists</h3>

                    <div className="margedBot30px">
                        {artistSearchUI}
                    </div>

                    <div className="artists-list__grid">
                        {filteredArtists
                        ? filteredArtists.map(renderArtist)
                        : <p className="grey">No artists found</p>}                     
                    </div>

                    {/* Bottom tabs */}
                    {/* <div className="margedTop40px leftalign">
                        <h3 className="margedBot20px">Browse Tours</h3>
                        <div className="flex-only row wrap" style={{columnGap: '20px', rowGap: '20px'}}>
                            <div className="list-box-card">
                                Asia
                            </div>
                            <div className="list-box-card">
                                Americas
                            </div>
                            <div className="list-box-card">
                                Oceania
                            </div>
                            <div className="list-box-card">
                                Europe
                            </div>
                            <div className="list-box-card">
                                Africa
                            </div>
                        </div>
                    </div> */}
                    
                </div>
                        
        </Flex>
    )
}

export default ArtistsList