import { Link } from "react-router-dom"
import { ROUTE_LOGIN } from "../../routes/routes.const"

const Hero = () => {

    return (
        <div className="landing-hero" id="home">
            <div className="landing-container">
                <div className="landing-hero-inner">
                    <div className="landing-hero-left">
                        <h1 className="landing-hero-title">The Global Ecosystem for Music Industry</h1>
                        <p className="margedBot30px landing-paragraph">Comparing artists, as well as booking and organizing concerts has never been easier.</p>
                        <div className="flex-align-center row">
                            <div style={{flexBasis: "50%", marginRight: "8px"}}>
                                <a href="#contact">
                                    <button className="button button--primary allwidth" style={{paddingTop: "20px", paddingBottom: "20px"}}>Contact Us</button>
                                </a>
                            </div>
                            <div style={{flexBasis: "50%", marginLeft: "8px"}}>
                                <Link to={ROUTE_LOGIN}>
                                    <button className="button button--ghost allwidth" style={{paddingTop: "20px", paddingBottom: "20px"}}>Login</button>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div className="landing-hero-right">
                        <div className="landing-laptop-div">
                            <img className="landing-laptop" src="macbook_mockup.png" alt="macbook mockup" />
                            <img className="landing-laptop-image" src="screenshot_artist.png" alt="screenshot of the app" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Hero