import { ROUTE_CONTRACTS, ROUTE_USERS, ROUTE_OFFERS, ROUTE_ORACLE, ROUTE_PROMOTORS, ROUTE_STATS, ROUTE_TOKEN, ROUTE_TOURING, ROUTE_VENUES, ROUTE_MANAGERS, ROUTE_ARTISTS, ROUTE_DASHBOARD, ROUTE_APIS, ROUTE_POTENTIAL_TOURINGS } from "../../routes/routes.const"

import HomePanel from "../common/HomePanel";

const AdminPanel = () => {

    const screens = {
        [ROUTE_DASHBOARD]: {
            title: 'Dashboard',
            icon: 'tabler:smart-home'
        },
        [ROUTE_ORACLE]: {
            title: 'Oracle',
            icon: 'ph:currency-circle-dollar'
        },
        [ROUTE_USERS]: {
            title: 'Users', 
            icon: 'tabler:user'
        },
        [ROUTE_OFFERS]: {
            title: 'Offers',
            icon: 'tabler:shopping-cart'
        },
        [ROUTE_CONTRACTS]: {
            title: 'Contracts', 
            icon: 'mdi:contract-outline'
        },
        [ROUTE_PROMOTORS]: {
            title: 'Promotors', 
            icon: 'fluent:person-key-20-regular'
        },
        // [ROUTE_MANAGERS]: {
        //     title: 'Managers', 
        // icon: 'tabler:color-swatch'
        // },
        [ROUTE_ARTISTS]: {
            title: 'Artists', 
            icon: 'mdi:artist-outline'
        },
        [ROUTE_TOURING]: {
            title: 'Touring',
            icon: 'ic:outline-travel-explore'
        },
        [ROUTE_POTENTIAL_TOURINGS]: {
            title: 'Potential Tourings',
            icon: 'ic:outline-travel-explore'
        },
        // [ROUTE_APIS]: {
        //     title: 'Apis',
        //     icon: 'tabler:color-swatch'
        // },
        [ROUTE_VENUES]: {
            title: 'Venues', 
            icon: 'mdi:place-outline'
        },
        // [ROUTE_TOKEN]: {
        //     title: 'Prime Token', 
        // },
        // [ROUTE_STATS]: {
        //     title: 'Statistics', 
        // },
    }

        return <HomePanel screens={screens} noProfile={true} />
}

export default AdminPanel;