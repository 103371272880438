import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { ROUTE_ARTISTS } from "../../../routes/routes.const"
import artistNames from "../../../temporary-storage/artistNames.json"
import { formatDisplayableNameToUrl } from "../../artists.util"
import { AiOutlineSearch } from "react-icons/ai"
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

const useArtistSearchInput = () => {

    const [searchInput, setSearchInput] = useState("")
    const [searchOptions, setSearchOptions] = useState<string[]>([])
    
    const navigate = useNavigate()
    const ref = useRef<HTMLInputElement>(null)

    const _names = artistNames as string[]

    useEffect(() => {
        const delayedUpdateFunction = setTimeout(() => {
            if(searchInput.length < 3) { setSearchOptions([]) }
            else {
                setSearchOptions(_names
                    .filter(name => 
                        name
                        .toLowerCase()
                        .includes(searchInput.toLowerCase())
                    )
                    .slice(0,50))
            }
        }, 200)
    
        return () => clearTimeout(delayedUpdateFunction)
    }, [searchInput, _names])


    document.onkeydown = (event: KeyboardEvent) => {
        if(event.key === 'Enter'){
            console.log(document.activeElement)
            if (ref.current && document.activeElement === ref.current) {
                handleSearchArtist()
            }
        }
    }

    const navigateToArtist = (artistName: string) => {
        const artistUrl = `${ROUTE_ARTISTS}/${formatDisplayableNameToUrl(artistName)}/overview`
        navigate(artistUrl)
    }

    const handleSearchArtist = () => {
        if(searchInput === '') { return }
        navigateToArtist(searchInput)
    }

    const handleSelectAutoComplete = (name: string) => {
        navigateToArtist(name)
    }

    const artistSearchUI =
    <div className="margedBot30px allwidth">
        <Autocomplete
            freeSolo
            id="artist-search"
            disableClearable
            // filterOptions={customFilterOptions}
            // inputValue={searchInput}
            options={searchOptions}
            // onChange={handleSelectAutoComplete}
            renderInput={(params) => (
                <div className="search-container">
                    <TextField
                        inputRef={ref}
                        style={{width: '97%'}}
                        {...params}
                        label="Artist Name"
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                        InputProps={{
                            ...params.InputProps,
                            type: 'search',
                        }}
                    />
                    <span style={{width: "3%"}}>
                        <button onClick={handleSearchArtist} className="flex btn button--primary btn-search allspace">
                            <AiOutlineSearch size={20} color="white" />
                        </button>
                    </span>
                </div>
            )}
            renderOption={(props, option, state) =>
                <li onClick={() => handleSelectAutoComplete(option)} className="leftalign clickable" style={{padding: "6px 16px"}}>{option}</li>
                }
        />
    </div>

    return { searchInput, artistSearchUI }

}

export default useArtistSearchInput