import { AiOutlineCloseCircle } from "react-icons/ai"
import { Row } from "../../../../../components/Containers"
import LabelInput from "../../../../../components/form/mui/label-input/LabelInput"
import { colors } from "../../../../../constants"
import useSetSnack from "../../../../../utils/hooks/useSetSnack"
import { OfferShowFormInputs } from "../../../../../utils/models/offer.model"
import { ls, lsToFlat, pnlAmountFormatter } from "../../../../../utils/utils"

const SponsorsForm = ( { show, showIndex, currencySymbol, setOfferShow, close, cancel }: {
    show: OfferShowFormInputs,
    showIndex: number,
    currencySymbol: null|string,
    setOfferShow: (showIndex: number, showProps: {}) => void,
    close: () => void,
    cancel: () => void,
}) => {

    const setSnack = useSetSnack()
    
    const { sponsors } = show 

    const sponsorsNumber = sponsors.length
    const sponsorsTotalAmount = sponsors.reduce((total, sponsor) => total + sponsor.amount, 0)

    const updateSponsor = (e: any, sponsorIndex: number, update: any) => {
        const newSponsor = {...show.sponsors[sponsorIndex], ...update}
        const updatedSponsors = [...show.sponsors]
        updatedSponsors[sponsorIndex] = newSponsor
        setOfferShow(
            showIndex, 
                { sponsors: updatedSponsors}
            )
    }

    const handleAddSponsor = () => {
        const updatedSponsors = [...show.sponsors]
        updatedSponsors.push({name: '', amount: 0})
        setOfferShow(
            showIndex, 
                { sponsors: updatedSponsors}
            )
    }

    const handleRemoveSponsor = (sponsorIndex: number) => {
        const updatedSponsors = [...show.sponsors]
        updatedSponsors.splice(sponsorIndex, 1)
        setOfferShow(
            showIndex, 
                { sponsors: updatedSponsors}
            )
    }

    const validateForm = () => {
        if(!sponsors.length) {
            setSnack({type: 'warning', content: 'Please enter at least one sponsor.'})
            return false
        }

        for(let i = 0 ; i < sponsors.length ; i++) {
            if(!sponsors[i].name || !sponsors[i].amount) {
                setSnack({type: 'warning', content: 'Please enter a name and an amount for every sponsor.'})
                return false
            }
        }

        return true
    }

    const handleSave = () => {
        const isFormValid = validateForm()

        if(isFormValid)
            close()
    }

    return (
        <div className="main-container">

            {sponsors.map((sponsor, sponsorIndex) => <Row className="allwidth">
                <div className="width45 margedRight20px">
                    <LabelInput 
                        title="Sponsor Name"
                        value={sponsor.name} 
                        onChange={(e:any) => updateSponsor(e, sponsorIndex, { name: e.target.value })}
                    />
                </div>
                <div className="width45 margedRight20px">
                    <LabelInput 
                        title={`Sponsorship amount (${currencySymbol ?? show.localCurrency})`}
                        value={ls(sponsor.amount)} 
                        onChange={(e:any) => {
                            const flattenedNumber = lsToFlat(e.target.value)
                            if(!isNaN(flattenedNumber)) {
                                updateSponsor(e, sponsorIndex, { amount: flattenedNumber })
                            }
                        }}
                    />
                </div>

                <AiOutlineCloseCircle
                    size={25}
                    onClick={() => handleRemoveSponsor(sponsorIndex)}
                    fill={colors.red}
                    className='clickable'
                />

            </Row>)}

            <button className="button button--round button--primary margedTop20px" onClick={handleAddSponsor}>Add Sponsor</button>

            <Row className="margedTop30px">
                <p className="width50 margedRight20px">Total sponsors : {sponsorsNumber}</p>
                <p className="width50">Total sponsors amount : {currencySymbol ?? show.localCurrency} {pnlAmountFormatter(sponsorsTotalAmount)}</p>
            </Row>

            <Row className="margedTop20px margedBot50px">
                <button className="button button--primary button--round margedRight50px" onClick={handleSave}>Save</button>
                <button className="button button--primary button--round" onClick={() => cancel()}>Cancel</button>
            </Row>
        </div>
    )
}

export default SponsorsForm