import { NavLink } from "react-router-dom"
import { ClientTask } from "../../../utils/models/client.model"

const TaskItemRow = ({ task }: { task: ClientTask }) => {
    
    return (
        <tr>
            <td>{task.message}</td>
            <td>
                <NavLink to={task.linkPath}>View</NavLink>
            </td>
        </tr>
    )
}

export default TaskItemRow