import { Link } from "react-router-dom"
import { Flex } from "../../../../components/Containers"
import { ROUTE_ARTISTS } from "../../../../routes/routes.const"
import { formatDisplayableNameToUrl } from "../../../../utils/artists.util"
import { Artist } from "../../../../utils/models/artists.model"

//! styles in styles/artists/artists.css

const ArtistItem = ({ artist }: { artist: Artist }) => {

    const artistUrl = `${ROUTE_ARTISTS}/${formatDisplayableNameToUrl(artist.displayableName)}/overview`

    const nameDisplay = artist.displayableName.length > 20 ? artist.displayableName.slice(0,20) + '...' : artist.displayableName
    
    return(
        <Link to={artistUrl}>
            <Flex className="artist-list__item-div">
                <div className="flex-only column alignstart">
                    {artist.image
                    ?   <img className="artist__logo-image" src={artist.image} alt={`${artist.displayableName} logo`} />
                    :   <Flex className="artist__logo-image xlarge">{artist.code.toUpperCase()}</Flex>}

                    <p className="artist-list__item-title">{nameDisplay}</p>

                </div>
            </Flex>
        </Link>
    )
}

export default ArtistItem