import moment from "moment"
import { useState } from "react"
import { createPortal } from "react-dom"
import { BiLayerPlus } from "react-icons/bi"
import { GiFireShield } from "react-icons/gi"
import { HiOutlineDocumentPlus } from "react-icons/hi2"
import { MdOpenInNew } from "react-icons/md"
import { useNavigate } from "react-router-dom"
import Dialog from "../../../../../components/dialog/Dialog"
import { accessLevels, colors, errorToSuccessTheme3 } from "../../../../../constants"
import { ROUTE_CONTRACTS } from "../../../../../routes/routes.const"
import { useAppSelector } from "../../../../../utils/hooks/reduxTypedHooks"
import useSetSnack from "../../../../../utils/hooks/useSetSnack"
import { ArtistRequest } from "../../../../../utils/models/requests.model"
import { ls } from "../../../../../utils/utils"
import ArtistRequestTouring from "../../../../promotor/artists/details/common/ArtistRequestTouring"
import ArtistForm from "../../touring/artists/ArtistForm"
import logo from "../../../../../images/logo.png"
import { MdClose } from "react-icons/md"

const RequestItemRow = ({ request }: {request: ArtistRequest}) => {
    const [requestVisible, setRequestVisible] = useState(false)
    const [artistFormVisible, setArtistFormVisible] = useState(false)

    const promotors = useAppSelector(state => state.promotors)
    const promotor = promotors.find(promotor => promotor.id === request.promotorId)

    const artists = useAppSelector(state => state.artists)
    const primuseArtist = artists.find(artist => artist.displayableName === request.artistName)

    const navigate = useNavigate()
    const setSnack = useSetSnack()

    const openRequest = () => setRequestVisible(true)
    const closeRequest = () => setRequestVisible(false)
    const openArtistForm = () => setArtistFormVisible(true)
    const closeArtistForm = () => setArtistFormVisible(false)

    const rootModalDiv = document.getElementById('modal-root')!

    const handleView = () => {
        openRequest()
    }

    const handleMakeContract = (event: any) => {
        event.stopPropagation()
        if(!primuseArtist){
            setSnack({type: 'warning', content: 'Artist not found.'})
            return 
        }
        navigate(ROUTE_CONTRACTS, {state: { openForm: { artist: primuseArtist, purchaser: promotor } }})
    }

    const handleAddArtist = (event: any) => {
        event.stopPropagation()
        openArtistForm()
    }

    return (
        <>
            {createPortal(
                <Dialog
                    visible={requestVisible}
                    onClose={closeRequest}
                    dialogCustomStyle={{overflowY: 'scroll', height: '90vh', top: '5vh', width: '80%', left: '10%', backgroundColor: 'var(--app-background)'}}
                    notAllSpace={true}
                >
                    <ArtistRequestTouring artistName={request.artistName} request={request} close={closeRequest} />
                </Dialog>, rootModalDiv)}

                {createPortal(
                <Dialog
                    visible={artistFormVisible}
                    onClose={closeArtistForm}
                    dialogCustomStyle={{overflowY: 'scroll', height: '90vh', top: '5vh', width: '80%', left: '10%', backgroundColor: 'var(--app-background)'}}
                    notAllSpace={true}
                >
                    <ArtistForm searchName={request.artistName.toLowerCase()} closeForm={closeArtistForm}/>
                </Dialog>, rootModalDiv)}

            <tr className="hover-medium-scale-shadow clickable" onClick={handleView}>
                    <th className="leftalign">{request.artistName}</th>
                    <th>{primuseArtist 
                    ?   <img src={logo} style={{width: '100px'}} alt="Primuse" />
                    :   <MdClose size={25} color={colors.red} />}</th>
                    <th className="fw400 leftalign">{moment(new Date(request.from)).format('MMMM Do YYYY')}</th>
                {promotor
                ?   <>
                        <th className="fw400 leftalign">{promotor.name}</th>
                        <th>{promotor?.accessLevel && 
                                <div className="flex-align-center row bold">
                                    <GiFireShield size={25} color={errorToSuccessTheme3[promotor.accessLevel - 1]} /> {accessLevels.promotor[promotor.accessLevel]}
                                </div>}
                        </th>
                        <th className="fw400 leftalign">{promotor.email} {promotor.phone}</th>
                        <th className="fw400">{promotor.contracts?.length ?? 0}</th>
                    </>
                :   <>
                        <th>Not Found</th>
                        <th>Not Found</th>
                        <th>Not Found</th>
                        <th>Not Found</th>
                    </>
                }
                <th>${ls(request.amount)}</th>
                <th>
                    {primuseArtist 
                    ?   <HiOutlineDocumentPlus size={25} className="main-color clickable hoverscale" onClick={handleMakeContract} />
                    :   null}
                </th>
                <th>
                    {primuseArtist
                    ?   null
                    :   <BiLayerPlus className="main-color-fill clickable hoverscale" size={25} onClick={handleAddArtist} />}
                </th>
                <th>
                    <MdOpenInNew className="main-color-fill clickable hoverscale" size={25} onClick={handleView} />
                </th>
            </tr>
        </>
    )
}

export default RequestItemRow