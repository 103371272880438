import { Flex } from "../../../../components/Containers"
import IconifyIcon from "../../../../components/Icon/IconifyIcon"
import { countries } from "../../../../utils/models/touring.model"
import { Venue } from "../../../../utils/models/venues.model"
import styles from "./venues.module.css"

const VenueItem = ({ venue }: { venue: Venue }) => {
    !venue && console.log('NO VENUE CASE')
    return (
        <div className="relative leftalign app-card app-card-no-padding">
            <div>
                {  
                    venue.image
                    ? <img className={styles.thumbnail} src={venue.image} alt="venue thumbnail" />
                    : <Flex className={styles.thumbnail}>NO IMAGE</Flex>
                }
            </div>
            <div className={styles.cardBody}>
                <p className="margedBot15px font18 title-color">{venue.name}</p>
                <Flex row className="allwidth space-between margedBot10px">
                    <button className="button button--noclick button--primary button--round button--small">{venue.country.name || countries[venue.country.countryCode]}</button>
                    {
                        venue.capacity
                        && <div className="flex-align-center">
                            <IconifyIcon icon="bi:people-fill" color="var(--main-color)" fontSize={15} />
                            <span className="margedLeft5px">{venue.capacity}</span>
                        </div>
                    }
                </Flex>
                <p>{venue.address}, {venue.city?.name}</p>
            </div>
        </div>
    )
}

export default VenueItem