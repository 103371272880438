import FullCalendar, { EventClickArg, EventDropArg } from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid/main'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin, { EventResizeDoneArg } from '@fullcalendar/interaction';
import { useAppDispatch, useAppSelector } from "../../../utils/hooks/reduxTypedHooks";
import { useEffect, useRef, useState } from "react";
import styles from "./agenda.module.css"
import DatePicker from "react-datepicker";
import AddEventSidebar from "./AddEventSidebar";
import CheckboxMUI from "../../../components/form/mui/checkbox/CheckboxMUI";
import { colors } from "../../../constants";
import { countries } from "../../../utils/models/touring.model";
import { PromotorEvent, PromotorEventCreateObject, promotorEventLabelsList, PromotorEventMergeObject, promotorEventsBgColors, promotorEventsLabelsColors } from "../../../utils/models/promotors.model";
import { AppColor } from "../../../utils/models/common.model";
import PromotorFeedback from "../common/feedback/PromotorFeedback";
import { clientActions } from "../../../store/clientSlice";
import useFetchApiAuth from "../../../utils/hooks/useFetchApiAuth";
import useSetSnack from "../../../utils/hooks/useSetSnack";
import LoadingModal from "../../../components/loading/LoadingModal";

const PromotorAgenda = () => {
    const [calendarApi, setCalendarApi] = useState<null | any>(null)
    const [eventsFilters, setEventsFilters] = useState([{filter: "Booked Shows", value: true}, ...promotorEventLabelsList.map(label => ({filter: label ,value: true}))])    // 0: booked shows, ...[n]: custom events labels
    const [selectedDates, setSelectedDates] = useState<{from: Date, to: Date, allDay: boolean, startTime: string, endTime: string} | undefined>(undefined)
    const [activeEvent, setActiveEvent] = useState<PromotorEvent | undefined>(undefined)
    const [isUpdatingEvent, setIsUpdatingEvent] = useState(false)
    const contracts = useAppSelector(state => state.contracts)
    const calendarRef = useRef(null)

    const client = useAppSelector(state => state.client)
    const promotorEvents = client?.promotor?.customEvents ? client.promotor.customEvents : []
    const promotorId = client?.promotor?.id

    const setSnack = useSetSnack()
    const dispatch = useAppDispatch()
    const fetchApiAuth = useFetchApiAuth()

    const [addEventSidebarOpen, setAddEventSidebarOpen] = useState(false)
    const openAddEventSidebar = () => setAddEventSidebarOpen(true)
    const closeAddEventSidebar = () => setAddEventSidebarOpen(false)

    const handleCloseAddEventSidebar = () => {
        closeAddEventSidebar()
        setActiveEvent(undefined)
    }

   
    const filtersArray: {title: string, color?: AppColor}[] = [
        {title: "Booked Shows", color: "main"},
        ...promotorEventLabelsList.map(label => ({title: label, color: promotorEventsLabelsColors[label]}))
    ]

    const toggleFilter = (filterIndex: number) => {
        setEventsFilters(prevValues => {
            const newValues = [...prevValues]
            newValues[filterIndex].value = !prevValues[filterIndex].value
            return newValues
        })
    }

    useEffect(() => {
        if (calendarApi === null) {
          // @ts-ignore
          setCalendarApi(calendarRef.current?.getApi())
        }
      }, [calendarApi, setCalendarApi])


    
    const bookedDaysColor = colors.bgMain
    const bookedDaysTextColor = colors.main
    const bookedDaysExtendedProps = {notClickable: true}
    const tourBookedDays: {title: string, start: string, editable: boolean, color: string, classNames: string[], isTourEvent: boolean }[] = []
    contracts.forEach(contract => {
        const color = bookedDaysColor
        const textColor = bookedDaysTextColor
        
        contract.shows.forEach(show => {
            const title = `${contract.artist.name} in ${countries[show.venue.flag]}`
            const start = show.date
            const event = {title, start, editable: false, color, textColor, classNames: ['noclick'], isTourEvent: true, extendedProps: bookedDaysExtendedProps}
            tourBookedDays.push(event)
        })
    })


    const customEventsTextColor = {
        Preparation: colors[promotorEventsLabelsColors["Preparation"]],
        Business: colors[promotorEventsLabelsColors["Business"]],
        Other: colors[promotorEventsLabelsColors["Other"]],
    }
    const customEvents = promotorEvents.map(event => {
        const title = event.title
        const { from, to, allDay } = event

        const start = new Date(from)
        const endDate = new Date(to)
        if(allDay) {
            // add +1 to the end date because the calendar end date is EXCLUSIVE
            endDate.setDate(endDate.getDate() + 1)
        }
        const end = endDate

        const color = promotorEventsBgColors[event.label]
        const textColor = customEventsTextColor[event.label]
        const extendedProps = {promotorEvent: event}

        return {title, start, end, allDay, color, textColor, extendedProps}
    })

    const filteredTourBookedEvents = tourBookedDays.filter(_ => eventsFilters[0].value)

    const filteredCustomEvents = customEvents.filter(promotorEvent => {
        const correspondingFilter = eventsFilters.find(filter => filter.filter === promotorEvent.extendedProps.promotorEvent.label)
        return correspondingFilter?.value
    })

    const calendarFilteredEvents = [...filteredTourBookedEvents, ...filteredCustomEvents]

    const handleAddEvent = async (event: PromotorEventCreateObject) => {
        if(!promotorId) {
            return
        }

        const { error, response: newEventsList } = await fetchApiAuth({
            method: 'POST',
            route: 'promotor-custom-events/add',
            body: {promotorId, event}
        })

        // snack
        setSnack(
            error
            ? {
                type: 'error', 
                content: error.toString()
            }
            : {
                type: 'success',
                content: 'Event added successfully.'
            }
        )
        
        if(!error){
            dispatch(clientActions.promotorSetEvents(newEventsList))
        }

    }

    const handleUpdateEvent = async (event: PromotorEventMergeObject) => {
        setIsUpdatingEvent(true)
        if(!promotorId) {
            setIsUpdatingEvent(false)
            return
        }

        const { error, response: newEventsList } = await fetchApiAuth({
            method: 'POST',
            route: 'promotor-custom-events/update',
            body: {promotorId, event}
        })

        // snack
        setSnack(
            error
            ? {
                type: 'error', 
                content: error.toString()
            }
            : {
                type: 'success',
                content: 'Event Updated successfully.'
            }
        )
        
        if(!error){
            // update local client
            dispatch(clientActions.promotorSetEvents(newEventsList))
        }

        setIsUpdatingEvent(false)
    }

    const handleDeleteEvent = async (event: PromotorEventMergeObject) => {
        if(!promotorId) {
            return
        }

        const { error, response: newEventsList } = await fetchApiAuth({
            method: 'POST',
            route: 'promotor-custom-events/delete',
            body: {promotorId, event}
        })

        // snack
        setSnack(
            error
            ? {
                type: 'error', 
                content: error.toString()
            }
            : {
                type: 'success',
                content: 'Event deleted successfully.'
            }
        )
        
        if(!error){
            // update local client
            dispatch(clientActions.promotorSetEvents(newEventsList))
        }
    }

    const handleDateSelect = (arg: any) => {
        const from: Date = arg.start
        const to: Date = arg.end
        const allDay: boolean = arg.allDay
        const startStr: string = arg.startStr
        const endStr: string = arg.endStr

        if(allDay) {
            to.setDate(to.getDate() - 1)    // -1 because the next day is put as 'end'
        }

        const startTime = startStr.substring(11,16)
        const endTime = endStr.substring(11,16)

        setSelectedDates({from, to, allDay, startTime, endTime})
        openAddEventSidebar()
    }

    const handleEventClick = (arg: EventClickArg) => {
        const extendedProps = arg.event.extendedProps
        if(extendedProps.notClickable) { return }
        const event: PromotorEvent = extendedProps.promotorEvent
        
        setActiveEvent(event)
        openAddEventSidebar()
    }

    const handleEventMove = (arg: EventDropArg|EventResizeDoneArg) => {
        const event: PromotorEvent = arg.event.extendedProps.promotorEvent
        if(!arg.event._instance) {
            console.log("arg.event._instance is undefined")
            return
        }
        const { allDay } = event
        const _from = arg.event._instance.range.start
        const toDate = arg.event._instance.range.end

        if(allDay) {
            toDate.setDate(toDate.getDate() - 1)    // -1 because the next day is put as 'end'
        }

        // removing timezoneOffset
        const timezoneMinutesOffset = _from.getTimezoneOffset()
        const utcNeutralFrom = new Date(_from.getTime() + (timezoneMinutesOffset * 60000))
        const utcNeutralTo = new Date(toDate.getTime() + (timezoneMinutesOffset * 60000))

        const from = utcNeutralFrom.toString().substring(0,25)
        const to = utcNeutralTo.toString().substring(0,25)

        const newEvent: PromotorEventMergeObject = {
            id: event.id,
            from,
            to,
            allDay
        }

        handleUpdateEvent(newEvent)
    }

    const areAllFilterChecked = eventsFilters.filter(filter => filter.value).length === eventsFilters.length
    const toggleAllFilters = () => {
        setEventsFilters(prevValue => prevValue.map(filter => ({...filter, value: !areAllFilterChecked})))
    }

    const renderFilters = filtersArray.map((filter, index) => 
    <div className="margedBot10px" key={index}>
        <CheckboxMUI
            checked={eventsFilters[index].value}
            onChange={(e: any) => toggleFilter(index)}
            label={filter.title}
            color={filter.color}
        />
    </div>)

    return (
        <div className='main-container margedBot100px'>

            <LoadingModal visible={isUpdatingEvent} text="Saving changes..." />

            <div className="margedTop20px">
                <PromotorFeedback page="Agenda" />
            </div>

            <h3 className="page-title">Agenda</h3>

            <div className="allwidth app-card app-card-no-padding">
                <div className="relative">
                    <div className={styles.leftNav}>
                        <div style={{margin: "25px", marginBottom: "26px"}}>
                            <button className="button button--primary uppercase allwidth" onClick={() => openAddEventSidebar()}>Add Event</button>
                        </div>
                        
                        <hr className="divider" />

                        <div className="date-picker-no-border">
                            <DatePicker inline onChange={date => calendarApi.gotoDate(date) } />
                        </div>

                        <hr className="divider" />

                        <div className="leftalign" style={{padding: "28px"}}>
                            <p className="uppercase text-soft margedBot20px">Filter</p>

                            <div className="margedBot10px">
                                <CheckboxMUI
                                    checked={areAllFilterChecked}
                                    onChange={toggleAllFilters}
                                    label='View All'
                                    color="green"
                                />
                            </div>

                            {renderFilters}
                            
                        </div>
                    </div>
                    <div className={styles.mainCalendarDiv}>
                        <FullCalendar
                            plugins={[ dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin ]}
                            initialView="dayGridMonth"
                            headerToolbar={{
                                start: 'prev next title',
                                end: 'today dayGridMonth timeGridWeek timeGridDay listMonth'
                                // end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth'
                              }}
                            events={calendarFilteredEvents}
                            dayMaxEventRows={3}
                            aspectRatio={2}
                            eventClick={handleEventClick}
                            selectable={true}
                            select={handleDateSelect}
                            editable={true}
                            eventDrop={handleEventMove}
                            eventResize={handleEventMove}
                            dayCellClassNames='clickable'
                            ref={calendarRef}
                        />
                    </div>

                    <AddEventSidebar
                        drawerWidth={420}
                        addEventSidebarOpen={addEventSidebarOpen}
                        closeAddEventSidebar={handleCloseAddEventSidebar}
                        handleAddEvent={handleAddEvent} 
                        handleUpdateEvent={handleUpdateEvent}
                        handleDeleteEvent={handleDeleteEvent}
                        selectedEvent={activeEvent}
                        selectedDates={selectedDates} 
                    />
                </div>
            </div>
        </div>
    )
}

export default PromotorAgenda