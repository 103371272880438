import { Offer } from "../../../utils/models/offer.model"
import OffersTable from "./OffersTable"

const OffersTab = ({ offers, title, ongoing }: { offers: Offer[], title: string, ongoing?: boolean }) => {

    return (
        <div className='flex' style={{paddingTop: '40px'}}>
            <h3 className="offers__page-title margedBot50px">{title} Offers</h3>

            <OffersTable offers={offers} ongoing={ongoing} />

        </div>
    )
}

export default OffersTab