import { AiOutlineCloseCircle } from "react-icons/ai"
import { Row } from "../../../../../components/Containers"
import LabelInput from "../../../../../components/form/mui/label-input/LabelInput"
import { colors } from "../../../../../constants"
import useSetSnack from "../../../../../utils/hooks/useSetSnack"
import { OfferShowFormInputs } from "../../../../../utils/models/offer.model"
import { ls, lsToFlat, pnlAmountFormatter } from "../../../../../utils/utils"

const OtherIncomesForm = ( { show, showIndex, currencySymbol, setOfferShow, close, cancel }: {
    show: OfferShowFormInputs,
    showIndex: number,
    currencySymbol: null|string,
    setOfferShow: (showIndex: number, showProps: {}) => void,
    close: () => void,
    cancel: () => void,
}) => {
    
    const setSnack = useSetSnack()

    const { otherIncomes } = show 

    const otherIncomesNumber = otherIncomes.length
    const otherIncomesTotalAmount = otherIncomes.reduce((total, otherIncome) => total + otherIncome.amount, 0)

    const updateOtherIncome = (e: any, otherIncomeIndex: number, update: any) => {
        const newOtherIncome = {...show.otherIncomes[otherIncomeIndex], ...update}
        const updatedOtherIncomes = [...show.otherIncomes]
        updatedOtherIncomes[otherIncomeIndex] = newOtherIncome
        setOfferShow(
            showIndex, 
                { otherIncomes: updatedOtherIncomes}
            )
    }

    const handleAddOtherIncome = () => {
        const updatedOtherIncomes = [...show.otherIncomes]
        updatedOtherIncomes.push({name: '', amount: 0})
        setOfferShow(
            showIndex, 
                { otherIncomes: updatedOtherIncomes}
            )
    }

    const handleRemoveOtherIncome = (otherIncomeIndex: number) => {
        const updatedOtherIncomes = [...show.otherIncomes]
        updatedOtherIncomes.splice(otherIncomeIndex, 1)
        setOfferShow(
            showIndex, 
                { otherIncomes: updatedOtherIncomes}
            )
    }

    const validateForm = () => {
        if(!otherIncomes.length) {
            setSnack({type: 'warning', content: 'Please enter at least one income.'})
            return false
        }

        for(let i = 0 ; i < otherIncomes.length ; i++) {
            if(!otherIncomes[i].name || !otherIncomes[i].amount) {
                setSnack({type: 'warning', content: 'Please enter a name and an amount for every income.'})
                return false
            }
        }

        return true
    }

    const handleSave = () => {
        const isFormValid = validateForm()

        if(isFormValid)
            close()
    }

    return (
        <div className="main-container">

            {otherIncomes.map((otherIncome, otherIncomeIndex) => <Row className="allwidth">
                <div className="width45 margedRight20px">
                    <LabelInput 
                        title="Other Income Name"
                        value={otherIncome.name} 
                        onChange={(e:any) => updateOtherIncome(e, otherIncomeIndex, { name: e.target.value })}
                    />
                </div>
                <div className="width45 margedRight20px">
                    <LabelInput 
                        title={`Income amount (${currencySymbol ?? show.localCurrency})`}
                        value={ls(otherIncome.amount)} 
                        onChange={(e:any) => {
                            const flattenedNumber = lsToFlat(e.target.value)
                            if(!isNaN(flattenedNumber)) {
                                updateOtherIncome(e, otherIncomeIndex, { amount: flattenedNumber })
                            }
                        }}
                    />
                </div>

                <AiOutlineCloseCircle
                    size={25}
                    onClick={() => handleRemoveOtherIncome(otherIncomeIndex)}
                    fill={colors.red}
                    className='clickable'
                />

            </Row>)}

            <button className="button button--round button--primary margedTop20px" onClick={handleAddOtherIncome}>Add Other Income</button>

            <Row className="margedTop30px">
                <p className="width50 margedRight20px">Number of other incomes : {otherIncomesNumber}</p>
                <p className="width50">Total other incomes amount : {currencySymbol ?? show.localCurrency} {pnlAmountFormatter(otherIncomesTotalAmount)}</p>
            </Row>

            <Row className="margedTop20px margedBot50px">
                <button className="button button--primary button--round margedRight50px" onClick={handleSave}>Save</button>
                <button className="button button--primary button--round" onClick={() => cancel()}>Cancel</button>
            </Row>
        </div>
    )
}

export default OtherIncomesForm