import { Flex } from "../../Containers"

const FormSection = ({ title, children, fullWidth, className }: {title?: string, fullWidth?: boolean, children: JSX.Element[] | JSX.Element, className?: string}) => {

    return(
        <Flex className={className ? className : `allwidth alignstart margedBot20px`}>
            {title && <label className="bold xlarge capitalize main-color text-shadow margedBot15px">{title} :</label>}
            <div className="allwidth">
                <div className={`form-section__children-div ${fullWidth ? 'form-section__full-width' : ''}`}>
                    {children}
                </div>
            </div>

        </Flex>
    )
}

export default FormSection