import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Flex } from "../../../../components/Containers";
import { RootState } from "../../../../store";

import { FaInstagram, FaLinkedin, FaMedium, FaSoundcloud, FaTiktok, FaTwitter } from "react-icons/fa";
import { AiFillFacebook, AiFillWarning, AiOutlineUser } from "react-icons/ai";
import { SiBeatsbydre } from "react-icons/si";
import { BiCheckCircle, BiUserCircle } from "react-icons/bi";
import { accessLevels, errorToSuccessTheme3 } from "../../../../constants";
import { GiFireShield } from "react-icons/gi";
import FormSection from "../../../../components/form/form-components/FormSection";
import LabelInput from "../../../../components/form/mui/label-input/LabelInput";
import FileInput from "../../../../components/form/form-components/FileInput";
import SocialPlatformInput from "../../../../components/form/form-components/SocialPlatformInput";
import { PlatformInfo } from "../../../../utils/models/artists.model";
import SelectInput from "../../../../components/form/form-components/SelectInput";
import { Licence } from "../../../../utils/models/client.model";
import CheckboxSpruha from "../../../../components/form/spruha/CheckboxSpruha";
import { useAppSelector } from "../../../../utils/hooks/reduxTypedHooks";
import { Promotor, PromotorFormInputs } from "../../../../utils/models/promotors.model";
import ReactFlagsSelect from "react-flags-select";

// import { PromotorLevelsList } from "../../../../utils/models/promotors.model";



export const socialIcons = {
    facebook: <AiFillFacebook />,
    twitter: <FaTwitter />, 
    instagram: <FaInstagram />, 
    medium: <FaMedium />, 
    linkedin: <FaLinkedin />, 
    soundcloud: <FaSoundcloud />, 
    tikTok: <FaTiktok />, 
    drooble: <SiBeatsbydre style={{transform: 'scale(-1, 1)'}} />, 
}


const NewPromotorForm: React.FC<any> = ({usePromotor, isPosting, mergePromotor, storeNewPromotor, setSelectedLogoFile, closeForm} : {
    usePromotor: Promotor | null,
    isPosting: boolean,
    mergePromotor: Function,
    storeNewPromotor: Function,
    setSelectedLogoFile: Dispatch<SetStateAction<string>>,
    closeForm: () => void
}) => {
    
    const client = useAppSelector(state => state.client);
    // @ts-ignore
    const accessLevelsList = Object.keys(accessLevels.promotor).map((levelKey, levelIndex) => ({ value: +levelKey as keyof typeof accessLevels.promotor, label: <div className="flex row justifystart bold"><GiFireShield size={25} color={errorToSuccessTheme3[levelIndex]} /> {accessLevels.promotor[levelKey]}</div> }))

    const initialState = {
        name: '',
        email: '',
        logo: '',
        accessLevel: accessLevelsList[0],
        cieName: '',
        cieId: '',
        phone: '',
        country: '',
        website: '',
        fullAddress: '',
        socials: {} as Record<string, PlatformInfo>,
        comments: '',
        createNewAccount: false,
        newAccountEmail: '',
        clientId: ''
    };

    const [promotor, _setPromotor] = useState<PromotorFormInputs|Promotor>(
        initialState 
    );
    const setPromotor = (_props:any) => _setPromotor({...promotor, ..._props});

    useEffect(() => {
        if (usePromotor) {
            const accessLevelKey = usePromotor.accessLevel
            const inputeablePromotor = {
                ...usePromotor,
                // @ts-ignore
                accessLevel: { value: accessLevelKey as keyof typeof accessLevels.promotor, label: <div className="flex row justifystart bold"><GiFireShield size={25} color={errorToSuccessTheme3[accessLevelKey - 1]} /> {accessLevels.promotor[accessLevelKey]}</div> }
            }

            setPromotor(inputeablePromotor);
        }
    }, [])

    const users = useSelector((state:RootState) => state.users);
    const promotorsUsersOptions = users.filter(user => (user.licence === Licence.Promotor) && !user.actorId)

    const handleStoreNewPromotor = () => {
        usePromotor
        ? mergePromotor(promotor)
        : storeNewPromotor(promotor);
    }

    const authorizedAccount = (
        promotor?.clientId 
        && users.find((u:any) => ( u.id === promotor.clientId ))
    );

    console.log('authorizedAccount', authorizedAccount);

    const clearForm = () => _setPromotor(initialState);

    console.log('promotor', promotor);

    return <div className="flex allheight width90 justifystart alignstart smaller margedBot50px" >
        <div className="title selfcentered xxlarge spaced-text text-shadow">{!usePromotor && 'NEW'} PRIMUSE PROMOTOR {usePromotor && 'EDITION'}</div>
        <Flex className="allwidth">
            <FormSection title="Identification">
                <LabelInput 
                    title='Name'
                    value={promotor.name}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setPromotor({name: e.target.value})}
                />
                <LabelInput 
                    title='Email'
                    value={promotor.email}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setPromotor({email: e.target.value})}
                />
                <FileInput
                    title='Logo'
                    value={promotor.logo}
                    onChange={({acceptedFile, imageDatas}:any) => {
                        setSelectedLogoFile(acceptedFile);
                        setPromotor({logo: imageDatas.source});}}
                />
                <SocialPlatformInput 
                    title="Socials"
                    options={Object.keys(socialIcons).map((sk:any) => ({ value: sk, label: <div className="flex row justifystart">{socialIcons[sk as keyof {}]}&nbsp;<b className="capitalize">{sk}</b></div> }))}
                    value={promotor.socials}
                    onChange={(_socials:any) => setPromotor({socials: _socials})}
                    renderer={(_sm:any) => {
                        const sm = _sm as keyof {};
                        return <div className="flex row">{socialIcons[sm]} {_sm} : <span className="fw400" style={{margin: '0 30px'}}>url : <b>{ promotor.socials?.[sm].url } </b></span><span className="fw400">id :  <b>{ promotor.socials?.[sm].id }</b></span> </div>
                    }}
                />
                <LabelInput 
                    title='Phone'
                    value={promotor.phone}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setPromotor({phone: e.target.value})}
                />
                <ReactFlagsSelect
                    searchable
                    onSelect={e => setPromotor({country: e})} 
                    selected={promotor.country} 
                    className="allwidth"
                    selectButtonClassName="flags-selection-button"
                />
            </FormSection>

            <FormSection title="Company">
                <LabelInput 
                    title='Name'
                    value={promotor.cieName}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setPromotor({cieName: e.target.value})}
                />
                <LabelInput 
                    title='VAT Number'
                    value={promotor.cieId}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setPromotor({cieId: e.target.value})}
                />
                <LabelInput 
                    title='Website'
                    value={promotor.website}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setPromotor({website: e.target.value})}
                />
                <LabelInput 
                    title='Address'
                    value={promotor.fullAddress}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setPromotor({fullAddress: e.target.value})}
                />
            </FormSection>

            <FormSection title="Trust">
            <SelectInput 
                options={accessLevelsList}
                title='Promotor Level'
                value={promotor.accessLevel}
                onChange={(e:any) => setPromotor({accessLevel: e})}
                showRawValue
            />
            <LabelInput 
                title='Comments'
                value={promotor.comments}
                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setPromotor({comments: e.target.value})}
            />
            </FormSection>

            <FormSection title="Primuse User">
                {authorizedAccount
                ?   <>
                        <Flex row className="allwidth selfstart">
                            <Flex flex={1}>
                                <BiUserCircle size={50} />
                            </Flex>
                            <Flex flex={2} className="selfstart">
                                {/* @ts-ignore */}
                                <div className="title margedTop"> Level {accessLevels[promotor?.accessLevel||2]}</div>

                                <div className="title margedTop"> Granted To Account</div>
                                <b>{ authorizedAccount.email }</b>
                                <div>
                                    <Flex row>{ authorizedAccount.licence } licence</Flex>
                                    &nbsp;
                                    {
                                        authorizedAccount.licence!=='promotor' 
                                        ? <Flex row className="warning">
                                            <AiFillWarning color="orange" size={20} />
                                            BAD LICENCE
                                        </Flex> 
                                        : <BiCheckCircle color="teal" size={20} />
                                    }
                                </div>
                            </Flex>
                        </Flex>

                        <SelectInput 
                            disabled={promotor.createNewAccount}
                            options={promotorsUsersOptions.filter((u:any) => u.id !== client?.id).map((user:any) => ({ value: user.id, label: <div className="flex row justifystart"><AiOutlineUser size={20} />&nbsp;<b>{user.name||'Unnamed*'}</b> &nbsp; ({user.email})</div> }))}
                            title='Link User (Account must have a "promotor" licence)'
                            value={promotor.clientId}
                            onChange={(e:any) => setPromotor({clientId: e?.value||''})}
                        />
                    </>
                :   <>
                        <CheckboxSpruha 
                            title='Create New Account'
                            checked={promotor.createNewAccount}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setPromotor({createNewAccount: e.target.checked})}
                        />
                        
                        {promotor.createNewAccount
                        ?   <LabelInput 
                                title='New Account Email'
                                value={promotor.newAccountEmail}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>): void => setPromotor({newAccountEmail: e.target.value})}
                            />
                        :   <SelectInput 
                            options={promotorsUsersOptions.filter((u:any) => u.id !== client?.id).map((user:any) => ({ value: user.id, label: <div className="flex row justifystart"><AiOutlineUser size={20} />&nbsp;<b>{user.name||'Unnamed*'}</b> &nbsp; ({user.email})</div> }))}
                            title='Link User (Account must have a "promotor" licence)'
                            value={promotor.clientId}
                            onChange={(e:any) => setPromotor({clientId: e?.value||''})}
                        />}
                </>
                }
            </FormSection>
            
            <Flex flex={1} row className="allspace spaceevenly margedTop">

                <button className="button button--danger button--round" disabled={isPosting} onClick={closeForm}>Cancel</button>
                <button className="button button--ghost button--round" disabled={isPosting} onClick={clearForm}>Clear</button>
                <button className="button button--primary button--round" disabled={isPosting} onClick={handleStoreNewPromotor}>
                    {usePromotor ? (isPosting ? 'Storing...' : 'Store Changes') : (isPosting ? 'Creating...' : 'Create')}
                </button>
            </Flex>
        </Flex>
    </div>
}

export default NewPromotorForm;