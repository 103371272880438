import ArtistsList from "../user-panels/promotor/artists/feed/ArtistsList"
import { useAppSelector } from "../utils/hooks/reduxTypedHooks"
import { Licence } from "../utils/models/client.model"
import AccessDeniedPage from "./AccessDeniedPage"

const ArtistsFeedPage = () => {
    const licence = useAppSelector(state => state.client?.licence)

    if(licence === Licence.Admin){
        return <ArtistsList />
    }  else if (licence === Licence.Promotor){
        return (<ArtistsList />)
    } else {
        return (<AccessDeniedPage />)
    }
}

export default ArtistsFeedPage