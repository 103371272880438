import { ls, pnlAmountFormatter } from "../../../../../../utils/utils"

const ArtistCostsTable = ({ currency, currencySymbol, rate, guaranteedFee, artistWitholdingTax, artistWitholdingTaxAmount, airFare, totalArtistCost }: {
    currency: string,
    currencySymbol: null|string,
    rate: null|number,
    guaranteedFee: number,
    artistWitholdingTax: number,
    artistWitholdingTaxAmount: number,
    airFare: number,
    totalArtistCost: number,
}) => {

    const guaranteedFeeUSD = rate ? guaranteedFee / rate : 0
    const artistWitholdingTaxAmountUSD = rate ? artistWitholdingTaxAmount / rate : 0
    const airFareUSD = rate ? airFare / rate : 0
    const totalArtistCostUSD = rate ? totalArtistCost / rate : 0

    return (
        <table className="app-table">
            <thead>
                <tr>
                    <th className="leftalign">Artist cost</th>
                    <th className="leftalign">Input</th>
                    <th className="leftalign">{currencySymbol ?? currency}</th>
                    <th className="leftalign">USD</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <th className="leftalign">Artist Fee</th>
                    <th className="leftalign">{pnlAmountFormatter(guaranteedFee)}</th>
                    <th className="leftalign error">{currencySymbol ?? currency} {pnlAmountFormatter(guaranteedFee)}</th>
                    <th className="leftalign error">${pnlAmountFormatter(guaranteedFeeUSD)}</th>
                </tr>
                <tr>
                    <th className="leftalign">Artist Witholding Tax (%)</th>
                    <th className="leftalign">{artistWitholdingTax}%</th>
                    <th className="leftalign error">{currencySymbol ?? currency} {pnlAmountFormatter(artistWitholdingTaxAmount)}</th>
                    <th className="leftalign error">${pnlAmountFormatter(artistWitholdingTaxAmountUSD)}</th>
                </tr>
                <tr>
                    <th className="leftalign">Air Fare Contribution</th>
                    <th className="leftalign">{pnlAmountFormatter(airFare)}</th>
                    <th className="leftalign error">{currencySymbol ?? currency} {pnlAmountFormatter(airFare)}</th>
                    <th className="leftalign error">${pnlAmountFormatter(airFareUSD)}</th>
                </tr>
                <tr>
                    <th className="leftalign">Total Artist Cost</th>
                    <th className="leftalign"></th>
                    <th className="leftalign error">{currencySymbol ?? currency} {pnlAmountFormatter(totalArtistCost)}</th>
                    <th className="leftalign error">${pnlAmountFormatter(totalArtistCostUSD)}</th>
                </tr>
            </tbody>
        </table>
    )
}

export default ArtistCostsTable