
const InputSpruha = (
    {
        title,
        value,
        onChange,
        placeholder,
        disabled,
        type,
        textarea,
        rows,
        content,
        className
    } : {
        title: string,
        value: string | number | undefined,
        onChange: any,
        placeholder?: string,
        disabled?: boolean,
        type?: string,
        textarea?: boolean,
        rows?: number,
        content?: any,
        className?: string
    }
) => {

    return (
        <div className={className}>
            <label className="label-ts">{title}</label>
            {content 
            ?   <div className={`input-ts ${disabled ? 'input-ts--disabled' : ''}`}>
                    {content}
                </div>
            :   textarea
                ?   <textarea
                        value={value}
                        onChange={onChange}
                        className="textarea-ts"
                        placeholder={placeholder}
                        disabled={disabled}
                        rows={rows ?? 10}
                    />
                :   <input 
                        value={value}
                        onChange={onChange}
                        className="input-ts"
                        placeholder={placeholder}
                        disabled={disabled}
                        type={type ?? "text"}
                    />}
        </div>
    )
}

export default InputSpruha