import PageLoading from "../../../../../components/loading/PageLoading"
import useFetchPromotorDrafts from "../../../../../utils/hooks/offer/useFetchPromotorDrafts"
import useFilter from "../../../../../utils/hooks/useFilter"
import OfferDraftItemRow from "./OfferDraftItemRow"

const PromotorOfferDrafts = () => {

    const {isFetchingDrafts, drafts } = useFetchPromotorDrafts()

    const { filterInput, FilterUI} = useFilter('Search by artist name')

    if(isFetchingDrafts) {
        return (<PageLoading />)
    }

    const filteredDrafts = drafts?.filter(draft => draft.artistName.toLowerCase().includes(filterInput.toLowerCase()))

    return (
        <div className='main-container margedBot100px paddedTop40px'>
            <h3 className="page-title">Offer Drafts</h3>

            <div className="margedBot20px">
                {FilterUI}
            </div>

            {filteredDrafts?.length 
            ?   <table className="app-table ">
                    <thead>
                        <tr>
                            <th className="leftalign">Date Created</th>
                            <th className="leftalign">Artist</th>
                            <th className="leftalign">Number of Shows</th>
                            <th className="leftalign">Countries</th>
                            <th>Continue Draft</th>
                            <th>Delete</th>
                        </tr>
                    </thead>

                    <tbody>
                        {filteredDrafts.map((draft, index) => <OfferDraftItemRow key={index} draft={draft} />)}
                    </tbody>
                </table>
            :   <p className="grey">No drafts for now.</p>}
        </div>
    )
}

export default PromotorOfferDrafts