import { useState } from "react"
import ReactCountryFlag from "react-country-flag"
import { Flex } from "../../../../../components/Containers"
import LoadingOval from "../../../../../components/loading/LoadingOval"
import useIsPromotor from "../../../../../utils/hooks/useIsPromotor"
import useSetSnack from "../../../../../utils/hooks/useSetSnack"
import { BandsintownEvent, SeatgeekEvent } from "../../../../../utils/models/artists.model"
import { countries, TouringDay, TouringDayWithTouringId } from "../../../../../utils/models/touring.model"
import { plural } from "../../../../../utils/utils"
import OutsideTourItem from "./OutsideTourItem"
import TourItem from "./TourItem"

const ArtistToursList = ( {primuseShows, openOfferForm, outsideEvents } : 
    {
        primuseShows: TouringDayWithTouringId[] | null,
        openOfferForm: Function, 
        outsideEvents: (SeatgeekEvent|BandsintownEvent)[] | undefined
    }) => {
    const initialIndexes = primuseShows ? primuseShows.map(_ => false) : []
    const [selectedIndexes, setSelectedIndexes] = useState(initialIndexes)

    const setSnack = useSetSnack()
    const isPromotor = useIsPromotor()

    if(outsideEvents === undefined) {
        return <Flex className='app-card width80'>
            <LoadingOval />
        </Flex> 
    }

    
    const markedOutsideEvents = outsideEvents.map(event => ({...event, isOutsideEvent: true}))
    const _primuseShows = primuseShows ?? []
    const upcomingPrimuseShows = _primuseShows.filter(show => !show.isPast)
    const allTours = [...upcomingPrimuseShows, ...markedOutsideEvents]
    
    // sorting chronollogically
    allTours.sort((tourA, tourB) => {
        //@ts-ignore differentiating which tour we have with the mark isOutsideEvent
        const dateA: string = tourA.isOutsideEvent ? tourA.datetime_local : tourA.date
        //@ts-ignore
        const dateB: string = tourB.isOutsideEvent ? tourB.datetime_local : tourB.date
        const timeA = new Date(dateA).getTime()
        const timeB = new Date(dateB).getTime()
        return timeA - timeB
    })

    let selectedDays: TouringDay[] = []
    if(primuseShows) {
        for(let i = 0 ; i < primuseShows.length ; i++){
            if(selectedIndexes[i]){
                selectedDays.push(primuseShows[i])
            }
        }
    }
    
    const toggleDay = (dayIndex: number) => {
        setSelectedIndexes(prevDays => {
            const newDays = [...prevDays]
            newDays[dayIndex] = !prevDays[dayIndex]

            return newDays
        })
    }

    const removeSelectedDay = (selectedDayIndex: number) => {
        if(!primuseShows) { return }
        const dayIndex = primuseShows.findIndex(day => selectedDays[selectedDayIndex].id === day.id)
        if(dayIndex !== -1) {
            toggleDay(dayIndex)
        }
    }

    const handleRemoveAllDays = () => {
        setSelectedIndexes(initialIndexes)
    }

    const handleBook = () => {
        if(!isPromotor) {
            setSnack({type: "warning", content: "Only a Kisum Promotor can book a show"})
            return
        }
        if(selectedDays.length === 0) {
            setSnack({type: 'warning', content: 'Please select at least one day'})
        } else {
            openOfferForm(selectedDays)
        }
    }

    
    return(
        <div className="flex-only row">
            <div className="app-card width80 margedRight20px">
                {allTours.length === 0
                &&  <p className="grey">No upcoming tours.</p>}

                {allTours.map((tour, index) => {
                // @ts-ignore
                const dayIndex = primuseShows ? primuseShows.findIndex(show => show.id === tour.id) : -1
                // @ts-ignore
                return tour.isOutsideEvent 
                ?   // @ts-ignore
                    <OutsideTourItem key={index} event={tour}/>
                :   <TourItem 
                        openOfferForm={openOfferForm} 
                        // @ts-ignore
                        tour={tour} 
                        key={index} 
                        dayIndex={dayIndex}
                        toggleDay={toggleDay}
                        selectedIndexes={selectedIndexes}
                    />})}

            </div>
            <div className="width20">
                <div className="app-card">
                    <h4 className="margedBot20px">Booking Cart</h4>

                    <div className="margedBot30px">
                        {selectedDays.length
                        ?   selectedDays.map((day, index) => <div className="margedBot10px flex-align-center row space-between" key={index}>
                            <p>{day.date}</p>
                            <div>
                                <ReactCountryFlag
                                    countryCode={day.country} 
                                    style={{
                                        lineHeight: '20px',
                                        fontSize: '20px',
                                        marginRight: '3px'
                                    }}
                                    title={countries[day.country]}
                                />
                                {countries[day.country]}
                                <button className="button button--ghost button--round button--small margedLeft10px" onClick={() => removeSelectedDay(index)}>Remove</button>
                            </div>
                            </div>)
                        :   <p className="grey">No selected days.</p>}
                    </div>

                    <Flex>
                        <Flex row>
                            <button onClick={handleRemoveAllDays} disabled={selectedDays.length === 0} className="button button--ghost button--round margedRight10px">Remove all</button>
                            <button onClick={handleBook} disabled={selectedDays.length === 0} className="button button--primary button--round">
                                Book ({selectedDays.length}) {plural('day', selectedDays.length)}
                            </button>
                        </Flex>
                    </Flex>
                </div>
            </div>
        </div>
    )
}

export default ArtistToursList