import Tabs from "../../../components/tabs-menu/Tabs"
import { Offer, OfferDraft } from "../../../utils/models/offer.model"
import ViewPNL from "./pnl/ViewPNL"

const ViewOfferPNLs = ( { offer, rates, currencySymbols }: {
    offer: Offer|OfferDraft,
    rates: (null|number)[],
    currencySymbols: (null|string)[],
} ) => {
    
    const { shows } = offer

    if(!shows.length) {
        return <p className="gray">The offer has no Shows.</p>
    }

    return (
        <Tabs activeTab='Show 1'>

            {shows.map((show, index) => 
                <div option-data={`Show ${index +1}`}>
                    <ViewPNL
                        show={show}
                        rate={rates[index]}
                        currencySymbol={currencySymbols[index]}
                        artistName={offer.artistName}
                    />
                </div>)}

        </Tabs>
    )
}

export default ViewOfferPNLs