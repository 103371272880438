import { TouringWithArtist, TouringDayWithTouringId } from "../../../utils/models/touring.model"
import { TfiLocationPin } from "react-icons/tfi"
import { AiOutlineCalendar } from "react-icons/ai"
import { FiClock } from "react-icons/fi"
import { BiMoney } from "react-icons/bi"
import { BiCommentDetail } from "react-icons/bi"
import { AiOutlineFolder } from "react-icons/ai"
import { ROUTE_ARTISTS } from "../../../routes/routes.const"
import { Link } from "react-router-dom"
import moment from 'moment'
import { countries } from "../../../utils/models/touring.model"
import { ls } from "../../../utils/utils"
import { Artist, ArtistFromDB, NormalizedArtistMarcoDB } from "../../../utils/models/artists.model"

import styles from "./touring.module.css"
import { useAppSelector } from "../../../utils/hooks/reduxTypedHooks"
import { Licence } from "../../../utils/models/client.model"
import { formatDisplayableNameToUrl } from "../../../utils/artists.util"

// Should pass either touring or touringDay (+ the next 5) depending on the data
const CalendarEventModal = ({touring , touringDay, artist, selectedDays, dayIndex, toggleDay, bookDay }: 
    {   touring?: TouringWithArtist | undefined, 
        touringDay?: TouringDayWithTouringId | undefined,
        artist?: Artist|ArtistFromDB|NormalizedArtistMarcoDB,
        selectedDays?: boolean[], 
        dayIndex?: number, 
        toggleDay?: Function ,
        bookDay?: Function
    }) => {

    const artistBookingUrl = artist ? `${ROUTE_ARTISTS}/${formatDisplayableNameToUrl(artist.displayableName)}/tours#booking` : ''

    const licence = useAppSelector(state => state.client?.licence)
    const isAdmin = licence === Licence.Admin

    const handleToggleDay = () => {
        if(!touringDay || dayIndex === undefined || !toggleDay){return}
        toggleDay(dayIndex)
    }

    const handleBookDay = () => {
        if(!touringDay || !bookDay){return}
        bookDay(touringDay)
    }

    return (
        <>
            {(touring || touringDay) ?
                <div className={styles.eventModalWrapper}>
                    <div className={styles.eventModalGrid}>
                            {touring 
                            ?   <img src={`${touring.artist.image}`} className={styles.eventModalImage} alt="artist logo" />
                            :   <img src={`${artist?.image}`} className={styles.eventModalImage} alt="artist logo" />}
                        <div className="flex-align-center space-between touring-modal__content-div">
                            <div className={styles.itemTextDiv}>
                                <h3 className={styles.modalArtistTitle}>{touring ? touring.artist.displayableName : artist?.displayableName}</h3>
                                <div className={`${styles.itemInfosContainer} ${styles.modalInfosContainer}`}>
                                        <div>
                                            
                                            <div className={styles.modalParagraphDiv}>
                                                <TfiLocationPin className={`${styles.icons} ${styles.modalIcons}`} color='var(--touring-main-color)' /> 
                                                {touring
                                                ?   <p>Countries : {touring.countries.map(country => countries[country]).join(' - ')}</p>
                                                :   touringDay && <p>Country : {countries[touringDay?.country]}</p>}
                                            </div>
                                            <div className={styles.modalParagraphDiv}>
                                                <AiOutlineCalendar className={`${styles.icons} ${styles.modalIcons}`} color='var(--touring-main-color)' /> 
                                                {touring
                                                ?   <p>From : {moment(new Date(touring.from)).format('MMMM Do YYYY')}</p>
                                                :   touringDay && <p>Date : {moment(new Date(touringDay.date)).format('MMMM Do YYYY')}</p>}
                                            </div>
                                            {touring && <div className={styles.modalParagraphDiv}>
                                                <AiOutlineCalendar className={`${styles.icons} ${styles.modalIcons}`} color='var(--touring-main-color)' /> 
                                                <p>Until : {moment(new Date(touring.to)).format('MMMM Do YYYY')}</p>
                                            </div>}
                                            <div className="">
                                                <div className={styles.modalParagraphDiv}>
                                                    <FiClock className={`${styles.icons} ${styles.modalIcons}`} color='var(--touring-main-color)' /> 
                                                    {touring
                                                    ?   <p>Show duration : {touring.showDuration} Minutes</p>
                                                    :   <p>Show duration : {touringDay?.showDuration} Minutes</p>}
                                                </div>
                                                {(touring && isAdmin) && 
                                                <>
                                                    <div className={styles.modalParagraphDiv}>
                                                        <BiMoney className={styles.icons} color='var(--touring-main-color)' /> 
                                                        <p>Possible fee : {ls(touring.possibleFee)}</p>
                                                    </div>
                                                    {touring.comments && <div className={styles.modalParagraphDiv}>
                                                        <BiCommentDetail className={styles.icons} color='var(--touring-main-color)' /> 
                                                        <p>Comments : {touring.comments}</p>
                                                    </div>}
                                                </>}
                                                
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div className="flex-only column">
                                {touring 
                                ?   <Link to={artistBookingUrl}><button className="button button--primary button--round">Book Artist</button></Link>
                                :   <>
                                        <button disabled={touringDay?.blocked.to !== ''} onClick={handleBookDay} className="button button--primary button--round margedBot20px">Book only this day</button>
                                        {(selectedDays && dayIndex !== undefined) && <button onClick={handleToggleDay} disabled={touringDay?.blocked.to !== ''} className="button button--primary button--round">{selectedDays[dayIndex] ? 'Remove' : 'Add'} day</button>}
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            :   <p>Tour not found</p>}
        </>
    )
}

export default CalendarEventModal