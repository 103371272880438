import { useState } from "react";
import { useAppSelector } from "../../../../utils/hooks/reduxTypedHooks";
import { Promotor } from "../../../../utils/models/promotors.model";
import PromotorDetails from "./details/PromotorDetails";
import PromotorsList from "./list/PromotorsList";


 const AdminPromotors = () => {
    const promotors = useAppSelector(state => state.promotors);
    const [openedPromotor, setOpenedPromotor] = useState<Promotor | null>(null);
    const [openPromotor, setOpenPromotor] = useState(false)

    const closePromotor = () => setOpenPromotor(false);

    return (
        openPromotor
        ? <PromotorDetails promotor={openedPromotor} close={closePromotor} />
        : <PromotorsList promotors={promotors} setOpenedPromotor={setOpenedPromotor} setOpenPromotor={setOpenPromotor} />
    )
}

export default AdminPromotors;