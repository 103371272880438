import { CONTRACT_STATUS_COMPLETED, CONTRACT_STATUS_IN_PROCESS, CONTRACT_STATUS_NULL_AND_VOID, CONTRACT_STATUS_SIGNED, CONTRACT_STATUS_WAITING_BOTH_SIGNATURES, CONTRACT_STATUS_WAITING_PRIMUSE_SIGNATURE, CONTRACT_STATUS_WAITING_PURCHASER_SIGNATURE } from "../../../../utils/models/contracts.model";

// Compute contract status with `waiting both signatures` | 'waiting purchaser signature' | 'waiting primuse signature' | `signed` | `nullandvoid` | `inprocess` | `completed`
const getContractStatus = (_contract) => {
    const {
        signatures,
        shows,
        status,         // non-auto status (set by contract admin) 

    } = _contract;

    const now = Date.now();
    const ttlSignatures = signatures?.length;
    const signed = ttlSignatures === 2;
    const showDates = shows.map(p => new Date(p.date).getTime()).sort((a, b) => (a > b));
    const firstShowDate = showDates[showDates.length-1];
    const lastShowDate = showDates[0];
    const hasSigned = (
        ttlSignatures
        ? signatures.map(s => ({[s.licence]: true})).reduce((a, b) => ({...a, ...b}))
        : {}
    );

  
    
    return (
        status || (
            // last show passed
            (lastShowDate < now) 
            ? (
                signed
                ? CONTRACT_STATUS_COMPLETED
                : CONTRACT_STATUS_NULL_AND_VOID
            )
            // last show tocome
            : (
                signed
                ? ((firstShowDate < Date.now()) ? CONTRACT_STATUS_IN_PROCESS : CONTRACT_STATUS_SIGNED)
                : (
                    hasSigned.admin
                    ? CONTRACT_STATUS_WAITING_PURCHASER_SIGNATURE
                    : (
                        hasSigned.promotor
                        ? CONTRACT_STATUS_WAITING_PRIMUSE_SIGNATURE
                        : CONTRACT_STATUS_WAITING_BOTH_SIGNATURES
                    )
                )
            )
        )
    );
}


export default getContractStatus;