import { forwardRef, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { touringLocationDropdownActions } from "../../../store/touringLocationDropdownSlice";
import { touringShowDurationDropdownActions } from "../../../store/touringShowDurationDropdownSlice";
import { useAppDispatch, useAppSelector } from "../../../utils/hooks/reduxTypedHooks";

import { getArtistsWithToursFromTourings, getCountriesFromTourings, getTouringsWithArtistFromTourings, isTourMatchingDates, LOCATION_EVERYWHERE, SHOW_DURATION_ALL_DURATIONS, getDurationsFromTourings } from "../../../utils/touring.util";
import CalendarScreen from "./CalendarScreen";
import CardScreen from "./CardScreen";
import Dropdown from "./dropdown/Dropdown";
import ListScreen from "./ListScreen";

import styles from "./touring.module.css"
import ButtonsFilter from "./buttons-filter/ButtonsFilter";
import { Flex } from "../../../components/Containers";
import LoadingOval from "../../../components/loading/LoadingOval";
import { Country, TouringWithArtist } from "../../../utils/models/touring.model";
import { ArtistWithTours } from "../../../utils/models/artists.model";
import { hasOneMatchInTwoArrays } from "../../../utils/utils";
import PromotorFeedback from "../common/feedback/PromotorFeedback";

const PromotorTouring = () => {
    const today = new Date()
    today.setHours(0,0,0,0)
    const in6months = new Date(today.getTime())
    in6months.setMonth(today.getMonth()+6)
    
    const [screen, setScreen] = useState<ScreenKeys>('list')
    // All 5 filters
    const [fromDateFilter, setFromDateFilter] = useState(today)
    const [toDateFilter, setToDateFilter] = useState(in6months) // By default seting the filter as from now until now+6 months
    const [textFilter, setTextFilter] = useState('')
    const locationFilter = useAppSelector(state => state.touringLocationDropdown.locationFilter)
    const durationsFilter = useAppSelector(state => state.touringShowDurationDropdown.durationsFilter)
    const isDurationDropdownOpen = useAppSelector(state => state.touringShowDurationDropdown.isOpen)

    const [computedAvailableCountries, setComputedAvailableCountries] = useState<Country[] | undefined>(undefined)
    const [computedAvailableDurations, setComputedAvailableDurations] = useState<number[] | undefined>(undefined)
    const [computedArtistsWithTours, setComputedArtistsWithTours] = useState<ArtistWithTours[] | undefined>(undefined)
    const [computedToursWithArtist, setComputedToursWithArtist] = useState<TouringWithArtist[] | undefined>(undefined)


    const dispatch = useAppDispatch()
    
    const artists = useAppSelector(state => state.artists)
    const tourings = useAppSelector(state => state.tourings)

    useEffect(() => {
        const computeAvailableOptions = async () => {
            const countriesPromise = getCountriesFromTourings(tourings)
            const durationsPromise = getDurationsFromTourings(tourings)

            const [countries, durations] = await Promise.all([countriesPromise, durationsPromise])

            setComputedAvailableCountries(countries)
            setComputedAvailableDurations(durations)
        }

        computeAvailableOptions()
    }, [])

    // first filtering tourings through location, type, duration, and dates
    const isLocationFilterEverywhere = locationFilter.includes(LOCATION_EVERYWHERE)
    const isDurationFilterAllDurations = durationsFilter.includes(SHOW_DURATION_ALL_DURATIONS)
    
    const partiallyFilteredTourings = tourings.filter(touring => {
        const locationFiltering = (isLocationFilterEverywhere || hasOneMatchInTwoArrays(touring.countries, locationFilter))
        const durationFiltering = (isDurationFilterAllDurations || durationsFilter.includes(touring.showDuration))
        const dateFiltering = isTourMatchingDates(touring.from, touring.to, fromDateFilter, toDateFilter)

        return (locationFiltering  && durationFiltering && dateFiltering)
    })

    //make touringWithArtist array and artistWithTours array
    useEffect(() => {
        const computeArtistsWithTours = async () => {
            const computedArtists = await getArtistsWithToursFromTourings(artists, partiallyFilteredTourings)
            setComputedArtistsWithTours(computedArtists)
        }

        const computeToursWithArtists = async () => {
            const computedTours = await getTouringsWithArtistFromTourings(artists, partiallyFilteredTourings)
            setComputedToursWithArtist(computedTours)
        }

        computeArtistsWithTours()
        computeToursWithArtists()
        //! need to rerun the effect when partiallyFilteredTouring changes which is when one of the filter changes
    }, [locationFilter, durationsFilter, fromDateFilter, toDateFilter])

    const isLoading = (computedAvailableCountries === undefined || computedAvailableDurations === undefined || computedArtistsWithTours === undefined || computedToursWithArtist === undefined)
    if(isLoading){
        return (<Flex>
            <LoadingOval />
        </Flex>)
    }

    const availableCountries = [LOCATION_EVERYWHERE, ...computedAvailableCountries]
    const availableDurations = [SHOW_DURATION_ALL_DURATIONS, ...computedAvailableDurations]

    const handleReset = () => {
        setTextFilter('')
        setFromDateFilter(today)
        setToDateFilter(in6months)
        dispatch(touringLocationDropdownActions.reset())
        dispatch(touringShowDurationDropdownActions.reset())
    }

    const handlePickFromDate = (date: Date) => {
        // puting time at 0:00:00 to have the same day INCLUDED in the fitler
        date.setHours(0, 0, 0, 0)
        setFromDateFilter(date)
    }
    const handlePickToDate = (date: Date) => {
        date.setHours(0, 0, 0, 0)
        setToDateFilter(date)
    }

    // then lastly filter from text input
    const fullyFilteredArtists = computedArtistsWithTours.filter(artist => {
        const lowerCFilter = textFilter.toLowerCase()
        const nameFilter = artist.displayableName.toLowerCase().includes(lowerCFilter)
        const bioFilter = artist.bio ? artist.bio.toLowerCase().includes(lowerCFilter) : false
        return (nameFilter|| bioFilter)
    })

    const fullyFilteredTours = computedToursWithArtist.filter(tourWithArtist => {
        const lowerCFilter = textFilter.toLowerCase()
        const nameFilter = tourWithArtist.artist.displayableName.toLowerCase().includes(lowerCFilter)
        const bioFilter = tourWithArtist.artist.bio ? tourWithArtist.artist.bio.toLowerCase().includes(lowerCFilter) : false
        return (nameFilter|| bioFilter)
    })

    // then sort
    fullyFilteredTours.sort((tourA, tourB) => {
        const artistAfromNumber = new Date(tourA.from).getTime()
        const artistBfromnumber = new Date(tourB.from).getTime()
        return  artistAfromNumber -artistBfromnumber
    })


    // routing
    const screens = {
        calendar: {
            title: "Calendar",
            component: <CalendarScreen tourings={fullyFilteredTours} />
        },
        list: {
            title: "List",
            component: <ListScreen tourings={fullyFilteredTours} />
        },
        card: {
            title: "Cards",
            component: <CardScreen touringArtists={fullyFilteredArtists} />
        }
    }
    type ScreenKeys = keyof typeof screens

    // filters and dropdowns inputs
    // location
    const handleToggleLocationItem = (location: string) => dispatch(touringLocationDropdownActions.toggleLocation(location))

    //duration
    const handleToggleDurationDropdown = () => dispatch(touringShowDurationDropdownActions.toggleDropdown())
    const handleCloseDurationDropdown = () => dispatch(touringShowDurationDropdownActions.closeDropdown())
    const handleToggleDurationItem = (duration: number) => dispatch(touringShowDurationDropdownActions.toggleDuration(duration))
    

    // custom input for date picker
    // @ts-ignore
    const DatePickerCustomInput = forwardRef(({ value, onClick }, ref) => (
        // @ts-ignore
        <button className={styles.datePickerInputBtn} onClick={onClick} ref={ref}>
          {value}
        </button>
      ));
      
    return(
        <div className={styles.wrapper}>
            <div className={styles.container}>
            <div className="margedTop20px">
                <PromotorFeedback page="Touring" />
            </div>

            <div className="app-card margedTop30px margedBot50px">
                <div className="paddedTop10px">

                        <div className="allwidth">
                            <div className={`${styles.navItem} margedBot10px`}>
                                <input type="text" className={styles.textFilterInput} value={textFilter} placeholder="Name, description" onChange={(e) => setTextFilter(e.target.value)}></input>
                            </div>
                            <ul className="flex-align-center space-between">
                            
                                <li className={styles.navItem}>
                                    <Dropdown 
                                        title="Duration"
                                        isDurations
                                        isOpen={isDurationDropdownOpen}
                                        items={availableDurations} 
                                        handleToggleDropdown={handleToggleDurationDropdown}
                                        handleClose={handleCloseDurationDropdown}
                                        handleClickItem={handleToggleDurationItem}
                                        currentFilter={durationsFilter}
                                    />
                                </li>
                                <p className={styles.datePickerLabel}>From</p>
                                <li className={`${styles.navItem} noPaddingLeft`}>
                                    <DatePicker 
                                        customInput={<DatePickerCustomInput />} 
                                        selected={fromDateFilter} 
                                        onChange={(selectedDate:Date) => {
                                            selectedDate.setHours(0,0,0,0)
                                            handlePickFromDate(selectedDate)
                                        }} 
                                    />
                                    {/* <DatePicker className="picker-test" onChange={setFromDateFilter} value={fromDateFilter} /> */}
                                </li>
                                <p className={styles.datePickerLabel}>To</p>
                                <li className={`${styles.navItem} noPaddingLeft`}>
                                    <DatePicker 
                                        customInput={<DatePickerCustomInput />} 
                                        selected={toDateFilter} 
                                        onChange={(selectedDate:Date) => {
                                            selectedDate.setHours(0,0,0,0)
                                            handlePickToDate(selectedDate)
                                        }} 
                                        minDate={fromDateFilter} 
                                    />
                                    {/* <DatePicker minDate={fromDateFilter} onChange={setToDateFilter} value={toDateFilter} /> */}
                                </li>
                                <li className={styles.navItem}>
                                    <button className="button touring__button touring__nav-button" onClick={handleReset}>Reset</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    
                </div>
            </div>

            <div className={styles.container}>
                <div className='flex-only justifyend'>
                        <div className="flex-align-center space-between">
                            {Object.keys(screens).map((screenName, index: number) => 
                                <div className='screens-buttons-div' key={index}>
                                    <button 
                                        className={`button touring__button touring-ghost__button button--round ${screen === screenName ? 'touring-ghost__button--active' : ''}`}
                                        onClick={() => {
                                            {/* @ts-ignore ; fine because screen IS typeof ScreenKeys and not string */}
                                            setScreen(screenName)
                                        }} 
                                        >{/* @ts-ignore */}
                                            {screens[screenName].title}
                                    </button>
                                </div>
                                )}
                        </div>
                </div>
                
                <ButtonsFilter items={availableCountries} currentFilter={locationFilter} handleClickItem={handleToggleLocationItem} translateCountryCode={true}/>

                {screens[screen].component}
            </div>
        </div>
            
    )
}

export default PromotorTouring;