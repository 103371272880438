import { useState } from "react";
import Web3 from "web3";
import { Flex } from "../../../../components/Containers";
import { promisify, shortenAddress } from "../../../../utils/utils";

const supportedNetworks = [
    
    {
        name: "Matic Testnet Mumbai",
        chainId: '80001',
        chainIdHexa: new Web3().utils.toHex('80001')

    },
    {
        name: 'Polygon',
        chainId: '137',
        chainIdHexa: new Web3().utils.toHex('137')
    },
];

const isSupportedNetwork = async(chainId) => {
    // check that client connected on supported network
    const currentChainId = chainId || await promisify(window.ethereum?.networkVersion);   
    const supportedNetwork = await Promise.resolve(
        supportedNetworks.find(net => net.chainId === currentChainId)
    );
    return supportedNetwork?.name?.length > 0;
}

const buttonProps = (errMsg, clientAddress) => {
    const wErrParams = {
        'true': {
            title: errMsg,
            className: 'app-button error-button'
        },
        'false': {
            title: (
                clientAddress 
                ? <div className="flex title allwidth padded">
                    &nbsp;PROFILE&nbsp; 
                    <div className="subtitle silver">{shortenAddress(clientAddress)}</div>
                </div> 
                : 'CONNECT WALLET'
            ),
            className: clientAddress ? 'app-button profile-button' : ''
        }
    };
    const withErr = (errMsg?.length > 0).toString();
    return {...wErrParams[withErr]}
}


const ConnectWallet = props => {
    const [eventsSet, setEvents] = useState(false);
    const [MMRequested, setMMRequested] = useState(false);

    const connectWallet = async() => {
        // check network
        const supportsClientNetworkBefore = await isSupportedNetwork();
        let switched;

        if (!supportsClientNetworkBefore) {
            switched = await new Promise(async(_switched) => {
                /* request network switch */
                try {
                    setMMRequested(true)
                    await window?.ethereum?.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: supportedNetworks[0].chainIdHexa}],
                    });
                    _switched(true)
                }catch(e) {
                    _switched(false)
                }
            })
        }
        
        const supportsClientNetworkAfter = await promisify(
            switched || supportsClientNetworkBefore || await isSupportedNetwork()
        );

        // connect
        const accounts = await window?.ethereum?.request({method: "eth_requestAccounts" });
        const error = await promisify(
            !supportsClientNetworkAfter 
            ? 'Bad Network' 
            : (props.client?.error === 'Bad Network' ? '' : props.client?.error)
        );
        const address = await promisify(accounts?.length ? accounts[0] : undefined);
        props.setClient({ 
            error, 
            address
        });

        // skip if already set
        if (!eventsSet) {
            window.ethereum?.on(
                'accountsChanged', 
                async accounts => {
                    props.setClient({
                        address: accounts[0],
                        error: props?.client?.error
                    });
                }
            
            );
            window.ethereum?.on(
                'chainChanged', 
                async (chainIdHexa) => {
                    const { chainId } = await promisify(
                        supportedNetworks.find(net=>net.chainIdHexa===chainIdHexa) 
                        || {}
                    );
                    const supNet = await isSupportedNetwork(chainId);
                    
                    props.setClient({
                        error: !supNet ? 'Bad Network' : '',
                        address
                    });
                    
                }
            );
            setEvents(true);
        }
    }

    const bProps = buttonProps(props?.client?.error, props?.client?.address);

    return <Flex>
        <button inverted
            {...bProps}
            onClick={connectWallet}
            className="button button--primary button--small offer__action-btn"
        >
            {bProps.title}
        </button>
        &nbsp;
        {
            MMRequested
            && <i className="grey larger margedTop">Please accept MetaMask Request...</i>
        }
    </Flex>

}
export default ConnectWallet