import { OfferShow, OfferShowFormInputs } from "../../models/offer.model";
import computePnlData from "./computePnlDatas";

const computeBreakEvenPoint = ({ show }: {
    show: OfferShow | OfferShowFormInputs
}) => {

    const { totalTickets, sponsorAmount, otherIncomesAmount, airFare, guaranteedFee } = computePnlData({show})
    
    const { dealInputs, pnlInputs } = show
    const { witholdingTax: artistWitholdingTax } = dealInputs
    const { corporateTicketPurchase, variableCosts, fixedCosts } = pnlInputs

    const pourcentagesArray = [...new Array(101)].map((_, index) => index)

    //revenues
    const ticketsSoldArray = pourcentagesArray.map(pourcentage => Math.round(totalTickets.ticketsForSale * (pourcentage/100)))
    const ticketCollectionsArray = pourcentagesArray.map(pourcentage => totalTickets.sales * (pourcentage/100))
    const corporateTicketAmount = corporateTicketPurchase.has ? corporateTicketPurchase.amount : 0
    const totalRevenueArray = pourcentagesArray.map((_, index) => ticketCollectionsArray[index] + sponsorAmount + otherIncomesAmount + corporateTicketAmount)

    //costs
    const costsArrays = variableCosts.map(
        variableCost => pourcentagesArray.map((_, index) => variableCost.has ? (totalRevenueArray[index] * (variableCost.percentage/100)) : 0)
    )
    const totalVariableCostArray = pourcentagesArray.map((_, index) => costsArrays.reduce((total, costArray) => total + costArray[index], 0))

    const artistWitholdingTaxAmount = guaranteedFee * (artistWitholdingTax/100)
    const totalArtistCost = guaranteedFee + artistWitholdingTaxAmount + airFare

    const totalFixedCosts = fixedCosts.reduce((total, cost) => total += (cost.has ? cost.amount : 0), 0)

    const totalExpenditureArray = pourcentagesArray.map((_, index) => totalVariableCostArray[index] + totalArtistCost + totalFixedCosts)

    
    const profitsArray = pourcentagesArray.map((_, index) => totalRevenueArray[index] - totalExpenditureArray[index])

    const minProfits = profitsArray[0]
    const maxProfits = profitsArray[100]

    const hasBreakEvenPoint = (minProfits <= 0 && maxProfits >= 0)

    let breakEvenIndex = -1

    let i = 0
    while ( i < pourcentagesArray.length && breakEvenIndex === -1 ) {
        if(profitsArray[i] >=0 ){
            breakEvenIndex = i
        }
        i++
    }

    return { hasBreakEvenPoint, breakEvenIndex, ticketsSoldArray, profitsArray }

}

export default computeBreakEvenPoint