import { TouringWithArtist } from "../../../utils/models/touring.model"
import ListItem from "./ListItem"

const ListScreen = ({tourings}: {tourings: TouringWithArtist[]}) => {

    const artistsListDisplay = tourings.map((touring, index) => <ListItem key={index} artist={touring.artist} touring={touring} />)

    return (
        <>
            {artistsListDisplay.length ?
                artistsListDisplay
            :   <p className="paddedTop50px">No artists are matching your selection.</p>
            }
        </>
    )
}

export default ListScreen