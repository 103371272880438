import { artistsActions } from "../store/artistsSlice";
import { touringsActions } from "../store/touringsSlice";
import { TouringFormInputs } from "../user-panels/admin/admin-panel-screens/touring/TouringForm"
import { useAppDispatch, useAppSelector } from "./hooks/reduxTypedHooks";
import useFetchApiAuth from "./hooks/useFetchApiAuth";
import useSetSnack from "./hooks/useSetSnack";
import { Artist, ArtistCreateObject, ArtistMinifiedStreamingDatas, NormalizedArtistMarcoDB } from "./models/artists.model";
import { Touring, TouringCreateObject, TouringDayCreateObject, TouringMergeObject } from "./models/touring.model"


export const useValidateTouringForm = ({ formInputs, touring }: {
    formInputs: TouringFormInputs,
    touring?: Touring,
}) => {

    const setSnack = useSetSnack()

    const validateForm = () => {

        const serializedDays: TouringDayCreateObject[] = formInputs.shows.map((day, index) => {
            const blocked = {to: day.blocked.value, date: 0}
            // seting up the proper blocked date in case the day is blocked for someone
            if(blocked.to) {
                // if we are updating the touring and the blocked person didn't change we let the same date
                if(blocked.to === touring?.shows[index].blocked.to){
                    blocked.date = touring?.shows[index].blocked.date
                } else {
                    // if we block to a new person we set the blocked time as now
                    blocked.date = new Date().getTime()
                }
            }
            return {
                ...day,
                date: day.date.toDateString(),
                country: day.country?.value,
                showDuration: day.showDuration.value,
                blocked,
                isPnlMandatory: formInputs.isPnlMandatory
            }
        })
    
        //form validation for global data
        if(!formInputs.countries?.length || !formInputs.artistSelected?.value || !formInputs.name){
            setSnack({type: 'warning', content: 'You need to fill all the global data'})
            return {isFormValid: false, serializedDays: []}
        }
        if(!formInputs.shows.length){
            setSnack({type: 'warning', content: 'You tour needs to have at least 1 day'})
            return {isFormValid: false, serializedDays: []}
        }
    
        // form validation for each day or fill placeholder data in case of a day off
        for(let index = 0 ; index < serializedDays.length ; index++) {
            if(!serializedDays[index].country){
                if(serializedDays[index].isDayOff){
                    serializedDays[index].country = formInputs.countries[0].value
                } else {
                    setSnack({type: 'warning', content: 'You need to indicate the Country for each non off day'})
                    return {isFormValid: false, serializedDays: []}
                }
            }
    
        }
    
        return {isFormValid: true, serializedDays}
    }

    return validateForm
}


export const useHandleSubmitTouring = ({ formInputs, setIsPosting, validateForm, resetForm, artistToAdd, addArtist, streamingDatas, isDj} : {
    formInputs: TouringFormInputs,
    setIsPosting: React.Dispatch<React.SetStateAction<boolean>>,
    validateForm: () => { isFormValid: boolean; serializedDays: TouringDayCreateObject[]; },
    resetForm: () => void,
    artistToAdd?: NormalizedArtistMarcoDB,
    streamingDatas?: ArtistMinifiedStreamingDatas,
    addArtist?: boolean,
    isDj: boolean,
}) => {
    const dispatch = useAppDispatch()
    const fetchApiAuth = useFetchApiAuth()
    const setSnack = useSetSnack()
    const artists = useAppSelector(state => state.artists)

    const handleSubmit = async () => {
        setIsPosting(true)
        const { isFormValid, serializedDays } = validateForm()

        if(!isFormValid){
            setIsPosting(false)
            return 
        }

        let createdArtist = null as Artist|null

        // If creating new Artist
        if(addArtist) {

            console.log("adding artist", artistToAdd)
            if(!artistToAdd?.displayableName){
                setSnack({type: 'error', content: `Coudld't add artist, Artist Displayable Name not found`})
                setIsPosting(false)
                return 
            }

            if(!streamingDatas){
                setSnack({type: 'error', content: `Streaming Datas not found`})
                setIsPosting(false)
                return 
            }

            const image = streamingDatas.spotify?.artist?.image?.url ?? ''

            const initialData = { promotors: [], contracts: [] }
            const newArtist: ArtistCreateObject = {
                ...artistToAdd, 
                ...initialData,
                assets: {technique: [], rider: [], material: [], travelParty: [], other: []},
                areGeneralAssetsVisible: true,
                preferences: '',
                comments: '',
                contacts: [],
                ipis: [],
                isnis: [],
                updated: 0,
                isArchived: false,
                image,
                banner: image,
                streamingDatas,
                isDj
            }

            // await store new artist
            const { error, response } = await fetchApiAuth({
                method: 'POST',
                route: 'create-artist',
                body: newArtist
            });
            
            createdArtist = response

            // snack
            setSnack(
                error
                ? {
                    type: 'error', 
                    content: error.toString()
                }
                : {
                    type: 'success',
                    content: `${artistToAdd.displayableName} has been successfully added as Primuse artists #${response?.id}`
                }
            )
            
            if(!error){
                // update global artists
                dispatch(artistsActions.add(response))
            }
        }

        const now = new Date().getTime()

        const foundArtist = artists.find(artist => artist.displayableName === formInputs.artistSelected.value)
        const artistId = foundArtist?.id ?? createdArtist?.id

        if(!artistId){
            setSnack({type: 'warning', content: 'Artist not found'})
            setIsPosting(false)
            return
        }
        
        const newTouring: TouringCreateObject = {
            ...formInputs, 
            artistId,
            showDuration: formInputs.showDuration.value,
            countries: formInputs.countries.map(country => country.value),
            from: formInputs.from.toDateString(),
            to: formInputs.to.toDateString(),
            shows: serializedDays,
            dateCreated: now,
            requests: [],
        }

        // removing artistSelected (that we replaced with artistId) from the posted object
        //@ts-ignore
        delete newTouring['artistSelected']

        // await post new touring
        const { error, response: createdTouring } = await fetchApiAuth({
            method: "POST",
            route: "create-touring",
            body: {...newTouring}
        })

        // snack
        setSnack(
            error
            ? {
                type: 'error', 
                content: error.toString()
            }
            : {
                type: 'success',
                content: "The touring has been successfully added"
            })

        //update Redux state
        if(!error){
            dispatch(touringsActions.add(createdTouring))
    
            resetForm()
        }

        setIsPosting(false)
    }

    return handleSubmit
}


export const useHandleUpdateTouring = ({ formInputs, touring, setIsPosting, validateForm, closeForm } : {
    formInputs: TouringFormInputs,
    touring: Touring | undefined,
    setIsPosting: React.Dispatch<React.SetStateAction<boolean>>,
    validateForm: () => { isFormValid: boolean; serializedDays: TouringDayCreateObject[]; },
    closeForm: any,
}) => {

    const dispatch = useAppDispatch()
    const fetchApiAuth = useFetchApiAuth()
    const setSnack = useSetSnack()

    const handleUpdate = async () => {
        setIsPosting(true)

        const { isFormValid, serializedDays } = validateForm()

        if(!isFormValid || !touring?.id) {return}

        const changedTouring: TouringMergeObject = {
            ...formInputs, 
            id: touring.id,
            artistId: touring.artistId, 
            showDuration: formInputs.showDuration.value,
            countries: formInputs.countries.map(country => country.value),
            from: formInputs.from.toDateString(),
            to: formInputs.to.toDateString(),
            shows: serializedDays,
        }

        // removing artistSelected (that we replaced with artistId) from the posted object
        //@ts-ignore
        delete changedTouring['artistSelected']

        // await post new touring
        const { error, response: updatedTouring } = await fetchApiAuth({
            method: "POST",
            route: "merge-touring",
            body: {...changedTouring}
        })

        // snack
        setSnack(
            error
            ? {
                type: 'error', 
                content: error.toString()
            }
            : {
                type: 'success',
                content: "The touring has been successfully updated"
            })

        
        //update Redux state
        if(!error){
            dispatch(touringsActions.merge(updatedTouring))

            if(closeForm) {
                closeForm()
            }
        }

        setIsPosting(false)
    }

    return handleUpdate
}