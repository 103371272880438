import computeBreakEvenPoint from "../../../../../../utils/hooks/offer/computeBreakEvenPoint";
import { OfferShow, OfferShowFormInputs } from "../../../../../../utils/models/offer.model";
import { pnlAmountFormatter } from "../../../../../../utils/utils";

const BreakEvenPointBox = ({ currency, currencySymbol, show }: {
    currency: string,
    currencySymbol: null|string,
    show: OfferShowFormInputs | OfferShow
}) => {

    const { hasBreakEvenPoint, breakEvenIndex, ticketsSoldArray, profitsArray } = computeBreakEvenPoint({show})

    return (
        <div className="app-card leftalign">
            {hasBreakEvenPoint
            ?   <div>
                    <p>Break Even : <b className="green-color">{breakEvenIndex}%</b> ({ticketsSoldArray[breakEvenIndex]}) tickets sold</p>
                    <p>Profit : {currencySymbol ?? currency} {pnlAmountFormatter(profitsArray[breakEvenIndex])}</p>
                </div>
            :   <p>No Break Even point</p>}

        </div>
    )
}

export default BreakEvenPointBox