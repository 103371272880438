import ButtonItem from "./ButtonItem"

import "./button-filter.css"

const ButtonsFilter = ({ items, currentFilter, handleClickItem, translateCountryCode }: { items: string[], currentFilter: string[], handleClickItem: any, translateCountryCode?: boolean }) => {

    const ButtonsFilterItems = items.map((item, index) => <ButtonItem key={index} currentFilter={currentFilter} item={item} handleClickItem={handleClickItem} translateCountryCode={translateCountryCode} />)

    return (
		<div className="buttons-filter__div">
			{ButtonsFilterItems}
		</div>
    )
}

export default ButtonsFilter