import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OfferDraft } from '../utils/models/offer.model';

type DraftState = OfferDraft[] | null

const initialState = null;

export const offerDraftsSlice = createSlice({
  name: 'offerDrafts',
  initialState: initialState as DraftState,
  reducers: {
    set: (state, action: PayloadAction<OfferDraft[]>) => {
        return action.payload
    },
    push: (state, action: PayloadAction<OfferDraft>) => {
        if(!state) { return } 

        state.push(action.payload)
    },
    merge: (state, action: PayloadAction<OfferDraft>) => {
      if(!state) { return }

        const {id, ...mergeProps} = action.payload
        const draftIndex = state.findIndex(draft => draft.id === id)
        if(draftIndex !== -1){
            state[draftIndex] = {...state[draftIndex], ...mergeProps}
        }
    },
    delete: (state, action: PayloadAction<{id: string}>) => {
        if(!state) { return }

        const foundIndex = state.findIndex(promotor => promotor.id === action.payload.id)
        if(foundIndex !== -1){
            state.splice(foundIndex, 1)
        }
    }
  },
});

export const offerDraftsActions = offerDraftsSlice.actions;

export default offerDraftsSlice.reducer;