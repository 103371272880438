import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Promotor } from '../utils/models/promotors.model';

const initialState: Promotor[] = [];

export const promotorsSlice = createSlice({
  name: 'promotors',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<Promotor[]>) => {
      return action.payload
    },
    merge: (state, action: PayloadAction<Promotor>) => {
      const {id, ...mergeProps} = action.payload;
     
        return (
          id
          ? state.map(p=>((p.id===id) ? {...p, ...mergeProps} : p))
          : state
        );
    },
    add: (state, action: PayloadAction<Promotor>) => {
      state.push(action.payload);
    },
    delete: (state, action: PayloadAction<{id: string}>) => {
      const searchId = action.payload.id
      const promotorIndex = state.findIndex(promotor => promotor.id === searchId)
      if(promotorIndex !== -1){
        state.splice(promotorIndex, 1)
      }
    },
  }
});

export const promotorsActions = promotorsSlice.actions;

export default promotorsSlice.reducer;