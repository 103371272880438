import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Contract } from '../utils/models/contracts.model';

const initialState: Contract[] = [];

export const contractsSlice = createSlice({
  name: 'contracts',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<Contract[]>) => {
      return action.payload
    },
    merge: (state, action: PayloadAction<Contract>) => {
      state.push(action.payload);
    },
    update: (state, action: PayloadAction<Contract>) => (
      state.map(s => (
        s.id === action.payload.id
        ? {...s, ...action.payload}
        : s
      ))
    ),
  },
});

export const contractsActions = contractsSlice.actions;

export default contractsSlice.reducer;