import DropdownMenu from './dropdownMenu'
import DropdownToggler from './dropdownToggler'

import './dropdown.css'

const Dropdown = ({ items, title, isOpen, handleToggleDropdown, handleClose, currentFilter, handleClickItem, isDurations }: 
    {   items: string[] | number[], 
        title: string, isOpen: boolean, 
        handleToggleDropdown: any, 
        handleClose: any, 
        currentFilter: string[] | number[], 
        handleClickItem: any, 
        isDurations?: boolean  
    }) => {

    return (
        <DropdownToggler isOpen={isOpen} handleToggleDropdown={handleToggleDropdown} handleClose={handleClose} title={title} >
            <DropdownMenu handleClickItem={handleClickItem} currentFilter={currentFilter} items={items} isDurations={isDurations} /> 
        </DropdownToggler>
    )
}

export default Dropdown