import { useState } from "react";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { snackActions } from "../../store/snackSlice";
import { Flex, Row } from "../Containers";
import Dropzone from "../Dropzone";
import LabelInput from "./mui/label-input/LabelInput";
import CreatableSelect from 'react-select/creatable';
import { MdOutlineAddTask } from "react-icons/md";
import { theme } from "../../constants";
import { AiFillCloseCircle } from "react-icons/ai";

/**
 * 
 * @returns 
 */
const FormCategory = (
    {
        category,
        inputs,
        columns,
        index,
        content

    } : {
        category?: string;
        inputs?: any[],
        columns?:any[],
        index: number,
        content?: React.ReactNode
        
    }
) => {
    const [newSocial, _setNewSocial] = useState({ key: {label: '', value: ''}, url: '' });
    const setNewSocial = (nsProps:any) => _setNewSocial({...newSocial, ...nsProps});

    const dispatch = useDispatch();
    const setSnack = (snack:any) => dispatch(snackActions.set(snack));

    const renderInput = (inp:any, inpi:number) => (
        inp.options?.length
        ? <Flex className="allwidth">
            <label className="selfstart bold">{ inp.title }</label>
            <Select
                isClearable
                placeholder={inp.title}
                className="width90 selfstart"
                options={inp.options}
                onChange={inp.onChange} 
                value={inp.options.find((o:any) => o.value === inp.value)}
            />
        </Flex>
        : (
            inp.file
            ? <Flex className="allwidth">
                <label className="selfstart bold">{ inp.title }</label>
                {   inp.value 
                    ? <div>
                        <div style={{ position: 'relative', top: '10px', left: '50%' }} className='clickable' onClick={() => inp.onChange({imageDatas: {source: ''}})}><AiFillCloseCircle color='grey' size={20} /></div>
                        <img src={inp.value} style={{ width: '15vw', height: 'auto', maxHeight: '15vh', objectFit: 'contain' }} alt={`${inpi}_logo`} />
                    </div>
                    : <Dropzone
                        title='Drop an image here or click to select'
                        accept='.jpg,.jpeg,.png'
                        setFile={inp.onChange}
                    />
                }
            </Flex>
            : (
                inp.stack
                ? <Flex className="allwidth">
                    <label className="selfstart bold">{ inp.title }</label>

                    <Flex className="alignstart allwidth marged-t">
                    {
                        Object.keys(inp.stack.value).map((sm:any, smi:number) => <Flex key={`inpstack_${inpi}_soi${smi}`} row>
                            {/* removable row */}
                            { inp.stack.renderer(sm) }
                        </Flex>)
                    }
                    </Flex>
                    <Flex row className="selfstart marged-t width95 bordered">
                        <Flex flex={4}>
                            <CreatableSelect
                                isClearable
                                options={inp.stack.options.filter((o:any) => !inp.stack.value[o.value])}
                                placeholder='Select Media'
                                className="selfstart allwidth noborder"
                                styles={{
                                    control: base => ({
                                        ...base,
                                        border: 0,
                                        // This line disable the blue border
                                        boxShadow: 'none'
                                    })
                                }}
                                onChange={(newValue: any) => {
                                    setNewSocial({key: newValue })
                                }}   

                                value={newSocial?.key?.value ? newSocial.key : null}
                            />
                        </Flex>
                        
                        <Flex flex={5}>
                        {
                            true    // mediaSelected ?
                            ? <Flex row className="selfstart allwidth">
                                <input 
                                    className="app-input nomargin allspace nbsp" 
                                    style={{minHeight: '38px'/* idem CreatableSelect `css-c8odyh-control` class*/}}
                                    placeholder={`https://`} 
                                    onChange={(e:React.ChangeEvent<HTMLInputElement>): void => setNewSocial({url: e.target.value})} 
                                    value={newSocial?.url}
                                />
                            </Flex>
                            : null
                        }
                        </Flex>
                        <Flex flex={1}>
                            <MdOutlineAddTask
                                size={20} 
                                color={theme[0]} 
                                className="clickable" 
                                onClick={() => {
                                    if (newSocial.key.value && newSocial.url) {
                                        inp.stack.onChange({...inp.stack.value, [newSocial.key.value]: newSocial.url});
                                        setNewSocial({ key: {}, url: '' });
                                    }
                                    else setSnack({ type: 'warning', content: 'Invalid Social' })
                                }} 
                            />
                        </Flex>
                    </Flex>
                    
                </Flex>
                :  <LabelInput bordered
                        key={`npromform_${index}_${inpi}`}
                        {...inp}
                    />
            )
        )
    )
                                console.log('content ?', content)
    return <Flex className="allwidth alignstart marged-t">
        { category && <label className="bold xlarge uppercase main-color text-shadow">{category} :</label> }
        <div className="flex margedLeft allwidth">
            <Row>
                {
                    inputs?.length
                    ? inputs.map(renderInput)
                    : (
                        columns?.length
                        ? <Flex row key={`npromformcol_${index}`} className='allwidth alignstart'>
                            { 
                                columns.map((col:any, coli) => <Flex key={`npromformcol_${index}_${coli}`} className='allwidth'>
                                    {
                                        col.inputs.map(renderInput)
                                    }
                                </Flex>) 
                            }
                        </Flex>
                        : content
                    )
                }
            </Row>
        </div>
    </Flex>
}

export default FormCategory