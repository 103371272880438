import { ChartData } from 'chart.js';
import { Bar } from 'react-chartjs-2';

const ChartJsBar = ({ data }: { 
    data: ChartData<'bar'>,
}) => {

    const options = {
        // maintainAspectRatio: true,
        // plugins: {
        //     legend:{ display: legend ? true : false }
        // }
    }

    const styledDatasets = data.datasets.map(dataset => ({
        ...dataset,
        borderRadius: 15,
        borderColor: 'transparent',
        barPercentage: 0.8,
    }))

    const styledData = {...data, datasets: styledDatasets}

    return (
        <Bar data={styledData} options={options} />
    )
}

export default ChartJsBar