import { dummyTask1, dummyTask2, ClientTask } from "../../../utils/models/client.model"
import TaskItemRow from "./TaskItemRow"

const Tasks = () => {

    const tasks = [dummyTask1, dummyTask2]
    //todo get tasks from client state
    // const client = useAppSelector(state => state.client)
    // const notifications = client?.notifications

    const renderTaskRow = (task: ClientTask, index: number) => <TaskItemRow task={task} key={index} />

    return(
        <div className="content-container">
            <h3 style={{margin: '60px auto'}}>Tasks page</h3>

            {tasks.length 
            ?
                <table className="app-table ">
                    <thead className="theme1bg">
                        <tr>
                            <th>Description</th>
                            <th>View</th>
                        </tr>
                    </thead>
                    <tbody>
                        {tasks.map(renderTaskRow)} 
                    </tbody>
                </table>

            : <p className="grey">No ongoing tasks at the moment.</p>}
        </div>
    )
}

export default Tasks