import { ThreeDots } from 'react-loader-spinner'
import { BsThreeDots } from 'react-icons/bs'
import { Flex } from '../Containers'

const LoadingDots = ({ loading, onClick, size, color }) => {
    const s = size || 20;

    return <Flex>
        {
            loading
            ? <ThreeDots
                height={s} 
                width={s} 
                color={color || 'black'}
                radius="9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{maxWidth: s}}
                wrapperClassName="flex"
                visible={true}
            />
            : <BsThreeDots size={s} className={onClick ? "clickable" : ""} onClick={onClick} />
        }
    </Flex>
}

export default LoadingDots