
const PrivacyPolicyPage = () => {

    return (
        <div className="landing__container">
            <h2 className='margedTop10px'>PRIVACY POLICY</h2>
            <div className='leftalign  margedBot50px fw400'>
                <p className="margedBot20px">Effective date: June 01, 2022</p>
                <p className="margedBot10px">Primuse Pte. Ltd. (“<b>PRIMUSE</b>", "we", "us" or "our") is committed to respecting your privacy. The purpose of this Privacy Policy (“<b>Privacy Policy</b>”) is to explain how we will use any personal information about you that we collect, use, share, and store of our websites - <a className='default-link-style' href="https://primuse-web.vercel.app/" target="_blank" rel="noreferrer noopener">https://primuse-web.vercel.app/</a>, <a className='default-link-style' href="https://primuse.net/" target="_blank" rel="noreferrer noopener">https://primuse.net</a>, <a className='default-link-style' href=" https://primusegroup.com" target="_blank" rel="noreferrer noopener"> https://primusegroup.com</a>, <a className='default-link-style' href=" https://primuse.io" target="_blank" rel="noreferrer noopener"> https://primuse.io</a>, <a className='default-link-style' href=" https://primuse.live" target="_blank" rel="noreferrer noopener"> https://primuse.live</a>, <a className='default-link-style' href="https://primuse.world" target="_blank" rel="noreferrer noopener">https://primuse.world</a>, <a className='default-link-style' href="https://primuse.vip" target="_blank" rel="noreferrer noopener">https://primuse.vip</a> and <a className='default-link-style' href="https://primuse.org" target="_blank" rel="noreferrer noopener">https://primuse.org</a> ( the “<b>Sites</b>”), the disclosure of personal data when you use our services, the choices you have associated with that data and the rights you have in any personal data we hold about you. Also, to tell you about the policies and procedures that we have in place to respect your privacy and keep your personal data secure.</p>
                <p className="margedBot10px">This Policy applies to the Sites, applications, products and services (collectively, “Services”) on or in which it is posted, linked, or referenced.</p>
                <p className="margedBot10px">We use your data to provide and improve our services. By using our services, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, accessible from the Sites.</p>
                <p className="margedBot10px">This Privacy Policy applies to you if you:</p>
                <ul className="ul-disc">
                    <li>Visit an <b>PRIMUSE</b> facility, purchase a ticket for an event or at a venue owned, operated, promoted, or serviced by <b>PRIMUSE</b>, its affiliates, subsidiaries and parent company;</li>
                    <li>Use or access our products, services, or technology in venues and events we own or operate; and</li>
                    <li>Interact with us online, use our mobile applications, visit our websites, or interact with or reference our products/services online, including via social media (e.g. when we correspond with you via social media, when we access information provided by social media platforms in relation to our pages on those platforms, or if you reference or interact with our Events and Venues (as defined below) on social media) (collectively, our “Services”).</li>
                </ul>
            
                <h2 className='margedTop30px'>BILLING AND PAYMENTS</h2>
                <ul className="ul-disc">
                    <li><b>Service</b>: Service is all the online and offline applications operated by <b>PRIMUSE</b> (such as websites, mobile applications, venues, etc..) and all the services offered by <b>PRIMUSE</b> (more info at <a className='default-link-style' href="https://www.primuse.net/services/" target="_blank" rel="noreferrer noopener">https://www.primuse.net/services/</a></li>
                    <li><b>Personal Data</b>: Personal Data means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).</li>
                    <li><b>Usage Data</b>: Usage Data is data collected automatically either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).</li>
                    <li><b>Cookies</b>: Cookies are small files stored on your device (computer or mobile device).</li>
                </ul>

                <h2 className='margedTop30px'>INFORMATION COLLECTION AND USE</h2>
                <p className="margedBot10px">We collect several different types of information for various purposes to provide and improve our Services to you.</p>
                <p className="margedBot10px">Types of Data Collected:</p>

                <div className="margedLeft20px margedBot20px">
                    <p className="margedBot10px"><b>I.- Personal Data</b></p>
                    <div className="margedLeft20px">
                        <p className="margedBot10px">While using our Services, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (“Personal Data”). Personally identifiable information may include, but is not limited to:</p>
                        <ul className="ul-disc">
                            <li>Identity information, such as your first name, last name, username or similar identifier, title, date of birth and gender;</li>
                            <li>Contact information, such as your email address and telephone number;</li>
                            <li>Profile information, such as your username and password, interests, preferences, feedback and survey responses;</li>
                            <li>Feedback and correspondence, such as information you provide in your responses to surveys, when you participate in market research activities, report a problem with Service, receive customer support or otherwise correspond with us;</li>
                            <li>Financial information, such as your credit card or other payment card details;</li>
                            <li>Transaction information, such details about purchases you make through the Service and billing details;</li>
                            <li>Usage information, such as information about how you use the Service and interact with us;</li>
                            <li>Marketing information, such as your preferences for receiving marketing communications and details about how you engage with them;</li>
                            <li>Financial information, such as bank account number and bank routing number; financial assets holdings; and</li>
                            <li>Technical information, such as your Ethereum wallet address and network information regarding transactions. Please note that your Ethereum wallet address is not collected by https://primuse.io/ or associated plugins and mobile applications (“Primuse Club App”).</li>
                            <li>Cookies and Usage Data</li>
                        </ul>
                    </div>
                </div>

                <div className="margedLeft20px margedBot20px">
                    <p className="margedBot10px"><b>II.- Further Information We Collect In Relation to the Primuse Club and Primuse Club Services.</b></p>
                    <div className="margedLeft20px">
                        <p className="margedBot10px">Further information we collect from you in relation to https://primuse.vip/ (the “Primuse Club Site”) and Primuse Club Services may include:</p>
                        <ul className="ul-disc">
                            <li>Further identity information, such as your country of birth, nationality, national ID number, place of birth, employer and occupation;</li>
                            <li>Passport and/or photo ID for identity verification purposes;</li>
                            <li>Information required to comply with anti-money laundering (AML) laws and know-your-customer (KYC) requirements (such as nationality and place of birth); </li>
                            <li>Source of funds for participating in token launches; and</li>
                            <li>Information that you give us in relation to your purchased token holdings, such as earnings received from staking, and the number of tokens in your wallet. </li>
                        </ul>
                    </div>
                </div>

                <div className="margedLeft20px margedBot20px">
                    <p className="margedBot10px"><b>III.- Usage Data</b></p>
                    <div className="margedLeft20px">
                        <p className="margedBot10px">We may also collect information on how the Services is accessed and used (“Usage Data”). This Usage Data may include information such as your computer’s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
                        <p className="margedBot10px">We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send or by contacting us.</p>
                    </div>
                </div>

                <div className="margedLeft20px margedBot20px">
                    <p className="margedBot10px"><b>Iv.- Tracking & Cookies Data</b></p>
                    <div className="margedLeft20px">
                        <p className="margedBot10px">We use cookies and similar tracking technologies to track the activity on our Services and we hold certain information.</p>
                        <p className="margedBot10px">Cookies are files with a small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Other tracking technologies are also used such as beacons, tags and scripts to collect and track information and to improve and analyse our Services.</p>
                        <p className="margedBot10px">You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Services.</p>
                        <p className="margedBot10px">Examples of Cookies we use:</p>
                        <ul className="ul-disc margedBot10px">
                            <li>Session Cookies. We use Session Cookies to operate our Service.</li>
                            <li>Preference Cookies. We use Preference Cookies to remember your preferences and various settings.</li>
                            <li>Security Cookies. We use Security Cookies for security purposes.</li>
                        </ul>
                        <p>More info about our Cookies Policy: https://www.primuse.net/cookies-policy/</p>
                    </div>
                </div>


                <h2 className='margedTop30px'>USE OF DATA</h2>
                <p className="margedBot10px"><b>PRIMUSE</b> uses the collected data for various purposes:</p>
                <ul className="ul-disc">
                    <li>To provide and maintain our Services</li>
                    <li>To notify you about changes to our Services</li>
                    <li>To allow you to participate in interactive features of our Services when you choose to do so</li>
                    <li>To provide customer support</li>
                    <li>To gather analysis or valuable information so that we can improve our Service</li>
                    <li>To monitor the usage of our Services</li>
                    <li>To detect, prevent and address technical issues</li>
                    <li>To provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information</li>
                    <li>To comply with law, as we believe necessary or appropriate to comply with applicable laws (including anti-money laundering (AML) laws and know-your-customer (KYC) requirements), lawful requests and legal process, such as to respond to subpoenas or requests from government authorities.</li>
                    <li>For compliance, fraud prevention, and safety; to protect, investigate, and deter against fraudulent, unauthorized, or illegal activity.</li>
                </ul>

                <h2 className='margedTop30px'>TRANSFER OF DATA</h2>
                <p className="margedBot10px">The Company has offices outside of the EU and has affiliates and service providers in Singapore and in other countries. Your personal information, including Personal Data, may be transferred — and maintained on — computers to or from Singapore or other locations outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ from those of your jurisdiction. Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>
                <p className="margedBot10px">EU users should read the important information provided  below about transfer of personal information outside of the European Economic Area (EEA).</p>
                <p><b>PRIMUSE</b> will take all the steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organisation or a country unless there are adequate controls in place including the security of your data and other personal information.</p>

                <h2 className='margedTop30px'>DISCLOSURE OF DATA</h2>
                <h3 className='margedTop30px'>Disclosure for Law Enforcement</h3>
                <p className="margedBot10px">Under certain circumstances, PRIMUSE may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).</p>
                <h3 className='margedTop30px'>Legal Requirements</h3>
                <p className="margedBot10px">PRIMUSE may disclose your Personal Data in the good faith belief that such action is necessary to:</p>
                <ul className="ul-disc">
                    <li>To comply with a legal obligation</li>
                    <li>To protect and defend the rights or property of PRIMUSE</li>
                    <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
                    <li>To protect the personal safety of users of the Service or the public</li>
                    <li>To protect against legal liability</li>
                </ul>

                <h2 className='margedTop30px'>SECURITY OF DATA</h2>
                <p>The security of your data is important to us but remember that no method of transmission over the Internet or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>

                <h2 className='margedTop30px'>SERVICE PROVIDERS</h2>
                <p className="margedBot10px">We may employ third party companies and individuals to facilitate our Services (“Service Providers”), provide the Service on our behalf, perform Service-related services or assist us in analysing how our Service is used.</p>
                <p className="margedBot10px">These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>
                <h3>Payments</h3>
                <p className="margedBot10px">We may provide paid products and/or services within the Services. In that case, we use third-party services for payment processing (e.g. payment processors).</p>
                <p>We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, MasterCard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.</p>
            
                <h2 className='margedTop30px'>LINKS TO OTHER SITES</h2>
                <p className="margedBot10px">Our Services may contain links to other sites that are not operated by us. If you click a third party link, you will be directed to that third party’s site. We strongly advise you to review the Privacy Policy of every site you visit</p>
                <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>

                <h2 className='margedTop30px'>CHILDREN’S PRIVACY</h2>
                <p className="margedBot10px">Our Service does not address anyone under the age of 18 (“Children”)</p>
                <p>We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your Child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.</p>

                <h2 className='margedTop30px'>CHANGES TO THIS PRIVACY POLICY</h2>
                <p className="margedBot10px">We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>
                <p className="margedBot10px">We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the “effective date” at the top of this Privacy Policy.</p>
                <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
            
                <h2 className='margedTop30px'>NOTICE TO EU DATA SUBJECTS</h2>
                <h3>Personal Information</h3>
                <div className="margedLeft20px">
                    <p className="margedBot10px">With respect to EU data subjects, “personal information,” as used in this Privacy Policy, is equivalent to “personal data” as defined in the <a className='default-link-style fw400' href="https://gdpr-info.eu/art-4-gdpr/" target="_blank" rel="noreferrer noopener">European Union General Data Protection Regulation</a> (GDPR). </p>
                </div>
                <h3>Legal Bases for Processing</h3>
                <div className="margedLeft20px">
                    <p className="margedBot10px">We only use your personal information as permitted by law. We are required to inform you of the legal bases of our processing of your personal information, which are described in the table below. If you have questions about the legal bases under which we process your personal information, contact us at <a className='default-link-style fw400' href="mailto:privacy@primuse.net" target="_blank" rel="noreferrer noopener">privacy@primuse.net</a>.</p>
                </div>
                <h3>Processing Purpose & Legal Basis: </h3>
                <div className="margedLeft20px margedTop10px">
                    <p className="underlined">a) To provide our service:</p>
                    <p className="margedBot10px">Our processing of your personal information is necessary to perform the contract governing our provision of the Services or to take steps that you request prior to signing up for the Service.</p>
                    <p className="underlined">b) To communicate with you; To optimize our platform; For compliance, fraud prevention, and safety; To provide our service:</p>
                    <p className="margedBot10px">These processing activities constitute our legitimate interests. We make sure we consider and balance any potential impacts on you (both positive and negative) and your rights before we process your personal information for our legitimate interests. We do not use your personal information for activities where our interests are overridden by any adverse impact on you (unless we have your consent or are otherwise required or permitted to by law).</p>
                    <p className="underlined">c) To comply with law:</p>
                    <p className="margedBot10px">We use your personal information to comply with applicable laws and our legal obligations, including anti-money laundering (AML) laws and know-your-customer (KYC) requirements.</p>
                    <p className="underlined">d) With your consent:</p>
                    <p className="margedBot10px">Where our use of your personal information is based upon your consent, you have the right to withdraw it anytime in the manner indicated in the Service or by contacting us at <a className='default-link-style fw400' href="mailto:privacy@primuse.net" target="_blank" rel="noreferrer noopener">privacy@primuse.net</a>.</p>
                </div>
                <h3>​​​​​​​Sensitive Data</h3>
                <div className="margedLeft20px">
                    <p className="margedBot10px">Some of the information you provide us may constitute sensitive data as defined in the GDPR (also referred to as special categories of personal data), including identification of your race or ethnicity on government-issued identification documents.</p>
                </div>
                <h3>Use for New Purposes</h3>
                <div className="margedLeft20px">
                    <p className="margedBot10px">We may use your personal information for reasons not described in this Privacy Policy, where we are permitted by law to do so and where the reason is compatible with the purpose for which we collected it. If we need to use your personal information for an unrelated purpose, we will notify you and explain the applicable legal basis for that use. If we have relied upon your consent for a particular use of your personal information, we will seek your consent for any unrelated purpose.</p>
                </div>
                <h3>Your Rights</h3>
                <div className="margedLeft20px">
                    <p className="margedBot10px">Under the GDPR, you have certain rights regarding your personal information. You may ask us to take the following actions in relation to your personal information that we hold:</p>
                    <ul className="ul-disc margedBot10px">
                        <li>Stop sending you direct marketing communications which you have previously consented to receive. We may continue to send you Service-related and other non-marketing communications.</li>
                        <li>Provide you with information about our processing of your personal information and give you access to your personal information.</li>
                        <li>Update or correct inaccuracies in your personal information.</li>
                        <li>Delete your personal information.</li>
                        <li>Transfer a machine-readable copy of your personal information to you or a third party of your choice.</li>
                        <li>Restrict the processing of your personal information.</li>
                        <li>Object to our reliance on our legitimate interests as the basis of our processing of your personal information that impacts your rights.</li>
                    </ul>
                    <p className="margedBot10px">You can submit these requests by email to <a className='default-link-style fw400' href="mailto:privacy@primuse.net" target="_blank" rel="noreferrer noopener">privacy@primuse.net</a>. We may request specific information from you to help us confirm your identity and process your request. Applicable law may require or permit us to decline your request. If we decline your request, we will tell you why, subject to legal restrictions. If you would like to submit a complaint about our use of your personal information or response to your requests regarding your personal information, you may contact us at <a className='default-link-style fw400' href="mailto:privacy@primuse.net" target="_blank" rel="noreferrer noopener">privacy@primuse.net</a> or submit a complaint to the data protection regulator in your jurisdiction. You can find your data protection regulator <a className='default-link-style fw400' href="https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm" target="_blank" rel="noreferrer noopener">here</a>.</p>
                </div>
                <h3>Cross-Border Data Transfer</h3>
                <div className="margedLeft20px">
                    <p className="margedBot10px">Please be aware that your personal data will be transferred to, processed, and stored in Singapore. Data protection laws in Singapore may be different from those in your country of residence. You consent to the transfer of your information, including personal information, to Singapore as set forth in this Privacy Policy by visiting our Sites or using our service.</p>
                    <p className="margedBot10px">Whenever we transfer your personal information out of the EEA to Singapore or countries not deemed by the European Commission to provide an adequate level of personal information protection, the transfer will be based on a data transfer mechanism recognized by the European Commission as providing adequate protection for personal information.</p>
                    <p>Please contact us if you want further information on the specific mechanism used by us when transferring your personal information out of the EEA.</p>
                </div>
                
                <h2 className='margedTop30px'>CONTACT US</h2>
                <p className="margedBot10px">If you have any questions about this Privacy Policy, please contact us:</p>
                <p>Primuse Pte. Ltd.</p>
                <p>E-Mail:<a className='default-link-style fw400' href="mailto:info@primuse.net" target="_blank" rel="noreferrer noopener">info@primuse.net</a></p>
                <p>This document was last updated on June 01, 2022</p>
            </div>
            
        </div>
    )
}

export default PrivacyPolicyPage