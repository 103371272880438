import Select from "react-select"
import { Flex } from "../../Containers"

const SelectInput = (
    { 
        options,
        title, 
        onChange, 
        value, 
        showRawValue, 
        disabled, 
        noBold, 
        multi,
        searchable,
        noTitle,
        filterOption,
        nonClearable,
    } : { 
        options: any[], 
        title: string,
        onChange: (param?: any, params?: any) => void, 
        value: any, 
        isCheckbox?: boolean, 
        showRawValue?: boolean, 
        disabled?: boolean, 
        noBold?: boolean, 
        multi?: boolean ,
        searchable?: boolean,
        noTitle?: boolean,
        filterOption?: any,
        nonClearable?: boolean,
    }) => {

    return (
        <Flex className="allwidth">
            {!noTitle && <label className={`selfstart ${noBold ? '' : 'bold'} margedBot8px label-color`}>{ title }</label>}
            <Select
                isClearable={!nonClearable}
                placeholder={title}
                className="allwidth selfstart"
                options={options}
                onChange={onChange}
                filterOption={filterOption ?? undefined}
                value={showRawValue ? value : options.find((o:any) => o.value === value)}
                isDisabled={disabled}
                isMulti={multi}
                isSearchable={searchable}
                styles={{
                    control: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: 'var(--card-background)',
                        border: state.isFocused ? '1px solid var(--main-color)' : 'thin solid rgba(var(--border-color-rgb), 0.12)',
                        // This line disable the blue border
                        boxShadow: "0",
                        '&:hover': {
                            borderColor: 'var(--main-color)'
                        }
                    }),
                    option: (baseStyles, state) => ({
                        ...baseStyles,
                        color: 'var(--text-color)',
                        backgroundColor: state.isSelected ? "#36395D" : state.isFocused ? '#373C57' : 'var(--card-background)'
                    }),
                    menu: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: 'var(--card-background)',
                    }),
                    input: (baseStyles, state) => ({
                        ...baseStyles,
                        color: 'var(--text-color)',
                    }),
                    singleValue: (baseStyles, state) => ({
                        ...baseStyles,
                        color: 'var(--text-color)',
                    }),
                    multiValue: (baseStyles, state) => ({
                        ...baseStyles,
                        backgroundColor: '#36395D',
                    }),
                    multiValueLabel: (baseStyles, state) => ({
                        ...baseStyles,
                        color: 'var(--text-color)',
                    }),
                }}
            />
        </Flex>
    )
}
// 
// 
export default SelectInput