// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttons-filter__div {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    margin-bottom: 50px;
    flex-wrap: wrap;
    row-gap: 30px;
    column-gap: 20px;
}`, "",{"version":3,"sources":["webpack://./src/user-panels/promotor/touring/buttons-filter/button-filter.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,gBAAgB;IAChB,mBAAmB;IACnB,eAAe;IACf,aAAa;IACb,gBAAgB;AACpB","sourcesContent":[".buttons-filter__div {\r\n    display: flex;\r\n    justify-content: center;\r\n    margin-top: 50px;\r\n    margin-bottom: 50px;\r\n    flex-wrap: wrap;\r\n    row-gap: 30px;\r\n    column-gap: 20px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
