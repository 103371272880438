import { useState } from "react"
import { useDispatch } from "react-redux"
import FileInput from "../../../../components/form/form-components/FileInput"
import FormSection from "../../../../components/form/form-components/FormSection"
import LabelInput from "../../../../components/form/mui/label-input/LabelInput"
import { snackActions } from "../../../../store/snackSlice"
import useHandleHostImage from "../../../../utils/hooks/useHandleHostImage"
import { Manager } from "../../../../utils/models/manager.model"

const ManagerForm = ( { manager, closeForm }: {manager?: Manager, closeForm?: any }) => {

    const [formInputs, setFormInputs] = useState({
        name: '',
        email: '',
        logo: ''
    })
    const [selectedLogoFile, setSelectedLogoFile] = useState('')
    const [isPosting, setIsPosting] = useState(false)

    const updateInput = (value: any, inputName: keyof typeof formInputs) => {
        setFormInputs(prevInputs => ({...prevInputs, [inputName]: value}))
    }

    const dispatch = useDispatch()
    const setSnack = (snack:any) => dispatch(snackActions.set(snack))

    const handleHostImage = useHandleHostImage(formInputs)

    const handleSubmit = () => {

    }

    const handleUpdate = () => {

    }

    return (
        <div className={`flex justifystart alignstart smaller allwidth margedTop50px ${manager ? "modal__manager-form" : ''}`}>
            <div className="title selfcentered xxlarge spaced-text">{!manager && 'NEW '}PRIMUSE MANAGER</div>
            <div className="allwidth margedBot100px">

                <FormSection title="Infos">
                    <LabelInput 
                        title='Name (required)'
                        value={formInputs.name}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {updateInput(e.target.value, 'name')}}
                    />
                    <LabelInput 
                        title='Email'
                        value={formInputs.email}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {updateInput(e.target.value, 'email')}}
                    />
                    <FileInput
                        title='Logo'
                        value={formInputs.logo}
                        onChange={({acceptedFile, imageDatas}:any) => {
                            setSelectedLogoFile(acceptedFile)
                            updateInput(imageDatas.source, 'logo');
                        }}
                    />
                </FormSection>

                {
                    manager 
                    ?   <>
                            <button className="button button--round button--uppercase button--primary" disabled={isPosting} onClick={handleUpdate}>{isPosting ? 'Updating...' : 'Update Manager'}</button>
                            <button className="button button--round button--uppercase button--ghost" onClick={closeForm}>Go back</button>
                        </>
                    :   <button className="button button--round button--uppercase button--primary" disabled={isPosting} onClick={handleSubmit}>{isPosting ? 'Adding...' : 'Add new Manager'}</button>
                }
            </div>
        </div>
    )
}

export default ManagerForm