import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LOCATION_EVERYWHERE } from '../utils/touring.util';

interface TouringLocationDropdown {
    isOpen: boolean,
    locationFilter: string[]
}
const initialState: TouringLocationDropdown = {
    isOpen: false,
    locationFilter: [LOCATION_EVERYWHERE]
};

export const touringLocationDropdownSlice = createSlice({
  name: 'touringLocationDropdown',
  initialState,
  reducers: {
    toggleDropdown: (state) => {
        state.isOpen = !state.isOpen
    },
    closeDropdown: (state) => {
        state.isOpen = false
    },
    reset: (state) => {
        return initialState
    },
    toggleLocation: (state, action: PayloadAction<string>) => {
        const locationIndex = state.locationFilter.findIndex(location => location === action.payload)
        if(locationIndex === -1){
            const isAddingLocationEverywhere = action.payload === LOCATION_EVERYWHERE
            // If we add the 'Everywhere' filter we remove every other filter
            if(isAddingLocationEverywhere){
                state.locationFilter = [action.payload]
            } else {
                state.locationFilter.push(action.payload)
                
                // If we add a filter we remove 'Everywhere' from the selected filters
                if(state.locationFilter.includes(LOCATION_EVERYWHERE)){
                    const locationEverywhereIndex = state.locationFilter.findIndex(location => location === LOCATION_EVERYWHERE)
                    state.locationFilter.splice(locationEverywhereIndex, 1)
                }
            }

            
        } else {
            state.locationFilter.splice(locationIndex, 1)
        }
    }
  },
});

export const touringLocationDropdownActions = touringLocationDropdownSlice.actions;

export default touringLocationDropdownSlice.reducer;