import PageLoading from "../../../components/loading/PageLoading"
import useFetchPromotorDrafts from "../../../utils/hooks/offer/useFetchPromotorDrafts"
import PnlTable from "./PnlTable"

const DraftsPnls = () => {

    const {isFetchingDrafts, drafts } = useFetchPromotorDrafts()

    if(isFetchingDrafts) {
        return (<PageLoading />)
    }

    return (
        <div className='main-container margedBot100px paddedTop40px'>

            <h3 className="page-title">Drafts's PNLs</h3>

            {drafts?.length
            ?   <PnlTable offers={drafts} />
            :   <p className="grey">No Drafts for now.</p>}

        </div>
    )
}

export default DraftsPnls