import AdminUsers from "../user-panels/admin/admin-panel-screens/users/AdminUsers"
import { useAppSelector } from "../utils/hooks/reduxTypedHooks"
import { Licence } from "../utils/models/client.model"
import AccessDeniedPage from "./AccessDeniedPage"

const Users = () => {
    const licence = useAppSelector(state => state.client?.licence)

    if(licence === Licence.Admin){
        return (<AdminUsers />)
    } else {
        return (<AccessDeniedPage />)
    }
}

export default Users