import { MouseEventHandler } from "react"

export const Row = ({ children, className, style } : {children: React.ReactNode, className?: string, style?:{}}) => <div className={`flex row allwidth ${className||''}`} style={style}>
    { children }
</div> 

export const Col = ({ children, className } : {children: React.ReactNode, className?: string}) => <div className={`flex column ${className||''}`}>
    { children }
</div> 

export const Flex = ({ children, flex, alignstart, className, style, row, onClick } : {children: React.ReactNode, flex?: number, alignstart?: boolean, className?: string, style?: any, row?: boolean, onClick?: MouseEventHandler<HTMLDivElement>} ) => <div onClick={onClick} style={style||{}} className={`flex${flex ? ` flexdot${flex}` : ''}${alignstart ? ' alignstart' : ''}${row ? ' row' : ''} ${className || ''}`}>
    { children }
</div> 

