import "../../../styles/artists/artists.css"

import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { ROUTE_ARTISTS, ROUTE_ARTISTS_FEED } from "../../../routes/routes.const";


/**
 * @dev AdminArtists screen
 */
const ArtistsOutlet = () => {

    const location = useLocation()
    const navigate = useNavigate()

    // redirecting to /artists/feed by default if we arrive at /artists
    useEffect(() => {
        if(location.pathname === ROUTE_ARTISTS){
            navigate(ROUTE_ARTISTS_FEED)
        }
    }, [location, navigate])

        return (
            <>
                <Outlet />
            </>
        )
}

export default ArtistsOutlet;