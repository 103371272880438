import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Touring } from '../utils/models/touring.model';

const initialState: Touring[] = [];

export const touringsSlice = createSlice({
  name: 'tourings',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<Touring[]>) => {
      return action.payload
    },
    add: (state, action: PayloadAction<Touring>) => {
      state.push(action.payload)
    },
    merge: (state, action: PayloadAction<Touring>) => {
      const {id, ...mergeProps} = action.payload
      const touringIndex = state.findIndex(touring => touring.id === id)
      if(touringIndex !== -1){
        state[touringIndex] = {...state[touringIndex], ...mergeProps}
      }
    },
    delete: (state, action: PayloadAction<{id: string}>) => {
      const searchId = action.payload.id
      const foundIndex = state.findIndex(touring => touring.id === searchId)
      if(foundIndex !== -1){
        state.splice(foundIndex, 1)
      }
    },
  },
});

export const touringsActions = touringsSlice.actions;

export default touringsSlice.reducer;