import { AiOutlineCloseCircle } from "react-icons/ai"
import { Flex, Row } from "../../../../../components/Containers"
import LabelInput from "../../../../../components/form/mui/label-input/LabelInput"
import useSetSnack from "../../../../../utils/hooks/useSetSnack"
import { OfferShowFormInputs, ShowTicket } from "../../../../../utils/models/offer.model"
import { computeTicketsDatas } from "../../../../../utils/offers.util"
import { ls, lsToFlat, plural, pnlAmountFormatter } from "../../../../../utils/utils"

const TicketsStep = ({ os, osi, currencySymbol, isReviewing, isLocalReviewing, setOfferShow, setShowTickets, removeShowTickets, addShowTickets, goToPreviousStep, goToNextStep, goBackToReview, goBackToLocalReview }:
    { 
        os: OfferShowFormInputs, 
        osi: number, 
        currencySymbol: null|string,
        isReviewing: boolean,
        isLocalReviewing: boolean,
        setOfferShow: (showIndex: number, showProps: {}) => void,
        setShowTickets: (index: number, ticketsIndex: number, _props: any) => void,
        removeShowTickets: (showIndex: number, ticketsIndex: number) => void,
        addShowTickets: (showIndex: number) => void,
        goToPreviousStep: () => void,
        goToNextStep: () => void,
        goBackToReview: () => void,
        goBackToLocalReview: () => void,
    }) => {

    const setSnack = useSetSnack()

    const numberOfTickets = os.tickets.length

    const { ticketsTotalGrossIncome, ticketsTotalNetIncome, compsTicketTotalAmount, vatAmount, otherTaxesAmount, totalTicketsQuantity } = computeTicketsDatas(os.tickets, os.ticketsVariables)
   
    const missingTickets = os.venue.totalCapacity - totalTicketsQuantity


    const checkIfTicketsValid = () => {
        if(numberOfTickets === 0) {
            setSnack({type: 'warning', content: 'Please add at least one ticket'})
            return false
        }
        for(let j= 0 ; j < numberOfTickets ; j++) {
            if(!os.tickets[j]?.name || !os.tickets[j].quantity || !os.tickets[j].grossPrice) {
                setSnack({type: 'warning', content: 'Please fill every field for each ticket'})
                return false
            }
        }

        if(totalTicketsQuantity > os.venue.totalCapacity) {
            setSnack({type: 'warning', content: 'The total tickets quantity is exceeding the venue maximum capacity'})
                return false
        }

        return true
    }

    const handlePrevious = () => {
        goToPreviousStep()
    }

    const handleNext = () => {
        const areTicketsValid = checkIfTicketsValid()
        if(!areTicketsValid) {
            return
        }
        goToNextStep()
    }

    const handleGoBackToReview = (isLocalReview: boolean) => {
        const areTicketsValid = checkIfTicketsValid()
        if(!areTicketsValid) {
            return
        }
        if(isLocalReview) {
            goBackToLocalReview()
        } else {
            goBackToReview()
        }
    }

    const handleChangeCompsTickets = (e: any) => {
        const newTicketsVariables = {...os.ticketsVariables}
        newTicketsVariables.compsTickets = +e.target.value

        // updating the comps % on each already existing ticket
        const newTickets = os.tickets.map((ticket: ShowTicket) => ({...ticket, compsTicketsPercent: +e.target.value}))

        setOfferShow(
            osi, 
            { 
                ticketsVariables: newTicketsVariables,
                tickets: newTickets
            }
        )
    }

    return (
        <Flex className="alignstart allwidth">

            <div className="title main-color margedBot10px">Tickets Taxes</div>

            <div className="grid-5-col margedBot20px">
                <LabelInput
                    title='VAT (%)'
                    type="number"
                    value={os.ticketsVariables.taxes.vatPercent}
                    onChange={(e:any) => {
                        const newTaxes = {...os.ticketsVariables.taxes}
                        newTaxes.vatPercent = +e.target.value
                        const newTicketsVariables = {...os.ticketsVariables, taxes: newTaxes}
                        setOfferShow(
                        osi, 
                        { ticketsVariables: newTicketsVariables }
                        )}
                    }
                />
                <LabelInput
                    title='Ticket Fee (%)'
                    type="number"
                    value={os.ticketsVariables.taxes.ticketFeePercent}
                    onChange={(e:any) => {
                        const newTaxes = {...os.ticketsVariables.taxes}
                        newTaxes.ticketFeePercent = +e.target.value
                        const newTicketsVariables = {...os.ticketsVariables, taxes: newTaxes}
                        setOfferShow(
                        osi, 
                        { ticketsVariables: newTicketsVariables }
                        )}
                    }
                />
                <LabelInput
                    title='Credit Card Fee (%)'
                    type="number"
                    value={os.ticketsVariables.taxes.creditCardFeePercent}
                    onChange={(e:any) => {
                        const newTaxes = {...os.ticketsVariables.taxes}
                        newTaxes.creditCardFeePercent = +e.target.value
                        const newTicketsVariables = {...os.ticketsVariables, taxes: newTaxes}
                        setOfferShow(
                        osi, 
                        { ticketsVariables: newTicketsVariables }
                        )}
                    }
                />
                <LabelInput
                    title='Other taxes (%)'
                    type="number"
                    value={os.ticketsVariables.taxes.otherTaxesPercent}
                    onChange={(e:any) => {
                        const newTaxes = {...os.ticketsVariables.taxes}
                        newTaxes.otherTaxesPercent = +e.target.value
                        const newTicketsVariables = {...os.ticketsVariables, taxes: newTaxes}
                        setOfferShow(
                        osi, 
                        { ticketsVariables: newTicketsVariables }
                        )}
                    }
                />
                <LabelInput
                    title='Comps Tickets (%)'
                    type="number"
                    value={os.ticketsVariables.compsTickets}
                    onChange={handleChangeCompsTickets}
                />
            </div>

            <div className="title main-color">Tickets</div>

            <table className="allwidth margedBot20px">
                <thead>
                    <tr>
                        <th>Name*</th>
                        <th>Quantity</th>
                        <th>Gross Price ({currencySymbol ?? os.localCurrency})</th>
                        <th>Comps Tickets %</th>
                        <th>Remove</th>
                    </tr>
                </thead>
                <tbody>
                    {/* Show tickets */}
                    {
                        os.tickets?.length
                        ? os.tickets.map((ticket:any, sti:number) => <tr key={`show${osi}tkts${sti}`}>
                            <td>
                                <div  className="margedRight20px">
                                    <LabelInput
                                        title='Name'
                                        value={ticket.name}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            const name = e.target.value;
                                            const sameName = os.tickets.find((_t:any)=>_t.name===name);
                                            if (sameName)
                                                setSnack({type: 'error', content: <span>A ticket type named <b>{name}</b> already exists in show n°{osi+1}</span>})    
                                            else 
                                                setShowTickets(osi, sti, { name });
                                        }}
                                    />
                                </div>
                            </td>
                            <td>
                                <div  className="margedRight20px">
                                    <LabelInput
                                        title='Quantity'
                                        type="number"
                                        value={ticket.quantity}
                                        onChange={(e:  React.ChangeEvent<HTMLInputElement>) => setShowTickets(osi, sti, { quantity: +e.target.value })}
                                    />
                                </div>
                            </td>
                            <td>
                                <div  className="margedRight20px">
                                    <LabelInput
                                        title={`Gross Price (${currencySymbol ?? os.localCurrency})`}
                                        value={ls(ticket.grossPrice)}
                                        onChange={(e:  React.ChangeEvent<HTMLInputElement>) => {
                                            const flattenedNumber = lsToFlat(e.target.value)
                                            if(!isNaN(flattenedNumber)) {
                                                setShowTickets(osi, sti, { grossPrice: flattenedNumber })
                                            }
                                        }}
                                    />
                                </div>
                            </td>
                            <td>
                                <div  className="margedRight20px">
                                    <LabelInput
                                        title='Compts Tickets %'
                                        type="number"
                                        min={0}
                                        max={100}
                                        value={ticket.compsTicketsPercent}
                                        onChange={(e:  React.ChangeEvent<HTMLInputElement>) => setShowTickets(osi, sti, { compsTicketsPercent: +e.target.value })}
                                    />
                                </div>
                            </td>
                            <td>
                                <div className="paddedTop10px">
                                    <AiOutlineCloseCircle
                                        size={25}
                                        onClick={() => removeShowTickets(osi, sti)}
                                        fill={'tomato'}
                                        className='clickable'
                                    />
                                </div>
                            </td>
                        </tr>)
                        : null
                    }
                        <tr>
                            <td></td>
                            <td>
                                <Flex className="paddedTop10px">
                                    <button className='xsmall button button--primary button--round flex' onClick={() => addShowTickets(osi)}><Row>
                                        <span className="xlarge" style={{lineHeight: '20px'}}>+</span> &nbsp; ADD TICKETS</Row>
                                    </button>
                                </Flex>
                            </td>
                            <td></td>
                        </tr>
                        <tr>
                            <td className="paddedTop20px">Tickets categories : {numberOfTickets}</td>
                            <td className="paddedTop20px">
                                <p>Total quantity : {ls(totalTicketsQuantity)} (Max capacity {ls(os.venue.totalCapacity)})</p>
                                <p>{(missingTickets > 0)
                                    ?   <span className="error">{ls(missingTickets)} {plural("seat", missingTickets)} without ticket</span>
                                    :   <span className="green-color">All seats filled</span>}</p>
                            </td>
                            <td className="paddedTop20px">Total ticket income : {currencySymbol ?? os.localCurrency} {pnlAmountFormatter(ticketsTotalGrossIncome)} </td>
                        </tr>
                    </tbody>
                </table>

                <Row className="allwidth justify-end textAlignEnd">
                    <div className="margedRight75px">
                        <p>Total Gross Income</p>
                        <p>Comps Tickets</p>
                        <p>VAT</p>
                        <p>Other Taxes</p>
                        <p>Total NET</p>
                    </div>
                    <div>
                        <p>{currencySymbol ?? os.localCurrency} {pnlAmountFormatter(ticketsTotalGrossIncome)} </p>
                        <p>{currencySymbol ?? os.localCurrency} {pnlAmountFormatter(compsTicketTotalAmount)} </p>
                        <p>{currencySymbol ?? os.localCurrency} {pnlAmountFormatter(vatAmount)} </p>
                        <p>{currencySymbol ?? os.localCurrency} {pnlAmountFormatter(otherTaxesAmount)}</p>
                        <p>{currencySymbol ?? os.localCurrency} {pnlAmountFormatter(ticketsTotalNetIncome)}</p>
                    </div>
                </Row>

                <Flex row className="allwidth">
                    {isReviewing
                    ?   <button className="button button--primary button--round margedRight100px" onClick={() => handleGoBackToReview(false)}>Apply</button>
                    :   isLocalReviewing
                        ?   <button className="button button--primary button--round margedRight100px" onClick={() => handleGoBackToReview(true)}>Apply</button>
                        :   <>
                                <button className="button button--primary button--round margedRight100px" onClick={handlePrevious}>Previous</button>
                                <button className="button button--primary button--round" onClick={handleNext}>Next</button>
                            </>}
                </Flex>
        </Flex>
    )
}

export default TicketsStep