import { ChartData } from "chart.js"
import ChartJsDoughnut from "../../../../../../components/Charts/ChartsJsDoughnut"
import { colors } from "../../../../../../constants"

const FixedCostPieChart = ({ currency, currencySymbol, totalArtistCost, fixedCosts }: {
    currency: string,
    currencySymbol: null|string,
    totalArtistCost: number,
    fixedCosts: { name: string; has: boolean; amount: number; }[]
}) => {

    const artistCostLabel= totalArtistCost ? ["Artist Cost"] : []
    const artistCostAmount = totalArtistCost ? [totalArtistCost] : []

    const existingFixedCosts = fixedCosts.filter(fixedCost => fixedCost.has && (fixedCost.amount))

    const fixedCostsLabels = existingFixedCosts.map(fixedCost => fixedCost.name)
    const fixedCostsAmounts = existingFixedCosts.map(fixedCost => fixedCost.amount)

    const pieData: ChartData<"doughnut"> = {
        labels: [
            ...artistCostLabel,
            ...fixedCostsLabels,
        ],
        datasets: [{
            data: [
                ...artistCostAmount,
                ...fixedCostsAmounts
            ],
            backgroundColor: [
            colors.red,
            colors.orange,
            colors.lightblue,
            colors.green,
            colors.main,
            colors.gray,
            ],
        }],
    }
    
    return (
        <div className="app-card" style={{width: '450px'}}>
            <p className="margedBot10px">Fix Costs ({currencySymbol ?? currency})</p>
            <div>
                <ChartJsDoughnut 
                    data={pieData}
                    legend
                    cutout={0}
                />
            </div>
        </div>
    )
}

export default FixedCostPieChart