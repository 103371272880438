
const Story = () => {

    return (
        <div className="landing-story" id="story">
            <div className="landing-container">
                <h2 className="landing-title margedBot20px">Our Story</h2>
                <h5 style={{fontSize: "22px"}} className="margedBot10px">Led by the belief that anyone can grow</h5>
                <p className="landing-paragraph">
                    After years of testing and experimenting with various software, our team decided to create Kisum - the ultimate solution for the music industry. Kisum is a professional-grade tool designed for music promoters that creates a global ecosystem for music, connecting artists, promoters, agents, and liaison agents. <br />
                    Our application goes beyond traditional analytical tools by collecting data from multiple platforms and providing insightful results in a timely manner, allowing for informed decision making. Additionally, we utilize the power of blockchain technology to securely and transparently track and submit offers, ensuring transparency and trust in the recording process. <br />
                    Kisum is the ultimate tool for the music industry, providing a comprehensive and efficient solution for all your needs."
                </p>
            </div>
        </div>
    )
}

export default Story