import FullCalendar, { EventClickArg } from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction';
import { TouringWithArtist } from '../../../utils/models/touring.model';
import Dialog from '../../../components/dialog/Dialog';
import { useState } from 'react';
import CalendarEventModal from './CalendarEventModal';
import useIsAdmin from '../../../utils/hooks/useIsAdmin';
import TouringForm from '../../admin/admin-panel-screens/touring/TouringForm';
import moment from 'moment';
import { colors } from '../../../constants';

const CalendarScreen = ({tourings}: {tourings: TouringWithArtist[]}) => {
    const [tourInfosModal, setTourInfosModal] = useState(false)
    const [addTouringModal, setAddTouringModal] = useState(false)
    const [activeEvent, setActiveEvent] = useState<TouringWithArtist | undefined>(undefined)
    const [newTouringDates, setNewTouringDates] = useState<{from: Date, to: Date} | undefined>(undefined)

    const closeInfosModal = () => setTourInfosModal(false)
    const closeTouringModal = () => setAddTouringModal(false)

    const isAdmin = useIsAdmin()

    const eventColors = [colors.main, colors.green, colors.lightblue, colors.orange, colors.red]
    const eventBgColors = [colors.bgMain, colors.bgGreen, colors.bgLightblue, colors.bgOrange, colors.bgRed]

    let firstEvent: null | number = null
    let lastEvent: null | number = null

    const formatedTours = tourings.map((touring) => {
        const title = touring.artist.displayableName

        const fromTime = new Date(touring.from).getTime()
        const toTime = new Date(touring.to).getTime()
        // find first and last tours dates for navigation limitations
        if(!firstEvent || fromTime < firstEvent){ firstEvent = fromTime }
        if(!lastEvent || toTime > lastEvent){ lastEvent = toTime}
        
        const start = moment(new Date(touring.from)).format('YYYYMMDD')
        const endDate = new Date(touring.to)
        // add +1 to the end date because the calendar end date is EXCLUSIVE
        endDate.setDate(endDate.getDate() + 1)
        const end = moment(endDate).format('YYYYMMDD')
        // randomly picking a color from the available colors based on the name (first caracter and length)
        const randomColorIndex = (title.charCodeAt(0) + title.length) % eventColors.length
        const color = eventBgColors[randomColorIndex]
        const textColor = eventColors[randomColorIndex]
        const extendedProps = {touring}
        return {title, start, end, color, extendedProps, textColor}
    })

    const handleEventClick = (info: EventClickArg) => {
        const touring: TouringWithArtist | undefined = info.event.extendedProps?.touring
        setActiveEvent(touring)
        setTourInfosModal(true)
    }

    const firstEventFirstDayOfMonth = firstEvent ? new Date(firstEvent) : new Date()
    firstEventFirstDayOfMonth.setDate(1)
    const lastEventLastDayOfMonth = lastEvent ? new Date(lastEvent) : new Date()
    lastEventLastDayOfMonth.setDate(31)
    // adding one because end limit is exclusive
    lastEventLastDayOfMonth.setDate(lastEventLastDayOfMonth.getDate() + 1)
    const calendarLimits = {start: moment(firstEventFirstDayOfMonth).format('YYYYMMDD'), end: moment(lastEventLastDayOfMonth).format('YYYYMMDD')}

    const customStyle = {height: 'auto', top: '30vh'}

    const handleDateSelect = (arg: any) => {
        const from: Date = arg.start
        const to: Date = arg.end
        to.setDate(to.getDate() - 1)    // -1 because the next day is put as 'end'

        setNewTouringDates({from, to})
        setAddTouringModal(true)
    }

    return (
        <>
            <Dialog visible={tourInfosModal} onClose={closeInfosModal} dialogCustomStyle={customStyle}>
                    <CalendarEventModal touring={activeEvent}/>
             </Dialog>
             {isAdmin && 
                <Dialog 
                    visible={addTouringModal} 
                    onClose={closeTouringModal}
                    dialogCustomStyle={{overflowY: 'scroll', height: '90vh', top: '5vh', width: '80%', left: '10%', backgroundColor: 'var(--app-background)'}}
                    notAllSpace={true}
                >
                    <TouringForm dates={newTouringDates} />
                </Dialog>}
                
            <div className="app-card">
                {formatedTours.length ? 
                    <FullCalendar
                        validRange={!isAdmin ? calendarLimits : undefined}
                        plugins={[ dayGridPlugin, interactionPlugin ]}
                        initialView="dayGridMonth"
                        events={formatedTours}
                        dayMaxEventRows={4}
                        eventClick={handleEventClick}
                        eventClassNames="clickable hover-opacity"
                        aspectRatio={2}
                        selectable={isAdmin}
                        select={handleDateSelect}
                        dayCellClassNames={isAdmin ? 'clickable' : ''}
                    />
                :   <p className="paddedTop50px">No tours are matching your selection.</p>}
            </div>
        </>
    )
}

export default CalendarScreen