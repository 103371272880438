import { ClientTask } from "../../../utils/models/client.model"
import NotificationItem from "./NotificationItem"

const NotificationsList = ({ notifications }: { notifications: ClientTask[]}) => {

    const notificationsItems = notifications.map((notification, index) => <NotificationItem key={index} notification={notification} />)

    return(
        <div className="notifications__dropdown">
			<div className="notifications__menu">
				{notifications.length
                ?   notificationsItems
                :   <div className="notification__item-div"><p className="grey">You don't have any notifications.</p></div>}
			</div>
		</div>
    )
}

export default NotificationsList