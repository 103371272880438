import { useState, forwardRef } from "react"
import SelectInput from "../../../../components/form/form-components/SelectInput"
import LabelInput from "../../../../components/form/mui/label-input/LabelInput"
import { useAppDispatch, useAppSelector } from "../../../../utils/hooks/reduxTypedHooks"
import DatePicker from "react-datepicker";
import { Artist } from "../../../../utils/models/artists.model"
import { countries, PotentialTouring, PotentialTouringCreateObject, ShowDuration, TouringCountriesList, TouringShowDurationsList } from "../../../../utils/models/touring.model"
import { Flex } from "../../../../components/Containers"
import useSetSnack from "../../../../utils/hooks/useSetSnack";
import useFetchApiAuth from "../../../../utils/hooks/useFetchApiAuth";
import { potentialTouringsActions } from "../../../../store/potentialTouringsSlice";

const PotentialTouringForm = ( { pTouring, artist, goBack }: 
    { 
        pTouring?: PotentialTouring,
        artist?: Artist,
        goBack?: any,
    } ) => {

    const today = new Date()
    today.setHours(0,0,0,0)

    const fromDate = new Date(pTouring?.from!)
    const toDate = new Date(pTouring?.to!)

    const inputeableShowDuration = pTouring ? {value: pTouring.showDuration, label: <div className="flex row justifystart"><b>{pTouring.showDuration}</b></div>} : null
    const inputeableCountries = pTouring ? pTouring.countries.map(country => ({value: country, label: <div className="flex row justifystart"><b>{countries[country]}</b></div>})) : null

    const [formInputs, setFormInputs] = useState({
        artistSelected: 
            artist ? 
            {value: artist.displayableName, label: <div className="flex row justifystart"><b>{artist.displayableName}</b></div> }
            : 
                {
                    value: '',
                    label: ''
                },
        from: pTouring?.from ? fromDate : today,
        to: pTouring?.to ? toDate : today,
        showDuration: inputeableShowDuration || {value: ShowDuration.sixtyMin, label: <div className="flex row justifystart"><b>{ShowDuration.sixtyMin}</b></div>},
        possibleFee: pTouring?.possibleFee ?? undefined,
        countries: inputeableCountries ??  [],
        comments: pTouring?.comments ||  '',
    })

    const [isPosting, setIsPosting] = useState(false)

    const artists = useAppSelector(state => state.artists)
    const artistsList = artists.map((artist) => ({ value: artist.displayableName, label: <div className="flex row justifystart"><b>{artist.displayableName}</b></div> }))

    const dispatch = useAppDispatch()
    const setSnack = useSetSnack()
    const fetchApiAuth = useFetchApiAuth()

    const countriesList = TouringCountriesList.map(country => ({value: country, label: <div className="flex row justifystart"><b>{countries[country]}</b></div>}))
    const showDurationsList = TouringShowDurationsList.map(duration => ({ value: duration, label: <div className="flex row justifystart"><b>{duration} Minutes</b></div> }))

    const isUpdatingPTour = pTouring !== undefined

    const updateInput = (value: any, inputName: string) => {
        setFormInputs(prevInputs => ({...prevInputs, [inputName]: value}))
    }

    const validateForm = () => {

        return true
    }

    const handleSubmit = async () => {
        setIsPosting(true)

        const isFormValid = validateForm()
        if(!isFormValid) {
            setIsPosting(false)
            return
        }

        const artist = artists.find(artist => artist.displayableName === formInputs.artistSelected.value)
        if(!artist){
            setSnack({type: 'warning', content: 'Artist not found'})
            setIsPosting(false)
            return
        }

        const now = new Date().getTime()

        const newPTouring: PotentialTouringCreateObject = {
            ...formInputs, 
            artistId: artist.id,
            showDuration: formInputs.showDuration.value,
            countries: formInputs.countries.map(country => country.value),
            from: formInputs.from.toDateString(),
            to: formInputs.to.toDateString(),
            dateCreated: now,
            isPotential: true,
        }

        // removing artistSelected (that we replaced with artistId) from the posted object
        //@ts-ignore
        delete newPTouring['artistSelected']

        // await post new touring
        const { error, response: createdPTouring } = await fetchApiAuth({
            method: "POST",
            route: "create-potential-touring",
            body: {...newPTouring}
        })

        // snack
        setSnack(
            error
            ? {
                type: 'error', 
                content: error.toString()
            }
            : {
                type: 'success',
                content: "The touring has been successfully added"
            })

        //update Redux state
        if(!error){
            dispatch(potentialTouringsActions.add(createdPTouring))
            goBack()
        }

        setIsPosting(false)
    }

    // @ts-ignore
    const DatePickerCustomInput = forwardRef(({ value, onClick }, ref) => (
        // @ts-ignore
        <button className="button button--primary" onClick={onClick} ref={ref}>
          {value}
        </button>
      ));

    return (
        <div className={`smaller main-container ${pTouring ? 'overflow-x-hidden margedBot30px' : 'margedTop50px margedBot100px'}`}>

            {!pTouring && <h2 className="page-title">New Potential Touring</h2>}

            <div className="allwidth app-card">
                <div className="grid-4-col alignend margedBot30px">

                    <SelectInput 
                        options={artistsList}
                        title='Selected Artist'
                        value={formInputs.artistSelected}
                        onChange={(e:any) => {updateInput(e, 'artistSelected')}}
                        showRawValue
                        searchable
                    />
                    <LabelInput 
                        title="Possible Fee"
                        value={formInputs.possibleFee}
                        type="number"
                        onChange={(e: any) => {updateInput(+e.target.value, 'possibleFee')}}
                    />
                    <div>
                        <span>From</span>
                        <DatePicker
                            customInput={<DatePickerCustomInput />} 
                            selected={formInputs.from} 
                            onChange={(selectedDate:Date) => {
                                selectedDate.setHours(0,0,0,0)
                                updateInput(selectedDate, 'from')
                            }} 
                        />
                    </div>
                    <div>
                        <span>To</span>
                        <DatePicker 
                            customInput={<DatePickerCustomInput />} 
                            selected={formInputs.to} 
                            onChange={(selectedDate:Date) => {
                                selectedDate.setHours(0,0,0,0)
                                updateInput(selectedDate, 'to')}} 
                            minDate={formInputs.from} 
                        />
                    </div>

                </div>

                <div className="grid-4-col alignend margedBot30px">
                    <SelectInput 
                        options={countriesList}
                        title='Countries'
                        value={formInputs.countries}
                        onChange={(e:any) => {updateInput(e, 'countries')}}
                        showRawValue
                        multi
                    />
                    <SelectInput 
                        options={showDurationsList}
                        title='Show Duration'
                        value={formInputs.showDuration}
                        onChange={(e:any) => {updateInput(e, 'showDuration')}}
                        showRawValue
                    />
                    <LabelInput 
                        title="Comments"
                        value={formInputs.comments}
                        onChange={(e: any) => {updateInput(e.target.value, 'comments')}}
                    />
                </div>

                <Flex row className="">
                    <button onClick={goBack} className="button button--danger button--round margedRight100px">Cancel</button>
                    <button onClick={handleSubmit} className="button button--primary button--round">{isPosting ? "Adding..." : "Add Potential Touring"} </button>
                </Flex>
            </div>
        </div>
    )
}

export default PotentialTouringForm