import Slider from "@mui/material/Slider"
import moment from "moment"
import { useState } from "react"
import { Flex, Row } from "../../../../../components/Containers"
import Dialog from "../../../../../components/dialog/Dialog"
import LabelInput from "../../../../../components/form/mui/label-input/LabelInput"
import CheckboxSpruha from "../../../../../components/form/spruha/CheckboxSpruha"
import ToolTip from "../../../../../components/ToolTip"
import useSetSnack from "../../../../../utils/hooks/useSetSnack"
import { AmountType, OfferFormInputs, OfferShowFormInputs } from "../../../../../utils/models/offer.model"
import { countries } from "../../../../../utils/models/touring.model"
import { computeOtherIncomesAmount, computeSponsorsAmount, computeTicketsDatas } from "../../../../../utils/offers.util"
import { ls, lsToFlat, pnlAmountFormatter } from "../../../../../utils/utils"
import OtherIncomesForm from "../pnl/OtherIncomesForm"
import SponsorsForm from "../pnl/SponsorsForm"

const OfferStep = ({ offer, os, osi, currencySymbol, isReviewing, isLocalReviewing, setOffer, setOfferShow, setShowDealInputs, 
    goToPreviousStep, goToNextStep, goBackToReview, goBackToLocalReview }: 
    { 
        offer: OfferFormInputs,
        os: OfferShowFormInputs, 
        osi: number,
        currencySymbol: null|string,
        isReviewing: boolean,
        isLocalReviewing: boolean,
        setOffer: (_props: any) => void,
        setOfferShow: (showIndex: number, showProps: {}) => void,
        setShowDealInputs: (showIndex: number, props: any) => void,
        goToPreviousStep: () => void,
        goToNextStep: () => void,
        goBackToReview: () => void,
        goBackToLocalReview: () => void,
    }) => {

    const [sponsorsVisible, setSponsorsVisible] = useState(false)
    const [otherIncomesVisible, setOtherIncomesVisible] = useState(false)

    const setSnack = useSetSnack()

    const openSponsors = () => setSponsorsVisible(true)
    const closeSponsors = () => setSponsorsVisible(false)
    const openOtherIncomes = () => setOtherIncomesVisible(true)
    const closeOtherIncomes = () => setOtherIncomesVisible(false)

    const sponsorshipAmount = computeSponsorsAmount(os.sponsors, os.sponsored)
    const otherIncomesAmount = computeOtherIncomesAmount(os.otherIncomes, os.hasOtherIncomes)
    const guaranteedFee = os.dealInputs.hasGuaranteedFee ? os.dealInputs.guaranteedFee : 0
    const airFare = os.hasAirFare ? (os.airFare ?? 0) : 0

    const today = new Date()
    const todayFormated = moment(today).format('YYYY-MM-DD')

    const checkIfOfferStepValid = () => {
        if(!offer.expiryDate) {
            setSnack({type: 'warning', content: 'Please provide an offer expiry date'})
            return false
        }

        if(os.amountType === AmountType.Fixed) {
            if(!os.amount) {
                setSnack({type: 'warning', content: 'Please enter an amount'})
                return false
            }

            if(os.isPnlMandatory && !os.isPnlDone) {
                setSnack({type: 'warning', content: 'Please make a PNL for the show'})
                return false
            }
        } 

        else if (os.amountType === AmountType.Deal) {
            if(!os.isPnlDone) {
                setSnack({type: 'warning', content: 'Please make a PNL for the show'})
                return false
            }
        }

        return true
    }

    const handlePrevious = () => {
        goToPreviousStep()
    }

    const handleNext = () => {
        const isOfferValid = checkIfOfferStepValid()
        if(!isOfferValid) {
            return
        }

        goToNextStep()
    }

    const handleGoBackToReview = (isLocalReview: boolean) => {
        const isOfferValid = checkIfOfferStepValid()
        if(!isOfferValid) {
            return
        }

        if(isLocalReview) {
            goBackToLocalReview()
        } else {
            goBackToReview()
        }
    }

    const handleToggleSponsors = (e: any) => {
        if(!os.sponsored) {
            openSponsors()
        }
        setOfferShow(
            osi, 
            { sponsored: e.target.checked }
        )
    }
    const handleCancelSponsors = () => {
        setOfferShow(
            osi, 
            { 
                sponsored: false,
                sponsors: [{name: "", amount: 0}]
            }
        )
        closeSponsors()
    }

    const handleToggleOtherIncomes = (e: any) => {
        if(!os.hasOtherIncomes) {
            openOtherIncomes()
        }
        setOfferShow(
            osi, 
            { hasOtherIncomes: e.target.checked }
        )
    }
    const handleCancelOtherIncomes = () => {
        setOfferShow(
            osi, 
            { 
                hasOtherIncomes: false,
                otherIncomes: [{name: "Food & Beverage", amount: 0}, {name: "Merchandising", amount: 0}]
            }
        )
        closeOtherIncomes()
    }

    const isFixedFee = os.amountType === AmountType.Fixed
    const toggleAmountType= () => {
        if(isFixedFee)
            setOfferShow(osi, { amountType: AmountType.Deal })
        else
        setOfferShow(osi, { amountType: AmountType.Fixed })
    }

    const { ticketsTotalGrossIncome, ticketsTotalNetIncome } = computeTicketsDatas(os.tickets, os.ticketsVariables)

    const guaranteedFeeGross = guaranteedFee + (guaranteedFee * (os.dealInputs.witholdingTax/100))

    const totalIncomeAmountGross = ticketsTotalGrossIncome + sponsorshipAmount + otherIncomesAmount
    // const totalIncomeNet = totalIncomeAmountGross * (1 - (os.taxPercent / 100))
    
    // const minRevenue = os.dealInputs.maxAttendance * minFeeNet
    // const maxRevenue = os.dealInputs.maxAttendance * entryFeeNet
    const promotorDistributedRevenues = totalIncomeAmountGross * (os.dealInputs.promotorDistributionPercent / 100) - airFare

    const promotorMaxRevenue = promotorDistributedRevenues - guaranteedFeeGross
    
    let artistDistributedRevenues = totalIncomeAmountGross * (1 - (os.dealInputs.promotorDistributionPercent / 100))
    if(os.hasAirFare) { 
        artistDistributedRevenues += os.airFare
    }
    // const artistMinRevenue = os.dealInputs.hasGuaranteedFee ? os.dealInputs.guaranteedFee : 0
    const artistMaxRevenue = os.dealInputs.hasGuaranteedFee
    ?   os.dealInputs.guaranteedFee + artistDistributedRevenues
    :   artistDistributedRevenues


    return (
        <>
            
            {/* Sponsors */}
            <Dialog
                title={`Sponsors for show with ${offer.artistName} ${moment(os.date).format("LL")} in ${countries[os.country]}`}
                visible={sponsorsVisible}
                onClose={closeSponsors}
                backdropClose={false}
                dialogCustomStyle={{overflowY: 'scroll', height: '60vh', top: '20vh', width: '70%', left: '15%', backgroundColor: "var(--app-background)"}}
                notAllSpace={true}
            >
                <SponsorsForm 
                    show={os}
                    showIndex={osi}
                    currencySymbol={currencySymbol}
                    setOfferShow={setOfferShow}
                    close={closeSponsors}
                    cancel={handleCancelSponsors} 
                />
            </Dialog>

            {/* Other Incomes */}
            <Dialog
                title={`Other incomes for show with ${offer.artistName} ${moment(os.date).format("LL")} in ${countries[os.country]}`}
                visible={otherIncomesVisible}
                onClose={closeOtherIncomes}
                backdropClose={false}
                dialogCustomStyle={{overflowY: 'scroll', height: '60vh', top: '20vh', width: '70%', left: '15%', backgroundColor: "var(--app-background)"}}
                notAllSpace={true}
            >
                <OtherIncomesForm 
                    show={os}
                    showIndex={osi}
                    currencySymbol={currencySymbol}
                    setOfferShow={setOfferShow}
                    close={closeOtherIncomes}
                    cancel={handleCancelOtherIncomes} 
                />
            </Dialog>

            <Flex className="alignstart allwidth margedBot30px">

                <p className="title main-color margedBot10px">Offer</p>

                <div className="allwidth grid-4-col alignend grid-small-col-gap margedBot10px">
                    <LabelInput
                        title='Offer Expiry Date*'
                        value={offer.expiryDate||''}
                        onChange={(e:any) => setOffer(
                            { expiryDate: e.target.value }
                        )}
                        type='date'
                        min={todayFormated}
                    />
                    <CheckboxSpruha 
                        title='Visa Application Required'
                        checked={os.visaRequired}
                        onChange={(e:any) => setOfferShow(
                            osi, 
                            {  visaRequired: e.target.checked }
                        )}
                        label
                    />

                    <CheckboxSpruha 
                        title='The event will be broadcasted'
                        checked={os.broadcasted||false}
                        onChange={(e:any) => setOfferShow(
                            osi, 
                            { broadcasted: e.target.checked }
                        )}
                        label
                    />
                </div>
                
                <div className="allwidth grid-3-col alignend grid-small-col-gap margedBot30px">
                    <Row>
                        <div className="width50 margedRight20px">
                            <CheckboxSpruha 
                                title='The event is sponsored'
                                checked={os.sponsored}
                                onChange={(e:any) => handleToggleSponsors(e)}
                                label
                            />
                        </div>
                        <div className="width50">
                            {os.sponsored && <button className="button button--primary button--round button--small" onClick={openSponsors}>Edit Sponsors</button>}
                        </div>
                    </Row>

                    <Row>
                        <Flex flex={3}>
                            <CheckboxSpruha 
                                title={'Flight contribution'}
                                checked={os.hasAirFare||false}
                                onChange={(e:any) => setOfferShow(
                                    osi, 
                                    { hasAirFare: e.target.checked }
                                )}
                                label
                            />
                        </Flex>
                        <Flex flex={7} className='margedLeft10px allwidth alignstart'>
                            {
                                os.hasAirFare
                                &&  <LabelInput
                                        title={`Flight Contribution (${currencySymbol ?? os.localCurrency})`}
                                        value={ls(os.airFare)}
                                        onChange={(e:any) => {
                                            const flattenedValue = lsToFlat(e.target.value)
                                            if(!isNaN(flattenedValue)) {
                                                setOfferShow(
                                                    osi, 
                                                    { airFare: flattenedValue }
                                                )
                                            }
                                        }}
                                    />
                            }
                        </Flex>
                    </Row>

                    <Row>
                        <div className="width50 margedRight20px">
                            <CheckboxSpruha 
                                title='Other Incomes'
                                checked={os.hasOtherIncomes}
                                onChange={(e:any) => handleToggleOtherIncomes(e)}
                                label
                            />
                        </div>
                        <div className="width50">
                            {os.hasOtherIncomes && <button className="button button--primary button--round button--small" onClick={openOtherIncomes}>Edit Other Incomes</button>}
                        </div>
                    </Row>
                </div>

                <p className="title main-color margedBot10px">Offer Amount</p>
                
                <Row className="margedBot50px">
                    <div className="width50">
                        <div className="flex-only row">
                            <p className="leftalign label-color margedRight5px">Fixed fee</p>
                            <ToolTip 
                                title="Fixed Fee" 
                                content={<p>State a fixed amount as an artist fee.</p>} 
                            />
                        </div>
                        <CheckboxSpruha 
                            inputOnly
                            title='Fixed fee'
                            checked={isFixedFee}
                            onChange={toggleAmountType}
                        />
                    </div>
                    <div className="width50">
                        
                        <div className="flex-only row">
                            <p className="leftalign label-color margedRight5px">Deal</p>
                            <ToolTip 
                                title="Deal" 
                                content={<p>Suggest a deal for the show. For example, you could offer a percentage of the door takings. You could also offer a guaranteed fee, as well as a break-even point, for an extended deal.</p>} 
                            />
                        </div>
                        <CheckboxSpruha 
                            title='Deal'
                            inputOnly
                            checked={!isFixedFee}
                            onChange={toggleAmountType}
                        />
                    </div>
                </Row>
                {isFixedFee
                ?   <div className="allwidth">
                        <p className="leftalign smaller grey">Net fee offer is exclusive of all taxes/commissions/deductions of any kind.<br />The promotor shall its sole cost and expense provide accommodation and transportation</p>

                        <div className="allwidth flex-only row margedTop10px">
                            <div className="width20 flex-only row">
                                <CheckboxSpruha 
                                    title='Net'
                                    checked={os.isAmountNet}
                                    onChange={(e:any) => setOfferShow(osi, { isAmountNet: e.target.checked })}
                                />
                                <CheckboxSpruha 
                                    title='Gross'
                                    checked={!os.isAmountNet}
                                    onChange={(e:any) => setOfferShow(osi, { isAmountNet: !e.target.checked })}
                                />
                            </div>
                            <div className="width80 flex-only row">
                                <div className={`${os.isAmountNet ? "allwidth" : "width80 margedRight20px"}`}>
                                    <LabelInput
                                        title={`Amount ${currencySymbol ?? os.localCurrency}*`}
                                        value={ls(os.amount)||''}
                                        onChange={(e:any) => {
                                            const flattenedValue = lsToFlat(e.target.value)
                                            if(!isNaN(flattenedValue)) {
                                                setOfferShow(osi, { amount: flattenedValue })
                                            }
                                        }}
                                    />
                                </div>
                                {!os.isAmountNet &&
                                    <div className="width20">
                                        <LabelInput
                                            title='Tax Percent'
                                            type="number"
                                            min={0}
                                            max={100}
                                            value={os.taxPercent}
                                            onChange={(e:any) => {
                                                setOfferShow(osi, { taxPercent: e.target.value })
                                            }}
                                        />
                                    </div>}
                            </div>
                        </div>
                        {!os.isAmountNet 
                        &&  <Row className="allwidth justify-end textAlignEnd margedTop20px">
                                <div className="margedRight75px">
                                    <p>Tax {os.taxPercent}%</p>
                                    <p>Amount NET</p>
                                </div>
                                <div>
                                    <p>{currencySymbol ?? os.localCurrency} {pnlAmountFormatter(os.amount * (os.taxPercent/100))}</p>
                                    <p>{currencySymbol ?? os.localCurrency} {pnlAmountFormatter(os.amount - (os.amount * (os.taxPercent/100)))}</p>
                                </div>
                            </Row>}
                    </div>
                    // Deal Fee
                :   <div className="allwidth">

                        <div className="grid-3-col alignend margedBot30px">

                            <div>
                                <div className="flex-only row margedBot10px">
                                    <p className="leftalign label-color margedRight5px">Total gross ticket income ({currencySymbol ?? os.localCurrency})</p>
                                    <ToolTip 
                                        title="Total gross ticket income" 
                                        content={<p>
                                            Coming from the ticket scale. This is the total amount of gross prices of the tickets enetered in the previous step.
                                            <br />
                                            This is the second factor for the calculation of possible income for promoter and artist. The numbers below the split slider display the potential outcome dynamically.
                                        </p>} 
                                    />
                                </div>
                                
                                <div className="flex-align-center row">
                                    <p className="leftalign margedRight10px">{pnlAmountFormatter(ticketsTotalGrossIncome)}</p>
                                    <button onClick={handlePrevious} className="button button--primary button--round button--small">Edit Tickets</button>
                                </div>
                            </div>

                            {/* <div>
                                <div className="flex-only row">
                                    <p className="leftalign label-color margedRight5px">Max attendance figure</p>
                                    <ToolTip 
                                        title="Max attendance" 
                                        content={<p>The maximum capacity of your venue.</p>} 
                                    />
                                </div>
                                <p className="leftalign margedRight10px">{totalTicketsQuantity}</p>
                            </div> */}
                            
                            
                            <div>
                                <p>Total net ticket income ({currencySymbol ?? os.localCurrency})</p>
                                <p className="bold">{pnlAmountFormatter(ticketsTotalNetIncome)}</p>
                            </div>
                        </div>

                        <div className="grid-3-col leftalign alignend margedBot30px">
                            
                            <div>
                                <p>Sponsorship amount gross ({currencySymbol ?? os.localCurrency})</p>
                                <p className="bold">{pnlAmountFormatter(sponsorshipAmount)}</p>
                            </div>
                            <div>
                                <p>Other incomes amount gross ({currencySymbol ?? os.localCurrency})</p>
                                <p className="bold">{pnlAmountFormatter(otherIncomesAmount)}</p>
                            </div>
                            <div>
                                <p>Total income amount GROSS ({currencySymbol ?? os.localCurrency})</p>
                                <p className="bold">{pnlAmountFormatter(totalIncomeAmountGross)}</p>
                            </div>
                        </div>

                        <p>Distribution</p>
                        <Slider 
                            value={os.dealInputs.promotorDistributionPercent}
                            onChange={(e: any) => setShowDealInputs(osi, {promotorDistributionPercent: e.target.value})}
                            aria-label="Default" 
                            valueLabelDisplay="auto" 
                        />
                        <Flex row className="margedBot20px space-between">
                            <p>Promotor: {os.dealInputs.promotorDistributionPercent}%</p>
                            <p>Artist: {100 - os.dealInputs.promotorDistributionPercent}%</p>
                        </Flex>

                        <div className="margedBot20px">
                            <div className="flex-only row">
                                <p className="leftalign label-color margedRight5px">Guaranteed Fee</p>
                                <ToolTip 
                                    title="Guaranteed Fee" 
                                    content={<p>
                                        Decide on a minimum fixed fee that you would be willing to pay. Additional payment from the door takings will only be made to the artist after the break-even point (see next field) has been reached, in accordance with the agreed upon percentage. This is the usual payment model and ensures peace of mind and transparency for all parties.
                                        <br />
                                        <br />
                                        If you don’t want to offer a minimum fixed fee, put the "Guaranteed fee" as "No" in order to offer a simple deal.
                                    </p>} 
                                />
                            </div>
                            <CheckboxSpruha
                                title='Guaranteed fee'
                                checked={os.dealInputs.hasGuaranteedFee}
                                onChange={(e:any) => setShowDealInputs(osi, {hasGuaranteedFee: e.target.checked})}
                                inputOnly
                                label
                            />

                            {os.dealInputs.hasGuaranteedFee
                            &&  <div>
                                    <div className="margedBot10px">
                                        <div className="grid-2-col">
                                            <LabelInput
                                                title={`Guaranteed Fee Amount (${currencySymbol ?? os.localCurrency})`}
                                                value={ls(os.dealInputs.guaranteedFee)}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                    const flattenedValue = lsToFlat(e.target.value)
                                                    if(!isNaN(flattenedValue)) {
                                                        setShowDealInputs(osi, {guaranteedFee: flattenedValue})
                                                    }
                                                }}
                                            />
                                            <LabelInput
                                                title="Witholding Tax (%)"
                                                type="number"
                                                min={0}
                                                max={100}
                                                value={os.dealInputs.witholdingTax}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setShowDealInputs(osi, {witholdingTax: +e.target.value})}
                                            />
                                        </div>
                                    </div>
                                    
                                    {/* <div className="flex-only row">
                                        <p className="leftalign label-color margedRight5px">Break even point</p>
                                        <ToolTip 
                                            title="Break even point" 
                                            content={<p>
                                                Enter here the amount of costs you need to cover, including the minimum fixed fee. In the so-called Break Deal, artists receive the minimum fixed fee and only receive a percentage of the door takings after the break-even point has been reached.
                                                <br />
                                                <br />
                                                Here’s an example of a gig payment:
                                                <br />
                                                Fixed minimum fee	200€
                                                <br />
                                                Break-even point	600€
                                                <br />
                                                Split	40% Promoter
                                                <br />
                                                60% Artist
                                                <br />
                                                Admission fee	10€
                                                <br />
                                                Number of tickets sold	100
                                                <br />
                                                Door takings	1000€
                                                <br />
                                                Artist fee	200€ + 280€ deal (60% from 600€)
                                            </p>} 
                                        />
                                    </div>
                                    <LabelInput
                                        noLabel
                                        type="number"
                                        value={dealInputs.breakEvenPoint}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateDealInputs(+e.target.value, "breakEvenPoint")}
                                    /> */}
                                </div>}
                        </div>

                        <Flex row className="margedBot20px space-between">
                            <div>
                                <p>Revenue for promotor (Gross)</p>
                                <p className="bold">max ({currencySymbol ?? os.localCurrency}) {pnlAmountFormatter(promotorMaxRevenue)}</p>
                            </div>
                            <div>
                                <p>Revenue for artist (Gross)</p>
                                {/* <p className="bold">min ${pnlAmountFormatter(artistMinRevenue, true)}</p> */}
                                <p className="bold">max ({currencySymbol ?? os.localCurrency}) {pnlAmountFormatter(artistMaxRevenue)}</p>
                            </div>
                        </Flex>

                    </div>}

            </Flex>

            <Flex row className="allwidth">
                {isReviewing
                ?   <button className="button button--primary button--round margedRight100px" onClick={() => handleGoBackToReview(false)}>Apply</button>
                :   isLocalReviewing
                    ?   <button className="button button--primary button--round margedRight100px" onClick={() => handleGoBackToReview(true)}>Apply</button>
                    :   <>
                            <button className="button button--primary button--round margedRight100px" onClick={handlePrevious}>Previous</button>
                            <button className="button button--primary button--round" onClick={handleNext}>Next</button>
                        </>}
            </Flex>
        </>
    )
}

export default OfferStep