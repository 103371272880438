import { TotalTickets } from "../../../../../../utils/hooks/offer/computePnlDatas"
import { ShowTicket } from "../../../../../../utils/models/offer.model"
import { pnlAmountFormatter } from "../../../../../../utils/utils"

const TicketsTable = ( { tickets, currency, currencySymbol, rate, VAT, ticketFee, creditCardTax, otherTaxes, compTickets, ticketPricesAfterVATArray, ticketNetPricesArray, 
    compTicketsNumbersArray, ticketsForSaleArray, salesArray, totalTickets }: {
        tickets: ShowTicket[],
        VAT: number,
        currency: string,
        currencySymbol: null|string,
        rate: null|number,
        ticketFee: number,
        creditCardTax: number,
        otherTaxes: number,
        compTickets: number,
        ticketPricesAfterVATArray: number[],
        ticketNetPricesArray: number[],
        compTicketsNumbersArray: number[],
        ticketsForSaleArray: number[],
        salesArray: number[],
        totalTickets: TotalTickets,
    } ) => {

    const averageNetTicketPrice = totalTickets.sales / totalTickets.quantity
    const ticketsNetPricesUSDArray = ticketNetPricesArray.map(price => rate ? price/rate : 0)
    const salesUSDArray = salesArray.map(sale => rate ? sale/rate : 0)
    const averageNetTicketPriceUSD = rate ? averageNetTicketPrice / rate : 0
    const totalSalesUSD = rate ? totalTickets.sales / rate : 0

    return (
        <table className="app-table">
            <thead>
                <tr>
                    <th className="leftalign">Ticket sales</th>
                    <th className="leftalign">Tickets allocation</th>
                    <th className="leftalign">Ticket Price ({currencySymbol ?? currency})</th>
                    <th className="leftalign">V.A.T <br /> {VAT}%</th>
                    <th className="leftalign">Ticket Fee <br /> {ticketFee}%</th>
                    <th className="leftalign">Credit Card <br /> {creditCardTax}%</th>
                    <th className="leftalign">Other taxes <br /> {otherTaxes}%</th>
                    <th className="leftalign">Net ticket price ({currencySymbol ?? currency})</th>
                    <th className="leftalign">Net ticket price (USD)</th>
                    <th className="leftalign">Comps Ticket <br /> {compTickets}%</th>
                    <th className="leftalign">Tickets for sale</th>
                    <th className="leftalign">Ticket Sales ({currencySymbol ?? currency})</th>
                    <th className="leftalign">Ticket Sales (USD)</th>
                </tr>
            </thead>
            <tbody>
                {tickets.map((ticket, index) => {
    
                    return (
                    <tr key={index}>
                        <th className="leftalign">{ticket.name}</th>
                        <th className="leftalign">{ticket.quantity}</th>
                        <th className="leftalign">{currencySymbol ?? currency} {pnlAmountFormatter(ticket.grossPrice)}</th>
                        <th className="leftalign">
                            {VAT && <p className="error">-{currencySymbol ?? currency} {pnlAmountFormatter((ticketPricesAfterVATArray[index] - ticket.grossPrice) * -1)}</p>}
                        </th>
                        <th className="leftalign">
                            {(ticketFee > 0) && <p className="error">-{currencySymbol ?? currency} {pnlAmountFormatter(ticketPricesAfterVATArray[index] * ticketFee/100)}</p>}
                        </th>
                        <th className="leftalign">
                            {(creditCardTax > 0) && <p className="error">-{currencySymbol ?? currency} {pnlAmountFormatter(ticketPricesAfterVATArray[index] * creditCardTax/100)}</p>}
                        </th>
                        <th className="leftalign">
                            {(otherTaxes > 0) && <p className="error">-{currencySymbol ?? currency} {pnlAmountFormatter(ticketPricesAfterVATArray[index] * otherTaxes/100)}</p>}
                        </th>
                        <th className="leftalign">
                            {currencySymbol ?? currency} {pnlAmountFormatter(ticketNetPricesArray[index])}
                        </th>
                        <th className="leftalign">
                            ${pnlAmountFormatter(ticketsNetPricesUSDArray[index])}
                        </th>
                        <th className="leftalign">
                            {compTicketsNumbersArray[index]}
                        </th>
                        <th className="leftalign">
                            {ticketsForSaleArray[index]}
                        </th>
                        <th className="leftalign bold">
                            {currencySymbol ?? currency} {pnlAmountFormatter(salesArray[index])}
                        </th>
                        <th className="leftalign bold">
                            ${pnlAmountFormatter(salesUSDArray[index])}
                        </th>
                    </tr>)
                    })
                }

                {/* total row */}
                <tr>
                    <th className="leftalign">Total</th>
                    <th className="leftalign">{totalTickets.quantity}</th>
                    <th className="leftalign"></th>
                    <th className="leftalign"></th>
                    <th className="leftalign"></th>
                    <th className="leftalign"></th>
                    <th className="leftalign"></th>
                    <th className="leftalign">
                        {currencySymbol ?? currency} {pnlAmountFormatter(averageNetTicketPrice)}
                    </th>
                    <th className="leftalign">
                        ${pnlAmountFormatter(averageNetTicketPriceUSD)}
                    </th>
                    <th className="leftalign">
                        {totalTickets.compTicketsNumber}
                    </th>
                    <th className="leftalign">
                        {totalTickets.ticketsForSale}
                    </th>
                    <th className="leftalign bold">
                        {currencySymbol ?? currency} {pnlAmountFormatter(totalTickets.sales)}
                    </th>
                    <th className="leftalign bold">
                        ${pnlAmountFormatter(totalSalesUSD)}
                    </th>
                </tr>
            </tbody>
        </table>
    )
}

export default TicketsTable