import { useOutletContext } from "react-router-dom"
import { useEffect, useRef, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Dialog from "../../../../../components/dialog/Dialog"
import { ROUTE_TOURING } from "../../../../../routes/routes.const"
import { useAppSelector } from "../../../../../utils/hooks/reduxTypedHooks"
import { Licence } from "../../../../../utils/models/client.model"
import ArtistDaysCalendar from "./ArtistDaysCalendar"
import ArtistRequestTouring from "../common/ArtistRequestTouring"
import ArtistToursList from "./ArtistToursList"
import { ArtistDetailsContext } from "../ArtistDetails"
import { BiLayerPlus } from "react-icons/bi"
import useFilterByContinent from "../../../../../utils/hooks/useFilterByContinents"
import { minifyArtistStreamingDatas } from "../../../../../utils/artists.util"

const ArtistDetailsTours = () => {
    const { artist, openOfferForm, daysOfTour, fetchedDatas } = useOutletContext<ArtistDetailsContext>()

    const [requestVisible, setRequestVisible] = useState(false)
    const [screen, setScreen] = useState<ScreenKeys>('list')
    const [isOnlyPrimuseTours, setIsOnlyPrimuseTours] = useState(false)
    
    const licence = useAppSelector(state => state.client?.licence)
    const artists = useAppSelector(state => state.artists)
    const isAdmin = licence === Licence.Admin
    
    const navigate = useNavigate()

    const { FilterUI, filteredCountries, isEverywhere } = useFilterByContinent()

    const bookingRef = useRef<HTMLDivElement>(null)

    const { hash } = useLocation()

    const isArtistPrimuse = !!artists.find(searchedArtist => searchedArtist.id === artist.id)

    const openRequest = () => setRequestVisible(true)
    const closeRequest = () => setRequestVisible(false)

    const setOnlyPrimuseTours = () => setIsOnlyPrimuseTours(true)
    const setAllTours = () => setIsOnlyPrimuseTours(false)


    //Filtering
    const continentFilteredOutsideEvents = fetchedDatas.events 
    ?   fetchedDatas.events.filter(event => {
            if(isEverywhere){ return true }
            return filteredCountries.includes(event.venue.country)
        })
    :   undefined
    const filteredOutsideEvents = continentFilteredOutsideEvents
    ?   continentFilteredOutsideEvents.filter(_ => !isOnlyPrimuseTours)
    :   undefined

    const filteredDaysOfTour = daysOfTour 
    ?   daysOfTour.filter(day => {
        if(isEverywhere){ return true }
        return filteredCountries.includes(day.country)
    })
    :   null

    // auto scroll to booking section
    useEffect(() => {
        if(hash === '#booking') {
            // need to setTimeout so it gets queued after the DOM is rendered
            setTimeout(() => {
                if(bookingRef.current !== null)
                    bookingRef.current.scrollIntoView()
            }, 0)
        }

    }, [hash])

    const handleAddTouring = () => {
        const addArtist = isArtistPrimuse ? false : true

        const streamingDatas = minifyArtistStreamingDatas(fetchedDatas)
        
        navigate(ROUTE_TOURING, {state: {toAddTouring: true, artistToAdd: artist, addArtist, streamingDatas}})
    }
    
    const screens = {
        calendar: {
            title: "Calendar",
            component: <ArtistDaysCalendar days={filteredDaysOfTour} openOfferForm={openOfferForm} artist={artist} />
        },
        list: {
            title: "List",
            component: <ArtistToursList openOfferForm={openOfferForm} primuseShows={filteredDaysOfTour} outsideEvents={filteredOutsideEvents} />
        },
    }
    type ScreenKeys = keyof typeof screens

    return (
        <div ref={bookingRef} className="main-container">
            <Dialog 
                visible={requestVisible} 
                onClose={closeRequest}
                title="Touring Request"
                notAllSpace 
                dialogCustomStyle={{overflowY: "scroll", height: '90vh', top: '5vh', width: '80%', left: '10vw', backgroundColor: 'var(--app-background)'}}
            >
                <ArtistRequestTouring artistName={artist.displayableName} close={closeRequest}/>
            </Dialog>
            <h2 className="margedBot30px leftalign">Upcoming tours</h2>

            {isAdmin && 
                <button className="flex row button button--primary uppercase margedTop10px margedBot20px" onClick={handleAddTouring}>
                    <BiLayerPlus size={25} />
                    &nbsp;
                    {isArtistPrimuse ? "New Touring" : "New Touring & Add Artist"}
                </button>}

            <div className="margedBot40px">
                {(!daysOfTour || daysOfTour.length === 0)
                &&  <p className="grey margedBot20px">No Primuse tours available for now.</p>}
                <button className="button button--primary button--round" onClick={openRequest}>Request Shows</button>
            </div>
            
            <div className="flex-align-center app-card space-between margedBot30px">
                <div className="width70 margedRight20px">
                    {FilterUI}
                </div>
                <div className="flex-only row wrap width30 justify-end col-gap20">
                    <button onClick={setAllTours} className={`button touring__button touring-ghost__button ${!isOnlyPrimuseTours ? 'touring-ghost__button--active' : ''}`}>
                        All Tours
                    </button>
                    <button onClick={setOnlyPrimuseTours} className={`button touring__button touring-ghost__button ${isOnlyPrimuseTours ? 'touring-ghost__button--active' : ''}`}>
                        Available Only
                    </button>
                </div>
            </div>
            <div className="touring__screens-nav margedBot30px">
                <div className="flex-only justifyend">
                    {Object.keys(screens).map((screenName, index: number) => 
                        <div className="screens-buttons-div" key={index}>
                            <button 
                                className={`button touring__button touring-ghost__button button--round ${screen === screenName ? 'touring-ghost__button--active' : ''}`}
                                onClick={() => {
                                    {/* @ts-ignore ; fine because screen IS typeof ScreenKeys and not string */}
                                    setScreen(screenName)
                                }} 
                                >{/* @ts-ignore */}
                                    {screens[screenName].title}
                            </button>
                        </div>
                        )}
                </div>
            </div>
            {screens[screen].component}

        </div>
    )
}

export default ArtistDetailsTours