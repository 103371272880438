import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BiUserCircle } from 'react-icons/bi';
import { FaCheck } from 'react-icons/fa';

import { Flex } from "../../../../components/Containers";
import ClipboardCopy from "../../../../components/clipboard/ClipboardCopy";

import FormCategory from "../../../../components/form/FormCategory";
import { clientActions } from "../../../../store/clientSlice";
import ConnectWallet from './ConnectWallet';
import Web3 from 'web3';
import { promisify } from "../../../../utils/utils";
import logo from '../../../../images/polygon-logo.png'
import { Bars } from "react-loader-spinner";
import { theme } from "../../../../constants";

import { useNavigate } from "react-router-dom";
import { ROUTE_ORACLE } from "../../../../routes/routes.const";



const ethBalance = async address => await Promise.resolve(parseInt((await new Web3(window.ethereum).eth.getBalance(address)).toString()));
const fromWei = (amount) => new Web3().utils.fromWei((amount).toString(), 'ether');
const toWei = amount => new Web3().utils.toWei(amount, 'ether');

const AdminOracle = () => {

    const [depositValue, setDepositValue] = useState();
    const [sending, setSending] = useState(false);
    const [sendError, setSendError] = useState(false);
    const [txHash, setTxHash] = useState(false);
    const [oracleBalance, setOracleBalance] = useState(0);
    
    const client = useSelector((state) => state.client);

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const depositValueChanged = (e) => { setDepositValue(e.target.value) }

    useEffect(() => {
        (async() => {
            const oBalance = await ethBalance(process.env.REACT_APP_ORACLE_ADDRESS)
            setOracleBalance(fromWei(oBalance))
        })()
    }, [])

    const formDef = [
        {
            category: 'Connected Account',
            content: <Flex className="alignstart allwidth">
                <div className="larger">{ client?.web3?.address }</div>
                <Flex className="xlarge bold ">{client?.web3?.balance?fromWei(client?.web3?.balance):'0.00'} MATIC</Flex>

            </Flex>
        },
        {
            category: 'Oracle Balance',
            content: <Flex className="alignstart xlarge bold allwidth">{oracleBalance || '0.00'} MATIC</Flex>
        },
        {
            category: 'Deposit',
            
        },
      
    ];

    const setClient = async(web3Props) => {
        const address = await promisify(web3Props.address || client.web3?.address);
        const balance = await promisify(address ? await ethBalance(address) : 0);
       
        const clientWeb3 = await promisify({
            ...(client.web3||{}), 
            address,
            ...web3Props, 
            ...(balance?{balance}:{})
        });

        dispatch(
            clientActions.set({
                ...client, 
                web3: clientWeb3
            })
        );

        // workaround for react-router-dom exiting the view on `window.ethereum` events
        // @note that the re-rendered component will go back to initial state be receives accurate props
        setTimeout(() => {
            navigate(ROUTE_ORACLE);
        })

    }

    const setMax = () => setDepositValue(fromWei(client?.web3?.balance||0))
    
    const topUp = async() => {
        setSending(true);
        setSendError(false);

        try {
            // proceed request
            const _txHash = await window.ethereum.request({
                method: 'eth_sendTransaction',
                params: [{
                    // gasPrice: '0x09184e72a000', // customizable by user during MetaMask confirmation.
                    // gas: '0x2710',              // idem
                    from: client.web3.address,
                    to: process.env.REACT_APP_ORACLE_ADDRESS,
                    value: parseInt(toWei(depositValue)).toString(16)//toWei((parseFloat(depositValue)).toString()),
                }]
            });

            setSending(false);
            setTxHash(_txHash)
        }catch(e) {
            setSending(false);
            setSendError({ message: '' });

        }
    }

    const quote = parseFloat(fromWei((client?.web3?.balance||0))) - (depositValue||0);

    return <Flex className="allspace justifystart smaller" >
        <div className="title selfcentered xxlarge spaced-text ">ORACLE ADMINISTRATION</div>
        {
            ((client?.web3?.address) && !(client?.web3?.error))
            ? <Flex className="spaceevenly width95">
                {
                    formDef.map((cat, cati) => <FormCategory
                        key={`adminoracleform_cat_${cati}`}
                        index={cati}
                        category={cat.category}
                        inputs={cat.inputs}
                        content={cat.content}
                    />)
                }


            {/* TX BOX */}
            <div className="flex flexfull allspace ">
                <div className="bordered boxshadow larger semi-opac-bg flex token-minter">
                    <div className="flex carter flexdot1 larger bottom-border allwidth theme1bg">
                        Top-Up The Primuse Oracle
                    </div>
                    <div className="flex flexdot9 width80 spaceevenly anthracite">
                        
                        <div className="flex allwidth">
                             {/* RECIPIENT */}
                             <div className="flex allwidth">
                                <div className="flex row justifystart allwidth smaller"><BiUserCircle size={35} style={{marginLeft: -3}} />&nbsp;Recipient</div>
                                { process.env.REACT_APP_ORACLE_ADDRESS }
                            </div>

                            <div className="flex row allwidth justifystart">
                                <div className="flex smaller flexdot3 row selfstart justifystart" style={{marginTop: '5%'}}>
                                    <div className="" style={{width:30, height: 30, padding: 2}}><img src={logo} style={{width:30, height: 30, objectFit: 'contain'}} /></div>
                                    &nbsp;
                                    MATIC Amount
                                </div>

                            </div>

                           

                            <div className="flex allwidth">
                                <input 
                                    type="number" 
                                    placeholder="0.00" 
                                    className={`app-input allwidth smaller nocarter${/*amountError ? ' error-box' : */''}`} 
                                    value={depositValue || 0} 
                                    onChange={depositValueChanged}
                                    style={{marginBottom: '0%'}}  
                                />
                                <button 
                                    className='xsmall app-button bordered marged-t selfend anthracite'
                                    onClick={setMax}
                                >
                                    max
                                </button>
                            </div>
                            
                        </div>

                        <div className="flex bordered allwidth height50 marged-v smaller nocarter">
                            {
                                (
                                    sendError
                                    ? <div className="error">{sendError?.message || 'Oops! An error occured while transfering. Please, check the network and try again'}</div>
                                    : (
                                        sending
                                        ? <div className="flex white">
                                            Transaction in progress
                                            <Bars
                                                height="70"
                                                width="80"
                                                color={theme[0]}
                                                ariaLabel="audio-loading"
                                                wrapperStyle={{ margin: '5%' }}
                                                wrapperClass="wrapper-class"
                                                visible={true}
                                            />
                                        </div>
                                        : (
                                            txHash
                                            ? <div className="flex success">
                                                <FaCheck color="teal" size='4vmax' />
                                                <b className='xlarge'>Success !</b>      
                                                <div className="large anthracite">You've sent {depositValue} MATIC</div>
                                                <ClipboardCopy title='See Transaction' copyText={`https://mumbai.polygonscan.com/tx/${txHash}`} />
                                            </div>
                                            : (
                                                depositValue
                                                ? <div className="flex">
                                                    {/* QUOTE */}
                                                    <div className="flex anthracite">
                                                        Your new balance will be : 
                                                        <b className="flex row">{quote<0 ? 0 : quote.toFixed(4)} &nbsp;<div className="larger">MATIC</div></b>
                                                    </div>
                                                </div>
                                                : 'Enter an amount'
                                            )
                                        )
                                    )
                                )
                            }


                        </div>

                        <button
                            className="app-button marged-v"
                            onClick={topUp}
                        >
                            SEND
                        </button>
                    </div>
                </div>
            </div>
            </Flex>
            : <Flex className="allheight spaceevenly width95">
                <ConnectWallet 
                    client={client.web3}
                    setClient={setClient}
                />
            </Flex>
        }
    </Flex>
}

export default AdminOracle;