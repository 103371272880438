import { useState } from "react"
import Dialog from "../../../../components/dialog/Dialog"
import FeedbackForm from "./FeedbackForm"

const PromotorFeedback = ({ page }: { page: string }) => {

    const [modalVisible, setModalVisible] = useState(false)

    const openModal = () => setModalVisible(true)
    const closeModal = () => setModalVisible(false)

    return (
        <div className="allwidth textAlignEnd">
            <button onClick={openModal} className="button button--primary">Give Feedback</button>

            <Dialog
                visible={modalVisible}
                title={`Feedback for page ${page}`}
                onClose={closeModal}
                backdropClose={false}
                dialogCustomStyle={{height: '350px', top: '23', width: '60%', left: '20%'}}
                notAllSpace={true}
            >
                <FeedbackForm close={closeModal} />
            </Dialog>
        </div>
    )
}

export default PromotorFeedback