import { Flex } from "../components/Containers";
import LandingPage from "../landing-page/LandingPage"
import AdminContracts from "../user-panels/admin/admin-panel-screens/contracts/AdminContracts";
import AdminOracle from "../user-panels/admin/admin-panel-screens/oracle/AdminOracle";
import AdminPromotors from "../user-panels/admin/admin-panel-screens/promotors/AdminPromotors";
import { ROUTE_ARTISTS, ROUTE_ARTISTS_FEED, ROUTE_CONTRACTS, ROUTE_HOME, ROUTE_LANDING, ROUTE_USERS, ROUTE_OFFERS, ROUTE_ORACLE, ROUTE_PROMOTORS, ROUTE_STATS, ROUTE_TASKS, ROUTE_TOKEN, ROUTE_TOURING, ROUTE_VENUES, ROUTE_PROFILE, ROUTE_MANAGERS, ROUTE_DASHBOARD, ROUTE_ARTIST_DETAILS, ROUTE_ARTIST_DETAILS_OVERVIEW, ROUTE_ARTIST_DETAILS_TOURS, ROUTE_AGENDA, ROUTE_APIS, ROUTE_ARTIST_DETAILS_ASSETS, ROUTE_TERMS_AND_CONDITIONS, ROUTE_PRIVACY_POLICY, ROUTE_ARTIST_DETAILS_ALBUMS, ROUTE_ARTIST_DETAILS_INFOS, ROUTE_LOGIN, ROUTE_POTENTIAL_TOURINGS, ROUTE_PNLS } from "./routes.const"
import UsersPage from "../pages/UsersPage";
import OffersPage from "../pages/OffersPage";
import TasksPage from "../pages/TasksPage";
import ProfilePage from "../pages/ProfilePage";
// import AdminDashboard from "../user-panels/admin/admin-panel-screens/dashboard/AdminDashboard";
import PanelPage from "../pages/PanelPage";
import ArtistsOutlet from "../user-panels/common/artists/ArtistsOutlet";
import ArtistsFeedPage from "../pages/ArtistsFeedPage";
import TouringPage from "../pages/TouringPage";
import ManagersPage from "../pages/ManagersPage";
import DashboardPage from "../pages/DashboardPage";
import ArtistDetailsOverview from "../user-panels/promotor/artists/details/overview/ArtistDetailsOverview";
import ArtistDetailsTours from "../user-panels/promotor/artists/details/tours/ArtistDetailsTours";
import AgendaPage from "../pages/AgendaPage";
import ApisPage from "../pages/ApisPage";
import ArtistDetailsAssets from "../user-panels/promotor/artists/details/assets/ArtistDetailsAssets";
import ArtistsDetailsPage from "../pages/ArtistDetailsPage";
import TermsPage from "../landing-page/TermsPage";
import PrivacyPolicyPage from "../landing-page/PrivacyPolicyPage";
import ArtistDetailsAlbums from "../user-panels/promotor/artists/details/albums/ArtistDetailsAlbums";
import ArtistDetailsInfos from "../user-panels/promotor/artists/details/infos/ArtistDetailsInfos";
import Login from "../landing-page/Login";
import PotentialTouringsPage from "../pages/PotentialTouringsPage";
import VenuesPage from "../pages/VenuesPage";
import PnlsPage from "../pages/PnlsPage";
import LandingPageV2 from "../landing-v2/LandingPageV2";

export const routes = [
    {
        path: ROUTE_LANDING,
        element: <LandingPageV2 />,
    },
    {
        path: ROUTE_LOGIN,
        element: <Login />,
    },
    {
        path: ROUTE_TERMS_AND_CONDITIONS,
        element: <TermsPage />,
    },
    {
        path: ROUTE_PRIVACY_POLICY,
        element: <PrivacyPolicyPage />,
    },
    {
        path: ROUTE_HOME,
        element: <PanelPage />,
        children: [
            // { index: true, element: <AdminDashboard /> },
            {
                path: ROUTE_DASHBOARD,
                element: <DashboardPage />
            },
            {
                path: ROUTE_ORACLE,
                element: <AdminOracle />
            },
            {
                path: ROUTE_USERS,
                element: <UsersPage />
            },
            {
                path: ROUTE_OFFERS,
                element: <OffersPage />
            },
            {
                path: ROUTE_PNLS,
                element: <PnlsPage />
            },
            {
                path: ROUTE_CONTRACTS,
                element: <AdminContracts />
            },
            {
                path: ROUTE_TASKS,
                element: <TasksPage />
            },
            {
                path: ROUTE_AGENDA,
                element: <AgendaPage />
            },
            {
                path: ROUTE_PROMOTORS,
                element: <AdminPromotors /> 
            },
            {
                path: ROUTE_MANAGERS,
                element: <ManagersPage /> 
            },
            {
                path: ROUTE_ARTISTS,
                element: <ArtistsOutlet />,
                children: [
                    {
                        path: ROUTE_ARTISTS_FEED,
                        element: <ArtistsFeedPage />
                    },
                    {
                        path: ROUTE_ARTIST_DETAILS,
                        element: <ArtistsDetailsPage />,
                        children: [
                            { index: true, element: <ArtistDetailsOverview /> },
                            {
                                path: ROUTE_ARTIST_DETAILS_OVERVIEW,
                                element: <ArtistDetailsOverview />
                            },
                            {
                                path: ROUTE_ARTIST_DETAILS_TOURS,
                                element: <ArtistDetailsTours />
                            },
                            {
                                path: ROUTE_ARTIST_DETAILS_ALBUMS,
                                element: <ArtistDetailsAlbums />
                            },
                            {
                                path: ROUTE_ARTIST_DETAILS_ASSETS,
                                element: <ArtistDetailsAssets />
                            },
                            {
                                path: ROUTE_ARTIST_DETAILS_INFOS,
                                element: <ArtistDetailsInfos />
                            },
                        ]
                    }
                ]
            },
            {
                path: ROUTE_TOURING,
                element: <TouringPage />
            },
            {
                path: ROUTE_POTENTIAL_TOURINGS,
                element: <PotentialTouringsPage />
            },
            {
                path: ROUTE_VENUES,
                element: <VenuesPage />
            },
            {
                path: ROUTE_TOKEN,
                element: <Flex className='allspace'>PRIME TOKEN COMING SOON !</Flex>
            },
            {
                path: ROUTE_STATS,
                element: <Flex className='allspace'>STATS COMING SOON !</Flex>
            },
            {
                path: ROUTE_PROFILE,
                element: <ProfilePage />
            },
            {
                path: ROUTE_APIS,
                element: <ApisPage />
            },
        ]
    }
]