import { MdOutlineAddTask } from "react-icons/md";
import { Flex } from "../../Containers";
import { useDispatch } from "react-redux";
import { snackActions } from "../../../store/snackSlice";
import { useState } from "react";
import { ArtistURL } from "../../../utils/models/artists.model";
import { ImCross } from "react-icons/im"
import LabelInput from "../mui/label-input/LabelInput";
import { colors } from "../../../constants";

const UrlsArrayInput = ({ title, value, renderer, onChange}: { title?: string, value: ArtistURL[], renderer: Function, onChange: Function}) => {
    const [urlInput, setUrlInput] = useState({type: '', href: ''})

    const handleRemoveElement = (indexToRemove: number) => {
        const updatedArray = value.filter((_, index) => index !== indexToRemove)
        onChange(updatedArray)
    }

    const dispatch = useDispatch()
    const setSnack = (snack:any) => dispatch(snackActions.set(snack))

    return(
        <Flex className="allwidth">
            {title && <label className="selfstart bold label-color">{ title }</label>}

            <Flex className="alignstart allwidth">
            {
                value.map((url:ArtistURL, index:number) => <Flex key={`inpurlsArray_${index}`} row>
                    {/* removable row */}
                    { renderer(url) }
                    <ImCross size={10} onClick={(e: React.MouseEvent<SVGElement, MouseEvent>) => handleRemoveElement(index)} color={colors.red} className="array-input__cross-icon clickable"/>
                </Flex>)
            }
            </Flex>
            <Flex row className="selfstart allwidth alignend">   
                <div className="allwidth grid-2-col margedRight20px">
                    <LabelInput
                        value={urlInput.type}
                        onChange={(e:React.ChangeEvent<HTMLInputElement>) => setUrlInput(prevInput => ({...prevInput, type: e.target.value}))}
                        title='type'
                    />
                    <LabelInput
                        value={urlInput.href}
                        onChange={(e:React.ChangeEvent<HTMLInputElement>) => setUrlInput(prevInput => ({...prevInput, href: e.target.value}))}
                        title='href'
                    />
                </div>       
                <MdOutlineAddTask
                    size={20} 
                    className="clickable main-color" 
                    onClick={() => {
                        if (urlInput.type && urlInput.href) {
                            const newUrl = {type: urlInput.type, href: urlInput.href}
                            onChange([...value, newUrl]);
                            setUrlInput({type: '', href: ''});
                        }
                        else setSnack({ type: 'warning', content: 'You need to fill both field' })
                    }} 
                />
            </Flex>
            
        </Flex>
    )
}

export default UrlsArrayInput