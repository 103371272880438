import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Artist } from '../utils/models/artists.model';

const initialState: Artist[] = [];

export const archivedArtistsSlice = createSlice({
  name: 'archivedArtists',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<Artist[]>) => {
      return action.payload
    },
    add: (state, action: PayloadAction<Artist>) => {
      return [...state, action.payload]
    },
    merge: (state, action: PayloadAction<Artist>) => {
      const {displayableName, ...mergeProps} = action.payload
      const artistIndex = state.findIndex(artist => artist.displayableName === displayableName)
      if(artistIndex !== -1){
        state[artistIndex] = {...state[artistIndex], ...mergeProps}
      }
    },
    delete: (state, action: PayloadAction<{id: string}>) => {
      const searchId = action.payload.id
      const foundIndex = state.findIndex(artist => artist.id === searchId)
      if(foundIndex !== -1){
        state.splice(foundIndex, 1)
      }
    },
  },
});

export const archivedArtistsActions = archivedArtistsSlice.actions;

export default archivedArtistsSlice.reducer;