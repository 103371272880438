import { Component } from 'react';
import { RiFileCopy2Line } from 'react-icons/ri';
import './clipboard.css';


export default class ClipboardCopy extends Component { 

    state = {
        copied: false
    }

    copy = async() => {
      
        await this.setState({copied: true});
        await navigator.clipboard.writeText(this.props.copyText);

        
        setTimeout(() => {
            this.setState({ copied: false });
        }, 3000)
    }
     
    render() {

        const { copied } = this.state;
        const size = this.props?.size || 20;
        
        return <div 
            className={`clipboard-button${copied ? ' copied' : ''}`} 
            style={{
                fontSize: 'smaller', 
                paddingLeft: 5, 
                paddingRight: 5,
                maxHeight: size
            }} 
            onClick={this.copy}
        >
            <span className="clipboard-icon flex row" >
                { this.props.title }
                <RiFileCopy2Line 
                    className="clickable" 
                    size={size} 
                />
            </span>
            <span className="clipboard-copied success" >
                Copied
            </span>
        </div>
    }
}