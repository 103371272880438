import { SHOW_DURATION_ALL_DURATIONS } from "../../../../utils/touring.util"

const DropdownItem = ({ item, currentFilter, handleClickItem, isDurations }: { item: string | number, currentFilter: string[] | number[], handleClickItem: any, isDurations?: boolean }) => {
    
	function handleClick() {
        handleClickItem(item)
	}

	//@ts-ignore
	const isCurrentActive = currentFilter.includes(item)
	const activeClass = isCurrentActive ? 'dropdown__active' : ''

	return (
		<div className={`dropdown__item ${activeClass}`} onClick={handleClick}>
			<span className={"dropdown__sortText"}>
				{isDurations 
				? item === SHOW_DURATION_ALL_DURATIONS ? "All Durations" : `${item} minutes`
				: item}
			</span>
		</div>
	)
}

export default DropdownItem