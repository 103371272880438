
const ENDPOINT_REDIRECT = 'https://primuse-web.vercel.app'

const TiktokAPI = () => {

    const csrfState = Math.random().toString(36).substring(2);

    let url = 'https://www.tiktok.com/auth/authorize/'

    url += `?client_key=${process.env.REACT_APP_TIKTOK_CLIENT_KEY}`
    url += '&scope=user.info.basic'
    url += '&response_type=code'
    url += `&redirect_uri=${ENDPOINT_REDIRECT}`
    url += '&state=' + csrfState

    return (
        <div>
            <h3>Tiktok</h3>
            <a className="margedBot100px" href={url}>Connect</a>
        </div>
    )
}

export default TiktokAPI