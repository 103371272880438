import { AiOutlineCloseCircle } from "react-icons/ai"
import { ls, pnlAmountFormatter } from "../../../../../utils/utils"

const ViewFixedCostsTable = ({ currency, currencySymbol, rate, fixedCosts, totalFixedCosts }: {
    currency: string,
    currencySymbol: null|string,
    rate: null|number,
    fixedCosts: {name: string, has: boolean, amount: number}[],
    totalFixedCosts: number
}) => {

    const convertToUSD = (amount: number) => {
        return rate ? amount/rate : 0
    }

    return (
        <table className="app-table">
            <thead>
                <tr>
                    <th className="leftalign" style={{width: '44%'}}>Fixed Cost</th>
                    <th className="leftalign" style={{width: '28%'}}>{currencySymbol ?? currency}</th>
                    <th className="leftalign" style={{width: '28%'}}>USD</th>
                </tr>
            </thead>
            <tbody>
                {fixedCosts.map((fixedCost, index) => 
                    fixedCost.has
                    ?   <tr key={index}>
                            <th className="leftalign">
                                <p>{fixedCost.name}</p>
                            </th>
                            <th className="leftalign error">
                                <p>{currencySymbol ?? currency} {pnlAmountFormatter(fixedCost.amount)}</p>
                            </th>
                            <th className="leftalign error">${pnlAmountFormatter(convertToUSD(fixedCost.amount))}</th>
                        </tr>
                    :   null)}

                <tr>
                    <th className="leftalign">TOTAL FIXED COSTS</th>
                    <th className="leftalign error">{currencySymbol ?? currency} {pnlAmountFormatter(totalFixedCosts)}</th>
                    <th className="leftalign error">${pnlAmountFormatter(convertToUSD(totalFixedCosts))}</th>
                </tr>
            </tbody>
        </table>
    )
}

export default ViewFixedCostsTable