import AdminOffers from "../user-panels/admin/admin-panel-screens/offers/AdminOffers"
import ArtistOffers from "../user-panels/artist/offers/ArtistOffers"
import PromotorOffers from "../user-panels/promotor/offers/tab/PromotorOffers"
import { useAppSelector } from "../utils/hooks/reduxTypedHooks"
import { Licence } from "../utils/models/client.model"
import AccessDeniedPage from "./AccessDeniedPage"

const Offers = () => {
    const licence = useAppSelector(state => state.client?.licence)

    if(licence === Licence.Admin){
        return (<AdminOffers />)
    } else if (licence === Licence.Promotor){
        return (<PromotorOffers />)
    } else if (licence === Licence.Artist) {
        return (<ArtistOffers />)
    }
    else {
        return (<AccessDeniedPage />)
    }
}

export default Offers