import moment from "moment"
import { useState } from "react"
import { createPortal } from "react-dom"
import Dialog from "../../../../../components/dialog/Dialog"
import { offerDraftsActions } from "../../../../../store/offerDraftsSlice"
import { useAppDispatch, useAppSelector } from "../../../../../utils/hooks/reduxTypedHooks"
import useFetchApiAuth from "../../../../../utils/hooks/useFetchApiAuth"
import useSetSnack from "../../../../../utils/hooks/useSetSnack"
import { OfferDraft } from "../../../../../utils/models/offer.model"
import { countries } from "../../../../../utils/models/touring.model"
import OfferForm from "../../form/OfferForm"

const OfferDraftItemRow = ({ draft } : { draft: OfferDraft }) => {

    const [isPosting, setIsPosting] = useState(false)
    const [offerOpened, setOfferOpened] = useState(false)
    const artists = useAppSelector(state => state.artists)
    const client = useAppSelector(state => state.client)

    const promotor = client?.promotor
    const artist = artists.find(artist => artist.id === draft.artistId)

    const fetchApiAuth = useFetchApiAuth()
    const setSnack = useSetSnack()
    const dispatch = useAppDispatch()

    if(!artist) {
        return <tr><th>Artist not found</th></tr>
    }
    if(!promotor) {
        return <tr><th>Promotor not found</th></tr>
    }

    const openOffer = () => setOfferOpened(true)
    const closeOffer = () => setOfferOpened(false)

    const rootModalDiv = document.getElementById('modal-root')!

    const draftCountries = draft.shows.map(show => countries[show.country])

    const handleDelete = async () => {
        setIsPosting(true)

        const { error, response } = await fetchApiAuth({
            method: 'POST',
            route: 'delete-offer-draft',
            body: { id: draft.id }
        })

        if(error) {
            setSnack({type: 'error', content: error.toString()})
        } else {
            setSnack({type: 'success', content: `Draft for ${draft.artistName} deleted successfully`})
            dispatch(offerDraftsActions.delete({id: draft.id}))
        }

        setIsPosting(false)
    }

    const handleContinueDraft = () => {
        openOffer()
    }


    return (
        <>
            {createPortal(
                <Dialog
                    visible={offerOpened}
                    onClose={closeOffer}
                    backdropClose={false}
                    dialogCustomStyle={{overflowY: 'scroll', height: '90vh', top: '5vh', width: '90%', left: '5%'}}
                    notAllSpace={true}
                >
                    <OfferForm 
                        artist={artist}
                        promotor={promotor}
                        closeForm={closeOffer}
                        offerDraft={draft}
                    />
                </Dialog>, rootModalDiv)}
                
            <tr>
                <th className="fw400 leftalign">{moment(new Date(draft.createdOn)).format('MM/D/YYYY h:mm A')}</th>
                <th className="fw400 leftalign">{draft.artistName}</th>
                <th className="fw400 leftalign">{draft.shows.length}</th>
                <th className="fw400 leftalign">{draftCountries.join(' - ')}</th>
                <th><button className="button button--primary button--round" onClick={handleContinueDraft}>Continue Draft</button></th>
                <th><button className="button button--danger button--round" disabled={isPosting} onClick={handleDelete}>{isPosting ? "Deleting..." : "Delete"}</button></th>
            </tr>
        </>
    )
}

export default OfferDraftItemRow