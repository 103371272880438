import { useEffect, useRef, useState } from "react"
import { ClientTask } from "../../../utils/models/client.model"
import NotificationsList from "./NotificationsList"
import { MdNotifications } from 'react-icons/md';

import "./notifications.css"

const ProfileNotifications = ({ notifications }: { notifications: ClientTask[]}) => {
    const [isOpen, setIsOpen] = useState(false)
    const ref = useRef<HTMLDivElement>(null)


    const closeDropdown = () => setIsOpen(false)
    const toggleDropdown = () => setIsOpen(prevValue => !prevValue)
    
    // close dropdown if click outside
    useEffect(() => {
		const handleClick = (e: any) =>{
			if (ref.current && !ref.current.contains(e.target)) {
				closeDropdown()
			  }

		}
		document.addEventListener("mousedown", handleClick)

		return(() => {
			document.removeEventListener("mousedown", handleClick)
		})
	}, [ref])

    return (
        <div ref={ref} onClick={toggleDropdown} className="relative flex">
            <MdNotifications size={30} className='clickable hoverscale'/>
            {notifications.length > 0 && <div className="notifications-circle-div">
                <div className="notifications-circle clickable"><span>{notifications.length}</span></div>
            </div>}
            {isOpen && <NotificationsList notifications={notifications} />}
        </div>
    )
}

export default ProfileNotifications