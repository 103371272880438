import { GiFireShield } from "react-icons/gi"
import { Flex, Row } from "../../../../components/Containers"
import { accessLevels, errorToSuccessTheme3 } from "../../../../constants"
import { Artist } from "../../../../utils/models/artists.model"
import { Offer } from "../../../../utils/models/offer.model"
import { Promotor } from "../../../../utils/models/promotors.model"
import ViewOfferShowItem from "./ViewOfferShowItem"
import { ls, plural } from "../../../../utils/utils"
import styles from "./offers.module.css"
import moment from "moment"
import { BiImage } from "react-icons/bi"
import InputSpruha from "../../../../components/form/spruha/InputSpruha"
import ReactCountryFlag from "react-country-flag"
import { countries } from "../../../../utils/models/touring.model"


const viewOffer = ( { offer, promotor, artist, isAdmin, close }: {offer: Offer, promotor?: Promotor, artist: Artist, isAdmin?: boolean, close: any}) => {

    const offerCountries = offer.shows.map(show => show.country)
    const uniqueCountries = Array.from(new Set(offerCountries))

    const promotorLevelDisplay = promotor?.accessLevel 
    ?   <div className="flex row justifystart"><GiFireShield size={25} color={errorToSuccessTheme3[promotor.accessLevel - 1]} /> {accessLevels.promotor[promotor.accessLevel]}</div> 
    :   <p className="grey">No level</p>

    return (
        <div className="main-container textAlignStart">
            <h1 className="text-centered margedBot50px">Offer</h1>

            <Row className="space-between margedBot50px fw400 app-card">
                <p><b className="title">{offer.shows.length}</b> {plural('Show', offer.shows.length)}</p>
                <Flex row>
                    <img className={styles.artistImg} src={`${artist.image}`} alt={`${artist.displayableName} logo`} />
                    <p>with <b>{artist.displayableName}</b></p>
                </Flex>
                <Flex row>
                    <span className="margedRight5px">In</span> 
                    {uniqueCountries.map((country, index) => 
                    <div key={index}>
                        <ReactCountryFlag
                            countryCode={country} 
                            style={{
                                lineHeight: '20px',
                                fontSize: '20px',
                                marginRight: '3px'
                            }}
                            title={countries[country]}
                        />
                        <b className="margedRight7px">{countries[country]}</b>
                    </div>
                    )}
                </Flex>
                <p>for <b>${ls(offer.totalAmount)}</b></p>
                <p><i>Valid until :</i> {moment(offer.expiryDate).format('DD MMM YYYY')}</p>
            </Row>

            <div className="margedBot50px app-card">
                <h3 className="bold xlarge capitalize main-color text-shadow margedBot15px">Buyer Informations</h3>
                {promotor
                ?   <div className={styles.viewGrid}>
                        {isAdmin && 
                            <InputSpruha 
                                title="Level"
                                value=''
                                content={promotorLevelDisplay}
                                disabled
                                onChange={() => {}}
                            />}
                        <InputSpruha 
                            title="Name"
                            value={promotor.name}
                            disabled
                            onChange={() => {}}
                        />
                        <InputSpruha 
                            title="Company"
                            value={promotor.cieName}
                            disabled
                            onChange={() => {}}
                        />
                        <InputSpruha 
                            title="Email"
                            value={promotor.email}
                            disabled
                            onChange={() => {}}
                        />
                        <InputSpruha 
                            title="Address"
                            value={promotor.fullAddress}
                            disabled
                            onChange={() => {}}
                        />
                        <InputSpruha 
                            title="Phone"
                            value={promotor.phone}
                            disabled
                            onChange={() => {}}
                        />
                        <InputSpruha 
                            title="Website"
                            value={promotor.website}
                            disabled
                            onChange={() => {}}
                        />
                        <InputSpruha 
                            title="VAT Number"
                            value={promotor.cieId}
                            disabled
                            onChange={() => {}}
                        />
                    </div>
                :   <p className="grey">Promotor not found</p>}
            </div>

            <div className="margedBot20px app-card">
                <h3 className="bold xlarge capitalize main-color margedBot15px">{plural('Show', offer.shows.length)}</h3>
                {offer.shows.map((show, index) => <ViewOfferShowItem show={show} index={index} key={index} />)}
            </div>

            {
                offer.binderLetter?.data
                && <div className="margedBot20px app-card">

                    <Flex className="allwidth alignstart marged-b">
                        <Row>
                            <Flex flex={8} className="alignstart">
                                <h3 className="bold xlarge capitalize theme1 text-shadow margedBot15px">Binder Letter</h3>
                            </Flex>
                            <Flex flex={2}>
                                <button
                                    className="button button--primary button--small offer__action-btn" 
                                    onClick={() => window.open(offer.binderLetter?.url, '_blank')}
                                >Open</button>
                            </Flex>
                        </Row>
                        <table className="app-table allwidth" style={{marginLeft: '5%'}}>
                            <thead>
                                <tr>
                                    <th>From</th>
                                    <th>Date</th>
                                    <th className="last">Image</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    offer.binderLetter.data.signatures?.length
                                    ? offer.binderLetter.data.signatures.map((s:any, si:number) => <tr key={`oblsign_${si}`}>
                                        <td><Flex><b>{s.licence === 'admin' ? 'Primuse' : 'Promotor'}</b><i className="grey">{s.from}</i></Flex></td>
                                        <td>{moment(s.date).format('LLL')}</td>
                                        <td className="last"><BiImage className="clickable theme1fill" size={25} onClick={() => window.open(s.url, '_blank')}/></td>
                                    </tr>)
                                    : <i className="grey">The binder has not been signed</i>
                                }
                            </tbody>
                        </table>
                        
                    </Flex>
                </div>
            }

            <Flex className="margedTop40px margedBot20px">
                <button onClick={close} className="button button--primary">Go back</button>
            </Flex>
        </div>
    )
}

export default viewOffer