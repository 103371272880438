// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs {
    width: 100%;
}
.tab-list {
    /* border-bottom: 1px solid #ccc; */
    padding-left: 0;
    text-align: left;
    font-size: larger;
    text-transform: capitalize;
    margin-top: 15px;
}
  
.tab-list-item {
    border-radius: 6px;
    display: inline-block;
    list-style: none;
    padding: 0.5rem 0.75rem;
    cursor: pointer;
    width: calc(90%/5);
    /* margin-bottom: -1px; */
    text-align: center;
}
.tab-list-item:hover,
.tab-list-item:focus {
    background-color: #2A2E41;
}

.tab-list-item.width-auto {
    width: auto;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
}

.tab-list-active {
    /* background-color: white; */
    /* border: solid #ccc;
    border-width: 1px 1px 1px 1px;
    border-bottom: 1px solid var(--app-background); */
    /* border-top-left-radius: 10px;
    border-top-right-radius: 10px; */
    border-radius: 6px;
    /* color: var(--main-color); */
    
    background-color: var(--main-color);
    color: white;
}
.tab-list-active:hover,
.tab-list-active:focus {
    background-color: var(--main-color);
}

.tab-content {
    /* height: 80vh; */
}
`, "",{"version":3,"sources":["webpack://./src/components/tabs-menu/tabs.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;AACA;IACI,mCAAmC;IACnC,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,0BAA0B;IAC1B,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,gBAAgB;IAChB,uBAAuB;IACvB,eAAe;IACf,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;AACtB;AACA;;IAEI,yBAAyB;AAC7B;;AAEA;IACI,WAAW;IACX,qBAAqB;IACrB,sBAAsB;AAC1B;;AAEA;IACI,6BAA6B;IAC7B;;qDAEiD;IACjD;oCACgC;IAChC,kBAAkB;IAClB,8BAA8B;;IAE9B,mCAAmC;IACnC,YAAY;AAChB;AACA;;IAEI,mCAAmC;AACvC;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".tabs {\r\n    width: 100%;\r\n}\r\n.tab-list {\r\n    /* border-bottom: 1px solid #ccc; */\r\n    padding-left: 0;\r\n    text-align: left;\r\n    font-size: larger;\r\n    text-transform: capitalize;\r\n    margin-top: 15px;\r\n}\r\n  \r\n.tab-list-item {\r\n    border-radius: 6px;\r\n    display: inline-block;\r\n    list-style: none;\r\n    padding: 0.5rem 0.75rem;\r\n    cursor: pointer;\r\n    width: calc(90%/5);\r\n    /* margin-bottom: -1px; */\r\n    text-align: center;\r\n}\r\n.tab-list-item:hover,\r\n.tab-list-item:focus {\r\n    background-color: #2A2E41;\r\n}\r\n\r\n.tab-list-item.width-auto {\r\n    width: auto;\r\n    padding-left: 1.25rem;\r\n    padding-right: 1.25rem;\r\n}\r\n\r\n.tab-list-active {\r\n    /* background-color: white; */\r\n    /* border: solid #ccc;\r\n    border-width: 1px 1px 1px 1px;\r\n    border-bottom: 1px solid var(--app-background); */\r\n    /* border-top-left-radius: 10px;\r\n    border-top-right-radius: 10px; */\r\n    border-radius: 6px;\r\n    /* color: var(--main-color); */\r\n    \r\n    background-color: var(--main-color);\r\n    color: white;\r\n}\r\n.tab-list-active:hover,\r\n.tab-list-active:focus {\r\n    background-color: var(--main-color);\r\n}\r\n\r\n.tab-content {\r\n    /* height: 80vh; */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
