import useFetchApiAuth from "../../utils/hooks/useFetchApiAuth"
import { ArtistFromDB } from "../../utils/models/artists.model"

const UseFetchArtistFromColdDB = () => {
    const fetchApiAuth = useFetchApiAuth()

    const fetchArtist = async (fetchName: string) => {
        const {error, response} = await fetchApiAuth(
            {
                method: 'GET',
                route: `cold-artist?name=${fetchName}`,
            },
            {returnServerError: true}
        )
    
        if(error){
            console.log(error)
            return null
        } else {
            return response as ArtistFromDB
        }
    }

    return fetchArtist
}

export default UseFetchArtistFromColdDB