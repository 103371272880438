import { useState } from "react"
import Axios from 'axios';
import { BiPlusCircle } from "react-icons/bi"
import { GrDocument } from "react-icons/gr"
import { useOutletContext } from "react-router-dom"
import Select from "react-select"
import Accordion from "../../../../../components/accordion/Accordion"
import { Flex, Row } from "../../../../../components/Containers"
import Dialog from "../../../../../components/dialog/Dialog"
import Dropzone from "../../../../../components/Dropzone"
import InputSpruha from "../../../../../components/form/spruha/InputSpruha"
import useHasClientAccessToAssets from "../../../../../utils/hooks/useCheckClientAccessToAssets"
import { b64toBlob, promisify } from "../../../../../utils/utils"
import { ArtistDetailsContext } from "../ArtistDetails"
import TourAssets from "./TourAssets"
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { primuseApi } from "../../../../../constants";
import moment from "moment";
import { artistsActions } from "../../../../../store/artistsSlice";
import useSetSnack from "../../../../../utils/hooks/useSetSnack";
import { clientActions } from "../../../../../store/clientSlice";
import { ImDownload, ImNewTab } from "react-icons/im";



const assetTypesOptions = [{label: 'Technical', value: 'technical'}, {label: 'Rider', value: 'rider'}, {label: 'Material', value: 'material'}, {label: 'Travel Party', value: 'travelParty'}, {label: 'Other', value: 'other'}]

const ArtistDetailsAssets = () => {
    const dispatch = useDispatch();
    const setSnack = useSetSnack();

    const client = useSelector((state:RootState) => state.client)

    const [newAssetDialogVisible, setNewAssetDialogVisible] = useState(false);
    const [newAsset, setNewAsset] = useState({ });
    const showAssetDialog = () => setNewAssetDialogVisible(true);
    const hideAssetDialog = () => {
        setNewAssetDialogVisible(false);
        setNewAsset({});
    }

    const selectNewAssetType = (e:any) => setNewAsset({...newAsset, type: e.value});
    const setNewAssetFile = ({acceptedFile, imageDatas}:any) => setNewAsset({...newAsset, file: acceptedFile, imageDatas});


    const renderAsset = (name:string, mimetype:string, source:any, _size?:number) => {
        console.log('renderAsset', {name, mimetype, _size, source: source.slice(0, 40)})
        return <Flex>
            {_size && <h4 className="margedTop20px">{name}</h4>}
            {_size && <h6 className="grey margedBot20px">{_size / 1000} Ko</h6>}
            {
                
                mimetype?.includes('image') // @ts-ignore
                ? <img src={source} style={{width:"30vw", height:"40vh", objectFit: 'contain'}} />
                : (
                    (mimetype?.includes('text') || mimetype?.includes('pdf'))   // @ts-ignore
                    ? <iframe style={{width:"40vw", height:"45vh"}} src={source} />
                    : <GrDocument size={50} className="marged-v" /> // @todo: icon depending on asset extension
                )
            }
        </Flex>
    }

    // @ts-ignore
    const arrayBufferFromUrl = (url, returnBase64, mimetype) => new Promise(async(resolve, reject) => {
        try {
            if (!url) reject('missing source url');
            console.log('arrayBufferFromUrl, url', url);
            const safeUrl = await Promise.resolve(
                url.replace('%C3%A9', 'é').replace('%2F','/')
            );
            // @ts-ignore
            const response = await axios.get(safeUrl, { responseType: "arraybuffer", responseEncoding: "binary"});
    
            if (response && response.data) {
                if (returnBase64) {
    
                    let b64 = await Promise.resolve(
                        `data:${mimetype||'image/png'};base64, ${Buffer.from(response.data, 'binary').toString('base64')}`
                    );        
                    resolve(b64);
                }
                else resolve(response.data);
            } 
            else reject('cant retrieve source from url')
        }catch(e) {
            reject(e)
        }
        
    });
    

    const downloadAsset = async(url:string, filename: string, extension:string) => {
        try {
            // @TODO: Fix no data issue !
            const response = await fetch(url, { method: 'get', mode: 'no-cors', referrerPolicy: 'no-referrer' });
            console.log('reponse', response)
            const blob = await response.blob();
            console.log('blob?', blob)
            const aElement = document.createElement('a');
            aElement.setAttribute('download', `${filename}.${extension}`);
            const href = URL.createObjectURL(blob);
            aElement.href = href;
            // aElement.setAttribute('href', href);
            aElement.setAttribute('target', '_blank');
            aElement.click();
            URL.revokeObjectURL(href);



        }catch(err) {
            setSnack({type: 'error', content: 'Error while downloading the asset, please contact us'})
        }
    }
   
    // @ts-ignore
    const renderAssetOptions = (url:string, name:string, extension:string) => (<Flex>
        <Flex className="clickable hoverscale" onClick={() => window.open(url, '_blank')}>
            <div className="capitalize">fullscreen</div>
            <ImNewTab size={60} />
        </Flex> {/* @ts-ignore */}
        
        <Flex className="clickable hoverscale margedTop50px" onClick={() => downloadAsset(url, name, extension)}>
            <div className="capitalize">download</div>
            <ImDownload size={60} />
        </Flex>

       
        
    </Flex>)

    

    const renderSelectedAsset = () => {
        // @ts-ignore
        const {name, type, size, path} = newAsset.file;   // @ts-ignore
        console.log('newAsset.file', newAsset.file)
        // @ts-ignore                console.log('newAsset.file', newAsset.file);
        return renderAsset(name||path, type, newAsset.imageDatas?.source, size)
       

    }

/**
 * @dev request to `/add-artist-asset/:artistId/:assetType/:assetName`
 */
    const addNewAssetToArtist = async() => {
                                                                    // @ts-ignore
        const pathSplit = newAsset.file.path.split('.');            // @ts-ignore
        const type = newAsset.type;                                 // @ts-ignore
        const name = newAsset.name || newAsset.file.name;           // @ts-ignore
        const extension = pathSplit[pathSplit.length-1];            // @ts-ignore
        const mimetype = newAsset.file.type;                        // @ts-ignore

        let fd = new FormData();                                                             // @ts-ignore
        const blob = await b64toBlob(newAsset.imageDatas.source.split(',')[1], mimetype);    // @ts-ignore
        fd.append('asset', blob, `${artist.code}-${name}.${extension}`);

        // store signature to contract using axios
        Axios.interceptors.request.use((config) => {                // @ts-ignore
            config.headers['X-Auth-Token'] = client?.token; 
            return config;
        });

        try {
            // const apiEndpoint = await promisify(`${primuseApi}/add-artist-asset/${artist.id}/${type}/${name}/${extension}/${mimetype}`);
            const apiEndpoint = await promisify(`${primuseApi}/add-artist-asset/${artist.id}/${type}/${name}?mimetype=${mimetype}&extension=${extension}`);
                                                                    // @ts-ignore
            const axiosResponse = await Axios({
                method: "post", 
                url: apiEndpoint,
                data: fd,   
                config: { headers: { "Content-Type": "multipart/form-data" } }
            });

            console.log('axiosResponse', axiosResponse)
            
            if (axiosResponse.data?.id === artist.id) {
                dispatch(artistsActions.merge(axiosResponse.data))
                hideAssetDialog()
                setSnack({
                    type: 'success',    // @ts-ignore
                    content: `A ${newAsset.type} document has been stored for ${artist.displayableName}`
                })
            }
        }catch(axiosError) {    // @ts-ignore
            const {status} = axiosError.response;

            if (status === 511) {
                dispatch(clientActions.disconnect());
                setSnack({ type: 'warning', content: "Your session has expired, please login" });
            }
        }
    }
    

    // @ts-ignore
    const selectedAssetType = (assetTypesOptions.find(o => o.value === newAsset.type) || {label:''}).label.toLowerCase();
    
    const { artist, artistWithTours } = useOutletContext<ArtistDetailsContext>()
    const hasAccess = useHasClientAccessToAssets(artist.id)


    if(!hasAccess) {
        return (
            <Flex className="allspace margedTop50px">
                <h3>Acces Denied</h3>
            </Flex>
        )
    
    }
    if(!artistWithTours) {
        return (<Flex className="allspace margedTop50px"><h3>Not a Primuse Artist</h3></Flex>)
    }

    const { tours } = artistWithTours;
    const upcomingTours = tours.filter(tour => !tour.isPast)
    console.log('artist.assets', artistWithTours.assets);

    return (
        <div className="main-container leftalign">
            <h2 className="margedBot20px">Assets</h2>

            {/* General Assets */}
            <div className="margedBot100px">
                <Row className="title-background-div margedBot20px allwidth">
                    <Flex flex={10} className="allwidth">
                        <h3>General Assets</h3>
                    </Flex>
                    <Flex flex={0} className="selfend paddedRight20px clickable">
                        {   // @ts-ignore
                            (client.licence === 'admin' || client.licence === 'artist' || client.licence === 'manager') 
                            && <BiPlusCircle size={20} onClick={showAssetDialog} />
                        }
                    </Flex>
                </Row>

              

                <Flex>
                    {   /* @ts-ignore */
                        artistWithTours.assets?.length && artistWithTours.assets.map((asset, assetIndex) => <Accordion 
                            key={`art_asset_${assetIndex}`}
                            className='allwidth'
                           
                            title={<Row className="allwidth justifystart spacebetween">
                                <div className="uppercase">{ asset.type }</div>
                                
                                <div className="">{ asset.name }</div>
                                <div className="grey">{ moment(asset.date).format('DD MMMM YYYY') }</div>
                            </Row>}
                            content={<Row>
                                <Flex flex={5}>
                                    { renderAsset(asset.name, asset.mimetype, asset.url) }
                                
                                </Flex>
                                <Flex flex={5}>
                                    { renderAssetOptions(asset.url, asset.name, asset.extension) }
                                </Flex>
                            </Row>}
    
                        />)
                    }
                </Flex>
            </div>

            {/* Show Assets */}
            <div>
                <div className="title-background-div margedBot20px">
                    <h3>Show Assets</h3>
                </div>
                <div>
                    {upcomingTours.map((tour, index) => <TourAssets tour={tour} key={index} />)}
                </div>
            </div>

            <Dialog
                visible={newAssetDialogVisible}
                onClose={hideAssetDialog}
                dialogCustomStyle={{ padding: '0 40px', width: '70%', left: '15%'}}
            >
                <Flex className="allspace justifystart">
                    <h1 className="padded">Upload a New Asset to {artist.displayableName}</h1>
                    <div className="divider v-marged"></div>
                    <Row className="allwidth">
                        <Flex flex={5} className="alignstart allwidth">
                            <h3 className="selfstart">Select an asset type :</h3>
                            <Select
                                placeholder={`Choose one asset option`}
                                className="allwidth selfstart"
                                options={assetTypesOptions}
                                onChange={selectNewAssetType} 
                                /* @ts-ignore */
                                value={assetTypesOptions.find(o => o.value === newAsset.type)}
                            />
                            {/* @ts-ignore */}
                            {newAsset.type && 
                                <>
                                    <h3 className="selfstart">Asset Name :</h3>
                                    <InputSpruha
                                        title=''
                                        // @ts-ignore
                                        value={newAsset.name}
                                        onChange={(e:any) => setNewAsset({...newAsset, name: e.target.value})}
                                        className="allwidth"

                                    />

                                </>
                            }
                            
                        </Flex>
                        <Flex flex={5} className="allspace">
                            {   // @ts-ignore
                                newAsset.type && <Dropzone
                                    /* @ts-ignore */
                                    title={`Select or Drop your ${selectedAssetType} document here`} 
                                    setFile={setNewAssetFile} 
                                    accept={'*'} 
                                    
                                />
                            }
                            
                            
                        </Flex>
                        
                    </Row>

                    
                    {/* @ts-ignore */}
                    {newAsset.file && <Flex className="allwidth">
                        { renderSelectedAsset() }
                    </Flex>}

                    {   // @ts-ignore
                        newAsset.type && <button className="app-button margedTop50px" onClick={addNewAssetToArtist}>Add {selectedAssetType}</button>
                    }
                </Flex>
            </Dialog>
        </div>
    )
}

export default ArtistDetailsAssets