import moment from "moment"
import { SeatgeekEvent, StandardizedEvent } from "../../../../../utils/models/artists.model"
import { countries } from "../../../../../utils/models/touring.model"

const OutsideTourItem = ({ event }: { event: SeatgeekEvent| StandardizedEvent}) => {
    const date = new Date(event.datetime_local)
    const day = moment(date).format('D')
    const month = moment(date).format('MMM')
    const {name: venueName, country, city} = event.venue
    // const displayedCountry = countries[country] ?? country
    const averagePrice = event.stats?.average_price
    const isFromSeatgeek = event.fromSeatgeek
    const { url } = event

    return (
        <a href={url} target="_blank" rel="noreferrer noopener" className="touring__list-item__border" style={{display: 'block'}}>
            <div className="hover-medium-scale-shadow" style={{padding: '20px 15px'}}>
                <div className="flex-align-center row">
                    <div className="flex-only column width10 textAlignStart">
                        <span className="tour__text-day">{day}</span>
                        <span className="tour__text-month">{month}</span>
                    </div>
                    <div className="width30 textAlignStart">
                        {venueName}
                    </div>
                    <div className="width30 textAlignStart flex-align-center">
                        {averagePrice ? `Average Ticket Price : $${averagePrice}` : ''}
                        {isFromSeatgeek
                        &&  <img src="/SeatGeek_Secondary.png" alt="seatgeek logo" style={{height: "15px", width: "21px", marginLeft: "10px"}} />}
                    </div>
                    <div className="width30 textAlignEnd ">
                        {`${city}, ${countries[country]}`}
                    </div>
                </div>
            </div>
        </a>
    )
}

export default OutsideTourItem