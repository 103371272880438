import { Row } from "../../../../components/Containers"
import IconifyIcon from "../../../../components/Icon/IconifyIcon"
import { Client } from "../../../../utils/models/client.model"
import { Promotor } from "../../../../utils/models/promotors.model"
import { countries } from "../../../../utils/models/touring.model"
import styles from "./promotor-profile.module.css"

const ProfileHeader = ({ promotor, client }: {promotor: Promotor, client: Client}) => {

    const { logo, name, email, cieName} = promotor

    return (
        <div className="app-card app-card-no-padding">
            <div className="relative">

                <div className={styles.background}></div>

                <div className="card-padding">
                    <div className={styles.headerPictureWrapper}>
                        <div className={styles.avatar}>
                            <div className={styles.image_div}>
                                {logo 
                                ?   <img src={logo} alt="profile" className={styles.profile_image} />
                                :   <img src="/blank-profile-picture.png" alt="blank profile" className={styles.profile_image} />}
                            </div>
                        </div>
                    </div>

                    <div className="leftalign" style={{paddingLeft: "150px"}}>
                        <h3 className="font20 title-color margedBot10px">{name}</h3>
                        <div className="flex-align-center row space-between">
                            <div className="font16">
                                <Row>
                                    {cieName !== undefined 
                                    &&  <div className="flex-only margedRight10px">
                                            <IconifyIcon icon="tabler:briefcase" fontSize={20} />
                                            <p className="margedLeft5px">{cieName}</p>
                                        </div>}

                                    {client.country !== undefined
                                    &&  <div className="flex-only margedRight10px">
                                            <IconifyIcon icon="tabler:flag" fontSize={20} />
                                            <p className="margedLeft5px">{countries[client.country]}</p>
                                        </div>}

                                    <div className="flex-only margedRight10px">
                                        <IconifyIcon icon="tabler:calendar" fontSize={20} />
                                        <p className="margedLeft5px">Date created</p>
                                    </div>
                                </Row>
                            </div>

                            <button className="button button--primary fontw500 uppercase">Connected</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProfileHeader