import { Offer } from "../../../../utils/models/offer.model"
import OffersTable from "./OffersTable"

const OffersTab = ({ offers, title, ongoing }: { offers: Offer[], title: string, ongoing?: boolean }) => {

    return (
        <div className='main-container margedBot100px paddedTop30px'>
            <h3 className="page-title">{title} Offers</h3>

            <OffersTable offers={offers} ongoing={ongoing} />

        </div>
    )
}

export default OffersTab