import { accessLevels, colors } from "../../constants";
import { PlatformInfo } from "./artists.model";
import { AppColor } from "./common.model";
import { Contract } from "./contracts.model";
import { UserCreateObject } from "./users.model";

export interface Promotor {
    id:string;
    isArchived: boolean;
    name: string;
    createdOn: number;    //Date().getTime()
    email?: string;
    clientId?: string;
    fullAddress?: string;
    logo?: string;
    cieId?: string;     // VAT Number
    cieName?: string;
    website?: string;
    accessLevel: keyof typeof accessLevels.promotor;
    socials?: Record<string, PlatformInfo>;
    contracts?: Contract[];
    artists?: string[];         // artists IDs
    offers?: string[];      // offers IDs
    bookingAgenciesReferences?: string[];
    pastBookedArtists?: string[];   // artists IDs
    pastShows?: any[];                  // todo: define Show model ?
   
    customEvents?: PromotorEvent[]
    phone?: string;
    country: string;
    comments?: string;
    mediaLinks?: any;

    createNewAccount?: undefined
};

export interface PromotorCreateObject extends Omit<Promotor, "id"|"createdOn"> {
    newUser?: UserCreateObject
}

export interface PromotorFormInputs {
    name: string;
    email: string;
    logo: string;
    accessLevel: {value: keyof typeof accessLevels.promotor, label: any};
    cieName: string;
    cieId: string;
    phone: string;
    country: string;
    website: string;
    fullAddress: string;
    socials: Record<string, PlatformInfo>;
    comments: string;
    createNewAccount: boolean;
    clientId: string;
    newAccountEmail: string
}

export interface PromotorEvent {
    id: string;
    title: string;
    from: string;
    to: string;
    allDay: boolean;
    label: PromotorEventLabel;
}
export type PromotorEventCreateObject = Omit<PromotorEvent, "id">
export interface PromotorEventMergeObject extends Partial<PromotorEvent> {
    id: string
}

export enum PromotorEventLabel {
    preparation = "Preparation",
    business = "Business",
    other = "Other",
}
export const promotorEventLabelsList = Object.values(PromotorEventLabel)
export const promotorEventsLabelsColors: Record<string, AppColor> = {
    Preparation: 'lightblue',
    Business: 'orange',
    Other: 'red',
}
export const promotorEventsBgColors = {
    Preparation: colors.bgLightblue,
    Business: colors.bgOrange,
    Other: colors.bgRed,
}

export const promotorEventsRecurringTitles = ["Prepare the venue", "Meet with the artist", "Task xyz"]