import { countries, TouringDay, TouringDayWithTouringId } from "../../../../../utils/models/touring.model"
import moment from 'moment'
import { Flex } from "../../../../../components/Containers"
import { ls, plural } from "../../../../../utils/utils"
import ReactCountryFlag from "react-country-flag"
import useIsAdmin from "../../../../../utils/hooks/useIsAdmin"
import { IoMdCheckmark } from "react-icons/io"
import useIsPromotor from "../../../../../utils/hooks/useIsPromotor"
import useSetSnack from "../../../../../utils/hooks/useSetSnack"

//! styles in user-panels/admin/admin-panels-screens/artists/artists.css

const TourItem = (
    { 
        tour,
        dayIndex,
        selectedIndexes,
        toggleDay,
        openOfferForm,
    } : 
    { 
        tour: TouringDayWithTouringId, 
        dayIndex: number, 
        selectedIndexes: boolean[],
        toggleDay: (dayIndex: number) => void, 
        openOfferForm: Function,
    }) => {

        
    const isAdmin = useIsAdmin()
    const isPromotor = useIsPromotor()
    const setSnack = useSetSnack()

    const handleToggleInput = (dayIndex: number) => {
        toggleDay(dayIndex)
    }

    const handleBookDay = (tourDay: TouringDay) => {
        if(!isPromotor) {
            setSnack({type: "warning", content: "Only a Kisum Promotor can book a show"})
            return
        }
        openOfferForm([tourDay])
    }

    const isAlreadySelected = selectedIndexes[dayIndex]

    const availabilityClass = tour.blocked.to !== '' ? 'artist-details__tour-day-blocked' : 'artist-details__tour-day-available'

    return (
        <div className={`touring__list-item ${availabilityClass}`}>

            <Flex className="margedBot5px">
                <p><b>{moment(new Date(tour.date)).format('dddd, MMMM Do')}</b></p>
            </Flex>

            <Flex row className="space-between">
                <div className="width80 leftalign">
                    <div className="booking-card__grid">

                        <div>
                            <ReactCountryFlag
                                countryCode={tour.country} 
                                style={{
                                    lineHeight: '20px',
                                    fontSize: '20px',
                                    marginRight: '3px'
                                }}
                                title={countries[tour.country]}
                            />
                            {countries[tour.country]}
                        </div>

                        <p className="fw400">Set duration : {tour.showDuration} Minutes</p>

                        {isAdmin
                        &&  <p className="fw400">Possible fee : {ls(tour.possibleFee)}</p>}

                    </div>

                </div>

                {tour.blocked.to 
                ?   <p className="error margedBot5px">Already blocked</p>
                :  <Flex>
                        <button onClick={()=> handleBookDay(tour)} className="button button--primary allwidth margedBot10px" style={{width: '100px'}}>
                            Book
                        </button>
                        {isAlreadySelected
                        ?   <div  className="green-color">
                                <span className="margedRight5px">Added</span>
                                <IoMdCheckmark />
                            </div>
                        :   <button onClick={() => handleToggleInput(dayIndex)} className="button button--primary allwidth">
                                Add to List
                            </button>}
                    </Flex>}

            </Flex>

        </div>
    )
}
export default TourItem