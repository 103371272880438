import AdminVenues from "../user-panels/admin/admin-panel-screens/venues/AdminVenues"
import { useAppSelector } from "../utils/hooks/reduxTypedHooks"
import { Licence } from "../utils/models/client.model"
import AccessDeniedPage from "./AccessDeniedPage"

const VenuesPage = () => {
    const licence = useAppSelector(state => state.client?.licence)

    if(licence === Licence.Admin ){
        return (<AdminVenues />)
    } else {
        return (<AccessDeniedPage />)
    }
}

export default VenuesPage