
const LoginIllustration1 = () => {

    return (
        <svg width="239" height="234" viewBox="0 0 239 234" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="88.5605" y="0.700195" width="149" height="149" rx="19.5" stroke="#7367F0" strokeOpacity="0.16"/>
<rect x="0.621094" y="33.761" width="200" height="200" rx="10" fill="#7367F0" fillOpacity="0.08"/>
</svg>
    )
}

export default LoginIllustration1