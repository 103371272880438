import { MouseEventHandler, useRef } from "react";
import { AiFillWarning, AiOutlineCloseCircle, AiOutlineMail } from "react-icons/ai";
import { Flex, Row } from "../../../../../components/Containers";
import Carousel from "../../../../../components/carousel/Carousel";
import Tabs from "../../../../../components/tabs-menu/Tabs";
import { Promotor } from "../../../../../utils/models/promotors.model";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import { ROUTE_ARTISTS } from "../../../../../routes/routes.const";
import { useNavigate } from "react-router-dom";
import { IoIosPeople } from "react-icons/io";
import { FaAddressCard, FaHandshake, FaRegAddressCard } from "react-icons/fa";
import { theme } from "../../../../../constants";
import { ls } from "../../../../../utils/utils";
import { BsImage, BsShieldFillCheck } from "react-icons/bs";
import { TfiFiles } from "react-icons/tfi";
import Chart from "../../../../../components/Charts/Chart";
import moment from "moment";
import { BiCheckCircle, BiLinkAlt, BiUserCheck, BiUserCircle } from "react-icons/bi";
import { formatDisplayableNameToUrl } from "../../../../../utils/artists.util";

interface PromotorDetailsProps {
    promotor: Promotor;
    close: MouseEventHandler<SVGElement>;
}

const PromotorDetails = (props:any/*PromotorDetailsProps*/) => {
    const {
        id,
        name,
        email,
        website,
        artists,
        contracts,
        clientId,
        logo,
        cieName,
        cieId,
        fullAddress,


    } = props.promotor;

    const navigate = useNavigate();
    const globalContracts = useSelector((state:RootState) => state.contracts);
    const users = useSelector((state:RootState) => state.users);
    const actorClient = clientId && users.find((u:any) => u.id === clientId);
    const licence = actorClient?.licence || 'No Licence'

    // list received upon connection
    const globalArtists = useSelector((state:RootState) => state.artists);
    const artistsList = globalArtists?.length ? globalArtists.filter((a:any) => ((artists||[]).includes(a.id))) : [];
    const promotorContracts = globalContracts.filter((c:any) => (contracts?.length && contracts.includes(c.id)));

    const totalAmount = (
        promotorContracts?.length
        ? promotorContracts
            .map((c:any) => (
                c.payments?.length
                ? c.payments
                    .map((p:any) => parseInt(p.amount))
                    .reduce((a:any, b:any) => (a + b))
                : 0
            ))
            .reduce((a:any, b:any) => (a + b))
        : 0
    )

    return <Flex>
        <h2 className="theme1bg allwidth"> 
            <Row> {name||email} &nbsp; <AiOutlineCloseCircle className="clickable hoverscale" size={25} onClick={props.close} /></Row>
            <div className="small grey">{id}</div>
        </h2>
        <Tabs activeTab='dashboard'>
            <div option-data='dashboard'>
                <Flex className="allspace justifystart selfstart padded" style={{background: 'whitesmoke'}}>
                    <Flex row className="allwidth spacebetween">
                        <Flex row className="item-card clickable hoverscale nomargin noborder box-shadow" style={{background: theme[3], height: '15vh'}}>
                            <Flex flex={3} className='bordered'><TfiFiles color='white' size={40} style={{padding: 5}} /></Flex>
                            <Flex flex={6} className="allwidth alignend white">
                                <div>Total Contracts</div>
                                <div className="xxlarge">{contracts?.length||0}</div>
                            </Flex>
                        </Flex>

                        <Flex row className="item-card clickable hoverscale nomargin noborder box-shadow" style={{background: theme[4], height: '15vh'}}>
                            <Flex flex={3} className='bordered'><FaHandshake color='white' size={40} /></Flex>
                            <Flex flex={6} className="allwidth alignend white">
                                <div>Total Amount</div>
                                <div className="xxlarge">{ls(totalAmount||0)}</div>
                            </Flex>
                        </Flex>

                        <Flex row className="item-card clickable hoverscale nomargin noborder box-shadow" style={{background: theme[5], height: '15vh'}}>
                            <Flex flex={3} className='bordered'><IoIosPeople color='white' size={40} /></Flex>
                            <Flex flex={6} className="allwidth alignend white">
                                <div>Total Artists</div>
                                <div className="xxlarge">{artists?.length||0}</div>
                            </Flex>
                        </Flex>
                        
                        <Flex row className="item-card clickable hoverscale nomargin noborder box-shadow" style={{background: theme[6], height: '15vh'}}>
                            <Flex flex={3} className='bordered'><BsShieldFillCheck color='white' size={40} style={{padding: 5}} /></Flex>
                            <Flex flex={6} className="allwidth alignend white">
                                <div>Licence</div>
                                <div className="xxlarge">{licence}</div>
                            </Flex>
                        </Flex>
                    </Flex>
                    <Flex row className="allspace margedTop justifystart spacebetween">
                        <Flex 
                            className="selfstart white-container padded allheight justifystart width20vw" 
                            flex={2} 
                        >
                            {
                                logo
                                ? <img className="app-logo" alt='' src={logo} style={{height: '12vmin', width: '12vmin', objectFit: 'contain', border: `5px solid ${theme[0]}`, borderRadius: '50%', padding: '1%'}}/>
                                : <BsImage color="grey" size={70} />
                            }
                            <div className="title">{cieName}</div>
                            <i className="grey smaller">{cieId}</i>
                            <Flex>
                                <Row><AiOutlineMail />&nbsp;{email}</Row>
                                {fullAddress && <Row><FaRegAddressCard />&nbsp;{fullAddress}</Row>}
                                {website && <Row><BiLinkAlt />&nbsp;{website}</Row>}
                            </Flex>
                            <div className="divider margedTop10" />
                            <Flex row className="allwidth justifystart">
                                <Flex flex={4}>
                                    <Chart
                                        //title="Trust Score"
                                        autoColors
                                        key='trust_chart'
                                        size='5vw'
                                        data={{
                                            datasets: [
                                                {
                                                    data: [30, 100-30],
                                                    //backgroundColor: [theme[4], 'transparent'],
                                                    borderColor: theme[0],
                                                    borderWidth: 1,
                                                }
                                            ]
                                        }}
                                    />
                                </Flex>
                                <Flex flex={6} className=" bold theme2">Trust Score <div className="black">35 %</div></Flex>
                            </Flex>
                            
                            <div className="divider" />
                            <Flex row className="allwidth">
                                <Flex flex={4}>
                                    <BiUserCheck size={50} />
                                </Flex>
                                <Flex flex={6} className="">
                                    <div className=" bold theme2 margedTop">Primuse Account</div>
                                    <b>{ actorClient?.email }</b>
                                    <div>
                                        <Flex row>{ licence } licence</Flex>
                                        &nbsp;
                                        {
                                            (!actorClient || (licence!=='promotor'))
                                            ? <Flex row className="warning">
                                                <AiFillWarning color="orange" size={20} />
                                                BAD LICENCE
                                            </Flex> 
                                            : <BiCheckCircle color="teal" size={20} />
                                        }
                                    </div>
                                </Flex>
                            </Flex>
                        </Flex>
                        <Flex flex={8} className="centered white-container selfstart" style={{flex: .75}}>
                            <Chart 
                                noLegend
                                line
                                key='amounts_line_chart'
                                title="Promoted Live Show Performances"
                                datasetIdKey={'id'}
                                data={{
                                    labels: promotorContracts?.map((c:any) => (`${moment(c.date).format('DD/MM/YYYY')}, ${c.artist.name}`)),
                                    datasets: [
                                        {
                                            id: 1,  // datasetIdKey
                                            label: ': Contract Amount',//': Contract Amounts',
                                            data: promotorContracts?.map((c:any) => (
                                                c.payments?.map((p:any) => parseInt(p.amount)).reduce((a:any, b:any) => a+b)
                                                //new Date(c.date).getTime()
                                            )),
                                            /// render colors
                                            backgroundColor: theme,
                                            borderColor: theme,
                                            borderWidth: 1,
                                        },
                                    ],
                                }}
                                width='65vw'
                                height='30vh'
                                
                            />
                        </Flex>
                    </Flex>
                    {
                        // Object.keys(props.promotor).map((pk, pki) => <Flex key={`promprop${pki}`} row><div className="title theme1">{pk}</div> : <div>{props.promotor[pk].toString()}</div></Flex>)
                    }
                </Flex>
            </div>
            <div option-data='contracts'>
                <Flex className="allspace">
                    Promotor Contracts
                </Flex>
            </div>
            <div option-data='artists'>
                <Flex className="allspace">
                {
                    artists?.length
                    ? <Carousel 
                        slides={artistsList.map((a, ai) => ({
                            title: a.displayableName,
                            subtitle: <i className="small">Country: {a.country}</i>,
                            description: <i className="small">{`Id #${a.id}`}</i>,
                            image: a.image || a.logo || a.banner,
                            footer: <button className="margedTop10 app-button" onClick={() => navigate(`${ROUTE_ARTISTS}/${formatDisplayableNameToUrl(a.displayableName)}`)}>Open</button>
                        }))}
                    />
                    : <p className="grey">{name} has no artists for now</p>
                }
                </Flex>
            </div>
        </Tabs>
    </Flex>
}

export default PromotorDetails;