import FeatureItem from "../components/FeatureItem"

const Features = () => {

    return (
        <section className="landing-features" id="features">
            <h3 className="landing-title margedBot30px">Features</h3>

            <div className="landing-container">
                <div className="grid-3-col">
                    <FeatureItem 
                        iconName="ic:round-star-outline"
                        title="Research & Analyse"
                        text="Our Kisum application, through the use of our algorithm, generates a comprehensive view of each artist's activity, allowing you to make efficient decisions in booking and organizing concerts."
                    />

                    <FeatureItem 
                        iconName="fluent:people-20-regular"
                        title="Interaction"
                        text="Kisum allows you to make offers and secure your contracts through our blockchain registration and analysis system, providing a secure and efficient way to book and organize concerts."
                    />

                    <FeatureItem 
                        iconName="iconoir:lock"
                        title="Security"
                        text="Kisum ensures the security of all your contract interactions, offers, submissions and soon payments, through the use of blockchain technology, providing a secure and reliable platform for booking and organizing concerts."
                    />

                </div>
            </div>
        </section>
    )
}

export default Features