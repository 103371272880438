import { AiOutlineCloseCircle } from "react-icons/ai"
import LabelInput from "../../../../../../components/form/mui/label-input/LabelInput"
import CheckboxSpruha from "../../../../../../components/form/spruha/CheckboxSpruha"
import { colors } from "../../../../../../constants"
import { pnlAmountFormatter } from "../../../../../../utils/utils"

const VariableCostsTable = ({ currency, currencySymbol, rate, variableCosts, updateVariableCost, addVariableCost, removeVariableCost, pourcentagesArray, totalVariableCostArray, totalRevenueArray }: {
    currency: string,
    currencySymbol: null|string,
    rate: null|number,
    pourcentagesArray: number[],
    totalVariableCostArray: number[],
    variableCosts: {name: string, has: boolean, percentage: number}[],
    updateVariableCost: (costIndex: number, value: {name: string, has: boolean, percentage: number}) => void,
    addVariableCost: () => void,
    removeVariableCost: (costIndex: number) => void,
    totalRevenueArray: number[]
}) => {

    const totalCostUSDArray = totalVariableCostArray.map(cost => rate ? cost/rate : 0)

    return (
        <table className="app-table">
            <thead>
                <tr>
                    <th className="leftalign" style={{width: "12%"}}>Variable cost</th>
                    <th style={{width: "4%"}}></th>
                    <th className="leftalign" style={{width: "4%"}}>Tax %</th>
                    {pourcentagesArray.map(pourcentage => <th key={pourcentage}  style={{width: "8%"}} className="leftalign">{pourcentage}%</th>)}
                </tr>
            </thead>
            <tbody>
                {variableCosts.map((variableCost, index) => 
                    <tr key={index}>
                        <th className="leftalign">
                            <div className="flex-align-center row">
                                <div className="allwidth flex-align-center row margedRight10px">
                                    <div className="flex margedRight5px">
                                        <AiOutlineCloseCircle
                                            size={18}
                                            onClick={() => removeVariableCost(index)}
                                            fill={colors.red}
                                            className='clickable'
                                        />
                                    </div>
                                    <LabelInput
                                        title=""
                                        noLabel
                                        value={variableCost.name}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateVariableCost(index, {...variableCost, name: e.target.value})}
                                    /> 
                                    {/* <span className="margedLeft5px">(%)</span> */}
                                </div>
                            </div>
                        </th>
                        <th>
                            <CheckboxSpruha
                                title=""
                                inputOnly
                                checked={variableCost.has}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>)  => updateVariableCost(index, {...variableCost, has: e.target.checked})}
                                label
                            />
                        </th>
                        <th>
                            <LabelInput
                                title=""
                                noLabel
                                type="number"
                                min={0}
                                max={100}
                                value={variableCost.percentage}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateVariableCost(index, {...variableCost, percentage: +e.target.value})}
                            />
                        </th>
                        {pourcentagesArray.map((_, index) => <th key={index} className="leftalign error">{currencySymbol ?? currency} {pnlAmountFormatter(variableCost.has ? (totalRevenueArray[index] * (variableCost.percentage / 100)) : 0)}</th>)}
                    </tr>)}
                <tr>
                    <th>
                        <button className="button button--primary button--round button--small" onClick={addVariableCost}>Add Variable Cost</button>
                    </th>
                </tr>
                <tr>
                    <th className="leftalign">Total Variable Cost ({currencySymbol ?? currency})</th>
                    <th></th>
                    <th></th>
                    {totalVariableCostArray.map((totalVariableCost, index) => <th key={index} className="leftalign error">{currencySymbol ?? currency} {pnlAmountFormatter(totalVariableCost)}</th>)}
                </tr>
                <tr>
                    <th className="leftalign">Total Variable Cost (USD)</th>
                    <th></th>
                    <th></th>
                    {totalCostUSDArray.map((totalVariableCost, index) => <th key={index} className="leftalign error">${pnlAmountFormatter(totalVariableCost)}</th>)}
                </tr>
            </tbody>
        </table>
    )
}

export default VariableCostsTable