// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accordion {
    /* max-width: 600px; */
    margin: 2rem auto;
    width: 100%;
    /* border: 1px solid silver */

}

.accordion-item {
    /* border: 1px solid silver; */
    border: var(--input-border);
    border-radius: 10px;
    width: 100%;
    /* background-color: white; */
    border-top: 0;
}
.accordion-item:first-of-type {
    /* border: 1px solid silver; */
    border-top: var(--input-border);
}
.active-accordion-item {
    /* border-left: 3px solid grey; */
    /* background-color: white; */
    background-color: var(--card-background);
}


.accordion-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    width: 100%;

    /* background-color: #21aeca; */
    /* background-color: white; */
    background-color: var(--card-background);
    border-radius: 10px;
}

.accordion-title:hover {
    /* background-color: rgb(216, 216, 216); */
    /* background-color: rgb(250,250,250); */
    background-color: var(--hover-color);
}

.accordion-title,
.accordion-content {
    padding: 1rem;
    /* border-bottom: 1px solid silver; */
}

.accordion-content {
    /* background-color: #39b9d2; */
    padding-bottom: 2em;

}
  `, "",{"version":3,"sources":["webpack://./src/components/accordion/accordion.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,iBAAiB;IACjB,WAAW;IACX,6BAA6B;;AAEjC;;AAEA;IACI,8BAA8B;IAC9B,2BAA2B;IAC3B,mBAAmB;IACnB,WAAW;IACX,6BAA6B;IAC7B,aAAa;AACjB;AACA;IACI,8BAA8B;IAC9B,+BAA+B;AACnC;AACA;IACI,iCAAiC;IACjC,6BAA6B;IAC7B,wCAAwC;AAC5C;;;AAGA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,eAAe;IACf,WAAW;;IAEX,+BAA+B;IAC/B,6BAA6B;IAC7B,wCAAwC;IACxC,mBAAmB;AACvB;;AAEA;IACI,0CAA0C;IAC1C,wCAAwC;IACxC,oCAAoC;AACxC;;AAEA;;IAEI,aAAa;IACb,qCAAqC;AACzC;;AAEA;IACI,+BAA+B;IAC/B,mBAAmB;;AAEvB","sourcesContent":[".accordion {\r\n    /* max-width: 600px; */\r\n    margin: 2rem auto;\r\n    width: 100%;\r\n    /* border: 1px solid silver */\r\n\r\n}\r\n\r\n.accordion-item {\r\n    /* border: 1px solid silver; */\r\n    border: var(--input-border);\r\n    border-radius: 10px;\r\n    width: 100%;\r\n    /* background-color: white; */\r\n    border-top: 0;\r\n}\r\n.accordion-item:first-of-type {\r\n    /* border: 1px solid silver; */\r\n    border-top: var(--input-border);\r\n}\r\n.active-accordion-item {\r\n    /* border-left: 3px solid grey; */\r\n    /* background-color: white; */\r\n    background-color: var(--card-background);\r\n}\r\n\r\n\r\n.accordion-title {\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: space-between;\r\n    cursor: pointer;\r\n    width: 100%;\r\n\r\n    /* background-color: #21aeca; */\r\n    /* background-color: white; */\r\n    background-color: var(--card-background);\r\n    border-radius: 10px;\r\n}\r\n\r\n.accordion-title:hover {\r\n    /* background-color: rgb(216, 216, 216); */\r\n    /* background-color: rgb(250,250,250); */\r\n    background-color: var(--hover-color);\r\n}\r\n\r\n.accordion-title,\r\n.accordion-content {\r\n    padding: 1rem;\r\n    /* border-bottom: 1px solid silver; */\r\n}\r\n\r\n.accordion-content {\r\n    /* background-color: #39b9d2; */\r\n    padding-bottom: 2em;\r\n\r\n}\r\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
