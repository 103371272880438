import { GrTarget } from 'react-icons/gr';
import { MdOutlineGetApp } from 'react-icons/md';
import React, { useCallback } from 'react'
import {useDropzone} from 'react-dropzone'

/**
 * 
 * @param props
 */
const Dropzone = (props) => {
    const { title, setFile, accept, returnImg } = props;

    const onDrop = useCallback(async(acceptedFiles) => {
        let af = acceptedFiles[0];
       
        const imageDatas = await new Promise((_imageDatas) => {
            const img = new window.Image()
            var fr = new FileReader();

            fr.onload = async() => {
                img.src= fr.result

                // wait for img to be read
                setTimeout(async() => {
                    const imageDatas = await Promise.resolve({
                        name: af.name,
                        source: fr.result,
                        width: ((i) => i.width)(img),
                        height: ((i) => i.height)(img),
                        ...(returnImg ? {img} : {})
                    })
        
                    _imageDatas(imageDatas)
                }, 100)
            }

            fr.readAsDataURL(af);

        })
        
        // Do something with the files
        setFile({acceptedFile: af, imageDatas})
        
    }, [setFile])

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

    return (
        <div id="drop_zone" style={{color: 'var(--text-color)', zIndex: 9999}} {...getRootProps()}>
        <input 
            {...getInputProps()} 
            multiple={false} 
            {
                ...(
                    accept
                    ? {accept}
                    : {}
                )
            }
        />
        {
            isDragActive 
            ? <div className="clickable flex col">
                <GrTarget size={50} />
                <p>Drop here</p>
            </div>
            : <div className="clickable flex col">
                <MdOutlineGetApp size={50} />
                <p>
                    {
                        title
                        || 'Drop your file here or click to select'
                    }
                </p>
            </div>
        }
        </div>
    )
}

export default Dropzone;