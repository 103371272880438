import { AiOutlineCloseCircle } from "react-icons/ai"
import LabelInput from "../../../../../../components/form/mui/label-input/LabelInput"
import CheckboxSpruha from "../../../../../../components/form/spruha/CheckboxSpruha"
import { colors } from "../../../../../../constants"
import { ls, lsToFlat, pnlAmountFormatter } from "../../../../../../utils/utils"

const FixedCostsTable = ({ currency, currencySymbol, rate, fixedCosts, updateFixedCost, addFixedCost, removeFixedCost, totalFixedCosts }: {
    currency: string,
    currencySymbol: null|string,
    rate: null|number,
    fixedCosts: {name: string, has: boolean, amount: number}[],
    updateFixedCost: (costIndex: number, value: {name: string, has: boolean, amount: number}) => void,
    addFixedCost: () => void,
    removeFixedCost: (costIndex: number) => void,
    totalFixedCosts: number
}) => {

    const convertToUSD = (amount: number) => {
        return rate ? amount/rate : 0
    }

    return (
        <table className="app-table">
            <thead>
                <tr>
                    <th className="leftalign" style={{width: '40%'}}>Fixed Cost</th>
                    <th  style={{width: "8%"}}></th>
                    <th className="leftalign" style={{width: '26%'}}>{currencySymbol ?? currency}</th>
                    <th className="leftalign" style={{width: '26%'}}>USD</th>
                </tr>
            </thead>
            <tbody>
                {fixedCosts.map((fixedCost, index) => 
                    <tr key={index}>
                        <th className="leftalign">
                            <div className="flex-align-center row">
                                <div className="flex margedRight10px">
                                    <AiOutlineCloseCircle
                                        size={18}
                                        onClick={() => removeFixedCost(index)}
                                        fill={colors.red}
                                        className='clickable'
                                    />
                                </div>
                                <LabelInput
                                    title=""
                                    noLabel
                                    value={fixedCost.name}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateFixedCost(index, {...fixedCost, name: e.target.value})}
                                /> 
                            </div>
                        </th>
                        <th className="leftalign">
                            <CheckboxSpruha
                                title=""
                                inputOnly
                                checked={fixedCost.has}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>)  => updateFixedCost(index, {...fixedCost, has: e.target.checked})}
                                label
                            />
                        </th>
                        <th className="leftalign error">
                            <LabelInput
                                title=""
                                noLabel
                                value={ls(fixedCost.amount)}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    const flattenedValue = lsToFlat(e.target.value)
                                    if(!isNaN(flattenedValue)) {
                                        updateFixedCost(index, {...fixedCost, amount: flattenedValue})
                                    }
                                }}
                            />
                        </th>
                        <th className="leftalign error">${pnlAmountFormatter(convertToUSD(fixedCost.amount))}</th>
                    </tr>)}

                <tr>
                    <th>
                        <button className="button button--primary button--round button--small" onClick={addFixedCost}>Add Fixed Cost</button>
                    </th>
                </tr>

                <tr>
                    <th className="leftalign">TOTAL FIXED COSTS</th>
                    <th className="leftalign"></th>
                    <th className="leftalign error">{currencySymbol ?? currency} {pnlAmountFormatter(totalFixedCosts)}</th>
                    <th className="leftalign error">${pnlAmountFormatter(convertToUSD(totalFixedCosts))}</th>
                </tr>
            </tbody>
        </table>
    )
}

export default FixedCostsTable