import { useDispatch } from "react-redux"
import { snackActions } from "../../store/snackSlice"

const useSetSnack = () => {
    const dispatch = useDispatch()
    // snack object : {type: 'success'|'warning'|'error', content: 'Message here'}
    const setSnack = (snack: {type: 'success'|'warning'|'error', content: any}) => dispatch(snackActions.set(snack))
    
    return setSnack
}

export default useSetSnack