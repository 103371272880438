import { useEffect, useState } from "react"
import { Flex, Row } from "../../../../components/Containers"
import OfferStep from "./show-steps/OfferStep"
import OtherStep from "./show-steps/OtherStep"
import ShowInfo from "./show-steps/ShowInfoStep"
import TicketsStep from "./show-steps/TicketsStep"
import VenueStep from "./show-steps/VenueStep"
import styles from "../offers.module.css"
import { AiFillCheckCircle } from "react-icons/ai"
import ReactCountryFlag from "react-country-flag"
import { countries } from "../../../../utils/models/touring.model"
import moment from "moment"
import ShowReviewStep from "./show-steps/ShowReviewStep"
import { fetchCountryInfos } from "../../../../api/dirsal/dirsalRequests"
import Dialog from "../../../../components/dialog/Dialog"
import { OfferFormInputs, OfferShowFormInputs } from "../../../../utils/models/offer.model"
import OfferPNL from "./pnl/OfferPNL"

export type ShowsStepsIndexes = 0 | 1 | 2 | 3 | 4 | 5
const ShowItem = ({ offer, os, osi, rates, currencySymbols, setRates, setCurrencySymbols, isReviewing, reviewGoToStep, setOffer, setOfferShow, setShowDealInputs, 
    setShowPnlInputs, setShowTickets, addShowTickets, removeShowTickets, goToNextOfferStep, goBackToReview, isEditingOffer, isEditingDraft }: 
    {
        offer: OfferFormInputs,
        os: OfferShowFormInputs, 
        osi: number,
        rates: (null|number)[],
        currencySymbols: (null|string)[],
        setRates: React.Dispatch<React.SetStateAction<(number | null)[]>>,
        setCurrencySymbols: React.Dispatch<React.SetStateAction<(string | null)[]>>,
        isReviewing: boolean,
        reviewGoToStep: null|ShowsStepsIndexes,
        setOffer: (_props: any) => void,
        setOfferShow: (showIndex: number, showProps: {}) => void, 
        setShowDealInputs: (showIndex: number, props: any) => void,
        setShowPnlInputs: (showIndex: number, props: any) => void,
        setShowTickets: (index: number, ticketsIndex: number, _props: any) => void,
        addShowTickets: (showIndex: number) => void,
        removeShowTickets: (showIndex: number, ticketsIndex: number) => void,
        goToNextOfferStep: () => void,
        goBackToReview: () => void,
        isEditingOffer: boolean,
        isEditingDraft: boolean,
    }) => {
        
    const [step, setStep] = useState<ShowsStepsIndexes>(reviewGoToStep ?? 0)
    const [isLocalReviewing, setIsLocalReviewing] = useState(false)

    const [pnlVisible, setPnlVisible] = useState(false)
    const openPnl = () => setPnlVisible(true)
    const closePnl = () => setPnlVisible(false)

    useEffect(() => {
        const fetchCountryDatas = async () => {
            const response = await fetchCountryInfos(os.country)
            console.log(response)

            const fetchedRate = response?.data?.currency?.exchange.inverseRate
            if(fetchedRate) {
                setRates(prevRates => {
                    const newRates = [...prevRates]
                    newRates[osi] = fetchedRate
                    return newRates
                })
            }

            const fetchedCurrencySymbol = response?.data?.currency?.symbol
            if(fetchedCurrencySymbol) {
                setCurrencySymbols(prevSymbols => {
                    const newSymbols = [...prevSymbols]
                    newSymbols[osi] = fetchedCurrencySymbol
                    return newSymbols
                })
            }

            const fetchedVAT = response?.data?.taxes?.rate
            if(fetchedVAT && !isEditingOffer && (!isEditingDraft || !os.ticketsVariables.taxes.vatPercent) ) {
                const vatAsPercent = fetchedVAT * 100
                const newTaxes = {...os.ticketsVariables.taxes}
                newTaxes.vatPercent = vatAsPercent
                let newTicketsVariables = {...os.ticketsVariables, taxes: newTaxes }
                setOfferShow(
                    osi, 
                    { ticketsVariables: newTicketsVariables }
                )
            }

        }

        fetchCountryDatas()
    }, [])
        
    const goToNextStep = () => setStep(prevStep => {
        switch(prevStep) {
            case 0:
                return 1
            case 1:
                return 2
            case 2:
                return 3
            case 3:
                return 4
            case 4:
                return 5
            case 5:
                return 5
        }
    })

    const goToPreviousStep = () => setStep(prevStep => {
        switch(prevStep) {
            case 0:
                return 0
            case 1:
                return 0
            case 2:
                return 1
            case 3:
                return 2
            case 4:
                return 3
            case 5:
                return 4
        }
    })

    const reviewToStep = (stepIndex: ShowsStepsIndexes) => {
        setIsLocalReviewing(true)
        setStep(stepIndex)
    }

    const goBackToLocalReview = () => {
        setStep(5)
    }

    const handleClickStep = (stepToGo: keyof typeof stepsComponents) => {
        if(step > stepToGo) {
            setStep(stepToGo)
        }
    }

    const stepsComponents = {
        0:  <ShowInfo 
                os={os}
                osi={osi}
                rate={rates[osi]}
                currencySymbol={currencySymbols[osi]}
                isReviewing={isReviewing}
                isLocalReviewing={isLocalReviewing}
                setOfferShow={setOfferShow}
                goToNextStep={goToNextStep}
                goBackToReview={goBackToReview}
                goBackToLocalReview={goBackToLocalReview}
            />,
        1:  <VenueStep 
                os={os}
                osi={osi}
                isReviewing={isReviewing}
                isLocalReviewing={isLocalReviewing}
                setOfferShow={setOfferShow}
                goToNextStep={goToNextStep}
                goToPreviousStep={goToPreviousStep}
                goBackToReview={goBackToReview}
                goBackToLocalReview={goBackToLocalReview}
            />,
        2:  <TicketsStep 
                os={os}
                osi={osi}
                currencySymbol={currencySymbols[osi]}
                isReviewing={isReviewing}
                isLocalReviewing={isLocalReviewing}
                setOfferShow={setOfferShow}
                setShowTickets={setShowTickets}
                addShowTickets={addShowTickets}
                removeShowTickets={removeShowTickets}
                goToNextStep={goToNextStep}
                goToPreviousStep={goToPreviousStep}
                goBackToReview={goBackToReview}
                goBackToLocalReview={goBackToLocalReview}
            />,
        3: <OfferStep 
                offer={offer}
                os={os}
                osi={osi}
                currencySymbol={currencySymbols[osi]}
                isReviewing={isReviewing}
                isLocalReviewing={isLocalReviewing}
                setOffer={setOffer}
                setOfferShow={setOfferShow}
                setShowDealInputs={setShowDealInputs}
                goToPreviousStep={goToPreviousStep}
                goToNextStep={goToNextStep}
                goBackToReview={goBackToReview}
                goBackToLocalReview={goBackToLocalReview}
            />,
        4:  <OtherStep 
                os={os}
                osi={osi}
                isReviewing={isReviewing}
                isLocalReviewing={isLocalReviewing}
                setOfferShow={setOfferShow}
                goToPreviousStep={goToPreviousStep}
                goToNextStep={goToNextStep}
                goBackToReview={goBackToReview}
                goBackToLocalReview={goBackToLocalReview}
            />,
        5:  <ShowReviewStep
                offer={offer}
                os={os}
                osi={osi}
                rates={rates}
                currencySymbols={currencySymbols}
                goToNextOfferStep={goToNextOfferStep}
                reviewToStep={reviewToStep}
                setOfferShow={setOfferShow}
                setShowPnlInputs={setShowPnlInputs}
            />
    }

    const amountActiveClass = step === 0 ? styles.stepper__doing : styles.stepper__done
    const venueActiveClass = step === 1 ? styles.stepper__doing : step > 1 ? styles.stepper__done : styles.stepper__todo
    const ticketsActiveClass = step === 2 ? styles.stepper__doing : step > 2 ? styles.stepper__done : styles.stepper__todo
    const termsActiveClass = step === 3 ? styles.stepper__doing : step > 3 ? styles.stepper__done : styles.stepper__todo
    const hotelActiveClass = step === 4 ? styles.stepper__doing : step > 4 ? styles.stepper__done : styles.stepper__todo
    const reviewActiveClass = step === 5 ? styles.stepper__doing : step > 5 ? styles.stepper__done : styles.stepper__todo

    const isPnlAccessible = step >= 3 || isLocalReviewing || isReviewing


    return (
        <Flex className="justifystart allwidth">

            {/* PNL */}
            <Dialog
                title={`Profit and Loss for show with ${offer.artistName} ${moment(os.date).format("LL")} in ${countries[os.country]}`}
                visible={pnlVisible}
                onClose={closePnl}
                backdropClose={false}
                dialogCustomStyle={{overflowY: 'scroll', height: '90vh', top: '5vh', width: '90%', left: '5%', backgroundColor: "var(--app-background)"}}
                notAllSpace={true}
            >
                <OfferPNL 
                    show={os}
                    setOfferShow={setOfferShow}
                    showIndex={osi}
                    rate={rates[osi]}
                    currencySymbol={currencySymbols[osi]}
                    setShowPnlInputs={setShowPnlInputs}
                    close={closePnl}
                    artistName={offer.artistName}
                />
            </Dialog>

            <Row className="alignstart">

                {/* Left Area */}
                <div className="margedRight20px" style={{width: '40%'}}>
                    {/* Stepper */}
                    <div className="app-card margedBot20px">
                        <div className="margedBot10px">
                            <ReactCountryFlag
                                countryCode={os.country} 
                                style={{
                                    lineHeight: '20px',
                                    fontSize: '20px',
                                    marginRight: '5px'
                                }}
                                title={countries[os.country]}
                            />
                            <b className="margedRight5px">{countries[os.country]}</b>
                            <span>{ moment(new Date(os.date)).format('DD MMM YYYY') }</span>
                        </div>
                        <div 
                            onClick={() => handleClickStep(0)} 
                            className={`${styles.stepper_item} ${amountActiveClass} ${step > 0 ? "clickable" : ""}`}
                        >
                            <span>1. Show Info</span>
                            <AiFillCheckCircle size={20} className={styles.stepperIcon} />
                        </div>
                        <div 
                            onClick={() => handleClickStep(1)} 
                            className={`${styles.stepper_item} ${venueActiveClass} ${step > 1 ? "clickable" : ""}`}
                        >
                            <span>2. Venue</span>
                            <AiFillCheckCircle size={20} className={styles.stepperIcon} />
                        </div>
                        <div 
                            onClick={() => handleClickStep(2)} 
                            className={`${styles.stepper_item} ${ticketsActiveClass} ${step > 2 ? "clickable" : ""}`}
                        >
                            <span>3. Tickets</span>
                            <AiFillCheckCircle size={20} className={styles.stepperIcon} />
                        </div>
                        <div 
                            onClick={() => handleClickStep(3)} 
                            className={`${styles.stepper_item} ${termsActiveClass} ${step > 3 ? "clickable" : ""}`}
                        >
                            <span>4. Offer</span>
                            <AiFillCheckCircle size={20} className={styles.stepperIcon} />
                        </div>
                        <div 
                            onClick={() => handleClickStep(4)} 
                            className={`${styles.stepper_item} ${hotelActiveClass} ${step > 4 ? "clickable" : ""}`}
                        >
                            <span>5. Other</span>
                            <AiFillCheckCircle size={20} className={styles.stepperIcon} />
                        </div>
                        <div 
                            onClick={() => handleClickStep(5)} 
                            className={`${styles.stepper_item} ${reviewActiveClass} ${step > 5 ? "clickable" : ""}`}
                        >
                            <span>6. Review</span>
                            <AiFillCheckCircle size={20} className={styles.stepperIcon} />
                        </div>
                    </div>

                    {/* PNL Area */}
                    <div className="app-card">
                        <p className="margedBot10px">PNL</p>
                        {!isPnlAccessible && <p className="margedBot10px error">Wait until step 4. Offer to view PNL</p>}
                        {os.isPnlDone && <p className="margedBot10px green-color">PNL done.</p>}
                        <button onClick={openPnl} disabled={!isPnlAccessible} className="button button--primary">{os.isPnlDone ? "Edit PNL" : "Add PNL"}</button>
                    </div>
                </div>
                
                <Flex className="app-card" style={{width: '60%'}}>
                    {stepsComponents[step]}
                </Flex>
            </Row>
            
        </Flex>                  
    )
}

export default ShowItem