import CryptoJS from "crypto-js";

const forcedString = async any => (
    typeof any === 'string'
    ? any
    : JSON.stringify(any)
);


/**
 * @dev Cipher - Encrypt any data
 * @param {string|object} data a string or object to encrypt
 * @returns {string} cipher text string modified to enable use in url or path (see below)
 * @dev Computed cipher is edited before return. all iterations of "/" are replaced by "\bar\" 
 */
    export const encrypt = async(data) => {
    const parsedData = await forcedString(data);
    
    let ciphertext = await Promise.resolve(
        CryptoJS.AES.encrypt(parsedData, process.env.REACT_APP_PEPPER).toString()
    );

    const noSlashData = await Promise.resolve(
        ciphertext.replace(/\//g, '-bar-')
    );

    const noPlusData = await Promise.resolve(
        noSlashData.replace(/\+/g, '-pls-')
    )

    return noPlusData;

}

/**
 * @dev Decipher - Decrypt any encrypted data
 * @param {string} cipher a cipher string to decrypt ("\bar\" iterations will be replaced by "/" : @see encrypt method)
 * @param {boolean} isObject *optional* boolean set to true if original data as object type (default: false)
 * @returns {string|object} the original string|object data that has been encrypted (returned type depends on the `isObject` parameter)
 */
export const decrypt = async(cipher, isObject) => {
    // maybe cipher contains "/bar/", replace for "/"
    const slashedCipher = await Promise.resolve(
        cipher.replace(/-bar-/g, '/')
    );

    const plusCipher = await Promise.resolve(
        slashedCipher.replace(/-pls-/g, '+')
    )

    let bytes = await Promise.resolve(
        CryptoJS.AES.decrypt(plusCipher, process.env.REACT_APP_PEPPER)
    );

    let originalData = await Promise.resolve(
        bytes.toString(CryptoJS.enc.Utf8)
    );
    
    let parsedData = await Promise.resolve(
        isObject
        ? JSON.parse(originalData)
        : originalData
    );

    return parsedData;
    
}

/**
 * @dev Hash any string data
 * @param {string} string there is no type test over this parameter, please provide a string
 * @param {string} salt *optional* (env var must be set if not provided)
 * @returns {string} hash
 */
export const hash = async(string, salt) => {
    let result
    let i, l, hval;

    hval = (!salt || salt === '0') ? process.env.REACT_APP_SALT : salt;

    for (i = 0, l = string.length; i < l; i++) {
        hval ^= string.charCodeAt(i);
        hval += (hval << 1) + (hval << 4) + (hval << 7) + (hval << 8) + (hval << 24);
    }

    result = ("0000000" + (hval >>> 0).toString(16)).substr(-8)
    //console.log('isHexString', ethUtil.isHexString("0x"+result))
    // let response = await toHexa(result);
    return "0x"+result;

}

/**
 * @dev Hash any data
 * @see https://cryptojs.gitbook.io/docs/#pbkdf2
 * @param {string|object} data the string|object data to hash 
 * @returns {string} PBKDF2 hash string
 */
export const hash_PBKDF2 = async(data) => {

    const strData = await forcedString(data);
    const dataHash = await Promise.resolve(
        CryptoJS.PBKDF2(strData, process.env.REACT_APP_PEPPER, { keySize: 512/32, iterations: 1000 })
    );
    const hashStr = await Promise.resolve(
        dataHash.toString(CryptoJS.enc.Hex) // Base64|utf8 can be used also
    );

    return hashStr;
}



