import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Client } from '../utils/models/client.model';
import { Promotor, PromotorEvent } from '../utils/models/promotors.model';

type ClientState = Client | null

const initialState = null;

export const clientSlice = createSlice({
  name: 'client',
  initialState: initialState as ClientState,
  reducers: {
    set: (state, action: PayloadAction<Client>) => {
      return action.payload
    },
    disconnect: () => {
      localStorage.removeItem('client');
      return initialState
    },

    promotorUpdateProfile: (state, action: PayloadAction<Promotor>) => {
      if(!state?.promotor) { return }
      state.promotor = action.payload
    },
    promotorSetEvents: (state, action: PayloadAction<PromotorEvent[]>) => {
      if(!state?.promotor) { return }
      state.promotor.customEvents = action.payload
    },
    // promotorAddEvent: (state, action: PayloadAction<PromotorEvent>) => {
    //   if(!state?.promotor) { return }
    //   //todo clean data and put customEvents as non optional initialized as empty array
    //   const currentEvents = state.promotor.customEvents ?? []
    //   state.promotor.customEvents = [...currentEvents, action.payload]
    // },
    // promotorDeleteEvent: (state, action: PayloadAction<{id: string}>) => {
    //   if(!state?.promotor) { return }
    //   if(!state.promotor.customEvents) { return }
    //   const eventIndex = state.promotor.customEvents.findIndex(event => event.id === action.payload.id)
    //   if(eventIndex !== -1) {
    //     state.promotor.customEvents.splice(eventIndex, 1)
    //   }
    // },
    // promotorMergeEvent: (state, action: PayloadAction<PromotorEventMergeObject>) => {
    //   if(!state?.promotor) { return }
    //   if(!state.promotor.customEvents) { return }
    //   const {id, ...mergeProps} = action.payload
    //   const eventIndex = state.promotor.customEvents?.findIndex(event => event.id === id)
    //   if(eventIndex !== -1) {
    //     state.promotor.customEvents[eventIndex] = {...state.promotor.customEvents[eventIndex], ...mergeProps}
    //   }
    // }

  },
});

export const clientActions = clientSlice.actions;

export default clientSlice.reducer;