import { Link } from "react-router-dom"
import { ROUTE_PRIVACY_POLICY, ROUTE_TERMS_AND_CONDITIONS } from "../../routes/routes.const"
import { BsFacebook, BsInstagram } from "react-icons/bs"
import logo from '../../images/logo.png';
import { RiFacebookFill } from "react-icons/ri";

const Footer = () => {

    return (
        <div className="landing-footer">
            <div className="landing-container">
                <div className="landing-footer-inner">
                    <div className="flex-only space-between row">
                        <div>
                            <p>Kisum © 2023 All Rights Reserved.</p>
                        </div>

                        <div className="flex row">
                            <div className="margedRight20px">
                                <Link to={ROUTE_TERMS_AND_CONDITIONS}><p>Terms & Conditions</p></Link>
                            </div>
                            <div className="margedRight20px">
                                <Link to={ROUTE_PRIVACY_POLICY}><p>Privacy Policy</p></Link>
                            </div>
                        </div>

                        <div className="flex row">
                            
                            <div className="margedRight10px">
                                <Link target="_blank" rel="noreferrer noopener" to="https://www.instagram.com/primuse_live/"><BsInstagram size={20} /></Link>
                            </div>
                            <Link target="_blank" rel="noreferrer noopener" to="https://www.facebook.com/profile.php?id=100083886105074"><RiFacebookFill size={20} /></Link>
                        </div>
                        
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Footer