// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.offers_viewGrid__RI5iQ {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    row-gap: 20px;
    column-gap: 20px;
}

.offers_artistImg__J0FVc {
    margin-right: 5px;
    height: 65px;
    width: 65px;
    object-fit: cover;
    border-radius: 200px;
}`, "",{"version":3,"sources":["webpack://./src/user-panels/admin/admin-panel-screens/offers/offers.module.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,qCAAqC;IACrC,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,YAAY;IACZ,WAAW;IACX,iBAAiB;IACjB,oBAAoB;AACxB","sourcesContent":["\r\n.viewGrid {\r\n    display: grid;\r\n    grid-template-columns: repeat(3, 1fr);\r\n    row-gap: 20px;\r\n    column-gap: 20px;\r\n}\r\n\r\n.artistImg {\r\n    margin-right: 5px;\r\n    height: 65px;\r\n    width: 65px;\r\n    object-fit: cover;\r\n    border-radius: 200px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"viewGrid": `offers_viewGrid__RI5iQ`,
	"artistImg": `offers_artistImg__J0FVc`
};
export default ___CSS_LOADER_EXPORT___;
