import { MdOutlineAddTask } from "react-icons/md";
import { Flex } from "../../Containers";
import { useDispatch } from "react-redux";
import { snackActions } from "../../../store/snackSlice";
import { useState } from "react";
import { Alias } from "../../../utils/models/artists.model";
import { ImCross } from "react-icons/im"
import LabelInput from "../mui/label-input/LabelInput";
import { colors } from "../../../constants";

const AliasArrayInput = ({ title, value, renderer, onChange}: { title?: string, value: any[], renderer: Function, onChange: Function}) => {

    const [aliasInput, setAliasInput] = useState({name: '', locale: ''})

    const handleRemoveElement = (indexToRemove: number) => {
        const updatedArray = value.filter((_, index) => index !== indexToRemove)
        onChange(updatedArray)
    }

    const dispatch = useDispatch()
    const setSnack = (snack:any) => dispatch(snackActions.set(snack))

    return (
        <Flex className="allwidth">
            {title && <label className="selfstart bold label-color">{ title }</label>}

            <Flex className="alignstart allwidth">
            {
                value.map((element:Alias, index:number) => <Flex key={`inpaliasesArray_i${index}`} row>
                    {/* removable row */}
                    { renderer(element) }
                    <ImCross size={10} onClick={(e: React.MouseEvent<SVGElement, MouseEvent>) => handleRemoveElement(index)} color={colors.red} className="array-input__cross-icon clickable"/>
                </Flex>)
            }
            </Flex>
            <Flex row className="selfstart allwidth alignend"> 
                <div className="allwidth grid-2-col margedRight20px">
                    <LabelInput
                        value={aliasInput.name}
                        onChange={(e:React.ChangeEvent<HTMLInputElement>) => setAliasInput(prevInput => ({...prevInput, name: e.target.value}))} 
                        title='name'
                    />
                    <LabelInput
                        value={aliasInput.locale}
                        onChange={(e:React.ChangeEvent<HTMLInputElement>) => setAliasInput(prevInput => ({...prevInput, locale: e.target.value}))} 
                        title='locale'
                    />
                </div>
                <MdOutlineAddTask
                    size={20} 
                    className="clickable main-color" 
                    onClick={() => {
                        if (aliasInput.name && aliasInput.locale) {
                            const newAlias = {name: aliasInput.name, locale: aliasInput.locale}
                            onChange([...value, newAlias]);
                            setAliasInput({name: '', locale: ''});
                        }
                        else setSnack({ type: 'warning', content: 'You need to fill both field' })
                    }} 
                />
            </Flex>
            
        </Flex>
    )
}

export default AliasArrayInput