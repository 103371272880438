import { Artist, ArtistFromDB, NormalizedArtistMarcoDB, SpotifyData } from "../../../../../utils/models/artists.model"
import { socialIcons } from "../../../../admin/admin-panel-screens/promotors/PromotorForm"
import { FaDeezer, FaLastfmSquare, FaGooglePlay } from "react-icons/fa"
import { AiFillApple, AiFillYoutube, AiFillAmazonSquare } from "react-icons/ai"
import { SiBeatport, SiSongkick, SiBandsintown, SiGenius } from "react-icons/si"
import { TbBrandTidal } from "react-icons/tb"
import { BsSpotify } from "react-icons/bs"
import { ImSoundcloud2 } from "react-icons/im"
import { FaLink } from "react-icons/fa"

export const streamingPlatformsIcons = {
    spotify: <BsSpotify />,
    deezer: <FaDeezer />,
    last: <FaLastfmSquare />,
    play: <FaGooglePlay />,
    apple: <AiFillApple />,
    beatport: <SiBeatport />,
    amazon: <AiFillAmazonSquare />,
    songkick: <SiSongkick />,
    soundcloud: <ImSoundcloud2 />,
    tidal: <TbBrandTidal />,
    music: <AiFillAmazonSquare />,
    youtube: <AiFillYoutube />,
    bandsintown: <SiBandsintown />,
    genius: <SiGenius />
}

const ArtistInfos = ({ artist, spotifyData }: { artist: Artist|ArtistFromDB|NormalizedArtistMarcoDB, spotifyData?: SpotifyData}) => {

    const genres = artist.genres ?? spotifyData?.artist?.genres

    return (
        <div>

            {(artist.gender || artist.type) 
            &&  <div className="margedBot50px">
                    <div className="details-title-border">
                        <h3 className="details-section-title">Identity</h3>
                    </div>
                    <p>
                        {artist.gender && <>{artist.gender}, </>}
                        {artist.type}
                    </p>
                </div>}

            <div className="margedBot50px">
                <div className="details-title-border">
                    <h3 className="details-section-title">Genres</h3>
                </div>
                {genres && genres.map((genre, index) => 
                <p className="tag-blue font12 cursor-default margedRight5px margedBot10px capitalize" key={index}>{genre}</p>)}
            </div>

            <>
                <div className="details-title-border">
                    <h3 className="details-section-title">Links</h3>
                </div>
                <ul>
                    <>
                    {artist.website 
                    &&  <li className='margedBot10px'>
                            <a href={artist.website} className="font14" target='_blank' rel="noreferrer">
                                <span className="margedRight5px vertical-align-middle"><FaLink /></span>
                                {artist.website}
                            </a> 
                        </li>}
                        
                    {Object.keys(socialIcons).map((socialName, index) => artist.socials?.[socialName] 
                    ?   <li className='margedBot10px' key={index}>
                            <a href={artist.socials[socialName].url} className="font14" target='_blank' rel="noreferrer" key={index}>
                                {/* @ts-ignore : socialName is of type keyof typeof socialIcons and not string  */}
                                <span className="margedRight5px vertical-align-middle">{socialIcons[socialName]}</span>
                                <span className="capitalize">{socialName}</span>
                            </a> 
                        </li>
                    :   null)}

                    {Object.keys(streamingPlatformsIcons).map((platformName, index) => {
                        // correcting the fact that the data is coming in nested objects for last.fm, play.google, and music.amazon
                        let correctedName = ''
                        let correctedUrl = ''
                        if(platformName === 'last') {
                            correctedName = 'last.Fm'
                            //@ts-ignore
                            correctedUrl = artist.streamingPlatforms?.last?.fm?.url
                        } else if(platformName === 'play') {
                            correctedName = 'Google Play'
                            //@ts-ignore
                            correctedUrl = artist.streamingPlatforms?.play?.google?.url
                        } else if(platformName === 'music') {
                            correctedName = 'Amazon Music'
                            //@ts-ignore
                            correctedUrl = artist.streamingPlatforms?.music?.amazon?.url
                        }
                        return artist.streamingPlatforms?.[platformName] 
                        ?   <li className='margedBot10px' key={index}>
                                <a href={correctedUrl ? correctedUrl : artist.streamingPlatforms[platformName].url} className="font14" target='_blank' rel="noreferrer" key={index}>
                                    {/* @ts-ignore : socialName is of type keyof typeof socialIcons and not string  */}
                                    <span className="margedRight5px vertical-align-middle">{streamingPlatformsIcons[platformName]}</span>
                                    <span className="capitalize">{correctedName ? correctedName : platformName}</span>
                                </a> 
                            </li>
                        :   null})}
                    </>
                </ul>
            </>
        </div>
    )
}

export default ArtistInfos