import './snackbar.css';

export const Snackbar = props => {
    const {
        closeSnack,         // *REQUIRED* function cancelling the received `children` prop
        type,               // success, warning, error
        children,
        delay
    } = props;

    let useType = type;

    if (children) {
        useType = useType||'success'
        setTimeout(closeSnack, (delay || 5000))
    }

    return <div key='app-snackbar' className={`${(children||useType) ? 'snackbar':''}${children ? ' active' : ''}${useType ? ` snack-${useType}` : ''}`}>
        { children}
    </div>
}
