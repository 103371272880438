import { Flex } from "../../../../../components/Containers"
import { ls, plural, pnlAmountFormatter } from "../../../../../utils/utils"
import { ShowsStepsIndexes } from "../ShowItem"
import ReactCountryFlag from "react-country-flag"
import { countries } from "../../../../../utils/models/touring.model"
import moment from "moment"
import { AmountType, OfferFormInputs, OfferShowFormInputs, ShowTicket } from "../../../../../utils/models/offer.model"
import Dialog from "../../../../../components/dialog/Dialog"
import { useState } from "react"
import PNLForm from "../pnl/PNLForm"
import { computeOtherIncomesAmount, computeSponsorsAmount, computeTicketsDatas } from "../../../../../utils/offers.util"

const ShowReview = ({ offer, show, showIndex, rates, currencySymbols, reviewGoToEdit, localReviewGoToEdit, setOfferShow, setShowPnlInputs}:
    { 
        offer: OfferFormInputs,
        show: OfferShowFormInputs,
        showIndex: number,
        rates: (null|number)[],
        currencySymbols: (null|string)[],
        reviewGoToEdit?: (showIndex: number, stepIndex: ShowsStepsIndexes) => void,
        localReviewGoToEdit?: (stepIndex: ShowsStepsIndexes) => void,
        setOfferShow: (showIndex: number, showProps: {}) => void, 
        setShowPnlInputs: (showIndex: number, props: any) => void,
    }) => {

    const [pnlVisible, setPnlVisible] = useState(false)
    const openPnl = () => setPnlVisible(true)
    const closePnl = () => setPnlVisible(false)

    const { tickets, isAmountNet, amount, eventName, showDuration, showTypes, taxPercent, venue, doorsOpen, amountType, dealInputs, isPnlDone,
        onStage, minAge, curfew, visaRequired, broadcasted, sponsored, sponsors, hasOtherIncomes, otherIncomes, comments, airFare, nearestAirport, hotel, localCurrency } = show

    const { hasGuaranteedFee, guaranteedFee, witholdingTax, promotorDistributionPercent } = dealInputs

    const currencySymbol = currencySymbols[showIndex]

    const _showTypes = showTypes.map(showType => showType.value)

    const { ticketsTotalGrossIncome, totalTicketsQuantity, ticketsTotalNetIncome } = computeTicketsDatas(show.tickets, show.ticketsVariables)


    const sponsorshipAmount = computeSponsorsAmount(sponsors, sponsored)
    const otherIncomesAmount = computeOtherIncomesAmount(otherIncomes, hasOtherIncomes)

    const totalIncomeGross = ticketsTotalGrossIncome + sponsorshipAmount + otherIncomesAmount

    const hasOtherInfos = (nearestAirport !== undefined) || (hotel !== undefined) || comments !== undefined

    return (
        <>

            {/* PNL */}
            <Dialog
                title={`Profit and Loss for show with ${offer.artistName} ${moment(show.date).format("LL")} in ${countries[show.country]}`}
                visible={pnlVisible}
                onClose={closePnl}
                backdropClose={false}
                dialogCustomStyle={{overflowY: 'scroll', height: '90vh', top: '5vh', width: '90%', left: '5%', backgroundColor: "var(--app-background)"}}
                notAllSpace={true}
            >
                <PNLForm 
                    show={show}
                    setOfferShow={setOfferShow}
                    showIndex={showIndex}
                    rate={rates[showIndex]}
                    currencySymbol={currencySymbols[showIndex]}
                    setShowPnlInputs={setShowPnlInputs}
                    close={closePnl}
                />
            </Dialog>

            
            <Flex row className="justifystart leftalign margedTop20px">
                <ReactCountryFlag
                    countryCode={show.country} 
                    style={{
                        fontSize: '30px',
                        marginRight: '5px'
                    }}
                    title={countries[show.country]}
                />
                <b className="margedRight5px font18">{countries[show.country]}</b>
                <span className="font18 margedRight10px">{ moment(new Date(show.date)).format('DD MMM YYYY') }</span>
                <span className="font18 main-color">${ls(amount, true)}</span>
            </Flex>
            <div className="allwidth flex-only wrap gap20 margedTop10px">
                <div className="allwidth app-card leftalign width400px" >
                    <Flex row className="allwidth space-between margedBot10px">
                        <h2>Show Info</h2>
                        {reviewGoToEdit && <button className="button button--primary button--round" onClick={() => reviewGoToEdit(showIndex,0)}>Edit</button>}
                        {localReviewGoToEdit && <button className="button button--primary button--round" onClick={() => localReviewGoToEdit(0)}>Edit</button>}
                    </Flex>
                    <div>
                        <p>Event name : {eventName}</p>
                        <p>Visitor minimum age : {minAge}</p>
                        <p>Show duration : {showDuration.value} minutes</p>
                        <p>Show {plural('type', _showTypes.length)} : {_showTypes.join(' - ')}</p>
                    </div>
                </div>

                <div className="allwidth app-card leftalign width400px">
                    <Flex row className="allwidth space-between margedBot10px">
                        <h2>Venue</h2>
                        {reviewGoToEdit && <button className="button button--primary button--round" onClick={() => reviewGoToEdit(showIndex,1)}>Edit</button>}
                        {localReviewGoToEdit && <button className="button button--primary button--round" onClick={() => localReviewGoToEdit(1)}>Edit</button>}
                    </Flex>
                    <div>
                        <p>{venue.name}, {venue.address} {venue.city}</p>
                        <p>{venue.isOutdoor ? "Outdoor" : "Indoor"}</p>
                        <p>Capacity : {venue.totalCapacity}</p>
                        <p>Website : {venue.website}</p>
                        {venue.phone && <p>Phone : {venue.phone}</p>}
                        <p>Doors open : {doorsOpen}</p>
                        <p>On stage : {onStage}</p>
                        <p>Curfew time : {curfew}</p>
                        {venue.pastShows && <p>Past shows : {venue.pastShows}</p>}
                    </div>
                </div>

                <div className="allwidth app-card leftalign width400px">
                    <Flex row className="allwidth space-between margedBot10px">
                        <h2>Tickets</h2>
                        {reviewGoToEdit && <button className="button button--primary button--round" onClick={() => reviewGoToEdit(showIndex,2)}>Edit</button>}
                        {localReviewGoToEdit && <button className="button button--primary button--round" onClick={() => localReviewGoToEdit(2)}>Edit</button>}
                    </Flex>
                    <div>
                        <p>Total quantity of {ls(totalTicketsQuantity)}, total gross income of {currencySymbol ?? localCurrency} {pnlAmountFormatter(ticketsTotalGrossIncome)}</p>
                        <p>Total net income of {currencySymbol ?? localCurrency} {pnlAmountFormatter(ticketsTotalNetIncome)}</p>
                        {tickets.map((ticket: ShowTicket, index: number) => 
                            <p key={index}>
                                {ticket.name}) quantity : {ticket.quantity ? ticket.quantity : "Unknown"}, Gross price : {currencySymbol ?? localCurrency} {pnlAmountFormatter(ticket.grossPrice)}, Comps Ticket: {ticket.compsTicketsPercent}%
                            </p>)}
                    </div>
                </div>

                <div className="allwidth app-card leftalign width400px">
                    <Flex row className="allwidth space-between margedBot10px">
                        <h2>Offer</h2>
                        {reviewGoToEdit && <button className="button button--primary button--round" onClick={() => reviewGoToEdit(showIndex,3)}>Edit</button>}
                        {localReviewGoToEdit && <button className="button button--primary button--round" onClick={() => localReviewGoToEdit(3)}>Edit</button>}
                    </Flex>
                    <div>
                        {amountType === AmountType.Fixed && 
                            <p>Amount : {currencySymbol ?? localCurrency} {pnlAmountFormatter(amount)} {isAmountNet ? "Net" : `Gross with ${taxPercent}% Tax`}</p>}
                        {amountType === AmountType.Deal && 
                        <>
                            <p>Deal</p>
                            <p>Total income gross : {currencySymbol ?? localCurrency} {pnlAmountFormatter(totalIncomeGross)}</p>
                            <p>Guaranteed Fee : {hasGuaranteedFee ? `Yes ${currencySymbol ?? localCurrency} ${pnlAmountFormatter(guaranteedFee)} gross + ${witholdingTax}% tax` : "No"}</p>
                            <p>Distribution : {promotorDistributionPercent}% Promotor - {100 - promotorDistributionPercent}% Artist</p>
                        </>}
                        <p>Offer expiry date : {offer.expiryDate}</p>
                        <p>Visa required : {visaRequired ? "Yes" : "No"}</p>
                        <p>Broadcast : {broadcasted ? `Yes` : "No"}</p>
                        <p>Sponsor : {sponsored ? `Yes, total : ${currencySymbol ?? localCurrency} ${pnlAmountFormatter(sponsorshipAmount)}` : "No"}</p>
                        <p>Other Incomes : {hasOtherIncomes ? `Yes, total : ${currencySymbol ?? localCurrency} ${pnlAmountFormatter(otherIncomesAmount)}` : "No"}</p>
                        {(airFare > 0) && <p>Flight contribution : {currencySymbol ?? localCurrency} {pnlAmountFormatter(airFare)}</p>}
                    </div>
                </div>

                <div className="allwidth app-card leftalign width400px">
                    <Flex row className="allwidth space-between margedBot10px">
                        <h2>Other</h2>
                        {reviewGoToEdit && <button className="button button--primary button--round" onClick={() => reviewGoToEdit(showIndex,4)}>Edit</button>}
                        {localReviewGoToEdit && <button className="button button--primary button--round" onClick={() => localReviewGoToEdit(4)}>Edit</button>}
                    </Flex>
                    <div>
                        {!hasOtherInfos && <p>No information provided.</p>}
                        {comments && <p>Comments : {comments}</p>}
                        {hotel?.name && <p>Hotel name : {hotel.name}</p>}
                        {hotel?.address && <p>Hotel address : {hotel.address}</p>}
                        {nearestAirport && <p>Nearest airport : {nearestAirport}</p>}
                    </div>
                </div>

                <div className="allwidth app-card leftalign width400px">
                    <p className="margedBot10px">PNL</p>
                    {isPnlDone && <p className="margedBot10px green-color">PNL done.</p>}
                    <button onClick={openPnl} className="button button--primary">{isPnlDone ? "Edit PNL" : "Add PNL"}</button>
                </div>

            </div>
        </>
    )
}

export default ShowReview