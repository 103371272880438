
const CheckboxSpruha = (
    {
        title,
        checked,
        onChange,
        disabled,
        label,
        inputOnly,
        small
    } : {
        title: string,
        checked: boolean | undefined,
        onChange: any,
        disabled?: boolean,
        label?: boolean,
        inputOnly?: boolean,
        small?: boolean
    }
) => {

    return (
        <div className="allwidth">
            {!inputOnly && <label className="allwidth leftalign label-ts">{title}</label>}
            <div className="flex row allwidth">
                <input 
                    checked={checked}
                    onChange={onChange}
                    className={`checkbox-ts ${small ? "checkbox-ts--small" : ""}`}
                    disabled={disabled}
                    type={"checkbox"}
                />
                {label && <label className="margedLeft5px">{checked ? 'Yes' : 'No'}</label>}
            </div>
        </div>
    )
}

export default CheckboxSpruha