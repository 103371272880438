import "./landing.css"

import Area1 from "./parts/Area1"
import Contact from "./parts/Contact"
import Features from "./parts/Features"
import Footer from "./parts/Footer"
import Hero from "./parts/Hero"
import How from "./parts/How"
import Navbar from "./parts/Navbar"
import Partners from "./parts/Partners"
import Secured from "./parts/Secured"
import Story from "./parts/Story"
import Why from "./parts/Why"

const LandingPageV2 = () => {

    return (
        <div className="landing-page">
            <Navbar />
            <Hero />
            <Story />
            <Partners />
            <Area1 />
            <Features />
            <How />
            <Secured />
            <Why />
            <Contact />
            <Footer />
        </div>
    )
}

export default LandingPageV2