import { countries, PotentialTouringWithArtist, TouringWithArtist } from "../../../../utils/models/touring.model"
import moment from 'moment'
import { useState } from "react"
import Dialog from "../../../../components/dialog/Dialog"
import PotentialTouringForm from "./PotentialTouringForm"
import { createPortal } from "react-dom"

import ReactCountryFlag from "react-country-flag"
import { ls } from "../../../../utils/utils"
import { IoMdCheckmark } from "react-icons/io"
import { colors } from "../../../../constants"
import { MdClose } from "react-icons/md"
import TouringForm from "../touring/TouringForm"
import useFetchApiAuth from "../../../../utils/hooks/useFetchApiAuth"
import useSetSnack from "../../../../utils/hooks/useSetSnack"
import { useAppDispatch } from "../../../../utils/hooks/reduxTypedHooks"
import { touringsActions } from "../../../../store/touringsSlice"
import { potentialTouringsActions } from "../../../../store/potentialTouringsSlice"

const ItemRow = ({ tour, handleMakeTouringFromPotential }: { 
    tour: TouringWithArtist|PotentialTouringWithArtist,
    handleMakeTouringFromPotential: (pTour: PotentialTouringWithArtist) => void 
}) => {

    const [pTourFormVisible, setPTourFormVisible] = useState(false)
    const [touringFormVisible, setTouringFormVisible] = useState(false)
    const [deleteTourModal, setDeleteTourModal] = useState(false)
    const [deletePTourModal, setDeletePTourModal] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)

    const fetchApiAuth = useFetchApiAuth()
    const setSnack = useSetSnack()
    const dispatch = useAppDispatch()
    
    const openPTourForm = () => setPTourFormVisible(true)
    const closePTourForm = () => setPTourFormVisible(false)

    const openTouringForm = () => setTouringFormVisible(true)
    const closeTouringForm = () => setTouringFormVisible(false)

    const openDeleteTourModal = () => setDeleteTourModal(true)
    const closeDeleteTourModal = () => setDeleteTourModal(false)

    const openDeletePTourModal = () => setDeletePTourModal(true)
    const closeDeletePTourModal = () => setDeletePTourModal(false)

    const rootModalDiv = document.getElementById('modal-root')!
    
    const handleClickEditPTour = () => {
        openPTourForm()
    }

    const handleClickEditTouring = () => {
        if(tour.isPotential) {
            return
        }
        openTouringForm()
    }

    const handleClickAdd = () => {
        if(!tour.isPotential) {
            return
        }

        handleMakeTouringFromPotential(tour)
    }

    const handleDeleteTour = async () => {
        setIsDeleting(true)

        const { error, response } = await fetchApiAuth({
            method: 'POST',
            route: 'delete-touring',
            body: { id: tour.id }
        });

        // snack
        setSnack(
            error
            ? {
                type: 'error', 
                content: error.toString()
            }
            : {
                type: 'success',
                content: `Tour with ${tour.artist.displayableName} from ${moment(new Date(tour.from)).format('LL')} has been successfully deleted`
            }
        )
        
        if(!error){
            // update global promotors
            dispatch(touringsActions.delete({ id: tour.id }))
            closeDeleteTourModal()
        }

        setIsDeleting(false)
    }

    const handleDeletePTour = async () => {
        setIsDeleting(true)

        const { error, response } = await fetchApiAuth({
            method: 'POST',
            route: 'delete-potential-touring',
            body: { id: tour.id }
        });

        // snack
        setSnack(
            error
            ? {
                type: 'error', 
                content: error.toString()
            }
            : {
                type: 'success',
                content: `Potential tour with ${tour.artist.displayableName} from ${moment(new Date(tour.from)).format('LL')} has been successfully deleted`
            }
        )
        
        if(!error){
            // update global promotors
            dispatch(potentialTouringsActions.delete({ id: tour.id }))
            closeDeletePTourModal()
        }

        setIsDeleting(false)
    }

    return (
        <>
            {createPortal(<Dialog
                visible={pTourFormVisible}
                title={`Edit Potential Touring for ${tour.artist.displayableName} starting ${moment(new Date(tour.from)).format('LL')}`}
                onClose={closePTourForm}
                backdropClose={false}
                dialogCustomStyle={{overflowY: 'scroll', height: '90vh', top: '5vh', width: '80%', left: '10%', backgroundColor: 'var(--app-background)'}}
                notAllSpace={true}
            >
                <PotentialTouringForm />
            </Dialog>, rootModalDiv)}

            {createPortal(<Dialog
                visible={touringFormVisible}
                title={`Edit Touring for ${tour.artist.displayableName} starting ${moment(new Date(tour.from)).format('LL')}`}
                onClose={closeTouringForm}
                backdropClose={false}
                dialogCustomStyle={{overflowY: 'scroll', height: '90vh', top: '5vh', width: '80%', left: '10%', backgroundColor: 'var(--app-background)'}}
                notAllSpace={true}
            >
                {/* @ts-ignore touring will be Touring 100% because of return in handleClickEditTouring if tour.isPotential */}
                <TouringForm touring={tour} artist={tour.artist} closeForm={closeTouringForm}/>
            </Dialog>, rootModalDiv)}

            {createPortal(
                <Dialog
                    title={`Delete tour with ${tour.artist.displayableName} from ${moment(new Date(tour.from)).format("LL")} `}
                    visible={deleteTourModal}
                    onClose={closeDeleteTourModal}
                    backdropClose={false}
                    dialogCustomStyle={{height: '250px', top: '23', width: '60%', left: '20%'}}
                    notAllSpace={true}
                >
                    <div>
                        <p className="margedTop20px margedBot30px">Are you sure you want to delete the tour with {tour.artist.displayableName} starting {moment(new Date(tour.from)).format('LL')} ?</p>
                        <button className="button button--round button--ghost margedRight50px" onClick={closeDeleteTourModal}>Cancel</button>
                        <button className="button button--round button--danger" onClick={handleDeleteTour}>{isDeleting ? "Deleting..." : "Delete"}</button>
                    </div>
                    
                </Dialog>, rootModalDiv)}

                {createPortal(
                <Dialog
                    title={`Delete potential tour with ${tour.artist.displayableName} from ${moment(new Date(tour.from)).format("LL")} `}
                    visible={deletePTourModal}
                    onClose={closeDeletePTourModal}
                    backdropClose={false}
                    dialogCustomStyle={{height: '250px', top: '23', width: '60%', left: '20%'}}
                    notAllSpace={true}
                >
                    <div>
                        <p className="margedTop20px margedBot30px">Are you sure you want to delete the potential tour with {tour.artist.displayableName} starting {moment(new Date(tour.from)).format('LL')} ?</p>
                        <button className="button button--round button--ghost margedRight50px" onClick={closeDeletePTourModal}>Cancel</button>
                        <button className="button button--round button--danger" onClick={handleDeletePTour}>{isDeleting ? "Deleting..." : "Delete"}</button>
                    </div>
                    
                </Dialog>, rootModalDiv)}

            <tr>
                <td className="leftalign">{moment(new Date(tour.dateCreated)).format('MM/D/YYYY h:mm A')}</td>
                <td className="leftalign"><b className="larger">{ tour.artist.displayableName }</b></td>
                <td  className="leftalign">{moment(new Date(tour.from)).format('D-MMM-YY')}</td>
                <td  className="leftalign">{moment(new Date(tour.to)).format('D-MMM-YY')}</td>
                <td className="leftalign" style={{maxWidth: '15vw'}}>
                    <div className="flex-align-center row wrap" style={{columnGap: '10px'}}>
                        {
                            tour.countries.map((country, index) => 
                                <ReactCountryFlag
                                    key={index}
                                    countryCode={country} 
                                    style={{
                                        lineHeight: '20px',
                                        fontSize: '20px',
                                    }}
                                    title={countries[country]}
                                />
                                /* {countries[country]} */)
                        }
                    </div>
                </td>
                <td  className="leftalign">{tour.showDuration} Minutes</td>
                <td className="leftalign capitalize">{tour.artist.genres?.[0]}</td>
                <td className="leftalign"></td>
                <td className="leftalign">{ls(tour.possibleFee, true)}</td>
                <td className="leftalign">{tour.comments}</td>
                <td>{tour.isPotential
                        ?   <MdClose color={colors.red} size={20} />
                        :   <IoMdCheckmark color={colors.green} size={20} />}</td>
                <td>
                    {tour.isPotential
                    ?   <button onClick={handleClickAdd} className="button button--primary">Add Touring</button>
                    :   <button onClick={handleClickEditTouring} className="button button--primary">Edit Data</button>}
                </td>
                <td>
                    {tour.isPotential
                    ?   <MdClose size={25} color={colors.red} className="clickable hoverscale" onClick={openDeletePTourModal} />
                    :   <MdClose size={25} color={colors.red} className="clickable hoverscale" onClick={openDeleteTourModal} />}
                </td>
            </tr>
        </>
    )
}

export default ItemRow