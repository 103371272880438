//todo update contract model
export interface Contract {
    id: string;
    artistCode: string;
    date: number;
    codeDate: string,
    index: number;
    artist: any;
    shows: any[];
    payments?: {amount: number|string, date:string}[]
};

export const CONTRACT_STATUS_COMPLETED = "completed"
export const CONTRACT_STATUS_NULL_AND_VOID = "nullandvoid"
export const CONTRACT_STATUS_IN_PROCESS= "in process"
export const CONTRACT_STATUS_SIGNED = "signed"
export const CONTRACT_STATUS_WAITING_PURCHASER_SIGNATURE = "waiting purchaser signature"
export const CONTRACT_STATUS_WAITING_PRIMUSE_SIGNATURE = "waiting primuse signature"
export const CONTRACT_STATUS_WAITING_BOTH_SIGNATURES = "waiting both signatures"