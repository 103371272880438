import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";

import { accessLevels, colors, theme } from "../../../../constants";
import { ls, mapAndReduceAsync, mapAsync, promisify, stackData, unix } from "../../../../utils/utils";
import moment from "moment";

import { Flex, Row } from "../../../../components/Containers";
import Chart from "../../../../components/Charts/Chart";


import { FaHandshake } from "react-icons/fa";
import { BsShieldFillCheck } from "react-icons/bs";
import { Touring } from "../../../../utils/models/touring.model";
import { Link } from "react-router-dom";
import { ROUTE_ARTISTS_FEED, ROUTE_CONTRACTS, ROUTE_PROFILE } from "../../../../routes/routes.const";
import { Artist } from "../../../../utils/models/artists.model";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid/main'
import PageLoading from "../../../../components/loading/PageLoading";
import IconifyIcon from "../../../../components/Icon/IconifyIcon";
import DashboardNextShows from "./DashboardNextShows";
import PromotorFeedback from "../../common/feedback/PromotorFeedback";



const PromotorDashboard = () => {
    const [nextShows, setNextShows] = useState([]);
    const [dashboardData, setDashboardData] = useState({});
    
    const client = useSelector((state:RootState) => state.client);
    const promotor = client?.promotor
    const contracts = useSelector((state:RootState) => state.contracts);   
    const artists = useSelector((state:RootState) => state.artists);   
    const _tourings = useSelector((state:RootState) => state.tourings);   
    const tourings = _tourings.map((t:Touring) => ({...t, artist: artists.find((a:Artist) => a.id === t.artistId)}));

    const loadDashboardData = async() => {
        
        const ttlContracts = contracts.length||0;
    
        if (contracts?.length) {
            const totalAmount = await mapAndReduceAsync(
                contracts,
                async (c:any) => (
                    c.payments?.length
                    ? c.payments
                        .map((p:any) => parseInt(p.amount))
                        .reduce((a:any, b:any) => (a + b))
                    : 0
                ),
                (a:any, b:any) => (a + b)
            );
           
            const signedContracts = (
                ttlContracts 
                ? await promisify(contracts.filter((c:any) => c.signatures?.length === 2)) 
                : []
            );
            const ttlToSignContracts = ttlContracts - signedContracts?.length;
            
            const signedContractsData = await mapAsync(
                signedContracts,
                async (c:any) => ({
                    x: moment(c.date),
                    y: await mapAndReduceAsync(c.payments, (p:any) => parseInt(p.amount), (a:any, b:any) => a+b),
                    shows: await mapAsync(c.shows, async(s:any) => ({...s, artistName: c.artist.name})),
                    label: `${c.purchaser.name} for ${c.artist.name} ${c.payments?.length && `($${ls(c.payments.map((p:any) => parseInt(p.amount)).reduce((a:any, b:any) => a+b), true)})`} \n${c.id}`
                })
            );
            // @ts-ignore
            const dateSorted = await promisify(signedContractsData.sort((a, b) => a.x.unix() -/* < */ b.x.unix()));
            // signed contracts min/max
            const min = dateSorted[0].x.unix() * 1000;
            const max = Date.now();
            /// end contracts amounts chart data
            

            /// Calendar booked shows
            const signedContractsShows = await mapAndReduceAsync(
                signedContractsData,
                async (_c:any) => _c.shows,
                (a:any, b:any) => [...a, ...b]
            );
            const sortedSignedContractsShows = await promisify(signedContractsShows.sort((a:any, b:any) => unix(a.date) - unix(b.date)));

            const firstEventFirstDayOfMonth = await promisify(moment(sortedSignedContractsShows[0].date).startOf('month').toDate());  
            const lastEventLastDayOfMonth = await promisify(moment(sortedSignedContractsShows[sortedSignedContractsShows.length-1].date).endOf('month').toDate());   
            // adding one because end limit is exclusive
            lastEventLastDayOfMonth.setDate(lastEventLastDayOfMonth.getDate() + 1)

            const calendarLimits = await promisify({start: moment(firstEventFirstDayOfMonth).format('YYYYMMDD'), end: moment(lastEventLastDayOfMonth).format('YYYYMMDD')})

            const tourBookedDays = await mapAsync(signedContractsShows, async(s:any) => ({ 
                title: `Event booked with ${s.artistName}`, 
                start: moment(s.date).format('YYYYMMDD'),
                textColor: colors.main,
                color: colors.bgMain }))


            /// @TODO : ADD PAYMENTS TO THE SCHEDULES CALENDAR                  /!\
            
            setDashboardData({
                totalAmount, 
                signedContracts, 
                ttlToSignContracts,
                signedContractsData, 
                min, max,
                calendarLimits,
                tourBookedDays,
                ttlContracts,
                loaded: true
            })
        
        } else {
            setDashboardData({
                totalAmount: 0, 
                signedContracts: [], 
                ttlToSignContracts: 0,
                signedContractsData: [], 
                min: 0, 
                max: 0,
                calendarLimits,
                tourBookedDays: [],
                ttlContracts: 0,
                loaded: true
            })
        }

    }

    useEffect(() => {
        // async load contracts and calendar data
        loadDashboardData();

        // async load 5 next shows (adding touringId to each show)
        tourings?.length && (async() => {
            const upcomingTourings = tourings.filter(touring => !touring.isPast)
            const upcomingShows = await mapAndReduceAsync(
                upcomingTourings,
                async (t:any) => t.shows.map((s:any) => ({...s, touringId: t.id, artist: t.artist, countries: t.countries, touringName: t.name})),
                // @ts-ignore
                (a, b) => [...a, ...b]
            );
            // @ts-ignore
            await promisify(upcomingShows.sort((a, b) => ( new Date(a.date).getTime() - new Date(b.date).getTime() /*? a : b*/ )))
            setNextShows(upcomingShows.slice(0,3));

        })();
    }, [])

    const { artists: cArtists } = promotor || {};

    // @ts-ignore
    const { totalAmount, signedContracts, signedContractsData, ttlToSignContracts, ttlContracts, min, max, calendarLimits, tourBookedDays, loaded } = dashboardData;

    return <>

       { 
        loaded
        ? <div className="main-container">
            <div className="margedTop20px">
                <PromotorFeedback page="Dashboard" />
            </div>

            <div className="margedTop30px">

                {/* Cards row */}
                <Flex row className="allwidth spacebetween">

                    <Link to={ROUTE_CONTRACTS}>
                        <Flex row className="app-card dashboard-card clickable">
                            <Flex flex={3} className='dashboard-icon-div' style={{backgroundColor: colors.bgMain}}>
                                <IconifyIcon icon="mdi:contract-outline" color={colors.main} fontSize={30} />
                            </Flex>
                            <Flex flex={6} className="allwidth textAlignEnd alignend">
                                <div className="title-color">Total Contracts</div>
                                <div className="xxlarge">{ttlContracts}</div>
                                <i className="smaller text-soft">
                                    {signedContracts.length} 
                                    &nbsp;signed
                                </i>
                                    { ttlToSignContracts && <i className="smaller text-soft">{ttlToSignContracts} to sign</i> }
                            </Flex>
                        </Flex>
                    </Link>

                    <Flex row className="app-card dashboard-card">
                        <Flex flex={3} className='dashboard-icon-div' style={{backgroundColor: colors.bgGreen}}>
                            <FaHandshake color={colors.green} size={30} />
                        </Flex>
                        <Flex flex={6} className="allwidth textAlignEnd alignend">
                            <div className="title-color">Total Amount</div>
                            <div className="font18" style={{lineHeight: '48px'}}>${ls(totalAmount||0, true)}</div>
                        </Flex>
                    </Flex>

                    <Link to={ROUTE_ARTISTS_FEED}>
                        <Flex row className="app-card dashboard-card clickable">
                            <Flex flex={3} className='dashboard-icon-div' style={{backgroundColor: colors.bgLightblue}}>
                                <IconifyIcon icon="mdi:artist-outline" color={colors.lightblue} fontSize={30} />
                            </Flex>
                            <Flex flex={6} className="allwidth textAlignEnd alignend">
                                <div className="title-color">Artists Booked</div>
                                <div className="xxlarge">{cArtists?.length||0}</div>
                            </Flex>
                        </Flex>
                    </Link>
                    
                    <Link to={ROUTE_PROFILE}>
                        <Flex row className="app-card dashboard-card clickable">
                            <Flex flex={3} className='dashboard-icon-div' style={{backgroundColor: colors.bgOrange}}>
                                <BsShieldFillCheck color={colors.orange} size={30} />
                            </Flex>
                            <Flex flex={6} className="allwidth alignend textAlignEnd">
                                <div className="title-color">Licence</div>
                                <b className="font18 capitalize" style={{lineHeight: '48px'}}>{client?.licence}</b>
                                {
                                    client?.promotor?.accessLevel   // @ts-ignore
                                    &&  <div className=""> 
                                            <i className="smaller text-soft">Level: </i>
                                            <span>{accessLevels.promotor[client.promotor.accessLevel]}</span>
                                        </div>
                                }
                            </Flex>
                        </Flex>
                    </Link>
                </Flex>

                
                <Flex row className="allwidth margedTop justifystart spacebetween">
                    
                    {/* <Flex 
                        className="selfstart white-container theme-bottom-border padded allheight justifystart width20vw" 
                        flex={2} 
                    >
                        {
                            logo
                            ? <img 
                                className="app-logo" 
                                alt='' 
                                src={logo} 
                                style={{
                                    height: '12vmin', 
                                    width: '12vmin', 
                                    objectFit: 'contain', 
                                    border: `5px solid ${theme[0]}`, 
                                    borderRadius: '50%', 
                                    padding: '1%'
                                }}
                            />
                            : <BsImage color="grey" size={70} />
                        }
                        <div className="title">{cieName}</div>
                        <i className="grey smaller">{cieId}</i>
                        <Flex>
                            <Row><AiOutlineMail />&nbsp;{email}</Row>
                            {fullAddress && <Row><FaRegAddressCard />&nbsp;{fullAddress}</Row>}
                            {website && <Row><BiLinkAlt />&nbsp;{website}</Row>}
                            <Row>
                                <button onClick={openForm} className="button button--primary button--small margedTop10px">
                                    Edit Profile
                                </button>
                            </Row>
                        </Flex>
                        <div className="divider margedTop10" />
                        <Flex row className="allwidth justifystart">
                            <Flex flex={4}>
                                <Chart
                                    autoColors
                                    chartId='trust_chart'
                                    size='5vw'
                                    data={{
                                        datasets: [
                                            {
                                                data: [35, 100-35],
                                                borderColor: theme[0],
                                                borderWidth: 1,
                                            }
                                        ]
                                    }}
                                    renderTooltip={(dta:any, dtaIndex:number) => `${dta}%${!dtaIndex ? ' trust' : ' doubt'}`}
                                />
                            </Flex>
                            <Flex flex={6} className=" bold theme2">Trust Score <div className="black">35 %</div></Flex>
                        </Flex>
                        
                        <div className="divider" />
                        <Flex row className="allwidth">
                     
                            <Flex flex={10} className="">
                                <div className=" bold theme2 margedTop">Primuse Account</div>
                                <b className="smaller">{ client?.email }</b>
                                <div>
                                    {
                                        (!client?.promotor?.clientId)
                                        ? <Flex row className="warning">
                                            <AiFillWarning color="orange" size={20} />
                                            NOT LINKED
                                        </Flex> 
                                        : <BiUserCheck color="#2AAA8A" size={50} />
                                    }
                                </div>
                                <button onClick={openForm} className="button button--primary button--small margedTop10px">
                                        Edit Access
                                    </button>
                            </Flex>
                        </Flex>   
                    </Flex> */}

                    {/* <Flex flex={8} className="centered selfstart" style={{flex: .75}}> */}
                    <Flex className="centered selfstart allwidth">
                        <Row className="margedTop20px">
                            {/* <Flex flex={5} className="justifystart white-container theme-bottom-border allspace h-padded">
                                <h2 className=" padded">Activity Value</h2>
                                <Chart 
                                    line
                                    chartId='amounts_line_chart'
                                    min={min}
                                    max={max}
                                    time={{
                                        unit: 'day',
                                    }}
                                    // @ts-ignore
                                    renderTooltip={({label}) => label}
                                    data={contracts?.length && {
                                        datasets: [
                                        
                                            {
                                                label: 'Contracts Total Amounts',
                                                id: 1,  
                                                data: stackData(
                                                    [
                                                        ...(    
                                                            signedContractsData
                                                        ),
                                                        { x: Date.now(), y: 0, label: 'To Date:' }
                                                    ],
                                                    
                                                    'y'
                                                ),
                                                backgroundColor: 'rgb(127, 62, 156, .7)',
                                                borderColor: theme,
                                                borderWidth: 1,
                                                // fill: {
                                                //     target: 'origin',
                                                //     above: 'rgb(127, 62, 156, .7)'
                                                // },
                                                // for bar charts only:
                                                // barThickness: 5,
                                                // maxBarThickness: 15,
                                            },
                                        ],
                                    }}
                                    width='30vw'
                                    height='30vh'
                                    
                                />
                            </Flex>
                            &nbsp; */}
                            <Flex className="app-card allwidth">
                                <h3 className="title-color">Schedules</h3>

                                <div className="allwidth margedBot20px">
                                    <FullCalendar
                                        validRange={calendarLimits}
                                        plugins={[ dayGridPlugin ]}
                                        initialView="dayGridMonth"
                                        events={tourBookedDays}
                                        dayMaxEventRows={2}
                                        aspectRatio={3}
                                        // height='30vh'
                                    />
                                </div>
                            </Flex>
                        </Row>
                        
                        <Flex className="app-card allwidth margedTop20px margedBot50px">
                            <DashboardNextShows nextShows={nextShows} />
                        </Flex>

                    </Flex>
                    
                </Flex>
                
              
            </div>
        </div>

        :   <PageLoading />
        }
            
    </>
}

export default PromotorDashboard;