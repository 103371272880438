import Tabs from "../../../../components/tabs-menu/Tabs"
import { useAppSelector } from "../../../../utils/hooks/reduxTypedHooks"
import { OfferStatus } from "../../../../utils/models/offer.model"
import PromotorFeedback from "../../common/feedback/PromotorFeedback"
import OffersTab from "./OffersTab"
import PromotorOfferDrafts from "./drafts/PromotorOfferDrafts"

//! styles in admin/admin-panel-screens/offers/offers.css

const PromotorOffers = () => {
    const offers = useAppSelector(state => state.offers)

    const currentStatus = [OfferStatus.Pending, OfferStatus.WaitingArtist, OfferStatus.RequestEdition, OfferStatus.PromotorTrusteeCheck]
    const pastStatus = [OfferStatus.AdminDenied, OfferStatus.Deprecated, OfferStatus.Withdrawn, OfferStatus.AdminAccepted, OfferStatus.ArtistAccepted, OfferStatus.ArtistDenied]
    
    const currentOffers = offers.filter(offer => currentStatus.includes(offer.status))
    const pastOffers = offers.filter(offer => pastStatus.includes(offer.status))

    return(
        <>
            <div className="main-container margedTop20px">
                <PromotorFeedback page="Offers" />
            </div>
            
            <Tabs activeTab='current'>
                <div option-data='current' data-icon='material-symbols:content-paste-go'>
                    <OffersTab offers={currentOffers}  title="Current" />
                </div>
                <div option-data='past' data-icon='wpf:past'>
                    <OffersTab past offers={pastOffers} title="Past" />
                </div>
                <div option-data='drafts' data-icon='material-symbols:edit-document-outline-rounded'>
                    <PromotorOfferDrafts />
                </div>
            </Tabs>
        </>
    )
}

export default PromotorOffers