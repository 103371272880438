import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PotentialTouring } from '../utils/models/touring.model';

type PotentialTouringsState = PotentialTouring[] | null

const initialState = null;

export const potentialTouringsSlice = createSlice({
  name: 'potentialTourings',
  initialState: initialState as PotentialTouringsState,
  reducers: {
    set: (state, action: PayloadAction<PotentialTouring[]>) => {
      return action.payload
    },
    add: (state, action: PayloadAction<PotentialTouring>) => {
      if(!state) { return }
      state.push(action.payload)
    },
    merge: (state, action: PayloadAction<PotentialTouring>) => {
      if(!state) { return }

      const {id, ...mergeProps} = action.payload
      const touringIndex = state.findIndex(touring => touring.id === id)
      if(touringIndex !== -1){
        state[touringIndex] = {...state[touringIndex], ...mergeProps}
      }
    },
    delete: (state, action: PayloadAction<{id: string}>) => {
      if(!state) { return }
      
      const searchId = action.payload.id
      const foundIndex = state.findIndex(potentialTouring => potentialTouring.id === searchId)
      if(foundIndex !== -1){
        state.splice(foundIndex, 1)
      }
    },
  },
});

export const potentialTouringsActions = potentialTouringsSlice.actions;

export default potentialTouringsSlice.reducer;