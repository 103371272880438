import { useAppSelector } from "../../../../../utils/hooks/reduxTypedHooks"
import { Touring, TouringRequest } from "../../../../../utils/models/touring.model"
import TourRequestItemRow from "./TourRequestItemRow"

export type RequestObject = { touring: Touring, request: TouringRequest}

const AdminRequests = () => {

    const tourings = useAppSelector(state => state.tourings)
    const requests: RequestObject[] = []

    tourings.forEach(touring => {
        touring.requests.forEach(request => {
            requests.push({touring, request})
        })
    })

    return(
        <div className='main-container margedBot100px paddedTop30px'>
            <h3 className="page-title">Tour Requests</h3>

            {requests?.length 
            ?   <table className="app-table">
                    <thead>
                        <tr>
                            <th className="leftalign">Date Created</th>
                            <th className="leftalign">For Artist</th>
                            {/* <th className="leftalign">Amount</th> */}
                            {/* <th className="leftalign">Tour From</th>
                            <th className="leftalign">Tour Until</th> */}
                            <th className="leftalign">Dates Requested</th>
                            <th className="leftalign">Promotor Name</th>
                            <th className="leftalign">Level</th>
                            <th className="leftalign">Email</th>
                            <th className="leftalign">Phone</th>
                            <th>Nbr of Contracts</th>
                        </tr>
                    </thead>

                    <tbody>
                        {requests.map((requestObject, index) => <TourRequestItemRow key={index} requestObject={requestObject} />)}
                    </tbody>
                </table>
            :   <p className="grey">No requests for now.</p>}
        </div>
    )
}

export default AdminRequests