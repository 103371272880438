import moment from "moment";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Flex } from "../../../../components/Containers";
import useFetchApiAuth from "../../../../utils/hooks/useFetchApiAuth";
import useSetSnack from "../../../../utils/hooks/useSetSnack";
import { offersActions } from "../../../../store/offersSlice";
import { AmountType, countriesCurrencies, countriesTaxRate, Offer, OfferCreateObject, OfferDraft, OfferDraftPostObject, OfferFormInputs, OfferShow, OfferShowFormInputs, OfferStatus } from "../../../../utils/models/offer.model";
import { formatOfferFromInputs, validateForm } from "../../../../utils/offers.util";
import ShowItem, { ShowsStepsIndexes } from "./ShowItem";
import { useAppSelector } from "../../../../utils/hooks/reduxTypedHooks";
import styles from "../offers.module.css"
import ReactCountryFlag from "react-country-flag";
import { countries, TouringDayWithTouringId } from "../../../../utils/models/touring.model";
import ReviewStep from "./review/ReviewStep";
import { Artist } from "../../../../utils/models/artists.model";
import { Promotor } from "../../../../utils/models/promotors.model";
import { offerDraftsActions } from "../../../../store/offerDraftsSlice";

const OfferForm = ({ artist, promotor, closeForm, selectedDays, offerToEdit, offerDraft }: {
    artist: Artist,
    promotor: Promotor,
    closeForm: () => void,
    selectedDays?: TouringDayWithTouringId[],
    offerToEdit?: Offer,
    offerDraft?: OfferDraft
}) => {

    const dispatch = useDispatch()
    const fetchApiAuth = useFetchApiAuth()
    const setSnack = useSetSnack()
    const client = useAppSelector(state => state.client)

    const [draftId, setDraftId] = useState(offerDraft?.id ?? '')
    const [isPosting, setIsPosting] = useState(false)
    const [offerStep, setOfferStep] = useState(0)
    const [isReviewing, setIsReviewing] = useState(false)
    const [reviewGoToStep, setReviewGoToStep] = useState<null|ShowsStepsIndexes>(null)

    
    const offerToEditShows = offerToEdit ? offerToEdit.shows : []
    const inputeableOfferToEditShows = offerToEditShows.map(show => {
        const inputeableShowTypes = show.showTypes.map(showtype => ({value: showtype, label: <div><b>{showtype}</b></div>}))
        const inputeableShowDuration = { value: show.showDuration, label: <div><b>{show.showDuration} Minutes</b></div> }
        return {
            ...show, 
            showTypes: inputeableShowTypes,
            showDuration: inputeableShowDuration
        }
    })

    const offerDraftShows = offerDraft ? offerDraft.shows : []
    const inputeableOfferDraftShows = offerDraftShows.map(show => {
        const inputeableShowTypes = show.showTypes.map(showtype => ({value: showtype, label: <div><b>{showtype}</b></div>}))
        const inputeableShowDuration = { value: show.showDuration, label: <div><b>{show.showDuration} Minutes</b></div> }
        return {
            ...show, 
            showTypes: inputeableShowTypes,
            showDuration: inputeableShowDuration
        }
    })

    const selectedDaysShows = selectedDays
    ?   selectedDays.map((selectedDay) => {
            const { id, date, showDuration, country, touringId, isPnlMandatory } = selectedDay;
            const inputeableShowDuration = { value: showDuration, label: <div><b>{showDuration} Minutes</b></div> }
            return {
                id,
                date, 
                showDuration: inputeableShowDuration, 
                country,
                localCurrency: countriesCurrencies[country],
                touringId, 
                isPnlMandatory,
                isPnlDone: false,
                eventName: '',
                showTypes: [],
                venue: {
                    name: '',
                    address: '',
                    city: '',
                    phone: '',
                    website: '',
                    totalCapacity: 0,
                    pastShows: '',
                    isOutdoor: false
                }, 
                tickets: [{name: "", quantity: 0, grossPrice: 0, compsTicketsPercent: 0}],
                ticketsVariables: {
                    taxes: {
                        vatPercent: 0,
                        ticketFeePercent: 0,
                        creditCardFeePercent: 0,
                        otherTaxesPercent: 0,
                    },
                    compsTickets: 0,
                },
                broadcasted: false,
                visaRequired: false,
                sponsored: false,
                sponsors: [{name: "", amount: 0}],
                hasAirFare: false,
                airFare: 0,
                hasOtherIncomes: false,
                otherIncomes: [{name: "Food & Beverage", amount: 0}, {name: "Merchandising", amount: 0}],
                minAge: '', 
                curfew: '', 
                amountType: AmountType.Fixed,
                isAmountNet: true,
                amount: 0,
                taxPercent: countriesTaxRate[country] ?? 0,
                onStage: '',
                doorsOpen: '',
                dealInputs: {
                    hasGuaranteedFee: false,
                    guaranteedFee: 0,
                    witholdingTax: 0,
                    promotorDistributionPercent: 50,
                },
                pnlInputs: {
                    corporateTicketPurchase: {has: false, amount: 0},
                    variableCosts: [
                        { name: "Government Tax", has: true, percentage: 0 },
                        { name: "Music Copyright", has: true, percentage: 0 },
                    ],
                    fixedCosts: [
                        {name: "Venue setup / teardown fee", has: true, amount: 0},
                        {name: "Venue Utilities", has: true, amount: 0},
                        {name: "Police, Security, Ushers, Barricades", has: true, amount: 0},
                        {name: "Technical Production", has: true, amount: 0},
                        {name: "Special Effects, Pyro, Endorsement, Props", has: true, amount: 0},
                        {name: "Freight, Stage", has: true, amount: 0},
                        {name: "Advertising & Promotion", has: true, amount: 0},
                        {name: "Manpower setup, runners, logistics", has: true, amount: 0},
                        {name: "Insurance, Permit, License, Visa", has: true, amount: 0},
                        {name: "Hotel / Accomodation", has: true, amount: 0},
                        {name: "Ground Transport", has: true, amount: 0},
                        {name: "Hospitality Rider", has: true, amount: 0},
                        {name: "Miscellaneous", has: true, amount: 0},
                        {name: "Local Operation Fee", has: true, amount: 0},
                    ]
                }
            }
        })

    :   []

    const initialShows: OfferShowFormInputs[] = offerToEdit ? inputeableOfferToEditShows : offerDraft ? inputeableOfferDraftShows : selectedDaysShows

    const initialState = {
        id: offerToEdit?.id ?? '',
        artistId: artist.id, 
        artistName: artist.displayableName,
        promotorId: promotor.id,
        status: OfferStatus.Pending,
        archived: false,
        expiryDate: '',
        totalAmount: 0,    
        shows: initialShows
    }

    const [offer, _setOffer] = useState<OfferFormInputs>(initialState)
    const numberOfShows = offer.shows.length
    const [rates, setRates] = useState<(null|number)[]>(new Array(numberOfShows).fill(null))
    const [currencySymbols, setCurrencySymbols] = useState<(null|string)[]>(new Array(numberOfShows).fill(null))

    const setOffer = (_props:any) => _setOffer({...offer, ..._props});

    const isEditingOffer = offerToEdit !== undefined
    const isEditingDraft = offerDraft !== undefined

    console.log(offer)


    // Update Form Inputs Functions
    // --------------------
    const setOfferShow = (showIndex:number, showProps:any) => {
        _setOffer(prevOffer => {
            const updatedShows = prevOffer.shows.slice()
            updatedShows[showIndex] = {...prevOffer.shows[showIndex], ...showProps}
            return {...prevOffer, shows: updatedShows}
        })
    }

    const setShowDealInputs = (showIndex: number, props: any) => {
        _setOffer(prevOffer => {
            const updatedShows = prevOffer.shows.slice()
            const updatedDealInputs = {...prevOffer.shows[showIndex].dealInputs, ...props}
            
            updatedShows[showIndex] = {...updatedShows[showIndex], dealInputs: updatedDealInputs}
            return {...prevOffer, shows: updatedShows}
        })
    }

    const setShowPnlInputs = (showIndex: number, props: any) => {
        _setOffer(prevOffer => {
            const updatedShows = prevOffer.shows.slice()
            const updatedPnlInputs = {...prevOffer.shows[showIndex].pnlInputs, ...props}
            
            updatedShows[showIndex] = {...updatedShows[showIndex], pnlInputs: updatedPnlInputs}
            return {...prevOffer, shows: updatedShows}
        })
    }

    // Tickets
     const addShowTickets = (showIndex:number) => {
        const { tickets } = offer.shows[showIndex];
        const newTicket = { name: '', quantity: 0, grossPrice: 0, compsTicketsPercent: offer.shows[showIndex].ticketsVariables.compsTickets }
        const upTickets = [...tickets, newTicket]; 
        setOfferShow(showIndex, { tickets: upTickets })
    }

    const setShowTickets = (showIndex:number, ticketsIndex:number, _props:any) => {
        let { tickets } = offer.shows[showIndex];
        const upTickets = tickets.map((t:any, ti:number) => (
            (ti === ticketsIndex)
            ? {...t, ..._props}
            : t
        ));
        setOfferShow(showIndex, { tickets: upTickets })

    }

    const removeShowTickets = (showIndex:number, ticketsIndex:number) => {
        let { tickets } = offer.shows[showIndex];
        const upTickets = tickets.filter((t:any, ti:number) => ti !== ticketsIndex);
        setOfferShow(showIndex, { tickets: upTickets })

    }

    const clearForm = () => setOffer(initialState);


    // Post requests Functions
    // --------------------
    const storeOffer = async() => {
        setIsPosting(true)

        const isFormValid = validateForm(offer, client, setSnack)

        if(!isFormValid) {
            setIsPosting(false)
            return 
        }

        
        else {
            const formatedOffer = formatOfferFromInputs(offer)
            // @unused
            //const totalOfferAmount = showsAmts.reduce((a:number, b:number) => (a + b));;
          
            // store the new offer here
            const { error, response } = await fetchApiAuth({
                method: 'POST',
                route: 'create-offer',
                body: formatedOffer
            });

            console.log('OFFER STORED ?', {error, response});

            if(response && !error) {
                dispatch(offersActions.push(response));
                setSnack({
                    type: 'success',
                    content: 'Your offer has been successfully added'
                })

                // Remove draft
                if(draftId) {
                    const { error, response } = await fetchApiAuth({
                        method: 'POST',
                        route: 'delete-offer-draft',
                        body: { id: draftId }
                    })
            
                    if(error) {
                        setSnack({type: 'error', content: error.toString()})
                    } else {
                        dispatch(offerDraftsActions.delete({id: draftId}))
                    }
                }
                closeForm()
            }
            else setSnack({
                type: 'error',
                content: 'An error occured while storing your offer, please try again'
            })
              
        }

        setIsPosting(false)
    }

    const mergeOffer = async () => {
        setIsPosting(true)
        
        const isFormValid = validateForm(offer, client, setSnack)

        if(!isFormValid) { 
            setIsPosting(false)
            return 
        }

        else {
            const formatedOffer = formatOfferFromInputs(offer)
            const offerId = offer.id
            const editedProps = formatedOffer

            const { error, response } = await fetchApiAuth({
                method: 'POST',
                route: 'promotor-edit-offer',
                body: { offerId, editedProps }
            });

            if(response && !error) {
                dispatch(offersActions.merge(response));
                setSnack({
                    type: 'success',
                    content: 'Your offer has been successfully updated'
                })
                closeForm()
            }
            else setSnack({
                type: 'error',
                content: 'An error occured while updating your offer, please try again'
            })
        }
        setIsPosting(false)
    }

    const handleSaveDraft = async () => {
        setIsPosting(true)

        const offerDraft: OfferCreateObject = formatOfferFromInputs(offer)
        delete offerDraft["id"]
        
        const postableOfferDraft: OfferDraftPostObject = {...offerDraft}

        if(draftId) {
            await mergeOfferDraft(postableOfferDraft, draftId)
        } else {
            await createOfferDraft(postableOfferDraft)
        }

        setIsPosting(false)
    }

    const mergeOfferDraft = async (postableOfferDraft: OfferDraftPostObject, draftId: string) => {
        
        const { error, response } = await fetchApiAuth({
            method: 'POST',
            route: 'merge-offer-draft',
            body: { id: draftId, offerDraft: postableOfferDraft }
        });

        if(error) {
            setSnack({type: 'error', content: error.toString()})
        } else {
            setSnack({type: 'success', content: "Draft saved successfully. You can complete your draft in the Offer tab"})
            dispatch(offerDraftsActions.merge(response))
        }
    }

    const createOfferDraft = async (postableOfferDraft: OfferDraftPostObject) => {

        const { error, response } = await fetchApiAuth({
            method: 'POST',
            route: 'create-offer-draft',
            body: { offerDraft: postableOfferDraft }
        });

        if(error) {
            setSnack({type: 'error', content: error.toString()})
        } else {
            setSnack({type: 'success', content: "Draft created successfully. You can complete your draft in the Offer tab"})
            dispatch(offerDraftsActions.push(response))
            setDraftId(response.id)
        }
    }


    const { image, logo, banner } = artist;

    const artistImage = banner||image||logo;

    const totalOfferAmount = offer.shows?.length 
        && offer.shows
        .map((s:any, si:number) => parseInt(s.amount||0))
        .reduce((a:number, b:number) => (a + b))


    // Stepper Functions
    // --------------------
    const goToNextOfferStep = () => {
        setOfferStep(prevStep => {
            if(prevStep + 1 === offer.shows.length) {
                setIsReviewing(true)
            }
            return prevStep + 1
        })
    }

    const goBackToReview = () => {
        setOfferStep(offer.shows.length)
    }

    const reviewGoToEdit = (showIndex: number, stepIndex: ShowsStepsIndexes) => {
        setReviewGoToStep(stepIndex)
        setOfferStep(showIndex)
    }
    

    // Stepper Components
    // --------------------
    const showsComponents = offer.shows.map((os: any, osi: number) => {
        return {
            [osi]:  <ShowItem
            key={osi}
            offer={offer}
            os={os}
            osi={osi}
            rates={rates}
            setRates={setRates}
            currencySymbols={currencySymbols}
            setCurrencySymbols={setCurrencySymbols}
            isReviewing={isReviewing}
            reviewGoToStep={reviewGoToStep}
            setOffer={setOffer}
            setOfferShow={setOfferShow}
            setShowDealInputs={setShowDealInputs}
            setShowPnlInputs={setShowPnlInputs}
            setShowTickets={setShowTickets}
            addShowTickets={addShowTickets}
            removeShowTickets={removeShowTickets}
            goToNextOfferStep={goToNextOfferStep}
            goBackToReview={goBackToReview}
            isEditingOffer={isEditingOffer}
            isEditingDraft={isEditingDraft}
        />
        }
    })
    
    const offerStepsComponents = {
        ...showsComponents.map((showComponent: any, index: number) => {return showComponent[index]}),
        [showsComponents.length]: <ReviewStep 
            offer={offer}
            rates={rates}
            currencySymbols={currencySymbols}
            reviewGoToEdit={reviewGoToEdit}
            isEditingOffer={isEditingOffer}
            totalOfferAmount={totalOfferAmount}
            closeForm={closeForm}
            isPosting={isPosting}
            mergeOffer={mergeOffer}
            storeOffer={storeOffer}
            setOfferShow={setOfferShow}
            setShowPnlInputs={setShowPnlInputs}
        />
    }

    const stepperShowsComponents = offer.shows.map((show: any, index: number) => {
        const removeDay = (_showIndex: number) => {
            const shows = offer.shows.filter((_:any, si:number) => si !== _showIndex)
            setOffer({shows})
            
            if (!shows.length) {
                closeForm()
            }
            setRates(prevRates => prevRates.filter((_, index) => index !== _showIndex))
            setCurrencySymbols(prevSymbols => prevSymbols.filter((_, index) => index !== _showIndex))

            if(offerStep > _showIndex) {
                setOfferStep(prevStep => prevStep - 1)
            }
        }
        
        return <div 
            key={index}
            // onClick={() => handleClickStep(0)} 
            className={`${styles.sp_stepper_item} ${offerStep === index ? styles.sp_stepper__doing : offerStep > index ? styles.sp_stepper__done : styles.sp_stepper__todo}`}
        >
            <Flex row style={{lineHeight: '10px', height: "28px", marginTop: '-28px'}}>
                <p className="margedRight10px">Show {index + 1}</p>
                <button onClick={() => removeDay(index)} className="button button--danger button--round button--small">Remove</button>
            </Flex>
            <div className={styles.stepper_show_infos}>
                <ReactCountryFlag
                    countryCode={show.country} 
                    style={{
                        lineHeight: '20px',
                        fontSize: '20px',
                        marginRight: '5px'
                    }}
                    title={countries[show.country]}
                />
                <b className="margedRight5px">{countries[show.country]}</b>
                <span>{ moment(new Date(show.date)).format('DD MMM YYYY') }</span>
            </div>
        </div>})


    // Render
    // --------
    return <div className="main-container margedBot50px">
        
            <div className="flex-only space-between row margedTop30px">
                <div className="flex-align-center page-title">
                    <p className="margedRight10px">
                        Booking Offer
                    </p>
                    <button className="button button--danger button--round button--small" onClick={closeForm} style={{height:'fitContent'}}>Cancel</button>
                </div>
                {!isEditingOffer && 
                    <div>
                    <button disabled={isPosting} onClick={handleSaveDraft} className="button button--primary button--round">{isPosting ? "Saving..." : "Save Draft"}</button> 
                    </div>}
            </div>
        
            <Flex className="allwidth justifystart">
                <Flex className="allspace margedBot40px">
                    <div 
                        className="allwidth app-banner" 
                        style={
                            artistImage 
                            ? { backgroundImage: `url(${artistImage})`, backgroundRepeat: 'no-repeat', backgroundSize: 'cover', backgroundPositionY: '50%', height: '10vh' }
                            : { background: 'grey' }
                        }
                    >
                        <h1 className="artist-details__artist-title text-shadow white">{artist.displayableName}</h1>
                    </div>
                </Flex>
                
                <Flex className="allwidth">
                    
                        {/* Stepper */}
                        <Flex row className="allwidth margedBot20px">
                            {stepperShowsComponents}

                            {/* Final step */}
                            <div 
                                className={`${styles.sp_stepper_item} ${offerStep === stepperShowsComponents.length ? styles.sp_stepper__doing : offerStep > stepperShowsComponents.length ? styles.sp_stepper__done : styles.sp_stepper__todo}`}
                            >
                                <span>Review and Confirm</span>
                            </div>
                        </Flex>
                    
                    {offerStepsComponents[offerStep]}
                </Flex>

            </Flex>
       
    </div>
}

export default OfferForm;