import { useState } from "react"
import LabelInput from "../../components/form/mui/label-input/LabelInput"
import useSetSnack from "../../utils/hooks/useSetSnack"
import { fetchApi } from "../../utils/utils"

const Contact = () => {

    const [formInputs, setFormInputs] = useState({
        firstName: "",
        lastName: "",
        email: "",
        message: ""
    })
    const [isPosting, setIsPosting] = useState(false)

    const setSnack = useSetSnack()

    const updateInput = (value: string, inputName: keyof typeof formInputs) => {
        setFormInputs(prevInputs => ({...prevInputs, [inputName]: value}))
    }

    const handleSubmit = async () => {
        setIsPosting(true)
        const { firstName, lastName, email, message } = formInputs

        if(!firstName || !lastName || !email || !message) {
            setSnack({type: 'warning', content: 'Please fill all the fields.'})
            setIsPosting(false)
            return
        }

        const body = { firstName, lastName, email, message }

        const response = await fetchApi({
                method: "POST",
                route: "landing-contact",
                body
            },
            {}
        )

        setIsPosting(false)
    }

    return (
        <div className="landing-contact" id="contact">
            <div className="landing-container">
                <div className="flex-only space-between">
                    <div className="landing-contact-left-area">
                        <h3 className="landing-title">Join the Kisum Community</h3>
                    </div>

                    <div style={{flexBasis: "50%"}}>
                        <div>
                            <div className="grid-2-col">
                                <LabelInput
                                    title="First Name"
                                    value={formInputs.firstName}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateInput(e.target.value, 'firstName')}
                                />
                                <LabelInput
                                    title="Last Name"
                                    value={formInputs.lastName}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateInput(e.target.value, 'lastName')}
                                />
                            </div>

                            <div className="margedBot20px">
                                <LabelInput
                                    title="Email"
                                    value={formInputs.email}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateInput(e.target.value, 'email')}
                                />
                            </div>

                            <div className="margedBot20px">
                                <LabelInput
                                    title="Message"
                                    value={formInputs.message}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateInput(e.target.value, 'message')}
                                    textarea
                                />
                            </div>
                            <div className="leftalign">
                                <button disabled={isPosting} className="button button--primary" onClick={handleSubmit}>{isPosting ? "Submitting..." : "Submit"}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Contact