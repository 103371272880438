import { Navigate, useLocation } from "react-router-dom";
import { ROUTE_LANDING, ROUTE_LOGIN, ROUTE_PRIVACY_POLICY, ROUTE_TERMS_AND_CONDITIONS } from "./routes.const";

const ProtectedRoute = ({ client, children } : { client: any, children: JSX.Element[] }) => {
    const location = useLocation();
    const publicRoutes = [ROUTE_LANDING, ROUTE_LOGIN, ROUTE_TERMS_AND_CONDITIONS, ROUTE_PRIVACY_POLICY]
    
    if (
        (!publicRoutes.includes(location.pathname))
        && !(client?.token && (client?.tokenValidity > Date.now()))
    ) 
        return <Navigate to={ROUTE_LANDING} replace />;

    return children;
}

export default ProtectedRoute