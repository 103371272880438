import { Flex } from "../../../../../components/Containers"
import LabelInput from "../../../../../components/form/mui/label-input/LabelInput"
import { OfferShowFormInputs } from "../../../../../utils/models/offer.model"

const OtherStep = ({ os, osi, isReviewing, isLocalReviewing, setOfferShow, goToPreviousStep, goToNextStep, goBackToReview, goBackToLocalReview }: { 
    os: OfferShowFormInputs,
    osi: number,
    isReviewing: boolean,
    isLocalReviewing: boolean,
    setOfferShow: (showIndex: number, showProps: {}) => void,
    goToPreviousStep: () => void,
    goToNextStep: () => void,
    goBackToReview: () => void,
    goBackToLocalReview: () => void,
}) => {

    const handlePrevious = () => {
        goToPreviousStep()
    }

    const handleNext = () => {
        goToNextStep()
    }

    const handleGoBackToReview = (isLocalReview: boolean) => {
        if(isLocalReview) {
            goBackToLocalReview()
        } else {
            goBackToReview()
        }
    }

    return (
        <Flex className="alignstart allwidth">

            <div className="title main-color">Other</div> 

            <div className="allwidth grid-2-col alignend grid-small-col-gap margedBot50px">
                <LabelInput   
                    title='Comments'
                    value={os.comments||''}
                    onChange={(e:any) => setOfferShow(
                        osi, 
                        { comments: e.target.value }
                    )}
                />
                <LabelInput
                    title='Nearest Airport'
                    value={os.nearestAirport}
                    onChange={(e:any) => setOfferShow(
                        osi, 
                        { nearestAirport: e.target.value }
                    )}
                />
                <LabelInput
                    title='Hotel Name'
                    value={os.hotel?.name||''}
                    onChange={(e:any) => setOfferShow(
                        osi, 
                        { hotel: {...(os.hotel||{}), name: e.target.value } }
                    )}
                />
                <LabelInput
                    title='Hotel Address'
                    value={os.hotel?.address||''}
                    onChange={(e:any) => setOfferShow(
                        osi, 
                        { hotel: {...(os.hotel||{}), address: e.target.value } }
                    )}
                />
            </div>

            <Flex row className="allwidth">
                {isReviewing 
                ?   <button className="button button--primary button--round margedRight100px" onClick={() => handleGoBackToReview(false)}>Apply</button>
                :   isLocalReviewing
                    ?   <button className="button button--primary button--round margedRight100px" onClick={() => handleGoBackToReview(true)}>Apply</button>
                    :   <>
                            <button className="button button--primary button--round margedRight100px" onClick={handlePrevious}>Previous</button>
                            <button className="button button--primary button--round" onClick={handleNext}>Save and Review</button>
                        </>}
            </Flex>
        </Flex>
    )
}

export default OtherStep