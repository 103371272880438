import { useNavigate } from 'react-router-dom';
import { ROUTE_HOME } from '../../routes/routes.const';
import { artistsActions } from '../../store/artistsSlice';
import { clientActions } from '../../store/clientSlice';
import { contractsActions } from '../../store/contractsSlice';
import { offersActions } from '../../store/offersSlice';
import { promotorsActions } from '../../store/promotorsSlice';
import { touringsActions } from '../../store/touringsSlice';
import { usersActions } from '../../store/usersSlice';
import { useAppDispatch } from './reduxTypedHooks';

import { socketConnect } from '../../socket/socket-server';
import useSetSnack from './useSetSnack';
import { markIsPastInTourings } from '../touring.util';
import { Promotor } from '../models/promotors.model';
import { archivedPromotorsActions } from '../../store/archivedPromotorsSlice';
import { User } from '../models/users.model';
import { archivedUsersActions } from '../../store/archivedUsersSlice';
import { Artist } from '../models/artists.model';
import { archivedArtistsActions } from '../../store/archivedArtistsSlice';



function useDispatchLoginRes() {

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const setSnack = useSetSnack();

    const socketApiRequestsHandlers = {
      'connected': (connected:boolean) => {
        console.log('socket connected', connected);
      },
      'disconnected': (disconnected:boolean) => {
        console.log('socket disconnected', disconnected);
      },
      'offersAction': (action:{method: keyof typeof offersActions, value:any, message?: string}) => {
        console.log('Socket incoming offersAction', action);
        
        dispatch(offersActions[action.method](action.value));
        action.message && setSnack({ type: 'warning', content: action.message });
      },
      'contractsAction': (action:{method:string, value:any}) => {
        // @ts-ignore
        dispatch(contractsActions[action.method](action.value));

        /// @TODO Add contractId to `promotor.contracts` and `artist.contracts`
        // const promotorId = action.value.purchaser.id
        // if (
        //   (action.method === 'push')
        //   && action.value.id 
        //   && promotorId
        // ) {           
        //   const promotors = useSelector((state:RootState) => state.promotors); 
        //   const promotor = promotors.find(p => p.id === promotorId);
        //   // @ts-ignore
        //   promotor && dispatch(promotorsActions.merge({
        //     ...promotor, 
        //     contracts: [...(promotor.contracts||[]), action.value.id] 
        //   }));
        // }

      }
      
  
    };

    const dispatchLoginRes = (response:any, redirectPathname?: string) => {
        console.log(response)
        
        const activeUsers = response.users?.filter((user: User) => !user.isArchived)
        const archivedUsers = response.users?.filter((user: User) => user.isArchived)
        dispatch(usersActions.set(activeUsers));
        dispatch(archivedUsersActions.set(archivedUsers));

        dispatch(clientActions.set(response.client));

        const activeArtists = response.artists?.filter((artist: Artist) => !artist.isArchived)
        const archivedArtists = response.artists?.filter((artist: Artist) => artist.isArchived)
        dispatch(artistsActions.set(activeArtists));
        dispatch(archivedArtistsActions.set(archivedArtists))
        
        const markedTourings = markIsPastInTourings(response.tourings)
        dispatch(touringsActions.set(markedTourings))
        
        dispatch(contractsActions.set(response.contracts));

        const activePromotors = response.promotors?.filter((promotor: Promotor) => !promotor.isArchived)
        const archivedPromotors = response.promotors?.filter((promotor: Promotor) => promotor.isArchived)
        dispatch(promotorsActions.set(activePromotors));
        dispatch(archivedPromotorsActions.set(archivedPromotors));

        dispatch(offersActions.set(response.offers));

        localStorage.setItem('client', JSON.stringify(response.client));

        // Connect to websocket server
        // response.client && (async() => {
        //   const socketConnected = await socketConnect(socketApiRequestsHandlers, response.client.token);
        //   console.log('socketConnected', socketConnected);
        // })()

        navigate(redirectPathname || ROUTE_HOME)
    }

  return dispatchLoginRes;
}

export default useDispatchLoginRes