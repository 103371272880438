import { Touring } from "./touring.model"

export interface Artist {
    id:string;
    code:string;
    clientId?: string;

    //Primuse infos
    promotors: string[]; // relatedPromotorsIds
    pastBookings?: any[] //todo define booking model
    contracts: string[]; // array of contracts Ids
    assets: ArtistAssets
    areGeneralAssetsVisible: boolean
    comments: string
    preferences: string
    possibleFee?: number
    contacts: ArtistContact[]
    isDj: boolean;
    isArchived: boolean

    // artist infos
    displayableName: string;
    image?: string,
    logo?: string;
    banner?: string;
    bio?: string;
    type?: string;
    active?: boolean;
    activeFrom?: string;
    areaName?: string;
    country?: string;
    aliases?: Alias[],
    genres?: string[];
    gender?: string,
    urls?: ArtistURL[];
    members?: Member[];
    website?: string;
    socials?: Record<string, PlatformInfo>;
    streamingPlatforms?: Record<string, PlatformInfo>;

    //database infos
    name: string; //name in databases //! USED FOR QUERIES
    updated: number;
    gid: string;
    ipis: string[],
    isnis: string[],
    totalPartyPeople?: number,

    // streamingDatas
    streamingDatas: ArtistMinifiedStreamingDatas
};
export type ArtistCreateObject = Omit<Artist, 'id'|'code'|'name'>
export interface ArtistFromDB extends Omit<Artist, 'id'|'code'|'clientId'|'promotors'|'documents'|'pastBookings'|'contracts'|'totalPartyPeople'|'assets'|'generalAssetsVisible'|'comment'|'preferences'|'possibleFee'|'contacts' > {
    id: undefined
}

export interface ArtistMarcoDB {
    id: string
    gid: string
    name: string
    type: string
    gender: string
    area: {area_name: string, areas_alias_name: string}
    bio_summary: string
    platforms_id: {
        apple?: string
        deezer?: string
        genius?: string
        lastfm?: string
        spotify?: string
        youtube?: string
        twitter?: string
        instagram?: string
        facebook?: string
    }
}
export interface NormalizedArtistMarcoDB extends ArtistMarcoDB {
    displayableName: string
    country: string
    bio: string
    socials: Record<string, PlatformInfo>;
    streamingPlatforms: Record<string, PlatformInfo>;
    image: ''
    genres: string[]
    website: ''
    banner: ''
    logo: ''
}


export interface ArtistWithTours extends Artist {
    tours: Touring[]
}

export interface ArtistAssets {
    technique: string[]   //url
    rider: string[]
    material: string[]
    travelParty: string[]
    other: string[]
}
export interface ArtistContact {
    agency: string
    label: string
    management: string
}
export interface ArtistURL {
    type: string;
    href: string;
}
export interface Member {
    id: string;
    gid: string,
    name: string,
    attr: string,
    begin: string,
    end: string,
    ended: boolean,
}
export interface PlatformInfo {
    url?: string;
    id?: string;
}
export interface Alias {
    name: string;
    locale: string;
}

export const streamingPlatformsList = ["spotify", "deezer", "last.fm", "play.google", "apple", "beatport", "amazon", "songkick", "soundcloud", "tidal", "music.amazon", "genius"]


export interface ArtistAPIDatas {
    // apple: AppleData | undefined;
    spotify: SpotifyData | undefined;
    twitter: TwitterData | undefined;
    deezer: DeezerData | undefined;
    lastfm: LastfmData | undefined;
    seatgeek: SeatgeekData | undefined;
    bandsintown: BandsintownData | undefined;
    genius: GeniusData | undefined;
    tiktok: TiktokData | undefined;
    instagram: InstagramData | undefined;
    facebook: FacebookData | undefined;
    youtube: YoutubeData | undefined;
    events: (SeatgeekEvent|StandardizedEvent)[] | undefined;
    pastEvents: StandardizedEvent[] | undefined;
    totalFollowers: number | undefined;
}

export interface ArtistMinifiedStreamingDatas {
    spotify: SpotifyMinifiedData | undefined;
    bandsintown: BandsintownMinifiedData | undefined;
    twitter: TwitterData | undefined;
    deezer: DeezerData | undefined;
    lastfm: LastfmData | undefined;
    genius: GeniusData | undefined;
    tiktok: TiktokData | undefined;
    instagram: InstagramData | undefined;
    facebook: FacebookData | undefined;
    youtube: YoutubeData | undefined;
    totalFollowers: number | undefined;
}

export interface AppleData {
    totalAlbums: number
}
export interface SpotifyData {
    albums?: SpotifyAlbum[] | null
    artist?: SpotifyArtist | null
    similarArtists?: SpotifyArtist[] | null
    topTracksUS?: SpotifyTopTrack[] | null
    id: string
}
export interface SpotifyMinifiedData {
    artist?: {
        external_urls?: {
            spotify?: string
        },
        followers?: {
            total?: number
        },
        image?: { url?: string }
    },

    latestAlbum?: {
        url?: string
        image?: string
        name?: string
        total_tracks?: number
        release_date?: string
    },

    topTrackUs?: {
        album?: {
            image?: { url?: string }
            release_date?: string
        }
        name?: string
        preview_url?: string
    }
}
export interface SpotifyArtist {
    id: string
    name: string
    followers: {total: number}
    external_urls: {spotify?: string}
    genres: string[]
    images: {url: string, height: number, width: number}[]
}
export interface SpotifyAlbum {
    name: string
    release_date: string        // YYYY-MM-DD
    images: {url: string, height: number, width: number}[]
    external_urls: {spotify?: string}
    total_tracks: number
}
export interface SpotifyTopTrack {
    name: string
    preview_url: string
    album: SpotifyAlbum
    artists: SpotifyTrackArtist[]
    external_urls: {spotify?: string}
}
export interface SpotifyTrackArtist {
    external_urls: {spotify?: string}
    name: string
    id: string
}
export interface TwitterData {
    followers: number
    screenName: string      // for url
}
export interface DeezerData {
    followers: number
    url: string
}
export interface GeniusData {
    followers: number
    url: string
}
export interface LastfmData {
    listeners: string
    playcount: string
    url: string
}
export interface TiktokData {
    followers: number
    name: string
}
export interface InstagramData {
    followers: number
    url: string
}
export interface FacebookData {
    followers: number
    url: string
}
export interface YoutubeData {
    followers: number
    url: string
}

export interface BandsintownData {
    followers: number
    url: string
    events: BandsintownEvent[]
    pastEvents: StandardizedEvent[]
}
export interface BandsintownMinifiedData {
    followers?: number
    url?: string
}
export interface BandsintownEvent {
    fromBandsintown: true
    datetime: string
    url: string
    venue: {
        name: string
        city: string
        country: string
        region: string
    }
}

export interface SeatgeekData {
    score: number
    events: SeatgeekEvent[]
}
export interface SeatgeekEvent {
    fromSeatgeek: true
    datetime_local: string;
    datetime_utc: string;
    short_title: string;    // and title: string
    url: string;    //link to seatgeek page
    stats: SeatgeekEventStats
    venue: {
        country: string;     //country code
        city: string;
        address: string;
        name: string;
        state: string;
        postal_code: string;
        capacity: number;       //often missing and put as 0
    }
}
export interface SeatgeekEventStats {
    average_price?: number
    dq_bucket_counts?: number[]
    highest_price?: number
    listing_count?: number
    lowest_price?: number
    lowest_price_good_deals?: number
    lowest_sg_base_price?: number
    lowest_sg_base_price_good_deals?: number
    median_price?: number
    visible_listing_count?: number
}

export interface StandardizedEvent extends BandsintownEvent {
    stats: undefined
    datetime_local: string
    fromSeatgeek?: true | undefined
}

// export const dummyArtist1: Artist = {
//     id: 'barfoo',
//     code: 'BRO',
//     displayableName: "Swae Lee",
//     name: "swae lee",
//     type: "solo",
//     active: true,
//     activeFrom: '2005',
//     areaName: "United States",
//     country: "US",
//     genres: ["rap"],
//     gender: "Male",
//     aliases: [],
//     urls: [{type: "bandcamp", href: "https://dubinc.bandcamp.com"}],
//     updated: 1231313132,
//     gid: "adadoaodkak-adsdad-adada",
//     ipis: ["113131131"],
//     isnis: ["122112"],
//     members: [],
//     contracts: [],
//     clientId: "111",
//     image: "https://cdn.cnn.com/cnnnext/dam/assets/200321172312-swae-lee-file-super-tease.jpg",
//     logo: 'roster-01.jpg',
//     banner: "events-2.jpg",
//     website: "www.website.png",
//     socials: {
//         facebook: {url: "facebook.com/swae", id: "swae"},
//         instagram: {url: "instagram.com/swae", id: "swae"}
//     },
//     streamingPlatforms: {
//         soundclound: {url: "https://soundcloud.com/swae", id: ""}
//     },
//     promotors: ["111"],
//     assets: {material: [], other: [], travelParty: [], technique:[], rider:[]},
//     generalAssetsVisible: true,
//     bio: "I'm Swae Lee",
//     pastBookings: [],
//     totalPartyPeople: 1
// };