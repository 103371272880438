import moment from "moment"
import { useState } from "react"
import { createPortal } from "react-dom"
import { Row } from "../../../../../components/Containers"
import Dialog from "../../../../../components/dialog/Dialog"
import { feedbacksActions } from "../../../../../store/feedbacksSlice"
import { useAppDispatch, useAppSelector } from "../../../../../utils/hooks/reduxTypedHooks"
import useFetchApiAuth from "../../../../../utils/hooks/useFetchApiAuth"
import { Feedback } from "../../../../../utils/models/feedbacks.model"
import FeedbackView from "./FeedbackView"

const FeedbackItemRow = ( { feedback }: { feedback: Feedback }) => {

    const [modalVisible, setModalVisible] = useState(false)
    const [isPosting, setIsPosting] = useState(false)
    const users = useAppSelector(state => state.users)
    const user = users.find(user => user.id === feedback.from)

    const dispatch = useAppDispatch()
    const fetchApiAuth = useFetchApiAuth()
    
    const openModal = () => setModalVisible(true)
    const closeModal = () => setModalVisible(false)

    const preview = feedback.text.length > 30 ? feedback.text.slice(0,30) + ".." : feedback.text

    const rootModalDiv = document.getElementById('modal-root')!

    const handleView = () => {
        openModal()
    }

    const handleDelete = async (event: any) => {
        event.stopPropagation()
        setIsPosting(true)
        
        // post delete
        const { error, response } = await fetchApiAuth({
            method: 'POST',
            route: 'feedback/delete',
            body: {feedbackId: feedback.id}
        })

        if(!error)
            dispatch(feedbacksActions.delete({id: feedback.id}))

        setIsPosting(false)
    }

    return (
        <>
            {createPortal(
                <Dialog
                    visible={modalVisible}
                    title={`Feedback from user with email ${user?.email}`}
                    onClose={closeModal}
                    dialogCustomStyle={{height: '350px', top: '23', width: '60%', left: '20%'}}
                    notAllSpace={true}
                >
                    <FeedbackView feedback={feedback} close={closeModal} handleDelete={handleDelete} />
                </Dialog>, rootModalDiv)}

            <tr className="hover-medium-scale-shadow clickable" onClick={handleView}>
                <th className="fw400 leftalign">{moment(new Date(feedback.createdOn)).format('MM/D/YYYY h:mm A')}</th>
                <th className="fw400 leftalign">{user ? user.email : "User not found"}</th>
                <th className="leftalign">{preview}</th>
                <th>
                    <Row>
                        <button className="button button--primary button--round margedRight10px" onClick={handleView}>View</button>
                        <button className="button button--danger button--round" disabled={isPosting} onClick={handleDelete}>{isPosting ? "Deleting..." : "Delete"}</button>
                    </Row>
                </th>
            </tr>
        </>
    )
}

export default FeedbackItemRow