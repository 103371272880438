import { Row } from "../../../../../components/Containers"
import computePnlData from "../../../../../utils/hooks/offer/computePnlDatas"
import useSetSnack from "../../../../../utils/hooks/useSetSnack"
import { AmountType, OfferShowFormInputs } from "../../../../../utils/models/offer.model"
import { validatePnlForm } from "../../../../../utils/offers.util"
import { ls } from "../../../../../utils/utils"
import BreakEvenPointLinesChart from "./charts/BreakEvenPointLinesChart"
import CostIncomePieChart from "./charts/CostIncomePieChart"
import FixedCostPieChart from "./charts/FixedCostPieChart"
import FixedIncomePieChart from "./charts/FixedIncomePieChart"
import ProfitAndLossBarChart from "./charts/ProfitAndLossBarChart"
import BreakEvenPointBox from "./other/BreakEvenPointBox"
import ArtistCostsTable from "./tables/ArtistCostsTable"
import FixedCostsTable from "./tables/FixedCostsTable"
import ProfitAndLossTable from "./tables/ProfitAndLossTable"
import RevenuesTable from "./tables/RevenuesTable"
import TicketsTable from "./tables/TicketsTable"
import VariableCostsTable from "./tables/VariableCostsTable"

const PNLForm = ( { show, setOfferShow, showIndex, rate, currencySymbol, setShowPnlInputs, close }: { 
    show: OfferShowFormInputs,
    setOfferShow: (showIndex: number, showProps: {}) => void,
    showIndex: number,
    rate: null|number,
    currencySymbol: null|string,
    setShowPnlInputs: (showIndex: number, props: any) => void,
    close: () => void,
 }) => {

    const setSnack = useSetSnack()

    const { tickets, ticketsVariables, localCurrency: currency, pnlInputs, dealInputs, hasOtherIncomes, otherIncomes, sponsored, sponsors, amountType } = show

    const { compsTickets, taxes } = ticketsVariables
    const { corporateTicketPurchase, variableCosts, fixedCosts } = pnlInputs
    const { witholdingTax: artistWitholdingTax, promotorDistributionPercent } = dealInputs
    
    const { vatPercent, ticketFeePercent, creditCardFeePercent, otherTaxesPercent } = taxes

    const isAmountDeal = amountType === AmountType.Deal
    


    const { 
        ticketPricesAfterVATArray, ticketNetPricesArray, compTicketsNumbersArray, ticketsForSaleArray, salesArray, totalTickets, pourcentagesArray ,
        ticketsSoldArray, ticketCollectionsArray, totalRevenueArray, sponsorAmount, otherIncomesAmount, corporateTicketAmount, totalVariableCostArray, guaranteedFee, 
        artistWitholdingTaxAmount, airFare, totalArtistCost, totalFixedCosts, totalExpenditureArray, profitsArray, artistProfitsArray, promotorProfitsArray, artistDistrubutionPercent
    } = computePnlData({show})

    // Inputs Management
    const updateFixedCost = (costIndex: number, value: {name: string, has: boolean, amount: number}) => {
        const updatedVariableCosts = [...fixedCosts]
        updatedVariableCosts[costIndex] = value
        setShowPnlInputs(showIndex, { fixedCosts: updatedVariableCosts } )
    }
    const addFixedCost = () => {
        const updatedFixedCosts = [...fixedCosts]
        updatedFixedCosts.push({name: "", has: true, amount: 0})
        setShowPnlInputs(showIndex, { fixedCosts: updatedFixedCosts } )
    }
    const removeFixedCost = (costIndex: number) => {
        const updatedFixedCosts = [...fixedCosts]
        updatedFixedCosts.splice(costIndex, 1)
        setShowPnlInputs(showIndex, { fixedCosts: updatedFixedCosts } )
    }

    const updateCorporateTickets = (value: {has: boolean, amount: number}) => {
        setShowPnlInputs(showIndex, { corporateTicketPurchase: value } )
    }

    const updateVariableCost = (costIndex: number, value: {name: string, has: boolean, percentage: number}) => {
        const updatedVariableCosts = [...variableCosts]
        updatedVariableCosts[costIndex] = value
        setShowPnlInputs(showIndex, { variableCosts: updatedVariableCosts } )
    }
    const addVariableCost = () => {
        const updatedVariableCosts = [...variableCosts]
        updatedVariableCosts.push({name: "", has: true, percentage: 0})
        setShowPnlInputs(showIndex, { variableCosts: updatedVariableCosts } )
    }
    const removeVariableCost = (costIndex: number) => {
        const updatedVariableCosts = [...variableCosts]
        updatedVariableCosts.splice(costIndex, 1)
        setShowPnlInputs(showIndex, { variableCosts: updatedVariableCosts } )
    }

    const handleSave = () => {
        const isFormValid = validatePnlForm({ pnlInputs, setSnack })

        if(isFormValid) {
            setOfferShow(showIndex, { isPnlDone: true })
            setSnack({type: "success", content: "PNL saved successfully"})
            close()
        }
    }

    

    return (
        <div className="width95 marged0auto margedBot20px margedTop20px">

            <p className="leftalign">Rate : US$1 = {currencySymbol ?? currency} {ls(rate)}</p>

            {/* TICKETING */}
            <h3 className="margedBot20px">Ticketing and other incomes</h3>

            <div className="margedBot20px">
                <TicketsTable 
                    tickets={tickets}
                    currency={currency}
                    currencySymbol={currencySymbol}
                    rate={rate}
                    VAT={vatPercent}
                    ticketFee={ticketFeePercent}
                    creditCardTax={creditCardFeePercent}
                    otherTaxes={otherTaxesPercent}
                    compTickets={compsTickets}
                    ticketPricesAfterVATArray={ticketPricesAfterVATArray}
                    ticketNetPricesArray={ticketNetPricesArray}
                    compTicketsNumbersArray={compTicketsNumbersArray}
                    ticketsForSaleArray={ticketsForSaleArray}
                    salesArray={salesArray}
                    totalTickets={totalTickets}
                />
            </div>


            {/* PROFIT AND LOSS */}
            <h3 className="margedTop20px margedBot20px">Profit and Loss</h3>

            <div className="margedBot20px">
                <RevenuesTable 
                    currency={currency}
                    currencySymbol={currencySymbol}
                    rate={rate}
                    pourcentagesArray={pourcentagesArray}
                    ticketsSoldArray={ticketsSoldArray}
                    ticketCollectionsArray={ticketCollectionsArray}
                    totalRevenueArray={totalRevenueArray}
                    sponsorAmount={sponsorAmount}
                    otherIncomesAmount={otherIncomesAmount}
                    corporateTicketAmount={corporateTicketAmount}
                    corporateTicketPurchase={corporateTicketPurchase}
                    updateCorporateTickets={updateCorporateTickets}
                />
            </div>

            <div className="margedBot20px">
                <VariableCostsTable
                    currency={currency}
                    currencySymbol={currencySymbol}
                    rate={rate}
                    pourcentagesArray={pourcentagesArray}
                    variableCosts={variableCosts}
                    updateVariableCost={updateVariableCost}
                    addVariableCost={addVariableCost}
                    removeVariableCost={removeVariableCost}
                    totalVariableCostArray={totalVariableCostArray}
                    totalRevenueArray={totalRevenueArray}
                />
            </div>

            <div className="width50 margedBot20px">
                <ArtistCostsTable
                    currency={currency}
                    currencySymbol={currencySymbol}
                    rate={rate}
                    guaranteedFee={guaranteedFee}
                    artistWitholdingTax={artistWitholdingTax}
                    artistWitholdingTaxAmount={artistWitholdingTaxAmount}
                    airFare={airFare}
                    totalArtistCost={totalArtistCost}
                />
            </div>

            <div className="width50 margedBot20px">
                <FixedCostsTable
                    currency={currency}
                    currencySymbol={currencySymbol}
                    rate={rate}
                    fixedCosts={fixedCosts}
                    updateFixedCost={updateFixedCost}
                    addFixedCost={addFixedCost}
                    removeFixedCost={removeFixedCost}
                    totalFixedCosts={totalFixedCosts}
                />
            </div>

            <div className="flex-only margedBot10px">
                <BreakEvenPointBox
                    currency={currency}
                    currencySymbol={currencySymbol}
                    show={show}
                />
            </div>

            <div className="margedBot20px">
                <ProfitAndLossTable
                    currency={currency}
                    currencySymbol={currencySymbol}
                    rate={rate}
                    pourcentagesArray={pourcentagesArray}
                    totalExpenditureArray={totalExpenditureArray}
                    profitsArray={profitsArray}
                    ticketsSoldArray={ticketsSoldArray}
                    artistProfitsArray={artistProfitsArray}
                    promotorProfitsArray={promotorProfitsArray}
                    artistDistrubutionPercent={artistDistrubutionPercent}
                    promotorDistributionPercent={promotorDistributionPercent}
                    isAmountDeal={isAmountDeal}
                />
            </div>
            
            <Row className="margedBot20px">
                <button onClick={handleSave} className="button button--primary button--round margedRight50px">Save</button>
                <button onClick={close} className="button button--ghost button--round margedRight50px">Cancel</button>
            </Row>

            <div className="flex-only row margedBot20px">
                <div className="margedRight20px">
                    <FixedIncomePieChart
                        currency={currency}
                        currencySymbol={currencySymbol}
                        hasOtherIncomes={hasOtherIncomes}
                        otherIncomes={otherIncomes}
                        sponsored={sponsored}
                        sponsors={sponsors}
                        corporateTicketPurchase={corporateTicketPurchase}
                    />
                </div>

                <div className="margedRight20px">
                    <FixedCostPieChart
                        currency={currency}
                        currencySymbol={currencySymbol}
                        totalArtistCost={totalArtistCost}
                        fixedCosts={fixedCosts}
                    />
                </div>

                <div className="margedRight20px">
                    <CostIncomePieChart
                        currency={currency}
                        currencySymbol={currencySymbol}
                        totalArtistCost={totalArtistCost}
                        totalFixedCosts={totalFixedCosts}
                        sponsorAmount={sponsorAmount}
                        otherIncomesAmount={otherIncomesAmount}
                        corporateTicketAmount={corporateTicketAmount}
                    />
                </div>
            </div>

            <div className="flex-only row margedBot20px">
                <div className="width50 margedRight20px">
                    <BreakEvenPointLinesChart
                        currency={currency}
                        currencySymbol={currencySymbol}
                        pourcentagesArray={pourcentagesArray}
                        totalRevenueArray={totalRevenueArray}
                        totalExpenditureArray={totalExpenditureArray}
                    />
                </div>
                <div className="width50">
                    <ProfitAndLossBarChart
                        currency={currency}
                        currencySymbol={currencySymbol}
                        pourcentagesArray={pourcentagesArray}
                        totalRevenueArray={totalRevenueArray}
                        totalExpenditureArray={totalExpenditureArray}
                        profitsArray={profitsArray}
                    />
                </div>
            </div>
            
        </div>
    )
}

export default PNLForm