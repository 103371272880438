import { Promotor } from "../../../../../utils/models/promotors.model"
import PromotorsTable from "./table/PromotorsTable"

const ArchivedPromotorsList = ( { promotors, setOpenedPromotor, setOpenPromotor }: {
    promotors: Promotor[],
    setOpenedPromotor: React.Dispatch<React.SetStateAction<Promotor | null>>,
    setOpenPromotor: React.Dispatch<React.SetStateAction<boolean>>,
}) => {

    return (
        <div className='main-container'>
            <h3 className="page-title margedTop30px">Archived Promotors</h3>

            <PromotorsTable 
                promotors={promotors} 
                setOpenedPromotor={setOpenedPromotor}
                setOpenPromotor={setOpenPromotor}
                isArchived
            />
        </div>
    )
}

export default ArchivedPromotorsList