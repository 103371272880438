import { ChartData } from "chart.js"
import ChartJsDoughnut from "../../../../../../components/Charts/ChartsJsDoughnut"
import { colors } from "../../../../../../constants"

const CostIncomePieChart = ({ currency, currencySymbol, totalArtistCost, totalFixedCosts, sponsorAmount, otherIncomesAmount, corporateTicketAmount }: {
    currency: string,
    currencySymbol: null|string,
    totalArtistCost: number,
    totalFixedCosts: number,
    sponsorAmount: number,
    otherIncomesAmount: number,
    corporateTicketAmount: number,
}) => {

    const totalCost = totalArtistCost + totalFixedCosts

    const totalIncome = sponsorAmount + otherIncomesAmount + corporateTicketAmount

    const pieData: ChartData<"doughnut"> = {
        labels: [
            "Total Fix Cost",
            "Total Fix Income",
        ],
        datasets: [{
            data: [
                totalCost,
                totalIncome,
            ],
            backgroundColor: [
            colors.red,
            colors.green,
            ],
        }],
    }

    return (
        <div className="app-card" style={{width: '350px'}}>
            <p className="margedBot10px">Cost / Income ({currencySymbol ?? currency})</p>
            <div>
                <ChartJsDoughnut 
                    data={pieData}
                    legend
                    cutout={0}
                />
            </div>
        </div>
    )
}

export default CostIncomePieChart