// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.agenda_leftNav__AZPIn {
    left: 0px;
    position: absolute;
    top: 0px;
    bottom: 0px;
    width: 292px;
    border-right: var(--input-border);
}

.agenda_mainCalendarDiv__wKrOQ {
    padding-left: 292px;
}`, "",{"version":3,"sources":["webpack://./src/user-panels/promotor/agenda/agenda.module.css"],"names":[],"mappings":"AAAA;IACI,SAAS;IACT,kBAAkB;IAClB,QAAQ;IACR,WAAW;IACX,YAAY;IACZ,iCAAiC;AACrC;;AAEA;IACI,mBAAmB;AACvB","sourcesContent":[".leftNav {\r\n    left: 0px;\r\n    position: absolute;\r\n    top: 0px;\r\n    bottom: 0px;\r\n    width: 292px;\r\n    border-right: var(--input-border);\r\n}\r\n\r\n.mainCalendarDiv {\r\n    padding-left: 292px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"leftNav": `agenda_leftNav__AZPIn`,
	"mainCalendarDiv": `agenda_mainCalendarDiv__wKrOQ`
};
export default ___CSS_LOADER_EXPORT___;
