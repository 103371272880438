import Tabs from "../../../../components/tabs-menu/Tabs"
import { useAppSelector } from "../../../../utils/hooks/reduxTypedHooks"
import UsersList from "./UsersList"

const AdminUsers = () => {

    const users = useAppSelector(state => state.users)
    const archivedUsers = useAppSelector(state => state.archivedUsers)

    return (
        <Tabs activeTab="Active Users">
            <div option-data='Active Users'>
                <UsersList
                    users={users}
                    isArchivedList={false}
                />
            </div>

            <div option-data='Archived Users'>
            <UsersList
                    users={archivedUsers}
                    isArchivedList={true}
                />
            </div>
        </Tabs>
    )
}

export default AdminUsers