import { useRef, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";

const useFilter = (placeholder: string, searchValue?: string, onClick?: () => void, keyboardShortcut?: boolean) => {
    const [filterInput, setFilterInput] = useState(searchValue||'');
    const ref = useRef<HTMLInputElement>(null)

    //enter keyboard shortcut 
    if(keyboardShortcut && onClick) {
        document.onkeydown = (event: KeyboardEvent) => {
            if(event.key === 'Enter'){
                if (ref.current && document.activeElement === ref.current) {
                    onClick()
                }
            }
        }
    }

    const FilterUI = 
    <div className="search-container">
        <input 
            value={filterInput} 
            onChange={(e) => setFilterInput(e.target.value)} 
            className="input-ts search-input-sp" 
            placeholder={placeholder}
            ref={ref}
        />
        <span>
            <button disabled={onClick === undefined} onClick={onClick} className="flex btn button--primary btn-search">
                <AiOutlineSearch size={20} color="white" />
            </button>
        </span>
    </div>

    return { filterInput, FilterUI}
}

export default useFilter