import { createSlice } from '@reduxjs/toolkit';

const initialState = {content: undefined};  // Snackbar display condition: `snack?.content !== undefined`

export const snackSlice = createSlice({
  name: 'snack',
  initialState,
  reducers: {
    set: (state, action) => {
      return action.payload
    },
    close: (state) => {
        return {...initialState, type: state.type}  // passing type to keep bg color while downing
    }
  },
});

export const snackActions = snackSlice.actions;

export default snackSlice.reducer; 