import { Row } from "../../../../../components/Containers"
import IconifyIcon from "../../../../../components/Icon/IconifyIcon"
import { accessLevels } from "../../../../../constants"
import { useAppSelector } from "../../../../../utils/hooks/reduxTypedHooks"
import { Client } from "../../../../../utils/models/client.model"
import { Promotor } from "../../../../../utils/models/promotors.model"
import { countries } from "../../../../../utils/models/touring.model"
import { ls } from "../../../../../utils/utils"
import ProfileDetails from "./ProfileDetails"

const ProfileTab = ({ promotor, client }: {promotor: Promotor, client: Client}) => {

    const contracts = useAppSelector(state => state.contracts)

    const totalAmount = contracts.reduce((accumulator, currentContract) => {
        let contractPaiementsTotal = 0
        if(currentContract.payments?.length) {
            contractPaiementsTotal = currentContract.payments.map(payment => +payment.amount).reduce((acc, current) => acc+current, 0)
        }

        return accumulator + contractPaiementsTotal
    }, 0)

    
    return (
        <div className="flex-only row leftalign margedTop20px fw400">

            {/* Left Area */}
            <div className="width30 margedRight25px">
                <div className="app-card margedBot15px">
                    <p className="font12 uppercase margedBot15px">About</p>

                    <div className="font16 margedBot20px">
                        <div className="flex-align-center margedBot15px">
                            <IconifyIcon icon="tabler:user" fontSize={20} />
                            <span className="margedLeft5px"><span className="fw600">Full Name:</span> {promotor.name}</span>
                        </div>

                        {client.country !== undefined 
                        &&  <div className="flex-align-center margedBot15px">
                                <IconifyIcon icon="tabler:flag" fontSize={20} />
                                <span className="margedLeft5px"><span className="fw600">Country:</span> {countries[client.country]}</span>
                            </div>}

                        <div className="flex-align-center margedBot15px">
                            <IconifyIcon icon="octicon:shield-check-24" fontSize={20} />
                            <span className="margedLeft5px"><span className="fw600">Promotor level:</span> {accessLevels.promotor[promotor.accessLevel]}</span>
                        </div>

                    </div>

                    <p className="font12 uppercase margedBot15px">Contact</p>

                    <div className="font16">
                        {promotor.phone
                        &&  <div className="flex-align-center margedBot15px">
                                <IconifyIcon icon="tabler:phone" fontSize={20} />
                                <span className="margedLeft5px"><span className="fw600">Phone:</span> {promotor.phone}</span>
                            </div>}

                        {promotor.email
                        &&  <div className="flex-align-center margedBot15px">
                                <IconifyIcon icon="tabler:mail" fontSize={20} />
                                <span className="margedLeft5px"><span className="fw600">Email:</span> {promotor.email}</span>
                            </div>}
                    </div>
                </div>

                <div className="app-card">
                    <p className="font12 uppercase margedBot15px">Overview</p>

                    <div className="font16">
                        <div className="flex-align-center margedBot15px">
                            <IconifyIcon icon="mdi:contract-outline" fontSize={20} />
                            <span className="margedLeft5px"><span className="fw600">Total contracts:</span> {contracts.length}</span>
                        </div>

                        <div className="flex-align-center margedBot15px">
                            <IconifyIcon icon="mdi:artist-outline" fontSize={20} />
                            <span className="margedLeft5px"><span className="fw600">Total artists:</span>  {promotor.artists?.length ?? 0}</span>
                        </div>

                        <div className="flex-align-center margedBot15px">
                        <IconifyIcon icon="tabler:file-dollar" fontSize={20} />
                            <span className="margedLeft5px"><span className="fw600">Total amount:</span> ${ls(totalAmount, true)}</span>
                        </div>
                    </div>
                </div>
            </div>

            {/* Right Area */}
            <div className="width70">
                <div className="margedBot25px">
                    <ProfileDetails promotor={promotor} />
                </div>

                <Row>
                    <div className="app-card width50 margedRight25px">
                        <p className="card-title margedBot20px">Connection</p>
                    </div>

                    <div className="app-card width50">
                        <p className="card-title margedBot20px">Teams</p>
                    </div>
                </Row>
            </div>
        </div>
    )
}

export default ProfileTab