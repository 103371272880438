import PromotorProfile from "../user-panels/promotor/promotor-panel-screens/profile/PromotorProfile"
import { useAppSelector } from "../utils/hooks/reduxTypedHooks"
import { Licence } from "../utils/models/client.model"
import AccessDeniedPage from "./AccessDeniedPage"
import NotFoundPage from "./NotFoundPage"

const ProfilePage = () => {
    const licence = useAppSelector(state => state.client?.licence)

    if(licence === Licence.Admin) {
        return <NotFoundPage />
    } 
    else if (licence === Licence.Promotor) {
        return <PromotorProfile />
    }
    else {
        return <AccessDeniedPage />
    }
}

export default ProfilePage