import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Venue } from '../utils/models/venues.model';

const initialState: Venue[] = [];

export const venuesSlice = createSlice({
  name: 'venues',
  initialState,
  reducers: {
    set: (state, action: PayloadAction<Venue[]>) => {
      return action.payload
    },
    add: (state, action: PayloadAction<Venue>) => {
      state.push(action.payload)
    },
  },
});

export const venuesActions = venuesSlice.actions;

export default venuesSlice.reducer;