import { useEffect, useState } from "react"
import { Link, Outlet, useParams } from "react-router-dom"
import { Flex } from "../../../../components/Containers"
import { RootState } from "../../../../store"
import { useAppSelector } from "../../../../utils/hooks/reduxTypedHooks"
import OfferForm from "../../offers/form/OfferForm"
import { TouringDayWithTouringId } from "../../../../utils/models/touring.model"
import { Artist, ArtistFromDB, ArtistAPIDatas, ArtistWithTours, NormalizedArtistMarcoDB } from "../../../../utils/models/artists.model"
import { getArtistWithToursFromTourings, getDaysOfTourFromTourings, getTouringsWithArtistFromTourings, removeDaysOffFromArtistWithTours } from "../../../../utils/touring.util"
import { fetchArtistFromMarcoDB } from "../../../../api/dirsal/dirsalRequests"
import { formatUrlNameIntoName, standardizeArtistMarcoDB } from "../../../../utils/artists.util"

import "../../../../styles/artists/artists.css"
import { ROUTE_ARTISTS_FEED } from "../../../../routes/routes.const"
import ArtistDetailsHeader from "./header/ArtistDetailsHeader"
import UseFetchArtistFromColdDB from "../../../../api/server/UseFetchArtistFromColdDB"
import PageLoading from "../../../../components/loading/PageLoading"
import useFetchArtistDatas from "../../../../utils/hooks/artists/useFetchArtistDatas"

export type ArtistDetailsContext = { 
    artist: Artist|ArtistFromDB|NormalizedArtistMarcoDB, 
    openOfferForm: Function, 
    daysOfTour: TouringDayWithTouringId[] | null, 
    artistWithTours: ArtistWithTours | null,
    fetchedDatas: ArtistAPIDatas,
}

const ArtistDetails = () => {

    const { artistName } = useParams()
    const artistNameFromURL = artistName ? formatUrlNameIntoName(artistName) : ''
    const [artistFromDB, setArtistFromDB] = useState<ArtistFromDB|undefined|null>(undefined)
    const [artistFromMarcoDB, setArtistFromMarcoDb] = useState<NormalizedArtistMarcoDB|undefined|null>(undefined)
    const [offerSelectedDays, setOfferSelectedDays] = useState<TouringDayWithTouringId[]>([]);
    const [daysOfTour, setDaysOfTour] = useState<TouringDayWithTouringId[] |undefined|null>(undefined)
    const [artistWithTours, setArtistWithTours] = useState<ArtistWithTours |undefined|null>(undefined)
    const tourings = useAppSelector(state => state.tourings)
    
    const primuseArtist = useAppSelector((state:RootState) => state.artists.find(artist => artist.displayableName.toLowerCase() === artistNameFromURL))
    const client = useAppSelector(state => state.client)
    const promotor = client?.promotor;

    let artist: Artist | ArtistFromDB | null | undefined | NormalizedArtistMarcoDB = primuseArtist ?? artistFromDB
    if(!artist && artistFromMarcoDB) {
        artist = artistFromMarcoDB
    }

    const fetchArtistFromColdDB = UseFetchArtistFromColdDB()

    const { fetchStreamingDatas, fetchedDatas, spotifyFinished, setSpotifyFinished } = useFetchArtistDatas(primuseArtist)

    const [isFetchingDatas, setIsFetchingDatas] = useState(false)
    
    //compute touring async
    useEffect(() => {
        const computeDaysOfTour = async () => {
            if(primuseArtist === undefined){return}
            const toursWithArtist = await getTouringsWithArtistFromTourings([primuseArtist], tourings)

            const days = await getDaysOfTourFromTourings(toursWithArtist)
            setDaysOfTour(days)
        }

        const computeArtistWithTours = async () => {
            if(primuseArtist === undefined){return}
            const computedArtist = await getArtistWithToursFromTourings(primuseArtist, tourings)
            setArtistWithTours(computedArtist)
        }

        computeDaysOfTour()
        computeArtistWithTours()
    }, [primuseArtist])

    // Fetch datas from APIs
    useEffect(() => {
        const fetchDatas = async() => {
            setSpotifyFinished(false)
            setIsFetchingDatas(true)
            
            // let dba = null
            let mdba = null
            // if(!primuseArtist){
            //     console.time('coldDB')
            //     dba = await fetchDBArist()
            //     console.timeEnd('coldDB')
            // }
            if(!primuseArtist) {
                setDaysOfTour(null)
                setArtistWithTours(null)
                console.time('SQLDB')
                mdba = await fetchMarcoDBArtist()
                console.timeEnd('SQLDB')
            }

            await fetchStreamingDatas(mdba)

            setIsFetchingDatas(false)
        }

        // const fetchDBArist = async() => {
        //     const artistFromDB = await fetchArtistFromColdDB(artistNameFromURL)
        //     setArtistFromDB(artistFromDB)
        //     return artistFromDB
        // }

        const fetchMarcoDBArtist = async () => {
            const result = await fetchArtistFromMarcoDB(artistNameFromURL)
            const foundArtist = result?.data?.response?.[0]
            if(foundArtist){
                const standardizedArtist = standardizeArtistMarcoDB(foundArtist)
                setArtistFromMarcoDb(standardizedArtist)
                return standardizedArtist as NormalizedArtistMarcoDB
            } else {
                return null
            }
        }

        fetchDatas()
    }, [artistName])


    if(daysOfTour === undefined || artistWithTours === undefined || (isFetchingDatas && !spotifyFinished) ) {
        return <PageLoading />
    }

    if(!artist){
        return (
            <Flex className='allspace'>
                <p className="grey margedBot30px">Artist not found</p>
                <Link to={ROUTE_ARTISTS_FEED}><button className="button button--primary">Go back to all artists</button></Link>
            </Flex>
        )
    }


    const artistWithToursNoDaysOff = removeDaysOffFromArtistWithTours(artistWithTours)

    const openOfferForm = (_selectedDays: TouringDayWithTouringId[]) => {
        setOfferSelectedDays(_selectedDays);
    };
    const closeOfferForm = () => setOfferSelectedDays([]);

    return (
        (offerSelectedDays?.length && primuseArtist && promotor )
        ? <OfferForm 
            artist={primuseArtist}
            promotor={promotor}
            closeForm={closeOfferForm}
            selectedDays={offerSelectedDays}
        />
        : <div>
            
            <ArtistDetailsHeader artist={artist} daysOfTour={daysOfTour} fetchedDatas={fetchedDatas} />

            <div className="margedBot50px paddedTop40px">
                <Outlet context={{ artist, openOfferForm, daysOfTour, artistWithTours: artistWithToursNoDaysOff, fetchedDatas }} />
            </div>
            
            <Link to={ROUTE_ARTISTS_FEED}><button className="button button--primary margedBot100px">Go back to all artists</button></Link>
        </div>
    )
}

export default ArtistDetails