// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notifications-circle-div {
    /* Make nav dropdown not go behind */
    z-index: 1; 
    position: absolute;
    top: 0px;
    right: -10px;
  }
  
  .notifications-circle {
    height: 20px;
    width: 20px;
    background-color: var(--main-color);
    border-radius: 200px;
  }

.notifications__dropdown {
    position: absolute;
    border-radius: 3px;
    top: 130%;
    right: -100px;
    background-color: white;
    width: 300px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.05);
    border: 1px solid lightgray;
}

.notifications__menu {
    display: flex;
    flex-direction: column;
    padding: 16px;
}

.notification__item-div {
    color: black;
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #eaeaea;
}
.notification__item-div:nth-last-of-type(1) {
    margin-bottom: 0;
    padding-bottom: 0;
    border-bottom: none;
}`, "",{"version":3,"sources":["webpack://./src/user-panels/common/notifications/notifications.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;IACpC,UAAU;IACV,kBAAkB;IAClB,QAAQ;IACR,YAAY;EACd;;EAEA;IACE,YAAY;IACZ,WAAW;IACX,mCAAmC;IACnC,oBAAoB;EACtB;;AAEF;IACI,kBAAkB;IAClB,kBAAkB;IAClB,SAAS;IACT,aAAa;IACb,uBAAuB;IACvB,YAAY;IACZ,sCAAsC;IACtC,2BAA2B;AAC/B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,oBAAoB;IACpB,mBAAmB;IACnB,gCAAgC;AACpC;AACA;IACI,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;AACvB","sourcesContent":[".notifications-circle-div {\r\n    /* Make nav dropdown not go behind */\r\n    z-index: 1; \r\n    position: absolute;\r\n    top: 0px;\r\n    right: -10px;\r\n  }\r\n  \r\n  .notifications-circle {\r\n    height: 20px;\r\n    width: 20px;\r\n    background-color: var(--main-color);\r\n    border-radius: 200px;\r\n  }\r\n\r\n.notifications__dropdown {\r\n    position: absolute;\r\n    border-radius: 3px;\r\n    top: 130%;\r\n    right: -100px;\r\n    background-color: white;\r\n    width: 300px;\r\n    box-shadow: 0 2px 5px rgba(0,0,0,0.05);\r\n    border: 1px solid lightgray;\r\n}\r\n\r\n.notifications__menu {\r\n    display: flex;\r\n    flex-direction: column;\r\n    padding: 16px;\r\n}\r\n\r\n.notification__item-div {\r\n    color: black;\r\n    padding-bottom: 15px;\r\n    margin-bottom: 15px;\r\n    border-bottom: 1px solid #eaeaea;\r\n}\r\n.notification__item-div:nth-last-of-type(1) {\r\n    margin-bottom: 0;\r\n    padding-bottom: 0;\r\n    border-bottom: none;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
