import { Offer, OfferStatus } from "../../../../utils/models/offer.model"
import OfferItemRow from "./OfferItemRow"
import { AiOutlineCaretUp, AiOutlineCaretDown } from "react-icons/ai"
import { useEffect, useState } from "react"
import { Flex } from "../../../../components/Containers"
import useFilter from "../../../../utils/hooks/useFilter"

const OffersTable = ({ offers, past }: { offers: Offer[], past?: boolean }) => {

    const _offers = offers.slice()
    _offers.sort((offerA, offerB) => offerB.date - offerA.date)

    const [isCreationSortAscending, setIsCreationSortAscending] = useState(false)
    const [isLimitSortAscending, setIsLimitSortAscending] = useState(false)
    const [isShowDatesSortAscending, setIsShowDatesSortAscending] = useState(false)
    const [isAmountSortAscending, setIsAmountSortAscending] = useState(false)
    const [isActionSortAscending, setIsActionSortAscending] = useState(false)

    const [sortedOffers, setSortedOffers] = useState(_offers)

    const { filterInput, FilterUI} = useFilter('Search by artist name')

    useEffect(() => {
        setSortedOffers(_offers)
    }, [offers])

    useEffect(() => {
        const filteredOffers = _offers.filter(offer => offer.artistName.toLowerCase().includes(filterInput.toLowerCase()))
        filteredOffers.sort((offerA, offerB) => offerB.date - offerA.date)
        setSortedOffers(filteredOffers)
    }, [filterInput])

    const handleSortByCreated = () => {
        if(isCreationSortAscending) {
            const newSortedOffers = sortedOffers.slice()
            newSortedOffers.sort((offerA, offerB) => offerB.date - offerA.date)
            setSortedOffers(newSortedOffers)
        } else {
            const newSortedOffers = sortedOffers.slice()
            newSortedOffers.sort((offerA, offerB) => offerA.date - offerB.date)
            setSortedOffers(newSortedOffers)
        }
        setIsCreationSortAscending(prevValue => !prevValue)
    }

    const handleSortByLimit = () => {
        if(isLimitSortAscending) {
            const newSortedOffers = sortedOffers.slice()
            newSortedOffers.sort((offerA, offerB) => {
                const timeA = new Date(offerA.expiryDate).getTime()
                const timeB = new Date(offerB.expiryDate).getTime()
                return timeB - timeA
            })
            setSortedOffers(newSortedOffers)
        } else {
            const newSortedOffers = sortedOffers.slice()
            newSortedOffers.sort((offerA, offerB) => {
                const timeA = new Date(offerA.expiryDate).getTime()
                const timeB = new Date(offerB.expiryDate).getTime()
                return timeA - timeB
            })
            setSortedOffers(newSortedOffers)
        }
        setIsLimitSortAscending(prevValue => !prevValue)
    }

    const handleSortByShowDates= () => {
        if(isShowDatesSortAscending) {
            const newSortedOffers = sortedOffers.slice()
            newSortedOffers.sort((offerA, offerB) => new Date(offerB.shows[0].date).getTime() - new Date(offerA.shows[0].date).getTime())
            setSortedOffers(newSortedOffers)
        } else {
            const newSortedOffers = sortedOffers.slice()
            newSortedOffers.sort((offerA, offerB) => new Date(offerA.shows[0].date).getTime() - new Date(offerB.shows[0].date).getTime())
            setSortedOffers(newSortedOffers)
        }
        setIsShowDatesSortAscending(prevValue => !prevValue)
    }

    const handleSortByAmount = () => {
        if(isAmountSortAscending) {
            const newSortedOffers = sortedOffers.slice()
            newSortedOffers.sort((offerA, offerB) => offerB.totalAmount - offerA.totalAmount)
            setSortedOffers(newSortedOffers)
        } else {
            const newSortedOffers = sortedOffers.slice()
            newSortedOffers.sort((offerA, offerB) => offerA.totalAmount - offerB.totalAmount)
            setSortedOffers(newSortedOffers)
        }
        setIsAmountSortAscending(prevValue => !prevValue)
    }

    const handleSortByAction = () => {
        const actionsStatus = [OfferStatus.RequestEdition, OfferStatus.PromotorTrusteeCheck]
        if(isActionSortAscending) {
            const newSortedOffers = sortedOffers.slice()
            newSortedOffers.sort((offerA, offerB) => {
                const hasAAction = actionsStatus.includes(offerA.status)
                const hasBAction = actionsStatus.includes(offerB.status)
                if(hasAAction) {
                    return -1
                } else if (hasBAction) {
                    return 1
                } else {
                    return 0
                }
            })
            setSortedOffers(newSortedOffers)
        } else {
            const newSortedOffers = sortedOffers.slice()
            newSortedOffers.sort((offerA, offerB) => {
                const hasAAction = actionsStatus.includes(offerA.status)
                const hasBAction = actionsStatus.includes(offerB.status)
                if(hasAAction) {
                    return 1
                } else if (hasBAction) {
                    return -1
                } else {
                    return 0
                }
            })
            setSortedOffers(newSortedOffers)
        }
        setIsActionSortAscending(prevValue => !prevValue)
    }

    const renderOfferRow = (offer: Offer, index: number) => <OfferItemRow offer={offer} key={index} past={past} />

    return (
        <>
            <div className="margedBot20px">
                {FilterUI}
            </div>

            {sortedOffers.length 
            ?
                <table className="app-table ">
                    <thead>
                        <tr>
                            <th className="leftalign">
                                <div className="clickable flex-align-center row" onClick={handleSortByCreated}>
                                    <p className="margedRight2px">Date of Creation</p>
                                    {isCreationSortAscending
                                    ?   <AiOutlineCaretUp size={15}/>
                                    :   <AiOutlineCaretDown size={15}/>}
                                </div>
                            </th>
                            <th className="leftalign">
                                <div className="clickable flex-align-center row" onClick={handleSortByLimit}>
                                    <p className="margedRight2px">Limit date</p>
                                    {isLimitSortAscending
                                    ?   <AiOutlineCaretUp size={15}/>
                                    :   <AiOutlineCaretDown size={15}/>}
                                </div>
                            </th>
                            <th className="leftalign">Artist</th>
                            <th>Nbr shows</th>
                            <th className="leftalign">Countries</th>
                            <th className="leftalign">
                                <div className="clickable flex-align-center row" onClick={handleSortByShowDates}>
                                    <p className="margedRight2px">Shows Dates</p>
                                    {isShowDatesSortAscending
                                    ?   <AiOutlineCaretUp size={15}/>
                                    :   <AiOutlineCaretDown size={15}/>}
                                </div> 
                            </th>
                            <th className="leftalign" style={{width: '10%'}}>
                                <div className="clickable flex-align-center row" onClick={handleSortByAmount}>
                                    <p className="margedRight2px">Offer</p>
                                    {isAmountSortAscending
                                    ?   <AiOutlineCaretUp size={15}/>
                                    :   <AiOutlineCaretDown size={15}/>}
                                </div>
                            </th>
                            <th className="leftalign">{ past ? 'Last Status' : 'Current Status' }</th>
                            <th>Open</th>
                            <th>View PNLs</th>
                            { !past && 
                                <th style={{width: '15%'}}>
                                    <Flex row className="clickable" onClick={handleSortByAction}>
                                        <p className="margedRight2px">Actions</p>
                                        {isActionSortAscending
                                        ?   <AiOutlineCaretUp size={15}/>
                                        :   <AiOutlineCaretDown size={15}/>}
                                    </Flex>
                                </th> }
                        </tr>
                    </thead>
                    <tbody>
                        {sortedOffers.map(renderOfferRow)} 
                    </tbody>
                </table>

            : <p className="grey">No offers at the moment.</p>}
        </>
    )
}

export default OffersTable