import { Link } from "react-router-dom";
import { formatDisplayableNameToUrl } from "../../../../utils/artists.util";
import { ROUTE_ARTISTS, ROUTE_TOURING } from "../../../../routes/routes.const";
import styles from "../../touring/touring.module.css";
import { Flex, Row } from "../../../../components/Containers";
import ReactCountryFlag from "react-country-flag"
import { TfiLocationPin } from "react-icons/tfi";
import { FiClock } from "react-icons/fi";
import moment from "moment";
import { AiOutlineCalendar, AiOutlineFolder } from "react-icons/ai";
import { countries } from "../../../../utils/models/touring.model";


const DashboardNextShows = ( { nextShows }: { nextShows: any[]}) => {

    return (
        <>
            <h3 className="title-color margedBot10px">Next Tour Days Listed</h3>
            {
                nextShows?.length
                && nextShows.map(renderNextShow)
            }
            <Link to={ROUTE_TOURING}>
                <button className="button button--primary button--round margedTop20px margedBot20px">View All</button>
            </Link>
        </>
    )
}

const renderNextShow = (nextShow: any, index: number) => {
    const {
        touringId,
        artist,
        touringName,
        ...show
    } = nextShow;

    const artistBookingUrl = `${ROUTE_ARTISTS}/${formatDisplayableNameToUrl(artist.displayableName)}/tours#booking`

    return <Link className="allwidth" to={artistBookingUrl} key={index}>
        <Row className="spacebetween touring__list-item clickable hover-medium-scale-shadow">
            <Flex className="alignstart">
                <h3 className="font18 title-color margedBot5px">{artist.displayableName} - {touringName}</h3>
                <Flex className={'smaller alignstart'}>
                    <Row className="justifystart">
                        {/* <TfiLocationPin className="main-color" size={20}  />   */}
                        &nbsp;
                        <Row className="justifystart">
                            <b>{countries[show.country]}</b>
                            &nbsp;
                            <ReactCountryFlag 
                                countryCode={show.country} 
                                style={{
                                    lineHeight: '35px',
                                    fontSize: '35px',
                                }}
                                title={show.country}
                            />
                        </Row>
                    </Row>
                </Flex>
            </Flex>
            <Flex className="larger">
                {/* <AiOutlineCalendar className="touring__icons" color='var(--touring-main-color)' />  */}
                <p>{moment(new Date(show.date)).format('MMMM Do YYYY')}</p>
            </Flex>
            <Flex>
                <button className="button touring__button button--round">Book Artist</button>
            </Flex>
        </Row>
    </Link>
}

export default DashboardNextShows