import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SHOW_DURATION_ALL_DURATIONS } from '../utils/touring.util';

interface TouringShowDurationDropdown {
    isOpen: boolean,
    durationsFilter: number[]
}

const initialState: TouringShowDurationDropdown = {
    isOpen: false,
    durationsFilter: [SHOW_DURATION_ALL_DURATIONS]
};

export const touringShowDurationDropdownSlice = createSlice({
  name: 'touringShowDurationDropdown',
  initialState,
  reducers: {
    toggleDropdown: (state) => {
        state.isOpen = !state.isOpen
    },
    closeDropdown: (state) => {
        state.isOpen = false
    },
    reset: (state) => {
        return initialState
    },
    toggleDuration: (state, action: PayloadAction<number>) => {
        const durationIndex = state.durationsFilter.findIndex(duration => duration === action.payload)
        if(durationIndex === -1){
            const isAddingAllDurations = action.payload === SHOW_DURATION_ALL_DURATIONS
            // If we add the 'All Durations' filter we remove every other filter
            if(isAddingAllDurations) {
                state.durationsFilter = [action.payload]
            } else {
                state.durationsFilter.push(action.payload)
    
                // If we add a filter we remove 'all durations' from the selected filters
                if(state.durationsFilter.includes(SHOW_DURATION_ALL_DURATIONS)){
                    const allDurationsIndex = state.durationsFilter.findIndex(duration => duration === SHOW_DURATION_ALL_DURATIONS)
                    state.durationsFilter.splice(allDurationsIndex, 1)
                }
            }
        } else {
            state.durationsFilter.splice(durationIndex, 1)
        }
    }
  },
});

export const touringShowDurationDropdownActions = touringShowDurationDropdownSlice.actions;

export default touringShowDurationDropdownSlice.reducer;