import Tabs from "../../../../../components/tabs-menu/Tabs"
import { OfferShowFormInputs } from "../../../../../utils/models/offer.model"
import ViewPNL from "../../../../common/offers/pnl/ViewPNL"
import PNLForm from "./PNLForm"

const OfferPNL = ({ show, setOfferShow, showIndex, rate, currencySymbol, setShowPnlInputs, close, artistName }: { 
    show: OfferShowFormInputs,
    setOfferShow: (showIndex: number, showProps: {}) => void,
    showIndex: number,
    rate: null|number,
    currencySymbol: null|string,
    setShowPnlInputs: (showIndex: number, props: any) => void,
    close: () => void,
    artistName: string,
 }) => {

    return (
        <Tabs activeTab='pnl'>
            <div option-data='pnl' data-icon='material-symbols:query-stats'>
                <PNLForm 
                    show={show}
                    setOfferShow={setOfferShow}
                    showIndex={showIndex}
                    rate={rate}
                    currencySymbol={currencySymbol}
                    setShowPnlInputs={setShowPnlInputs}
                    close={close}
                />
            </div>

            <div option-data='pdf' data-icon='material-symbols:picture-as-pdf'>
                <ViewPNL
                    show={show}
                    rate={rate}
                    currencySymbol={currencySymbol}
                    artistName={artistName}
                />
            </div>
        </Tabs>
    )
}

export default OfferPNL