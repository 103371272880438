import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { snackActions } from './store/snackSlice';

import { Snackbar } from './components/snackbar/Snackbar';

import Dialog from './components/dialog/Dialog';

import { useLocation, Outlet } from "react-router-dom";
import { ROUTE_LANDING, ROUTE_DASHBOARD } from "./routes/routes.const"
import ProtectedRoute from "./routes/ProtectedRoute";

import useDispatchLoginRes from './utils/hooks/useDispatchLoginRes'
import useHandleTokenExpired from './utils/hooks/useHandleTokenExpired'

import {fetchApiAuthentified, promisify} from './utils/utils'
import useSetSnack from './utils/hooks/useSetSnack'

import FullPageLoading from "./components/loading/FullPageLoading"

import './App.css';
import './fullcalendar.css'
import './holobutton.scss';

function App () {
  const [sessionLogin, setSessionLogin] = useState(false);

  const client = useSelector(state => state.client);
  const snack = useSelector(state => state.snack);

  const dispatch = useDispatch();
  
  const dispatchLoginRes = useDispatchLoginRes();
  const handleTokenExpired = useHandleTokenExpired();
  const setSnack = useSetSnack();
  

  const closeSnack = () => dispatch(snackActions.close());
  
  const location = useLocation()
  
  useEffect(() => {
    if (!client?.token) {
      const lc = localStorage.getItem('client');
      const localClient = lc?.includes('id') && JSON.parse(lc);
      const isLanding = location.pathname === ROUTE_LANDING;

      if (localClient?.token && (localClient?.tokenValidity > Date.now())) {
        
        setSessionLogin(true)
        
        const fetchApiAuth = fetchApiAuthentified(
          localClient.token, 
          handleTokenExpired
        );


        (async() => {
            // fetch
            const {response, error} = await fetchApiAuth({
              method: 'POST',
              route: 'session-login'
            });

            if (!error) {
              const redirectPath = await promisify((location.pathname && !isLanding) ? location.pathname : ROUTE_DASHBOARD);
              dispatchLoginRes(response, redirectPath);
              setSnack({
                type: 'success',
                content: 'You are connected'
              })
            } else {
              setSnack({
                type: 'error',
                content: error.toString()
              })
            }

            setSessionLogin(false);
        })()
      }
      else if(localClient && !isLanding) {
        setSnack({
          type: 'warning',
          content: 'Session has expired'
        });
        localStorage.removeItem('client')
      }
    }
  }, [])

    return (
      <div className="app-wrapper"> 
        <div 
          className="app" 
        >
          {
            sessionLogin
            ? <FullPageLoading />
            : <ProtectedRoute client={client}>
                <Outlet />
              </ProtectedRoute>
          }
        </div>


        <Snackbar
            type={snack?.type}
            closeSnack={closeSnack}
        >
            { snack?.content }
        </Snackbar>

        <Dialog />
      </div>
    );
}

export default App;
