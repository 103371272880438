import { useState } from "react"
import { createPortal } from "react-dom"
import { AiOutlineFolderOpen } from "react-icons/ai"
import { ThreeDots } from "react-loader-spinner"
import { fetchCountryInfos } from "../../../api/dirsal/dirsalRequests"
import { Flex } from "../../../components/Containers"
import Dialog from "../../../components/dialog/Dialog"
import { colors } from "../../../constants"
import ViewPNL from "../../../user-panels/common/offers/pnl/ViewPNL"
import { OfferShow } from "../../models/offer.model"

const useViewShowPNL = ({ show, artistName, textColor }: { show: OfferShow, artistName: string, textColor?: boolean }) => {

    const [viewPNL, setViewPNL] = useState(false)
    const [rate, setRate] = useState<(null|number)>(null)
    const [currencySymbol, setCurrencySymbol] = useState<(null|string)>(null)

    const [isFetchingCountryDatas, setIsFetchingCountryDatas] = useState(false)

    const rootModalDiv = document.getElementById('modal-root')!

    const openViewPNL = () => setViewPNL(true)
    const closeViewPNL = () => setViewPNL(false)

    const handleViewPNL = async () => {
        setIsFetchingCountryDatas(true)

        // fetching country datas
        const response = await fetchCountryInfos(show.country)
        console.log(response)

        const fetchedRate = response?.data?.currency?.exchange.inverseRate
        if(fetchedRate) {
            setRate(fetchedRate)
        }
        
        const fetchedCurrencySymbol = response?.data?.currency?.symbol
        if(fetchedCurrencySymbol) {
            setCurrencySymbol(fetchedCurrencySymbol)
        }
        

        openViewPNL()

        setIsFetchingCountryDatas(false)
    }

    const pnlsModal = createPortal(
        <Dialog
            visible={viewPNL}
            onClose={closeViewPNL}
            dialogCustomStyle={{overflowY: 'scroll', height: '90vh', top: '5vh', width: '80%', left: '10%', backgroundColor: 'var(--app-background)'}}
            notAllSpace={true}
        >
            <ViewPNL
                show={show}
                rate={rate}
                currencySymbol={currencySymbol}
                artistName={artistName}
            />
        </Dialog>, rootModalDiv)

    const openPnlsButton = isFetchingCountryDatas
        ?   <Flex><ThreeDots 
                height="25" 
                width="25" 
                radius="9"
                color={textColor ? colors.gray : colors.main} 
            /></Flex>
        :   <AiOutlineFolderOpen className={`${textColor ? "text-color-fill" : "main-color-fill"} clickable hoverscale`} size={25} onClick={handleViewPNL} />


    return { pnlsModal, openPnlsButton, handleViewPNL }
}

export default useViewShowPNL