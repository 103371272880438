import moment from "moment";
import ReactCountryFlag from "react-country-flag";
import { BsCheckLg, BsPencil } from "react-icons/bs";
import { ImCancelCircle } from "react-icons/im";
import { MdOpenInNew } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { Flex, Row } from "../../../../components/Containers";
import LoadingDots from "../../../../components/loading/LoadingDots";
import { errorToSuccessTheme3 } from "../../../../constants";
import { ARTIST_DETAILS_ASSETS_ENDPOINT, ROUTE_ARTISTS } from "../../../../routes/routes.const";
import { formatDisplayableNameToUrl } from "../../../../utils/artists.util";
import { countries } from "../../../../utils/models/touring.model";
import { ls } from "../../../../utils/utils";
import getContractStatus from "./get-contract-status";

const ContractItemRow = ( {c, cind, openContract, openingContract }: {c: any, cind: number, openContract: any, openingContract: boolean|string}) => {
    const navigate = useNavigate()

    const { id, artist, date, purchaser, shows, payments} = c;
        const status = getContractStatus(c);
        
        const handleOpenAssets = (e: any) => {
            //stop propagation of opening the contract
            e.stopPropagation()
            const assetsUrl = `${ROUTE_ARTISTS}/${formatDisplayableNameToUrl(artist.name)}/${ARTIST_DETAILS_ASSETS_ENDPOINT}`
            navigate(assetsUrl)
        }
        
        const cStatus = <Flex>
            {
                status.includes('waiting')
                // ? <Flex row><FaPenAlt color={errorToSuccessTheme3[1]} size={20} /> <span className="selfstart silver"> { status.includes('both') && 'X2' }</span></Flex>
                ? <Flex row><BsPencil color={errorToSuccessTheme3[1]} size={20} /> { status.includes('both') && <BsPencil color={errorToSuccessTheme3[1]} size={20} /> }</Flex>
                : (
                    status.includes('null')
                    ? <ImCancelCircle color={errorToSuccessTheme3[0]} size={20} />
                    : <BsCheckLg color={errorToSuccessTheme3[2]} size={20} />
                )
                
            } 
            <b>{ status }</b> 
        </Flex>

        return <tr key={`contract_${cind}`} onClick={() => openContract(id)} className="clickable hover-medium-scale-shadow"> 
            <td className="leftalign">{ moment(date).format('LLL') }</td>
            <td className="leftalign">{ id }</td>
            <td className="clickable leftalign">{ artist.name }</td>
            <td className="clickable leftalign">{ purchaser.name }</td>
            <td>
                <Row className="wrap">
                    {
                        shows.map((show: any, index: number) => <Flex className="margedRight10px" key={index}>

                            <ReactCountryFlag
                                countryCode={show?.venue?.flag} 
                                style={{
                                    lineHeight: '20px',
                                    fontSize: '20px',
                                }}
                                title={countries[show.venue?.flag]}
                            />
                            {/* { countries[show.venue?.flag] } */}
                        </Flex>)
                    }
                </Row>
            </td>
            <td>{ shows?.length }</td>
            <td>{ ls(payments.map((p: any) => parseInt(p.amount)).reduce((a: any, b: any) => a+b)) }</td>
            <td>{ cStatus }</td>
            <td> <MdOpenInNew onClick={handleOpenAssets} className="main-color-fill clickable hoverscale" size={25} /> </td>
            <td className="last">
                {/* @ts-ignore */}
                <LoadingDots
                    loading={openingContract === id}
                    onClick={() => openContract(id)}
                />
              
            </td>
        </tr>
}

export default ContractItemRow