import { useEffect, useState } from "react"
import PageLoading from "../../../../../components/loading/PageLoading"
import { requestsActions } from "../../../../../store/requestsSlice"
import { useAppDispatch, useAppSelector } from "../../../../../utils/hooks/reduxTypedHooks"
import useFetchApiAuth from "../../../../../utils/hooks/useFetchApiAuth"
import useSetSnack from "../../../../../utils/hooks/useSetSnack"
import RequestItemRow from "./RequestItemRow"

const AdminRequests = () => {

    const [isFetching, setIsFetching] = useState(false)
    const requests = useAppSelector(state => state.requests)
    const fetchApiAuth = useFetchApiAuth()
    const setSnack = useSetSnack()
    const dispatch = useAppDispatch()

    useEffect(() => {
        const fetchRequests = async () => {
            setIsFetching(true)
            const { error, response: requestsList } = await fetchApiAuth({
                method: 'GET',
                route: 'show-requests',
            })

            setSnack(
                error
                ? {
                    type: 'error', 
                    content: error.toString()
                }
                : {
                    type: 'success',
                    content: 'Requests list fetched successfully.'
                }
            )

            if(!error)
                dispatch(requestsActions.set(requestsList))

            setIsFetching(false)
            }
        
        // only fetch feedbacks once per session
        if(requests === null) {
            fetchRequests()
        }
    }, [])

    if(isFetching) {
        return (<PageLoading />)
    }

    return(
        <div className='main-container margedBot100px paddedTop30px'>
            <h3 className="page-title">Requests</h3>

            {requests?.length 
            ?   <table className="app-table">
                    <thead>
                        <tr>
                            <th className="leftalign">For Artist</th>
                            <th>Kisum</th>
                            <th className="leftalign">From</th>
                            <th className="leftalign">Promotor Name</th>
                            <th className="leftalign">Level</th>
                            <th className="leftalign">Contacts</th>
                            <th>Nbr of Contracts</th>
                            <th>Amount</th>
                            <th>Make Contract</th>
                            <th>Add Artist</th>
                            <th>View</th>
                        </tr>
                    </thead>

                    <tbody>
                        {requests.map((request, index) => <RequestItemRow key={index} request={request} />)}
                    </tbody>
                </table>
            :   <p className="grey">No requests for now.</p>}
        </div>
    )
}

export default AdminRequests