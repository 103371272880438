import WhyItem from "../components/WhyItem"

const Why = () => {

    return (
        <div className="landing-why">
            <div className="landing-container">
                <h2 className="landing-title margedBot30px">Why Kisum</h2>

                <div className="grid-3-col" style={{columnGap: "80px"}}>
                    <WhyItem title="Artists" text="1.7M" iconName="mdi:artist" />
                    <WhyItem title="Analysis" text="2.3s" iconName="mdi:graph-line" />
                    <WhyItem title="Datas" text="8.5M" iconName="material-symbols:database" />
                </div>
            </div>
        </div>
    )
}

export default Why