import Dialog from "../dialog/Dialog"
import { Oval } from "react-loader-spinner"

const LoadingModal = ({ visible, text }: {visible: boolean, text: string }) => {

    return (
        <Dialog
            visible={visible}
            backdropClose={false}
            dialogCustomStyle={{height: '150px', top: '30%', width: '40%', left: '30%'}}
            onClose={() => {}}
            // notAllSpace={true}
        >
            <Oval
                height={34}
                width={34}
                color="var(--main-color)"
                secondaryColor="var(--main-color)"
            />
            <p className="margedTop10px">{text}</p>
        </Dialog>
    )
}

export default LoadingModal